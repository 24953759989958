<!-- Register modal - style you can find in register.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide p-0" id="signupModal" tabindex="-1" role="dialog" aria-labelledby="signupModal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content register">
            <div class="modal-header justify-content-end">
                <button type="button" #auxCloseSignupModal class="close" data-dismiss="modal" aria-label="Close"
                    (click)="signupStep(0)">
                    <span aria-hidden="true">
                        <mat-icon style="font-size: 30px;">cancel</mat-icon>
                    </span>
                </button>
            </div>
            <div class="modal-body modal-body-register">
                <div class="container-fluid m-0 p-0 login-signup">
                    <form [formGroup]="registerForm" class="m-0 p-0" id="registerform" autocomplete="off"
                        (ngSubmit)="crearUsuario()">
                        <h3 *ngIf="step === 0 || step == 2" class="signup-title text-center">Crea una nueva cuenta</h3>
                        <h3 *ngIf="step === 1" class="signup-title text-center">Dirección y Contacto</h3>
                        <!-- Step 1 -->
                        <div *ngIf="step === 0" class="m-0 p-0 signup-step">
                            <div class="signup-desc text-left">
                                <p>Todos los campos son obligatorios.</p>
                            </div>
                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input"
                                    [ngClass]="{'focus-custom-input': signupRutFocus}">
                                    <label *ngIf="signupRutFocus || registerForm.get('rut').value?.length" for="rut"
                                        class="{{ signupRutFocus ? 'blue' : 'gray' }}">
                                        RUT
                                    </label>
                                    <input class="form-control" type="text" name="rut_valida" id="rut"
                                        formControlName="rut" placeholder="RUT" onfocus="this.placeholder = '';"
                                        onblur="this.placeholder = 'RUT';" (input)="changeRut()"
                                        (focus)="signupRutFocus=true;" (blur)="signupRutFocus=false;">
                                </div>
                            </div>
                            <p *ngIf="campoNoValido('rut')" class="mb-0 pb-0 mt-1" style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes ingresar un RUT válido.
                            </p>
                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input"
                                    [ngClass]="{'focus-custom-input': signupNameFocus}">
                                    <label *ngIf="signupNameFocus || registerForm.get('nombre').value?.length"
                                        for="nombre" class="{{ signupNameFocus ? 'blue' : 'gray' }}">
                                        Nombre
                                    </label>
                                    <input class="form-control" type="text" formControlName="nombre"
                                        placeholder="Nombre" onfocus="this.placeholder = '';"
                                        onblur="this.placeholder = 'Nombre';" id="nombre"
                                        (focus)="signupNameFocus=true;" (blur)="signupNameFocus=false;">
                                </div>
                            </div>
                            <p *ngIf="campoNoValido('nombre')" class="mb-0 pb-0 mt-1" style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes escribir tu primer nombre.
                            </p>
                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input"
                                    [ngClass]="{'focus-custom-input': signupLastNameFocus}">
                                    <label
                                        *ngIf="signupLastNameFocus || registerForm.get('apellido_paterno').value?.length"
                                        for="apellido_paterno" class="{{ signupLastNameFocus ? 'blue' : 'gray' }}">
                                        Primer Apellido
                                    </label>
                                    <input class="form-control" type="text" formControlName="apellido_paterno"
                                        id="apellido_paterno" placeholder="Primer Apellido"
                                        onfocus="this.placeholder = '';" onblur="this.placeholder = 'Primer Apellido';"
                                        (focus)="signupLastNameFocus=true;" (blur)="signupLastNameFocus=false;">
                                </div>
                            </div>
                            <p *ngIf="campoNoValido('apellido_paterno')" class="mb-0 pb-0 mt-1" style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes escribir tu primer apellido.
                            </p>
                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input"
                                    [ngClass]="{'focus-custom-input': signupMLastNameFocus}">
                                    <label
                                        *ngIf="signupMLastNameFocus || registerForm.get('apellido_materno').value?.length"
                                        for="apellido_materno" class="{{ signupMLastNameFocus ? 'blue' : 'gray' }}">
                                        Segundo Apellido
                                    </label>
                                    <input class="form-control" type="text" formControlName="apellido_materno"
                                        id="apellido_materno" placeholder="Segundo Apellido"
                                        onfocus="this.placeholder = '';" onblur="this.placeholder = 'Segundo Apellido';"
                                        (focus)="signupMLastNameFocus=true;" (blur)="signupMLastNameFocus=false;">
                                </div>
                            </div>
                            <p *ngIf="campoNoValido('apellido_materno')" class="mb-0 pb-0 mt-1"
                                style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes escribir tu segundo apellido.
                            </p>
                            <div class="form-row p-0 m-0">
                                <div class="col-12 col-sm-6 col-lg-6 p-0 m-0 pr-lg-3">
                                    <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                        [ngClass]="{'signup-input-wrapper-addon-blue': signupGenderFocus, 'signup-input-wrapper-addon-gray': !signupGenderFocus}">
                                        <select id="genero" class="form-control" style="height: 100%; width: 100%; border: none; margin-right: 5px;"
                                            formControlName="genero">
                                            <option selected [value]="''">Género</option>
                                            <option *ngFor="let genero of generos" [value]="genero">{{ genero }}</option>
                                        </select>
                                    </div>
                                    <p *ngIf="campoNoValido('genero')" class="mb-0 pb-0 mt-1"
                                        style="display: flex; align-items: center;"
                                        [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                        <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                        Debes seleccionar el género.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-6 p-0 m-0">
                                    <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                        [ngClass]="{'signup-input-wrapper-addon-blue': signupBirthDateFocus, 'signup-input-wrapper-addon-gray': !signupBirthDateFocus}">
                                        <div class="col-12 m-0 signup-custom-input">
                                            <label *ngIf="signupBirthDateFocus || registerForm.get('fecha_nacimiento').value?.length"
                                                class="{{ signupBirthDateFocus ? 'blue' : 'gray' }}">
                                                Fecha de nacimiento
                                            </label>
                                            <div class="input-group p-0 m-0">
                                                <input formControlName="dia_nac" id="dia_nac" style="width: 33%;" type="number" min="1" max="31" name="dia_nac" [(ngModel)]="dia_nac" placeholder="Día">
                                                <input formControlName="mes_nac" id="mes_nac" style="width: 33%;" type="number" min="1" max="12" name="mes_nac" [(ngModel)]="mes_nac" placeholder="Mes">
                                                <input formControlName="ano_nac" id="ano_nac" style="width: 33%;" type="number" min="1900" name="ano_nac" [(ngModel)]="ano_nac" placeholder="Año">
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="!validarFecha()" class="mb-0 pb-0 mt-1"
                                        style="display: flex; align-items: center;"
                                        [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                        <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                        Selecciona en el calendario, la fecha, mes y año de tu nacimiento.
                                    </p>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-6 col-12"></div>
                                <div class="col-md-6 col-12" style="display: flex;
                                justify-content: flex-end;">
                                    <button class="btn float-sm-right next" type="button"
                                        (click)="signupStep(1)">Siguiente</button>
                                </div>
                            </div>

                        </div>
                        <!-- step 2 -->
                        <!-- Step 2 -->
                        <div *ngIf="step === 1" class="m-0 p-0 signup-step">
                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input"
                                    [ngClass]="{'focus-custom-input': signupEmailFocus}">
                                    <label *ngIf="signupEmailFocus || registerForm.get('email').value?.length"
                                        for="email" class="{{ signupEmailFocus ? 'blue' : 'gray' }}">
                                        Correo electrónico
                                    </label>
                                    <input class="form-control" type="email" formControlName="email" id="email"
                                        placeholder="Correo electrónico" onfocus="this.placeholder = '';"
                                        onblur="this.placeholder = 'Correo electrónico';" (focus)="signupEmailFocus=true;"
                                        (blur)="signupEmailFocus=false;">
                                </div>
                            </div>
                            <p *ngIf="campoNoValido('email')" class="mb-0 pb-0 mt-1"
                                style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes escribir un correo electrónico válido.
                            </p>
                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input" [ngClass]="{'focus-custom-input': signupTelefonoFocus}">
                                  <label *ngIf="signupTelefonoFocus || registerForm.get('telefono').value?.length" for="telefono" class="{{ signupTelefonoFocus ? 'blue' : 'gray' }}">
                                    Teléfono
                                  </label>
                                  <div class="input-group p-0">
                                    <div class="input-group-btn p-0 m-0">
                                      <button type="button" class="btn btn-secondary dropdown-toggle m-0 p-0" (click)="toggleDropdown()" aria-haspopup="true" aria-expanded="false" style="display: flex; align-items: center;">
                                        <span [class]="'fi ' + selectedAreaCode.cou_flag"></span>
                                        <p style="margin-left: 5px; margin-bottom: 0;">(+{{ selectedAreaCode.cou_phone_code }})</p>
                                        <mat-icon>expand_more</mat-icon>
                                      </button>
                                      <div class="dropdown-menu" [ngClass]="{'show': dropdownOpen}" (clickOutside)="closeDropdown()" style="width: auto !important;">
                                        <a class="dropdown-item" href="#" *ngFor="let areaCode of areaCodes" (click)="selectAreaCode(areaCode)">
                                          <span [class]="'fi ' + areaCode.cou_flag" style="margin-right: 10px;"></span> (+{{ areaCode.cou_phone_code }})
                                        </a>
                                      </div>
                                    </div>
                                    <input class="form-control" aria-label="Teléfono" type="tel" formControlName="telefono" id="telefono" placeholder="Teléfono"
                                      onfocus="this.placeholder = '';" onblur="this.placeholder = 'Teléfono';"
                                      (focus)="signupTelefonoFocus=true;" (blur)="signupTelefonoFocus=false;">
                                  </div>
                                </div>
                              </div>
                              <p *ngIf="campoNoValido('telefono')" class="mb-0 pb-0 mt-1" style="display: flex; align-items: center;" [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes ingresar entre {{selectedAreaCode?.cou_min_phone}} y {{selectedAreaCode?.cou_max_phone}} dígitos
                              </p>
                              
                              

                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupCountryFocus, 'signup-input-wrapper-addon-gray': !signupCountryFocus}">
                                <select id="pais" class="form-control" style="height: 100%;width: 100%;margin-right: 5px; border: none;"
                                    formControlName="pais">
                                    <option selected [value]="''">País</option>
                                    <option *ngFor="let pais of paises" [value]="pais.cou_id">{{ pais.cou_name }}
                                    </option>
                                </select>
                            </div>
                            <p *ngIf="campoNoValido('pais')" class="mb-0 pb-0 mt-1"
                                style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes seleccionar el País.
                            </p>

                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupRegionFocus, 'signup-input-wrapper-addon-gray': !signupRegionFocus}">
                                <select id="pais" class="form-control" style="height: 100%;width: 100%;margin-right: 5px; border: none;"
                                    formControlName="region">
                                    <option selected [value]="''">Región</option>
                                    <option *ngFor="let region of regiones" [value]="region.reg_id">{{ region.reg_name
                                        }}</option>
                                </select>
                            </div>
                            <p *ngIf="campoNoValido('region')" class="mb-0 pb-0 mt-1"
                                style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes seleccionar una Región.
                            </p>

                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupMunicipalityFocus, 'signup-input-wrapper-addon-gray': !signupMunicipalityFocus}">
                                <select id="pais" class="form-control" style="height: 100%;width: 100%;margin-right: 5px; border: none;"
                                    formControlName="comuna">
                                    <option selected [value]="''">Comuna</option>
                                    <option *ngFor="let comuna of comunas" [value]="comuna.com_id">{{ comuna.com_name }}
                                    </option>
                                </select>
                            </div>
                            <p *ngIf="campoNoValido('comuna')" class="mb-0 pb-0 mt-1"
                                style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes seleccionar una Comuna.
                            </p>

                            <div class="form-group row signup-input-wrapper">
                                <div class="col-12 m-0 signup-custom-input"
                                    [ngClass]="{'focus-custom-input': signupStreetFocus}">
                                    <label *ngIf="signupStreetFocus || registerForm.get('calle').value?.length"
                                        for="calle" class="{{ signupStreetFocus ? 'blue' : 'gray' }}">
                                        Calle
                                    </label>
                                    <input class="form-control" type="text" formControlName="calle" placeholder="Calle"
                                        onfocus="this.placeholder = '';" onblur="this.placeholder = 'Calle';"
                                        id="nombre" (focus)="signupStreetFocus=true;" (blur)="signupStreetFocus=false;">
                                </div>
                            </div>
                            <p *ngIf="campoNoValido('calle')" class="mb-0 pb-0 mt-1"
                                style="display: flex; align-items: center;"
                                [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                Debes escribir tu Calle.
                            </p>
                            <div class="form-row p-0 m-0">
                                <div class="col-12 col-sm-6 col-lg-6 p-0 m-0 pr-lg-3">
                                    <div class="form-group row signup-input-wrapper">
                                        <div class="col-12 m-0 signup-custom-input"
                                            [ngClass]="{'focus-custom-input': signupNumberAddressFocus}">
                                            <label
                                                *ngIf="signupNumberAddressFocus || registerForm.get('numeroCasa').value?.length"
                                                for="calle" class="{{ signupNumberAddressFocus ? 'blue' : 'gray' }}">
                                                Número
                                            </label>
                                            <input class="form-control" type="text" formControlName="numeroCasa"
                                                placeholder="Numero" onfocus="this.placeholder = '';"
                                                onblur="this.placeholder = 'Número';" id="nombre"
                                                (focus)="signupNumberAddressFocus=true;"
                                                (blur)="signupNumberAddressFocus=false;">
                                        </div>
                                    </div>
                                    <p *ngIf="campoNoValido('numeroCasa')" class="mb-0 pb-0 mt-1"
                                        style="display: flex; align-items: center;"
                                        [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                        <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                        Debes escribir el numero tu Calle.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-6 p-0 m-0">
                                    <div class="form-group row signup-input-wrapper">
                                        <div class="col-12 m-0 signup-custom-input"
                                            [ngClass]="{'focus-custom-input': signupNumberDepartmentFocus}">
                                            <label
                                                *ngIf="signupNumberDepartmentFocus || registerForm.get('rest').value?.length"
                                                for="calle" class="{{ signupNumberDepartmentFocus ? 'blue' : 'gray' }}">
                                                N° Depto/Oficina(Si aplica)
                                            </label>
                                            <input class="form-control" type="text" formControlName="rest"
                                                placeholder="N° Depto/Oficina(Si aplica)"
                                                onfocus="this.placeholder = '';"
                                                onblur="this.placeholder = 'N° Depto/Oficina(Si aplica)';" id="nombre"
                                                (focus)="signupNumberDepartmentFocus=true;"
                                                (blur)="signupNumberDepartmentFocus=false;">
                                        </div>
                                    </div>
                                    <p *ngIf="campoNoValido('rest')" class="mb-0 pb-0 mt-1"
                                        style="display: flex; align-items: center;"
                                        [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                        <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                        Debes escribir el numero tu Calle.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12" style="display: flex;
                                justify-content: flex-start;">
                                    <button class="btn float-sm-right prev" (click)="signupStep(0)">Volver</button>
                                </div>
                                <div class="col-md-6 col-12" style="display: flex;
                                justify-content: flex-end;">
                                    <button class="btn float-sm-right next" (click)="signupStep(2)">Siguiente</button>
                                </div>
                            </div>
                        </div>

                        <!-- step 3 -->

                        <div *ngIf="step === 2" class="m-0 p-0 signup-step">
                            <!-- password 1 -->
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass1Focus, 'signup-input-wrapper-addon-gray': !signupPass1Focus}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <label *ngIf="signupPass1Focus || registerForm.get('pass1').value?.length"
                                        for="pass1" class="{{ signupPass1Focus ? 'blue' : 'gray' }}">
                                        Contraseña
                                    </label>
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType1 ? 'text' : 'password'"
                                            formControlName="pass1" id="pass1" name="pass1" placeholder="Contraseña"
                                            onfocus="this.placeholder = '';" onblur="this.placeholder = 'Contraseña';"
                                            (focus)="signupPass1Focus=true;" (blur)="signupPass1Focus=false;"
                                            (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center">
                                    <span class="calendar" (click)="toggleFieldTextType1()">
                                        <mat-icon>{{ fieldTextType1 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>
                            <div class="password-desc">
                                <p class="{{ passLength === 0 && !formSubmitted ? 
                                    'default' : 
                                    passMinLength ? 
                                    'ok' : 
                                    'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passMinLength ? 'check_circle' : 'cancel'
                                        }}</mat-icon>
                                    Debe contener al menos 8 caracteres
                                  </p>
                                  
                                <p class="{{ passLength === 0 && !formSubmitted ?
                                    'default' :
                                    passCapital ?
                                    'ok' :
                                    'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passCapital ? 'check_circle' : 'cancel' }}</mat-icon>

                                    Debe contener al menos 1 mayúscula
                                </p>
                                <p class="{{ passLength === 0 && !formSubmitted ?
                                    'default' :
                                    passLetter ?
                                    'ok' :
                                    'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passLetter ? 'check_circle' : 'cancel' }}</mat-icon>
                                    Debe contener al menos 1 minúscula
                                </p>
                                <p class="{{ passLength === 0 && !formSubmitted ?
                                    'default' :
                                    passNumber ?
                                    'ok' :
                                    'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passNumber ? 'check_circle' : 'cancel' }}</mat-icon>
                                    Debe contener al menos 1 numero
                                </p>
                            </div>
                            <!-- password 2 -->
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass2Focus, 'signup-input-wrapper-addon-gray': !signupPass2Focus}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <label *ngIf="signupPass2Focus || registerForm.get('pass2').value?.length"
                                        for="pass2" class="{{ signupPass2Focus ? 'blue' : 'gray' }}">
                                        Contraseña
                                    </label>
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType2 ? 'text' : 'password'"
                                            formControlName="pass2" id="pass2" name="pass2" placeholder="Contraseña"
                                            onfocus="this.placeholder = '';" onblur="this.placeholder = 'Contraseña';"
                                            (focus)="signupPass2Focus=true;" (blur)="signupPass2Focus=false;">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 align-self-center">
                                    <span class="calendar" (click)="toggleFieldTextType2()">
                                        <mat-icon>{{ fieldTextType2 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>
                            <p *ngIf="registerForm.hasError('noEsIgual')" class="mb-0 pb-0 mt-1 field-error"
                                style="display: flex; align-items: center;">
                                <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                Debes ingresar la misma contraseña del campo anterior.
                            </p>
                            <div class="row">
                                <div class="form-group signup-input-wrapper col-md-8 col-12" style="display: flex;
                                flex-direction: column;
                                align-items: baseline;">
                                    <div class="col-12 m-0 signup-custom-input"
                                        [ngClass]="{'focus-custom-input': signupcodVerificationFocus}">
                                        <label
                                            *ngIf="signupcodVerificationFocus || registerForm.get('codigo_verificacion').value?.length"
                                            for="codigo_verificacion"
                                            class="{{ signupcodVerificationFocus ? 'blue' : 'gray' }}">
                                            Código de Verificación
                                        </label>
                                        <input class="form-control" type="text" formControlName="codigo_verificacion"
                                            id="codigo_verificacion" placeholder="Código de Verificación"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Código de Verificación';"
                                            (focus)="signupcodVerificationFocus=true;"
                                            (blur)="signupcodVerificationFocus=false;">
                                    </div>
                                    <p *ngIf="campoNoValido('codigo_verificacion')" class="mb-0 pb-0 mt-1"
                                        style="display: flex; align-items: center;"
                                        [ngClass]="{'field-help': !step1Flag, 'field-error': step1Flag}">
                                        <mat-icon style="font-size: 15px;" *ngIf="step1Flag">cancel</mat-icon>
                                        Código de verificación no valido
                                    </p>
                                </div>
                                <div class="col-md-4 col-12" style="    margin-top: 16px;
                                display: flex;
                                align-items: center;">
                                    <div class="col-12">
                                        <button class="btn float-sm-right next" id="requestCodeButton"
                                            style="margin: 0 !important;" type="button" (click)="
                                        requestCode()">Solicitar codigo</button>
                                    </div>

                                </div>
                            </div>


                            <div class=" row m-0 p-0">
                                <div class="form-group form-check checkbox">
                                    <input id="chckb-allow-emailing" type="checkbox"
                                        class="form-check-input filled-in chk-col-blue-a" name="chckb-allow-emailing"
                                        formControlName="notificaciones">
                                    <label for="chckb-allow-emailing" class="form-check-label">Deseo recibir información
                                        y promociones de Almamedis.</label>
                                </div>
                                <div class="form-group form-check checkbox">
                                    <input id="checkbox-signup" type="checkbox"
                                        class="form-check-input filled-in chk-col-blue-a" name="checkbox-signup"
                                        formControlName="terminos">
                                    <label for="checkbox-signup" class="form-check-label">Acepto los términos y
                                        condiciones.</label><a class="pointer" data-toggle="modal"
                                        data-target="#terminosycondiciones"> (ver más)</a>
                                </div>
                                <div class="">
                                    <p *ngIf="aceptaTerminos() && this.formSubmittedAux " style="color:red; margin-left: 20px; display: flex; align-items: center;">
                                      <mat-icon style="color: red;">cancel</mat-icon>
                                      Debes aceptar los términos y condiciones para crear tu cuenta
                                    </p>
                                  </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12" style="display: flex;
                                justify-content: flex-start;">
                                    <button class="btn float-sm-right prev" type="button"
                                        (click)="goBack()">Volver</button>
                                </div>
                                <div class="col-md-6 col-12" style="display: flex;
                                justify-content: flex-end;">
                                    <button class="btn float-sm-right next" type="submit">Crear cuenta</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Register modal modal -->

<!-- Register modal success - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="registerSuccessModal" tabindex="-1" role="dialog"
    aria-labelledby="registerSuccessModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 p-0">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/face-happy.svg" alt="Responsive image">
                    <p class="m-0 px-5">¡Tu cuenta ha sido creada con éxito!</p>
                    <button class="btn" type="button" data-dismiss="modal" aria-label="Close">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>
<button #trigRegSuccModal type="button" class="btn d-none" data-toggle="modal" data-target="#registerSuccessModal">
    aux trigger for register success modal
</button>
<!-- ============================================================== -->
<!-- End Register modal success modal -->

<!-- Register modal error - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="registerErrorModal" tabindex="-1" role="dialog" aria-labelledby="registerErrorModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 p-0">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/face-sad.svg" alt="Responsive image">
                    <div class="m-0 px-1 px-sm-5">
                        <p class="m-0 p-0">Lo sentimos, tuvimos un problema para crear tu cuenta.</p>
                        <p class="m-0 p-0">{{message_error}}</p>
                        <br>
                        <p class="m-0 p-0">Por favor, vuelve a intentar.</p>
                    </div>
                    <button class="btn" type="button" data-dismiss="modal" aria-label="Close">Volver a intentar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<button #trigRegErrorModal type="button" class="btn d-none" data-toggle="modal" data-target="#registerErrorModal">
    aux trigger for register success modal
</button>
<!-- ============================================================== -->
<!-- End Register modal error -->
<!-- Register modal error - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="verificationErrorModal" tabindex="-1" role="dialog" aria-labelledby="verificationErrorModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 p-0">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/face-sad.svg" alt="Responsive image">
                    <div class="m-0 px-1 px-sm-5">
                        <p class="m-0 p-0">{{message_error}}</p>
                        <br>
                        <p class="m-0 p-0">Por favor, vuelve a intentar.</p>
                    </div>
                    <button class="btn" type="button" data-dismiss="modal" aria-label="Close">Volver a intentar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<button #trigInvalidVerificationCodeModal type="button" class="btn d-none" data-toggle="modal" data-target="#verificationErrorModal">
    aux trigger for register success modal
</button>
<!-- ============================================================== -->
<!-- End Register modal error -->

<!-- Modal TERMINOS Y CONDICIONES -->
<div class="modal fade" id="terminosycondiciones" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-card">
        <div class="modal-content">
            <div class="modal-header">
                <h2>Terminos y condiciones del servicio</h2>
            </div>
            <div class="modal-body modal-body-card">
                <p style="font-weight: bold;text-align: center;">TÉRMINOS Y CONDICIONES DE USO DE PLATAFORMA DE
                    TELEMEDICINA ALMAMEDIS.</p>
                <p style="font-weight: bold;text-align: center;">NO UTILICE ESTE SITIO WEB EN CASO DE UNA EMERGENCIA O
                    URGENCIA MEDICAS</p>
                <p style="font-weight: bold;text-align: center;">SI TIENE UNA EMERGENCIA O URGENCIA DE SALUD DIRIJASE DE
                    INMEDIATO A UN CENTRO DE ATENCIÓN HOSPITALARIA O CLINICA.</p>
                <br>
                <p style="font-weight: bold;">BIENVENIDO</p>
                <p class="pTerminosCondiciones">Usted está accediendo a esta plataforma para poder hacer uso del
                    servicio de asistencia de telemedicina, de acuerdo a lo dispuesto en su seguro contratado con CHUBB
                    SEGUROS S.A. (“CHUBB”). Para estos efectos CHUBB ha contratado los servicios de la asistencia de
                    telemedicina con el proveedor Almamedis, quien ha dispuesto los Términos y Condiciones para el uso
                    de este sitio web, así como de los servicios y las herramientas provistas a través de éste, lo que
                    está condicionado a la aceptación por parte de usted de los términos de uso descritos a
                    continuación.</p>
                <p class="pTerminosCondiciones">El acceso y uso de este sitio web se rige por los Términos y Condiciones
                    descritos a continuación, así como por la legislación que se aplique en la República de Chile a los
                    mismos. En consecuencia, todas las visitas y todos los servicios que se realicen en este sitio, como
                    asimismo sus efectos jurídicos, quedarán regidos por estas condiciones o reglamento y sometidas a
                    esa legislación.</p>
                <p class="pTerminosCondiciones">Este documento describe los términos, condiciones generales y las
                    condiciones particulares, en adelante ("Términos y Condiciones” aplicables al uso de los servicios
                    ofrecidos y/o provistos por Almamedis), dentro del sitio web https://chubb.almamedis.cl, incluidas
                    las herramientas provistas a través del mismo para la realización de video-consultas a distancia e
                    intercambio de información y/o datos personales, en adelante también referidos como “Servicios”.</p>
                <p class="pTerminosCondiciones">Cualquier persona que desee usar el Sitio o los Servicios podrá hacerlo
                    previa aceptación de los Términos y Condiciones acá descritos.</p>
                <br>

                <p style="font-weight: bold;">CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES
                    TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS
                    SERVICIOS ASOCIADOS AL MISMO.</p>
                <p class="pTerminosCondiciones">Usted debe leer, entender y aceptar todas las condiciones establecidas
                    en los Términos y Condiciones, previo a su inscripción como Usuario de Almamedis.</p>
                <p class="pTerminosCondiciones">Debe entender que las interacciones con los proveedores de servicios de
                    consulta médica a través de Almamedis son un complemento de la relación personal que pueda tener con
                    un profesional de la salud. Por consiguiente, los servicios ofrecidos por Almamedis no están
                    necesariamente destinados a sustituir los exámenes físicos o controles regulares de salud que puede
                    realizar o le aconseje un profesional de salud.</p>
                <br>

                <p style="font-weight: bold;">INDEPENDENCIA DE CRITERIO PROFESIONAL</p>
                <p class="pTerminosCondiciones">Usted entiende y acepta que Almamedis presta sus servicios vía
                    plataforma web, para atenciones de salud, las que son efectuadas bajo el conocimiento y criterio del
                    profesional respectivo. </p>
                <p class="pTerminosCondiciones">Todos los profesionales de la salud que se encuentran disponibles para
                    consultas “on line”, ofrecen sus servicios en el libre ejercicio de su profesión y utilizan
                    Almamedis como un medio para comunicarse con Usted o su beneficiario.</p>
                <p class="pTerminosCondiciones">Cualquier información, recomendación, indicación o diagnóstico recibido
                    de un “Profesional de la salud” a través de Almamedis, proviene exclusivamente de aquel, no pudiendo
                    Almamedis intervenir en el acto profesional mismo, ya sea que se trate de diagnóstico, medicación,
                    tratamiento u otros. Sin perjuicio de ello, Almamedis, es responsable de la elección de los
                    profesionales que acceden a la plataforma y de chequear sus títulos o habilitaciones profesionales,
                    de manera que quienes prestan su servicio mediante la plataforma Almamedis sean profesionales
                    habilitados en Chile para el ejercicio de la profesión médica. </p>
                <br>

                <p style="font-weight: bold;">SEGURIDAD Y FUNCIONAMIENTO DE LA PLATAFORMA Y EL SERVICIO</p>
                <p class="pTerminosCondiciones">Queda establecido que Almamedis implementará y ejecutará todos los
                    procedimientos y estándares de seguridad de la información que adhieren a la norma ISO27001, de modo
                    de proteger su privacidad y limitar el riesgo de pérdida de información. </p>
                <p class="pTerminosCondiciones">Usted conoce y acepta que para el adecuado funcionamiento de la
                    plataforma Almamedis debe contar con una conexión a internet de banda ancha estable y un dispositivo
                    electrónico con las características técnicas suficientes para reproducir una videoconferencia.
                    Almamedis no se responsabiliza de una interrupción en el servicio a causa de fallas en la conexión
                    que provengan de la calidad de la banda ancha y/o de los equipos electrónicos de su propiedad.</p>
                <br>

                <p style="font-weight: bold;">SELECCIÓN DE PROFESIONALES MÉDICOS DISPONIBLES</p>
                <p class="pTerminosCondiciones">Cuando el Paciente o “Usuario” solicita una consulta para obtener la
                    atención de un “Profesional de la salud”, Almamedis le ofrecerá la opción de consultar con cualquier
                    profesional independiente afiliado a nuestra red, que se encuentre disponible para atenderlo. Para
                    su tranquilidad, Almamedis selecciona a profesionales de la salud que cumplen con los requisitos que
                    la legislación vigente en nuestro país para proveer los servicios que ofrecen.</p>
                <p class="pTerminosCondiciones">Cuando Usted solicita una consulta, está aceptando específicamente que
                    el “Profesional de la salud” que Usted ha seleccionado podrá, desde ese momento, acceder a su
                    historial clínico que se encuentre disponible en la plataforma. Igualmente, al solicitar una
                    consulta, usted está dando inicio a una relación directa “Profesional de la salud” – “Usuario”, a
                    través de la plataforma de telemedicina de Almamedis.</p>
                <br>

                <p style="font-weight: bold;">REGISTRO COMO USUARIO</p>
                <p class="pTerminosCondiciones">Para tener acceso a los servicios en línea, deberá registrarse como
                    “Usuario” y deberá crear una cuenta personal, suministrando la información que se solicita. Con la
                    excepción de las cuentas de sus asegurados adicionales cuando corresponda, Usted acuerda no crear
                    más de una cuenta de usuario, ni crear una cuenta para otra persona, si no ha sido autorizado de
                    manera expresa y escrita. Asimismo, Usted se compromete a proveer información correcta, verdadera y
                    actualizada al momento de registrarse o cuando realice la actualización de su ficha de información
                    personal. </p>
                <br>

                <p style="font-weight: bold;">SEGURIDAD</p>
                <p class="pTerminosCondiciones">Al registrarse, deberá ingresar sus datos y una clave de acceso (la cual
                    podrá modificar regularmente). Estos datos son personales e intransferibles y usted es responsable
                    de mantener la seguridad de su cuenta, cuidando en todo momento la seguridad y confidencialidad de
                    sus claves de acceso. Usted entiende y acepta que en ningún caso podrá facilitar el acceso de
                    terceros a Almamedis mediante el uso de su nombre de usuario y contraseña. </p>
                <br>

                <p style="font-weight: bold;">POLÍTICA DE PRIVACIDAD</p>
                <p class="pTerminosCondiciones">Almamedis garantiza la confidencialidad “Profesional de la Salud” –
                    “Usuario”. Toda información que relacione su identidad con las condiciones físicas y mentales
                    presentes, pasadas o futuras y su historia de salud, es considerada información de carácter privado
                    y será tratada como tal. </p>
                <p class="pTerminosCondiciones">Como parte de su proceso operativo, Almamedis registra su información
                    personal y se obliga a no cederla, licenciarla o compartirla fuera de Almamedis ni a sus entidades
                    asociadas. Con la aceptación de estos Términos y Condiciones, usted nos autoriza desde ya en forma
                    expresa a almacenar y emplear dicha información para permitir que Almamedis provea los servicios de
                    telemedicina.</p>
                <p class="pTerminosCondiciones">El usuario gozará de todos los derechos que le reconoce la Ley Nº19.628
                    sobre protección de datos y la ley del consumidor en Chile. </p>
                <br>

                <p style="font-weight: bold;">RESPONSABILIDAD</p>
                <p class="pTerminosCondiciones">Almamedis es responsable de contar con una red de profesionales de salud
                    idóneos que cumplan con la normativa vigente para ejercer su profesión en Chile.</p>
                <br>

                <p style="font-weight: bold;">COOKIES</p>
                <p class="pTerminosCondiciones">Como la mayoría de los sitios web, utilizamos "cookies" y archivos de
                    registro web para analizar el uso del sitio, para mejorar la calidad de nuestro servicio y con la
                    finalidad de personalizar en la medida de lo posible su experiencia en nuestro sitio web. Una cookie
                    es un archivo de datos que reside en su computadora, teléfono inteligente, tableta o cualquier otro
                    dispositivo desde el que ingrese y permite su reconocimiento como usuario cuando regrese a nuestro
                    sitio web desde el mismo equipo, siempre que utilice el mismo navegador.</p>
                <p class="pTerminosCondiciones">Usted tiene el derecho de eliminar o bloquear las cookies de nuestro
                    sitio web utilizando la configuración de su navegador, pero esto podría afectar su capacidad para
                    ingresar o utilizar nuestros servicios.</p>
                <br>

                <p style="font-weight: bold;">PROPIEDAD INTELECTUAL E INDUSTRIAL - ENLACES</p>
                <p class="pTerminosCondiciones">Todos los derechos de propiedad industrial e intelectual de este sitio,
                    así como de los elementos contenidos en el mismo (incluyendo con carácter enunciativo pero no
                    limitativo, marcas, signos distintivos, nombres comerciales, dominios, imágenes, fotografías,
                    sonido, audio, video, diseño gráfico, código fuente, logos, contenidos, textos, gráficos,
                    ilustraciones, bases de datos y demás elementos) son de la propiedad exclusiva de Almamedis, quien
                    se reserva expresamente todos los derechos derivados del mismo.</p>
                <p class="pTerminosCondiciones">Los contenidos de las pantallas relativas a los servicios de Almamedis,
                    así como también los programas, bases de datos, redes y/o archivos que permitan al usuario acceder y
                    usar su cuenta, son propiedad de Almamedis y están protegidos por las leyes y los tratados
                    internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales.</p>
                <p class="pTerminosCondiciones">El Sitio puede contener enlaces a otros sitios web, lo cual no indica
                    que sean propiedad u operados por Almamedis. En virtud de que Almamedis no tiene control sobre tales
                    sitios, no será responsable por los contenidos, materiales, acciones y/o servicios prestados por los
                    mismos, ni por eventuales daños o pérdidas ocasionadas por la utilización de éstos, sean causadas
                    directa o indirectamente. La existencia de enlaces a otros sitios web no implica una sociedad,
                    relación, aprobación, respaldo de Almamedis a dichos sitios y/o su contenido.</p>
                <p class="pTerminosCondiciones">Queda expresamente prohibida la reproducción, distribución o difusión de
                    la totalidad o parte de los contenidos de este sitio con fines comerciales, sin la autorización
                    expresa y escrita de Almamedis. El uso indebido y la reproducción total o parcial de dichos
                    contenidos quedan prohibidos, salvo autorización expresa y por escrito de Almamedis.</p>
                <p class="pTerminosCondiciones">El Usuario se compromete a respetar los derechos de propiedad
                    Intelectual e Industrial de Almamedis y deberá abstenerse de suprimir, alterar, eludir o manipular
                    cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en este sitio
                    web.</p>
                <br>

                <p style="font-weight: bold;">MODIFICACIONES</p>
                <p class="pTerminosCondiciones">Almamedis se reserva el derecho de efectuar sin previo aviso las
                    modificaciones y/o mejoras que considere oportunas en este sitio web, o en sus aplicaciones,
                    pudiendo cambiar, suprimir o añadir enlaces, contenidos y servicios que se prestan a través del
                    mismo y/o la forma en que aparezcan presentados.</p>
                <br>

                <p style="font-weight: bold;">DERECHO DE EXCLUSIÓN</p>
                <p class="pTerminosCondiciones">Almamedis se reserva el derecho de denegar o retirar el acceso a su
                    sitio web y/o servicios ofrecidos a cualquier usuario que incumpla las condiciones estipuladas en
                    estos Términos y Condiciones. El uso indebido o malicioso será notificado al Usuario. </p>
                <br>

                <p style="font-weight: bold;">MODIFICACIONES DEL PRESENTE AVISO LEGAL</p>
                <p class="pTerminosCondiciones">Almamedis se reserva el derecho a modificar, desarrollar o actualizar en
                    cualquier momento, con previa notificación, los Términos y Condiciones de este sitio web. El Usuario
                    deberá aceptar los Términos y Condiciones que se hallen vigentes en el momento en que acceda y haga
                    uso de los servicios.</p>
                <br>

                <p style="font-weight: bold;">DOMICILIO</p>
                <p class="pTerminosCondiciones">Se fija como domicilio de Almamedis: Huérfanos #865, of. 1603, comuna y
                    ciudad de Santiago, República de Chile.</p>
                <p class="pTerminosCondiciones">Si Usted tiene alguna duda sobre los Términos y Condiciones que rigen el
                    Sitio o sobre los Servicios ofrecidos, deberá comunicarse con nuestro equipo de Atención al Cliente
                    (contacto@almamedis.cl) para resolver dicha duda.</p>
                <br>

                <p style="font-weight: bold;">NOTIFICACIONES</p>
                <p class="pTerminosCondiciones">Almamedis utilizará diferentes medios de comunicación para notificar el
                    resultado de ciertas acciones, procesos o procedimientos realizados por usted o de manera automática
                    a nuestros sistemas. Dentro de estos canales de comunicación se encuentran los canales telefónicos,
                    correo electrónico, mensajes de texto, mensajes instantáneos por la aplicación de mensajería
                    WhatsApp, notificaciones de escritorio en los navegadores y notificaciones push provenientes de
                    nuestra aplicación. Por la aceptación de los Términos y Condiciones, usted acepta recibir estas
                    notificaciones en la línea telefónica, aplicaciones y cuentas que registre como propias en nuestra
                    aplicación.</p>
                <br>

                <p style="font-weight: bold;">ACEPTACIÓN Y AUTORIZACIÓN</p>
                <p class="pTerminosCondiciones">Usted manifiesta que ha leído con detenimiento este documento, se obliga
                    a cumplir con lo aquí estipulado, acepta expresamente todas las disposiciones en él contenidas y
                    cumple con otorgar la autorización correspondiente en favor de Almamedis por el uso de esta
                    plataforma y en beneficio de los Profesionales de la Salud, para los procedimientos y/o consultas
                    que obtenga a través de la misma. </p>
                <p class="pTerminosCondiciones">El Usuario entiende que la aceptación de los Términos y Condiciones aquí
                    contenidos, es equivalente a un contrato escrito y con este protocolo se da cumplimiento expreso a
                    la legislación nacional vigente.</p>
                <p></p>
                <br>
                <br>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Volver</button>
            </div>
        </div>
    </div>
</div>