import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';
import { AgreementService } from 'src/app/services/agreement.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-otra-prestacion',
  templateUrl: './otra-prestacion.component.html',
  styleUrls: ['./otra-prestacion.component.css']
})
export class OtraPrestacionComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private agreementService: AgreementService,
    private generalServices: GeneralService,
    private router: Router,
  ) { }
  URL_DOCS = URL_DOCS;
  agreement:any = {};
  benefits: any[] = [];
  agreements: any[] = [];
  // agreement = {};
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((parametros: any) => {
      if (parametros.agre_typ_id_) {
        this.getAgreement({ agre_typ_id_: parametros.agre_typ_id_ });
      }
    });
  }

  getAgreement({ agre_typ_id_ = '' }) {
    this.generalServices.modalCargando();
    this.agreementService.agreementTypes({ agre_typ_id_ })
      .then((result: any) => {
        // console.log(result);
        if (result.agreementsTypes && result.agreementsTypes.length > 0) {
          this.agreement = result.agreementsTypes[0];
        }
        if (result.benefits) {
          this.benefits = result.benefits;
        }
        if (result.agreements) {
          this.agreements = result.agreements;
        }
        // console.log(this.agreement);
        // console.log(this.benefits);
        // console.log(this.agreements);
        Swal.close();
      })
      .catch(error => {

      })
  }

  volver() {

    let url = '/otras-prestraciones';
    this.router.navigateByUrl(url);
  }
}
