<div style="margin-left: 5px;">
  <div class="row">
    <div class="col-md-12 titulobeneficiario">
      Selecciona el día que quieres visualizar
    </div>
  </div>
  <app-agenda-medico2 class="" 
    [vistaProximasConsultas] = "true"
    (messageEvent)='clickHourAgendaMedico($event)'
    (messageMonth)='clickMonthCalendar($event)'>
  </app-agenda-medico2>
</div>


<!-- Modales --> 
<!-- Información de la consulta -->
<div class="modal fade" id="verHourModal2" tabindex="-1" role="dialog" aria-labelledby="verHourModalLabel2" aria-hidden="true" >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header mx-auto">
          <h4 class="modal-title titulo-modal" id="verHourModalLabel2">Datos de la reserva</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li>Número consulta: <strong>{{data_reserva.id_consulta}}</strong></li>
            <li>Nombre paciente: <strong>{{data_reserva.name_ben}}</strong></li>
            <li>Rut paciente: <strong>{{data_reserva.getAppointment.ben_use_rut}}</strong></li>
            <li>Fecha de nacimiento: <strong>{{data_reserva.getAppointment.ben_use_birthday}}</strong></li>
            <li>Email paciente: <strong>{{data_reserva.use_email}}</strong></li>
            <li>Telefono paciente: <strong>{{data_reserva.use_phone}}</strong></li>
            <li>Motivo consulta: <strong>{{data_reserva.name_reason}}</strong></li>
            <li>Doctor: <strong>{{data_reserva.name_doc}}</strong></li>
            <li>Email doctor: <strong>{{data_reserva.doc_email}}</strong></li>
            <li>Telefono doctor: <strong>{{data_reserva.doc_phone}}</strong></li>
            <li>Especialidad: <strong>{{data_reserva.name_area}}</strong></li>
            <li>Día consulta: <strong>{{data_reserva.name_day}}</strong></li>
            <li>Hora consulta: <strong>{{data_reserva.name_hour}}</strong></li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#cancelAppoModal2" *ngIf="data_reserva.cancelable">Cancelar Consulta</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade cancelarAppoModal" id="cancelAppoModal2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 style="text-align: center;" >Anulación de consulta</h2>
        </div>
        <div class="modal-body">
          <div class="card p-0 m-0" [ngClass]="{'border-confirm': data_reserva.confirmFlag, 'border-go-lobby': data_reserva.goLobbyFlag}">
            <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
              <div class="m-0 p-0 first-line">
                <app-profile-img 
                  [img]="data_reserva.consulta.att_path" >
                </app-profile-img>
                <div class="ml-3 align-self-center">
                  <p class="card-text m-0 p-0 appt-date">{{ data_reserva.date[0] }} {{ data_reserva.date[1] }}</p>
                  <p class="card-text pt-3 mr-5 doc-data">{{ data_reserva.doctorName }} {{ data_reserva.doctorLastName }} - {{ data_reserva.spec }} </p>
                  <p class="card-text m-0 p-0 doc-data">Nº de reserva: {{ data_reserva.id_consulta}} </p>
                </div>
              </div>
            </div>
          </div>
          <br>
          <select name="cancel_by" id="cancel_by" style="padding-top: 10px;"class="form-control camposModalAgregar" [(ngModel)]="cancel_by" >
            <option value="PACIENTE">Paciente</option>
            <option value="MEDICO">Médico</option>
            <option value="COORDINADOR">Coordinador</option>
            <option value="CONTACT CENTER">Contact Center</option>
          </select>
          <br><br>
          <textarea class="form-control" rows="4" style="padding-top: 10px;" name="cancel_reason" [(ngModel)]="cancel_reason" id="cancel_reason" placeholder="Motivo de anulación"></textarea>
          <div class="text-danger" *ngIf="(cancel_reason.length < 4)">
            <p><mat-icon>highlight_off</mat-icon> Debes ingresar un motivo de anulación</p>
          </div>
          
        </div>
        <div class="modal-footer">
          <div class="row" style="width: 100%;">
            <div class="col-md-3" style="height: 40px;float: left;">
              <button type="button" class="btn btn-volver" style="float: left;width: 100%;" data-dismiss="modal">
                <div style="margin: auto;">
                  Cerrar formulario
                </div>
              </button>
            </div>
            <div class="col-md-6 d-none d-sm-block" ></div>
            <div class="col-md-3" style="height: 40px;float: right;">
              <button class="btn btn-confirmar" data-dismiss="modal" style="float: right;width: 100%;" (click)="confirmarCancelAppo()" [disabled]="(cancel_reason.length < 4)">
                <div style="margin: auto;">
                  Anular consulta
                </div>
              </button>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </div>
  

  <button #cerrarVerModal type="button" class="btn d-none" data-toggle="modal" data-target="#verHourModal2">
    aux trigger for login loading modal
  </button>

  <button #cerrarCancelAppoModal type="button" class="btn d-none" data-toggle="modal" data-target="#cancelAppoModal2">
    aux trigger for login loading modal
  </button>
