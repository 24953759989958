<div *ngIf="usuario.typ_name == 'PACIENTE'" class="col-md-12"> <!--  *ngIf="tipo_usuario_name=='PACIENTE'" -->
     <br>
     <div class="minititulo">
          Mi agenda
     </div>
     <div class="titulo">
          Reserva de hora
     </div>
    <br>
 </div>

<ng-container *ngIf="infoTransaction && infoTransaction.id_consulta" >
  <div class="col-12 mt-4 sm-7 text-sm-left container-subtitle-bar">
      <div class="row">
        <div
          class="col-8 col-md-8 col-sm-7">
          <h3 class="text-select-date">{{ subtitle }}</h3>
        </div>
    
      </div>
    </div>
 
 <div class="col-12 mt-sm-4 text-sm-left ml-3">
 
      <div class="row container-success-message p-3">
        <div class="col-12 col-sm-12 text-center mt-3">
          <img src="./assets/images/reserva.png" alt="imagen del medico" class="image-doctor">
        </div>
        <div class="col-12 col-sm-12 text-center mt-3">
          <h3 class="title">¡Tu reserva ha sido generada con éxito!</h3>
        </div>
        <div class="col-12 col-sm-12 text-center mt-3">
          <h3 class="titleReserva">Nº de reserva: {{infoTransaction.id_consulta}}</h3>
        </div>
        <div class="col-12 col-sm-12 text-center">
          <small>Se ha enviado el detalle de la reserva a tu correo electrónico.</small>
        </div>
        <div class="col-12 col-sm-12 text-center mt-5">
           <button type="submit" class="btn btn-primary btn-available" routerLink="/dashboard" >
                Ir al Inicio
           </button>
        </div>
      </div>
    </div>
</ng-container>
