import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-incrementador',
  templateUrl: './incrementador.component.html',
  styles: []
})
export class IncrementadorComponent implements OnInit{

  ngOnInit() {
    this.btnClass = `btn ${ this.btnClass }`;
  }

  //ocupo el @INPUT cuando quiero recibir un valor desde el padre!
  //@Input() progreso:number = 50;
  //lo que hace es recibir del padre el atributo "valor" que luego lo setea a "progreso" en este modulo
  @Input('valor') progreso:number = 50;
  @Input() btnClass:string = 'btn btn-primary' ;

  //para emitir un valor resultado de un proceso ocupo el IOTPUT
  @Output() valorSalida: EventEmitter<number> = new EventEmitter();//lo inicializo para que no sea null o undifined

  /*get getPorcentaje() {
    return `${ this.progreso }%`;
  }*/

  cambiarValor( valor:number){

    if( this.progreso >= 100 && valor >= 0){
      this.valorSalida.emit(100);
      this.progreso = 100;
    }else{
      if( this.progreso <=0 && valor < 0){
        this.valorSalida.emit(0);
        this.progreso = 0;
      }
      else{
        this.progreso = this.progreso + valor;
        this.valorSalida.emit(this.progreso);
      }
    }

  }

  onChange( nuevoValor:number){
    ////console.log("hey!!!");
    ////console.log(event);

    if( nuevoValor >= 100){
      this.progreso = 100;
    }else{
      if (nuevoValor <= 0){
        this.progreso = 0;
      }else{
        this.progreso = nuevoValor;
      }
    }
    this.valorSalida.emit(this.progreso);

  }
}
