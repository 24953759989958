

<div class="col-12 col-sm-12 container-hours" style="margin-top: 20px; padding-left: 0;">
  <div class="row row-cols-1 row-cols-sm-2 p-sm-0 m-sm-0">

    <div class="col-12 col-sm mr-sm-3 container-calendar" style="padding-left: 0;">
      <app-calendar-month
        (messageEvent)='clickDayCalendar($event)' 
        (messageMonth)='clickMonthCalendar($event)'>
      </app-calendar-month>
    </div>

    <div class="col-12 col-sm" style="padding-left: 0;" style="margin-top: 0"><!-- container-shadow  -->
      <div *ngIf="dia_seleccionado" class="row p-sm-3 list-hours">
        <div class="col-sm-12 mb-sm-4">
          <div class="row row-cols-sm-2 pt-3 pb-3">
            <div class="col-6 col-sm-6">
              <span class="available-hours-title">Horas disponibles</span>
            </div>
            <div class="col-6 col-sm-6 text-right">
              <span class="date">{{ daySelected.dayTitle }}</span>
            </div>
          </div>
        </div>

        <div class="not-hours" *ngIf="(daySelected.available == false)">
          <div class="d-flex">
            <mat-icon>info</mat-icon>
            <span>No existen horas disponibles para este día.</span>
          </div>
        </div>

        <div class="col-12">
          <div class="row row-cols-4 mb-sm-3" *ngIf="daySelected.available">
            <div class="col-3 col-sm-3 mb-sm-2 pb-2 text-sm-center text-left" *ngFor="let itemHour of horasAuxiliares">
              <button (click)="clickHour( itemHour )" *ngIf="(itemHour.existe)"
                [ngClass]="(itemHour.available == true)? 'btn hour-btn-habilitada': 'btn hour-btn-bloqueada'">  
                {{ itemHour.from }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Llamado desde mis próximas consultas médico -->


  </div>
</div> 