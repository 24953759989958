import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-plans-component',
  templateUrl: './admin-plans.component.html',
  styleUrls: ['./admin-plans.component.css']
})
export class AdminPlansComponent implements OnInit {
  @ViewChild('deleteCustomerBenTri') deleteCustomerBenTri: ElementRef;
  @ViewChild('seeCustomerBenTri') seeCustomerBenTri: ElementRef;
  @ViewChild('addSpeModal') addSpeModal: ElementRef;
  @ViewChild('addMediaModal') addMediaModal: ElementRef;

  cBenefitsTypes: any[] = [];
  cBenefits: any[] = [];
  customers: any[] = [];
  specialityList: any[] = [];
  editCustomerBenSelected: any = {
    cus_ben_id: '',
    cus_ben_name: '',
    cus_ben_cus_id: '',
    cus_ben_cus_name: '',
    cus_ben_qty_beneficiaries: '',
    cus_ben_code: '',
    cus_ben_cus_ben_typ_id: '',
    cus_ben_cus_ben_typ_name: null,
    cus_ben_description: '',
    cus_ben_phone: '',
    cus_ben_text_welcome: '',
    cus_ben_particular: '',
    cus_ben_image: null,
    cus_ben_media: [],
    new_cus_ben_media: [],
    cus_ben_specialities: [],
    new_cus_ben_specialities: [],
  };

  deleteCustomerBenSelected: any = {};
  detailCustomerBenSelected: any = {};
  newCustomerBen: any = {
    cus_ben_name: '',
    cus_ben_cus_id: '',
    cus_ben_cus_name: '',
    cus_ben_qty_beneficiaries: '',
    cus_ben_code: '',
    cus_ben_cus_ben_typ_id: '',
    cus_ben_cus_ben_typ_name: null,
    cus_ben_description: '',
    cus_ben_phone: '',
    cus_ben_text_welcome: '',
    cus_ben_particular: '',
    cus_ben_image: null,
    cus_ben_media: [],
    cus_ben_specialities: [],
  };

  newSpe: any = {
    spe_id: '',
    spe_name: '',
    cty_titular: null,
    cty_beneficiary: null,
    disc_titular: 0,
    disc_beneficiary: 0,
  };
  newMedia: any = {};

  steps: any = {
    table: false,
    add: false,
    edit: false,
  }

  constructor(
    private customerService: CustomerService,
    private generalService: GeneralService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.postListarCustomer();
    this.postListarCBenefits();
    this.getSpecialityList();
    this.changeStep({ table: true });
  }

  postListarCustomer() {
    this.customers = [];
    this.customerService.postListarCustomer({})
      .then((result: any) => {
        // console.log(result);
        this.customers = result;
        // Swal.close();
      })
      .catch((error: any) => {
        // Swal.close();
      });
  }

  postListarCBenefits() {
    this.cBenefits = [];
    this.cBenefitsTypes = [];
    this.generalService.modalCargando();
    this.customerService.postListarCBenefits()
      .then((result: any) => {
        this.cBenefits = result.planes && result.planes.length > 0 ? result.planes : [];
        this.cBenefitsTypes = result.tipos && result.tipos.length > 0 ? result.tipos : [];
        // console.log(this.cBenefits);
        // console.log(this.cBenefitsTypes);
        Swal.close();
      })
      .catch(error => {
        // console.log(error);
        this.generalService.modalError('Error', 'Error al cargar planes.');
      });

  }

  addCustomerBenefits() {
    this.changeStep({ add: true });
  }

  deleteCustomerBenefits(cBenefit) {
    this.deleteCustomerBenSelected = cBenefit;
    this.renderer.selectRootElement(this.deleteCustomerBenTri.nativeElement).click();

  }

  editCustomerBenefits(cBenefit) {
    this.changeStep({ edit: true });
    // console.log(cBenefit);
    this.editCustomerBenSelected = JSON.parse(JSON.stringify(cBenefit));
    this.editCustomerBenSelected.cus_ben_cus_id = this.editCustomerBenSelected.cus_id;
    this.editCustomerBenSelected.new_cus_ben_media = [];
    this.editCustomerBenSelected.cus_ben_media = cBenefit.cus_ben_media ? cBenefit.cus_ben_media : [];
    this.editCustomerBenSelected.new_cus_ben_specialities = [];
    this.editCustomerBenSelected.cus_ben_specialities = cBenefit.cus_ben_specialities ? cBenefit.cus_ben_specialities : [];
  }

  cancelEdit() {
    this.ngOnInit();
  }

  confirmDelete() {
    if (!this.deleteCustomerBenSelected.cus_ben_id) {
      this.generalService.modalError('Falta información', 'Debe seleccionar un plan.');
      return;
    }
    this.generalService.modalCargando();
    this.renderer.selectRootElement(this.deleteCustomerBenTri.nativeElement).click();
    this.customerService.postEliminarCBenefits(this.deleteCustomerBenSelected.cus_ben_id)
      .then(async result => {
        this.generalService.modalExitoso('Plan eliminado', 'El plan se eliminó exitosamente.');
        await this.generalService.sleep(1000);
        this.ngOnInit();
      })
      .catch(error => {
        this.generalService.modalError('Error al eliminar', 'Error al eliminar plan.');
      });
  }

  onFileSelectedEdit(event) {

  }

  changeStep({ table = false, add = false, edit = false }) {
    this.steps = {
      table: table,
      add: add,
      edit: edit,
    }
  }

  cancelAdd() {
    this.ngOnInit();
  }

  seeDetails(cBenefit) {
    this.detailCustomerBenSelected = cBenefit;
    this.renderer.selectRootElement(this.seeCustomerBenTri.nativeElement).click();
  }
  confirmAdd() {

    this.newCustomerBen.cus_ben_particular = this.newCustomerBen.cus_ben_particular == 'true' ? true : false;
    // console.log(this.newCustomerBen);
    if (this.newCustomerBen.cus_ben_media.length <= 0) {
      this.generalService.modalError('Falta información', 'Debe seleccionar fotos para el carrusel.');
      return;
    } else if (this.newCustomerBen.cus_ben_specialities.length <= 0) {
      this.generalService.modalError('Falta información', 'Debe seleccionar especialidadess.');
      return;
    } else if (!this.newCustomerBen.cus_ben_name || this.newCustomerBen.cus_ben_name.length < 2) {
      this.generalService.modalError('Falta información', 'Debe indicar un nombre.');
      return;
    } else if (!this.newCustomerBen.cus_ben_cus_id) {
      this.generalService.modalError('Falta información', 'Debe indicar un cliente.');
      return;
    } else if (!this.newCustomerBen.cus_ben_cus_ben_typ_id) {
      this.generalService.modalError('Falta información', 'Debe indicar una periocidad.');
      return;
    } else if (!this.newCustomerBen.cus_ben_description || this.newCustomerBen.cus_ben_description.length < 2) {
      this.generalService.modalError('Falta información', 'Debe indicar una descripción.');
      return;
    } else if (!this.newCustomerBen.cus_ben_image) {
      this.generalService.modalError('Falta información', 'Debe agregar una imagen al plan.');
      return;
    }
    this.generalService.modalCargando();
    this.customerService.postCrearCBenefits(this.newCustomerBen)
      .then(async result => {
        this.generalService.modalExitoso('Plan creado', 'El plan se creó exitosamente.');
        await this.generalService.sleep(1000);
        this.ngOnInit();
      })
      .catch(error => {
        // console.log(error);

        this.generalService.modalError('Error al crear', 'Error al crear plan.');
      });
  }

  confirmEdit() {

    this.editCustomerBenSelected.cus_ben_particular = this.editCustomerBenSelected.cus_ben_particular == 'true' ? true : false;
    if (!this.editCustomerBenSelected.cus_ben_name || this.editCustomerBenSelected.cus_ben_name.length < 2) {
      this.generalService.modalError('Falta información', 'Debe indicar un nombre.');
      return;
    } else if (!this.editCustomerBenSelected.cus_ben_cus_id) {
      this.generalService.modalError('Falta información', 'Debe indicar un cliente.');
      return;
    } else if (!this.editCustomerBenSelected.cus_ben_cus_ben_typ_id) {
      this.generalService.modalError('Falta información', 'Debe indicar una periocidad.');
      return;
    } else if (!this.editCustomerBenSelected.cus_ben_description || this.editCustomerBenSelected.cus_ben_description.length < 2) {
      this.generalService.modalError('Falta información', 'Debe indicar una descripción.');
      return;
    }

    this.generalService.modalCargando();
    this.customerService.postCrearCBenefits(this.editCustomerBenSelected)
      .then(async result => {
        this.generalService.modalExitoso('Plan modificado', 'El plan se modificó exitosamente.');
        await this.generalService.sleep(1000);
        this.ngOnInit();
      })
      .catch(error => {
        // console.log(error);

        this.generalService.modalError('Error al crear', 'Error al modificar plan.');
      });
  }

  getSpecialityList() {
    this.generalService.getSpecialityList()
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          this.specialityList = data;
          // console.log(this.specialityList, 'specialityList-getSpecialityList');

        }
      })
      .catch(e => console.error(e));
  }

  onFileSelected(event) {

  }

  addSpe() {
    if (!this.newSpe || !this.newSpe.spe_id) {
      this.generalService.modalError('Falta información', 'Debe seleccionar una especialidad.');
      return;
    } else if (!this.newSpe.disc_titular || this.newSpe.disc_titular < 1 || this.newSpe.disc_titular > 100) {
      this.generalService.modalError('Información incorrecta', 'Debe insertar un porcentaje de 1-100.');
      return;
    } else if (!this.newSpe.disc_beneficiary || this.newSpe.disc_beneficiary < 1 || this.newSpe.disc_beneficiary > 100) {
      this.generalService.modalError('Información incorrecta', 'Debe insertar un porcentaje de 1-100.');
      return;
    }
    for (let index = 0; index < this.specialityList.length; index++) {
      const element = this.specialityList[index];
      if (element.spe_id == Number(this.newSpe.spe_id)) {
        this.newSpe.spe_name = element.spe_name;
        break;
      }
    }
    if (this.editCustomerBenSelected && this.editCustomerBenSelected.cus_ben_cus_id) {

      for (let index = 0; index < this.editCustomerBenSelected.cus_ben_specialities.length; index++) {
        const element = this.editCustomerBenSelected.cus_ben_specialities[index];
        if (Number(element.spe_id) == Number(this.newSpe.spe_id)) {
          this.generalService.modalError('Error', 'Debe borrar esta especialidad antes para agregarla.');
          this.renderer.selectRootElement(this.addSpeModal.nativeElement).click();
          return;
        }
      }
      for (let index = 0; index < this.editCustomerBenSelected.new_cus_ben_specialities.length; index++) {
        const element = this.editCustomerBenSelected.new_cus_ben_specialities[index];
        if (Number(element.spe_id) == Number(this.newSpe.spe_id)) {
          this.editCustomerBenSelected.new_cus_ben_specialities[index] = this.newSpe;
          // console.log(this.editCustomerBenSelected.new_cus_ben_specialities);
          this.renderer.selectRootElement(this.addSpeModal.nativeElement).click();
          return;
        }
      }
      let arraySpe = this.editCustomerBenSelected.new_cus_ben_specialities;
      arraySpe.push(this.newSpe);
      this.editCustomerBenSelected.new_cus_ben_specialities = arraySpe;
      this.renderer.selectRootElement(this.addSpeModal.nativeElement).click();

    } else {
      for (let index = 0; index < this.newCustomerBen.cus_ben_specialities.length; index++) {
        const element = this.newCustomerBen.cus_ben_specialities[index];
        if (Number(element.spe_id) == Number(this.newSpe.spe_id)) {
          this.newCustomerBen.cus_ben_specialities[index] = this.newSpe;
          // console.log(this.newCustomerBen.cus_ben_specialities);
          this.renderer.selectRootElement(this.addSpeModal.nativeElement).click();
          return;
        }
      }

      let arraySpe = this.newCustomerBen.cus_ben_specialities;
      arraySpe.push(this.newSpe);
      this.newCustomerBen.cus_ben_specialities = arraySpe;
      // console.log(this.newCustomerBen.cus_ben_specialities);
      this.renderer.selectRootElement(this.addSpeModal.nativeElement).click();
    }

  }

  deleteSpe(spe) {
    // console.log(spe);
    if (this.editCustomerBenSelected && this.editCustomerBenSelected.cus_ben_cus_id) {
      let arraySpe = this.editCustomerBenSelected.new_cus_ben_specialities;
      for (let index = 0; index < arraySpe.length; index++) {
        const element = arraySpe[index];
        if (element.spe_id == Number(spe.spe_id)) {
          arraySpe.splice(index, 1);
          break;
        }
      }
      this.editCustomerBenSelected.new_cus_ben_specialities = arraySpe;
    } else {      
      let arraySpe = this.newCustomerBen.cus_ben_specialities;
      for (let index = 0; index < arraySpe.length; index++) {
        const element = arraySpe[index];
        if (element.spe_id == Number(spe.spe_id)) {
          arraySpe.splice(index, 1);
          break;
        }
      }
      this.newCustomerBen.cus_ben_specialities = arraySpe;
    }

  }

  openModalSpe() {
    this.newSpe = {};
    this.renderer.selectRootElement(this.addSpeModal.nativeElement).click();

  }

  addMedia(event) {
    if (!event || !event.target || !event.target.files || !event.target.files[0]) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido');
    }
    this.newMedia = event.target.files[0];
    // console.log(this.newMedia);

  }

  confirmAddMedia() {
    if (!this.newMedia || !this.newMedia.name) {
      this.generalService.modalError('Falta un documento', 'Documento no válido');
      return;
    }


    if (this.editCustomerBenSelected && this.editCustomerBenSelected.cus_ben_cus_id) {
      let arrayMedia = this.editCustomerBenSelected.new_cus_ben_media;
      arrayMedia.push(this.newMedia);
      this.newCustomerBen.new_cus_ben_media = arrayMedia;
      this.renderer.selectRootElement(this.addMediaModal.nativeElement).click();
    } else {
      let arrayMedia = this.newCustomerBen.cus_ben_media;
      arrayMedia.push(this.newMedia);
      this.newCustomerBen.cus_ben_media = arrayMedia;
      this.renderer.selectRootElement(this.addMediaModal.nativeElement).click();
    }
  }
  openModalMedia() {
    this.newMedia = {};
    this.renderer.selectRootElement(this.addMediaModal.nativeElement).click();
  }

  deleteMedia(index) {
    if (this.editCustomerBenSelected && this.editCustomerBenSelected.cus_ben_cus_id) {
      let arrayMedia = this.editCustomerBenSelected.new_cus_ben_media;
      arrayMedia.splice(index, 1);
      this.editCustomerBenSelected.new_cus_ben_media = arrayMedia;
    } else {
      let arrayMedia = this.newCustomerBen.cus_ben_media;
      arrayMedia.splice(index, 1);
      this.newCustomerBen.cus_ben_media = arrayMedia;
    }
  }

  deleteMediaRoomLobby(med_roo_lob_id) {
    let index_media;
    if (!med_roo_lob_id) {
      this.generalService.modalError('Error', 'Falta seleccionar un archivo');
      return;
    }

    for (let index = 0; index < this.editCustomerBenSelected.cus_ben_media.length; index++) {
      const element = this.editCustomerBenSelected.cus_ben_media[index];
      if (element.med_roo_lob_id == med_roo_lob_id) {
        index_media = index;
        break;
      }
    }

    this.generalService.modalCargando();
    this.customerService.deleteMediaRoomLobby({ med_roo_lob_id })
      .then(result => {
        this.generalService.modalExitoso('Eliminación exitosa', 'Se eliminó de correcta forma el archivo.');
        let arrayMedia = this.editCustomerBenSelected.cus_ben_media;
        arrayMedia.splice(index_media, 1);
        this.editCustomerBenSelected.cus_ben_media = arrayMedia;
      })
      .catch(error => {
        this.generalService.modalError('Error', error && error.error && error.error.message ? error.error.message : 'Error al eliminar archivo');
      });


  }


  deleteSpeDet(cus_ben_det_id) {
    let index_det;
    if (!cus_ben_det_id) {
      this.generalService.modalError('Error', 'Falta seleccionar una especialidad.');
      return;
    }

    for (let index = 0; index < this.editCustomerBenSelected.cus_ben_specialities.length; index++) {
      const element = this.editCustomerBenSelected.cus_ben_specialities[index];
      if (element.cus_ben_det_id == cus_ben_det_id) {
        index_det = index;
        break;
      }
    }
    this.generalService.modalCargando();
    this.customerService.deleteSubSpecialityDetail({ cus_ben_det_id })
      .then(result => {
        this.generalService.modalExitoso('Eliminación exitosa', 'Se eliminó de correcta forma.');
        let arrayDetails = this.editCustomerBenSelected.cus_ben_specialities;
        arrayDetails.splice(index_det, 1);
        this.editCustomerBenSelected.cus_ben_specialities = arrayDetails;
      })
      .catch(error => {
        this.generalService.modalError('Error', error && error.error && error.error.message ? error.error.message : 'Error al eliminar');
      });
  }

  onFileSelectedLogo(event) {
    if (!event || !event.target || !event.target.files || !event.target.files[0]) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido');
    }
    if (this.editCustomerBenSelected && this.editCustomerBenSelected.cus_ben_cus_id) {
      this.editCustomerBenSelected.cus_ben_image = event.target.files[0];
    } else {
      this.newCustomerBen.cus_ben_image = event.target.files[0];
    }
  }
}
