<div class="col-md-12"> <!--  *ngIf="tipo_usuario_name=='PACIENTE'" -->
    <br>
    <div class="minititulo">
            Mi agenda
    </div>
    <div class="titulo">
            Mis próximas consultas
    </div>
</div>

 <div class="container-fluid">
      <div class="row">
          <div class="col-md-12 titulobeneficiario">
              Detalle de la consulta
          </div>
      </div>
    </div>
<!-- next appointments MEDICO-->
<div *ngIf="(typ_id == '1')">
    <div class="row">
        <div class="col-12">
            <div class="row d-sm-flex">
                <div class="col-md-12">
                    <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
                    <div class="card p-0 m-0" >
                        <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                            <div class="m-0 p-0 first-line">
                                <!--<app-profile-img [img]="appt.img" [doctorName]="appt.nombre_paciente" [doctorLastName]="appt.apellido_paterno_paciente"
                                    [color]="appt.goLobbyFlag ? 'confirm' : appt.confirmFlag ? 'confirm' : 'confirm'">
                                </app-profile-img> -->
                                <div class="img-cirlce-wrapper">
                                    <p class="m-0 p-0 text-default">
                                        {{pacienteName}}{{pacienteLastName}}
                                    </p>
                                </div>
                                <div class="ml-3 align-self-center">
                                    <p class="card-text m-0 p-0 appt-date">{{datos_consulta.nombre_paciente}} {{datos_consulta.apellido_paterno_paciente}} {{datos_consulta.apellido_materno_paciente}}</p>
                                    <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ date[0]}} - {{ date[1]}}</p>
                                    <p class="card-text m-0 p-0 ">{{ datos_consulta.spe_name}} </p> <!-- {{ appt.spec }} - {{ appt.subSpec }} -->
                                    <!-- <p class="card-text m-0 p-0 ">Nº de reserva: {{ datos_consulta.id_consulta}} </p> {{ appt.spec }} - {{ appt.subSpec }} -->
                                    <p class="card-text m-0 p-0 "> </p>
                                </div>
                                <app-popover class="ml-auto mr-2">
                                    <p class="pointer"  data-toggle="modal" data-target="#eliminarPaciente">Anular consulta</p>
                                </app-popover>
                            </div>
                            <br>
                            <div class="d-flex align-items-end justify-content-end flex-wrap">
                                <button *ngIf="video_bool" type="button" class="btn btn-primary mr-auto" (click)='goVideoConsultaMedico()'>
                                    Ir a la videoconsulta
                                </button>
                                <button *ngIf="!video_bool" type="button" class="btn btn-primary mr-auto" disabled>
                                    Ir a la videoconsulta
                                </button>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 titulobeneficiario">
                Descripción de la consulta
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-6 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion" >Profesional</p>
                    <p class="card-text m-0 p-0 cuerpoDescripcion" >{{ datos_consulta.nombre_doctor}} {{ datos_consulta.apellido_paterno_doctor}} {{ datos_consulta.apellido_materno_doctor}}</p>
                    <p class="card-text m-0 p-0 "> </p>
                </div>
            </div>
            <br>
            <div class="col-md-6 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion">Acompañante del paciente</p>
                    <p *ngIf="datos_consulta.nombre_acompanante != null" class="card-text m-0 p-0 cuerpoDescripcion">{{ datos_consulta.nombre_acompanante}} {{datos_consulta.apellido_paterno_acompanante}} {{ datos_consulta.apellido_materno_acompanante}}</p>
                    <p *ngIf="datos_consulta.nombre_acompanante == null" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no presenta acompañante a la consulta **</p>
                    <p class="m-0 p-0 "> </p>
                </div>
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-12 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion">Motivo de la consulta</p>
                    <p *ngIf="datos_consulta.razon != ''" class="card-text m-0 p-0 cuerpoDescripcion">{{ datos_consulta.razon}}</p>
                    <p *ngIf="datos_consulta.razon == ''" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no especifica motivo de la consulta **</p>
                    <p class="m-0 p-0 "> </p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- next appointments PACIENTE-->
<div *ngIf="(typ_id == '2')">
    <div class="row">
        <div class="col-12">
            <div class="row d-sm-flex">
                <div class="col-md-12">
                    <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
                    <div class="card p-0 m-0" >
                        <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                            <div class="m-0 p-0 first-line">
                                <!--<app-profile-img [img]="appt.img" [doctorName]="appt.nombre_paciente" [doctorLastName]="appt.apellido_paterno_paciente"
                                    [color]="appt.goLobbyFlag ? 'confirm' : appt.confirmFlag ? 'confirm' : 'confirm'">
                                </app-profile-img> -->
                                <app-profile-img 
                                    [img]="datos_consulta.att_path">


                                </app-profile-img>
                                <div class="ml-3 align-self-center">
                                    <p class="card-text m-0 p-0 appt-date" style="font-weight: bold">Fecha de la consulta: {{ date[0]}} - {{ date[1]}}</p>
                                    <p class="card-text m-0 p-0 ">{{ datos_consulta.nombre_doctor}} {{ datos_consulta.apellido_paterno_doctor}} - {{ datos_consulta.spe_name}}</p> <!-- {{ appt.spec }} - {{ appt.subSpec }} -->
                                    <p class="card-text m-0 p-0 ">Nº de reserva: {{ datos_consulta.id_consulta}} </p>
                                    <p class="card-text m-0 p-0 "> </p>
                                </div>
                                <app-popover class="ml-auto mr-2">
                                    <a data-toggle="modal" class="pointer" (click)="modificarConsulta(datos_consulta)"><p>Modificar consulta</p></a>
                                    <a class="pointer"  data-toggle="modal" data-target="#eliminarPaciente"><p>Anular consulta</p></a>
                                </app-popover>
                            </div>
                            <br>
                            <div class="d-flex align-items-end justify-content-end flex-wrap">
                                <button *ngIf="video_bool" type="button" class="btn btn-primary mr-auto" (click)='goLobby()'>
                                    Ir a sala de espera
                                </button>
                                <ng-container *ngIf="!video_bool">
                                    <button *ngIf="datos_consulta.tel_doc_app_boo_sta_id==30 || datos_consulta.tel_doc_app_boo_sta_id==39" type="button" class="btn btn-primary mr-auto" disabled>
                                        Ir a sala de espera
                                    </button>
                                    <button *ngIf="datos_consulta.tel_doc_app_boo_sta_id==10" type="button" class="btn btn-primary mr-auto" style="background: #316AD8 !important;" (click)="confirmarAsistencia()">
                                        Confirmar asistencia
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 titulobeneficiario">
                Descripción de la consulta
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-6 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion" >Paciente</p>
                    <p class="card-text m-0 p-0 cuerpoDescripcion" >{{datos_consulta.nombre_paciente}} {{datos_consulta.apellido_paterno_paciente}} {{datos_consulta.apellido_materno_paciente}}</p>
                    <p class="card-text m-0 p-0 "> </p>
                </div>
            </div>
            <br>
            <div class="col-md-6 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion">Acompañante del paciente</p>
                    <p *ngIf="datos_consulta.nombre_acompanante != null" class="card-text m-0 p-0 cuerpoDescripcion">{{ datos_consulta.nombre_acompanante}} {{datos_consulta.apellido_paterno_acompanante}} {{ datos_consulta.apellido_materno_acompanante}}</p>
                    <p *ngIf="datos_consulta.nombre_acompanante == null" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no presenta acompañante a la consulta **</p>
                    <p class="m-0 p-0 "> </p>
                </div>
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-12 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion">Motivo de la consulta</p>
                    <p *ngIf="datos_consulta.razon != ''" class="card-text m-0 p-0 cuerpoDescripcion">{{ datos_consulta.razon}}</p>
                    <p *ngIf="datos_consulta.razon == ''" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no especifica motivo de la consulta **</p>
                    <p class="m-0 p-0 "> </p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL ANULAR CONSULTA -->
  <div class="modal fade" id="eliminarPaciente" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
            <p class="tituloModal">Anulación de consulta</p>
            <br>
            <form class="form col-md-12"
                    id="ComentarioCancelacion"
                    autocomplete ="off"
                    [formGroup] = "comentarioCancelacion"
                    (ngSubmit)="eliminaConsulta()">
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 border-card-modal">
                    <div class="m-0 p-0 first-line">
                        <div class="img-cirlce-wrapper">
                            <p class="m-0 p-0 text-default">
                                {{pacienteName}}{{pacienteLastName}}
                            </p>
                        </div>
                        <div class="ml-3 align-self-center">
                            <p class="card-text m-0 p-0 appt-date">{{datos_consulta.nombre_paciente}} {{datos_consulta.apellido_paterno_paciente}} {{datos_consulta.apellido_materno_paciente}}</p>
                            <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ date[0]}} - {{ date[1]}}</p>
                            <p class="card-text m-0 p-0 ">{{ datos_consulta.spe_name}}</p> <!-- {{ appt.spec }} - {{ appt.subSpec }} -->
                            <p class="card-text m-0 p-0 "> </p>
                        </div>
                        <br>
                    </div>
                </div>
                <br><br>
                <div class="form-group">
                    <textarea class="form-control" placeholder="Motivo de la cancelación*" formControlName="comentario" rows="5" id="comentario"></textarea>
                </div>
                <div class="row">
                    <div class="col text-danger">
                        <p *ngIf="campoNoValido('comentario')"> El motivo de la cancelación obligatorio</p>
                    </div>
                </div> 
                <br>
                <br>
                <div class="container-fluid">
                  <div class="row">
                      <div class="col-md-6">
                          <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar formulario</button>
                      </div>
                      <div class="col-md-6 letrabotonagregar">
                          <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-toggle="modal" data-target="#confirmaAnular">Cancelar consulta</button>
                      </div>
                  </div>
                </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL CONFIRMA ANULAR CONSULTA -->
  <div *ngIf="!comentarioCancelacion.invalid" class="modal fade" id="confirmaAnular" aria-labelledby="exampleModalLabel" aria-hidden="true"> <!-- *ngIf="!consultaCancelarData.invalid" -->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
            <p class="tituloModal">¿Esta seguro que desea anular la consulta?</p>
            <br>
          <br><br>
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                </div>
                <div class="col-md-6 letrabotonagregar">
                    <button type="buton" (click)="confirmaEliminar()" class="btn btn-outline-primary btn-lg botonModalAgregar" data-dismiss="modal" data-toggle="modal" data-target="#exitoAnulacion">Confirmar</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- MODAL ELIMINACION EXITOSA CONSULTA -->
  <div class="modal fade" id="exitoAnulacion" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
        <br>
            <P class="tituloModalConfirma">   <mat-icon style="font-size: 60px;">mood</mat-icon> </P>
            <br>
            <p class="tituloModalConfirma">La consulta ha sido anulada exitosamente</p>
            <br>
          <br>
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 letrabotonagregar">
                    <button type="buton" (click)="volverProximasConsultas()" data-dismiss="modal" class="btn btn-outline-primary btn-lg botonModalConfirmar">Aceptar</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


