import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ZoomService } from 'src/app/services/zoom.service';

@Component({
  selector: 'app-consultas-finalizadas',
  templateUrl: './consultas-finalizadas.component.html',
  styleUrls: ['../../../../assets/css/styles.css', './consultas-finalizadas.component.css']
})
export class ConsultasFinalizadasComponent implements OnInit {

  @Input() consulta: any = {};
  @Output() messageEvent = new EventEmitter<any>();
  
  data_consulta: any = {};

  data_selected: any = {}           //resumen del consulta
  _usuario: any = {};  // usuario actual
  ficha: boolean = false;

  constructor(
    private router: Router,
    private _medicosServices: MedicosService,
    private _schedule: ScheduleService,
    private _zoomService : ZoomService
  ) { }

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo()
    ////console.log(this.consulta);
    
    
    this._zoomService.getConsulta(this.consulta.id_consulta)
    .then(resultado => {
      ////console.log(resultado);
      this.data_consulta = resultado;
    }).catch(error => {
      console.log(error);
    });
  }

  verDetalle(){
    this.messageEvent.emit(this.consulta)
    this.ficha = true;
  }



}
