<div class="row" style="width: 100%;margin-bottom: 25px;">
     <ng-container *ngIf="agreements && agreements.length > 0;else noBenefits">
          <!-- <div id="benefitsCarrousel" class="carousel slide" data-ride="carousel">

               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <img src="la.jpg" alt="Los Angeles">
                 </div>
                 <div class="carousel-item">
                   <img src="chicago.jpg" alt="Chicago">
                 </div>
                 <div class="carousel-item">
                   <img src="ny.jpg" alt="New York">
                 </div>
               </div> -->

          <ng-container *ngFor="let agreement of agreements">
               <div class="col-lg-3 col-md-3 col-sm-12 " style="margin-top: 10px;">
                    <app-agreement [agreement]="agreement"></app-agreement>
               </div>
          </ng-container>
          <!-- </div>
          <a class="carousel-control-prev" href="#benefitsCarrousel" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </a>
          <a class="carousel-control-next" href="#benefitsCarrousel" data-slide="next">
            <span class="carousel-control-next-icon"></span>
          </a> -->
     </ng-container>
</div>
<ng-template #noBenefits>
     <!-- <div class="row cardInfo" style="width: 100%;">
          <div class="col-auto" style="align-self: center;">
               <mat-icon style="color: #171994;">info</mat-icon>
          </div>
          <div class="col" style="align-self: center;">
               <p class="infoStyleText">Por el momento no contamos con prestaciones de salud complementarias para ti.
                    Nos encontramos trabajando fuertemente para poder brindarte la mejor y más integral experiencia en
                    salud.</p>
          </div>
     </div> -->
</ng-template>