<div class="card">

    <h5 class="card-title">
        <button type="button" #auxCloseSignupModal class="close" aria-label="Close" (click)="closeSurveyModal()">
            <span aria-hidden="true">
                <mat-icon style="font-size: 30px;  color: #171994;">cancel</mat-icon>
            </span>
        </button>
    </h5>
    <div class="card-body" style="display: flex;
    flex-direction: column;
    align-items: center;width: 100%;
    padding: 0;justify-content: center;">
        <h5 *ngIf="number_page <= 4" class="title-modal">Califica tu última consulta</h5>


        <div *ngIf="number_page <= 4" class="row" style=" width: 100%; display: flex; justify-content: center;">
            <div class="card-doctor">


                <table>
                    <tr>
                        <td>
                            <div class="img-cirlce-wrapper" style="margin: 20px;">
                                <img *ngIf="pendingSurveys[0]?.att_path" class="img-fluid"
                                    [src]="URL_DOCUMENTS+pendingSurveys[0]?.att_path" alt="profile-img"
                                    title="profile-img">
                                <p *ngIf="!pendingSurveys[0]?.att_path" class="m-0 p-0">
                                    {{ pendingSurveys[0]?.nombre_doctor}}
                                </p>
                            </div>
                        <td>

                            <p class="texto-subtitulo-uno">Dia {{pendingSurveys[0]?.dia}} de {{obtenerNombreMes(pendingSurveys[0]?.mes)}} - a las {{pendingSurveys[0]?.hora}} horas </p>
                            <p class="texto-titulo-card">Dr. {{pendingSurveys[0]?.nombre_doctor}} - {{pendingSurveys[0]?.especialidad}}</p>
                            <!-- <p class="texto-subtitulo-uno">{{pendingSurveys[0]?.med_con_id}}</p> -->
                        </td>

                    </tr>
                </table>


            </div>
        </div>

        <table *ngIf="number_page <= 4">
            <tr>
                <td>
                    <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 0; else first">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #first>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 1; else second">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #second>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 2; else third">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #third>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 3; else fourth">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #fourth>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
            </tr>
        </table>
        
       
        <div *ngIf="number_page>=0 && number_page<=4">
            <p class="quantity-text">Califica tu atención</p>
        </div>
        <div *ngIf="number_page==0">
            <h2 class="texto-pregunta">¿Cómo evalúas la atención del médico?</h2>
        </div>
        <div *ngIf="number_page==1">
            <h2 class="texto-pregunta">¿Cómo evalúas la calidad de la videollamada?</h2>
        </div>
        <div *ngIf="number_page==2">
            <h2 class="texto-pregunta">¿Qué tan fácil fue utilizar AlmaMedis?</h2>
        </div>
        <div *ngIf="number_page==3">
            <h2 class="texto-pregunta">En una escala del 1 al 10</h2>
            <h2 class="texto-pregunta">¿Con qué probabilidad recomendaría AlmaMedis a un familiar o amigo?</h2>
        </div>
        <div *ngIf="number_page==4">
            <h2 class="texto-pregunta">¿Deseas agregar algo más? (opcional)</h2>
        </div>
        <!-- <p>{{calificaciones[number_page]}}</p> -->
        <div *ngIf="number_page < 3">
            <div class="ec-stars-wrapper pointer">
              <a style="font-size: 43px;" 
                 [ngClass]="{'star': true, 'a-star': (hoverIndex >= 1) || (calificaciones[number_page] >= 1)}"
                 (click)='calificacion(1)' 
                 (mouseenter)="onMouseEnter(1)" 
                 (mouseleave)="onMouseLeave()" 
                 data-value="1" 
                 title="Votar con 1 estrellas">&#9733;</a>
              <a style="font-size: 43px;" 
                 [ngClass]="{'star': true, 'a-star': (hoverIndex >= 2) || (calificaciones[number_page] >= 2)}"
                 (click)='calificacion(2)' 
                 (mouseenter)="onMouseEnter(2)" 
                 (mouseleave)="onMouseLeave()" 
                 data-value="2" 
                 title="Votar con 2 estrellas">&#9733;</a>
              <a style="font-size: 43px;" 
                 [ngClass]="{'star': true, 'a-star': (hoverIndex >= 3) || (calificaciones[number_page] >= 3)}"
                 (click)='calificacion(3)' 
                 (mouseenter)="onMouseEnter(3)" 
                 (mouseleave)="onMouseLeave()" 
                 data-value="3" 
                 title="Votar con 3 estrellas">&#9733;</a>
              <a style="font-size: 43px;" 
                 [ngClass]="{'star': true, 'a-star': (hoverIndex >= 4) || (calificaciones[number_page] >= 4)}"
                 (click)='calificacion(4)' 
                 (mouseenter)="onMouseEnter(4)" 
                 (mouseleave)="onMouseLeave()" 
                 data-value="4" 
                 title="Votar con 4 estrellas">&#9733;</a>
              <a style="font-size: 43px;" 
                 [ngClass]="{'star': true, 'a-star': (hoverIndex >= 5) || (calificaciones[number_page] >= 5)}"
                 (click)='calificacion(5)' 
                 (mouseenter)="onMouseEnter(5)" 
                 (mouseleave)="onMouseLeave()" 
                 data-value="5" 
                 title="Votar con 5 estrellas">&#9733;</a>
            </div>
          </div>
        <div *ngIf="number_page==3" class="row"  style="width: 100%;place-content: center;">
            <div class="contenedor-numeros">
                <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" style="    text-align: center;
                width: 100%;">
                    <button
                        [ngClass]="(calificaciones[number_page] == i) ? 'button-quantity button-color-press':'button-quantity button-color'"
                        (click)="calificacion(i)">
                        {{i}}
                    </button>
                </ng-container>
            </div>
            <div class="row" class="contenedor-probabilidad">
                <div class="col-12" style="width: 100%;
                display: flex;
                justify-content: space-between;">
                    <p class="quantity-text">Muy poco probable</p>
                    <p class="quantity-text">Muy probable</p>
                </div>
            </div>
            <!-- <div class="col-2 d-none d-sm-flex">
                <p class="quantity-text">Muy poco probable</p>
            </div>
            <div class="col-8 d-none d-sm-flex"></div>
            <div class="col-2 d-none d-sm-flex">
                <p class="quantity-text">Muy probable</p>
            </div> -->
        </div>
        <div *ngIf="number_page == 4" style="    width: 80%;">
            <textarea class="form-control" [(ngModel)]="comentario"
                style="width: 100%; ;background: #FFFFFF;border: 1px solid #A5A5A5;box-sizing: border-box;border-radius: 4px;"
                placeholder="Deja tus comentarios y/o sugerencias." rows="4"></textarea>

        </div>

        <div *ngIf="number_page == 5" class="card-deck row" style="margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 0 50px;
        flex-direction: column;
        align-items: center;">
            <i class="far fa-laugh" style="color:#171994;font-size: 58px;margin: 0 0 40px 0;"></i> <br>
            <h5 class="title-modal">Tu evaluación ha sido enviada exitosamente</h5>
            <br>

            <h2 *ngIf="pendingSurveys.length > 1" class="quantity-text">Aun tienes consultas sin evaluar</h2>
            <br>
            <h2 *ngIf="pendingSurveys.length > 1" class="texto-pregunta">¿Deseas seguir evaluando tus anteriores
                consultas?</h2>
            <br>
        </div>
        <div *ngIf="number_page == 6" class="card-deck row" style="margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 0 50px;
        flex-direction: column;
        align-items: center;">
            <i class="far fa-laugh" style="color:#171994;font-size: 58px;margin: 0 0 40px 0;"></i> <br>
            <h5 class="title-modal">Su encuesta ha sido enviada exitosamente</h5>
            <br>

        </div>
        <br>

        <div class="card-deck row">
            <div *ngIf="number_page > 0 && number_page <5 " style="width: auto;">
                <button (click)='volver()' class="btn btn-outline-primary btn-lg botonModalInicio">
                    Volver Atrás </button>
            </div>
            <div *ngIf="number_page < 4">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar"
                    (click)='siguiente()'>Continuar</button>
            </div>
            <div *ngIf="number_page == 4">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar"
                    (click)='sendSurvey()'>Continuar</button>
            </div>
            <div *ngIf="number_page == 5" style="width: auto;">
                <button class="btn btn-outline-primary btn-lg botonModalInicio" (click)="closeSurveyModal()">Salir</button>
            </div>
            <div *ngIf="number_page == 5 && this.pendingSurveys.length > 1">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar"
                    (click)='resetModalSurvey()'>Continuar</button>
            </div>
            <div *ngIf="number_page == 5 && this.pendingSurveys.length == 1">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar"
                (click)='siguiente()'>Continuar</button>
            </div>
            <div *ngIf="number_page == 6">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar"
                    (click)='resetModalSurvey()'>Ir al inicio</button>
            </div>
        </div>
        <br><br>
    </div>


</div>