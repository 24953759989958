

<div class="col-12 col-sm-12 container-hours" style="margin-top: 20px; padding-left: 0; width: 100%;">
  <div class="row row-cols-1 row-cols-sm-2 p-sm-0 m-sm-0">
    <div class="col-12 col-sm mr-sm-3 container-calendar" style="margin: 10px 0px 0px 0px">
      <app-calendar-month *ngIf="!vistaProximasConsultas" class="" (messageEvent)='clickDayCalendar($event)'>
      </app-calendar-month>

      <app-calendar-month *ngIf="vistaProximasConsultas" class="" (messageEvent)='clickDayCalendar2($event)'
        (messageMonth)='clickMonthCalendar($event)'>
      </app-calendar-month>


    </div>

    <!-- Llamado desde cualquier lugar -->

    <!-- AM -->
    <div *ngIf="!vistaProximasConsultas" class="col-12 col-sm general" style="margin: 10px 0px 0px 15px">
      <!-- container-shadow  -->
      <div class="row p-sm-3 list-hours">
        <div class="col-sm-12 mb-sm-4">
          <div class="row row-cols-sm-2 pt-3 pb-3">
            <div class="col-6 col-sm-6">
              <span class="available-hours-title">Horas disponibles</span>
            </div>
            <div class="col-6 col-sm-6 text-right">
              <span class="date">{{ daySelected.dayTitle }}</span>
            </div>
          </div>
        </div>

       

        <mat-tab-group [selectedIndex]="currentTab === 'AM' ? 0 : (currentTab === 'PM' ? 1 : 2)">
          <mat-tab label="AM">
            <div class="col-12">
              <div class="not-hours" *ngIf="(daySelected.available == false)" style="margin: 10px 0px 10px 0px; text-align: center;">
                <div class="d-flex">
                  <mat-icon>info</mat-icon>
                  <span>No existen horas disponibles para este día.</span>
                </div>
              </div>
              <div class="row row-cols-4 mb-sm-3 container-buttons" *ngIf="daySelected.available">
                <ng-container *ngFor="let itemHour of hoursDay">
                  <ng-container
                    *ngIf="(itemHour.existe) && (itemHour.hour_number >= 0 && itemHour.hour_number <= 1130)">
                    <div class="col-3 col-sm-3 mb-sm-2 pb-2 text-sm-center text-left">
                      <button (click)="clickHour( itemHour )" class="btn btn-primary" [disabled]="!itemHour.available">
                        {{ itemHour.from }}
                      </button>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </mat-tab>


          <mat-tab label="PM" id="hoursPm">
            <div class="col-12">
              <div class="not-hours" *ngIf="(daySelected.available == false)" style="margin: 10px 0px 10px 0px;">
                <div class="d-flex">
                  <mat-icon>info</mat-icon>
                  <span>No existen horas disponibles para este día.</span>
                </div>
              </div>
              <div class="row row-cols-4 mb-sm-3 container-buttons" *ngIf="daySelected.available">
                <ng-container *ngFor="let itemHour of hoursDay">
                  <ng-container *ngIf="(itemHour.existe) && (itemHour.hour_number >= 1200 && itemHour.hour_number <= 2330)">
                    <!-- ngif -->
                    <div class="col-3 col-sm-3 mb-sm-2 pb-2 text-sm-center text-left">
                      <button (click)="clickHour( itemHour )" class="btn btn-primary" [disabled]="!itemHour.available">
                        {{ itemHour.from }}
                      </button>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>




    <!-- Llamado desde cualquier lugar -->


    <!-- Llamado desde mis próximas consultas médico -->
    <div *ngIf="vistaProximasConsultas" class="col-12 col-sm" style="padding-left: 0;" style="margin-top: 0">
      <!-- container-shadow  -->
      <div class="row p-sm-3 list-hours">
        <div class="col-sm-12 mb-sm-4">
          <div class="row row-cols-sm-2 pt-3 pb-3">
            <div class="col-6 col-sm-6">
              <span class="available-hours-title">Horas disponibles</span>
            </div>
            <div class="col-6 col-sm-6 text-right">
              <span class="date">{{ daySelected.dayTitle }}</span>
            </div>
          </div>
        </div>

        <div class="not-hours" *ngIf="(daySelected.available == false)">
          <div class="d-flex">
            <mat-icon>info</mat-icon>
            <span>No existen horas disponibles para este día.</span>
          </div>
        </div>

        <div class="col-12">
          <div class="row row-cols-4 mb-sm-3" *ngIf="daySelected.available">
            <div class="col-3 col-sm-3 mb-sm-2 pb-2 text-sm-center text-left" *ngFor="let itemHour of horasAuxiliares">
              <button (click)="clickHour( itemHour )" *ngIf="(itemHour.existe)"
                [ngClass]="(itemHour.available == true)? 'btn hour-btn-habilitada': 'btn hour-btn-bloqueada'">
                {{ itemHour.from }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Llamado desde mis próximas consultas médico -->


  </div>
</div>