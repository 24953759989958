<!-- <div class="card cardAgreement" (click)="goToBenefit()" style="align-items: center;padding: 8px">
     <div class="row fullCard">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="text-align: center;">
               <p class="agre_title" style="font-size: 65px;">{{benefit.agre_title}}</p>
               <img class="benefitIMG" [src]="URL_DOCS+benefit.agre_path |safeUrl">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="align-self: center;">
               <p class="agreementStyle">{{benefit.agre_description}}</p>
          </div>
     </div>
</div> -->

<!-- 
<div class="card mb-3 cardAgreement" (click)="goToBenefit()" >
     <div class="row g-0">
       <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="display: flex; flex-direction: column;  align-items: center;">
          <p class="agre_title" style="font-size: 65px;">{{benefit.agre_title}}</p>
          <div style="height: 100%; width: auto; display: flex; align-items: center; justify-content: center;">
               <img [src]="URL_DOCS+benefit.agre_path |safeUrl" class=" benefitIMG img-fluid" alt="...">
          </div>
       </div>
       <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12"  style="display: flex; align-items: center;">
         <div class="card-body" style="padding: 0;">
          <p class="agreementStyle">{{benefit.agre_description}}</p>

         </div>
       </div>
     </div>
   </div> -->

   

   <div class="row row-cols-1 row-cols-md-3 g-4">
     <div class="col d-flex">
          <div class="card mb-3 cardAgreement" (click)="goToBenefit()" >
               <div class="row g-0">
                 <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="display: flex; flex-direction: column;  align-items: center;">
                    <div style="height: 60%; width: auto; display: flex; align-items: center; justify-content: center; margin: 10px;">

                         <p class="agre_title" style="font-size: 55px;">{{benefit.agre_title}}</p>
                    </div>
                    <div style="height: 40%; width: auto; display: flex; align-items: center; justify-content: center; margin: 10px;">
                         <img [src]="URL_DOCS+benefit.agre_path |safeUrl" class=" benefitIMG img-fluid" alt="...">
                    </div>
                 </div>
                 <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12"  style="display: flex; align-items: center; margin: 20px 0;">
                   <div class="card-body" style="padding: 0;">
                    <p class="agreementStyle">{{benefit.agre_description}}</p>
          
                   </div>
                 </div>
               </div>
             </div>
     </div>
     
   </div>