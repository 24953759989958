import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) {

  }

  async postListarCustomer({ main = null, is_bulk_type = false, products_list = false }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      main,
      is_bulk_type,
      products_list
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarCustomer', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async postCrearCustomer(cus_name, cus_url, cus_phone, cus_image: File, cus_can_pay) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        // 'Content-Type': 'application/json'
      })
    };
    const fd = new FormData();
    fd.append('file', cus_image, cus_image.name);
    fd.append('cus_name', cus_name);
    if (cus_url) {
      fd.append('cus_url', cus_url);
    }
    if (cus_phone) {
      fd.append('cus_phone', cus_phone);
    }

    if (cus_can_pay) {
      fd.append('cus_can_pay', 'true');
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postCrearCustomer', fd, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error);
      throw error;
    }
  }

  async postModificarCustomer(cus_id, cus_name, cus_url, cus_phone, cus_image, cus_can_pay) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        // 'Content-Type': 'application/json'
      })
    };
    const fd = new FormData();
    fd.append('cus_id', cus_id);
    fd.append('cus_name', cus_name);
    if (cus_url) {
      fd.append('cus_url', cus_url);
    }
    if (cus_phone) {
      fd.append('cus_phone', cus_phone);
    }
    if (cus_image) {
      fd.append('file', cus_image, cus_image.name);
    }
    if (cus_can_pay) {
      fd.append('cus_can_pay', 'true');
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postModificarCustomer', fd, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error);
      throw error;
    }
  }

  async postBorrarCustomer(cus_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      cus_id
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postBorrarCustomer', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async postListarCBenefits() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarCBenefits', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async postEliminarCBenefits(cus_ben_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      cus_ben_id
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postEliminarCBenefits', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async postCrearCBenefits(benefit: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };
    const fd = new FormData();
    if (benefit.cus_ben_id)
      fd.append('cus_ben_id', benefit.cus_ben_id);

    if (benefit.cus_ben_image && benefit.cus_ben_image.name) {
      fd.append('logo', benefit.cus_ben_image, benefit.cus_ben_image.name);
    }
    let media_array = benefit.new_cus_ben_media ? benefit.new_cus_ben_media : benefit.cus_ben_media;
    let specialities_array = benefit.new_cus_ben_specialities ? benefit.new_cus_ben_specialities : benefit.cus_ben_specialities;

    for (let index = 0; index < media_array.length; index++) {
      const element = media_array[index];
      fd.append('media' + index, element, element.name);
    }

    fd.append('cus_ben_specialities', JSON.stringify(specialities_array));
    fd.append('cus_ben_name', benefit.cus_ben_name);
    fd.append('cus_ben_cus_id', benefit.cus_ben_cus_id);
    fd.append('cus_ben_cus_ben_typ_id', benefit.cus_ben_cus_ben_typ_id);
    fd.append('cus_ben_description', benefit.cus_ben_description);
    fd.append('cus_ben_particular', benefit.cus_ben_particular);
    if (benefit.cus_ben_qty_beneficiaries)
      fd.append('cus_ben_qty_beneficiaries', benefit.cus_ben_qty_beneficiaries);
    if (benefit.cus_ben_code)
      fd.append('cus_ben_code', benefit.cus_ben_code);
    if (benefit.cus_ben_phone)
      fd.append('cus_ben_phone', benefit.cus_ben_phone);
    if (benefit.cus_ben_text_welcome)
      fd.append('cus_ben_text_welcome', benefit.cus_ben_text_welcome);

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postCrearCBenefits', fd, httpOptions).toPromise()
      return data;
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async loadBulkFile(file_data, bulk_data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      file_data,
      bulk_data,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/loadBulkFile', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async deleteMediaRoomLobby({ med_roo_lob_id }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      med_roo_lob_id
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/deleteMediaRoomLobby', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async deleteSubSpecialityDetail({ cus_ben_det_id }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      cus_ben_det_id
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/deleteSubSpecialityDetail', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async deleteProduct({ cus_ben_pro_id }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      cus_ben_pro_id
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/deleteProduct', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async insertProduct(params) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    try {
      let data = await this.http.post(URL_SERVICIOS + '/insertProduct', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }
}
