<div class="videoconsulta"> <!-- no responsive -->
  <div class="row" style="margin: 20px 0px;">
    <div class="col-md-4 col-sm-12">
      <app-title-header [title]='_title' [subtitle]='_subtitle'>
      </app-title-header>
    </div>
    <div class="col-md-8 col-sm-12 container-buttons">

      <div class="col-md-4 sm-12" style="margin-bottom: 5px;">
        <button *ngIf="asistencia_paciente" type="button" class="btn botonCancelar colorLetraVolver" data-toggle="modal"
          data-target="#ModalNoSePresenta">Paciente no asiste
        </button>
      </div>
      <div class="col-md-4 sm-12" style="margin-bottom: 5px;">
        <button *ngIf="asistencia_paciente" type="button" class="btn botonCancelar colorLetraVolver" data-toggle="modal"
          data-target="#eliminarPaciente" (click)="infoCancelaConsulta()"> Anular consulta</button>
      </div>
      <div class="col-md-4 sm-12" style="margin-bottom: 5px;">
        <button type="button" type="button" class="btn botonCancelar colorLetraVolver"
          (click)="verificacionFinalizar()">Finalizar consulta
        </button>
      </div>
    </div>
  </div>
  <!-- 
  <div class="botones"> 
    <div class="">
      <button *ngIf="asistencia_paciente" style="margin-right: 10px !important;"
        type="button" class="btn btn-outline-secondary mr-auto" 
        data-toggle="modal" data-target="#ModalNoSePresenta"
        >Paciente no se presenta
      </button>
      <button 
        type="button" class="btn btn-outline-secondary mr-auto" 
        (click)="verificacionFinalizar()">Finalizar consulta
      </button>
    </div>
  </div> -->

  <div class="row">

    <div class="col-md-4 col-sm-12">
      <div class="detalle-consulta">
        <h4 style="margin-bottom: 10px;">Hoy {{consulta.fecha_consulta.day}} de {{consulta.fecha_consulta.month}} - a
          las {{consulta.fecha_consulta.time}} horas</h4>
        <div class="align-self-center">
          <p class="card-text m-0 p-0">Paciente: {{consulta.nombre_paciente}} {{consulta.apellido_paterno_paciente}}
            {{consulta.apellido_materno_paciente}}</p>
          <p class="card-text m-0 p-0 ">Edad: {{consulta.edad}}</p>
          <p class="card-text m-0 p-0">Acompañante: {{consulta.nombre_acompanante}}
            {{consulta.apellido_paterno_acompanante}} {{consulta.apellido_materno_acompanante}}</p>
          <p class="card-text m-0 p-0">Área médica: {{consulta.spe_name}} </p>
        </div>
        <h4 style="margin: 12px 0">Motivo de consulta</h4>
        <div class="align-self-center">
          <p class="card-text m-0 p-0" style="padding: 10px auto;">{{consulta.razon}}</p>
        </div>
      </div>

      <div class="card border-confirm card-fono">
        <div class="card-block ">
          <p class="card-text m-0 p-0">Si tienes algún problema técnico, o el paciente no se presenta, debes comunicarte
            con nuestra Mesa de Ayuda.</p>
          <p *ngIf="consulta.telefono_plan" class="fono p-0">{{consulta.telefono_plan}}</p>
        </div>
      </div>


    </div>

    <div class="col-md-8 col-sm-12">
      <div id="video-icon-zoom"><mat-icon style="font-size: 70px;height: auto; width: auto;">videocam</mat-icon></div>
    </div>
  </div>

</div>

<div class="options">
  <div class="row" style="margin: 0;">
    <button class="col-md-3 col-sm-6" id="consulta" [class]="options[0] ? 'checked' : 'nochecked'"
      (click)="optionsButton(0)">Consulta actual y documentos</button>
    <button class="col-md-3 col-sm-6" [class]="options[1] ? 'checked' : 'nochecked'"
      (click)="optionsButton(1)">Antecedentes del paciente</button>
    <button class="col-md-3 col-sm-6" [class]="options[2] ? 'checked' : 'nochecked'"
      (click)="optionsButton(2)">Consultas previas del paciente</button>
    <button class="col-md-3 col-sm-6" id="agenda" [class]="options[3] ? 'checked' : 'nochecked'"
      (click)="optionsButton(3)">Mi agenda</button>
  </div>
</div>



<div *ngIf="options[0]">
  <app-ficha-medica [eventos]='ficha_eventos' [consulta]='consulta' (messageEvent)='retornaArchivosConsulta($event)'>

  </app-ficha-medica>
</div>

<!-- <div class="row d-none d-sm-flex"  *ngIf="options[2]">
  <div *ngFor="let appt of consultas_pasadas" class="col-12" style="margin-bottom: 30px;">
    <app-consultas-finalizadas
      [consulta]="appt" 
      (messageEvent)="verDetalle($event)">
    </app-consultas-finalizadas>
  </div>
</div> -->

<div *ngIf="options[1]">
  <app-antecedentes-paciente [consulta]='consulta'>
  </app-antecedentes-paciente>
</div>

<div *ngIf="options[2]">
  <app-consultas-previas [consulta]='consulta'>
  </app-consultas-previas>
</div>


<div style="margin-left: 5px;" id="agenda_medico" style="display: none;">
  <app-agenda-medico2 class="" (messageEvent)='clickHourAgendaMedico($event)'>
  </app-agenda-medico2>
</div>


<!-- Modales -->
<div class="modal fade ModalFinalizarConsulta" id="ModalFinalizarConsulta" tabindex="-1" role="dialog"
  aria-labelledby="ModalFinalizarConsultaLabel" aria-hidden="true">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="text-align: center;">Resumen de ficha médica</h2>
      </div>
      <div class="modal-body">
        <p style="font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 24px;
            color: #171994;">Los siguientes documentos fueron generados:</p>
        <div class="card" style="align-items: center;">
          <div>


            <input *ngIf="archivos_consulta.diagnostics.length>0" id="diag_id" class="filled-in checkbox-css"
              type="checkbox" checked="checked" disabled>
            <input *ngIf="archivos_consulta.diagnostics.length<1" id="diag_id" class="filled-in checkbox-css"
              type="checkbox" disabled>
            <label for="diag_id" class="texto-checkbox">Diagnóstico e indicaciones</label><br>

            <input *ngIf="archivos_consulta.prescriptions.length>0" id="pre_id" class="filled-in checkbox-css"
              type="checkbox" checked="checked" disabled>
            <input *ngIf="archivos_consulta.prescriptions.length<1" id="pre_id" class="filled-in checkbox-css"
              type="checkbox" disabled>
            <label for="pre_id" class="texto-checkbox">Receta médica</label><br>

            <input *ngIf="archivos_consulta.certificados.length>0" id="cert_id" class="filled-in checkbox-css"
              type="checkbox" checked="checked" disabled>
            <input *ngIf="archivos_consulta.certificados.length<1" id="cert_id" class="filled-in checkbox-css"
              type="checkbox" disabled>
            <label for="cert_id" class="texto-checkbox">Certificado médico</label><br>

            <input *ngIf="archivos_consulta.orders.length>0" id="ord_id" class="filled-in checkbox-css" type="checkbox"
              checked="checked" disabled>
            <input *ngIf="archivos_consulta.orders.length<1" id="ord_id" class="filled-in checkbox-css" type="checkbox"
              disabled>
            <label for="ord_id" class="texto-checkbox">Órdenes de exámenes</label><br>

            <input *ngIf="(documentos_consulta.length>0) || (documentos_consulta.att_att_typ_id != 'BOLETA')" id="docs_id" class="filled-in checkbox-css" type="checkbox"
              checked="checked" disabled>
            <input *ngIf="documentos_consulta.length<1" id="docs_id" class="filled-in checkbox-css" type="checkbox"
              disabled>
            <label for="docs_id" class="texto-checkbox">Documentos adjuntos</label><br>
          </div>
        </div>

        <div>

          <p style="font-family: Mulish;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;                
                  align-items: center;
                  text-align: center;
                  color: #000000;"> Al confirmar la finalización de la consulta <b>se enviará una notificación al
              paciente </b>señalando que los resultados han sido correctamente agregados. Recuerda que <b> siempre
              podrás modificar los resultados desde la sección de historial de consultas en el detalle de cada
              consulta.</b></p>

        </div>
      </div>
      <div class="modal-footer">
        <div style="width: 50%;">
          <button type="button" class="btn btn-volver" style="float: left;" data-dismiss="modal">
            <div style="margin: auto;">Volver</div>
          </button>
        </div>
        <div style="width: 50%;">
          <button class="btn btn-confirmar" (click)='finalizarVideoconsulta()' style="float: right;">
            <div style="margin: auto;">Finalizar consulta</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<button #MFinalizarConsulta type="button" class="btn d-none" data-toggle="modal" data-target="#ModalFinalizarConsulta">
  aux trigger for register success modal
</button>


<!-- Modales -->
<div class="modal fade ModalFinalizarConsulta" id="ModalNoSePresenta" tabindex="-1" role="dialog"
  aria-labelledby="ModalNoSePresentaLabel" aria-hidden="true">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="text-align: center;">¿Éstas seguro que deseas señalar que el paciente no se presentó?</h2>
      </div>
      <div class="modal-body">
        <!-- <p style="font-family: Mulish;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 18px;
                  line-height: 24px;
                  color: #171994;">Los siguientes documentos fueron generados:</p> -->
        <!-- <div class="card" style="align-items: center;">
          <div >
            
          
            <input *ngIf="archivos_consulta.diagnostics.length>0" id="diag_id" class="filled-in checkbox-css" type="checkbox" checked="checked" disabled >
            <input *ngIf="archivos_consulta.diagnostics.length<1" id="diag_id" class="filled-in checkbox-css" type="checkbox" disabled >
            <label for="diag_id" class="texto-checkbox">Diagnóstico e indicaciones</label><br>

            <input *ngIf="archivos_consulta.prescriptions.length>0" id="pre_id" class="filled-in checkbox-css" type="checkbox" checked="checked" disabled >
            <input *ngIf="archivos_consulta.prescriptions.length<1" id="pre_id" class="filled-in checkbox-css" type="checkbox" disabled >
            <label for="pre_id" class="texto-checkbox">Receta médica</label><br>

            <input *ngIf="archivos_consulta.certificados.length>0" id="cert_id" class="filled-in checkbox-css" type="checkbox" checked="checked" disabled >
            <input *ngIf="archivos_consulta.certificados.length<1" id="cert_id" class="filled-in checkbox-css" type="checkbox" disabled >
            <label for="cert_id" class="texto-checkbox">Certificado médico</label><br>

            <input *ngIf="archivos_consulta.orders.length>0" id="ord_id" class="filled-in checkbox-css" type="checkbox" checked="checked" disabled >
            <input *ngIf="archivos_consulta.orders.length<1" id="ord_id" class="filled-in checkbox-css" type="checkbox" disabled >
            <label for="ord_id" class="texto-checkbox">Órdenes de exámenes</label><br>

            <input *ngIf="documentos_consulta.length>0" id="docs_id" class="filled-in checkbox-css" type="checkbox" checked="checked" disabled >
            <input *ngIf="documentos_consulta.length<1" id="docs_id" class="filled-in checkbox-css" type="checkbox" disabled >
            <label for="docs_id" class="texto-checkbox">Documentos adjuntos</label><br>
        </div>
      </div> -->

        <div>

          <p style="font-family: Mulish;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;                
                  align-items: center;
                  text-align: center;
                  color: #000000;"> <b>Al realizar esto la consulta se dará por finalizada.</b></p>

        </div>
      </div>
      <div class="modal-footer">
        <div style="width: 50%;">
          <button type="button" class="btn btn-volver" style="float: left;" data-dismiss="modal">
            <div style="margin: auto;">Volver</div>
          </button>
        </div>
        <div style="width: 50%;">
          <button class="btn btn-confirmar" (click)="pacienteNoSePresenta()" style="float: right;">
            <div style="margin: auto;">Paciente no se presenta</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<button #MNoSePresenta type="button" class="btn d-none" data-toggle="modal" data-target="#ModalNoSePresenta">
  aux trigger for register success modal
</button>

<!-- MODAL ANULAR CONSULTA -->
<div class="modal fade" id="eliminarPaciente" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">Anulación de consulta</p>
        <br>
        <div class="form col-md-12" id="ConsultaCancelarData" autocomplete="off">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 border-card-modal">
            <div class="m-0 p-0 first-line">
              <div class="img-cirlce-wrapper">
                <p class="m-0 p-0 text-default">
                  {{ pacienteName }}{{ pacienteLastName }}
                </p>
              </div>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ consulta.nombre_paciente }} {{
                  consulta.apellido_paterno_paciente }} {{consulta.apellido_materno_paciente}}</p>
                <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{
                  consulta.fecha_consulta.day }} de {{ consulta.fecha_consulta.month}} a las
                  {{consulta.fecha_consulta.time}} horas.</p>
                <p class="card-text m-0 p-0 ">{{ consulta.spe_name }}</p>
                <p class="card-text m-0 p-0 "> </p>
              </div>
            </div>
            <br>
          </div>
          <br><br>
          <div class="form-group">
            <textarea class="form-control" placeholder="Motivo de la cancelación*" [(ngModel)]="infoConsulta.comentario" rows="5" id="comentario"></textarea>
          </div>
          <!-- <div class="row">
            <div class="col text-danger">
              <p *ngIf="campoNoValido('comentario')"> El motivo de la cancelación obligatorio</p>
            </div>
          </div> -->
          <br>
          <br>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar
                  formulario</button>
              </div>
              <div class="col-md-6 letrabotonagregar">
                <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-toggle="modal"
                  data-target="#confirmaAnular">Cancelar consulta</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL CONFIRMA ANULAR CONSULTA -->
<div  class="modal fade" id="confirmaAnular" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">¿Esta seguro que desea anular la consulta?</p>
        <br>
        <br><br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                data-dismiss="modal">Cerrar</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" (click)="confirmaEliminar()" class="btn btn-outline-primary btn-lg botonModalAgregar"
                data-dismiss="modal" data-toggle="modal" data-target="#exitoAnulacion">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>