import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }


  async agreementTypes({ agre_typ_id_ = '' }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      agre_typ_id_,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/agreementTypes', params, httpOptions).toPromise();
      return data;
    }

    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async agreement({ agre_id_ = '', route = '' }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      agre_id_,
      route,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/agreement', params, httpOptions).toPromise();
      return data;
    }

    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }
}
