import { Component, OnInit } from '@angular/core';
import { URL_DOCS } from 'src/app/config/config';
import { DocumentsService } from 'src/app/services/documents.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';


@Component({
  selector: 'app-documents-consult',
  templateUrl: './documents-consult.component.html',
  styleUrls: ['./documents-consult.component.css']
})
export class DocumentsConsultComponent implements OnInit {
  fecha_sistema = new Date();

  ruta:any = '';
  doc = null;
  documentos: any={};
  URL_DOCUMENTS = URL_DOCS;

  usuario: any = {};

  constructor(
    private _documentServices: DocumentsService,
    public _general: GeneralService,
  ) { }

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    let use_id = this.usuario._id;

    this._documentServices.postListaMisArchivosConsulta()
      .then(result => { 
        this.documentos = result;
        // console.log(this.documentos);
        
        for (let docum in this.documentos){ //numero correlativo para los documentos de una misma consultaId
          let ind = 1;
          let boo_id = 0;
          for (let file of this.documentos[docum]){
            if( boo_id == file.app_boo_id ){
              ind++;
            }
            else {
              ind = 1;
            }
            let ind_st = (100+ind).toString().substring(1)
            file.file_name_desc = file.file_name.replace('XX', ind_st)
            boo_id = file.app_boo_id;
          }
        }
      })
      .catch(error => { 
        // console.log(error)
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar documentos pasados.',
          timer: 2000
        })
      })
  }

  update_doc(){
    // console.log('hola');
    this.ngOnInit();
    
  }



}
