import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { discountCode } from 'src/app/interfaces/discount.interface';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-table-discount-code',
  templateUrl: './table-discount-code.component.html',
  styleUrls: ['./table-discount-code.component.css']
})
export class TableDiscountCodeComponent implements OnInit {
  @Input()ELEMENT_DATA: discountCode[] = [];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  displayedColumns: string[] = ['dis_id', 'dis_code', 'fecha_inicio', 
                                'fecha_termino', 'status', 'dis_typ_name',
                                'dis_value', 'cantidad_usos', 'dis_limit', 
                                'specialities', 'created_by'];
  dataSource = new MatTableDataSource <discountCode>(this.ELEMENT_DATA);


  fileName = 'ExcelFile.xlsx';
  

  constructor() { }

  
  ngOnChanges(changes) {
    // console.log(changes, 'changes-ngOnChanges');
    if (changes.ELEMENT_DATA) {
      this.dataSource = new MatTableDataSource<discountCode>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement ).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage()
    }
  }

  ngOnInit(): void {
    // console.log(this.ELEMENT_DATA, 'jkakjjaka');
  }

  exportexcel(){
    let element = document.getElementById('excel-codes');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);  
  }

}
