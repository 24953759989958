
<div *ngIf="evento">
 
<div class="row">
  <div class="col-4"> <!-- Medico -->
     <div class="day-available" *ngIf="!day_available"><!-- -->
      <span>Selecciona un día con horas disponibles</span>
    </div>
    <div class="day-available" *ngIf="day_available"><!-- -->
      <span>Día: {{ day_available_date }}</span>
      <br><br>
      <table>
        <tr *ngFor='let hor of hours_available_day'>
          <td>{{ hor.sch_hou_from }} - {{ hor.sch_hou_to }}</td>
          <td *ngIf="hor.estado_hora == 'RESERVADA'">
            RESERVADA<!-- <button title="Ir a la consulta" type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" routerLink="/meetingRoom">{{ hor.estado_hora }}</button> -->
          </td>
          <td *ngIf="hor.estado_hora != 'RESERVADA'">{{ hor.estado_hora }}</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="col-8">
    <div class="card-calendar">
      <app-calendar class="agenda-user-calendar"
        [eventos] = 'eventos'
        (messageEvent)='receiveMessage($event)'>
      </app-calendar>
    </div>
    
    <mat-icon style="color: #98d7e2;">check_box_outline_blank</mat-icon> Días con horas 
    <mat-icon style="color: #585858; margin-left: 20px;">check_box_outline_blank</mat-icon> Días sin horas
  </div>
  
</div>

</div>


