<div class="row">
    <div class="col-md-12">
        <br>
        <div class="titulo">
            Estado de consultas
        </div>
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <input autocomplete="disabled" type="search" placeholder='Numero de consulta, paciente, médico…'
                    class="estiloInput inputStyle" [(ngModel)]="info_input">
            </div>
            <div class="col-md-6">
                <ng-container *ngIf="dropdownListArea.length > 0">
                    <ng-multiselect-dropdown [placeholder]="'Seleccione Especialidad'" [settings]="dropdownSettingsArea"
                        [data]="dropdownListArea" [(ngModel)]="selectedItemsArea" (onSelect)="onItemSelectArea($event)"
                        (onSelectAll)="onSelectAllArea($event)">
                    </ng-multiselect-dropdown>
                </ng-container>
            </div>
            <div class="col-md-6">
                <ng-container *ngIf="dropdownList.length > 0">
                    <ng-multiselect-dropdown [placeholder]="'Seleccione Estados'" [settings]="dropdownSettings"
                        [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </ng-container>
            </div>
            <div class="col-md-6">
                <ng-container *ngIf="dropdownListCustomer.length > 0">
                    <ng-multiselect-dropdown [placeholder]="'Seleccione Planes'" [settings]="dropdownSettingsCustomer"
                        [data]="dropdownListCustomer" [(ngModel)]="selectedItemsCustomer"
                        (onSelect)="onItemSelectCustomer($event)" (onSelectAll)="onSelectAllCustomer($event)">
                    </ng-multiselect-dropdown>
                </ng-container>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-6"><input autocomplete="disabled" type="search" [(ngModel)]="f_desde"
                            placeholder='Fecha desde dd/mm/aaaa' class="estiloInput inputStyle"></div>
                    <div class="col-6"><input autocomplete="disabled" type="search" [(ngModel)]="f_hasta"
                            placeholder='Fecha hasta dd/mm/aaaa' class="estiloInput inputStyle"></div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-6">
                        <button type="buton" (click)="Exportar()"
                            class="btn btn-outline-primary botonExportar">Exportar</button>
                    </div>
                    <div class="col-6">

                        <button type="buton" (click)="ListarLoading()"
                            class="btn btn-outline-primary botonListar">Listar</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="col-md-12">
        <div class="row">
        </div>
    </div>
    <div class="row">
        <div class="card col-md-12">
            <div class="card-body bordeCardBeneficiario">
                <div class="flex-row comment-row">
                    <div *ngIf="this.estados_dia.length > 0">
                        <div style="overflow-x:auto;">
                            <table class="table">
                                <thead
                                    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 24px; color: #171994;align-items: center; text-align: center;background-color:#3166d84a;">
                                    <tr>
                                        <th>Número</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Paciente</th>
                                        <th>Médico</th>
                                        <th>Área médica</th>
                                        <th>Estado</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody
                                    style="font-family: Mulish; font-size: 18px;color: #3F3F3F; align-items: center; text-align: center;">
                                    <tr *ngFor="let item of this.consultas_descarga" ngDefaultControl>
                                        <td>{{item.id_consulta}}</td>
                                        <td>{{item.fecha_consulta}}</td>
                                        <td>{{item.hora_consulta}}</td>
                                        <td>{{item.nombre_paciente}}</td>
                                        <td>{{item.nombre_medico}}</td>
                                        <td>{{item.area_medica}}</td>
                                        <td>{{item.estado_consulta}}</td>
                                        <td>
                                            <div class="row"
                                                style="margin-right: 0; margin-left: 0px; justify-content: center;">
                                                <div class="col-6">
                                                    <span data-toggle="modal" data-target="#previsualizarEstadoConsulta"
                                                        style="cursor: pointer;" (click)='clickConsulta(item)'>
                                                        <mat-icon style="color:#316AD8;">
                                                            manage_search
                                                        </mat-icon>                                                    </span>
                                                </div>
                                                <ng-container *ngIf="usuario.typ_id == 8">
                                                    <div>
                                                        <span data-toggle="modal" data-target="#modificarEstadoConsulta"
                                                            style="cursor: pointer;" (click)='clickConsulta(item)'>
                                                            <mat-icon style="color:#316AD8;">
                                                                settings
                                                            </mat-icon> 
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <br><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Buscador de nombre pcaiente, medico o numero de consultas <input autocomplete="off" type="text">
area Medica <select name="" id=""></select>
estado de la consultas <select name="" id=""></select>
fecha desde date
fecha hasta date
hora desde
hora hasta
boton listar
boton exportar (a excel) -->


<!-- modal pre-visualizar-->
<!-- <div class="modal fade" id="modificarEstadoConsulta" aria-labelledby="exampleModalLabel" aria-hidden="true"
    #previsualizarEstadoConsulta>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="estiloBodyModal">
                    <p class="tituloModal" style="margin: 0%;width: auto;">Detalle de la consulta</p>
                    <hr class="estiloHrModal">

                    <p class="subTituloModal" style="margin: 0%;width: auto;">Estado de la consulta</p>
                    <div class="divBodyModal row">
                        <div class="col-4">
                            <p class="letraNormalModal">Estado: {{consulta_selected.estado_consulta}}</p>

                        </div>


                    </div>
                    <hr class="estiloHrModal">


                </div>
                <div class="container-fluid" style="padding: 10px 10px;align-items:center;text-align: center;">
                    <button type="button" class="btn btn-secondary letraBotonModal" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="form-group modal fade" id="modificarEstadoConsulta" aria-labelledby="modificarEstadoConsulta"
    aria-hidden="true" tabindex="-1">
    <div class="form-group modal-dialog modalUpdateAppo">
        <div class="row">
            <div class="col-12" style="align-self: center">
                <p class="modalTitle">Modificar Estado</p>
            </div>
        </div>

        <ng-container>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 cardConsulta">
                <div class="row align-items-center">
                    <div class="col-8 ml-3">
                        <b style="font-weight: 700;">Fecha de la consulta:
                        </b><span>{{consulta_selected.fecha_consulta}}</span>
                        <br>
                        <b style="font-weight: 700;">Hora de la consulta:
                        </b><span>{{consulta_selected.hora_consulta}}</span>
                        <br>
                        <b style="font-weight: 700;">Paciente: </b><span>{{consulta_selected.nombre_paciente}}</span>
                        <br>
                        <b style="font-weight: 700;">Medico: </b><span>{{consulta_selected.nombre_medico}}</span>
                        <br>
                        <b style="font-weight: 700;">Area Médica: </b><span>{{consulta_selected.area_medica}}</span>
                        <br>
                        <b style="font-weight: 700;">N° de consulta: </b><span>{{consulta_selected.id_consulta}}</span>
                        <br>
                    </div>
                    <div class="col-3">
                        <h3 class="nombretitular" style="color: #316AD8;">
                            <span> {{consulta_selected.estado_consulta | titlecase}}</span>
                        </h3>
                    </div>
                </div>
            </div>
        </ng-container>

        <br>



        <div class="row cardModalTable" style="align-items: center;"
            *ngIf="typificationSelected && typificationSelected.typifications">
            <div class="col-3 divLeftModal">
                <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Estado Actual: </label>
            </div>
            <div class="col-4 divRightModal">
                <!-- <p class="modalTableSubTitle pDivModalContent">"{{infoPost.price | currency:'CLP'}}"</p> -->
                <span>{{consulta_selected.estado_consulta | titlecase}}</span>
            </div>
            <br /><br />
        </div>


        <form (ngSubmit)="changeStatus()" autocomplete="off" [formGroup]="statusAppo"
            *ngIf="typificationSelected && typificationSelected.typifications">


            <ng-container>
                <div class="row cardModalTable" style="align-items: center;">
                    <div class="col-3 divLeftModal">
                        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Nuevo Estado*: </label>
                    </div>

                    <div class="col-6 divRightModal form-group">
                        <select id="inputState" class="form-control" formControlName="status">
                            <option value="" selected>Seleccione nuevo estado...</option>
                            <ng-container *ngFor="let typification of typificationSelected.typifications">
                                <option [value]="typification.typ_id">{{typification.sta_name}} -
                                    {{typification.typ_description}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="row cardModalTable" style="align-items: center;">
                    <div class="col-3 divLeftModal">
                        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Observación*: </label>
                    </div>

                    <div class="col-6 divRightModal form-group">
                        <textarea id="reason" class="form-control" formControlName="reason">
                        </textarea>
                    </div>
                </div>
            </ng-container>


            <br>
            <div class="container">
                <label for="flexCheckDefault" style="display: flex; justify-content: center;">
                    El cambio de estado se encuentra limitado por el estado de origen de la consulta.
                </label>
            </div>
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row" style="justify-content: space-around;">
                        <div class="col-md-4 sm-12">
                            <button type="button" class="btn btn-secondary botonCancelar colorLetraVolver"
                                data-dismiss="modal" data-toggle="modal">
                                Volver atras
                            </button>
                        </div>
                        <div class="col-md-4 sm-12 letrabotonagregar">
                            <button type="submit" (click)="changeStatus()" class="btn btn-primary botonVolver">
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <ng-container *ngIf="consulta_selected.estado_consulta == 'CANCELADA'">
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row" style="justify-content: space-around;">
                        <div class="col-md-4 sm-12">
                            <button type="button" class="btn btn-secondary botonCancelar botonVolver colorLetraVolver"
                                data-dismiss="modal" data-toggle="modal">
                                Volver atras
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>


    </div>
</div>

<button #triModificarEstadoConsulta type="button" class="btn d-none" data-toggle="modal"
    data-target="#modificarEstadoConsulta">
    aux trigger for recovery error modal
</button>

<button #triAceptaModificarStatus type="button" class="btn d-none" data-toggle="modal"
    data-target="#aceptaModificarStatus">
    aux trigger for recovery error modal
</button>

<!--modal-->

<!-- modal confirmacion cambio estado -->


<div class="modal fade" id="aceptaModificarStatus" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="form-group modal-dialog modalConfirmAppo">

        <p></p>
        <br />
        <div class="form-group row ">
            <div class="row-md-12">
                <p class="tituloModal">
                    ¿Estás seguro que deseas guardar estos cambios?
                </p>
                <p class="tituloModal"></p>
            </div>
        </div>
        <br />

        <div>
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row" style="justify-content: space-around;">
                        <div class="col-md-4 sm-12">
                            <button type="button" class="btn btn-secondary botonCancelar colorLetraVolver"
                                data-dismiss="modal" data-toggle="modal" data-target="#modificarEstadoConsulta">
                                Volver atras
                            </button>
                        </div>
                        <div class="col-md-4 sm-12 letrabotonagregar">
                            <button type="button" (click)="confirmChangeStatus()" class="btn btn-primary botonVolver">
                                Modificar estado
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- modal pre-visualizar-->
<div class="modal fade" id="previsualizarEstadoConsulta" aria-labelledby="exampleModalLabel" aria-hidden="true"
    #previsualizarEstadoConsulta>
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="estiloBodyModal">
                    <p class="tituloModal" style="margin: 0%;width: auto;">Detalle de la consulta</p>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos de la consulta</p>
                    <div class="divBodyModal row">
                        <div class="col-4">
                            <p class="letraNormalModal">Número: {{consulta_selected.id_consulta}}</p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Fecha: {{consulta_selected.fecha_consulta}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Hora: {{consulta_selected.hora_consulta}}</p>

                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Motivo: {{consulta_selected.motivo_consulta}}</p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Estado de la consulta</p>
                    <div class="divBodyModal row">
                        <div class="col-4">
                            <p class="letraNormalModal">Estado: {{consulta_selected.estado_consulta}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Dispositivo paciente:
                                {{consulta_selected.dispositivo_paciente}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Conexión del paciente:
                                {{consulta_selected.conexion_paciente}}
                            </p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Conexión del médico: {{consulta_selected.conexion_medico}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Hora conexión del paciente:
                                {{consulta_selected.hora_conexion_paciente}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Hora conexión del médico:
                                {{consulta_selected.hora_conexion_medico}}</p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos del paciente</p>
                    <div class="divBodyModal row">
                        <div class="col-4">
                            <p class="letraNormalModal">Nombre: {{consulta_selected.nombre_paciente}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">RUT: {{consulta_selected.rut_paciente}}</p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Fecha de nacimiento:
                                {{consulta_selected.nacimiento_paciente}}
                            </p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Teléfono: {{consulta_selected.telefono_paciente}}</p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Correo: {{consulta_selected.correo_paciente}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Tipo de beneficiario:
                                {{consulta_selected.tipo_beneficiario}}
                            </p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos del médico</p>
                    <div class="divBodyModal row">
                        <div class="col-6">
                            <p class="letraNormalModal">Nombre: {{consulta_selected.nombre_medico}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">RUT: {{consulta_selected.rut_medico}}</p>
                        </div>
                        <div class="col-6">
                            <p class="letraNormalModal">Teléfono: {{consulta_selected.telefono_medico}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Correo: {{consulta_selected.correo_medico}}</p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos de la encuesta</p>
                    <div class="divBodyModal row">
                        <div class="col-4">
                            <p class="letraNormalModal">Atención del médico:
                                {{consulta_selected.atencion_medico_encuesta}}</p>
                            <p class="letraNormalModal">Calidad videollamada:
                                {{consulta_selected.calidad_llamada_encuesta}}</p>
                        </div>
                        <div class="col-4">
                            <!-- <br> -->
                            <p class="letraNormalModal">Usabilidad plataforma:
                                {{consulta_selected.usabilidad_plataforma_encuesta}}</p>
                            <p class="letraNormalModal">NPS:
                                {{consulta_selected.nps}}</p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Comentario: {{consulta_selected.comentario_encuesta}}</p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos de la anulación</p>
                    <div class="divBodyModal row">
                        <div class="col-4">
                            <p class="letraNormalModal">Perfil que anula: {{consulta_selected.perfil_anulacion}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Medio de anulación: {{consulta_selected.medio_anulacion}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Fecha de anulación: {{consulta_selected.fecha_anulacion}}
                            </p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Hora de anulación: {{consulta_selected.hora_anulacion}}</p>
                        </div>
                        <div class="col-4">
                            <p class="letraNormalModal">Motivo: {{consulta_selected.motivo_anulacion}}</p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos del agendamiento</p>
                    <div class="divBodyModal row">
                        <div class="col-6">
                            <p class="letraNormalModal">Fecha: {{consulta_selected.fecha_agendamiento}}</p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Hora: {{consulta_selected.hora_agendamiento}}</p>
                        </div>
                        <div class="col-6">
                            <p class="letraNormalModal">Perfil que agenda: {{consulta_selected.perfil_agendamiento}}
                            </p>
                            <!-- <br> -->
                            <p class="letraNormalModal">Ejecutivo que agenda:
                                {{consulta_selected.ejecutivo_agendamiento}}</p>

                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->
                    <p class="subTituloModal" style="margin: 0%;width: auto;">Datos confirmación de asistencia</p>
                    <div class="divBodyModal row">
                        <div class="col-6">
                            <p class="letraNormalModal">Confirma asistencia:
                                {{consulta_selected.estado_confirmacion}}
                            </p>
                            <p class="letraNormalModal">Canal de confirmación:
                                {{consulta_selected.canal_confirmacion}}
                            </p>
                        </div>
                        <div class="col-6">
                            <p class="letraNormalModal">Fecha: {{consulta_selected.fecha_confirmacion}}</p>
                            <p class="letraNormalModal">Hora: {{consulta_selected.hora_confirmacion}}</p>
                        </div>
                    </div>
                    <hr class="estiloHrModal">
                    <!-- -------------------------------------------------- -->

                </div>
                <div class="container-fluid" style="padding: 10px 10px;align-items:center;text-align: center;">
                    <button type="button" class="btn btn-secondary letraBotonModal" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--modal-->