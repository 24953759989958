<app-title-header [title]='_title' [subtitle]='_subtitle'>
</app-title-header>



<div class="col-md-12">
  <div class="row">
    <div class="col-md-6">
      <input autocomplete="disabled" type="search" placeholder='N° de Rut...' class="estiloInput inputStyle"
        [(ngModel)]="use_rut" (input)="changeRut()">
    </div>
    <div class="col-md-6 ">
      <ng-multiselect-dropdown [placeholder]="'Seleccione un cliente'" [settings]="dropdownSettings"
        [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)">
      </ng-multiselect-dropdown>
    </div>
    
    <div class="col-md-6"><input autocomplete="disabled" type="search" [(ngModel)]="f_desde"
        placeholder='Fecha desde dd/mm/aaaa' class="estiloInput inputStyle"></div>
    <div class="col-md-6"><input autocomplete="disabled" type="search" [(ngModel)]="f_hasta"
        placeholder='Fecha hasta dd/mm/aaaa' class="estiloInput inputStyle"></div>
    <div class="col-md-12 asistenciaInput">
      <div class="row">
        <div class="col-md-3 col-sm-12" style="padding: 5px;"><button (click)="ExportarExcel()" class="btn btn-outline-primary botonExportar" style="background-color: #107C41;
          border-color: #107C41;">Exportar a
            Excel</button></div>
        <div class="col-md-3 col-sm-12" style="padding: 5px;"><button (click)="ExportarCSV()" class="btn btn-outline-primary botonExportar">Exportar a
            CSV</button></div>
        <div class="col-md-6 col-sm-12" style="padding: 5px;"><button (click)="ListarLoading()" class="btn btn-outline-primary botonListar">Listar</button>
        </div>
      </div>
    </div>

    <div class="col-md-8" style="padding: 5px;">
      <input autocomplete="disabled" type="search" [(ngModel)]="input_email" placeholder='ingrese correo'
        class="estiloInput inputStyle">
    </div>
    <div class="col-md-4">
      <div class="row">
        <div class="col-12" style="padding: 5px;">
          <button type="button" data-dismiss="modal" data-toggle="modal" class="btn btn-outline-primary botonExportar"
            data-target="#aceptaEnviarEmail">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="aceptaEnviarEmail" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row">
          <div class="col-md-12">
            <p class="tituloModal">
              Se enviara por correo la informacion solicitada
            </p>
            <p class="subTituloModal">
              ¿Esta seguro de esta acción? </p>
            </div>
        </div>
        <br /><br />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal"
                data-toggle="modal">
                Cancelar
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="button" (click)="emailLoading()" class="btn btn-primary botonModalModificar"
                data-dismiss="modal" data-toggle="modal">
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table class="table table-sm table-hover" *ngIf="this.activaciones.length > 0">
  <thead
    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 24px; color: #171994;align-items: center; text-align: center;background-color:#3166d84a;">
    <tr>
      <th scope="col">Rut</th>
      <th scope="col">Nombre</th>
      <th scope="col">Apellido</th>
      <th scope="col">Fecha creación</th>
      <th scope="col">Cliente</th>
      <th scope="col">Activo</th>
      
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let activacion of activaciones">
      <td>{{activacion.rut}}</td>
      <td>{{activacion.nombre}}</td>
      <td>{{activacion.apellido_paterno}}</td>
      <td>{{activacion.fecha_activacion}}</td>
      <td>{{activacion.cliente}}</td>
      <td>{{activacion.activo}}</td>


    </tr>
  </tbody>
</table>