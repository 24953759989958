<!-- MEDICO -->
<div *ngIf="(_usuario.typ_id == 1)" class="card h-100 p-0 m-0" [ngClass]="{'border-confirm': data.confirmFlag, 'border-go-lobby': data.goLobbyFlag}">
  <div class="card-block px-2 px-sm-3 pt-2">
    <div class="m-0 p-0 first-line">
      <app-profile-img 
        [img]="data.img" 
        [doctorName]="data.nombre_paciente" 
        [doctorLastName]="data.apellido_paterno_paciente"
        [color]="data.goLobbyFlag ? 'goLobby' : data.confirmFlag ? 'goLobby' : 'goLobby'">
      </app-profile-img>
      <div class="ml-3 align-self-center">
        <p class="card-text m-0 p-0 appt-date">{{ data.fecha_consulta| date:'dd-MM-yyyy' }} a las {{ data.hora|slice:0:5}}</p>
        <p class="card-text m-0 p-0 ">{{ data.spec }} </p>
        <p class="card-text m-0 p-0 ">{{ data.nombre_paciente }} {{ data.apellido_paterno_paciente }} {{data.apellido_materno_paciente}} </p>
      </div>
    </div>
    <br>
    <div class="d-flex align-items-end justify-content-end flex-wrap">
      <!-- <button 
        type="button" class="btn btn-primary mr-auto" 
        (click)="goConsulta()">Ir a la consulta
      </button> -->
      <button type="button" class="btn btn-primary mr-auto" style="float: left;" (click)="goConsulta()" >Ir a la consulta</button>

      <p style="font-family: Mulish;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 24px;
                  text-align: center;       
                  color: #B661F4;" 
                  class="card-text flag-helper appt-date">No finalizada</p>
    </div>
  </div>
</div>
