<div class="col-12 mt-3 text-center text-sm-left title-header" *ngIf="(title == 'Mi agenda' || !(title == 'Videoconsulta' || title == 'Mi videoconsulta'))">
    <span>{{title}}</span>
    <h2>{{subtitle}}</h2>
</div>

<div class="mt-3 text-center text-sm-left title-header" *ngIf="(title == 'Videoconsulta' || title == 'Mi videoconsulta')">
    <h2>{{title}}</h2>
    <h3>{{subtitle}}</h3>
</div>

