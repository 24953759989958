import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UsuarioService } from '../../services/usuario.service';
import { error } from 'protractor';
import { LoginValidationsService } from '../login-validations.service';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('auxCloseSignupModal') auxCloseSignupModal: ElementRef;
  @ViewChild('trigRegSuccModal') trigRegSuccModal: ElementRef;
  @ViewChild('trigRegErrorModal') trigRegErrorModal: ElementRef;
  @ViewChild('auxBirthDate') trigAuxBirthDate: ElementRef;
  @ViewChild('trigInvalidVerificationCodeModal') trigInvalidVerificationCodeModal: ElementRef;


  public step: number = 0;
  public step1Flag: boolean = false;
  public step2Flag: boolean = false;
  public fieldTextType1: boolean = false;
  public fieldTextType2: boolean = false;
  public passLength: number = 0;
  public passMinLength: boolean = false;
  public passCapital: boolean = false;
  public passLetter: boolean = false;
  public passNumber: boolean = false;
  public equalPass: boolean = false;
  // flags placeholders
  //step1
  public signupRutFocus: boolean = false;
  public signupNameFocus: boolean = false;
  public signupLastNameFocus: boolean = false;
  public signupMLastNameFocus: boolean = false;
  public signupGenderFocus: boolean = false;
  public signupBirthDateFocus: boolean = false;
  //step2
  public signupEmailFocus: boolean = false;
  public signupTelefonoFocus: boolean = false;
  public signupCountryFocus: boolean = false;
  public signupRegionFocus: boolean = false;
  public signupMunicipalityFocus: boolean = false; 
  public signupStreetFocus: boolean = false; 
  public signupNumberAddressFocus: boolean = false; 
  public signupNumberDepartmentFocus: boolean = false; 

  //step3
  public signupPass1Focus: boolean = false;
  public signupPass2Focus: boolean = false;
  public signupcodVerificationFocus: boolean = false;

  public fecha_prueba: any;
  public dia_nac: any;
  public mes_nac: any;
  public ano_nac: any;
  public message_error: string = "";

  public token_recaptcha: string;
  generos: string[] = ['Masculino', 'Femenino', 'Prefiero no decirlo'];
  areaCodes: any[] = [];
  selectedAreaCode: any = { cou_phone_code: '56', cou_flag: 'fi-cl', cou_min_phone: 9, cou_max_phone: 9 };

  paises: any[] = [];
  regiones: any[] = [];
  comunas: any[] = [];

  verificationCode: String;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private renderer: Renderer2,
    private loginValidationsService: LoginValidationsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private generalService: GeneralService

  ) {



  }
  ngOnInit() {
    this.usuarioService.getCodPaises()
    .then((data: any) =>{
      this.areaCodes = data as any[];
      // console.log('codigos pais:', this.areaCodes);

    });

    this.usuarioService.getPaises().then(paises => {
      this.paises = paises as any[]; // Asegúrate de asignar a un arreglo
      // console.log('Paises:', this.paises);

      this.registerForm.get('pais').valueChanges.subscribe(cou_id => {
        this.usuarioService.getRegiones(cou_id).then(regiones => {
          this.regiones = regiones as any[]; // Asegúrate de asignar a un arreglo
          // console.log('Regiones:', this.regiones);
          this.comunas = [];
        }).catch(error => {
          // console.error('Error al cargar las regiones:', error);
        });
      });
    }).catch(error => {
      console.error('Error al cargar los países:', error);
    });

    this.registerForm.get('region').valueChanges.subscribe(reg_id => {
      this.usuarioService.getComunas(reg_id).then(comunas => {
        this.comunas = comunas as any[]; // Asegúrate de asignar a un arreglo
        // console.log('Comunas:', this.comunas);
      }).catch(error => {
        console.error('Error al cargar las comunas:', error);
      });
    });
  }
  dropdownOpen: boolean = false;

  selectAreaCode(areaCode: any) {
    this.selectedAreaCode = areaCode;
    this.registerForm.get('codigo_area').setValue(areaCode.cou_phone_code);
    this.dropdownOpen = false;
    // console.log(this.selectedAreaCode);
    this.updatePhoneValidators();
  }

  updatePhoneValidators() {
    const telefonoControl = this.registerForm.get('telefono');
    telefonoControl.setValidators([
      Validators.required,
      Validators.minLength(this.selectedAreaCode.cou_min_phone),
      Validators.maxLength(this.selectedAreaCode.cou_max_phone),
      Validators.pattern("^[0-9]*$")
    ]);
    telefonoControl.updateValueAndValidity();
  }


  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown() {
    this.dropdownOpen = false;
  }

  public formSubmitted = false;
  public formSubmittedAux = false;

  public registerForm = this.fb.group({
    rut: ['', [Validators.required, this.loginValidationsService.validaRut]],
    nombre: ['', [Validators.required, Validators.minLength(3)]],
    apellido_paterno: ['', [Validators.required, Validators.minLength(3)]],
    apellido_materno: ['', [Validators.required, Validators.minLength(3)]],
    genero: ['', Validators.required],
    fecha_nacimiento: ['', Validators.required],
    dia_nac: ['', Validators.required],
    mes_nac: ['', Validators.required],
    ano_nac: ['', Validators.required],
    codigo_area: [this.selectedAreaCode.cou_phone_code, Validators.required],
    telefono: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    email: ['', [Validators.required, Validators.email, Validators.minLength(3)]],
    pass1: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    pass2: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    use_sta_id: [18],
    idtipoUsuario: [2],
    // idRegion: [999],
    // idProvincia: [999],
    // idComuna: [999],
    pais: ['', Validators.required],
    region: ['', Validators.required],
    comuna: ['', Validators.required],
    calle: ['', Validators.required],
    numeroCasa: ['', Validators.required],
    rest: [''],
    notificaciones: [false, []],
    terminos: [false, [Validators.requiredTrue]],
    token_recaptcha: [''],
    codigo_verificacion: ['',Validators.required],

  }, {
    validators: this.passwordsIguales('pass1', 'pass2')
  })

  checkPass1 = () => {
    const pass1: string = this.registerForm.get('pass1').value;
    this.passLength = pass1.length;
    if (this.passLength >= 8) {
      this.passMinLength = true;
    } else {
      this.passMinLength = false;
    }
    if (pass1.match(/[A-Z]/g)) {
      this.passCapital = true;
    } else {
      this.passCapital = false;
    }
    if (pass1.match(/[a-z]/g)) {
      this.passLetter = true;
    } else {
      this.passLetter = false;
    }
    if (pass1.match(/[0-9]/g)) {
      this.passNumber = true;
    } else {
      this.passNumber = false;
    }
  }

  validarFecha() {

    let fecha_ext = this.mes_nac + '/' + this.dia_nac + '/' + this.ano_nac;
    try {
      this.fecha_prueba = new Date(fecha_ext);//MM/DD/YYYY
      let fecha_nac = formatDate(this.fecha_prueba, 'dd-yyyy-MM', 'en-US');

      let fecha_sistema_aux = new Date();
      let fecha_sistema_aux2 = formatDate(fecha_sistema_aux, 'dd-MM-yyyy', 'en-US');
      ////console.log(this.fecha_prueba.getDate());
      ////console.log(this.dia_nac);

      if (this.fecha_prueba > fecha_sistema_aux || this.dia_nac != this.fecha_prueba.getDate()) {
        ////console.log("Fecha Inválida");
        return false;

      } else {
        ////console.log("Fecha Menor");
        ////console.log(fecha_nac)
        this.registerForm.controls['fecha_nacimiento'].setValue(fecha_nac);
        return true;
      }
    } catch (error) {
      ////console.log("Error al crear Fecha");
      return false;
    }

    return false;
  }

  signupStep(step: number) {
    // console.log(this.registerForm.value);
    
    if (step === 1) {
      const valRut: boolean = this.registerForm.get('rut').invalid;
      const valNombre: boolean = this.registerForm.get('nombre').invalid;
      const valApellidoPaterno: boolean = this.registerForm.get('apellido_paterno').invalid;
      const valApellidoMaterno: boolean = this.registerForm.get('apellido_materno').invalid;
      const valFechaNacimiento: boolean = this.validarFecha();
      const valGenero: boolean = this.registerForm.get('genero').invalid;
      if (valRut || valNombre || valApellidoPaterno || valApellidoMaterno || !valFechaNacimiento || valGenero) {
        this.step1Flag = true;
      } else {
        this.step1Flag = false;
        this.step = step;
      }
    } else if (step === 2) {
      const valEmail: boolean = this.registerForm.get('email').invalid;
      const valTelefono: boolean = this.registerForm.get('telefono').invalid;
      const valCodArea: boolean = this.registerForm.get('codigo_area').invalid;
      const valPais: boolean = this.registerForm.get('pais').invalid;
      const valRegion: boolean = this.registerForm.get('region').invalid;
      const valComuna: boolean = this.registerForm.get('comuna').invalid;
      const valCalle: boolean = this.registerForm.get('calle').invalid;
      const valNumero: boolean = this.registerForm.get('numeroCasa').invalid;
      const valNumeroDepto: boolean = this.registerForm.get('rest').invalid;

      if (valEmail || valTelefono || valCodArea || valPais || valRegion || valComuna || valCalle || valNumero) {
        this.step1Flag = true;
      } else {
        this.step1Flag = false;
        this.step = step;
      }
    } else {
      this.step = step;
    }
  }
  goBack(): void {
    // Método para ir al paso anterior
    this.step--;
  }

  private lastRequestTime: number | null = null;
  private cooldownPeriod = 5 * 60 * 1000;

  requestCode() {
    const currentTime = Date.now();

    if (this.lastRequestTime && (currentTime - this.lastRequestTime) < this.cooldownPeriod) {
      this.generalService.modalError(
        'El código de verificación ya fue solicitado.',
        'Podrás solicitar un nuevo código dentro de 5 minutos más.'
      );
    } else {
      const rut = this.registerForm.get('rut').value;
      const codigoArea = this.registerForm.get('codigo_area').value;
      const telefono = this.registerForm.get('telefono').value;
      const email = this.registerForm.get('email').value;
      const nombre = this.registerForm.get('nombre').value;
      const apellido_paterno = this.registerForm.get('apellido_paterno').value;

      const telefonoCompleto = codigoArea + telefono;
  
      this.usuarioService.verificationCode(rut, telefonoCompleto, email, nombre, apellido_paterno)
        .then((data: any) => {
          this.verificationCode = data;
          // console.log('codigo: ', this.verificationCode);
        })
        .catch(error => {
          console.error('Error al solicitar el código de verificación:', error);
        });
      this.lastRequestTime = currentTime;
      this.generalService.modalExitoso(
        'Código de verificación solicitado con éxito.',
        'Podrás solicitar un nuevo código dentro de 5 minutos más.'
      );
      this.disableButton();
    }
  }

  disableButton() {
    const button = document.getElementById('requestCodeButton') as HTMLButtonElement;
    if (button) {
      button.disabled = true;
      setTimeout(() => {
        button.disabled = false;
      }, this.cooldownPeriod);
    }
  }

  auxClickBirthDate() {
    this.renderer.setAttribute(this.trigAuxBirthDate.nativeElement, 'type', 'date');
    this.renderer.selectRootElement(this.trigAuxBirthDate.nativeElement).focus();
  }

  toggleFieldTextType1 = () => {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  toggleFieldTextType2 = () => {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  // estoy importando el usuario service del servico de usuario. de esta forma puedo ocupar los metodos que defino en ese servicio
  crearUsuario() {
    this.formSubmitted = true;
    this.formSubmittedAux = true;
  
    // Limpiar datos del formulario
    this.registerForm.controls['nombre'].setValue(this.registerForm.get('nombre').value.split('.').join(''));
    this.registerForm.controls['apellido_paterno'].setValue(this.registerForm.get('apellido_paterno').value.split('.').join(''));
    this.registerForm.controls['apellido_materno'].setValue(this.registerForm.get('apellido_materno').value.split('.').join(''));
    this.registerForm.controls['nombre'].setValue(this.registerForm.get('nombre').value.trim());
    this.registerForm.controls['apellido_paterno'].setValue(this.registerForm.get('apellido_paterno').value.trim());
    this.registerForm.controls['apellido_materno'].setValue(this.registerForm.get('apellido_materno').value.trim());
  

    if (!this.registerForm.get('terminos').value) {
      return;
    }
    if (this.registerForm.invalid || (this.registerForm.invalid && !this.validarFecha())) {
      return;
    }
  
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    });
    Swal.showLoading();
  
    this.recaptchaV3Service.execute('recaptcha')
  .subscribe((token_recaptcha: string) => {
    // Asignar el token reCAPTCHA al formulario
    this.registerForm.controls['token_recaptcha'].setValue(token_recaptcha);

    // Llamar al servicio para crear el usuario
    this.usuarioService.crearUsuario(this.registerForm.value)
      .subscribe(resp => {
        // Si la creación del usuario fue exitosa
        Swal.close();
        this.renderer.selectRootElement(this.auxCloseSignupModal.nativeElement).click();
        this.renderer.selectRootElement(this.trigRegSuccModal.nativeElement).click();
        this.formSubmittedAux = false;
        // console.log('Usuario creado exitosamente');
      }, (err) => {
        // Si hay un error al crear el usuario
        Swal.close();
        this.message_error = err.error.message;
        this.renderer.selectRootElement(this.auxCloseSignupModal.nativeElement).click();
        this.renderer.selectRootElement(this.trigRegErrorModal.nativeElement).click();
      });
  });
  }
  

  campoNoValido(campo: string): boolean {
    const valInvalid: boolean = this.registerForm.get(campo).invalid;
    if (valInvalid) {
      return true;
    } else {
      return false;
    }
  }

  contrasenasNoValidas() {
    const password1 = this.registerForm.get('pass1').value;
    const password2 = this.registerForm.get('pass2').value;

    if ((password1 !== password2) && this.formSubmitted) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    } else {
      return false;
    }
  }

  aceptaTerminos() {
    return !this.registerForm.get('terminos').value && this.formSubmittedAux;
  }

  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      this.formSubmitted = false;
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value !== pass2Control.value) {
        return {
          noEsIgual: true
        };
      }
    }
  }

  changeRut() {
    // Despejar Puntos
    var rut_Cliente = this.registerForm.get('rut').value
    var valor = rut_Cliente;
    //console.log(this.registerForm.get('rut').value)
    //reemplazo los puntos por vacio
    let resultado = valor.split('.').join('');
    // reemplazo los - por vacio
    let resultado2 = resultado.split('-').join('');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    // Formatear RUN
    if (valor != "") {
      this.registerForm.controls['rut'].setValue(cuerpo + "-" + dv);
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;

    // Para cada dígito del Cuerpo
    for (i = 1; i <= cuerpo.length; i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado
    dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
      //console.log("changeRut invalido");
    }
    else {
      //console.log("changeRut valido");
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
  }

}
