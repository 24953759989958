<app-title-header 
  [title]='_title' 
  [subtitle]='_subtitle'>
</app-title-header>


<div class="disponibilidad-horario" style="min-width: 800px;">

  <div class="box-container">
    <div class="btns-week row row-cols-7" >
      <button *ngFor="let item of weekdays" 
        class="col"
        [ngClass]="(item.selected== true)? 'dia-seleccionado':'dia-no-seleccionado'"
        (click)="clickDayWeek(item.date)">{{item.name}}
      </button>
    </div>

    <div class="not-hours" *ngIf="(dia_disponible == false)" style="margin-top: 60px;">
      <div class="d-flex">
        <mat-icon style="color:#171994;">info</mat-icon>
        <span>No tienes horarios disponibles.</span>
      </div>
    </div>

    <div class="blocks-hour" style="margin-top: 60px;" *ngIf="(dia_disponible == true)">
      <div class="" *ngFor="let itemHour of hours_selected_day">
        <button style="cursor: default;"
          [ngClass]="(!itemHour.sta_id)? 'hour-btn-bloqueada': (itemHour.sta_id == 21)? 'hour-btn-disponible': (itemHour.sta_id == 24)? 'hour-btn-agendada': 'hour-btn-bloqueada' ">
          {{ itemHour.from }}
        </button>
      </div>
    </div>
  </div>

</div>