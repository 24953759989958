<!-- {{ usuario.typ_name }} -->
    <div class="col-md-12">
        <br>
        <div class="minititulo">
             Videoconsulta
        </div>
        <div class="titulo">
             Mi sala de espera
        </div>
       <br>
    </div>


<div *ngIf="usuario.typ_name == 'MEDICO'" class="row">
    <div class="card col-md-12">
        <div class="card-body bordeCardBeneficiario">
            <div class="flex-row comment-row" >
                <div *ngIf="consultas.length > 0">
                    <div *ngIf="usuario.typ_name == 'MEDICO'" style="overflow-x:auto;">
                            <table class="table">
                                <thead style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 24px; color: #171994;">
                                    <tr>
                                        <th>Paciente</th>
                                        <th>Rut</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Consulta</th>
                                    </tr>
                                </thead>
                                <tbody style="font-family: Mulish; font-size: 20px;color: #3F3F3F">
                                        <tr *ngFor="let item of consultas" ngDefaultControl >
                                            <td>{{item.nombre_paciente}} {{item.apellido_paterno_paciente}} {{item.apellido_materno_paciente}}</td>
                                            <td>{{item.rut_paciente}}</td>
                                            <td>{{item.fecha_consulta| date:'dd-MM-yyyy'}}</td>
                                            <td>{{item.hora}}</td>
                                            <td><button class="btn btn-outline-primary btn-lg botonModalAgregar" 
                                                        style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                                                        (click)="unirseReunionDoctor(item)">Ir a la Consulta</button></td>
                                        </tr>

                                </tbody>
                            </table>

                            <br><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="usuario.typ_name == 'MEDICO'">
    <div *ngIf="consultas.length > 0" class="row">
        <div class="col-md-12 card-alerta" *ngIf="consultas.length == 0">
            <table>
                <tr>
                    <td><mat-icon style="color:#171994;font-size: 30px;margin: 20px 40px 10px;">info</mat-icon>   </td>
                    <td><p class="letras-alerta" style="margin: 20px 5px 10px; font-weight: bold;">No tienes consultas para el día de hoy</p>
                    </td>

                </tr>
            </table>
        </div>
    </div>
</div>
<div *ngIf="usuario.typ_name == 'PACIENTE'">
    <div *ngIf="consultas.length > 0" class="row">
        <div class="col-md-12 card-alerta" *ngIf="consultas.length == 0">
            <table>
                <tr>
                    <td><mat-icon style="color:#171994;font-size: 30px;margin: 20px 40px 10px;">info</mat-icon>   </td>
                    <td><p class="letras-alerta" style="margin: 20px 5px 10px; font-weight: bold;">No tienes consultas para el día de hoy</p>
                    </td>

                </tr>
            </table>
        </div>
    </div>
</div>

    <!-- paciente -->
    <div class="col-md-12" *ngIf="usuario.typ_name == 'PACIENTE'" style="overflow-x:auto;">
        <div class="card bordeCardBeneficiario" style="margin-top: 20px;" *ngFor="let item of consultas">
            <div class="card-body ">
            <section>
                <form class="form-material row" autocomplete ="off">
                    <div class="form-group">
                </div>
                    <div class="form-group col-lg-3 col-md-6 col-sm-6 datospaciente">
                        <label for="nombre" class="col-md-12 col-form-label colorletra">Medico</label>
                        {{item.nombre_doctor}} {{item.apellido_paterno_doctor}} {{item.apellido_materno_doctor}}</div>
                    <div class="form-group col-lg-3 col-md-6 col-sm-6 datospaciente">
                        <label class="col-md-12 col-form-label colorletra">Especialidad</label>
                        <!-- <input type="text" class="form-control form-control-line datospaciente" disabled>-->{{item.spe_name}}</div>
                    <div class="form-group col-lg-2 col-md-6 col-sm-6 datospaciente">
                        <label class="col-md-12 col-form-label colorletra">Fecha</label>
                        <!-- <input type="text" class="form-control form-control-line datospaciente" disabled>-->{{item.fecha_consulta| date:'dd-MM-yyyy'}}</div>
                    <div class="form-group col-lg-2 col-md-6 col-sm-6 datospaciente">
                        <label class="col-md-12 col-form-label colorletra">Hora</label>
                        <!-- <input type="text" class="form-control form-control-line datospaciente" disabled>-->{{item.hora}}</div>
                    <div class="form-group col-lg-2 col-md-12 col-sm-12 datospaciente">
                        <label  class="col-md-12 col-form-label colorletra">Consulta</label>
                        <button class="btn btn-outline-primary botonModalAgregar" 
                                style="border-radius: 30px; width: 100%; font-size: 18px; color: #ffffff; font-weight: bold;height: auto;white-space: normal;"
                                (click)="unirseReunionPaciente(item)">Ir a la sala 
                                de espera</button>
                    </div>
                    <!-- <div class="form-group col-md-12">
                        <hr class="m-t-0 m-b-40 linea-color"> 
                    </div> -->
                </form>
               </section>
            </div>
        </div>
        <!-- EDITAR PERFIL DEL MEDICO NUEVO ESTILO -->
    </div>


<!--<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-8 col-xs-12">
                            <h3 style="font-size: 28px; font-weight: bold; color: #5cd6ff" >Mi Sala de Espera</h3>
                        </div>
                    </div>
                </div>
            <div *ngIf="consultas.length > 0">

                <div *ngIf="usuario.typ_name == 'PACIENTE'">
                    <div class="card-body">
                        <table class="table">
                            <thead style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 24px; color: #171994;">
                                <tr>
                                    <th>Medico</th>
                                    <th>Especialidad</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Consulta</th>
                                </tr>
                            </thead>
                            <tbody style="font-size: 20px;color: #000000">

                                     <th scope="row">15</th> 
                                    <tr *ngFor="let item of consultas" ngDefaultControl>
                                        <td>{{item.nombre_doctor}} {{item.apellido_paterno_doctor}} {{item.apellido_materno_doctor}}</td>
                                        <td>{{item.spe_name}}</td>
                                        <td>{{item.fecha_consulta| date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.hora}}</td>
                                        <td><button *ngIf="item.tel_doc_app_boo_sta_id==30" class="btn btn-outline-primary" 
                                                    style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                                                    (click)="unirseReunionPaciente(item)">Unirse a la Consulta</button>
                                            <button *ngIf="item.tel_doc_app_boo_sta_id==10" class="btn btn-outline-primary" 
                                                    style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                                                    (click)="ConfirmarAsistencia(item)">Confirmar Asistencia</button>
                                        </td>
                                    </tr>

                            </tbody>
                        </table>

                        <br><br>
                    </div>
                </div>





                <div *ngIf="usuario.typ_name == 'MEDICO'">
                    <div class="card-body">
                        <table class="table">
                            <thead style="font-size: 20px; font-weight: bold; color: #000000">
                                <tr>
                                    <th>Paciente</th>
                                    <th>Rut</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Consulta</th>
                                </tr>
                            </thead>
                            <tbody style="font-size: 20px;color: #000000">

                                    <!-- <th scope="row">15</th> 
                                    <tr *ngFor="let item of consultas" ngDefaultControl >
                                        <td>{{item.nombre_paciente}} {{item.apellido_paterno_paciente}} {{item.apellido_materno_paciente}}</td>
                                        <td>{{item.rut_paciente}}</td>
                                        <td>{{item.fecha_consulta| date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.hora}}</td>
                                        <td><button class="btn btn-outline-primary" 
                                                    style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                                                    (click)="unirseReunionDoctor(item)">Ver la Consulta</button></td>
                                    </tr>

                            </tbody>
                        </table>

                        <br><br>
                    </div>
                </div>
            </div>

            <div *ngIf="consultas.length == 0">
                <p>No tiene consultas para este día</p>
            </div>

            </div>
        </div>
    </div>
</div> -->

<!--
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-8 col-xs-12">
                            <h3 style="font-size: 28px; font-weight: bold; color: #5cd6ff">Mis Reuniones Grupales</h3>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <table class="table">
                        <thead style="font-size: 20px;font-weight: bold; color: #000000">
                            <tr>
                                <th>Medico</th>
                                <th>Especialidad</th>
                                <th>Fecha</th>
                                <th>Hora</th>
                                <th>Paciente</th>
                                <th>Consulta</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 20px;color: #000000">
                            <tr>
                                <td>JOSE SOTO</td>
                                <td>Medicina General</td>
                                <td>25-01-2021</td>
                                <td>19:30</td>
                                <td>Gloria González</td>
                                <td><button class="btn btn-outline-primary"
                                            style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                                            (click)="unirseReunion()">Unirse a la Consulta</button></td>
                            </tr>
                        </tbody>
                    </table>

                    <br><br>
                </div>

            </div>
        </div>
    </div>
</div> -->