

<!-- <div class="row">
  <div class="col">
    <button type="button" class="updatebtn"
                style="float: right;width: auto;"(click)="update_doc()">
                <p style="margin: 0 25px;">
                    Actualizar
                </p>
            </button>
  </div>
</div> -->
  
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active" id="nav-exm-tab" data-toggle="tab" href="#nav-exm" role="tab" aria-controls="nav-exm" aria-selected="true">Diagnostico e indicaciones</a>
    <a class="nav-item nav-link" id="nav-odc-tab" data-toggle="tab" href="#nav-odc" role="tab" aria-controls="nav-odc" aria-selected="false">Mis órdenes de exámenes</a>
    <a class="nav-item nav-link" id="nav-rcm-tab" data-toggle="tab" href="#nav-rcm" role="tab" aria-controls="nav-rcm" aria-selected="false">Mis recetas médicas</a>
    <a class="nav-item nav-link" id="nav-exa-tab" data-toggle="tab" href="#nav-exa" role="tab" aria-controls="nav-exa" aria-selected="false">Mis certificados médicos</a>
    <a class="nav-item nav-link" id="nav-bol-tab" data-toggle="tab" href="#nav-bol" role="tab" aria-controls="nav-bol" aria-selected="false">Boletas</a>
    <a class="nav-item nav-link" id="nav-otros-tab" data-toggle="tab" href="#nav-otros" role="tab" aria-controls="nav-otros" aria-selected="false">Otros documentos</a>

  </div>
</nav>

<div class="tab-content" id="nav-tabContent">

  <div class="tab-pane fade show active" id="nav-exm" role="tabpanel" aria-labelledby="nav-exm-tab">
    <!-- tab1 -->
    <br>
    <div class="row">

      <div class="col-sm-6" *ngFor="let item of documentos.diagnosticos" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.file_name_desc}}</h4>
            </div> 
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="card-btn d-flex"> <mat-icon>file_download</mat-icon> Descargar documento</button>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="tab-pane fade" id="nav-otros" role="tabpanel" aria-labelledby="nav-otros-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of documentos.otros" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.file_name_desc}}</h4>
            </div>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="card-btn d-flex"> <mat-icon>file_download</mat-icon> Descargar documento</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="nav-bol" role="tabpanel" aria-labelledby="nav-bol-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of documentos.boletas" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.file_name_desc}}</h4>
            </div>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="card-btn d-flex"> <mat-icon>file_download</mat-icon> Descargar documento</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="nav-exa" role="tabpanel" aria-labelledby="nav-exa-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of documentos.certificados" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.file_name_desc}}</h4>
            </div> 
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="card-btn d-flex"><mat-icon>file_download</mat-icon> Descargar documento</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="nav-odc" role="tabpanel" aria-labelledby="nav-odc-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of documentos.examenes" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.file_name_desc}}</h4>
            </div>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="card-btn d-flex"><mat-icon>file_download</mat-icon> Descargar documento</button>
          </div>
        </div>
      </div> 
    </div>
  </div>

  <div class="tab-pane fade" id="nav-rcm" role="tabpanel" aria-labelledby="nav-rcm-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of documentos.recetas" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.file_name_desc}}</h4>
            </div>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="card-btn d-flex"><mat-icon>file_download</mat-icon>Descargar documento</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>




<div class="tab-content nav-tabs">

  <div id="menu3" class="tab-pane fade">
  <br>
    
  </div>
</div>





