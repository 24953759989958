<!-- Recovery password modal - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="recoveryModal" tabindex="-1" role="dialog" aria-labelledby="recoveryModal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-end">
                <button type="button" #closeRecoveryModal class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <mat-icon style="font-size: 30px;">cancel</mat-icon>
                    </span>
                </button>
            </div>
            <div class="modal-body modal-body-recovery">
                <div class="container-fluid m-0 p-0 login-recovery">
                    <form [formGroup]="recoveryForm" class="m-0 p-0" id="recoveryform" autocomplete="off"
                        (ngSubmit)="recovery()">
                        <h3 class="recovery-title text-center">Olvidé mi contraseña</h3>
                        <div class="recovery-desc text-left">
                            <span>Si no puedes ingresar a tu cuenta porque olvidaste tu contraseña, puedes recuperarla
                                ingresando tu RUT
                                en
                                el siguiente campo:</span>
                        </div>
                        <!-- rut -->
                        <div class="form-group row recovery-input-wrapper">
                            <div class="col-12 m-0 recovery-custom-input"
                                [ngClass]="{'focus-custom-input': recoveryRutFocus}">
                                <label *ngIf="recoveryRutFocus || recoveryForm.get('rut').value?.length" for="email"
                                    class="{{ recoveryRutFocus ? 'blue' : 'gray' }}">
                                    Ingresa tu RUT
                                </label>
                                <input class="form-control" type="text" formControlName="rut" id="recoveryRut"
                                    placeholder="Ingresa tu RUT" onfocus="this.placeholder = '';"
                                    onblur="this.placeholder = 'Ingresa tu RUT';" (focus)="recoveryRutFocus=true;"
                                    (input)="changeRut()" (blur)="recoveryRutFocus=false;">
                            </div>
                        </div>
                        <p *ngIf="recoveryForm.get('rut').invalid" class="mb-0 pb-0 mt-1" style="display: flex; align-items: center;"
                            [ngClass]="{'field-help': !formSubmitted, 'field-error': formSubmitted}">
                            <mat-icon style="font-size: 20px;" *ngIf="formSubmitted">cancel</mat-icon>
                            Debes ingresar un RUT válido.
                        </p>
                        <div class=" text-left recovery-inst">
                            <span>Al seleccionar el botón “Recuperar mi contraseña” te enviaremos un correo
                                electrónico
                                a la dirección asociada a tu cuenta, donde te daremos las instrucciones para volver
                                a
                                ingresar.</span>
                        </div>
                        <div class="form-group text-center recovery-btn">
                            <div class="col-12">
                                <button class="btn" type="submit">
                                    Recuperar contraseña
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Recovery password modal -->

<!-- Recovery password success modal - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="recoverySuccessModal" tabindex="-1" role="dialog"
    aria-labelledby="recoverySuccessModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 p-0">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/face-happy.svg" alt="Responsive image">
                    <p class="m-0 px-0 px-sm-5">
                        Acabamos de enviar un correo electrónico a
                        <span>{{ testEmail }}</span>
                        <span *ngIf="testPhone"> y un mensaje por WhatsApp a <span>{{ testPhone }}</span></span>
                        para recuperar tu contraseña.
                    </p>
                    <br>
                    <p class="m-0 px-0 px-sm-5">
                        <span>Recuerda revisar en tu carpeta de Recibidos o Spam <span *ngIf="testPhone"> y tus mensajes de WhatsApp</span>.</span>
                    </p>
                    <button class="btn" type="button" data-dismiss="modal" aria-label="Close">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>

<button #trigRecSuccModal type="button" class="btn d-none" data-toggle="modal" data-target="#recoverySuccessModal">
    aux trigger for recovery success modal
</button>
<!-- ============================================================== -->
<!-- End Recovery password success modal -->

<!-- Recovery password success modal - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="recoveryErrorModal" tabindex="-1" role="dialog" aria-labelledby="recoveryErrorModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 p-0">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/face-sad.svg" alt="Responsive image">
                    <div class="m-0 px-1 px-sm-5">
                        <p class="m-0 p-0">Lo sentimos, tuvimos un problema para crear tu cuenta.</p>
                        <br>
                        <p class="m-0 p-0">Por favor, vuelve a intentar.</p>
                    </div>
                    <button class="btn" type="button" data-dismiss="modal" aria-label="Close">Volver a intentar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<button #trigRecErrorModal type="button" class="btn d-none" data-toggle="modal" data-target="#recoveryErrorModal">
    aux trigger for recovery error modal
</button>
<!-- ============================================================== -->
<!-- End Recovery password success modal -->