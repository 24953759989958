import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nopagefound',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.css']
})
export class NopagefoundComponent {

  year = new Date().getFullYear();

  constructor(
    private router: Router
  ) { }

  mandarLogin(){
    var url = "login/";
    this.router.navigate([url]);
  }

}
