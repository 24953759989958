<h1 style="font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #3F3F3F;">Mis documentos</h1>
<h1 style="font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 38px;
            line-height: 48px;
            color: #0E0087;">Recetas y otros</h1>
<app-documents-consult></app-documents-consult>