<div class="row steps mt-3">
    <div class="col-12 col-sm-10 ml-sm-2">
        <div class="row pt-1 ml-1">
            <div class="col-6 col-sm"
                [ngClass]="{'d-none d-sm-block': steps.selectDate || steps.completeForm || steps.showSummary || steps.payment || (!steps.showSummary && showSuccessMessage)}">
                <div class="row p-sm-10">
                    <div class="col-2 m-0 p-0">
                        <div class="number" [ngClass]="{'activeNumber': steps.searchDoctor}">1</div>
                    </div>
                    <div class="col m-0 p-0">
                        <p class="description" [ngClass]="{'activeDescription': steps.searchDoctor}">Busca tu médico
                            <br>o especialidad</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm"
                [ngClass]="{'d-none d-sm-block': steps.completeForm || steps.showSummary || steps.payment || (!steps.showSummary && showSuccessMessage)}">
                <div class="row p-sm-10">
                    <div class="col-2 m-0 p-0">
                        <div class="number" [ngClass]="{'activeNumber': steps.selectDate}">2</div>
                    </div>
                    <div class="col m-0 p-0">
                        <p class="description" [ngClass]="{'activeDescription': steps.selectDate}">Selecciona<br> tu
                            hora y fecha</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm" [ngClass]="{'d-none d-sm-block': steps.searchDoctor || steps.payment || steps.showSummary}">
                <div class="row p-sm-10">
                    <div class="col-2 m-0 p-0">
                        <div class="number" [ngClass]="{'activeNumber': steps.completeForm}">3</div>
                    </div>
                    <div class="col m-0 p-0">
                        <p class="description" [ngClass]="{'activeDescription': steps.completeForm}">Completa
                            el<br>formulario de reserva</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm" [ngClass]="{'d-none d-sm-block':  steps.searchDoctor || steps.selectDate}">
                <div class="row p-sm-10">
                    <div class="col-2 m-0 p-0">
                        <div class="number" [ngClass]="{'activeNumber': steps.payment}">4</div>
                    </div>
                    <div class="col m-0 p-0">
                        <p class="description" [ngClass]="{'activeDescription': steps.payment}">Datos<br>del pago</p>
                    </div>
                </div>
            </div>

            <div class="col-6 col-sm" [ngClass]="{'d-none d-sm-block': steps.searchDoctor || steps.selectDate || steps.completeForm}">
                <div class="row p-sm-10">
                    <div class="col-2 m-0 p-0">
                        <div class="number" [ngClass]="{'activeNumber': steps.showSummary || showSuccessMessage}">5</div>
                    </div>
                    <div class="col m-0 p-0">
                        <p class="description" [ngClass]="{'activeDescription': steps.showSummary}">Resumen<br> de tu reserva</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>