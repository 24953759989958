import { Component, Input, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { ZoomService } from 'src/app/services/zoom.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { ScheduleService } from '../../services/schedule.service';
import { GestionService } from 'src/app/services/gestion.service';
import { PendingSurveysService } from 'src/app/services/pending-surveys.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../../assets/css/styles.css', './dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @Input() med_con_id: any;
  @Input() consulta: any = {};

  number_page: number = -1;
  calificaciones = [0, 0, 0, 0,];
  comentario: String = "";
  
  public usuario: any;
  public tipo_usuario_name: string;

  public appointments: any[] = [];
  public auxAppts: any[] = [];
  private auxApptsPos: number = 0;


  public pendingAppointmentList: any[] = [];
  public pendingSurveysList: any[] = [];
  public auxPending: any[] = [];
  private auxPendingPos: number = 0;
  public apptDataPending: any = {};

  public pendingAppointment_selected: any = {};

  public disponibilidadKPI: any = [];
  public activacionesKPI: any = {};
  public evaluacionesKPI: any = {};



  consultas_acomp: Array<any> = [];
  auxAppts_acomp: Array<any> = [];

  public info_usuario = this.fb.group({
    use_id: ['', [Validators.required]],
    con_ana_page: ['', [Validators.required]],
    con_ana_connection_type: ['', [Validators.required]],
    con_ana_connection_mode: ['', [Validators.required]]
  });

  constructor(
    public _usuarioService: UsuarioService,
    private fb: FormBuilder,
    private router: Router,
    private _fichaMedicaServices: FichaMedicaService,
    private _zoomServices: ZoomService,
    private scheduleService: ScheduleService,
    private gestionService: GestionService,
    private pendingSurveys: PendingSurveysService,
    // private _generalServices: GeneralService
  ) { }

  ngOnInit() {
    this.usuario = new EncriptacionService().getUserInfo();

    this.tipo_usuario_name = new EncriptacionService().getUserInfo().typ_name;
    // if (this.usuario.typ_id == 11 || this.usuario.typ_id == 12) {
    //   return;
    // }
    // this.proximasHoras();
    if (this.usuario.typ_id == 2) {
      this.getListarConsultas();
      this.pendingAppointment();
      this.getListarConsultasAcompanante();
      this.infoUsuario();
      this.getPendingSurveys()
      
    }
    if (this.usuario.typ_id == 1) {
      this.getListarConsultas();
      this.infoUsuario();
    }

    if (this.usuario.typ_id == 13) {
      this.scheduleDisponibilityKPI();
      this.activatedUsersKPI();
      this.surveyListKPI();
    }
    

  }

  infoUsuario() {
    var ua = navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(ua)) {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
      this.info_usuario.controls['use_id'].setValue(this.usuario._id);
      this.info_usuario.controls['con_ana_page'].setValue('Dashboard');
      this.info_usuario.controls['con_ana_connection_type'].setValue('IOS');
      this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
      //  //console.log(this.info_usuario.value);
      this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe((data: any) => {
          //console.log("registro almacenado");

        })

    }
    else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
      this.info_usuario.controls['use_id'].setValue(this.usuario._id);
      this.info_usuario.controls['con_ana_page'].setValue('Dashboard');
      this.info_usuario.controls['con_ana_connection_type'].setValue('Android');
      this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
      //  //console.log(this.info_usuario.value);
      this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe((data: any) => {
          //console.log("registro almacenado");

        })
    }
    else {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
      this.info_usuario.controls['use_id'].setValue(this.usuario._id);
      this.info_usuario.controls['con_ana_page'].setValue('Dashboard');
      this.info_usuario.controls['con_ana_connection_type'].setValue('Desktop');
      this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
      //  //console.log(this.info_usuario.value);
      this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe((data: any) => {
          // //console.log("registro almacenado");

        })
    }
  }

  getListarConsultasAcompanante() {
    this._zoomServices.getListarConsultasAcompanante()
      .then((data: any) => {

        if (!(data.length > 0)) {
          // //console.log('Sin proximas consultas acompanante.');
        } else {
          let consultas2 = data.sort((a, b) => {
            if (a.fecha_consulta > b.fecha_consulta) {
              return 1;
            } else if (a.fecha_consulta < b.fecha_consulta) {
              return -1;
            } else {
              if (a.hora > b.hora) {
                return 1;
              } else if (a.hora < b.hora) {
                return -1;
              } else {
                return 0;
              }
            }
          });

          // for (let appt of consultas){
          //   this.consultas_acomp.push(appt)
          //   if (this.consultas_acomp.length <= 2) {
          //     this.auxAppts.push(appt);
          //   }
          // }

          for (let i = 0; i < consultas2.length; i++) {
            let appt = consultas2[i];
            let hour = appt.hora.substr(0, 2);
            let minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-', '/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            let now = new Date();
            let formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              let msToAppt = this.timeToX(apptDate, now);
              let minToAppt = this.msToMin(msToAppt);
              if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
                confirmFlag = true;
              }
              let goLobbyFlag: boolean = false;
              if (minToAppt <= 60) {
                goLobbyFlag = true;
              }
              let apptData = {
                acompanante: true,
                date: formatedApptDate,
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
                goLobbyFlag,
                id_consulta: appt.id_consulta,
                zoo_mee_room_id: appt.zoo_mee_room_id,
                med_con_id: appt.med_con_id,
                nombre_paciente: appt.nombre_paciente,
                apellido_paterno_paciente: appt.apellido_paterno_paciente,
                apellido_materno_paciente: appt.apellido_materno_paciente,
                consulta: appt

              };
              this.consultas_acomp.push(apptData);
              if (this.consultas_acomp.length <= 2) {
                this.auxAppts_acomp.push(apptData);
              }
            }
          }

        }
      })
      .catch(e => {
        console.log(e)
      })

  }

  getListarConsultas = () => {
    this._fichaMedicaServices.getListarConsultas()
      .then((result: any) => {
        // console.log(result);

        if (!(result.consultas.length > 0)) {
          ////console.log('Sin proximas consultas.');
        } else {
          const consultas = result.consultas.sort((a, b) => {
            if (a.fecha_consulta > b.fecha_consulta) {
              return 1;
            } else if (a.fecha_consulta < b.fecha_consulta) {
              return -1;
            } else {
              if (a.hora > b.hora) {
                return 1;
              } else if (a.hora < b.hora) {
                return -1;
              } else {
                return 0;
              }
            }
          });
          for (let i = 0; i < consultas.length; i++) {
            const appt = consultas[i];
            const hour = appt.hora.substr(0, 2);
            const minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-', '/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            const now = new Date();
            const formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              const msToAppt = this.timeToX(apptDate, now);
              const minToAppt = this.msToMin(msToAppt);
              if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
                confirmFlag = true;
              }
              let goLobbyFlag: boolean = false;
              if (minToAppt <= 15) {
                goLobbyFlag = true;
              }
              const apptData = {
                acompanante: false,
                date: formatedApptDate,
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30 || appt.tel_doc_app_boo_sta_id === 44,
                goLobbyFlag,
                id_consulta: appt.id_consulta,
                zoo_mee_room_id: appt.zoo_mee_room_id,
                med_con_id: appt.med_con_id,
                nombre_paciente: appt.nombre_paciente,
                apellido_paterno_paciente: appt.apellido_paterno_paciente,
                apellido_materno_paciente: appt.apellido_materno_paciente,
                consulta: appt

              };
              this.appointments.push(apptData);
              if (this.appointments.length <= 2) {
                this.auxAppts.push(apptData);
              }
            }
          }
        }
      })
      .catch(e => (e));
  }

  pendingAppointment() {
    this.scheduleService.getListarConsultasPendientes(null)
      .then((data: any) => {
        this.pendingAppointmentList = data;
        this.auxPending = [];

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (index < 2) {
            this.auxPending.push(element);

          }
        }

      })
      .catch(e => console.error(e));
  }

  getPendingSurveys() {
    this.pendingSurveys.postPendingSurveys()
    .then((data: any) => {
      this.pendingSurveysList = data.pendingSurveys || [];
      // console.log(this.pendingSurveysList);
  
      // Verificar si hay encuestas pendientes
      if (this.pendingSurveysList.length > 0) {
        // Obtener la última encuesta de la lista
        const lastSurvey = this.pendingSurveysList[0];
        
        // Verificar si el campo tel_doc_app_boo_survey_skipped está en false
        if (!lastSurvey.tel_doc_app_boo_survey_skipped) {
          // Mostrar el modal solo si tel_doc_app_boo_survey_skipped es false
          this.showSurveyModal();
        }
      }
    });
  }
  
  
  
  showSurveyModal() {
    // Obtener el modal
    const modal = document.getElementById('surveyModal');
    
    // Mostrar el modal
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
    }
  }
  
  closeSurveyModal() {
    // Obtener el modal
    const modal = document.getElementById('surveyModal');
    
    // Cerrar el modal
    if (modal) {
      modal.style.display = 'none';
    }
  }  
  
  onModalClosed() {
    window.location.reload();
    // this.ngOnInit();
  }
  
  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    ////console.log(minToAppt);

    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];

    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Hoy ' + returnDate[0];
    }

    const auxApptDateHour = apptDate.getHours();
    const auxApptDateMin = apptDate.getMinutes();
    let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
    let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
    returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;

    if (minToAppt < -15) {
      return [];
    }

    /*
       if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
      if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Hoy ' + returnDate[0];
    }
    if (minToAppt < 0 && minToAppt >=-15) {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (minToAppt < -15) {
      return [];
    }
    }
    */
    return returnDate;
  }

  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  nextCard = (step: number) => {
    this.auxApptsPos = this.auxApptsPos + step;
    let plus = this.auxApptsPos + Math.abs(step);
    if (this.auxApptsPos < 0) {
      this.auxApptsPos = this.appointments.length - 1;
      plus = 0;
    } else if (this.auxApptsPos >= this.appointments.length) {
      this.auxApptsPos = 0;
      plus = 1;
    }
    if (plus < 0) {
      plus = this.appointments.length - 1;
    } else if (plus >= this.appointments.length) {
      plus = 0;
    }
    this.auxAppts = [];
    this.auxAppts[0] = this.appointments[this.auxApptsPos];
    this.auxAppts[1] = this.appointments[plus];
  }

  nextCardPending = (step: number) => {
    this.auxPendingPos = this.auxPendingPos + step;
    let plus = this.auxPendingPos + Math.abs(step);
    if (this.auxPendingPos < 0) {
      this.auxPendingPos = this.pendingAppointmentList.length - 1;
      plus = 0;
    } else if (this.auxPendingPos >= this.pendingAppointmentList.length) {
      this.auxPendingPos = 0;
      plus = 1;
    }
    if (plus < 0) {
      plus = this.pendingAppointmentList.length - 1;
    } else if (plus >= this.pendingAppointmentList.length) {
      plus = 0;
    }
    this.auxPending = [];
    this.auxPending[0] = this.pendingAppointmentList[this.auxPendingPos];
    this.auxPending[1] = this.pendingAppointmentList[plus];
  }

  reservaHora() {
    var url = "reserva-hora/";
    this.router.navigate([url]);
  }


  resedeHora() {
    var url = "reservar-hora-contact/";
    this.router.navigate([url]);
  }

  clickZoomDescarga() {
    window.open('https://zoom.us/download');
  }


  scheduleDisponibilityKPI(){
    this.gestionService.scheduleDisponibilityKPI()
      .then(((data : any) =>{
        // this.disponibilidadKPI = data;
        this.disponibilidadKPI = data.reverse();

        // console.log(data);
      }))
  };

  activatedUsersKPI(){
    this.gestionService.activatedUsersKPI()
      .then(((data : any) =>{
        this.activacionesKPI = data && data.length > 0 ? data[0] : null;
        // console.log(data, 'activated');
      }))
  };

  surveyListKPI(){
    this.gestionService.surveyListKPI()
    .then(((data : any) =>{
      this.evaluacionesKPI = data && data.length > 0 ? data[0] : null;
      // console.log(data, 'survey');
    }))
  }

}
