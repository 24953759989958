
<div class="row">
  <div class="col-md-12">
    <br>
        <div class="minititulo">
            Tarifas y Descuentos
        </div>
        <div class="titulo">
            Tarifas
        </div>

  </div>
</div>

<br>

<div class="container">
  <app-listar-especialidades></app-listar-especialidades>
</div>
