
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GeneralService } from '../../../services/general.service';
import { CustomerService } from '../../../services/customer.service';
import { EncriptacionService } from '../../../services/encriptacion.service';
import { TarifasService } from '../../../services/tarifas.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { discountCode } from 'src/app/interfaces/discount.interface';
import { MatSort } from '@angular/material/sort';



@Component({
  selector: 'app-codigo-descuento',
  templateUrl: './codigo-descuento.component.html',
  styleUrls: ['./codigo-descuento.component.css']
})
export class CodigoDescuentoComponent implements OnInit {
 

  ELEMENT_DATA: discountCode[] = [];





  public clientes = [];
  public cliente_selected: any = {};

  public planesCliente: any = [];
  public clientePlan_selected: any = {};

  public especialidadesList: any = [];
  public especialidad_selected = {};

  public usuario: any = {};
  public descuentos = [];



  plan;
  plan_cargado;
  cliente;
  especialidades;
  dctoCode: any = {};


  descuentoRadio = {
    valorPorcentaje: false,
    valorMonto: false
  }

  prestacionRadio = {
    medicinaGeneral: false,
    especialidades: false
  }

  asignacionRadio = {
    asignacionGeneral: false,
    asignacionCliente: false,
    asignacionPlan: false
  }



  public dctoPost = this.fb.group({
    prestacion: ['', [Validators.required]],
    especialidades: ['',],
    descuento: ['', [Validators.required]],
    monto: ['', [Validators.required]],
    asignacion: ['', [Validators.required]],
    cliente: ['',],
    plan: ['',],
    fechaInicio: ['', [Validators.required]],
    fechaLimite: ['', [Validators.required]],
    limiteUso: ['', [Validators.required, Validators.min(0), Validators.max(10)]],
  });


  campoValido(campo: string) {
    return this.dctoPost.controls[campo].errors
      && this.dctoPost.controls[campo].touched;
  }




  constructor(private fb: FormBuilder,
    public generalService: GeneralService,
    public customerService: CustomerService,
    public tarifasService: TarifasService) {
  }






  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    this.getSpecialityList();
    this.postListarCustomer();
    this.listDiscounts();


    this.descuentoRadio = {
      valorPorcentaje: false,
      valorMonto: false
    }

    this.prestacionRadio = {
      medicinaGeneral: false,
      especialidades: false
    }

    this.asignacionRadio = {
      asignacionGeneral: false,
      asignacionCliente: false,
      asignacionPlan: false
    }




  }
  resetEspecialidad() {  //Reset especialidadesList
    this.especialidad_selected = {};
    this.especialidades = '';

  }

  resetCliente() {
    this.cliente_selected = {};
    this.cliente = '';


  }

  resetPlan() {
    this.clientePlan_selected = {};
    this.plan = '';
  }




  //Listar planes por Cliente

  changeClient(cliente) {

    this.cliente_selected = {};

    this.resetPlan();

    if (!cliente) {
      return;
    }

    for (let index = 0; index < this.clientes.length; index++) {
      const element = this.clientes[index];
      if (element.cus_id == cliente) {
        this.cliente_selected = element;
      }
    }

    this.plan = this.cliente_selected.cus_id;
    this.changePlanByClient(this.cliente_selected.cus_id);


  }

  //Cambiar planes por Cliente

  changePlanByClient(id_plan) {

    if (!id_plan) {
      return;
    }
    for (let plan of this.cliente_selected.planes) {
      if (plan.cus_ben_id == id_plan) {
        this.clientePlan_selected = plan;
        this.cargarPlanes(true, id_plan)
        break;
      }
    }

  }

  cargarPlanes(bol, id_cus) {
    this.plan_cargado = bol;
  }





  changeSpe = () => {
    // console.log(this.dctoPost.value.prestacion);
    if(this.dctoPost.value.prestacion == 2){
      this.dctoPost.get('especialidades').setValidators([]);
      this.dctoPost.get('especialidades').updateValueAndValidity();
    } else {
      this.dctoPost.get('especialidades').clearValidators();
      this.dctoPost.get('especialidades').updateValueAndValidity();
    }
  }



  changePorcentaje = () => {
    if (this.dctoPost.value.descuento == 1) {
      this.dctoPost.get('monto').setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
      this.dctoPost.get('monto').updateValueAndValidity();
    } else {
      this.dctoPost.get('monto').clearValidators();
      this.dctoPost.get('monto').updateValueAndValidity();
    }
  }

  changeMonto = () => {
    if (this.dctoPost.value.descuento == 2) {
      this.dctoPost.get('monto').setValidators([Validators.required, Validators.min(1000), Validators.max(100000)]);
      this.dctoPost.get('monto').updateValueAndValidity();
    } else {
      this.dctoPost.get('monto').clearValidators();
      this.dctoPost.get('monto').updateValueAndValidity();
    }
  }

  changeCli = () => {
    if (this.dctoPost.value.asignacion === 2) {
      this.dctoPost.get('cliente').setValidators([Validators.required]);
      this.dctoPost.get('cliente').updateValueAndValidity();
    } else {
      this.dctoPost.get('cliente').clearValidators();
      this.dctoPost.get('cliente').updateValueAndValidity();
    }
  }

  // changePlan  = () => {
  // //   console.log(this.dctoPost.value.plan);
  //   if(this.dctoPost.value.cliente != ""){
  //     this.dctoPost.get('plan').setValidators([Validators.required]);
  //     this.dctoPost.get('plan').updateValueAndValidity();
  //   } else {
  //     this.dctoPost.get('plan').clearValidators();
  //     this.dctoPost.get('plan').updateValueAndValidity();
  //   }
  // }


  
  getSpecialityList() {
    this.generalService.getSpecialityList()
      .then((data: any) => {
        // console.log(data, 'data-getSpecialityList');
        this.especialidadesList = data;
      })
      .catch(e => console.error(e));
  }

  //Listar clientes

  postListarCustomer() {
    this.customerService.postListarCustomer({})
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-postListarCustomer');
          this.clientes = data;
        }
      })
      .catch(e => console.error(e));
  }

    //Listar descuentos


  listDiscounts() {
    this.tarifasService.listDiscounts()
      .then((data: any) => {
        // console.log(data, 'data-listDiscounts');
        this.descuentos = data
      })

  }


  async createCode() {


    if (this.dctoPost.invalid) {
      this.dctoPost.markAllAsTouched();
      return;
    }

    if (this.dctoPost.valid) {
      this.generalService.modalCargando();
      await this.tarifasService.createDiscount(this.dctoPost.value)
        .then((result: any) => {
          this.generalService.modalExitoso('Codigo creado exitosamente', result.code ? 'Código ' + result.code : 'Creado exitosamente');
          // console.log(result, 'Codigo-exitoso');
          this.dctoPost.reset();
          this.ngOnInit();

        }).catch(error => {
          this.generalService.modalError('Error al generar codigo', error.msg ? error.msg : 'Codigo no creado')
          // console.log(error, 'Error en formulario, validar campos');

        });
      // console.log(this.dctoPost.value, 'Codigo creado');

    }


  }




  showEspecialidades() {
    if (this.prestacionRadio.especialidades) {
      this.prestacionRadio.especialidades = false;
    } else {
      this.prestacionRadio.especialidades = true;
      this.prestacionRadio.medicinaGeneral = false;
    }
  }

  hideEspecialidades() {
    this.resetEspecialidad();
    if (this.prestacionRadio.medicinaGeneral) {
      this.prestacionRadio.medicinaGeneral = false;
    } else {
      this.prestacionRadio.especialidades = false;
    }
  }

  showPorceDcto() {
    this.changePorcentaje();

    if (this.descuentoRadio.valorPorcentaje) {
      this.descuentoRadio.valorPorcentaje = false;
    } else {
      this.descuentoRadio.valorPorcentaje = true;
      this.descuentoRadio.valorMonto = false;
    }
  }

  showMontoDcto() {
    if (this.descuentoRadio.valorMonto) {
      this.descuentoRadio.valorMonto = false;
    } else {
      this.descuentoRadio.valorMonto = true;
      this.descuentoRadio.valorPorcentaje = false;
    }
  }

  showClientes() {
    if (this.asignacionRadio.asignacionCliente) {
      this.asignacionRadio.asignacionCliente = false;
    } else {
      this.asignacionRadio.asignacionCliente = true;
      this.asignacionRadio.asignacionGeneral = false;
      this.asignacionRadio.asignacionPlan = false;
    }
  }

  hideClientes() {

    this.resetCliente();
    this.resetPlan();

    if (this.asignacionRadio.asignacionGeneral) {
      this.asignacionRadio.asignacionGeneral = false;
    } else {
      this.asignacionRadio.asignacionCliente = false;
      this.asignacionRadio.asignacionPlan = false;
    }
  }

  showPlanes(cliente: any) {
    this.cliente_selected = cliente;
    // console.log(cliente, 'Planes-cliente');
    if (this.asignacionRadio.asignacionPlan) {
      this.asignacionRadio.asignacionPlan = false;
    } else {
      this.asignacionRadio.asignacionPlan = true;
    }
  }

}

