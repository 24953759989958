import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { MedicosService } from 'src/app/services/medicos.service';

@Component({
  selector: 'app-proximas-consultas-doc',
  templateUrl: './proximas-consultas-doc.component.html',
  styleUrls: ['../../../../assets/css/styles.css', './proximas-consultas-doc.component.css']
})
export class ProximasConsultasDocComponent implements OnInit {
  @Input() data: any = {};
  @Input() index: any = {};
  usuario:any;
  constructor(
    private router: Router,
    private _medicosServices: MedicosService,) { }

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
  }

  goLobby = () => {
    var url = "salaespera/"+this.data.zoo_mee_room_id+"/"+this.data.id_consulta+"/"+this.data.med_con_id;
    this.router.navigate([url]);
  }


}
