import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { io } from "socket.io-client";
import {  URL_SERVICIOS_SOCKET } from "../config/config";

@Injectable({
  providedIn: 'root'
})
export class ChatSocketService {

    public socket = io(URL_SERVICIOS_SOCKET, {
        transports: ['websocket']
    });

    joinRoom(data)
    {
        this.socket.emit('chat:join',data);
    }

    created()
    {
        let observable = new Observable<{user:String, message:String}>(observer=>{
            this.socket.on('chat:created', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    joined()
    {
        let observable = new Observable<{user:String, message:String}>(observer=>{
            this.socket.on('chat:joined', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    userJoined()
    {
        let observable = new Observable<{user:String, message:String}>(observer=>{
            this.socket.on('chat:userJoined', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    message()
    {
        let observable = new Observable<{user:String, message:String}>(observer=>{
            this.socket.on('chat:message', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    leave()
    {
        let observable = new Observable<{user:String, message:String}>(observer=>{
            this.socket.on('leave', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    emitSocket(topic, data) {
        if (topic && data) {
            this.socket.emit('chat:'+topic, data);
        }
    }
}
