<div style="padding:5px">

  <h1 class="texto-titulo">Chat con tu médico</h1>
  <h1 class="texto-subtitulo">Mis chats abiertos</h1>

  <div class="row">
    <div class="col-md-12">
      <div class="card-alerta">
        <table>
          <tr>
            <td><mat-icon style="color:#171994;font-size: 24px; margin: 18px 24px;">info</mat-icon> </td>
            <td><p class="letras-alerta">Todos los chat se mantendrán abiertos solo por 24 horas una vez finalizadas las consultas.</p></td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <br>

  <app-listado-chats></app-listado-chats>

</div>