import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { URL_DOCS } from 'src/app/config/config';
import { ChatSocketService } from 'src/app/services/chat-socket.service';
import { ChatService } from 'src/app/services/chat.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { ZoomService } from 'src/app/services/zoom.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit{
  @Input() eventos: any; 
  @Input() consulta: any = {};
  @Output() messageEvent = new EventEmitter<string>();
  title = 'angular-chat';
  user = '';
  messages: any = [];
  newMessage = '';
 
  ultimo_msg: any = {};
  tipo_user: any ;
  observar = true;
  room: any;

  doctorName: any;
  doctorLastName: any;

  pacienteName: any ;
  pacienteLastName: any;
  URL_DOCUMENTS = URL_DOCS;
  appointments: any = {};
  consul:any = {};

  maximixado: boolean = false;
  estilo:any;
  alto: any;
  hora_final: any;
  tiempo_restante: any = "";
  

  constructor(
    private _chatServices: ChatService,
    private _chatServiceSocket: ChatSocketService,
    private _zoomService: ZoomService,
    private router: Router
  ) { 
    this._chatServiceSocket.created().subscribe(data => this.created(data));
    this._chatServiceSocket.joined().subscribe(data => this.joined(data));
    this._chatServiceSocket.userJoined().subscribe(data => this.userJoined(data));
    this._chatServiceSocket.message().subscribe(data => this.message(data));
    this._chatServiceSocket.leave().subscribe(data => this.leave(data));
  }
  
  ngOnInit() {
    this.tipo_user = new EncriptacionService().getUserInfo().typ_name;
    this.user = new EncriptacionService().getUserInfo().nombre;
    ////console.log("Chat:");
    ////console.log(this.consulta);
    this.room = 'chat-' + this.consulta.med_con_id;
    //this.join();
        
    this._chatServices.postListarRegistroChat(this.consulta.med_con_id)
      .then((results:any) => {
        ////console.log(results);


        this.messages = results;

        if (results.length > 0) {
          this.ultimo_msg = results[results.length - 1];

        } else { 
          this.ultimo_msg = {tel_cha_det_id:0}
        }  
      })
      .catch(error => { 
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar chat, por favor intentelo nuevamente.',
          timer: 2000
        });
      }
    );
    
    this._zoomService.getConsultaMed(this.consulta.med_con_id)
    .then(resultado => {
      this.consul = resultado;
      this.consul.fecha_consulta = this.consul.fecha_consulta.substr(0, 10).replaceAll('-', '/');
      //console.log(this.consul);
      
      this._chatServiceSocket.emitSocket('join',{roomName:this.room});
      const appt = this.consul;
      const hour = appt.hora.substr(0, 2);
      const minute = appt.hora.substr(3, 2);
      var apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-','/'));
      apptDate.setHours(hour);
      apptDate.setMinutes(minute);
      const now = new Date();
      const formatedApptDate = this.formatApptDate(apptDate, now);
      //cumpleanos = new Date(1995,11,17,3,24,0);
      this.hora_final = new Date(appt.fecha_consulta.substr(0, 4), (appt.fecha_consulta.substr(5, 2) - 1), appt.fecha_consulta.substr(8, 2), appt.hora.substr(0, 2), appt.hora.substr(3, 2));
      //console.log(this.hora_final);
      this.hora_final.setDate(this.hora_final.getDate() + 1);
      //console.log(this.hora_final);
      this.tiempo_faltante();
      ////console.log(apptDate);
      
      if (formatedApptDate.length > 0) {
        let confirmFlag: boolean = false;
        const msToAppt = this.timeToX(apptDate, now);
        const minToAppt = this.msToMin(msToAppt);
        if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
          confirmFlag = true;
        }
        let goLobbyFlag: boolean = false;
        if (minToAppt <= 60) {
          goLobbyFlag = true;
        }
        const apptData = {
          date: formatedApptDate,
          doctorName: appt.nombre_doctor.split(' ', 1)[0],
          doctorLastName: appt.apellido_paterno_doctor,
          pacienteN: appt.nombre_paciente.split(' ', 1)[0],
          pacienteLN: appt.apellido_paterno_paciente,
          spec: appt.spe_name,
          subSpec: appt.sub_spe_name,
          img: appt.img ? appt.img : '',
          confirmFlag,
          confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
          goLobbyFlag,
          consulta:appt
        };
        this.appointments = apptData;
        this.doctorName = this.appointments.doctorName.charAt(0).toUpperCase();
        this.doctorLastName = this.appointments.doctorLastName.charAt(0).toUpperCase();
        this.pacienteName = this.appointments.pacienteN.charAt(0).toUpperCase();
        this.pacienteLastName = this.appointments.pacienteLN.charAt(0).toUpperCase();
      }
    })  

    this.alto = 200;
    this.estilo = 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)';
  }

  created(data) {
    //console.log(data,'data-created');
    
  }

  joined(data) {
    //console.log(data,'data-joined');
    
  }

  userJoined(data) {
    //console.log(data,'data-userJoined');
    
  }

  message(data) {
    //console.log(data.data.roomName, 'data.data.roomName-message');
    
    //console.log(data.data, 'data.data-message');
    if (this.room == data.data.roomName) {      
      //console.log(data.data.message,'data.data.message-message');
      
      this.messages.push(data.data.message);           
      //console.log(this.messages,'messages');
    }
    
  }

  leave(data) {
    //console.log(data,'data-leave');
    
  }

  sendMessage() {
    ////console.log("Entro");
    
    if (this.newMessage == "") {
      return;
    }

    this._chatServices.postCrearRegistroChat(this.consulta.med_con_id,this.newMessage)
      .then((results:any) => {
        ////console.log("Mensaje enviado");
        //this.sendMessageSocket();
        this.messages.push(results.message);           

        let data = {
          roomName: this.room,
          message: results.message
        };
        //console.log(data,'data-postCrearRegistroChat');
        

        this._chatServiceSocket.emitSocket('message',data);
        
        this.newMessage = "";
      })
      .catch(error => { 

        Swal.fire({
          icon: 'warning',
          title: 'Error al mandar el mensaje, por favor intentelo nuevamente.',
          timer: 2000
        })
      });

  }
  
  async tiempo_faltante() {

    while (this.observar) {
      let hora_actual = new Date();
      this.tiempo_restante = this.hora_final.getTime() - hora_actual.getTime();
      if (this.tiempo_restante<0) {
        this.observar = false;
      }
      this.tiempo_restante = this.tiempo_restante / 1000;
      let horas = Math.trunc(this.tiempo_restante / 3600);
      let minutos = Math.trunc((this.tiempo_restante % 3600) / 60);
      let segundos = Math.trunc(this.tiempo_restante % 60);
      this.tiempo_restante = horas;
      //console.log(this.tiempo_restante);
      await this.sleep(5*60*1000);
    }

  }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  ngOnDestroy(){
    this.observar = false; // switches your IntervalObservable off
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////Funciones para la card-consulta///////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Hoy ' + returnDate[0];
    }
    if (minToAppt < 0) {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
       returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    return returnDate;
  }

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }


  verMas() {
    this.maximixado = true;
    this.alto = 570;

   }

  verMenos() {
    this.maximixado = false;
    this.alto = 200;

  }
  
  navegarDetalle() { 
    // var url = "detalleconsultachat/" + this.consulta.med_con_id;
    var url = "historialConsultasDoc/paciente/"+this.consulta.med_con_id;
    
    this.router.navigate([url]);
  }



}