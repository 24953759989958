<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Striped Progress Bar</h4>
                <div class="progress m-t-20">
                    <div class="progress-bar bg-primary progress-bar-striped active" 
                        style="height:10px;"
                        [style.width] = "getPorcentaje1" 
                        role="progressbar"> 
                        <!-- [style.width] = "getPorcentaje" -->
                    </div>
                </div>
                <div class="progress m-t-20">
                    <div class="progress-bar bg-info progress-bar-striped active" 
                        style="height:10px;" 
                        [style.width] = "getPorcentaje2"
                        role="progressbar"> 
                        <!-- [style.width] = "getPorcentaje" -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-body">
               
                <app-incrementador (valorSalida)="progreso1 = $event" 
                                    [valor]="progreso1">
                </app-incrementador>
                <!-- [progreso]="15"> lo que le dice es que el valor del progreso de este modulo es de 15 y lo reemplaza del 50 que etsba definido-->
                <!--   (valorSalida)="cambioValorHijo($event)"  lo que estoy haciendo es pasarle el valor de salida calculado en el incrementador-->
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <app-incrementador (valorSalida)="progreso2 = $event" 
                                    [valor]="progreso2"
                                    [btnClass]="'btn btn-info'">
                                    <!-- [btnClass] no se ocupa de esta forma ya que asi le tengo que pasar un valor, al hacerlo sin parentesis o con comillas simple dentro de los "" le paso un string que es lo que necesito
                                    de esta forma cambio el color de los botones en el componente incrementador-->
                </app-incrementador>
            </div>
        </div>
    </div>
</div>