import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { MedicoComponent } from './medico/medico.component';
import { OnboardingComponent } from './onboarding.component';
import { PacienteComponent } from './paciente/paciente.component';


const routes: Routes = [
  {
    path: '', 
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: OnboardingComponent,
    children: [
      { path: 'onboarding/medico', component: MedicoComponent, data: { titulo: 'Onboardin Médico' } },
      { path: 'onboarding/paciente', component: PacienteComponent, data: { titulo: 'Onboardin Paciente'}}
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
