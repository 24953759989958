import { ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomService } from 'src/app/services/zoom.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FunctionsService } from 'src/app/services/functions.service';
import Swal from 'sweetalert2';

import { ScheduleService } from 'src/app/services/schedule.service';
import { AgendaMedico2Component } from 'src/app/components/agenda-medico2/agenda-medico2.component';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service'; import { Component, OnInit } from '@angular/core';
import { DocumentsService } from 'src/app/services/documents.service';
import { URL_DOCS } from 'src/app/config/config';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-videoconsulta-medico-fin',
  templateUrl: './videoconsulta-medico-fin.component.html',
  styleUrls: ['./videoconsulta-medico-fin.component.css']
})
export class VideoconsultaMedicoFinComponent implements OnInit {

  @Input() data: any = {};

  _title = 'Videoconsulta';
  _subtitle = 'Detalle de la consulta';
  archivos_consulta: any = {
    diagnostics: [],
    certificados: [],
    prescriptions: [],
    orders: []
  };
  documentos_consulta: any = [];


  use_id_paciente: any;
  @ViewChild('MFinalizarConsulta') MFinalizarConsulta: ElementRef;
  @ViewChild('MNoSePresenta') MNoSePresenta: ElementRef;

  @ViewChild(AgendaMedico2Component) AGENDA_MEDICO: AgendaMedico2Component; //para llamar las funciones contenidas en ese componente


  _usuario: any = {};  // usuario actual

  consulta: any = {
    fecha_consulta: {}
  };
  btnIniciar: boolean = false;              //habilita el boton de iniciar videoconsulta
  id_de_consulta: string = '';
  hora_final: any;


  ///////////////////////////////////////////////////////////////

  // historial: boolean = false;
  // antecedentes: boolean = false;
  // ficha: boolean = false;
  // agenda: boolean = false;
  // chat: boolean = false;

  URL_DOCUMENTS = URL_DOCS;

  ficha_eventos: boolean = false;     //[evento]	

  asistencia_paciente: Boolean = false;
  pacienteName: string;
  pacienteLastName: string;

  nombre_paciente: string;
  apellido_paterno_paciente: string;
  apellido_materno_paciente: string;
  date: string;
  date2: string;
  spec: string;
  subSpec: string;


  infoConsulta: any = {};

  boo_id: any;
  use_id: any;
  cancel_reason: any;
  data_selected: any;



  constructor(
    private activatedRoute: ActivatedRoute,
    private _zoomService: ZoomService,
    private _fichaMedicaServices: FichaMedicaService,
    private _functions: FunctionsService,
    public scheduleService: ScheduleService,
    private router: Router,
    private renderer: Renderer2,
    private _documentServices: DocumentsService,
    private generalService: GeneralService
  ) { }



  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();

    if (this._usuario.typ_id != 1) {
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
    else {
      this.activatedRoute.params.subscribe(parametros => {
        this.obtenerConsulta(parametros.idConsulta);
        this.id_de_consulta = parametros.idConsulta;
        ////console.log(this.id_de_consulta);

      });
      this.horasDisponibles(this._usuario.doc_id);
      this.agendaMedicoDiv = document.getElementById('agenda_medico');
    }

    this.tiempoPasadoConsulta();

    // Llamar a la función tiempoPasadoConsulta() cada minuto
    const interval = setInterval(() => {
      this.tiempoPasadoConsulta();
    }, 60000); // 60000 ms = 1 minuto

    // Limpia el intervalo después de 4 minutos (opcional)
    setTimeout(() => {
      clearInterval(interval);
    }, 60000); // 240000 ms = 4 minutos

  }

  agendaMedicoDiv: any;



  obtenerConsulta(consulta_id) {
    
    this._zoomService.getConsulta(consulta_id)
      .then(async resultado => {
        // console.log(resultado);
        this.consulta = resultado;
        // this.pacienteNoSePresentaState();
        this.use_id_paciente = await this.consulta.user_id;
        this.consulta.fecha_consulta = this._functions.formatApptDate(new Date(this.consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/')), this.consulta.hora)
        this.tiempoPasadoConsulta();
        this.consulta.dateText = this.consulta.fecha_consulta.text
        this.btnIniciar = true;
        this.ficha_eventos = true;
        this.boo_id = this.consulta.id_consulta;
        this.pacienteName = this.consulta.nombre_paciente.charAt(0).toUpperCase();
        this.pacienteLastName = this.consulta.apellido_paterno_paciente.charAt(0).toUpperCase();
        this._fichaMedicaServices.getListarFichaConsulta(this.consulta)
          .then(results => {
            //console.log(results);
            this.archivos_consulta = results;
          })
          .catch(error => {
            console.log(error);
          });
        this._documentServices.postListaArchivosConsulta(this.consulta.id_consulta, this.use_id_paciente)
          .then(results => {
            // console.log(results);
            this.documentos_consulta = results;

          })
          .catch(error => {
            console.log(error);
          });

      }).catch(error => {
        console.log(error);
      });
  }


  infoCancelaConsulta() {

    this.infoConsulta = this.consulta;
    // console.log(this.infoConsulta, 'Data consulta');

  }


  async confirmaEliminar() {


    // console.log(this.infoConsulta,);
    this.generalService.modalCargando();

    if (this.infoConsulta.comentario && this.infoConsulta.comentario.length > 3) {


      await this.scheduleService.cancelAppointment(this.boo_id, this._usuario._id, this.infoConsulta.comentario, {}, null)
        .then(result => {
          // console.log(result, 'Comentario y anulacion exitosa');
          this.generalService.modalExitoso('Anulacion de consulta exitosa', '')
        })
        .catch(error => {
          console.log(error, 'Error en la anulacion');
          this.generalService.modalError('Error al anular la consulta', '')
        })
    } else {
      this.generalService.modalError('Error al anular la consulta', 'Ingrese comentario válido')

    }
    this.router.navigate(['/', 'proximasConsultas']);

  }


  options = [false, false, false, false];     // habilitacion de botones

  optionsButton($event) {
    this.agendaMedicoDiv.style.display = 'none'
    this.options = [false, false, false, false];
    this.options[$event] = true;
    if ($event == 3) {
      this.agendaMedicoDiv.style.display = 'block'
      this.AGENDA_MEDICO.sendToAgendaMedico(this.horas_medico); // envia la data al componentes
    }
  }



  verificacionFinalizar() {
    this._fichaMedicaServices.getListarFichaConsulta(this.consulta)
      .then(results => {
        // //console.log(results);
        this.archivos_consulta = results;
        this._documentServices.postListaArchivosConsulta(this.consulta.id_consulta, this.use_id_paciente)
          .then(results => {
            ////console.log(results);
            this.documentos_consulta = results;

          })
          .catch(error => {
            console.log(error);
          });
        this.renderer.selectRootElement(this.MFinalizarConsulta.nativeElement).click();

      })
      .catch(error => {
        console.log(error);
      });
  }

  finalizarVideoconsulta() {
    let params = {
      id_consulta: this.consulta.id_consulta
    }
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();

    this._zoomService.postUpdateSheduleEndState(params)
      .then(result => {
        ////console.log(result);
        Swal.fire({
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > La consulta fue finalizada de forma exitosa</h1><br>',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000
        })
        this.router.navigate(['/', 'proximasConsultas']);
        setTimeout(() => window.location.reload(), 200);
      })
      .catch(error => {
        console.log(error);

      })

  }


  clickHourAgendaMedico($event) {
    //console.log($event)
  }



  horas_medico: any;

  // horas disponibles doctor
  horasDisponibles(doc_id) {
    this.scheduleService.getScheduleHoursDisp(doc_id, null, null)
      .then(data => {
        this.horas_medico = data;
      })
  }

  retornaArchivosConsulta(data) {
    this.archivos_consulta = data;
    ////console.log(data);

    ////console.log(this.archivos_consulta);

  }

  pacienteNoSePresenta() {
    ////console.log("No se presenta");
    //id_consulta
    let params = {
      id_consulta: this.consulta.id_consulta
    }

    this.generalService.modalCargando();

    this._zoomService.postUpdateSheduleNoPatient(params)
      .then(result => {
        this.router.navigate(['/', 'proximasConsultas']);
        Swal.close();
        setTimeout(() => window.location.reload(), 200);
      })
      .catch(error => {
        //console.log(error);
        this.generalService.modalError('Error','Error al modificar consulta.');
      })    //this.renderer.selectRootElement(this.MNoSePresenta.nativeElement).click();
  }

  // pacienteNoSePresentaState() {

  //   let params = {
  //     id_consulta: this.consulta.id_consulta
  //   }
  //   ////console.log(params);
  //   this._zoomService.postSheduleNoPatientState(params)
  //     .then(result => {
  //       ////console.log(result);
  //       this.tiempoPasadoConsulta();
  //     })
  //     .catch(error => {
  //       //console.log(error);
  //       this.asistencia_paciente = false;
  //     })    //this.renderer.selectRootElement(this.MNoSePresenta.nativeElement).click();
  // }

  tiempoPasadoConsulta() {
    //this.hora_final = new Date(this.consulta.fecha_consulta.substr(0, 4), (this.consulta.fecha_consulta.substr(5, 2) - 1), this.consulta.fecha_consulta.substr(8, 2), this.consulta.hora.substr(0, 2), this.consulta.hora.substr(3, 2));
    let fecha_actual = new Date();
    ////console.log(fecha_actual);

    ////console.log(this.consulta.fecha_consulta.date);
    try {
      ////console.log((fecha_actual - this.consulta.fecha_consulta.date) / (1000 * 60));
      if (((fecha_actual.getTime() - this.consulta.fecha_consulta.date.getTime()) / (1000 * 60)) >= 15) {
        this.asistencia_paciente = true;
      } else {
        this.asistencia_paciente = false;
      }
      //console.log(this.asistencia_paciente);

    } catch (e) {
      console.log(e);
    }
  }
}