<div style="width: 100%; align-items: center;text-align: center;">
    <h1 class="letra-titulo">Validación de recetas médicas</h1>
</div>

<div class="d-none d-sm-block" style="width: 100%;">
  <br>
  <table style="width: 100%;">
      <td style="width: 30%;"></td>
      <td style="width: 40%;">
          <div class="card-padre" style="width: 100%;">
              <div class="card-datos">
                  <h1 class="letra-card-titulo">Ingrese el código de verificación</h1>
              </div>
              <br><br>
              <div style="padding-left: 20px;width: 100%;padding-right: 20px;"> <input  type="text" style="width: 100%" name="id_documento" [ngModel]="this.datos_url.id_documento" (ngModelChange)="onChange($event)"></div>
              <br>
              <div *ngIf='datos_documento.att_path'>
                <p *ngIf='datos_documento.att_typ_id == 5' style="padding-left: 20px;color: rgb(55, 193, 60);font-size: 20px;"><mat-icon style="color: rgb(55, 193, 60);">check_circle_outline</mat-icon> Documento firmado electrónicamente </p>
                <p *ngIf='datos_documento.pre_sta_id == 45' style="padding-left: 20px;color: red;font-size: 20px;"><mat-icon style="color: red;">do_not_disturb_alt</mat-icon> Documento retenido el {{datos_documento.fecha_retencion}} </p>

                  <table *ngIf='datos_documento.pre_sta_id == 33' style="width: 100%;">
                      <!-- <td style="width: 50%;text-align: center;"> -->
                        <!-- <button class="btn btn-secondary letraBotonModal" style="background: #a7a7ab;" data-toggle="modal" data-target="#previsualizardocumento" >Ver</button> -->
                      <!-- </td> -->
                      <td style="width: 100%;text-align: center;">
                        <button class="btn btn-secondary letraBotonModal" style="background: #a7a7ab;" ><a style="color: white;" [href]="pdf_url" download="proposed_file_name">Descargar</a></button>
                      </td>
                  </table>
              </div>
              <br>
              <div style="padding-left: 20px;padding-right: 20px;">
                <table style="width: 50%;">
                  <td style="width: 50%;text-align: center;">
                    <button class="btn btn-secondary letraBotonModal" style="background: #0E0087;" (click)='buscarDocumento()' >Verificar</button>
                  </td>
                  <td style="width: 50%;text-align: center;">
                    <button *ngIf="datos_documento.att_path && datos_documento.pre_sta_id == 33" class="btn btn-secondary letraBotonModal" style="background: red;" (click)='retenerModal()' >Retener</button>
                  </td>
              </table>
              </div>
          </div>
      </td>
      <td style="width: 30%;"></td>
  </table>
  
</div>




<div class="d-sm-none" style="width: 100%; align-items: center;text-align: center;">
    <br>
    <div class="card-padre" style="width: 100%;">
      <div class="card-datos">
          <h1 class="letra-card-titulo">Ingrese el código de verificación</h1>
      </div>
      <br><br>
      <div style="padding-left: 20px;width: 100%;padding-right: 20px;"> <input  type="text" style="width: 100%" name="id_documento" [ngModel]="this.datos_url.id_documento" (ngModelChange)="onChange($event)"></div>
      <br>
      <div *ngIf='datos_documento.att_path'>
        <p *ngIf='datos_documento.att_typ_id == 5' style="padding-left: 20px;color: rgb(55, 193, 60);font-size: 20px;"><mat-icon style="color: rgb(55, 193, 60);">check_circle_outline</mat-icon> Documento firmado electrónicamente </p>
        <p *ngIf='datos_documento.pre_sta_id == 45' style="padding-left: 20px;color: red;font-size: 20px;"><mat-icon style="color: red;">do_disturb</mat-icon> Documento retenido el {{datos_documento.fecha_retencion}} </p>
        <table *ngIf='datos_documento.pre_sta_id == 33' style="width: 100%;">
          <tr >
            <!-- <td  style="width: 50%;text-align: center;"> -->
              <!-- <button class="btn btn-secondary letraBotonModal" style="background: #a7a7ab;" data-toggle="modal" data-target="#previsualizardocumento" >Ver</button> -->
            <!-- </td> -->
            <td style="width: 100%;text-align: center;">
              <button class="btn btn-secondary letraBotonModal" style="background: #a7a7ab;" ><a style="color: white;" [href]="pdf_url" download="proposed_file_name">Descargar</a></button>
            </td>
          </tr>
      </table>

      </div>
      <br>
      <div style="padding-left: 20px;padding-right: 20px;">
        <table style="width: 100%;">
          <td style="width: 50%;text-align: center;">
            <button class="btn btn-secondary letraBotonModal" style="background: #0E0087;" (click)='buscarDocumento()' >Verificar</button>
          </td>
          <td style="width: 50%;text-align: center;">
            <button *ngIf="datos_documento.att_path && datos_documento.pre_sta_id == 33" class="btn btn-secondary letraBotonModal" style="background: red;" (click)='retenerModal()' >Retener</button>
          </td>
      </table>
      </div>
  </div>
</div>

  <!-- modal pre-visualizar-->
  <div class="modal fade" id="previsualizardocumento" aria-labelledby="exampleModalLabel" aria-hidden="true" #previsualizardocumento>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
  
            <p class="tituloModalEliminar" style="text-align: center;margin: 0%;width: auto;">Ver documento</p>
          <div style="width: auto;height: 450px;">
  
            <!-- <embed src={{pdf_url|safeUrl}} frameborder="0" width="100%" height="400px"> -->
            <!-- <iframe [src]="pdf_url"style="width: 100%;border: none;margin: 0;padding: 0;overflow: hidden;z-index: 3;height: 100%;"></iframe> -->
  
          </div>
          <div class="container-fluid" style="padding: 10px 10px;align-items:center;text-align: center;">
            <button type="button" class="btn btn-secondary letraBotonModal" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--modal-->
<!-- 
  <button #cerrarSubirDoc type="button" class="btn " data-toggle="modal" data-target="#previsualizardocumento" [disabled]='!datos_documento.att_path' > 
    aux trigger for login loading modal
  </button> -->