import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LoginValidationsService } from '../login-validations.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {
  @ViewChild('closeRecoveryModal') closeRecoveryModal: ElementRef;
  @ViewChild('trigRecSuccModal') trigRecSuccModal: ElementRef;
  @ViewChild('trigRecErrorModal') trigRecErrorModal: ElementRef;

  public formSubmitted: boolean = false;
  public recoveryRutFocus: boolean = false;
  testEmail: string = "t*****@correo.com";
  testPhone: string = '+569**********'

  public token_recaptcha_recovery: string;


  constructor(
    private renderer: Renderer2,
    public _usuarioService: UsuarioService,
    private fb: FormBuilder,
    private loginValidationsService: LoginValidationsService,
    private _usuarioServices: UsuarioService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
  }

  public recoveryForm = this.fb.group({
    rut: ['', [Validators.required, this.loginValidationsService.validaRut]],
  }, {});

  checkField(field: string): boolean {
    const valInvalid: boolean = this.recoveryForm.get(field).invalid;
    if (valInvalid && this.recoveryForm.get(field).value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  recovery() {
    this.formSubmitted = true;
    if (this.recoveryForm.invalid) {
      //console.log('formularion invalido');
      return;
    }
    this.recaptchaV3Service.execute('recaptcha')
      .subscribe((token_recaptcha: string) => {
        // console.log('recovery', token_recaptcha);
        this.token_recaptcha_recovery = token_recaptcha;


    //console.log(this.recoveryForm.value);
    this.renderer.selectRootElement(this.closeRecoveryModal.nativeElement).click();
    
    this._usuarioServices.recoveryPass({ rut:this.recoveryForm.value.rut, token_recaptcha: this.token_recaptcha_recovery })
    .then((results:any) => {
      // console.log(results);
      this.testEmail = results.mail;
      this.testPhone = results.phone;
      // let arreglo_mail = this.testEmail.split("@");
      // var modificado = arreglo_mail[0];
      // modificado = modificado[0].substr(0,5)+'**************';
      // this.testEmail = modificado + '@' + arreglo_mail[1]; 
      this.renderer.selectRootElement(this.trigRecSuccModal.nativeElement).click();
      })
      .catch(error => {
        // console.log(error);
      })
    
    });
  }

  changeRut() {
    // Despejar Puntos
    var rut_Cliente = this.recoveryForm.get('rut').value
    var valor = rut_Cliente;
    //console.log(this.recoveryForm.get('rut').value)
    //reemplazo los puntos por vacio
    let resultado = valor.split('.').join('');
    // reemplazo los - por vacio
    let resultado2 = resultado.split('-').join('');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    // Formatear RUN
    if (valor != "") {
      this.recoveryForm.controls['rut'].setValue(cuerpo + "-" + dv);
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;

    // Para cada dígito del Cuerpo
    for (i = 1; i <= cuerpo.length; i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado
    dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
      //console.log("changeRut invalido");
    }
    else {
      //console.log("changeRut valido");
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
  }
}
