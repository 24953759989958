import { Component, OnInit } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-mesa-ayuda',
  templateUrl: './mesa-ayuda.component.html',
  styleUrls: ['./../../../assets/css/styles.css', './mesa-ayuda.component.css']
})
export class MesaAyudaComponent implements OnInit {

  show = false;
  pageInfo: any = {}; 
  questions: any[] = [];

  constructor(
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.pageInfo = new EncriptacionService().getPageInfo();    
    this.getQuestions();
  }

  muestraTexto() {
    //console.log("cambio de estado");
    this.show = true
  }

  getQuestions() {
    this.generalService.getQuestions()
      .then((result:any) => {
        //console.log(result, 'result-getQuestions');
        this.questions = result;
      })
      .catch(error => {
        //console.log(error, 'error-getQuestions');

      });
  }

}
