import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import Swal from 'sweetalert2';
import { CalendarMonthComponent } from '../../calendar/calendar-month/calendar-month.component';

@Component({
  selector: 'app-configuracion-agenda',
  templateUrl: './configuracion-agenda.component.html',
  styleUrls: ['./configuracion-agenda.component.css']
})
export class ConfiguracionAgendaComponent implements OnInit {
  @Input() doctor: any; //Se entrega el doctor externo
  _usuario: any = {};
  nombre_medico = '';

  @ViewChild(CalendarMonthComponent) CALENDAR: CalendarMonthComponent;

  hoursDay: Array<any> = [];

  selected_day: any = {};                   //día seleccionado
  hours_selected_day: Array<any> = [];      //horas del dia seleccionado
  hours_list_doc: Array<any> = [];          //listado de horas del doctor, del mes

  hours_selected_bloquear: Array<any> = [];      //horas a bloquear
  hours_selected_habilitar: Array<any> = [];      //horas a habilitar

  checkBloquear: boolean = false;
  checkHabilitar: boolean = false;

  btnBloquear: boolean = false;
  btnHabilitar: boolean = false;

  num_hours_bloqueables = 0;
  num_hours_habilitables = 0;

  dia_editable: boolean = false;

  constructor(
    private _functions: FunctionsService,
    public _schedule: ScheduleService,
  ) { }

  ngOnInit(): void {

    //console.log(this.doctor);
    
    this._usuario = new EncriptacionService().getUserInfo();

    this.nombre_medico =  (this._usuario.typ_id == 1 )? this._usuario.nombre_usuario: ''; // espacio para el CC o CM

    // this.getHorasBloques();
    // this.getHorasMes(this._usuario.doc_id,null);
  }


  ngOnChanges() {
    // if (this.doctor.doc_id) {
    //   this.getHorasMes(this.doctor.doc_id);
    //   //console.log(this.doctor);
    //   // this.horasDisponibles(this.doctor.doc_id)
    //   // this.getListarConsultas();
    // }
  }

}