import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';

import { saveAs } from 'file-saver';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';

import { URL_SERVICIOS, URL_DOCS } from '../config/config';
import { ErrorsService } from './errors.service';
import { formatDate } from '@angular/common';
import { EncriptacionService } from './encriptacion.service';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    public http: HttpClient,
    public router: Router,
    private encriptacionService : EncriptacionService,
    public _errors: ErrorsService,
  ) { }




  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////       getDoctorList         /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Listado de doctores activos
  async getDoctorList(id_doctor = null, id_especialidad = null, zoom = null, hours_disp = null) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      doc_id: id_doctor,
      spe_id: id_especialidad,
      zoom: zoom,
      hours_disp: hours_disp,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getDoctorList', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////       postListarBeneficiarios        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async getSpecialityList(id_especialidad = null) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      spe_id: id_especialidad,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getSpecialityList ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async getCompleteSubSpecialityList() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getCompleteSubSpecialityList ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async postListInstitution() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListInstitution ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async postListTitle() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListTitle ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////       postListarBeneficiarios        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postListarBeneficiarios(use_rut) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      use_rut: use_rut,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarBeneficiarios', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////       postListPlansXBeneficiaries        ////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async postListPlansXBeneficiaries(use_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      use_id: use_id,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListPlansXBeneficiaries', JSON.stringify(params), httpOptions).toPromise()
      // console.log(data,'data-postListPlansXBeneficiaries');
      
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////       postListarAcompanantes        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postListarAcompanantes(use_rut) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      use_rut: use_rut,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarAcompanantes', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }

  ///////////

  async postListarBeneficiarioMain() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarBeneficiariosMain', JSON.stringify(params), httpOptions).toPromise()

      //console.log(data);

      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;

    }
  }



  async postListarBeneficiarioMyB() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarBeneficiariosByM', JSON.stringify(params), httpOptions).toPromise()

      //console.log(data);

      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;

    }
  }

  async postListarusuariosSistema() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarusuariosSistema', JSON.stringify(params), httpOptions).toPromise()

      //console.log(data);

      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;

    }
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////       getDoctorInfo      /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async getDoctorInfo(rut) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      rut: rut,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getDoctorInfo', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }




  async postListCommuneProvinceRegion() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {}
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListCommuneProvinceRegion', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async getListarConsultasChat() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsultasChat', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }
  async postListarConsultasPorPagar(estado, doc_id, fecha_inicio, fecha_termino) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    var estado_value = null;
    var doc_value = null;

    if (estado != "") {
      estado_value = estado;
    } else {
      estado_value = null;
    }

    if (doc_id != "") {
      doc_value = doc_id;
    } else {
      doc_value = null;
    }


    let params = {
      estado: estado_value,
      doc_id: doc_value,
      fecha_inicio: fecha_inicio,
      fecha_termino: fecha_termino,
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarConsultasPorPagar', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }

  async getAppBooStatus() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getAppBooStatus', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }


  async postRealizaOnBoarding() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postRealizaOnBoarding', JSON.stringify({}), httpOptions).toPromise();
      return data
    }
    catch (error) {
      // this._errors.requestError(error);
      throw 1;
    }
  }


  async postNextHabilDay() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postNextHabilDay', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error);
      throw 1;
    }
  }

  async getInfoDomain() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getInfoDomain', JSON.stringify({}), httpOptions).toPromise();
      let encriptado = this.encriptacionService.toBinary(JSON.stringify(data));
      sessionStorage.setItem('page', encriptado);
      return data;
    }
    catch (error) {
      // this._errors.requestError(error);
      throw 1;
    }
  }

  async getQuestions() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getQuestions', JSON.stringify({}), httpOptions).toPromise();
      return data;
    }
    catch (error) {
      // this._errors.requestError(error);
      throw 1;
    }
  }



  getDevice() {
    let ua = navigator.userAgent;
    let dispositivo = {
      type: '',
      connection: (window.navigator['connection'] as any)?.effectiveType,
    };
    if (/iPhone|iPad|iPod/i.test(ua)) {
      dispositivo.type = 'IOS';
    }
    else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      dispositivo.type = 'Android';
    } else {
      dispositivo.type = 'Desktop';
    }

    return dispositivo;

  }



  getCodecs() {
    let codecs = {
      'audio': RTCRtpReceiver.getCapabilities('audio').codecs,
      'video': RTCRtpReceiver.getCapabilities('video').codecs,
    };
    return codecs;
  }

  verificarFecha(fecha_string) {
    let array_fecha = fecha_string.replaceAll(' ', '').replaceAll('-', '/').split('/');
    let fecha_date = new Date(array_fecha[2], array_fecha[1] - 1, array_fecha[0]);
    if (fecha_date.toString() == 'Invalid Date') {
      // //console.log('Fecha invalida-verificarFecha');
      return false;
    }
    let fecha_date_string = formatDate(fecha_date, 'yyyy-MM-dd', 'en-US');
    let array = fecha_date_string.split('-');
    if (parseInt(array_fecha[2]) == parseInt(array[0]) && parseInt(array_fecha[1]) == parseInt(array[1]) && parseInt(array_fecha[0]) == parseInt(array[2])) {
      return {
        fecha_date: fecha_date,
        fecha_date_string: fecha_date_string
      };
    } else {
      // //console.log('Fecha invalida-verificarFecha');
      return false;
    }
  }

  // Funcion para descargar documentos usando solo el att_id, se le agrega el parametro att_name, para cuando se van a buscar los archivos por el nombre de este
  async descargarFileAtt(att_id, name_file_desc, noDownload) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
      responseType: 'blob' as 'blob'
    };
    let params = {
      att_id: att_id,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/descargarFileAtt', JSON.stringify(params), httpOptions).toPromise()
      if (!noDownload) {
        saveAs(data, name_file_desc);
        return name_file_desc;
      } else {
        //console.log(data);
        return data;
      }
    }
    catch (error) {
      // throw error;
      console.log(error)
      if (error.status = 404) {
        Swal.fire({
          icon: 'warning',
          // title: 'Favor reingresar',
          text: 'Archivo no encontrado',
          timer: 2000
        })
      }
    }
  }


  modalCargando() { 
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 1000000
    })
    Swal.showLoading();
  }

  modalExitoso(titulo, descripcion) {
    Swal.close();
    Swal.fire({
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >' + titulo + '</h1><br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >' + descripcion + '</p><br>',
      // footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Volver al inicio</button> ',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 4000
    })

  }

  modalError(titulo, descripcion) {
    Swal.close();
    Swal.fire({
      allowOutsideClick: true,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 24px; line-height: 26px; align-items: center; text-align: center; color: #171994;" >' + titulo + '</h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >' + descripcion + '</p><br>',
      timer: 30000,
      showCancelButton: true,
      cancelButtonText: "Volver",
      showConfirmButton: false,
      customClass: {
        popup: 'swal2-custom-border',
        cancelButton: 'swal2-custom-cancel-btn'
      },
    })
  }

  verifyExtension(fileName, arrayType: String[]) {
    if (!fileName) {
      return false;
    }
    let arrayFileName = fileName.toLowerCase().split('.');
    for (let index = 0; index < arrayType.length; index++) {
      const element = arrayType[index];
      if (element == arrayFileName[arrayFileName.length - 1]) {
        return true;
      }
    }
    return false;
  }

  validaRut(rutCompleto) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
      return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'K';
    return (this.dv(rut) == digv);
  }

  dv(T) {
    var M = 0, S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'K';
  }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  validaEmail(email: string): boolean {
    try {      
      const patronEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return patronEmail.test(email);
    } catch (error) {
      return false;
    }
  }

}
