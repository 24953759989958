import { Component, OnInit } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-login-wrapper',
  templateUrl: './login-wrapper.component.html',
  styleUrls: ['./login-wrapper.component.css']
})
export class LoginWrapperComponent implements OnInit {

  domainInfo: any = {};
  constructor(
    private generalService: GeneralService,
    private encriptacionService: EncriptacionService,

  ) { }

  ngOnInit(): void {

    this.observerPageInfo();

  }

  async observerPageInfo() {
    
    while (true) {
      try {
        this.domainInfo = this.encriptacionService.getPageInfo();  
        // console.log(this.domainInfo,'this.domainInfo');
        
      } catch (error) {
        
      }
      if (this.domainInfo && this.domainInfo.cus_url_phone) {
        break;
      }
      await this.generalService.sleep(2000);
    }

  }

}
