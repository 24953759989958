<div class="row">
  <div *ngIf="tipo_usuario_name == 'MEDICO'" class="col-md-12">
    <br />
    <div class="minititulo">Mi perfil</div>
    <div class="titulo">Mis antecedentes</div>
    <br />
    <div *ngIf="tipo_usuario_name == 'MEDICO' && !muestraEditar" class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
              class="img-circle img-responsive imagentamano" />
          </div>
        </div>
        <section>
          <form class="form-material m-t-40 row" id="registerform" autocomplete="off" [formGroup]="registerForm">
            <div class="form-group" style="margin-top: 10px">
              <div class="col-md-12">
                <label for="perfil" class="col-md-4 col-form-label colorletra">
                  <button class="colorletra" data-toggle="modal" (click)="editarPerfil()" style="display: flex;">
                    Modificar datos
                    <mat-icon style="color: #316ad8">edit</mat-icon></button></label>
              </div>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
              <input type="text" class="form-control form-control-static datospaciente" disabled
                formControlName="nombre" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Primer Apellido:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="apellido_paterno" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Segundo Apellido</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="apellido_materno" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled formControlName="rut" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="telefono" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="genero" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="fecha_nacimiento" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="email" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Región:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="region" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Comuna:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="comuna" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Dirección:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="direccion" />
            </div>
            <div class="form-group col-md-2 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Numero:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="numero" />
            </div>

            <div class="subTitulo">
              <br />
              Antecedentes academicos
            </div>

            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Grado / Título:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="titulo" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Universidad:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="universidad" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Fecha de egreso:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="fechaegresoX" />
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div *ngIf="tipo_usuario_name == 'MEDICO' && muestraEditar" class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
              class="img-circle img-responsive imagentamano" />
          </div>
        </div>
        <section>
          <form class="form-material m-t-40 row" id="registerform" autocomplete="off" [formGroup]="registerForm"
            (ngSubmit)="modificaMedico()">
            <div class="row col-md-12">
              <div class="col-md-7">
                <label for="perfil" class="col-md-4 col-form-label colorletra"><button class="colorletra" type="button"
                    (click)="cancelarEditarPerfil()" style="display: flex;">
                    Cancelar modificar datos
                    <mat-icon style="color: #316ad8">close</mat-icon></button></label>
              </div>
              <div class="col-md-5 colorletrasboton">
                <button type="buton" class="btn btn-outline-primary btn-lg boton" data-toggle="modal"
                  data-target="#aceptaModificarMedico">
                  Guardar cambios
                </button>
              </div>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
              <input type="text" class="form-control input" formControlName="nombre" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Primer Apellido:</label>
              <input type="text" class="form-control input" formControlName="apellido_paterno" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Segundo Apellido</label>
              <input type="text" class="form-control input" formControlName="apellido_materno" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
              <input type="text" class="form-control input" disabled formControlName="rut" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
              <input type="text" class="form-control input" formControlName="telefono" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
              <select name="genero" class="form-control camposModalAgregar input" id="genero" formControlName="genero">
                <option value="{{ genero }}" selected>{{ genero }}</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="N/A">N/A</option>
              </select>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
              <input type="text" class="form-control input" disabled formControlName="fecha_nacimiento_chile" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
              <input type="text" class="form-control input" formControlName="email" />
            </div>

            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Región:</label>
              <select name="region" class="form-control camposModalAgregar input" id="region" (change)="tipo_comuna()"
                formControlName="region">
                <option value="{{ region }}" selected>{{ region }}</option>
                <option [value]="zona.reg_id" *ngFor="let zona of regiones; let i = index">
                  {{ zona.reg_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Comuna:</label>
              <select name="comuna" class="form-control camposModalAgregar input" id="comuna" (change)="tipo_comuna()"
                formControlName="comuna">
                <option value="{{ comuna }}" selected>{{ comuna }}</option>
                <option [value]="zona.com_id" *ngFor="let zona of comunas; let i = index">
                  {{ zona.com_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Dirección:</label>
              <input type="text" class="form-control input" formControlName="direccion" />
            </div>
            <div class="form-group col-md-2 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Numero:</label>
              <input type="text" class="form-control input" formControlName="numero" />
            </div>

            <div class="subTitulo">
              <br />
              Antecedentes academicos
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Grado / Título:</label>
              <select name="titulo" class="form-control camposModalAgregar input" id="titulo" formControlName="titulo">
                <option value="{{ titulo }}" selected>{{ titulo }}</option>
                <option [value]="zona.tit_id" *ngFor="let zona of grados_Academicos; let i = index">
                  {{ zona.tit_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Universidad:</label>
              <select name="universidad" class="form-control camposModalAgregar input" id="universidad"
                formControlName="universidad">
                <option value="{{ universidad }}" selected>
                  {{ universidad }}
                </option>
                <option [value]="zona.ins_id" *ngFor="let zona of universidades; let i = index">
                  {{ zona.ins_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Fecha de egreso:</label>
              <input type="date" class="form-control input" formControlName="fechaegreso" />
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>

  <!-- paciente -->
  <div *ngIf="tipo_usuario_name == 'PACIENTE' && !muestraEditarPaciente" class="card">
    <br />
    <div class="minititulo">Mi perfil</div>
    <div class="titulo">Mis antecedentes</div>
    <br />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
              class="img-circle img-responsive imagentamano" />
          </div>
          <!--<div class="col-md-6 colorletrasboton">
                        <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#modificaPerfil">Ver antecedentes médicos</button>
                    </div>-->
        </div>
        <section>
          <form class="form-material m-t-40 row" id="registerform" autocomplete="off" [formGroup]="registerForm">
            <div class="form-group" style="margin-top: 10px">
              <div class="col-md-12">
                <label for="perfil" class="col-md-4 col-form-label"><button class="colorletra" type="button"
                    (click)="editarPerfilPaciente()" style="display: flex;">
                    Modificar datos
                    <mat-icon style="color: #316ad8">edit</mat-icon></button></label>
              </div>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
              <input type="text" class="form-control form-control-static datospaciente" disabled
                formControlName="nombre" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Primer Apellido:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="apellido_paterno" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Segundo Apellido</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="apellido_materno" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled formControlName="rut" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="telefono" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="genero" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="fecha_nacimiento" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="email" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="email" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="region" class="col-md-5 col-form-label colorletra">Región:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="region" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="comuna" class="col-md-5 col-form-label colorletra">Comuna:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="comuna" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="direccion" class="col-md-12 col-form-label colorletra">Dirección:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="direccion" />
            </div>
            <div class="form-group col-md-2 m-t-20">
              <label for="numero" class="col-md-12 col-form-label colorletra">Numero:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="numero" />
            </div>
          </form>
        </section>
      </div>
    </div>
    <!-- EDITAR PERFIL DEL MEDICO NUEVO ESTILO -->
  </div>
  <!-- fin paciente-->
  <!-- EDITAR PERFIL DEL PACIENTE -->
  <div class="col-md-12">
    <div *ngIf="tipo_usuario_name == 'PACIENTE' && muestraEditarPaciente" class="card">
      <br />
      <div class="minititulo">Mi perfil</div>
      <div class="titulo">Mis antecedentes</div>
      <br />

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
              class="img-circle img-responsive imagentamano" />
          </div>
        </div>
        <section>
          <form class="form-material m-t-40 row" id="registerform" autocomplete="off" [formGroup]="registerForm">
            <div class="row col-md-12">
              <div class="col-md-7">
                <label for="perfil" class="col-md-4 col-form-label"><button class="colorletra" type="button"
                    (click)="cancelarEditarPerfilPaciente()" style="display: flex;">
                    Cancelar modificar datos
                    <mat-icon style="color: #316ad8">close</mat-icon></button></label>
              </div>
              <div class="col-md-5 colorletrasboton">
                <button type="buton" class="btn btn-outline-primary btn-lg boton" data-toggle="modal"
                  data-target="#aceptaModificar">
                  Guardar cambios
                </button>
              </div>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
              <input type="text" class="form-control input" formControlName="nombre" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Primer Apellido:</label>
              <input type="text" class="form-control input" formControlName="apellido_paterno" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Segundo Apellido</label>
              <input type="text" class="form-control input" formControlName="apellido_materno" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
              <input type="text" class="form-control input" disabled formControlName="rut" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
              <input type="text" class="form-control input" formControlName="telefono" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
              <select name="genero" class="form-control camposModalAgregar input" id="genero" formControlName="genero">
                <option value="" selected>{{ genero }}</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="N/A">N/A</option>
              </select>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
              <input type="text" class="form-control input" disabled formControlName="fecha_nacimiento_chile" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
              <input type="text" class="form-control input" formControlName="email" />
            </div>

            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Región:</label>
              <select name="region" class="form-control camposModalAgregar input" id="region" (change)="tipo_comuna()"
                formControlName="region">
                <option value="{{ region }}" selected>{{ region }}</option>
                <option [value]="zona.reg_id" *ngFor="let zona of regiones; let i = index">
                  {{ zona.reg_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Comuna:</label>
              <select name="comuna" class="form-control camposModalAgregar input" id="comuna" (change)="tipo_comuna()"
                formControlName="comuna">
                <option value="{{ comuna }}" selected>{{ comuna }}</option>
                <option [value]="zona.com_id" *ngFor="let zona of comunas; let i = index">
                  {{ zona.com_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Dirección:</label>
              <input type="text" class="form-control input" formControlName="direccion" />
            </div>
            <div class="form-group col-md-2 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Numero:</label>
              <input type="text" class="form-control input" formControlName="numero" />
            </div>
            <div class="form-group col-md-12 m-t-20">
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>

  <!-- CONTACT CENTER -->
  <div *ngIf="
      (usuario.typ_id == 7 || usuario.typ_id == 8) && !muestraEditarPaciente
    " class="col-md-12">
    <br />
    <div class="minititulo">Mi perfil</div>
    <div class="titulo">Mis antecedentes</div>
    <br />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
              class="img-circle img-responsive imagentamano" />
          </div>
          <!--<div class="col-md-6 colorletrasboton">
                        <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#modificaPerfil">Ver antecedentes médicos</button>
                    </div>-->
        </div>
        <section>
          <form class="form-material m-t-40 row" id="registerform" autocomplete="off" [formGroup]="registerForm">
            <div class="form-group" style="margin-top: 10px">
              <div class="col-md-12">
                <label for="perfil" class="col-md-4 col-form-label"><button class="colorletra" type="button"
                    (click)="editarPerfilPaciente()" style="display: flex;">
                    Modificar datos
                    <mat-icon style="color: #316ad8">edit</mat-icon></button></label>
              </div>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
              <input type="text" class="form-control form-control-static datospaciente" disabled
                formControlName="nombre" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Primer Apellido:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="apellido_paterno" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Segundo Apellido</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="apellido_materno" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled formControlName="rut" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="telefono" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="genero" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="fecha_nacimiento" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="email" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="email" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="region" class="col-md-5 col-form-label colorletra">Región:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="region" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="comuna" class="col-md-5 col-form-label colorletra">Comuna:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="comuna" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="direccion" class="col-md-12 col-form-label colorletra">Dirección:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="direccion" />
            </div>
            <div class="form-group col-md-2 m-t-20">
              <label for="direccion" class="col-md-12 col-form-label colorletra">Numero:</label>
              <input type="text" class="form-control form-control-line datospaciente" disabled
                formControlName="numero" />
            </div>
          </form>
        </section>
      </div>
    </div>
    <!-- EDITAR PERFIL DEL MEDICO NUEVO ESTILO -->
  </div>
  <!-- fin-->
  <!-- EDITAR PERFIL -->
  <div class="col-md-12">
    <div *ngIf="
        (usuario.typ_id == 7 || usuario.typ_id == 8) && muestraEditarPaciente
      " class="card">
      <br />
      <div class="minititulo">Mi perfil</div>
      <div class="titulo">Mis antecedentes</div>
      <br />
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
              class="img-circle img-responsive imagentamano" />
          </div>
        </div>
        <section>
          <form class="form-material m-t-40 row" id="registerform" autocomplete="off" [formGroup]="registerForm">
            <div class="row col-md-12">
              <div class="col-md-7">
                <label for="perfil" class="col-md-4 col-form-label"><button class="colorletra" type="button"
                    (click)="cancelarEditarPerfilPaciente()" style="display: flex;">
                    Cancelar modificar datos
                    <mat-icon style="color: #316ad8">close</mat-icon></button></label>
              </div>
              <div class="col-md-5 colorletrasboton">
                <button type="buton" class="btn btn-outline-primary btn-lg boton" data-toggle="modal"
                  data-target="#aceptaModificar">
                  Guardar cambios
                </button>
              </div>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
              <input type="text" class="form-control input" formControlName="nombre" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Primer Apellido:</label>
              <input type="text" class="form-control input" formControlName="apellido_paterno" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Segundo Apellido</label>
              <input type="text" class="form-control input" formControlName="apellido_materno" />
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
              <input type="text" class="form-control input" disabled formControlName="rut" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
              <input type="text" class="form-control input" formControlName="telefono" />
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
              <select name="genero" class="form-control camposModalAgregar input" id="genero" formControlName="genero">
                <option value="" selected>{{ genero }}</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="N/A">N/A</option>
              </select>
            </div>
            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
              <input type="text" class="form-control input" disabled formControlName="fecha_nacimiento_chile" />
            </div>
            <div class="form-group col-md-6 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
              <input type="text" class="form-control input" formControlName="email" />
            </div>

            <div class="form-group col-md-12 m-t-20">
              <hr class="m-t-0 m-b-40 linea-color" />
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Región:</label>
              <select name="region" class="form-control camposModalAgregar input" id="region" (change)="tipo_comuna()"
                formControlName="region">
                <option value="{{ region }}" selected>{{ region }}</option>
                <option [value]="zona.reg_id" *ngFor="let zona of regiones; let i = index">
                  {{ zona.reg_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3 m-t-20">
              <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Comuna:</label>
              <select name="comuna" class="form-control camposModalAgregar input" id="comuna" (change)="tipo_comuna()"
                formControlName="comuna">
                <option value="{{ comuna }}" selected>{{ comuna }}</option>
                <option [value]="zona.com_id" *ngFor="let zona of comunas; let i = index">
                  {{ zona.com_name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Dirección:</label>
              <input type="text" class="form-control input" formControlName="direccion" />
            </div>
            <div class="form-group col-md-2 m-t-20">
              <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Numero:</label>
              <input type="text" class="form-control input" formControlName="numero" />
            </div>
            <div class="form-group col-md-12 m-t-20">
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
  <!-- fin CONTACT CENTER-->
</div>

<!-- Modal ACEPTA MODIFICAR PACIENTE-->
<div class="modal fade" id="aceptaModificar" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row">
          <div class="col-md-12">
            <p class="tituloModal">
              ¿Estás seguro de los cambios en tu perfil?
            </p>
            <p class="tituloModal"></p>
          </div>
        </div>
        <br /><br />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn botonCancelar colorLetraVolver" data-dismiss="modal">
                Volver atras
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" data-dismiss="modal" data-toggle="modal" (click)="modificaPacienteAcepta()"
                class="btn botonVolver">
                Sí, estoy seguro
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal ACEPTA MODIFICAR MEDICO -->
<div class="modal fade" id="aceptaModificarMedico" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row">
          <div class="col-md-12">
            <p class="tituloModal">
              ¿Estás seguro de los cambios en tu perfil?
            </p>
            <p class="tituloModal"></p>
          </div>
        </div>
        <br /><br />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">
                Volver atras
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" (click)="modificaMedicoAcepta()"
                class="btn btn-outline-primary btn-lg botonModalAgregar">
                Sí, estoy seguro
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- nuevo formulario de cambio de datos reactivo sin usar 2 templates-->

<!-- 
  <div *ngIf="tipo_usuario_name == 'PACIENTE'">
    <div class="minititulo">Mi perfil</div>
    <div class="titulo">Mis antecedentes</div>
  
    <div class="card ">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 container-fluid">
            <form [formGroup]="registerForm" (ngSubmit)="modificaPacienteAcepta()">
              <div class="row p-20">
                <div class="col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content: center;">
                  <img src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user"
                    class="img-circle img-responsive imagentamano" />
                  <label for="perfil" class="col-md-12 col-form-label text-center">
                    <button class="colorletra" type="button" (click)="editarPerfilPaciente()"
                      style="display: flex; width: 100%; justify-content: center;" *ngIf="!muestraEditarPaciente">
                      Modificar datos
                      <mat-icon style="color: #316ad8">edit</mat-icon>
                    </button>
                    <button class="colorletra" type="button" (click)="cancelarEditarPerfilPaciente()"
                      style="display: flex; width: 100%; justify-content: center;" *ngIf="muestraEditarPaciente">
                      Cancelar modificar datos
                      <mat-icon style="color: #316ad8">close</mat-icon>
                    </button>
                  </label>
                </div>
  
                <div class="col-md-8 col-sm-12">
                  <div class="row">
                    <div class="col-md-6 col-sm-12 form-group" *ngFor="let input of primeraSeccionInputs">
                      <label [for]="input.id" class="colorletra">{{ input.label }}</label>
                      <input class="form-control datospaciente m-0"
                        [ngClass]="{'input-antecedentes': !muestraEditarPaciente}" [type]="input.type"
                        [id]="input.id" [formControlName]="
                        input.id === 'fecha_nacimiento'
                          ? (muestraEditarPaciente ? 'fecha_nacimiento' : 'fecha_nacimiento_chile')
                          : input.formControlName"
                        [placeholder]="input.placeholder" [disabled]="!muestraEditarPaciente">
                    </div>
                  </div>
                </div>
              </div>
              <hr>
  
              <div class="row p-20">
                <div class="col-md-6 col-sm-12 form-group" *ngFor="let input of segundaSeccionInputs">
                  <label [for]="input.id" class="colorletra">{{ input.label }}</label>
                  <input class="form-control datospaciente m-0"
                    [ngClass]="{'input-antecedentes': !muestraEditarPaciente}" [type]="input.type"
                    [id]="input.id" [formControlName]="input.formControlName"
                    [placeholder]="input.placeholder" [disabled]="!muestraEditarPaciente">
                </div>
              </div>
              <hr>
              <div class="row p-20">
                <div class="col-md-3 col-sm-12 form-group" *ngFor="let input of terceraSeccionInputs">
                  <label [for]="input.id" class="colorletra">{{ input.label }}</label>
                  <input class="form-control datospaciente m-0"
                    [ngClass]="{'input-antecedentes': !muestraEditarPaciente}" [type]="input.type"
                    [id]="input.id" [formControlName]="input.formControlName"
                    [placeholder]="input.placeholder" [disabled]="!muestraEditarPaciente">
                </div>
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
   -->