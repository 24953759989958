import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';
import { VerificacionDocumentosService } from 'src/app/services/verificacion-documentos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})
export class DocumentoComponent  {

  datos_url: any = {
    id_documento:null
  };
  retenido: boolean = false;
  datos_documento: any = {};
  pdf_url: any = URL_DOCS+"/uploads/noimage.png";

  constructor(
    private activatedRoute: ActivatedRoute,
    private verificacionServices: VerificacionDocumentosService,
    private router: Router,
    private sanitizer: DomSanitizer
  )
  {
    this.activatedRoute.params.subscribe(parametros => {
      this.datos_url = parametros;
    });

    if (this.datos_url.id_documento) {
      this.buscarDocumento();
    } else {
      //console.log('No viene parametros url');
      
    }
  }




  modalMensaje(titulo) {
    Swal.close();
    Swal.fire({
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
        ' <h1 style="font-family: Mulish;padding: 0 20px; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > '+titulo+'</h1><br>',
      timer: 2000
    })
  }

  modalErrorMensaje(titulo) {
    Swal.close();
    Swal.fire({
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">error_outline</i> <br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > '+titulo+'</h1><br>',
      timer: 2000
    })
  }


  buscarDocumento(){
    //console.log('Buscando');
    this.verificacionServices.postTraerDatosDocumento({info_doc:this.datos_url.id_documento})
      .then(result => {
        this.datos_documento = result;
        //console.log(this.datos_documento);
        if (this.datos_documento.pre_sta_id == 33) {
          this.retenido = false;
        } else {
          this.retenido = true;
        }
        //console.log(this.retenido);
        // this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.pdf_url = URL_DOCS+this.datos_documento.att_path;
      }).catch(error => {
        this.modalErrorMensaje('No existe el documento');
        var url = "verificaDocumento";
        this.datos_documento = {};
        this.router.navigate([url]);
      })
  }


  onChange(newValue) {
    //console.log(newValue);
    this.datos_url = { id_documento : newValue };
  }

  retenerDocumento() {
    this.verificacionServices.postRetenerDocumento({info_doc:this.datos_url.id_documento})
    .then(result => {
      // this.datos_documento = result;
      this.buscarDocumento();
      // this.pdf_url = this.sanitizer.bypassSecurityTrustResourceUrl(URL_DOCS + this.datos_documento.att_path);
      this.modalMensaje('Documento retenido exitosamente');
      var url = "verificaDocumento";
      this.router.navigate([url]) //navigateByUrl('/dashboard');
      setTimeout(() => window.location.reload(), 1000);
    }).catch(error => {
      this.modalErrorMensaje('Error al retener documento.');
      var url = "verificaDocumento";
      this.router.navigate([url]);
    })
  }

  retenerModal() {
    Swal.fire({
      html:
      `<div style="text-align: center; font-family: Mulish; font-style: normal;">
      <h1 style="padding: 0 50px; color: red; font-weight: bold; font-size: 24px; line-height: 32px;">Retener documento</h1>
      <br>
      <p style="color: #171994; font-size: 18px; line-height: 24px;">¿Está seguro de retener este documento?</p>
    </div>`,
      showCancelButton: true,
      // confirmButtonColor: '#3085D6',
      // cancelButtonColor: '#d33',
      cancelButtonText: 'Retener',
      confirmButtonText: 'Volver',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn-swal-v2-confirm',
        cancelButton: 'btn-swal-v2-cancel',
        popup: 'container-swal-v2-customer'
      }
    })
      .then((result) => {
        //console.log(result);
        
        if (result.isConfirmed) {
          //console.log('Volver');
        } else {
          this.retenerDocumento();
        }
      })
    
  }
}
