import { Component, Input, OnInit, ViewChild,  } from '@angular/core';
import { ScheduleService } from 'src/app/services/schedule.service';
import { FunctionsService } from 'src/app/services/functions.service';


import Swal from 'sweetalert2';
import { CalendarMonthComponent } from 'src/app/components/calendar/calendar-month/calendar-month.component';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

// import { CalendarEvent } from 'angular-calendar';

@Component({
  selector: 'app-configurar-agenda',
  templateUrl: './configurar-agenda.component.html',
  styleUrls: ['./configurar-agenda.component.css']
})

export class ConfigurarAgendaComponent implements OnInit {
  @Input() doctor: any = {}; //Se entrega el doctor externo
  _title = 'Mi agenda';
  _subtitle = 'Configurar agenda'

  _usuario: any = {};
  nombre_medico = '';

  @ViewChild(CalendarMonthComponent) CALENDAR: CalendarMonthComponent;

  hoursDay: Array<any> = [];

  selected_day: any = {};                   //día seleccionado
  hours_selected_day: Array<any> = [];      //horas del dia seleccionado
  hours_list_doc: Array<any> = [];          //listado de horas del doctor, del mes

  hours_selected_bloquear: Array<any> = [];      //horas a bloquear
  hours_selected_habilitar: Array<any> = [];      //horas a habilitar
  hours_selected_habilitar_no_bloq: Array<any> = [];      //horas a habilitar que no estan bloqueadas

  checkBloquear: boolean = false;
  checkHabilitar: boolean = false;
  checkHabilitarNoBloqueadas: boolean = false;

  btnBloquear: boolean = false;
  btnHabilitar: boolean = false;

  num_hours_bloqueables = 0;
  num_hours_habilitables = 0;
  num_hours_no_gestionadas = 0;
  dia_editable: boolean = false;
  segundo_dia_habil: any = "";
  edit_reason = '';

  constructor(
    private _functions: FunctionsService,
    public _schedule: ScheduleService,
    public _general: GeneralService
  ) { }

  ngOnInit(): void {

    this._usuario = new EncriptacionService().getUserInfo();

    this.nombre_medico =  (this._usuario.typ_id == 1 )? this._usuario.nombre_usuario: this.doctor.use_name; // espacio para el CC o CM

    this.getHorasBloques();
    this.getHorasMes();
    this._general.postNextHabilDay()
      .then(result => {
        this.segundo_dia_habil = result[0].fecha;
      })
  }

  ngOnChanges() {
    //console.log(this.doctor);
    
    this.getHorasMes();

    
  }

  getHorasBloques(){ 
    this._schedule.getScheduleTimes()
    .then( (data: any) => {
      this.hoursDay = data;
    })
  }

  //respuesta al clickear dia en el calendario
  clickDayCalendar($event){
    this.num_hours_no_gestionadas = 0;
    this.hours_selected_habilitar_no_bloq = [];
    this.selected_day = this._functions.formatApptDate(new Date($event.date), '12:00');
    this.selected_day.str_date = this.selected_day.int.substr(0, 4) + '-' + this.selected_day.int.substr(4, 2) + '-' + this.selected_day.int.substr(6, 2);

    let restr = this.segundo_dia_habil;
    // restr.setDate(restr.getDate() + 2);
    // //console.log(restr);
    // //console.log(this.date2number(this.selected_day.date));
    // //console.log(this.date2number(restr));

    this.dia_editable = (this.date2number(this.selected_day.date) >= this.date2number(new Date()))? true: false; 
    this.dia_editable = (this.date2number(this.selected_day.date) > restr && (this._usuario.typ_id == 1 || this._usuario.typ_id == 7 ) )? false : this.dia_editable;

    this.hours_selected_day = this.hoursDay;
    for (let dia_sch of this.hours_list_doc) {
      if(this.selected_day.int == dia_sch.int){ //el doctor tine horas el dia seleccionado

        for ( let hou of dia_sch.hours ){
          hou.status = (hou.sta_id == 22)? false: true;       //22 = no activa
          
          hou.bloqueable = (hou.sta_id == 21)? true: false;   //si está reservada(24), bloqueda (para reservar) (23) o no activa (22) no se puede bloquear
          hou.bloqueable  = (this.date2number(this.selected_day.date) >= this.date2number(new Date()))? hou.bloqueable : false;  //si es una fecha previa no está disponible para modificar
          hou.bloqueable = (this.date2number(this.selected_day.date) > restr && (this._usuario.typ_id == 1 || this._usuario.typ_id == 7 ) )? false : hou.bloqueable; //mayor a 2 dias no esta disponible para el medico
          
          hou.habilitable = (hou.sta_id == 22)? true: false;   
        }
            
        let blocks = [];
        for (let block of this.hoursDay){
          let ind = dia_sch.hours.findIndex( (hour) => hour.from == block.from );
          
          if ( ind !== -1 ) {
            blocks.push(dia_sch.hours[ind]);
            // //console.log(dia_sch.hours[ind])
            // estado_hora: "ACTIVA"
            // from: "12:30"
            // id: 3711
            // sta_id: 21
            // sta_name: "ACTIVA"
            // to: "13:00"
          }
          else{
            blocks.push(block);
            // //console.log(block)
            // from: "10:30"
            // id: 6
            // spe_id: -1
            // to: "11:00"
            this.num_hours_no_gestionadas = this.num_hours_no_gestionadas + 1;
          }
        }
        this.hours_selected_day = blocks;
        //console.log(this.hours_selected_day);
        break;
      }
    }

    this.checkBloquear = false;         //check descheckeado
    this.checkHabilitar = false;

    this.btnBloquear = false;           //boton desabled
    this.btnHabilitar = false;          

    this.num_hours_bloqueables = 0;     //cantidad de hora bloqueables
    this.num_hours_habilitables = 0;

    for (let hour of this.hours_selected_day){
      this.num_hours_bloqueables = (hour.bloqueable == true)?  this.num_hours_bloqueables + 1 : this.num_hours_bloqueables;
      hour.selected_bloq = false;
      this.num_hours_habilitables = (hour.habilitable == true)? this.num_hours_habilitables + 1: this.num_hours_habilitables;
      hour.selected_hab = false;
    }

  }



  // consulta las horas del doctor del mes
  getHorasMes() {
    var doctor_id;
    if (this.doctor.doc_id && (this._usuario.typ_id == 7 || this._usuario.typ_id == 8)) {
      doctor_id = this.doctor.doc_id;
    } else {
      doctor_id = this._usuario.doc_id;
    }
    // //console.log(doctor_id);
    
    this._schedule.getScheduleHoursDoc(doctor_id, null,true)
      .then( (data: any) => {
        this.hours_list_doc = data;        
        for (let dia_sch of this.hours_list_doc){
          let dia = new Date(dia_sch.sch_date)
          dia_sch.int = `${dia.getFullYear()}${((dia.getMonth() +101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`;
        }
        if(this.selected_day.day){ 
          this.clickDayCalendar({date: this.selected_day.date}); //emulo clickear el dia previamente seleccionado en el calendar
        };  
    })
  }



  clickHourBloquear(itemHour) {
    if (itemHour.bloqueable) {
      let ind = this.hours_selected_day.findIndex( (hour) => hour.id == itemHour.id );
      if ( ind !== -1 ) {
        this.hours_selected_day[ind].selected_bloq = !this.hours_selected_day[ind].selected_bloq;
      }
      let ind2 = this.hours_selected_day.findIndex( (hour) => hour.selected_bloq == true );
      this.btnBloquear = ( ind2 !== -1 )? true : false;
    }
  }

  clickHourHabilitar(itemHour) {
    //console.log(itemHour);
    if ((itemHour.spe_id == -1 && this._usuario.typ_id == 8)) {
      let ind = this.hours_selected_day.findIndex((hour) => hour.id == itemHour.id);
      if (ind !== -1) {
        this.hours_selected_day[ind].selected_hab = !this.hours_selected_day[ind].selected_hab
      }
      let ind2 = this.hours_selected_day.findIndex((hour) => hour.selected_hab == true);
      this.btnHabilitar = (ind2 !== -1) ? true : false;
    } else if (!itemHour.bloqueable) {
      let ind = this.hours_selected_day.findIndex((hour) => hour.id == itemHour.id);
      if (ind !== -1) {
        this.hours_selected_day[ind].selected_hab = !this.hours_selected_day[ind].selected_hab
      }
      let ind2 = this.hours_selected_day.findIndex((hour) => hour.selected_hab == true);
      this.btnHabilitar = (ind2 !== -1) ? true : false;
    }
  }

  

  checkHourBloq(){
    for(let hour of this.hours_selected_day){
      if (hour.bloqueable == true){
        hour.selected_bloq = (this.checkBloquear)? true: false;
      }
    }
    let ind2 = this.hours_selected_day.findIndex( (hour) => hour.selected_bloq == true );
    this.btnBloquear = ( ind2 !== -1 )? true : false;
  }

  checkHourHab(){
    for(let hour of this.hours_selected_day){
      if (hour.habilitable == true){
        hour.selected_hab = (this.checkHabilitar)? true: false;
      }
    }
    let ind2 = this.hours_selected_day.findIndex( (hour) => hour.selected_hab == true );
    this.btnHabilitar = ( ind2 !== -1 )? true : false;
  }

  checkHourHabNoBloq(){
    for(let hour of this.hours_selected_day){
      if (hour.spe_id == -1){
        hour.selected_hab = (this.checkHabilitarNoBloqueadas)? true: false;
      }
    }
    let ind2 = this.hours_selected_day.findIndex( (hour) => hour.selected_hab == true );
    this.btnHabilitar = ( ind2 !== -1 )? true : false;
  }

  bloquearHoras(){
    let hours = [];
    this.edit_reason = '';
    for(let hour of this.hours_selected_day){
      if (hour.bloqueable == true && hour.selected_bloq == true){
        hours.push(hour)
      }
    }
    this.hours_selected_bloquear = hours;
  }

  habilitarHoras(){
    let hours = [];
    this.edit_reason = '';
    let hours_sin_habilitar = [];
    for(let hour of this.hours_selected_day){
      if (hour.habilitable == true && hour.selected_hab == true) {
        hours.push(hour)
      } else if (hour.spe_id == -1 && this._usuario.typ_id == 8 && hour.selected_hab == true) {
        hours_sin_habilitar.push(hour)
      }

    }

    this.hours_selected_habilitar = hours;
    this.hours_selected_habilitar_no_bloq = hours_sin_habilitar;
    //console.log(this.selected_day);
    

  }

  //confirmar modales
  confirmarBloquearHoras(){
    let hours = [];
    for(let hour of this.hours_selected_bloquear){
      hours.push({id: hour.id, activa: false})
    }
    this.editHoras( this._usuario.doc_id, hours, true, this.edit_reason )
  }

  confirmarHabilitarHoras(){
    let hours = [];
    for(let hour of this.hours_selected_habilitar){
      hours.push({id: hour.id, activa: true})
    }
    this.editHoras(this._usuario.doc_id, hours, false, this.edit_reason )
  }

  
  date2number(date){
    return (date.getFullYear()*10000 + (date.getMonth() +1)*100 + date.getDate())
  }

  // Funcion agrega horas
  editHoras(id_doctor, hours, bloqueo, edit_reason){
    Swal.showLoading();
    this._schedule.editScheduleHours(id_doctor, hours, edit_reason) 
      .then( async (data: any) => {
        if (this.hours_selected_habilitar_no_bloq.length >0) {
          await this.addHoras(this.doctor.doc_id,this.selected_day.str_date,this.hours_selected_habilitar_no_bloq);
        }
        let text1 = (bloqueo)? 'bloqueadas': 'habilitadas';
        Swal.fire({
          allowOutsideClick: false,
          html: `<i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>
                <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Las horas han sido ${text1} exitosamente</h1> <br>
                <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>`,
          showConfirmButton: false,
          timer: 2500
        })
        this.getHorasMes();
    })
  }

  boolCheckHourHabNoBloq() {
    if(this.num_hours_no_gestionadas<this.hoursDay.length) {
      return true;
    }
    return false;
   }

  // Funcion agrega horas
  async addHoras(id_doctor, day, hours){
    await this._schedule.addScheduleHours(id_doctor, day, hours) 
      .then( (data: any) => {
        this.getHorasMes(); //actualizar las info en pantalla
    })
  }
  

}