import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';
import { AgreementService } from 'src/app/services/agreement.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-beneficio',
  templateUrl: './beneficio.component.html',
  styleUrls: ['./beneficio.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BeneficioComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private agreementService: AgreementService,
    private generalServices: GeneralService,
    private router: Router,
  ) { }
  URL_DOCS = URL_DOCS;
  agreement: any = {};

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((parametros: any) => {
      // console.log(parametros);

      if (parametros.agre_id_ && parametros.route) {
        this.getAgreement({ agre_id_: parametros.agre_id_, route: parametros.route });

      }
    });
  }

  getAgreement({ agre_id_ = '', route = '' }) {
    this.generalServices.modalCargando();
    this.agreementService.agreement({ agre_id_, route })
      .then((result: any) => {
        // console.log(result);
        if (result && result.benefit) {
          this.agreement = result.benefit;
        }
        Swal.close();
      })
      .catch(error => {
        this.generalServices.modalError('Error', 'Error al traer beneficio');
      })
  }

  volver() {

    window.history.back(); // Esto navegará hacia atrás en el historial del navegador

  }

  hola(arg1, arg2) {
    // console.log('Hola');
    // console.log(arg1);
    // console.log(arg2);

  }
}
