import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginValidationsService } from 'src/app/auth/login-validations.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css']
})
export class PacienteComponent implements OnInit {

  _usuario: any = {};
  rut: '';
  tipo_usuario_name: string;



  generos: string[] = ['Masculino', 'Femenino', 'Prefiero no decirlo'];
  areaCodes: any[] = [];
  selectedAreaCode: any = { cou_phone_code: '56', cou_flag: 'fi-cl', cou_min_phone: 9, cou_max_phone: 9 };

  paises: any[] = [];
  regiones: any[] = [];
  comunas: any[] = [];

  pagina: any;


  public fieldTextType1: boolean = false;
  public fieldTextType2: boolean = false;
  public fieldTextType3: boolean = false;

  public signupPass: boolean = false;
  public signupPass1Focus: boolean = false;
  public signupPass2Focus: boolean = false;



  titulo: any = [
    "Actualiza tus datos personales",
    "Crea tu contraseña",
    "Reservar tu hora",
    "Tener videoconsultas",
    "Revisar tu historial de consultas",
    "Revisar tus datos personales",
    "Revisar tus beneficiarios"
  ];
  titulo2: any = [
    "¡Bienvenido!",
    "¡Bienvenido!",
    "Reservar tu hora en 4 simples pasos.",
    "Ir a la sala de espera y participar de la videollamada con tu médico ",
    "Ver el detalle de tus consultas y descargar los documentos generados.",
    "Editar tu perfil, agregar y eliminar beneficiarios ",
    "Editar tu perfil, agregar y eliminar beneficiarios "
  ];

  subtitulo: any = [
    "",
    "",
    "Selecciona el área médica o un médico/profesional específico.",
    "Selecciona sala de espera y conéctate en línea con tu médico/profesional.",
    "Para que tengas un registro de todas tus horas.",
    "En donde podrás editarlos y revisar tus antecedentes médicos.",
    "Para que lleves un registro de los vinculados a tu perfil."
  ];
  siguiente: any = [
    "Siguiente",
    "Siguiente",
    "Siguiente",
    "Siguiente",
    "Siguiente",
    "Siguiente",
    "¡Empezemos!"
  ];
  estilo: any = [
    '#E8E8E8',
    '#E8E8E8',
    '#E8E8E8',
    '#E8E8E8',
    '#E8E8E8',
    '#E8E8E8',
    '#E8E8E8',
  ]
  constructor(
    private router: Router,
    private GeneralService: GeneralService,
    public _usuarioService: UsuarioService,
    private fb: FormBuilder,
    private loginValidationsService: LoginValidationsService,

  ) { }

  ngOnInit(): void {
    // console.log(this.pagina);
    // console.log(this.selectedAreaCode);
    this.updatePhoneValidators();

    // Verificar si hay un token en el sessionStorage
    if (!sessionStorage.getItem('token')) {
        this._usuarioService.logout();
        return; // Salir del método si no hay token
    }

    // Obtener información del usuario
    this._usuario = new EncriptacionService().getUserInfo();
    this.registerForm.controls['use_id'].setValue(this._usuario._id);

    this.rut = this._usuario.rut;
    this.tipo_usuario_name = this._usuario.typ_name;

    // Obtener información del perfil
    this._usuarioService.informacionPerfil(this.rut, this.tipo_usuario_name).subscribe(
        (data) => {
            console.log('Información perfil:', data);
            this._usuario = data; // Asignar la información del perfil al usuario

            // Verificar el apellido paterno y cambiar la página
            if (this._usuario.apellido_paterno === ".-") {
                this.pagina = 0;
                this.limpiarColores();
                // console.log('Enviando a página 0');
              } else if(this._usuario.tiene_password == 'no'){
                  this.pagina = 1;
                  this.limpiarColores();
                  // console.log('Enviando a página 1');
              } else{
                this.pagina = 2;
                this.limpiarColores();
                  // console.log('Enviando a página 2');
              }
              // console.log('Página inicial:', this.pagina);

                
            // Establecer el RUT en el formulario si está disponible
            if (this._usuario && this._usuario.rut) {
                const fechaNacimiento = this._usuario.fecha_nacimiento_chile
                    ? new Date(this._usuario.fecha_nacimiento_chile).toISOString().split('T')[0]
                    : null;

                // Llenar el formulario con los valores del usuario
                this.registerForm.patchValue({
                    rut: this._usuario.rut,
                    nombre: this._usuario.nombre,
                    fecha_nacimiento_chile: fechaNacimiento // Asignar la fecha en formato 'YYYY-MM-DD'
                });
            }
        },
        (error) => {
            console.error('Error al obtener la información del perfil:', error);
            // Verifica si el error es el que provoca que la sesión se cierre
        }
    );

    // Obtener códigos de países
    this._usuarioService.getCodPaises()
        .then((data: any) => {
            this.areaCodes = data as any[];
        })
        .catch(error => {
            console.error('Error al cargar los códigos de países:', error);
        });

    // Obtener países
    this._usuarioService.getPaises()
        .then(paises => {
            this.paises = paises as any[];
            this.registerForm.get('pais').valueChanges.subscribe(cou_id => {
                this._usuarioService.getRegiones(cou_id).then(regiones => {
                    this.regiones = regiones as any[];
                    this.comunas = []; // Limpiar comunas al cambiar de país
                }).catch(error => {
                    console.error('Error al cargar las regiones:', error);
                });
            });
        })
        .catch(error => {
            console.error('Error al cargar los países:', error);
        });

    // Obtener comunas al cambiar la región
    this.registerForm.get('region').valueChanges.subscribe(reg_id => {
        this._usuarioService.getComunas(reg_id).then(comunas => {
            this.comunas = comunas as any[];
        }).catch(error => {
            console.error('Error al cargar las comunas:', error);
        });
    });
}

  dropdownOpen: boolean = false;

  
  public registerForm = this.fb.group({

    // step1

    rut: [''],
    use_id: [''],
    nombre: ['', [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-ZáéíóúÁÉÍÓÚñÑçÇ ]+$")]],
    apellido_paterno: ['', [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-ZáéíóúÁÉÍÓÚñÑçÇ ]+$")]],
    apellido_materno: ['', [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-ZáéíóúÁÉÍÓÚñÑçÇ ]+$")]],
    genero: ['', Validators.required],
    fecha_nacimiento_chile: ['', Validators.required],

    // step2

    email: ['', [Validators.required, Validators.email, Validators.minLength(3)]],
    codigo_area: [this.selectedAreaCode.cou_phone_code, Validators.required],
    telefono: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    pais: ['', Validators.required],
    region: ['', Validators.required],
    comuna: ['', Validators.required],
    direccion: ['', Validators.required],
    numero: ['', Validators.required],
    rest: [''],
  })

  public cambiaPass = this.fb.group({
    contrasena_actual: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva_2: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    // codigo_verificacion: [''],
  }, {
    validators: this.passwordsIguales('contrasena_nueva', 'contrasena_nueva_2')
  });
  
  
  selectAreaCode(areaCode: any) {
    this.selectedAreaCode = areaCode;  
    this.registerForm.get('codigo_area').setValue(areaCode.cou_phone_code);

    const telefonoControl = this.registerForm.get('telefono');
    const fullPhoneNumber = `${areaCode.cou_phone_code}${telefonoControl.value}`;

    // console.log(fullPhoneNumber);

    this.updatePhoneValidators();
    this.closeDropdown();  // Cierra el dropdown después de seleccionar
  }

  updatePhoneValidators() {
    const telefonoControl = this.registerForm.get('telefono');
    telefonoControl.setValidators([
      Validators.required,
      Validators.minLength(this.selectedAreaCode.cou_min_phone),
      Validators.maxLength(this.selectedAreaCode.cou_max_phone),
      Validators.pattern("^[0-9]*$")
    ]);
    telefonoControl.updateValueAndValidity();
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown() {
    this.dropdownOpen = false;
  }

  formSubmitted: boolean = false;
  formSubmitted2: boolean = false;

  campoNoValido(campo: string) {
    const control = this.registerForm.get(campo);
    return control?.invalid && (control?.touched || control?.dirty || this.formSubmitted);
  }


  onSubmit() {
    if (this.registerForm.valid) {
      this.formSubmitted = true;
      // Lógica de envío del formulario
      // console.log('Formulario enviado con éxito', this.registerForm.value);
    } else {
      // console.log('Formulario inválido');
    }
  }

  volverBoton() {
   
      this.pagina = this.pagina - 1;
      this.limpiarColores();
      // console.log(this.pagina);
  
  }

  salirBoton(){
    if(this.pagina <= 2){
      this._usuarioService.logout();
    }

  }

  irDashboard() {
    this.router.navigate(['dashboard']) //navigateByUrl('/dashboard');
    setTimeout(() => window.location.reload(), 1000);
  }
  siguienteBoton() {
    if (this.pagina === 0) {
      this.modificarDatos();  // Llama a la función de modificación solo en la primera página
    } else if(this.pagina === 1){
      this.cambiaPassword();
    }else {
      this.pagina = this.pagina + 1;
      this.limpiarColores();
      // console.log(this.pagina);
    }
  }
  
  modificarDatos() {
    this.formSubmitted = true; // Marcar que el formulario fue enviado
    if (this.registerForm.invalid) {
      // Detener el flujo si el formulario es inválido
      Swal.fire({
        icon: 'error',
        title: 'Hay errores en el formulario, verifique los campos e intente de nuevo.',
        showConfirmButton: false,
        timer: 3000
      });
      return;
    }
  
    if (this.registerForm.value.comuna) {
      // Mostrar alerta de "Cargando"
      Swal.fire({
        allowOutsideClick: false,
        html: ` <div style="margin: 40px 0;">
                  <i class="material-icons" style="color:#2BABB7; font-size: 58px; margin-bottom:25px">schedule</i>
                  <h1 style="font-family: Mulish; font-style: normal; font-weight: 700; font-size: 24px; line-height: 32px; text-align: center; color: #171994;">Cargando</h1>
                </div>`,
        showConfirmButton: false
      });
  
      // Obtener el código de área seleccionado o usar el valor por defecto
      const codigoArea = this.registerForm.value.codigo_area || this.selectedAreaCode.cou_phone_code;
  
      // Concatenar el código de área y el teléfono
      const telefono = `+${codigoArea}${this.registerForm.value.telefono}`;
  
      // Preparar los datos a enviar
      const dataParaEnviar = {
        ...this.registerForm.value,
        telefono: telefono
      };
  
      // Llamada al servicio para enviar los datos
      this._usuarioService.modificarUsuario(dataParaEnviar).then((data: any) => {
        // Alerta de éxito
        Swal.fire({
          allowOutsideClick: false,
          html: ` <div style="margin: 40px 0;">
                    <i class="material-icons" style="color:#171994; font-size: 58px; margin-bottom:25px">mood</i>
                    <h1 style="font-family: Mulish; font-style: normal; font-weight: 800; font-size: 38px; line-height: auto; text-align: center; color: #171994; margin-bottom:25px;">
                      Tus datos han sido guardados con éxito
                    </h1>
                    <button style="border-radius: 30px; border: none; background: #171994; font-family: Mulish; font-style: normal; font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF; padding: 8px 50px">Aceptar</button>
                  </div>`,
          showConfirmButton: false,
          timer: 3000
        });
  
        // Avanzar a la siguiente página después de un pequeño delay
        setTimeout(() => {
          this.pagina = this.pagina + 1;
          this.limpiarColores();
          // console.log(this.pagina);
        }, 1000);
  
      }).catch((error) => {
        console.error('Error al enviar los datos', error);
        Swal.fire({
          icon: 'error',
          title: 'Hubo un error al enviar los datos.',
          showConfirmButton: false,
          timer: 3000
        });
      });
      if(this._usuario.tiene_password == 'no'){
        this.pagina == 1;
      }else{
        this.pagina == 2;
      }
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Verifique que haya ingresado la región y comuna.',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 1000
      });
    }
  }

  toggleFieldTextType1 = () => {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  toggleFieldTextType2 = () => {
    this.fieldTextType2 = !this.fieldTextType2;
  }
  toggleFieldTextType3 = () => {
    this.fieldTextType3 = !this.fieldTextType3;
  }

  passLength: number = 0;
  passMinLength: boolean = false;
  passCapital: boolean = false;
  passLetter: boolean = false;
  passNumber: boolean = false;
  equalPass: boolean = false;
  contrasena_nueva_2: string;

  checkPass1 = () => {
    const pass1: string = this.cambiaPass.get('contrasena_nueva').value;
    this.passLength = pass1.length;
    if (this.passLength >= 8) {
      this.passMinLength = true;
    } else {
      this.passMinLength = false;
    }
    if (pass1.match(/[A-Z]/g)) {
      this.passCapital = true;
    } else {
      this.passCapital = false;
    }
    if (pass1.match(/[a-z]/g)) {
      this.passLetter = true;
    } else {
      this.passLetter = false;
    }
    if (pass1.match(/[0-9]/g)) {
      this.passNumber = true;
    } else {
      this.passNumber = false;
    }
  }

  campoNoValidoPassword(campo: string): boolean {

    if (this.cambiaPass.get(campo) != this.cambiaPass.get('contrasena_nueva') && this.formSubmitted2) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    } else {
      return false;
    }
  }

  cambiaPassword() {

    this.formSubmitted2 = true;
    if (this.cambiaPass.invalid) {
      //console.log('formulario invalido');
      return;
      //no retorno nada si es invalido el formulario
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Espere por favor...',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 3000
      })
      this._usuarioService.modificarPassword(this.cambiaPass.value)
        .subscribe((data: any) => {
          if(data.status){
            Swal.fire({
              allowOutsideClick: false,
              html:
                ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
                ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: auto; align-items: center; text-align: center; color: #171994;" >La contraseña ha sido modificada exitosamente </h1> <br>' +
                ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
              showConfirmButton: false,
              timer: 3000
  
            })
            setTimeout(() => {
              this.pagina = this.pagina + 1;
              this.limpiarColores();
              // console.log(this.pagina);
            }, 1000);          
          }else{
            Swal.fire({
              allowOutsideClick: false,
              html:
                ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
                ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: auto; align-items: center; text-align: center; color: #171994;" >' + data.message + '</h1> <br>' +
                ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
              showConfirmButton: true,
              
  
            })
            setTimeout(() => {
              // console.log(this.pagina);
            }, 1000);  
          }
        }, (err) => {
          // Si sucede un error
          Swal.fire({
            allowOutsideClick: false,
            html:
              ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
              ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: auto; align-items: center; text-align: center; color: #171994;" >' + err.error.message + '</h1> <br>' +
              ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >Intente nuevamente. </p><br>',

          })
          /*Swal.fire('Error', err.error.message, 'error' );*/
        })
    }

  }

  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      this.formSubmitted2 = false;
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value !== pass2Control.value) {
        return {
          noEsIgual: true
        };
      }
    }
  }

  
  

  empezemosBoton() {
    // this.pagina = this.pagina + 1;

    this.GeneralService.postRealizaOnBoarding()
      .then(result => {
        //console.log(result);
        this.irDashboard();
      })
      .catch(error => {
        console.log(error);
        this.irDashboard();
      })

  }

  limpiarColores() {
    this.estilo = [
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
    ];

    if (this.pagina <= 6) {
      this.estilo[this.pagina] = '#2AC9CE';
    } else {
      this.estilo[4] = '#2AC9CE';
    }
  }

}

