<app-title-header *ngIf="_usuario.typ_id == 1"
  [title]= '_title'
  [subtitle]='_subtitle'>
</app-title-header>

<div class="configurar-agenda">

  <h3>Selecciona un día para configurar tu agenda</h3>
  
  <div class="div-calendar">
    <app-calendar-month
      (messageEvent)='clickDayCalendar($event)'>
    </app-calendar-month>
  </div>
  

  <div class="div-selected-day" *ngIf="selected_day.day">

    <div class="not-hours" *ngIf="(dia_editable == false)" style="margin-top: 60px;">
      <div style="display: flex; color: #0e0087;">
        <mat-icon>info</mat-icon>
        <span>No puedes  bloquear / habilitar horas para este día.</span>
      </div>
    </div>
    
    <div class="bloquear-horas" *ngIf="(dia_editable == true)">
      <h3 style="margin-bottom: 20px;">Horas disponibles para bloquear al {{selected_day.weekday}} {{selected_day.day}} de {{selected_day.month}} {{selected_day.year}}</h3>
      <div class="not-hours" *ngIf="(num_hours_bloqueables == 0)">
        <div style="display: flex; color: #0e0087;">
          <mat-icon>info</mat-icon>
          <span>No tiene horas disponibles para bloquear</span>
        </div>
      </div>

      <div class="box-container">

        <div class="blocks-hour">
          <div class="" *ngFor="let itemHour of hours_selected_day">
            <button 
              [ngClass]="(itemHour.bloqueable == true)? ( (itemHour.selected_bloq == true)? 'btn hour-btn-seleccionada': 'btn hour-btn-habilitada' ):'btn hour-btn-bloqueada'"
              [disabled]="!itemHour.sta_id"
              (click)="clickHourBloquear(itemHour)"> 
              {{ itemHour.from }}
            </button>
          </div>
        </div>

        <hr style="border: 1px solid #B8B8B8; margin: 30px 0;">

        <div class="confirm-hour">
          <div class="row">
            <div class="col-8" style="padding-top: 12px;">
              <input id="check-selected-hour-bloq" type="checkbox" 
                [(ngModel)]="checkBloquear"
                class="chk-col-light-blue"
                (change)="checkHourBloq()"
                [disabled]="(num_hours_bloqueables == 0)">
              <label for="check-selected-hour-bloq">Seleccionar todas las horas disponibles</label>
            </div>
            <div class="col-4" style="text-align: right;">
              <button class="btn btn-primary" [disabled]="!btnBloquear" data-toggle="modal" data-target="#bloquearHorasModal" (click)="bloquearHoras()">Bloquear horas</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="habilitar-horas" *ngIf="(dia_editable == true) || _usuario.typ_id == 8">
      <h3 style="margin-bottom: 20px;">Horas disponibles para habilitar al {{selected_day.weekday}} {{selected_day.day}} de {{selected_day.month}} {{selected_day.year}}</h3>
      <div class="not-hours" *ngIf="(num_hours_habilitables == 0)">
        <div style="display: flex; color: #0e0087;">
          <mat-icon>info</mat-icon>
          <span>No tiene horas disponibles para habilitar</span>
        </div>
      </div>

      <div class="box-container" *ngIf="(num_hours_habilitables != 0) || _usuario.typ_id == 8">

        <div class="blocks-hour">
          <div class="" *ngFor="let itemHour of hours_selected_day">
            <button *ngIf="_usuario.typ_id == 1 || _usuario.typ_id == 7"
              [ngClass]="(itemHour.habilitable == true)? ( (itemHour.selected_hab == true)? 'btn hour-btn-seleccionada': 'btn hour-btn-habilitada' ):'btn hour-btn-bloqueada'"
              [disabled]="!itemHour.sta_id"
              (click)="clickHourHabilitar(itemHour)" > 
              {{ itemHour.from }}
            </button>

            <button *ngIf="_usuario.typ_id == 8"
              [ngClass]="(itemHour.habilitable == true || itemHour.spe_id== -1)? ( (itemHour.selected_hab == true)? 'btn hour-btn-seleccionada': 'btn hour-btn-habilitada' ):'btn hour-btn-bloqueada'"
              (click)="clickHourHabilitar(itemHour)" > 
              {{ itemHour.from }}
            </button>

          </div>
        </div>

        <hr style="border: 1px solid #B8B8B8; margin: 30px 0;">

        <div class="confirm-hour">
          <div class="row">
            <div class="col-8" style="padding-top: 12px;">
              <input id="check-selected-hour" type="checkbox" 
                [(ngModel)]="checkHabilitar"
                class="chk-col-light-blue"
                (change)="checkHourHab()"
                [disabled]="(num_hours_habilitables == 0)">
              <label for="check-selected-hour">Seleccionar todas las horas bloqueadas</label>

              <input id="check-selected-all-hours" type="checkbox" 
                *ngIf="_usuario.typ_id == 8"
                [(ngModel)]="checkHabilitarNoBloqueadas"
                class="chk-col-light-blue"
                (change)="checkHourHabNoBloq()"
                [disabled]="!boolCheckHourHabNoBloq()">
              <label  *ngIf="_usuario.typ_id == 8" for="check-selected-all-hours">Seleccionar todas las horas no bloqueadas</label>





            </div>
            <div class="col-4" style="text-align: right;">
              <button class="btn btn-primary" [disabled]="!btnHabilitar" data-toggle="modal" data-target="#habilitarHorasModal" (click)="habilitarHoras()">Habilitar horas</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>




<!-- Modales --> 
<!-- Confirmar bloqueo -->
<div class="modal fade" id="bloquearHorasModal" tabindex="-1" role="dialog" aria-hidden="true" >
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header mx-auto" style="border: none;">
        <h2 class="modal-title" style="text-align: center;">¿Está seguro que desea bloquear las siguientes horas?</h2>
      </div>
      <div class="modal-body" style="border: none;">
        <h3 class="modal-title" style="margin: 20px 0" >Resumen de horas por bloquear</h3>
        <div class="card">
          <h4>Doctor o profesional: {{nombre_medico}}</h4>
          <p style="font-size: 18px;"><strong>Fecha de bloqueos:</strong> {{selected_day.weekday}} {{selected_day.day}} de {{selected_day.month}} {{selected_day.year}}</p>
          <div class="blocks-hour">
            <div class="" *ngFor="let itemHour of hours_selected_bloquear">
              <button style="cursor: default;" class="btn hour-btn-seleccionada">{{ itemHour.from }}</button>
            </div>
          </div>
        </div>
        <label>Recuerda que puedes habilitar las horas que estás bloqueando.</label>

        <textarea class="form-control" rows="4" style="padding-top: 10px;" name="edit_reason" [(ngModel)]="edit_reason" id="edit_reason" placeholder="Motivo de bloqueo"></textarea>
        <div class="text-danger" *ngIf="(edit_reason.length < 4)">
          <p class="d-flex"><mat-icon>highlight_off</mat-icon> Debes ingresar un motivo de bloqueo</p>
        </div>

      </div>
      <div class="modal-footer" style="border: none; padding-left: 30px;">
        <div style="width: 50%;">
          <button type="button" class="btn btn-volver" data-dismiss="modal" style="float: left;">Cerrar</button>
        </div>
        <div style="width: 50%;">
          <button class="btn btn-confirmar" data-dismiss="modal" (click)="confirmarBloquearHoras()" style="float: right;" [disabled]="(edit_reason.length < 4)">Confirmar bloqueo</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Confirmar Habilitar-->
<div class="modal fade" id="habilitarHorasModal" tabindex="-1" role="dialog" aria-hidden="true" >
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header mx-auto" style="border: none;">
        <h2 class="modal-title" style="text-align: center;">¿Está seguro que desea habilitar las siguientes horas?</h2>
      </div>
      <div class="modal-body" style="border: none;">
        <h3 *ngIf="hours_selected_habilitar.length >0" class="modal-title" style="margin: 20px 0" >Resumen de horas por desbloquear</h3>
        <div *ngIf="hours_selected_habilitar.length >0" class="card">
          <h4>Doctor o profesional: {{nombre_medico}}</h4>
          <p style="font-size: 18px;"><strong>Fecha de habilitación:</strong> {{selected_day.weekday}} {{selected_day.day}} de {{selected_day.month}} {{selected_day.year}}</p>
          <div class="blocks-hour">
            <div class="" *ngFor="let itemHour of hours_selected_habilitar">
              <button style="cursor: default;" class="btn hour-btn-seleccionada">{{ itemHour.from }}</button>
            </div>
          </div>
        </div>
        <h3 *ngIf="hours_selected_habilitar_no_bloq.length >0" class="modal-title" style="margin: 20px 0" >Resumen de horas por habilitar</h3>
        <div *ngIf="hours_selected_habilitar_no_bloq.length >0" class="card">
          <h4>Doctor o profesional: {{nombre_medico}}</h4>
          <p style="font-size: 18px;"><strong>Fecha de habilitación:</strong> {{selected_day.weekday}} {{selected_day.day}} de {{selected_day.month}} {{selected_day.year}}</p>
          <div class="blocks-hour">
            <div class="" *ngFor="let itemHour of hours_selected_habilitar_no_bloq">
              <button style="cursor: default;" class="btn hour-btn-seleccionada">{{ itemHour.from }}</button>
            </div>
          </div>
        </div>
        <label>Recuerda que puedes bloquear las horas que estás habilitando.</label>

        <div *ngIf="(hours_selected_habilitar.length > 0)" >
          <textarea class="form-control" rows="4" style="padding-top: 10px;" name="edit_reason" [(ngModel)]="edit_reason" id="edit_reason" placeholder="Motivo de desbloqueo"></textarea>
          <div class="text-danger" *ngIf="(edit_reason.length < 4)">
            <p class="d-flex"><mat-icon>highlight_off</mat-icon> Debes ingresar un motivo de desbloqueo</p>
          </div>
        </div>

      </div>
      <div class="modal-footer" style="border: none; padding-left: 30px;">
        <div style="width: 50%;">
          <button type="button" class="btn btn-volver" data-dismiss="modal" style="float: left;">Cerrar</button>
        </div>
        <div style="width: 50%;">
          <button class="btn btn-confirmar" data-dismiss="modal" (click)="confirmarHabilitarHoras()" style="float: right;" *ngIf="(hours_selected_habilitar.length == 0)">Confirmar habilitación</button>
          <button class="btn btn-confirmar" data-dismiss="modal" (click)="confirmarHabilitarHoras()" 
            style="float: right;" *ngIf="(hours_selected_habilitar.length > 0)" [disabled]="(edit_reason.length < 4)">Confirmar desbloqueo</button>
        </div>
      </div>
    </div>
  </div>
</div>