<!-- Formulario para ingresar id consulta -->
<div>

  <input type="text" name="id_consulta" [(ngModel)]="id_consulta" placeholder="Ingresa id de la consulta">
  <br><br>
  <button class="subirbtn" type="submit" (click)="buscarHoraId()" name="submit">Buscar</button>
</div>
<br>
<div *ngIf="consulta.id_consulta" style="width: 100%;">
  <label><strong>Id Consulta:{{consulta.id_consulta}}</strong> </label><br>
  <label><strong>Datos Paciente:</strong> </label><br>
  <div class="row col-12">
    <div class="col-6">
      <label>Nombre: {{consulta.nombre_paciente}} {{consulta.apellido_paterno_paciente}}
        {{consulta.apellido_materno_paciente}}</label>
    </div>
    <div class="col-6">
      <label>Rut: {{consulta.rut_paciente}}</label>
    </div>
    <div class="col-6">
      <label>Correo: {{consulta.mail_paciente}}</label>
    </div>
    <div class="col-6">
      <label>Fecha de nacimeinto: {{consulta.fecha_nacimiento_paciente}} </label>
    </div>
    <div class="col-6">
      <label>Numero de teléfono: {{consulta.telefono_paciente}}</label>
    </div>
  </div>
  <label><strong>Datos Médico:</strong></label><br>
  <div class="row col-12">
    <div class="col-6">
      <label>Nombre: {{consulta.nombre_doctor}} {{consulta.apellido_paterno_doctor}}
        {{consulta.apellido_materno_doctor}}</label>
    </div>
    <div class="col-6">
      <label>Rut: {{consulta.rut_doctor}}</label>
    </div>
    <div class="col-6">
      <label>Correo: {{consulta.email_doctor}}</label>
    </div>
    <div class="col-6">
      <label>Numero de teléfono: {{consulta.telefono_doctor}}</label>
    </div>
  </div>
  <label><strong>Datos consulta:</strong></label><br>
  <div class="row col-12">
    <div class="col-6">
      <label>Fecha: {{consulta.fecha_consulta|date:'dd-MM-yyyy'}}</label>
    </div>
    <div class="col-6">
      <label>Hora: {{consulta.hora}}</label>
    </div>
    <div class="col-6">
      <label>Especialidad: {{consulta.spe_name}}</label>
    </div>
    <div class="col-6">
      <label *ngIf="consulta.nombre_acompanante">Acompañante: {{consulta.nombre_acompanante}}
        {{consulta.apellido_paterno_acompanante}} {{consulta.apellido_materno_acompanante}}</label>
      <label *ngIf="!consulta.nombre_acompanante">Acompañante: *No tiene acompañante*</label>
    </div>
    <div class="col-12">
      <label *ngIf="consulta.razon">Motivo consulta: {{consulta.razon}} </label>
      <label *ngIf="!consulta.razon">Motivo consulta: * No informa motivo *</label>
    </div>
    <div class="col-6">
      <button class="subirbtn" data-toggle="modal" data-target="#editAppoModal"
        (click)="editarHora(consulta)">Modificar</button>
    </div>
    <div class="col-6">
      <button class="subirbtn" data-toggle="modal" data-target="#cancelAppoModal"
        (click)="cancelarHora(consulta)">Anular</button>
    </div>
  </div>
</div>




<!-- Modal tabla de procesos historico
  <div class="modal fade" id="cancelAppoModal" tabindex="-1" role="dialog" aria-labelledby="cancelAppoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header mx-auto">
          <h4 class="modal-title titulo-modal" id="cancelAppoModalLabel">Reserva de Hora</h4>
        </div>
        <div class="modal-body">
                  ¿Confirma que desea cancelar la hora con el (la) Dr. {{data_selected.name_doc}} el {{data_selected.name_day}}
                  a las {{data_selected.name_hour}}?
        </div>
        <div class="modal-footer row">
                  <button type="button" class="btn btn-secondary col" data-dismiss="modal" >Cancelar</button>
                  <button class="btn btn-primary col" data-dismiss="modal" (click)="confirmarCancelAppo(data_selected.num_reserva, '')">Si</button>
        </div>
      </div>
    </div>
  </div> -->



<!-- Modal historial hora -->
<div class="modal fade" id="historialModal" tabindex="-1" role="dialog" aria-labelledby="historialModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title titulo-modal" id="historialModalLabel">Datos de la reserva</h5>
      </div>
      <div class="modal-body">
        <ul>
          <li>Beneficiario: <strong>{{data_selected.name_ben}}</strong></li>
          <li>Doctor: <strong>{{data_selected.name_doc}}</strong></li>
          <li>Área: <strong>{{data_selected.name_area}}</strong></li>
          <li>Día: <strong>{{data_selected.name_day}}</strong></li>
          <li>Hora: <strong>{{data_selected.name_hour}}</strong></li>
          <li>Razón: <strong>{{data_selected.name_reason}}</strong></li>
          <li>Síntomas: <strong>{{data_selected.name_symptoms}}</strong></li>
          <li>Estado consulta: <strong>{{data_selected.estado_appo}}</strong></li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>



<!-- Modal resumen hora Edit-->
<div class="modal fade" id="editAppoModal" tabindex="-1" role="dialog" aria-labelledby="editAppoModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title titulo-modal" id="editAppoModalLabel">Datos de la reserva</h5>
      </div>
      <div class="modal-body">
        <ul>
          <li>Beneficiario: <strong>{{data_selected.name_ben}}</strong></li>
          <li>Doctor: <strong>{{data_selected.name_doc}}</strong></li>
          <li>Área: <strong>{{data_selected.name_area}}</strong></li>
          <li>Día: <strong>{{data_selected.name_day}}</strong></li>
          <li>Hora: <strong>{{data_selected.name_hour}}</strong></li>
          <!-- <li>Razón: <strong>{{data_selected.name_reason}}</strong></li>
                      <li>Síntomas: <strong>{{data_selected.name_symptoms}}</strong></li> -->
        </ul>
        <div class="card-calendar" *ngIf="calendar_edit_bool">
          Seleccionar una nueva hora
          <div>
            <app-calendar class="schedule-calendar" [eventos]='eventos2' (messageEvent)='receiveMessage2($event)'>
            </app-calendar>
          </div>
          <div class="day-available2" *ngIf="day_available2">

            <div>{{ day_available_date2 }}</div>
            <br>

            <ul>
              <li *ngFor='let hor of hours_available_day2'>
                {{ hor.sch_hou_from }} - {{ hor.sch_hou_to }}
                <button class="btn btn-outline-info btn-sm" (click)="reservarEdit(hor)">Reservar hora</button>
              </li>
            </ul>
          </div>
          <br>
          <div *ngIf="hora_seleccionada">
            <h5>Confirmar modificación</h5>
            <p><mat-icon>arrow_forward</mat-icon>Nueva hora: <span style="font-weight: 600;">{{hora_new}}</span></p>
            <br>
            <select name="modify_by" id="modify_by" style="padding-top: 10px;" class="form-control camposModalAgregar"
              [(ngModel)]="modify_by">
              <option [value]="userType.type_user_id" *ngFor="let userType of listUserType">{{userType.type_user_name}}
              </option>
            </select>
            <br>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="acepta_edicion">
              <label class="form-check-label" for="exampleCheck1">Confirmar</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-warning" data-dismiss="modal" [disabled]="!acepta_edicion"
            (click)="confirmaEdicion()">Modificar Hora</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade cancelarAppoModal" id="cancelAppoModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="text-align: center;" class="tituloModal">Anulación de consulta</h2>
      </div>
      <div class="modal-body">
        <div class="card p-0 m-0 border-go-lobby border-card-modal">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
            <div class="m-0 p-0 first-line">
              <app-profile-img [img]="consulta.att_path">
              </app-profile-img>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ data_selected.name_day }}</p>
                <p class="card-text pt-3 mr-5 doc-data">{{data_selected.name_doc}} - {{ consulta.spe_name }} </p>
                <p class="card-text m-0 p-0 doc-data">Nº de reserva: {{ consulta.id_consulta}} </p>
              </div>
            </div>
          </div>
        </div>
        <br>
        <select name="cancel_by" id="cancel_by" style="padding-top: 10px;" class="form-control camposModalAgregar"
          [(ngModel)]="cancel_by">
          <option value="" disabled selected hidden>Cancelado por: </option>
          <option [value]="userType.type_user_name" *ngFor="let userType of listUserType">{{userType.type_user_name}}
        </select>
        <br><br>
        <textarea class="form-control" rows="4" style="padding-top: 10px;" name="cancel_reason"
          [(ngModel)]="cancel_reason" id="cancel_reason" placeholder="Motivo de anulación"></textarea>
        <div class="text-danger" *ngIf="(cancel_reason.length < 4)">
          <p> <mat-icon>highlight_off</mat-icon> Debes ingresar un motivo de anulación</p>
        </div>

      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-md-3" style="height: 40px;float: left;">
            <button type="button" class="btn btn-volver" style="float: left;width: 100%;" data-dismiss="modal">
              <div style="margin: auto;">
                Cerrar formulario
              </div>
            </button>
          </div>
          <div class="col-md-6 d-none d-sm-block"></div>
          <div class="col-md-3" style="height: 40px;float: right;">
            <button class="btn btn-confirmar" data-dismiss="modal" style="float: right;width: 100%;"
              (click)="confirmarCancelAppo()" [disabled]="(cancel_reason.length < 4)">
              <div style="margin: auto;">
                Anular consulta
              </div>
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>