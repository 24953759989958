import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { AgendaMedicoProximasComponent } from 'src/app/components/agenda-medico-proximas/agenda-medico-proximas.component';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-proximas-consultas-medico',
  templateUrl: './proximas-consultas-medico.component.html',
  styleUrls: ['./proximas-consultas-medico.component.css']
})
export class ProximasConsultasMedicoComponent implements OnInit {

  _title = 'Mi agenda';
  _subtitle = 'Mis próximas consultas';

  @ViewChild(AgendaMedicoProximasComponent) AGENDA_MEDICO: AgendaMedicoProximasComponent; //para llamar las funciones contenidas en ese componente

  public parametros_url: any;
  consultas_list: any = []; 
  consultas: any = []; 
  public appointments: any[] = [];
  public auxAppts: any[] = [];
  private auxApptsPos: number = 0;
  public consultaCancelar: any[] = [];

  public signupRutFocus: boolean = false;
  public formSubmitted = false;
  public formulario = false;
  pacienteName:string;
  pacienteLastName:string;

  nombre_paciente:string;
  apellido_paterno_paciente:string;
  apellido_materno_paciente:string;
  date:string;
  date2:string;
  spec:string;
  subSpec:string;

  date3: any;
  public consultaCancelarData = this.fb.group({
    img: [''],
    nombre_paciente: ['', [Validators.required]],
    apellido_paterno_paciente: ['', [Validators.required]],
    apellido_materno_paciente: ['', [Validators.required]],
    date: ['', [Validators.required]],
    date2: ['', [Validators.required]],
    spec: ['', [Validators.required]],
    subSpec: ['', [Validators.required]],
    med_con_id: ['', [Validators.required]],
    comentario:['', [Validators.required]],
    id_consulta:['', [Validators.required]],
    use_id:['', [Validators.required]],
    email_doctor:['', [Validators.required]]
  });

  boo_id:any;
  use_id:any;
  cancel_reason:any;
  data_selected: any;

  
  constructor(
    public _usuarioService: UsuarioService,
    public _scheduleService: ScheduleService,
    private fb: FormBuilder,
    private router: Router,
    private _fichaMedicaServices: FichaMedicaService
  ) {
    this.date3 = new Date().toISOString().slice(0, 10);
    this.date3 = this.date3.replaceAll('-', '/');
    //console.log(this.date3);
    
   }

  usuario: any; // Usuario logeado

  ngOnInit(): void {
    this.getListarConsultas();
    this.usuario = new EncriptacionService().getUserInfo()

    this.horasDisponibles(this.usuario.doc_id)
  }

  getListarConsultas = () => {
    this._fichaMedicaServices.getListarConsultas()
      .then((result: any) => {
        // //console.log(result);
        
        if (!(result.consultas.length > 0)) {
          //console.log('Sin proximas consultas.');
        } else {
          const consultas = result.consultas.sort((a, b) => {
            if (a.fecha_consulta > b.fecha_consulta) {
              return 1;
            } else if (a.fecha_consulta < b.fecha_consulta) {
              return -1;
            } else {
              if (a.hora > b.hora) {
                return 1;
              } else if (a.hora < b.hora) {
                return -1;
              } else {
                return 0;
              }
            }
          });
          for (let i = 0; i < consultas.length; i++) {
            const appt = consultas[i];
            const hour = appt.hora.substr(0, 2);
            const minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-','/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            const now = new Date();
            const formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              const msToAppt = this.timeToX(apptDate, now);
              const minToAppt = this.msToMin(msToAppt);
              if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
                confirmFlag = true;
              }
              let goLobbyFlag: boolean = false;
              if (minToAppt <= 60) {
                goLobbyFlag = true;
              }
              const apptData = {
                date: formatedApptDate,
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
                goLobbyFlag,
                id_consulta: appt.id_consulta,
                zoo_mee_room_id:appt.zoo_mee_room_id,
                med_con_id:appt.med_con_id,
                nombre_paciente:appt.nombre_paciente,
                apellido_paterno_paciente:appt.apellido_paterno_paciente,
                apellido_materno_paciente:appt.apellido_materno_paciente,
                email_doctor: appt.email_doctor,
                use_id: appt.use_id,
                consulta: appt,
                fecha:appt.fecha_consulta.substr(0, 10).replaceAll('-','/'),

              };
              this.appointments.push(apptData);
              this.auxAppts.push(apptData);
            }
          }

          //console.log(this.appointments)
        }
      })
      .catch(e => console.log(e));
  }


  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  
  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Hoy ' + returnDate[0];
    }
    if (minToAppt < 0) {
      return [];
    }
    return returnDate;
  }

  goLobby = (item) => {
    var url = "videoconsulta/"+item.id_consulta;
    this.router.navigate([url]);
  }

  unirseReunionPaciente(item)
  {
    //console.log(item);
    var resta_minutos = this.obtenerResta(item.hora);
    var fecha_sistema = new Date();
    //console.log("Fecha actual: " + fecha_sistema);

    const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');

    //console.log(fecha);

    //console.log(item.fecha_consulta.substring(0,10));

    if (item.fecha_consulta.substring(0,10)!=fecha.toString()) { 

      Swal.fire({
        icon: 'info',
        title: 'Consulta en más días.',
        text: 'Aún faltan días para acceder a la consulta...',
        timer: 5000
      })
    }else if (resta_minutos > 60) {

      Swal.fire({
        icon: 'info',
        title: 'Fuera de horario.',
        text: 'Aún falta para acceder a la consulta...',
        timer: 5000
      })
      
    } else if (resta_minutos < -60) {
      Swal.fire({
        icon: 'info',
        title: 'Fuera de horario.',
        text: 'El tiempo para acceder a la consulta ya ha terminado...',
        timer: 5000
      })

    } else
    {
      var url = "salaespera/"+item.zoo_mee_room_id+"/"+item.id_consulta+"/"+item.med_con_id;
      this.router.navigate([url]);

    }


   }

   unirseReunionDoctor(item)
   {
     //console.log(item);
      var url = "consulta/"+item.id_consulta;
      this.router.navigate([url]);
       
   }
   unirseReunion()
   {
    Swal.fire({
      icon: 'info',
      title: 'Consulta en más días.',
      text: 'Aún faltan días para acceder a la consulta...',
      timer: 5000
    })
 
  }
  
  obtenerResta(hora)
  {

    var fecha = new Date(Date.now());
    var minutos = fecha.getHours() * 60 + fecha.getMinutes();
    var minutos_consulta = parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
    var diferencia = minutos_consulta - minutos;
    //console.log("Diferencia "+diferencia);
    return diferencia;
  }
  
  
  infoCancelaConsulta(i){
    console.log(this.appointments[i]);
    
    this.consultaCancelarData.controls['comentario'].setValue('');
    this.consultaCancelarData.controls['nombre_paciente'].setValue(this.appointments[i].nombre_paciente);
    this.consultaCancelarData.controls['apellido_paterno_paciente'].setValue(this.appointments[i].apellido_paterno_paciente);
    this.consultaCancelarData.controls['apellido_materno_paciente'].setValue(this.appointments[i].apellido_materno_paciente);
    this.consultaCancelarData.controls['date'].setValue(this.appointments[i].date[0]);
    this.consultaCancelarData.controls['date2'].setValue(this.appointments[i].date[1]);
    this.consultaCancelarData.controls['spec'].setValue(this.appointments[i].spec);
    this.consultaCancelarData.controls['subSpec'].setValue(this.appointments[i].subSpec);
    this.consultaCancelarData.controls['med_con_id'].setValue(this.appointments[i].med_con_id);
    this.consultaCancelarData.controls['id_consulta'].setValue(this.appointments[i].id_consulta);
    this.consultaCancelarData.controls['use_id'].setValue(this.appointments[i].use_id);
    this.consultaCancelarData.controls['email_doctor'].setValue(this.appointments[i].email_doctor);
    this.pacienteName = this.appointments[i].nombre_paciente.charAt(0).toUpperCase();
    this.pacienteLastName = this.appointments[i].apellido_paterno_paciente.charAt(0).toUpperCase();
    this.nombre_paciente= this.appointments[i].nombre_paciente;
    this.apellido_paterno_paciente= this.appointments[i].apellido_paterno_paciente;
    this.apellido_materno_paciente= this.appointments[i].apellido_materno_paciente;
    this.date= this.appointments[i].date[0];
    this.date2= this.appointments[i].date[1];
    this.spec= this.appointments[i].spec;
    this.subSpec= this.appointments[i].subSpec;
    this.boo_id= this.appointments[i].id_consulta;
    this.use_id= this.usuario
    this.data_selected={email_doctor: this.appointments[i].email_doctor};
  }

  campoNoValido( campo:string ): boolean {
    if ( this.consultaCancelarData.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }

  eliminaConsulta(){
    console.log(this.consultaCancelarData.value);
    this.formSubmitted = true;
    if (this.consultaCancelarData.invalid) {
      //console.log('formulario invalido');
      return;
    }else{
      //console.log("confimar primer paso");
    }
    
  }
  
  confirmaEliminar(){
    //console.log(this.consultaCancelarData.value);
    if (this.consultaCancelarData.invalid) {
      //console.log('formulario invalido');
      return;
    }else{
      this.formulario=true;
      this._scheduleService.cancelAppointment(this.boo_id, this.use_id._id ,this.consultaCancelarData.value.comentario, this.data_selected,null)
      .then((result: any) => {
        //console.log("eliminado Correctamente");
        
      })
      .catch(
        error =>{
          console.log(error);
          
        }
      );

    }
    
  }

  reload(){
    window.location.reload();
  }

  irdetallesPacientes(appt){
    var url = "proximasConsultas/paciente/"+appt.med_con_id;
    this.router.navigate([url]);
  }


  //al clickear 1 hora desde el componnete
  clickHourAgendaMedico($event){ // lleva al detalle de la consulta
    if($event.hour.available == false){
      for(let appt of this.auxAppts){
        if($event.hour.sch_hou_id == appt.consulta.sch_hou_id){
          this.irdetallesPacientes(appt)
          break;
        }
      }
    }
  }

  dia_seleccionado: boolean = false;      //si se selecciona un dia
  fechaSelected: any = {};
  appointmentsDay: any;                 //consultas del dia seleccionado

  //al clickear 1 dia desde el componente
  clickDayAgendaMedico($event) { // lleva al detalle de la consulta
    //console.log($event,'event-clickDayAgendaMedico');
    
    this.dia_seleccionado = false;
    this.appointmentsDay = [];
    this.fechaSelected = $event;

    for (let appo of this.appointments){
      if( appo.fecha.replaceAll('/','') == this.fechaSelected.dateInt.toString()){
        this.dia_seleccionado = true;
        this.appointmentsDay.push(appo)
      }
    }
  }


  horasDisponibles(doc_id){
    this._scheduleService.getScheduleHoursDoc(doc_id, null, true)
    .then( (data: any) => {   
      let hoy = new Date();
      let hoy_int = parseInt(`${hoy.getFullYear()}${((hoy.getMonth() +101).toString()).substring(1, 3)}${((hoy.getDate() + 100).toString()).substring(1, 3)}`);
      let dataX = [];
      for (let diaX of data) {
        let dia = new Date(diaX.sch_date.substr(0, 10).replaceAll('-','/'));
        let dia_int = parseInt(`${dia.getFullYear()}${((dia.getMonth() +101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`);
        if(dia_int >= hoy_int){
          dataX.push(diaX)
        }
      }
      this.AGENDA_MEDICO.sendToAgendaMedico( dataX, null); 
    })
  }


}
