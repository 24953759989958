<div *ngIf="number_page==-1">
    <div class="card">

        <br><br><br>
        <i class="far fa-laugh" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;"></i>
        <br>
        <h2 class="texto-titulo">Consulta finalizada con éxito</h2>

        <br>
        <div>

            <h3 style="margin: 10px;">Recibirás un correo electrónico y un resumen de tu atención, <strong>apenas los
                    resultados y documentos asociados estén listos.</strong>También, recuerda que tras tu consulta,
                tendrás 24 horas para hablar con tu médico en la sección “Chat con tu médico”.</h3>

            <h3 style="margin: 10px;">Gracias por ser parte de AlmaMedis.</h3>
        </div>
        <br>

        <div class="card-deck">

            <div style="width: auto;">
                <button routerLink="/dashboard" routerLinkActive="active"
                    class="btn btn-outline-primary btn-lg botonModalInicio" style="width: 100%;" (click)='inicio()'> Ir
                    al inicio </button>
            </div>
            <div style="margin-left: 20px; margin-right: 20px;">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar" (click)='siguiente()'>Califica tu
                    atención</button>
            </div>

        </div>


        <br><br>
    </div>
</div>


<div *ngIf="number_page!=-1">
    <div class="card">
        <table>
            <tr>
                <td>
                    <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 0; else first">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #first>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 1; else second">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #second>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 2; else third">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #third>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="number_page > 3; else fourth">
                        <mat-icon style="font-size: 36px; margin:5px; color:#171994;">horizontal_rule</mat-icon>
                    </ng-container>
                    <ng-template #fourth>
                        <mat-icon style="font-size: 36px; margin:5px; color:#B8B8B8;">horizontal_rule</mat-icon>
                    </ng-template>
                </td>
            </tr>
        </table>
        
        <!-- <div></div>
                <div><i style="font-size: 50px;margin:5px;" class="fas fa-horizontal-rule"></i></div>
                <div><i style="font-size: 50px;margin:5px;" class="fas fa-horizontal-rule"></i></div>
                <div><i style="font-size: 50px;margin:5px;" class="fas fa-horizontal-rule"></i></div>
            -->

        <!-- <br> -->
        <div *ngIf="number_page>=0 && number_page<=4">
            <p class="quantity-text">Califica tu atención</p>
        </div>
        <br>
        <div *ngIf="number_page==0">
            <h2 class="texto-pregunta">¿Cómo evalúas la atención del médico?</h2>
        </div>
        <div *ngIf="number_page==1">
            <h2 class="texto-pregunta">¿Cómo evalúas la calidad de la videollamada?</h2>
        </div>
        <div *ngIf="number_page==2">
            <h2 class="texto-pregunta">¿Qué tan fácil fue utilizar Almamedis?</h2>
        </div>
        <div *ngIf="number_page==3">
            <h2 class="texto-pregunta">En una escala del 1 al 10</h2>
            <h2 class="texto-pregunta">¿Con qué probabilidad recomendaría AlmaMedis a un familiar o amigo?</h2>
        </div>
        <div *ngIf="number_page==4">
            <h2 class="texto-pregunta">¿Deseas agregar algo más? (opcional)</h2>
        </div>
        <br>
        <!-- <p>{{calificaciones[number_page]}}</p> -->
        <div *ngIf="number_page < 3">
            <div class="ec-stars-wrapper pointer">
                <a style="font-size: 43px;" [ngClass]="(calificaciones[number_page] >= 1) ? 'star a-star':'star'"
                    (click)='calificacion(1)' data-value="1" title="Votar con 1 estrellas">&#9733;</a>
                <a style="font-size: 43px;" [ngClass]="(calificaciones[number_page] >= 2) ? 'star a-star':'star'"
                    (click)='calificacion(2)' data-value="2" title="Votar con 2 estrellas">&#9733;</a>
                <a style="font-size: 43px;" [ngClass]="(calificaciones[number_page] >= 3) ? 'star a-star':'star'"
                    (click)='calificacion(3)' data-value="3" title="Votar con 3 estrellas">&#9733;</a>
                <a style="font-size: 43px;" [ngClass]="(calificaciones[number_page] >= 4) ? 'star a-star':'star'"
                    (click)='calificacion(4)' data-value="4" title="Votar con 4 estrellas">&#9733;</a>
                <a style="font-size: 43px;" [ngClass]="(calificaciones[number_page] >= 5) ? 'star a-star':'star'"
                    (click)='calificacion(5)' data-value="5" title="Votar con 5 estrellas">&#9733;</a>
            </div>
        </div>
        <div *ngIf="number_page==3" class="row" style="width: 90%;place-content: center;">
            <div style="width: 90%;">
                <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
                    <button
                        [ngClass]="(calificaciones[number_page] == i) ? 'button-quantity button-color-press':'button-quantity button-color'"
                        (click)="calificacion(i)">
                        {{i}}
                    </button>
                </ng-container>
            </div>
            <div class="col-2 d-none d-sm-flex">
                <p class="quantity-text">Muy poco probable</p>
            </div>
            <div class="col-8 d-none d-sm-flex"></div>
            <div class="col-2 d-none d-sm-flex">
                <p class="quantity-text">Muy probable</p>
            </div>
        </div>
        <div class="col-md-6" style="width: 90%;" *ngIf="number_page > 3">
            <textarea class="form-control" [(ngModel)]="comentario"
                style="width: 100%; ;background: #FFFFFF;border: 1px solid #A5A5A5;box-sizing: border-box;border-radius: 4px;"
                placeholder="Deja tus comentarios y/o sugerencias." rows="4"></textarea>

        </div>
        <br>

        <div class="card-deck row">
            <div style="width: auto;">
                <button (click)='volver()' class="btn btn-outline-primary btn-lg botonModalInicio" style="width: 100%;">
                    Volver </button>
            </div>
            <div *ngIf="number_page != 4" style="margin-left: 20px; margin-right: 20px;">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar"
                    (click)='siguiente()'>Siguiente</button>
            </div>
            <div *ngIf="number_page == 4" style="margin-left: 20px; margin-right: 20px;">
                <button class="btn btn-outline-primary btn-lg botonModalAgregar" (click)='sendSurvey()'>Enviar</button>
            </div>
        </div>
        <br><br>
    </div>
</div>






<br><br>
<div class="row">
    <div class="col-md-6">

        <div class="card">

            <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                <h3 class="card-texto">¿Deseas informar un problema? Recuerda que puedes comunicarte directamente con
                    nuestra Mesa de Ayuda.</h3>

                <p *ngIf="consulta.telefono_plan" style="margin: 20px;" class="texto-numero">{{consulta.telefono_plan}}
                </p>
            </div>
        </div>

    </div>
</div>



<div class="modal fade hide" id="LoadingModal" tabindex="-1" role="dialog" aria-labelledby="LoadingModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 py-4">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/clock-transicion.svg" alt="Responsive image">
                    <p class="m-0 px-0 px-sm-5">Cargando</p>
                </div>
            </div>
        </div>
    </div>
</div>