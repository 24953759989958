

<div class="main-container d-flex">
  <div class="sidenav" id="side_nav">
    <div class="header-box">
      <h1 class="fs-4"><span class="text-dark rounded shadow px-2 me-2">Menu</span></h1>
    </div>

  </div>
   
</div>