<div class="container d-none d-sm-block" style="width: 100%; align-items: center; text-align: center;">
    <br>

    <header>
        <img src="assets/images/logo-almamedis.png" alt="almaMedis logo" style="height: 83px;">
        <p *ngIf="pagina >= 2" class="letra-sub">En almaMedis podrás</p>
        <p *ngIf="pagina < 2" class="letra-sub">¡Bienvenido!</p>
        <p class="letra-tit">{{titulo[pagina]}}</p>

    </header>
    <main class="body">

        <!-- Paso 1 - Formulario de registro -->
        <div *ngIf="pagina === 0" style="padding: 0 10% 20%;">
            <div class="container-fluid m-0 p-0 ">

                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <!-- <div class="row" style="margin: 0">
                        <div class="col">
                            <p class="letra-sub">¡Bienvenido!</p>
                            <p class="letra-tit">{{titulo[pagina]}}</p>
    
                        </div>
                    </div> -->
                    <div class="row" >
                        <!-- Columna Izquierda -->
                        <div class="col-6">

                            <div class="form-group">
                                <label for="rut"></label>
                                <input class="form-control" type="text" id="rut" formControlName="rut"
                                    placeholder="Ingresa tu rut" disabled>
                            </div>
                            <div class="error-message">
                            </div>

                            <div class="form-group">
                                <label for="nombre"></label>
                                <input class="form-control" type="text" id="nombre" formControlName="nombre"
                                    placeholder="Ingresa tu nombre">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('nombre')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un nombre valido.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="apellido_paterno"></label>
                                <input class="form-control" type="text" id="apellido_paterno"
                                    formControlName="apellido_paterno" placeholder="Ingresa tu primer apellido">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('apellido_paterno')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un apellido paterno valido.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="apellido_materno"></label>
                                <input class="form-control" type="text" id="apellido_materno"
                                    formControlName="apellido_materno" placeholder="Ingresa tu segundo apellido">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('apellido_materno')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un apellido materno valido.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="genero"></label>
                                <select class="form-control" id="genero" formControlName="genero">
                                    <option value="" disabled selected>Selecciona tu género</option>
                                    <option *ngFor="let genero of generos" [value]="genero">{{ genero }}</option>
                                </select>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('genero')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Selecciona un género.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="fecha_nacimiento_chile"></label>
                                <input id="fecha_nacimiento_chile" class="form-control" type="date"
                                    formControlName="fecha_nacimiento_chile">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('fecha_nacimiento_chile')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa tu fecha de nacimiento.
                                </p>
                            </div>


                        </div>

                        <!-- Columna Derecha -->
                        <div class="col-6">

                            <div class="form-group">
                                <label for="email"></label>
                                <input class="form-control" type="text" id="email" formControlName="email"
                                    placeholder="Ingresa tu email">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('email')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un email valido.
                                </p>
                            </div>


                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-btn">
                                        <button type="button" class="btn btn-secondary dropdown-toggle"
                                            (click)="toggleDropdown()" aria-haspopup="true" aria-expanded="false"
                                            style="display: flex; align-items: center;">
                                            <span [class]="'fi ' + selectedAreaCode.cou_flag"
                                                style="margin: 0 5px;"></span>
                                            <p style="margin: 0 !important;"> (+{{ selectedAreaCode.cou_phone_code }})
                                            </p>
                                            <mat-icon>expand_more</mat-icon>
                                        </button>
                                        <div class="dropdown-menu" [ngClass]="{'show': dropdownOpen}"
                                            (clickOutside)="closeDropdown()">
                                            <button class="dropdown-item" *ngFor="let areaCode of areaCodes"
                                                (click)="selectAreaCode(areaCode); $event.preventDefault()">
                                                <span [class]="'fi ' + areaCode.cou_flag"></span> (+{{
                                                areaCode.cou_phone_code }})
                                            </button>
                                        </div>

                                    </div>
                                    <input class="form-control" type="tel" formControlName="telefono" id="telefono"
                                        placeholder="Teléfono">
                                </div>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('telefono')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa entre {{selectedAreaCode?.cou_min_phone}} y
                                    {{selectedAreaCode?.cou_max_phone}} dígitos
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="pais"></label>
                                <select class="form-control" id="pais" formControlName="pais">
                                    <option value="" disabled selected>Selecciona tu País</option>
                                    <option *ngFor="let pais of paises" [value]="pais.cou_id">{{ pais.cou_name }}
                                </select>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('pais')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Selecciona un País.
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="region"></label>
                                        <select class="form-control" id="region" formControlName="region">
                                            <option value="" disabled selected>Selecciona tu región</option>
                                            <option *ngFor="let region of regiones" [value]="region.reg_id">{{
                                                region.reg_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="error-message">
                                        <p *ngIf="campoNoValido('region')" class="text-danger mb-0 pb-0 mt-1"
                                            style="display: flex; align-items: center;">
                                            <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                            Selecciona un Región.
                                        </p>
                                    </div>

                                </div>
                                <div class="col-6">

                                    <div class="form-group">
                                        <label for="comuna"></label>
                                        <select class="form-control" id="comuna" formControlName="comuna">
                                            <option value="" disabled selected>Selecciona tu comuna</option>
                                            <option *ngFor="let comuna of comunas" [value]="comuna.com_id">{{
                                                comuna.com_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="error-message">
                                        <p *ngIf="campoNoValido('comuna')" class="text-danger mb-0 pb-0 mt-1"
                                            style="display: flex; align-items: center;">
                                            <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                            Selecciona una comuna.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="direccion"></label>
                                <input class="form-control" type="text" id="direccion" formControlName="direccion"
                                    placeholder="Ingresa tu dirección">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('direccion')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa una dirección.
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="numero"></label>
                                        <input class="form-control" type="text" id="numero" formControlName="numero"
                                            placeholder="Ingresa tu numero">
                                    </div>
                                    <div class="error-message">
                                        <p *ngIf="campoNoValido('numero')" class="text-danger mb-0 pb-0 mt-1"
                                            style="display: flex; align-items: center;">
                                            <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                            Ingresa una numeración.
                                        </p>
                                    </div>

                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="rest"></label>
                                        <input class="form-control" type="text" id="rest" formControlName="rest"
                                            placeholder="Departamento / Oficina">
                                    </div>
                                    <!-- <p *ngIf="campoNoValido('rest')" class="text-danger mb-0 pb-0 mt-1" style="display: flex; align-items: center;">
                                        <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                        Ingresa una numeración.
                                    </p> -->

                                </div>
                            </div>

                        </div>

                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="pagina === 1" style="padding: 0 10% 20%;">
            <div class="container-fluid m-0 p-0 ">
                <form [formGroup]="cambiaPass" (ngSubmit)="cambiaPassword()">
                    <!-- <div class="row">
                            <div class="col">
                                <p class="letra-tit" style="margin: 50px 0 20px;">{{titulo[pagina]}}</p>
        
                            </div>
                        </div> -->
                    <div class="row cambia-pass" style="padding: 0 20%;">
                        <div class="col">

                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass, 'signup-input-wrapper-addon-gray': !signupPass}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType1 ? 'text' : 'password'"
                                            formControlName="contrasena_actual" id="contrasena_actual"
                                            name="contrasena_actual" placeholder="Contraseña actual"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Contraseña actual';" (focus)="signupPass=true;"
                                            (blur)="signupPass=false;" (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center"
                                    style="display: flex; justify-content: center;">
                                    <span class="calendar" (click)="toggleFieldTextType1()">
                                        <mat-icon>{{ fieldTextType1 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>
                            <br>
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass1Focus, 'signup-input-wrapper-addon-gray': !signupPass1Focus}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType2 ? 'text' : 'password'"
                                            formControlName="contrasena_nueva" id="contrasena_nueva"
                                            name="contrasena_nueva" placeholder="Contraseña nueva*"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Contraseña nueva*';"
                                            (focus)="signupPass1Focus=true;" (blur)="signupPass1Focus=false;"
                                            (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center"
                                    style="display: flex; justify-content: center;">
                                    <span class="calendar" (click)="toggleFieldTextType2()">
                                        <mat-icon>{{ fieldTextType2 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>
                            <br>
                            <div class="password-desc ">
                                <p class="{{ passLength === 0 && !formSubmitted ? 
                                        'default' : 
                                        passMinLength ? 
                                        'ok' : 
                                        'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passMinLength ? 'check_circle' : 'cancel'
                                        }}</mat-icon>
                                    Debe contener al menos 8 caracteres
                                </p>
                                <p class="{{ passLength === 0 && !formSubmitted ?
                                        'default' :
                                        passCapital ?
                                        'ok' :
                                        'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passCapital ? 'check_circle' : 'cancel' }}</mat-icon>

                                    Debe contener al menos 1 mayúscula
                                </p>
                                <p class="{{ passLength === 0 && !formSubmitted ?
                                        'default' :
                                        passLetter ?
                                        'ok' :
                                        'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passLetter ? 'check_circle' : 'cancel' }}</mat-icon>
                                    Debe contener al menos 1 minúscula
                                </p>
                                <p class="{{ passLength === 0 && !formSubmitted ?
                                        'default' :
                                        passNumber ?
                                        'ok' :
                                        'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ?
                                        'radio_button_unchecked' : passNumber ? 'check_circle' : 'cancel' }}</mat-icon>
                                    Debe contener al menos 1 numero
                                </p>
                            </div>
                            <br>
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass2Focus, 'signup-input-wrapper-addon-gray': !signupPass1Focus}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType3 ? 'text' : 'password'"
                                            formControlName="contrasena_nueva_2" id="contrasena_nueva_2"
                                            name="contrasena_nueva_2" placeholder="Contraseña nueva*"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Repetir contraseña nueva*';"
                                            (focus)="signupPass2Focus=true;" (blur)="signupPass2Focus=false;"
                                            (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center"
                                    style="display: flex; justify-content: center;">
                                    <span class="calendar" (click)="toggleFieldTextType3()">
                                        <mat-icon>{{ fieldTextType3 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>
                            <br>

                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Páginas con imágenes -->
        <div *ngIf="pagina === 2">
            <img src="assets/images/onboarding/paciente/desktop/pant0.png" style="height: 45vh;">
        </div>
        <div *ngIf="pagina === 3">
            <img src="assets/images/onboarding/paciente/desktop/pant1.png" style="height: 45vh;">
        </div>
        <div *ngIf="pagina === 4">
            <img src="assets/images/onboarding/paciente/desktop/pant2.png" style="height: 45vh;">
        </div>
        <div *ngIf="pagina === 5">
            <img src="assets/images/onboarding/paciente/desktop/pant3.png" style="height: 45vh;">
        </div>
        <div *ngIf="pagina === 6">
            <img src="assets/images/onboarding/paciente/desktop/pant4.png" style="height: 45vh;">
        </div>
    </main>



    <footer class="footer">

        <!-- Navegación entre páginas -->
        <div>
            <mat-icon *ngIf="pagina == 0" class="boton-circulo" [ngStyle]="{color: estilo[0]}">circle</mat-icon>
            <mat-icon *ngIf="pagina == 1" class="boton-circulo" [ngStyle]="{color: estilo[1]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[2]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[3]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[4]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[5]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[6]}">circle</mat-icon>
        </div>

        <!-- Subtítulos de las páginas -->
        <p class="letra-subtit">{{subtitulo[pagina]}}</p>

        <!-- Botones de navegación -->
        <div class="d-flex align-items-center justify-content-center">
            <div *ngIf="pagina < 6" style="width: 55%; height: 60px; align-items: center; text-align: center;">
                <button *ngIf="pagina <= 2" (click)="salirBoton()" class="boton-omitir">Salir</button>
                <button *ngIf="pagina > 2" (click)="volverBoton()" class="boton-omitir">Volver</button>
                <button *ngIf="pagina >= 2" (click)="siguienteBoton()" class="boton-siguiente" style="float: right;">Siguiente</button>
                <button 
                *ngIf="pagina == 0" 
                    class="boton-siguiente" 
                    [ngClass]="{'boton-siguiente-disabled': registerForm.invalid}" 
                    [disabled]="registerForm.invalid" 
                    (click)="siguienteBoton()" 
                    style="float: right;">
                    Siguiente
                </button>
                <button 
                *ngIf="pagina == 1" 
                    class="boton-siguiente" 
                    [ngClass]="{'boton-siguiente-disabled': cambiaPass.invalid}" 
                    [disabled]="cambiaPass.invalid" 
                    (click)="siguienteBoton()" 
                    style="float: right;">
                    Siguiente
                </button>
            </div>
            <div *ngIf="pagina > 5" style="width: 55%; height: 60px; align-items: center; text-align: center;">
                <button (click)="empezemosBoton()" class="boton-siguiente">¡Empecemos!</button>
            </div>
        </div>
        
    </footer>
</div>



<div class="container d-sm-none" style="width: 100%; align-items: center;text-align: center;     height: 100%;">
    <br>
    <header>
        <img src="assets/images/logo-almamedis.png" alt="alamedis logo" style="width:70%;">
        <p *ngIf="pagina>=2" class="letra-sub" style="font-size: 28px;">En AlmaMedis podrás</p>
        <p class="letra-tit" style="font-size: 24px;">{{titulo2[pagina]}}</p>
        <p *ngIf="pagina<2" class="letra-sub" style="font-size: 24px;">{{titulo[pagina]}}</p>

    </header>
    <main class="body" style="width: 100%;">
        <div>

            <img *ngIf="pagina==2" src="assets/images/onboarding/paciente/mobile/pantallazo0.png"
                style="height: 70vh; margin-bottom: -33%;">
            <img *ngIf="pagina==3" src="assets/images/onboarding/paciente/mobile/pantallazo1.png"
                style="height: 70vh; margin-bottom: -33%;">
            <img *ngIf="pagina>3" src="assets/images/onboarding/paciente/mobile/pantallazo2.png"
                style="height: 70vh; margin-bottom: -33%;">
        </div>

        <div *ngIf="pagina === 0" style="padding: 0 0 40%;">
            <div class="container-fluid m-0 p-0 ">

                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <!-- <div class="row" style="margin: 0">
                        <div class="col">
                            <p class="letra-sub">¡Bienvenido!</p>
                            <p class="letra-tit">{{titulo[pagina]}}</p>
    
                        </div>
                    </div> -->
                    <div class="row" style="padding: 0 5%;">
                        <!-- Columna Izquierda -->
                        <div class="col-12">

                            <div class="form-group">
                                <label for="rut"></label>
                                <input class="form-control" type="text" id="rut" formControlName="rut"
                                    placeholder="Ingresa tu rut" disabled>
                            </div>
                            <div class="error-message">
                            </div>

                            <div class="form-group">
                                <label for="nombre"></label>
                                <input class="form-control" type="text" id="nombre" formControlName="nombre"
                                    placeholder="Ingresa tu nombre">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('nombre')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un nombre valido.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="apellido_paterno"></label>
                                <input class="form-control" type="text" id="apellido_paterno"
                                    formControlName="apellido_paterno" placeholder="Ingresa tu primer apellido">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('apellido_paterno')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un apellido paterno valido.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="apellido_materno"></label>
                                <input class="form-control" type="text" id="apellido_materno"
                                    formControlName="apellido_materno" placeholder="Ingresa tu segundo apellido">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('apellido_materno')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un apellido materno valido.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="genero"></label>
                                <select class="form-control" id="genero" formControlName="genero">
                                    <option value="" disabled selected>Selecciona tu género</option>
                                    <option value="masculino">Masculino</option>
                                    <option value="femenino">Femenino</option>
                                    <option value="otro">Otro</option>
                                </select>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('genero')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Selecciona un género.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="fecha_nacimiento_chile"></label>
                                <input id="fecha_nacimiento_chile" class="form-control" type="date"
                                    formControlName="fecha_nacimiento_chile">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('fecha_nacimiento_chile')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa tu fecha de nacimiento.
                                </p>
                            </div>



                            <!-- Columna Derecha -->

                            <div class="form-group">
                                <label for="email"></label>
                                <input class="form-control" type="text" id="email" formControlName="email"
                                    placeholder="Ingresa tu email">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('email')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa un email valido.
                                </p>
                            </div>


                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-btn">
                                        <button type="button" class="btn btn-secondary dropdown-toggle"
                                            (click)="toggleDropdown()" aria-haspopup="true" aria-expanded="false"
                                            style="display: flex; align-items: center;">
                                            <span [class]="'fi ' + selectedAreaCode.cou_flag"
                                                style="margin: 0 5px;"></span>
                                            <p style="margin: 0 !important;"> (+{{ selectedAreaCode.cou_phone_code }})
                                            </p>
                                            <mat-icon>expand_more</mat-icon>
                                        </button>
                                        <div class="dropdown-menu" [ngClass]="{'show': dropdownOpen}"
                                            (clickOutside)="closeDropdown()">
                                            <button class="dropdown-item" *ngFor="let areaCode of areaCodes"
                                                (click)="selectAreaCode(areaCode); $event.preventDefault()">
                                                <span [class]="'fi ' + areaCode.cou_flag"></span> (+{{
                                                areaCode.cou_phone_code }})
                                            </button>
                                        </div>

                                    </div>
                                    <input class="form-control" type="tel" formControlName="telefono" id="telefono"
                                        placeholder="Teléfono">
                                </div>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('telefono')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa entre {{selectedAreaCode?.cou_min_phone}} y
                                    {{selectedAreaCode?.cou_max_phone}} dígitos
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="pais"></label>
                                <select class="form-control" id="pais" formControlName="pais">
                                    <option value="" disabled selected>Selecciona tu País</option>
                                    <option *ngFor="let pais of paises" [value]="pais.cou_id">{{ pais.cou_name }}
                                </select>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('pais')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Selecciona un País.
                                </p>

                            </div>
                            <div class="form-group">
                                <label for="region"></label>
                                <select class="form-control" id="region" formControlName="region">
                                    <option value="" disabled selected>Selecciona tu región</option>
                                    <option *ngFor="let region of regiones" [value]="region.reg_id">{{
                                        region.reg_name}}
                                    </option>
                                </select>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('region')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Selecciona un Región.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="comuna"></label>
                                <select class="form-control" id="comuna" formControlName="comuna">
                                    <option value="" disabled selected>Selecciona tu comuna</option>
                                    <option *ngFor="let comuna of comunas" [value]="comuna.com_id">{{
                                        comuna.com_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('comuna')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Selecciona una comuna.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="direccion"></label>
                                <input class="form-control" type="text" id="direccion" formControlName="direccion"
                                    placeholder="Ingresa tu dirección">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('direccion')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa una dirección.
                                </p>
                            </div>

                            <div class="form-group">
                                <label for="numero"></label>
                                <input class="form-control" type="text" id="numero" formControlName="numero"
                                    placeholder="Ingresa tu numeración">
                            </div>
                            <div class="error-message">
                                <p *ngIf="campoNoValido('numero')" class="text-danger mb-0 pb-0 mt-1"
                                    style="display: flex; align-items: center;">
                                    <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                    Ingresa una numeración.
                                </p>
                            </div>


                            <div class="form-group">
                                <label for="rest"></label>
                                <input class="form-control" type="text" id="rest" formControlName="rest"
                                placeholder="Departamento / Oficina">
                            </div>
                            <!-- <p *ngIf="campoNoValido('rest')" class="text-danger mb-0 pb-0 mt-1" style="display: flex; align-items: center;">
                                        <mat-icon style="font-size: 15px;">cancel</mat-icon>
                                        Ingresa una numeración.
                                    </p> -->



                        </div>

                    </div>
                </form>
            </div>
        </div>

        

        <div *ngIf="pagina === 1">
            <div class="container-fluid">
                <form [formGroup]="cambiaPass" (ngSubmit)="cambiaPassword()">
                    <!-- Título -->
                    <!-- <div class="row">
                        <div class="col-12 text-center">
                            <p class="letra-tit my-4">{{titulo[pagina]}}</p>
                        </div>
                    </div> -->

                    <!-- Campos de Contraseña -->
                    <div class="row justify-content-center cambia-pass px-lg-5 px-md-3 px-2">
                        <div class="col-12 col-md-8 col-lg-6">

                            <!-- Contraseña Actual -->
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass, 'signup-input-wrapper-addon-gray': !signupPass}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType1 ? 'text' : 'password'"
                                            formControlName="contrasena_actual" id="contrasena_actual"
                                            name="contrasena_actual" placeholder="Contraseña actual"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Contraseña actual';" (focus)="signupPass=true;"
                                            (blur)="signupPass=false;" (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center"
                                    style="display: flex; justify-content: center;">
                                    <span class="calendar" (click)="toggleFieldTextType1()">
                                        <mat-icon>{{ fieldTextType1 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>

                            <br>

                            <!-- Nueva Contraseña -->
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass1Focus, 'signup-input-wrapper-addon-gray': !signupPass1Focus}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType2 ? 'text' : 'password'"
                                            formControlName="contrasena_nueva" id="contrasena_nueva"
                                            name="contrasena_nueva" placeholder="Contraseña nueva*"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Contraseña nueva*';"
                                            (focus)="signupPass1Focus=true;" (blur)="signupPass1Focus=false;"
                                            (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center"
                                    style="display: flex; justify-content: center;">
                                    <span class="calendar" (click)="toggleFieldTextType2()">
                                        <mat-icon>{{ fieldTextType2 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>

                            <br>

                            <!-- Requisitos de Contraseña -->
                            <div class="password-desc">
                                <p class="{{ passMinLength ? 'ok' : 'nok' }}"
                                    style="display: flex; align-items: center;">
                                    <mat-icon>{{ passMinLength ? 'check_circle' : 'cancel' }}</mat-icon> Debe contener
                                    al menos 8 caracteres
                                </p>
                                <p class="{{ passCapital ? 'ok' : 'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon>{{ passCapital ? 'check_circle' : 'cancel' }}</mat-icon> Debe contener al
                                    menos 1 mayúscula
                                </p>
                                <p class="{{ passLetter ? 'ok' : 'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon>{{ passLetter ? 'check_circle' : 'cancel' }}</mat-icon> Debe contener al
                                    menos 1 minúscula
                                </p>
                                <p class="{{ passNumber ? 'ok' : 'nok' }}" style="display: flex; align-items: center;">
                                    <mat-icon>{{ passNumber ? 'check_circle' : 'cancel' }}</mat-icon> Debe contener al
                                    menos 1 número
                                </p>
                            </div>
                            <br>
                            <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
                                [ngClass]="{'signup-input-wrapper-addon-blue': signupPass1Focus, 'signup-input-wrapper-addon-gray': !signupPass1Focus}">
                                <div class="col-10 col-md-11 m-0 signup-custom-input">
                                    <div class="input-group m-0 p-0">
                                        <input class="form-control" [type]="fieldTextType3 ? 'text' : 'password'"
                                            formControlName="contrasena_nueva_2" id="contrasena_nueva_2"
                                            name="contrasena_nueva" placeholder="Repetir contraseña nueva*"
                                            onfocus="this.placeholder = '';"
                                            onblur="this.placeholder = 'Repetir contraseña nueva*';"
                                            (focus)="signupPass2Focus=true;" (blur)="signupPass2Focus=false;"
                                            (input)="checkPass1()">
                                    </div>
                                </div>
                                <div class="col-2 col-md-1 m-0 m-0 align-self-center"
                                    style="display: flex; justify-content: center;">
                                    <span class="calendar" (click)="toggleFieldTextType3()">
                                        <mat-icon>{{ fieldTextType3 ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>
    <footer class="footer">
        <div>
            <!-- Círculos para las nuevas páginas -->
            <mat-icon *ngIf="pagina == 0" class="boton-circulo" [ngStyle]="{color: estilo[0]}">circle</mat-icon>
            <mat-icon *ngIf="pagina == 1" class="boton-circulo" [ngStyle]="{color: estilo[1]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[2]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[3]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[4]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[5]}">circle</mat-icon>
            <mat-icon class="boton-circulo" [ngStyle]="{color: estilo[6]}">circle</mat-icon>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <div *ngIf="pagina<6" style="width: 100%;height: 60px;align-items: center;text-align: center; display: flex; justify-content: center;">
                <button *ngIf="pagina > 2" (click)="volverBoton()" class="boton-omitir">Volver</button>
                <button *ngIf="pagina <= 2" (click)="salirBoton()" class="boton-omitir">Salir</button>
                <button *ngIf="pagina >= 2" (click)="siguienteBoton()" class="boton-siguiente">Siguiente</button>
                <button 
                *ngIf="pagina == 0" 
                    class="boton-siguiente" 
                    [ngClass]="{'boton-siguiente-disabled': registerForm.invalid}" 
                    [disabled]="registerForm.invalid" 
                    (click)="siguienteBoton()" 
                    style="float: right;">
                    Siguiente
                </button>
                <button 
                *ngIf="pagina == 1" 
                    class="boton-siguiente" 
                    [ngClass]="{'boton-siguiente-disabled': cambiaPass.invalid}" 
                    [disabled]="cambiaPass.invalid" 
                    (click)="siguienteBoton()" 
                    style="float: right;">
                    Siguiente
                </button>
            </div>
            <div *ngIf="pagina>5" style="width: 100%;height: 60px;align-items: center;text-align: center;">
                <button (click)="empezemosBoton()" class="boton-siguiente" style="width: 40%">¡Empecemos!</button>
            </div>
        </div>

    

    </footer>

</div>