import { Component, Input, OnInit } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  @Input() usuario: any = {};

  public planes: any = [];
  public plan_selected: any = {};

  constructor(
    public generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    // console.log(new EncriptacionService().getUserInfo());

    this.postListPlansXBeneficiaries();
  }

  postListPlansXBeneficiaries() {
    this.generalService.postListPlansXBeneficiaries(this.usuario.use_id)
      .then(result => {
        // console.log(result, 'result-postListPlansXBeneficiaries');
        this.planes = result;
      })
      .catch(error => {
        //console.log(error,'error-postListPlansXBeneficiaries');
      });
  }


  showPlan(plan) {
    // console.log(plan, 'plan-showPlan');
    this.plan_selected = plan;
  }

}
