<!-- MEDICO -->
<div *ngIf="(usuario.typ_id == 1)" class="card p-0 m-0" [ngClass]="{'border-confirm': data.confirmFlag, 'border-go-lobby': data.confirmFlag}">
    <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
        <div class="m-0 p-0 first-line">
            <app-profile-img [img]="data.img" [doctorName]="data.nombre_paciente" [doctorLastName]="data.apellido_paterno_paciente"
                [color]="data.goLobbyFlag ? 'confirm' : data.confirmFlag ? 'confirm' : 'confirm'">
            </app-profile-img>
            <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ data.nombre_paciente }} {{ data.apellido_paterno_paciente }} {{data.apellido_materno_paciente}}</p>
                <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ data.date[0] }} {{ data.date[1] }}</p>
                <p class="card-text m-0 p-0 ">{{ data.spec }} </p>
                <p class="card-text m-0 p-0 "> </p>
            </div>
            <app-popover class="ml-auto mr-2">
                <p class="pointer" data-toggle="modal" data-target="#eliminarPaciente">Anular consulta</p>
                <p class="pointer">Ver detalles de la consulta</p>
            </app-popover>
        </div>
        <br>
        <div class="d-flex align-items-end justify-content-end flex-wrap">
            <button type="button" class="btn btn-primary mr-auto"
                [disabled]="!data.goLobbyFlag" (click)="goLobby()">
                Ir a la videoconsulta
            </button>
        </div>
    </div>
</div>

<!-- MODAL ANULAR CONSULTA -->
  <div class="modal fade" id="eliminarPaciente" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
            <p class="tituloModal">Anulación de consulta</p>
            <br>
            <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 border-card-modal">
                <div class="m-0 p-0 first-line">
                    <app-profile-img [img]="data.img" [doctorName]="data.nombre_paciente" [doctorLastName]="data.apellido_paterno_paciente"
                        [color]="data.goLobbyFlag ? 'confirm' : data.confirmFlag ? 'confirm' : 'confirm'">
                    </app-profile-img>
                    <div class="ml-3 align-self-center">
                        <p class="card-text m-0 p-0 appt-date">{{ data.nombre_paciente }} {{ data.apellido_paterno_paciente }} {{data.apellido_materno_paciente}}</p>
                        <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ data.date[0] }} {{ data.date[1] }}</p>
                        <p class="card-text m-0 p-0 ">{{ data.spec }}</p>
                        <p class="card-text m-0 p-0 "> </p>
                    </div>
                    </div>
                    <br>
                </div>
          <br><br>
            <div class="form-group">
                <textarea class="form-control" placeholder="Motivo de la cancelación*" rows="5" id="comentario"></textarea>
            </div>
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar formulario</button>
                </div>
                <div class="col-md-6 letrabotonagregar">
                    <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar" data-toggle="modal" data-target="#confirmaAnular">Cancelar consulta</button> <!-- (click)="botonEliminarBeneficiario()"  -->
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL CONFIRMA ANULAR CONSULTA -->
  <div class="modal fade" id="confirmaAnular" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
            <p class="tituloModal">¿Esta seguro que desea anular la consulta?</p>
            <br>
          <br><br>
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                </div>
                <div class="col-md-6 letrabotonagregar">
                    <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar">Confirmar</button> <!-- (click)="botonEliminarBeneficiario()"  -->
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>