<!-- modals -->
<app-recovery></app-recovery>
<app-login-loading></app-login-loading>
<app-login-error></app-login-error>
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section class="container-fluid m-0 p-0 login-container">
    <!-- desktop -->
    <div class="row d-none d-sm-flex m-0 p-0 login-banner">
        <div class="col-sm-12 d-flex align-items-start login-form-wrapper">
            <div class="d-flex flex-column align-items-center">
                <div class="m-0 p-0 login-logo">
                    <img src="assets/images/logo-almamedis.png" alt="alamedis logo">
                </div>
                <app-login [loginPaciente]="false" [loginMobile]="false"></app-login>
            </div>
        </div>
        <svg class="curve" data-name="Layer 1" viewBox="0 0 1440 122" xmlns="http://www.w3.org/2000/svg">
            <path d="M1439 180.5H0V0C149 7 463.385 28.1203 768 86.5C1031.5 137 1323.33 64.1667 1439 20.5L1439 180.5Z"
                fill="black" />
        </svg>
    </div>
    <!-- mobile -->
    <div class="row d-sm-none m-0 p-0 login-banner">
        <svg class="curve" data-name="Layer 1" viewBox="0 0 1440 122" xmlns="http://www.w3.org/2000/svg">
            <path d="M1439 180.5H0V0C149 7 463.385 28.1203 768 86.5C1031.5 137 1323.33 64.1667 1439 20.5L1439 180.5Z"
                fill="black" />
        </svg>
    </div>
    <div class="row d-sm-none m-0 p-0">
        <div class="col-12 justify-content-center login-form-wrapper">
            <div class="m-0 p-0 text-center login-logo">
                <img src="assets/images/logo-almamedis.png" alt="Responsive image">
            </div>
            <app-login [loginPaciente]="false" [loginMobile]="true"></app-login>
        </div>
    </div>
    <!-- all -->
    <div class="row login-message">
        <div class="col-12">
            <p>La más completa comunidad de salud en <span class="highlight">cualquier lugar</span> y cuando <span
                    class="highlight">nos necesites</span></p>
        </div>
    </div>
    <div class="row login-skills justify-content-center align-items-center">
        <div class="col-12 col-sm-4 m-0 p-0 p-sm-1">
            <div class="skill-wrapper">
                <img src="assets/images/icon/healthcare.svg" alt="alamedis logo">
                <p class="mt-4">Una plataforma cercana y que te acompaña</p>
            </div>
        </div>
        <div class="col-12 col-sm-4 m-0 p-0 p-sm-1">
            <div class="skill-wrapper">
                <img src="assets/images/icon/mobile-phone.svg" alt="alamedis logo">
                <p class="mt-4">Constantemente conectados con nuestros pacientes</p>
            </div>
        </div>
        <div class="col-12 col-sm-4 m-0 p-0 p-sm-1">
            <div class="skill-wrapper">
                <img class="align-self-center" src="assets/images/icon/protection.svg" alt="alamedis logo">
                <p class="mt-4">Todos tus datos protegidos con altos estándares de seguridad</p>
            </div>
        </div>
    </div>
    <app-login-footer></app-login-footer>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->