
import { Component, OnInit, ViewChild  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { GeneralService } from 'src/app/services/general.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { CalendarDocComponent } from 'src/app/components/calendar-doc/calendar-doc.component';

@Component({
  selector: 'app-agenda-del-medico',
  templateUrl: './agenda-del-medico.component.html',
  styleUrls: ['./agenda-del-medico.component.css']
})
export class AgendaDelMedicoComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,  
    public _general: GeneralService,
    public _schedule: ScheduleService,
    private router: Router) { 
  }

  @ViewChild(CalendarDocComponent) CalendarDoc :CalendarDocComponent;
  _usuario: any = {};
  doctor_selected: any = {};
  hours_list_doc: any;                // horas disponibles del medico toales (todos los dias)

  btn_disp = {
    ver: true,
    edit: false,
    add: false,
  }
  parametros_url: any = {};

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();

    this.activatedRoute.params.subscribe(parametros => {
      this.parametros_url = parametros;

      //console.log( parametros);
    });
    switch (this.parametros_url.item) {
      case 'ver':
        this.btn_disp = {ver: true, edit: false, add: false};
        break;
      case 'edit':
        this.btn_disp = {ver: false, edit: true, add: false};
        break;
      case 'add':
        this.btn_disp = {ver: false, edit: false, add: true};
        break;
      default:
        this.router.navigate(['dashboard'])  
        break;
    }
    
    if ( !(this._usuario.typ_id == 7 || this._usuario.typ_id == 8 || this._usuario.typ_id == 1) ){
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
    this.activatedRoute.params.subscribe(parametros => {
      if ( !(parametros.item == 'ver' || parametros.item == 'edit' || parametros.item == 'add') || (parametros.item == 'add' && (this._usuario.typ_id == 1)) ) {
        this.router.navigate(['dashboard'])
      }
      else {
        this._general.getDoctorList( parametros.id_doctor, null ) //consulto al doctor segun el ide
          .then( (data: any) => {
            if ( data.length == 0 ) {
              Swal.fire({
                icon: 'error',
                text: 'Error',
                timer: 2000
              })
              setTimeout(() => this.router.navigate(['dashboard']), 2000);
            }
            else {
              this.doctor_selected = data[0];  
            }
          })
          .catch( error => console.log(error))
      }
    });
  }



  volver(){
    this.router.navigate(['agenda-medicos']);
  }



  receiveMessage($event) {
    //console.log($event)
  }

}

