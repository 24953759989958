import { Component, Input, OnInit } from '@angular/core';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';

@Component({
  selector: 'app-con-sin-finalizar',
  templateUrl: './con-sin-finalizar.component.html',
  styleUrls: ['./con-sin-finalizar.component.css']
})
export class ConSinFinalizarComponent implements OnInit {
  @Input() use_id: any; 
  appointments: any[] = [];
  constructor(
    private _fichaServices:FichaMedicaService
  ) { }

  ngOnInit(): void {
    this._fichaServices.getListarConsultasNoFinalizadas(this.use_id)
      .then((result: any) => {
        //console.log(result);
        // this.consultas = result;
        if (!(result.consultas.length > 0)) {
          //console.log('Sin proximas consultas.');
        } else {
          const consultas = result.consultas;
          for (let i = 0; i < consultas.length; i++) {
            const appt = consultas[i];
            const hour = appt.hora.substr(0, 2);
            const minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-','/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            const now = new Date();
            const formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              const msToAppt = this.timeToX(apptDate, now);
              const minToAppt = this.msToMin(msToAppt);
              if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
                confirmFlag = true;
              }
              let goLobbyFlag: boolean = false;
              if (minToAppt <= 60) {
                goLobbyFlag = true;
              }
              const apptData = {
                date: formatedApptDate,
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
                goLobbyFlag,
                hora:appt.hora,
                fecha_consulta:appt.fecha_consulta.substr(0, 10).replaceAll('-','/'),
                id_consulta: appt.id_consulta,
                zoo_mee_room_id:appt.zoo_mee_room_id,
                med_con_id:appt.med_con_id,
                nombre_paciente:appt.nombre_paciente,
                apellido_paterno_paciente:appt.apellido_paterno_paciente,
                apellido_materno_paciente: appt.apellido_materno_paciente,
                consulta:appt

              };
              if(appt.tel_doc_app_boo_sta_id != 44){

                this.appointments.push(apptData);
              }
              // if (this.appointments.length <= 2) {
              //   this.auxAppts.push(apptData);
              // }
            }
          }
          //console.log(this.appointments);
          

        }
      })
      .catch(error => {
        console.log(error)
      })
    
  }

  
  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Hoy ' + returnDate[0];
    }
    if (minToAppt < 0) {
      return returnDate;
    }
    return returnDate;
  }

  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }

}
