import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-wrapper-doctor',
  templateUrl: './login-wrapper-doctor.component.html',
  styleUrls: ['./login-wrapper-doctor.component.css']
})
export class LoginWrapperDoctorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
