import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.css']
})
export class AgreementsComponent implements OnInit {
  @Input() agreements: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
