 <div class="row">
    <div class="col-md-12">
        <br>
        <div class="minititulo">
                Mi videoconsulta
        </div>
        <div class="titulo">
                Sala de espera
        </div>
        <br>
    </div>
</div>

<div class="row">
    <div class="row col-md-12">
        <div class="card-alerta" style="margin: 0px 10px">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <p class="letras-alerta"><mat-icon style="color:#171994;font-size: 30px;height: auto; width: auto; margin: 0 10px;">info</mat-icon>Por razones de confidencialidad, usted será dirigido automáticamente a la video llamada una vez que el médico la de por iniciada.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<br>

<div class="row">
    <div class="col-md-12">
        <p class="letra-titulo">Debes Tener Presente</p>
    </div>
</div>

<div class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <div class="card-deck">

            <div class="card" >
                
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text"></p>
                    <p class="card-text">Recuerda que debes encender tu cámara y tu micrófono, antes de iniciar tu consulta de telemedicina.</p>
                </div>
            </div>
            <div class="card" >

                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text"></p>
                    <p class="card-text">Si tienes algún problema técnico, o el profesional médico no se presenta, debes comunicarte con nuestra Mesa de Ayuda.</p>
                    <p *ngIf="consulta.telefono_plan" style="font-family: Mulish; font-weight: bold; color: #2BABB7; font-style: normal; font-size: 24px;line-height: 32px;"> {{consulta.telefono_plan}} </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
            <app-card-consulta
                [idconsulta]='parametros_url.id_consulta'>
            </app-card-consulta>
    </div>
</div>

<br>

<div class="row">
    <div *ngIf="parametros_url.id_consulta" class="col-md-12">
        <app-carrusel [consulta]="parametros_url.id_consulta">
        </app-carrusel>
    </div>
</div>