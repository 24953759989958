
<div class="row">
  <div class="col-md-12">
    <br>
        <div class="minititulo">
            Mi perfil
        </div>
        <div class="titulo">
            Mis tarifas
        </div>

  </div>
</div>
<br>

<div id="rate-details" class="col-10"style="margin-bottom: 48px;">
  <h3>Especialidades</h3>

  <ng-container *ngIf="usuario._id">
 <app-detalle-tarifa></app-detalle-tarifa>
</ng-container>


</div>




