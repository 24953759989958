<div class="card">
    
    <div class="card-body">
        <h3>{{ title }}</h3>

        <div style="display: block">
            <canvas baseChart
                [data]="doughnutChartData"
                [labels]="doughnutChartLabels"
                [colors]="colors"
                [chartType]="doughnutChartType">
            </canvas>
        </div>

    </div>

</div>