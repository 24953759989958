import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import Swal from 'sweetalert2';
import { AppComponent } from '../../app.component';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { CompleterService, CompleterData } from 'ng2-completer';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-historial-consultas-medico',
  templateUrl: './historial-consultas-medico.component.html',
  styleUrls: ['./../../../assets/css/styles.css', './historial-consultas-medico.component.css']
})
export class HistorialConsultasMedicoComponent implements OnInit {

  consulta: any = [];
  consultas: any = [];
  consultas_list: any;
  consultas_aux: any;

  public appointments: any[] = [];
  public auxAppts: any[] = [];
  solo_fecha:boolean = true;

  f_desde: any = '';
  f_hasta: any = '';

  anio:any;
  botones = null;
  ficha = null;
  accion = 0;
  modificar = false;
  usuario:any;

  showOptionFecha: boolean = true;
  completerData: CompleterData;

  constructor(
    private _fichaMedicaServices: FichaMedicaService,
    private completerService: CompleterService,
    private generalService: GeneralService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.usuario = new EncriptacionService().getUserInfo();
    this.verConsultas()

  }

  
  verConsultas() 
  { 
    let data = {
      doc_id: (this.usuario.typ_name == 'PACIENTE')? null : this.usuario._id,
      paciente: (this.usuario.typ_name == 'PACIENTE')? this.usuario.rut : null,
      date: null,
    }

    this._fichaMedicaServices.getListarConsultasPasadas(data)
      .then( (results:any) => { 
         //console.log(results);
        if (!(results.consultas.length > 0)) {
          //console.log('Sin proximas consultas.');
        } else {
          const consultas = results.consultas;
          for (let i = 0; i < consultas.length; i++) {
            const appt = consultas[i];
            //appt.consulta.fecha_consulta = appt.consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/');
            const hour = appt.hora.substr(0, 2);
            const minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-','/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            const now = new Date();
            this.anio = apptDate.getFullYear();
            const formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              const msToAppt = this.timeToX(apptDate, now);
              const minToAppt = this.msToMin(msToAppt);
              const apptData = {
                date: formatedApptDate,
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                apptDate: new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-','/')),
                anio: apptDate.getFullYear(),
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
                id_consulta: appt.id_consulta,
                zoo_mee_room_id:appt.zoo_mee_room_id,
                med_con_id:appt.med_con_id,
                nombre_paciente:appt.nombre_paciente,
                apellido_paterno_paciente:appt.apellido_paterno_paciente,
                apellido_materno_paciente:appt.apellido_materno_paciente,
                email_doctor: appt.email_doctor,
                use_id: appt.use_id,
                consulta:appt,
                nombre_completo: appt.nombre_paciente + ' ' + appt.apellido_paterno_paciente + ' ' + appt.apellido_materno_paciente,
              };
              apptData.consulta.fecha_consulta = apptData.consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/');
              this.appointments.push(apptData);
              this.auxAppts.push(apptData);
              
              

              let ind = this.nombres_pacientes.findIndex( (pacie) => pacie.nombre_completo == apptData.nombre_completo);
              if(ind < 0) {  this.nombres_pacientes.push({nombre_completo: apptData.nombre_completo}) }
              
            }

            this.completerData = this.completerService.local(this.nombres_pacientes, 'nombre_completo', 'nombre_completo');
          }
        }
        // console.log(this.appointments);
        // console.log(this.auxAppts);        
    })
    .catch( e => console.log(e))
  }

  obtenerResta(hora) 
  {
    var fecha = new Date(Date.now());
    var minutos = fecha.getHours() * 60 + fecha.getMinutes();
    var minutos_consulta = parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
    var diferencia = minutos_consulta - minutos;
    return diferencia;
  }
  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    const year = months[apptDate.getFullYear()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    return returnDate;
  }
  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);
  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  //historialConsultasDoc/paciente
  irdetallesPacientes(appt){
    var url = "historialConsultasDoc/paciente/"+appt.med_con_id;
    this.router.navigate([url]);
  }

  
  strPacienteFilter: any = '';
  strPacienteFilter_fin: any ='';       //Se almacena el string para filtrar x fecha o x nombre_paciente;
  nombres_pacientes: Array<any> = [];
  public auxAppts_fin: any[] = [];


  changeNamePaciente(event){
    this.solo_fecha = false;
    ////console.log(event)
    ////console.log(this.strPacienteFilter);
    this.strPacienteFilter_fin = '';
    //console.log("log de nombre de paciente");
    
  }

  changeFechas(event){
    ////console.log(event)
    this.solo_fecha = true;
    if (this.strPacienteFilter_fin != '') {
      for (let i = 0; i < this.auxAppts.length; i++) {
        //console.log(this.auxAppts[i].consulta.fecha_consulta.substring(0,10).replaceAll('-',''));

          if (this.auxAppts[i].consulta.fecha_consulta.substring(0,10).replaceAll('/','') >= this.strPacienteFilter.replaceAll('-','') && this.auxAppts[i].consulta.fecha_consulta.substring(0,10).replaceAll('-','') <= this.strPacienteFilter_fin.replaceAll('-','')) {
            this.auxAppts_fin.push(this.auxAppts[i]);
          }

      }
      // console.log(this.auxAppts_fin);
      
      // //console.log(this.auxAppts);
      // if (this.auxAppts) { replace('-','')  .substring(0,9)
      //   // this.generalService.verificarFecha(this.auxAppts.consulta.fecha_consulta)
      // }
      
    }
  }
  

}
