<app-title-header [title]='_title' [subtitle]='_subtitle'>
</app-title-header>

<!-- card -->

<div class="card p-0 card-doctor" *ngIf="data_cargada">
  <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
    <div class="row">
      <app-profile-img class="col-md-1 col-4" [img]="datos_consulta.att_path" [doctorName]="datos_consulta.nombre_doctor"
      [doctorLastName]="datos_consulta.apellido_materno_doctor" [color]="'default'" style="    align-content: center; padding: 5px;">
    </app-profile-img>
    <div class="col-md-11 col-8 m-0 p-0 " style="width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;">
        <div class="row" style=" width: 100%;     display: flex;
        align-items: center;">
        <div class="col-md-9 col-12">
          <div class="col-12 m-0 p-0 align-self-center detalle">
            <p class="card-text mb-1 p-0 doc-data"> Dr. {{ datos_consulta.nombre_doctor }} {{
              datos_consulta.apellido_paterno_doctor }} {{ datos_consulta.apellido_materno_doctor }}</p>
            <p class="card-text m-0 p-0 doc-data">Área médica - {{ datos_consulta.spe_name }}</p>
            <!-- - {{ datos_consulta.sub_spe_name }} -->
            <p class="card-text m-0 p-0 appt-date">Hoy {{datos_consulta.fecha_consulta.day}} de
              {{datos_consulta.fecha_consulta.month}} - a las {{datos_consulta.fecha_consulta.time}} horas</p>
          </div>
        </div>
        <div class="col-md-3 col-12 align-self-center confirmada">
          <p class="text-right">
            <li>Confirmada</li>
          </p>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <app-video-web-rtc [video_iniciado]='videoIniciado'>
    </app-video-web-rtc>
  </div>
</div>
<br>

<div class="options">
  <div class="row" style="margin: 0;">
    <button class="col-6" id="consulta" [class]="options[0] ? 'checked' : 'nochecked'"
      (click)="optionsButton(0)">Mis documentos</button>
    <button class="col-6" id="antecedentes" [class]="options[1] ? 'checked' : 'nochecked'"
      (click)="optionsButton(1)">Mis antecedentes</button>
  </div>
</div>

<div class="options2" *ngIf="options[0]">
  <div class="row" style="margin: 0;">
    <div class="col-md-6 col-12">
      <button class="col-md-6 col-6" id="documentos" [class]="options2[0] ? 'checked' : 'nochecked'"
        (click)="optionsButton2(0)">Ver y Adjuntar</button>
      <button class="col-md-6 col-6" id="recetas" [class]="options2[1] ? 'checked' : 'nochecked'"
        (click)="optionsButton2(1)">Recetas y otros</button>

    </div>
    <div class="col-md-6 col-12 contenedor-actualizar">
      <button *ngIf="options2[1]" type="button" class="updatebtn" style="float: right" (click)="updateDocuments2()">Actualizar</button>

    </div>

  </div>
</div>

<div *ngIf="options2[0]">
  <app-documents>
  </app-documents>
</div>
<div *ngIf="options2[1]">
  <app-documents-consult>
  </app-documents-consult>
</div>
<div *ngIf="options[1]">
  <app-antecedentes-paciente>
  </app-antecedentes-paciente>
</div>

<div class="row">
  <div class="col-md-12">
    <p class="letra-titulo">Debes Tener Presente</p>
  </div>
</div>

<div class="row mb-2 mb-sm-4 mt-1 mt-sm-1">
  <div class="card-deck">

    <div class="card border-confirm card-fono col-md-6 col-sm-12">
      <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
        <p class="card-text"></p>
        <p class="card-text">Recuerda que debes encender tu cámara y tu micrófono, antes de iniciar tu consulta de
          telemedicina.</p>
      </div>
    </div>
    <div class="card border-confirm card-fono col-md-6 col-sm-12">
      <div class="card-block ">
        <p class="card-text m-0 p-0">Si tienes algún problema técnico, o el paciente no se presenta, debes comunicarte
          con nuestra Mesa de Ayuda.</p>
        <p *ngIf="datos_consulta.telefono_plan" class="fono p-0">{{datos_consulta.telefono_plan}}</p>
      </div>
    </div>
  </div>
</div>