<app-title-header
  [title]= '_title'
  [subtitle]='_subtitle'>
</app-title-header>

<div class="schedule-container">

  <form name="form" style="text-align: left" #f1="ngForm" class="">

    <label for="">Rut Titular</label>
		<ng2-completer name="id_titular"
			(selected)="changeTitular($event)"
			[textNoResults]="'Titular no encontrado.'"
			[datasource]="titulares"
			[(ngModel)]="searchStrMain"
			[minSearchLength]="0"
			[placeholder]="'Seleccione Titular'"
			#id_clinica="ngModel"
			[autoMatch]="true"
			[inputClass]="'form-control'"
			[clearUnselected]="true"
			[openOnFocus]="true"
			[disabled]="tit_cargado"
			required>
		</ng2-completer>
		<div class="my-3">
			<button type="submit" class="btn btn-primary" (click)="rutTitular()" *ngIf="!tit_cargado">Cargar</button>
			<button class="btn btn-outline-secondary" (click)="change()" *ngIf="tit_cargado">Cambiar</button>
		</div>
		
	</form>

</div>

<div *ngIf="tit_cargado">

  <app-reserva-hora 
                  [vista_externa]='true' [beneficiario_externo]='beneficiary_selected'>
  </app-reserva-hora>

</div>