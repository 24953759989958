import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';


import Swal from 'sweetalert2';
import { Router } from '@angular/router';

import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';
import { GeneralService } from './general.service';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
    public _generalServices: GeneralService
  ) { }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getScheduleTimes       /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async getScheduleTimes() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {}
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getScheduleTimes', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getScheduleHours        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Consulta todas las horas disponibles del doctor
  async getScheduleHours(id_doctor, available, sch_sta_id, sch_hou_sta_id, date_day, next) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      doc_id: id_doctor,                                    // null= todos
      available: available,                                 // false indica dias disponibles, dominante
      sch_sta_id: sch_sta_id,                               // null=todos 7,8
      sch_hou_sta_id: sch_hou_sta_id,       // 11 / null = todos
      date_day: date_day,                                   // '20201006' (usa >=) //null=todos
      next: next,                                           // solo la proxima hora
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getScheduleHours', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
      throw 1;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getScheduleHoursDoc        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Consulta todas las horas del mes de un doctor
  async getScheduleHoursDoc(id_doctor, mes, todas) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      doc_id: id_doctor,                         // 
      mes: mes,
      todas: todas                             //='yyyymm'. si es null entrega el mes
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getScheduleHoursDoc', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getScheduleHoursDisp      /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async getScheduleHoursDisp(id_doctor, mes = null, todas = null) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      doc_id: id_doctor,
      mes: mes,
      todas: todas,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getScheduleHoursDisp', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       addScheduleHours        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async addScheduleHours(id_doctor, day, hours) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      doc_id: id_doctor,
      day: day,
      hours: hours
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/addScheduleHours', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       editScheduleHours        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async editScheduleHours(id_doctor, hours, edit_reason = '') {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      doc_id: id_doctor,
      hours: hours,
      edit_reason: edit_reason
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/editScheduleHours', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getAppointment       /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Consulta todas las horas disponibles del doctor
  async getAppointment(id_usuario, id_doctor, boo_id = null, sch_hou_id = null, next = false) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      use_id: id_usuario,              // not null
      doc_id: id_doctor,               //
      boo_id: boo_id,
      sch_hou_id: sch_hou_id,
      next: next,                       // proximas consultas
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getAppointment', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       scheduleAppointment        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async scheduleAppointment(sch_hou_id, ben_id, sub_spe_id, data_selected, use_id, code) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let dispositivo = this._generalServices.getDevice();
    //console.log(dispositivo);

    let params = {
      sch_hou_id: sch_hou_id,
      ben_id: ben_id,
      sub_spe_id: sub_spe_id,
      data_selected: data_selected,
      device_type: dispositivo.type,
      use_id: use_id,
      code
    }
    // console.log(params);

    try {
      let data = await this.http.post(URL_SERVICIOS + '/scheduleAppointment', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error);
      throw error;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       payAppointment        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async payAppointment(id_consulta) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    // let dispositivo = this._generalServices.getDevice();

    let params = {
      id_consulta: id_consulta,
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/payAppointment', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error);
      throw error;
    }
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       cancelAppointment       /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async cancelAppointment(boo_id, use_id, cancel_reason, data_selected = null, cancel_by) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let dispositivo = this._generalServices.getDevice();
    //console.log(dispositivo);

    let params = {
      boo_id: boo_id,
      use_id: use_id,
      cancel_reason: cancel_reason,
      cancel_by: cancel_by,
      data_selected: data_selected,
      device_type: dispositivo.type
    }

    //console.log(params);

    try {
      let data = await this.http.post(URL_SERVICIOS + '/cancelAppointment', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
      throw (error);
    }
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       editAppointment       /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async editAppointment(data, data_selected) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      sch_hou_id: data.sch_hou_id,
      ben_id: data.ben_id,
      sub_spe_id: data.sub_spe_id,
      boo_id: data.boo_id,
      use_typ_name: data.use_typ_name,
      data_selected: data_selected,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/editAppointment', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw (error);

    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getDoctor         /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Consulto listado de doctores
  async getDoctor(doctor_name_selected, doctor_surname_selected, speciality_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      nombre: doctor_name_selected.toUpperCase(),
      apellidoPaterno: doctor_surname_selected.toUpperCase(),
      idEspecialidad: speciality_id,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarDoctor', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       editDoctor         /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async editDoctor(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      idDoctor: data.idDoctor,
      docNumeroRegistro: data.docNumeroRegistro,
      docFechaRegistro: data.docFechaRegistro,
      idUsuario: data.idUsuario,
      idtitulo: data.idtitulo,
      fechaEmisionTitulo: data.fechaEmisionTitulo,
      idInstitucion: data.idInstitucion,
      idEspecialidad: data.idEspecialidad,
      comentarios: data.comentarios
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postModificarDoctor', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postInsertSurvey         /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postInsertSurvey(params) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postInsertSurvey', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      throw error;
      // this._errors.requestError(error)
    }
  }


  async getListarConsultasPendientes(id_consulta) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      id_consulta: id_consulta
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsultasPendientes', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async typificationsAppointment() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};

    try {
      let data = await this.http.post(URL_SERVICIOS + '/typificationsAppointment', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async updateStateAppointment(id_consulta, typ_id, reason,) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let dispositivo = this._generalServices.getDevice();

    let params = {
      id_consulta: id_consulta,
      typ_id: typ_id,
      reason: reason,
      device: dispositivo.type,
    };

    // console.log(params);
    try {
      let data = await this.http.post(URL_SERVICIOS + '/updateStateAppointment', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

}
