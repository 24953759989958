import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http'; 

import { Router } from '@angular/router';
import { CusId } from '../interfaces/cus-id.interface';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
    providedIn: 'root'
  })
export class CusIdService {
  
    constructor(
      private http: HttpClient,
      private router: Router,
      public _errors: ErrorsService,
    ) {}

    traeId(usuario : CusId) {

        const httpOptions = {
            headers: new HttpHeaders({
              'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
            })
          };
        try {
            let data = this.http.post( URL_SERVICIOS + '/cus_id', usuario, httpOptions ).toPromise()
            return data
          }
          catch (error) {
            this._errors.requestError(error)
          }
    }

}