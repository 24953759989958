import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';
import { lastDayOfDecade } from 'date-fns';
import { Usuario } from 'src/app/models/usuario.model';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import Swal from 'sweetalert2';
import { CompleterService, CompleterData } from 'ng2-completer';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-historial-consultas',
  templateUrl: './historial-consultas.component.html',
  styleUrls: ['./historial-consultas.component.css']
})
export class HistorialConsultasComponent implements OnInit {
  @Input() eventos: any; 
  @Input() consulta: any; 
  @Input() solo_ver: any; 
  @Output() messageEvent = new EventEmitter<string>();
  fecha_sistema = new Date();
  titulo: String="Seleccione Opción en Barra Lateral";
  subtitulo: String="Seleccione Opción en Barra Lateral";
  comentario: String;
  accion: any = 0;

  botones: boolean = false;
  
  examenes: any;
  id_exam: any;

  recetas: any;
  id_receta: any;

  diagnosticos: any;
  id_diagnostico: any;

  causales: any;

  consultas: any = [];
  consultas_list: any;
  consultas_aux: any;

  ficha: any;

  id_requerimiento:any;

  id_causal: any;
  modificar: boolean = false;

  usuario: Usuario;
  id_con_med: any;

  clinicas: CompleterData;
  clinicas_data: any = [];
  id_clinica: any;
  clinica_selected: any;
  searchStr: string;  

  historial: any = [];

  enable = false;
  aux : any;
  con_aux: any;
  constructor(
    private _fichaMedicaServices: FichaMedicaService,
    private router: Router, 
    private completerService: CompleterService
  ) {

    this._fichaMedicaServices.getListarExamType()
      .then(results => { 
        this.examenes = results;
      })
      .catch( e => console.log(e))

    this._fichaMedicaServices.getListarPrescriptionType()
      .then(results => { 
        this.recetas = results;
      });

    this._fichaMedicaServices.getListarConDiagnostic()
      .then(results => { 
        this.diagnosticos = results;
      });
    
    this._fichaMedicaServices.getListarConCausal()
      .then(results => { 
        this.causales = results;
      });
    
      this._fichaMedicaServices.getListarClinicas().then(results => { 

        this.clinicas_data = results;
        this.clinicas = this.completerService.local(this.clinicas_data, 'hos_name', 'hos_name')    
  
      });

    this.usuario = new EncriptacionService().getUserInfo();


   }

  ngOnInit(): void {
  }


  verConsultas() 
  { 

    //console.log(this.consulta) // no lo ocuparé
    let data = {
      doc_id: (this.usuario.typ_name == 'PACIENTE')? null : this.usuario._id,
      paciente: (this.usuario.typ_name == 'PACIENTE')? this.usuario.rut : null,
      date: null,
    }

    this._fichaMedicaServices.getListarConsultasPasadas(data)
      .then( (results:any) => { 
        if ( results.consultas.length > 0 ){
          this.consultas = [];
          this.botones = null;
          this.ficha = null;
          this.accion = 0;
          this.modificar = false;
          this.consultas_list = results;        
          var largo = this.consultas_list.consultas.length;
        
          for (var i = 0 ; i < largo; i++){
            var resta_minutos = this.obtenerResta(this.consultas_list.consultas[i].hora);
            var fecha_sistema = new Date();
            const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');

            if (this.consultas_list.consultas[i].fecha_consulta.substring(0, 10) != fecha.toString()) {
              this.consultas.push(this.consultas_list.consultas[i]);
            } else if (resta_minutos > 0) {

            } else { 
              this.consultas.push(this.consultas_list.consultas[i]);
            } 
          }
        }
        else {
          Swal.fire({
            icon: 'info',
            text: 'Sin consultas pasadas',
            timer: 3000
          })
        }
    })
    .catch( e => console.log(e))
  }

  obtenerResta(hora) 
  {
    var fecha = new Date(Date.now());
    var minutos = fecha.getHours() * 60 + fecha.getMinutes();
    var minutos_consulta = parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
    var diferencia = minutos_consulta - minutos;
    return diferencia;
  }

  VerFicha2(item) {
    //this.aux = item;
    this.id_con_med = item.med_con_id;

    this.modificar = false;
    this._fichaMedicaServices.getListarFichaConsulta(item)
      .then(results => {
        this.ficha = results;
      })
      .catch(error => {
        // // console.log(error);
      });
  }


  VerFicha(item) {
    this.con_aux = item;
    this.id_con_med = item.med_con_id;

    this.modificar = false;
    this._fichaMedicaServices.getListarFichaConsulta(item)
      .then(results => {
        //console.log(results);
        

        if (item.email_doctor==this.usuario.email) {
          this.modificar = true;
        }
        this.botones = true;
        this.consultas = [];
        this.accion = 0;
        this.ficha = results;
      })
      .catch(error => {
        // // console.log(error);
      });
  }


  SoloVerPrescription(item) { 
    this.aux = item;
    this.titulo = "Receta Médica";
    this.subtitulo = "Receta Médica";
    this.comentario = item.pre_his_indication;
    this.accion = 3;
    this.id_receta = item.pre_his_pre_typ_id;
    this.id_requerimiento = item.pre_his_pre_id;
    this.enable = true;
  }

  SoloVerOrder(item) { 
    this.aux = item;
    this.id_requerimiento=item.med_ord_his_med_ord_id;
    if (item.med_ord_his_med_ord_typ_id == 2)
    {
      this.titulo = "Certificado Médico";
      this.subtitulo = "Actualizar Certificado Médico";
      this.comentario = item.med_ord_his_comment;
      this.accion = 4;

    } else if (item.med_ord_his_med_ord_typ_id ==3) { 

      this.titulo = "Orden Médica";
      this.subtitulo = "Actualizar Orden Médica";
      this.comentario = item.med_ord_his_comment;
      this.accion = 2;
      this.id_exam =item.med_ord_his_exa_typ_id;
    }
    this.enable = true;

  }

  SoloVerDiagnostic(item) { 
    this.aux = item;
    this.titulo = "Diágnostico e Indicaciones";
    this.subtitulo = "Actualizar Diágnostico";
    this.comentario = item.diag_det_his_diagnostic_detail;
    this.accion = 1;
    this.id_diagnostico = item.diag_det_his_dia_id;
    this.id_requerimiento = item.diag_det_his_dia_det_id;
    this.enable = true;

  }

  SoloVerAnamnesis(item) {
    this.aux = item;
    this.titulo = "Historia Clínica";
    this.subtitulo = "Actualizar Historia Clínica";
    this.comentario = item.med_con_his_anamnesis;
    this.id_requerimiento=item.med_con_his_med_con_id;
    this.accion = 5;
    this.enable = true;

  }

  SoloVerDerivation(item) {
    this.aux = item;
    // this.id_con_med = item.med_con_id;
    this.titulo = "Derivación Clínica";
    this.subtitulo = "Actualizar Derivación Clínica";
    this.comentario = item.der_his_doctor;
    this.id_requerimiento = item.der_his_der_id;
    this.searchStr = item.hos_name;
    this.accion = 7;
    this.enable = true;

  }

  eliminarPrescription(item) { 
    //console.log(item);

    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    

    this._fichaMedicaServices.postDeletePrescription(item.pre_his_pre_id)
      .then(result => { 


        Swal.close();
        this.verConsultas();
        Swal.fire({
          icon: 'success',
          title: 'Se eliminó correctamente!.',
          showConfirmButton: false,
          timer: 10000
        })

      })
      .catch(error => { 
          Swal.fire('Error.',
          error.message,  'error'
        );
      })


  }

  eliminarOrder(item) { 
    //console.log(item);

    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    

    this._fichaMedicaServices.postDeleteOrder(item.med_ord_id)
      .then(result => { 


        Swal.close();
        this.verConsultas();
        Swal.fire({
          icon: 'success',
          title: 'Se eliminó correctamente!.',
          showConfirmButton: false,
          timer: 10000
        })

      })
      .catch(error => { 
          Swal.fire('Error.',
          error.message,  'error'
        );
      })

  }

  verPrescription(item) { 
    this.titulo = "Receta Médica";
    this.subtitulo = "Receta Médica";
    this.comentario = item.pre_his_indication;
    this.accion = 3;
    this.id_receta = item.pre_his_pre_typ_id;
    this.id_requerimiento = item.pre_his_pre_id;
    this.enable = false;
    this._fichaMedicaServices.postListarHistorial(item.pre_his_pre_id,'/getListarPrescriptionHistory')
    .then(results => {
      this.historial = results;
      for (let his of this.historial.consulta){
        his.descripcion = his.pre_typ_name+': '+his.pre_his_prescription;
        his.fecha = his.pre_his_date;
        if (his.cau_description) {
          his.motivo = his.cau_description;
        } else { 
          his.motivo = "Sin motivo";
        }
        //console.log(his.pre_typ_name+': '+his.pre_his_prescription);
        
      }
      //console.log(this.historial);

    })
    .catch(error => { 
      Swal.fire({
        icon: 'warning',
        title: 'Error al cargar historial de modificaciones...',
        timer: 2000
      })

    });
  }

  verOrder(item) { 
    this.id_requerimiento = item.med_ord_his_med_ord_id;
    
    if (item.med_ord_his_med_ord_typ_id == 2)
    {
      this.titulo = "Certificado Médico";
      this.subtitulo = "Actualizar Certificado Médico";
      this.comentario = item.med_ord_his_comment;
      this.accion = 4;

    } else if (item.med_ord_his_med_ord_typ_id ==3) { 

      this.titulo = "Orden Médica";
      this.subtitulo = "Actualizar Orden Médica";
      this.comentario = item.med_ord_his_comment;
      this.accion = 2;
      this.id_exam =item.med_ord_his_exa_typ_id;
    } else if (item.med_ord_his_med_ord_typ_id ==1) { 

    this.titulo = "Indicación Médica";
    this.subtitulo = "Actualizar Indicación Médica";
    this.comentario = item.med_ord_his_comment;
    this.accion = 2;
    this.id_exam =item.med_ord_his_exa_typ_id;
  }
    this.enable = false;
    this._fichaMedicaServices.postListarHistorial(item.med_ord_his_med_ord_id,'/getListarOrderHistory')
      .then(results => {
        this.historial = results;
        ////console.log(this.historial);
        for (let his of this.historial.consulta) {
          var des;
          if (his.med_ord_his_med_ord_typ_id ==3) {
            des = his.exa_typ_name+': ';
          } else {
            des = '';
          }
          if (his.cau_description) {
            his.motivo = his.cau_description;
          } else { 
            his.motivo = "Sin motivo";
          }
          his.descripcion = des+his.med_ord_his_comment;
          his.fecha = his.med_ord_his_date;
          ////console.log(his.pre_typ_name+': '+his.pre_his_prescription);
          
        }
        //console.log(this.historial);
        

      })
      .catch(error => { 
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar historial de modificaciones...',
          timer: 2000
        })

      });
  }

  verDiagnostic(item) { 
    this.titulo = "Diágnostico e Indicaciones";
    this.subtitulo = "Actualizar Diágnostico";
    this.comentario = item.diag_det_his_diagnostic_detail;
    this.accion = 1;
    this.id_diagnostico = item.diag_det_his_dia_id;
    this.id_requerimiento = item.diag_det_his_dia_det_id;
    this.enable = false;
    this._fichaMedicaServices.postListarHistorial(item.diag_det_his_dia_det_id,'/getListarDiagnosticHistory')
    .then(results => {
      this.historial = results;
      //console.log(this.historial);
      for (let his of this.historial.consulta) {
        if (his.cau_description) {
          his.motivo = his.cau_description;
        } else { 
          his.motivo = "Sin motivo";
        }
        his.descripcion = his.dia_name+': '+his.diag_det_his_diagnostic_detail;
        his.fecha = his.diag_det_his_date;
        ////console.log(his.pre_typ_name+': '+his.pre_his_prescription);
      }
    })
    .catch(error => { 
      Swal.fire({
        icon: 'warning',
        title: 'Error al cargar historial de modificaciones...',
        timer: 2000
      })

    });
  }

  verAnamnesis(item) {
    this.titulo = "Historia Clínica";
    this.subtitulo = "Actualizar Historia Clínica";
    this.comentario = item.med_con_his_anamnesis;
    this.id_requerimiento=item.med_con_his_med_con_id;
    this.accion = 5;
    this.enable = false;
    this._fichaMedicaServices.postListarHistorial(item.med_con_his_med_con_id,'/getListarAnamnesisHistory')
    .then(results => {
      this.historial = results;
      //console.log(this.historial);
      for (let his of this.historial.consulta) {
        if (his.cau_description) {
          his.motivo = his.cau_description;
        } else { 
          his.motivo = "Sin motivo";
        }
        his.descripcion = his.med_con_his_anamnesis;
        his.fecha = his.med_con_his_date_attention;
        ////console.log(his.pre_typ_name+': '+his.pre_his_prescription);
        
      }

    })
    .catch(error => { 
      Swal.fire({
        icon: 'warning',
        title: 'Error al cargar historial de modificaciones...',
        timer: 2000
      })

    });
  }

  verDerivation(item) {
    // this.id_con_med = item.med_con_id;
    this.titulo = "Derivación Clínica";
    this.subtitulo = "Actualizar Derivación Clínica";
    this.comentario = item.der_his_doctor;
    this.id_requerimiento = item.der_his_der_id;
    this.searchStr = item.hos_name;
    this.accion = 7;
    this.enable = false;
    this._fichaMedicaServices.postListarHistorial(item.der_his_der_id,'/getListarDerivationHistory')
    .then(results => {
      this.historial = results;
      //console.log(this.historial);
      for (let his of this.historial.consulta) {
        if (his.cau_description) {
          his.motivo = his.cau_description;
        } else { 
          his.motivo = "Sin motivo";
        }
        his.descripcion = his.hos_name+': '+his.der_his_doctor;
        his.fecha = his.der_his_date;
        ////console.log(his.pre_typ_name+': '+his.pre_his_prescription);
        
      }

    })
    .catch(error => { 
      Swal.fire({
        icon: 'warning',
        title: 'Error al cargar historial de modificaciones...',
        timer: 2000
      })

    });
  }

  irChat(item) { 

    //console.log(item);

    var url = "chat/"+item.med_con_id;
    var resta_minutos = this.obtenerResta(item.hora);
    var fecha_sistema = new Date();
    var fecha_anterior = new Date();
    fecha_anterior.setDate(fecha_anterior.getDate() - 1);
    
    let fecha_ayer = formatDate(fecha_anterior, 'yyyy-MM-dd', 'en-US');
    
    const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');
    
    const fecha_consulta = formatDate(item.fecha_consulta.substring(0, 10), 'yyyy-MM-dd', 'en-US');
    
    //console.log(fecha);
    //console.log(fecha_consulta);
    //console.log(fecha_ayer);
    //console.log(resta_minutos);

    
    if (fecha == fecha_consulta) {
      this.router.navigate([url]);
    }
    else if (fecha_ayer == fecha_consulta && resta_minutos > 0) {
      this.router.navigate([url]);
    } else { 

      Swal.fire({
        icon: 'info',
        title: 'Fuera de horario.',
        text: 'Ya no puede acceder al chat de la consulta...',
        timer: 3000
      })

    }


  }

  crearOrden(f: NgForm) {
    var comentario = f.value.comentario;
    var diagnostico = f.value.id_diagnostico;
    var examen = f.value.id_exam;
    var receta = f.value.id_receta;
    var causal = f.value.id_causal;

    if (comentario.length > 3 && causal) {
      
      if (this.accion == 1 && diagnostico) {//Diagnostico

        let params = {
          causal: causal,
          diag_det_his_dia_det_id:this.id_requerimiento,
          dia_det_med_con_id:this.id_con_med,
          dia_det_dia_id:diagnostico,
          dia_det_diagnostic_detail: comentario,
        }
        
        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();
        
        this._fichaMedicaServices.postCreateOrder(params,'/postCreateDiagnostic')
          .then(results => {

            this.titulo="Seleccione Opción en Barra Lateral";
            this.subtitulo="Seleccione Opción en Barra Lateral";
            this.comentario="";
            this.accion = 0;

            Swal.close();
            this.verConsultas();
            Swal.fire({
              icon: 'success',
              title: 'Se modificó correctamente!.',
              showConfirmButton: false,
              timer: 10000
            })

            
                
          })
          .catch(error => { 
            Swal.fire('Error.',
              error.message,  'error'
            );
          });
      
      } else if (this.accion == 2 && examen) {//Examen
       
        let params = {
          causal: causal,
          med_ord_exa_typ_id:examen,
          id_tel_medical_order:this.id_requerimiento,
          med_ord_med_ord_typ_id: 3,
          med_ord_comment: comentario,
          med_ord_tel_med_con_id:this.id_con_med
        }
        
        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();
        
        this._fichaMedicaServices.postCreateOrder(params,'/postCreateOrder')
          .then(results => {

            this.titulo="Seleccione Opción en Barra Lateral";
            this.subtitulo="Seleccione Opción en Barra Lateral";
            this.comentario="";
            this.accion = 0;

            Swal.close();
            this.verConsultas();
            Swal.fire({
              icon: 'success',
              title: 'Se modificó correctamente!.',
              showConfirmButton: false,
              timer: 10000
            })
            
              
          })
          .catch(error => { 
            Swal.fire('Error.',
              error.message,  'error'
            );
          });
    
      } else if (this.accion == 3 && receta) {//Receta

        let params = {
          causal: causal,
          pre_pre_typ_id:receta,
          pre_his_pre_id:this.id_requerimiento,
          pre_prescription:comentario,
          pre_indication: comentario,
          pre_med_con_id:this.id_con_med
        }

        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();
        this._fichaMedicaServices.postCreateOrder(params,'/postCreatePrescription')
          .then(results => {

            this.titulo="Seleccione Opción en Barra Lateral";
            this.subtitulo="Seleccione Opción en Barra Lateral";
            this.comentario="";
            this.accion = 0;

            Swal.close();
            this.verConsultas();
            Swal.fire({
              icon: 'success',
              title: 'Se modificó correctamente!.',
              showConfirmButton: false,
              timer: 10000
            })
            
                
          })
          .catch(error => { 
            Swal.fire('Error.',
              error.message,  'error'
            );
            
          });
        
      } else if (this.accion == 4) {//Certificado

        let params = {
          causal: causal,
          med_ord_exa_typ_id:"",
          id_tel_medical_order:this.id_requerimiento,
          med_ord_med_ord_typ_id: 2,
          med_ord_comment: comentario,
          med_ord_tel_med_con_id:this.id_con_med
        }
        // // //console.log(params);
        
        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();
        
        this._fichaMedicaServices.postCreateOrder(params,'/postCreateOrder')
          .then(results => {

            this.titulo="Seleccione Opción en Barra Lateral";
            this.subtitulo="Seleccione Opción en Barra Lateral";
            this.comentario="";
            this.accion = 0;
            Swal.close();
            this.verConsultas();
            Swal.fire({
              icon: 'success',
              title: 'Se modificó correctamente!.',
              showConfirmButton: false,
              timer: 10000
            })
            
                
          })
          .catch(error => { 
            Swal.fire('Error.',
              error.message,  'error'
            );
          });

      } else if (this.accion == 5) {//Carta Paciente


        let params = {
          causal: causal,
          med_con_anamnesis: comentario,
          med_con_id:this.id_requerimiento
        }

        
        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();
        
        this._fichaMedicaServices.postCreateOrder(params,'/postUpdateMedicalAnamnesis')
          .then(results => {

            this.titulo="Seleccione Opción en Barra Lateral";
            this.subtitulo="Seleccione Opción en Barra Lateral";
            this.comentario="";
            this.accion = 0;
            Swal.close();
            this.verConsultas();
            Swal.fire({
              icon: 'success',
              title: 'Se modificó correctamente!.',
              showConfirmButton: false,
              timer: 10000
            })
            
            
            })
            .catch(error => { 
              Swal.fire('Error.',
                error.message,  'error'
              );
            });

      } else if (this.accion == 6) {//Archivos,, todavía no ocupado

        let params = {   }

      } else if (this.accion == 7 && this.clinica_selected.hos_id) {//Derivar Paciente

        let params = {
         med_ord_tel_med_con_id: this.id_con_med,
          der_hos_id:this.clinica_selected.hos_id,
          der_doctor: this.comentario,
          der_id:this.id_requerimiento
        }
        //console.log(params);
        
        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();
        
        this._fichaMedicaServices.postCreateOrder(params,'/postCreateDerivation').then(results => {

          this.titulo="Seleccione Opción en Barra Lateral";
          this.subtitulo="Seleccione Opción en Barra Lateral";
          this.comentario="";
          this.accion = 0;
          this.verConsultas();
          Swal.close();
      
          Swal.fire({
            icon: 'success',
            title: 'Se modificó correctamente!.',
            showConfirmButton: false,
            timer: 10000
          })
              
              }).catch(error => { 
                Swal.fire('Error.',
                error.message,  'error'
              );
                
              });

      }
    } else { 

      Swal.fire({
        icon: 'info',
        title: 'Campos Incompletos',
        text: 'Por favor complete los campos del formulario.',
        timer: 5000
      })

    }
  }

  changeClinica($event) {
    if ($event){
      this.clinica_selected = $event.originalObject;
    }
    //console.log(this.clinica_selected);
    
  } 

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }
  
  async descargarPrescription(item) { 
    this.SoloVerPrescription(item);
    await this.sleep(1000);
    this.generarPdf();
  }

  async descargarOrder(item) { 
    this.SoloVerOrder(item);
    await this.sleep(1000);
    this.generarPdf();
  }

  async descargarDiagnostic(item) { 
    this.SoloVerDiagnostic(item);
    await this.sleep(1000);
    this.generarPdf();
  }

  async descargarAnamnesis(item) { 
    this.SoloVerAnamnesis(item);
    await this.sleep(1000);
    this.generarPdf();
  }

  async descargarDerivation(item) { 
    this.SoloVerDerivation(item);
    await this.sleep(1000);
    this.generarPdf();
  }
  generarPdf() { 
    let data = document.getElementById('pdf');  
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')  
       let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); //Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);  
      pdf.save('Documento.pdf');   
    }); 

  }
  
}
