<div class="row justify-content-center align-items-center text-center pt-4 pt-sm-0 pb-4 pb-sm-0 login-footer">
    <div class="col-sm-4 d-none d-sm-block pr-4 contact">
        <p class="text-sm-right">CONTÁCTANOS</p>
    </div>
    <div class="col-12 col-sm-4 col-lg-2 pr-0 mr-0 borders">
        <p class="title d-block d-sm-none text-center">CONTÁCTANOS</p>
        <p class="title text-center text-sm-left">Mesa de ayuda</p>
        <div class="data deskhelp">
            <img class="align-self-start" src="assets/images/icon/phone.svg" alt="phone logo">
            <div class="m-0 p-0 ml-sm-3">
                <p *ngIf="pageInfo.cus_url_phone" class="text-center text-sm-left">{{pageInfo.cus_url_phone}}</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-4 data mt-4 mt-sm-0">
        <img class="d-block d-sm-none" src="assets/images/icon/mail.svg" alt="envelope logo">
        <p class="text-center text-sm-left">Correo electrónico</p>
        <div class="m-0 p-0 email-data">
            <img class="d-none d-sm-block" src="assets/images/icon/mail.svg" alt="envelope logo">
            <p class="text-center text-sm-left ml-sm-3">contacto@almamedis.cl</p>
        </div>
    </div>
</div>