import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent implements OnInit {
  @ViewChild('trigPopover') trigPopover: ElementRef;
  @ViewChild('trigPopoverIcon') trigPopoverIcon: ElementRef;
  @ViewChild('popoverC') popoverC: ElementRef;
  public isActive: boolean = false;

  constructor() {}

  ngOnInit(): void {
  }

  toggle = () => {
    this.isActive = !this.isActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.trigPopover.nativeElement.contains(event.target)) {
      this.isActive = false;
    }
  }
}
