<div style="text-align: center">
  <button type="button" class="subirbtn" style="width: auto" (click)="btnListarEspecialidades()">
    Listar Especialidades
  </button>
  <button type="button" class="subirbtn" style="width: auto" (click)="btnListarEspecialistas()">
    Listar Especialistas
  </button>

  <!-- <button type="button" class="subirbtn" style="width: auto;" (click)="btnVer()">
    Ver Médicos
  </button> -->
</div>

<br />

<!-- LISTAR ESPECIALIDADES-->

<div class="card col-12" *ngIf="card.especilidades">
  <div class="subTitle2">Especialidades</div>
  <br />
  <div class="container" style="margin-top: 20px">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col-3">Nombre</th>
            <th scope="col-3">Tarifa</th>
            <th scope="col-3">Duracion Consulta</th>
            <th scope="col-3">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let especialidad of especialidades">
            <th scope="row">{{especialidad.spe_name}}</th>
            <td>${{especialidad.sub_spe_pri_price | puntoMiles}}</td>
            <td>30 minutos</td>
            <td>
              <button class="buttonModificar" (click)="updateSubEspecialidad(especialidad)" data-toggle="modal"
                tabindex="-1" data-target="#showEspecialidadModal">
                <p class="textButton">Modificar</p>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!------------------ Modal desplegable ESPECIALIDADES--------------->

<div class="modal fade" id="showEspecialidadModal" aria-labelledby="showEspecialidadModal" aria-hidden="true"
  tabindex="-1">

  <div class="modal-dialog modalShowEspecialidades">
    <div class="row">
      <div class="col-12" style="align-self: center">
        <p class="modalTitle">Modificar Tarifa</p>
      </div>
    </div>
    <br /><br />

    <div class="subTitle">{{ infoSubEsp.spe_name }}</div>
    <br />

    <div class="row cardModalTable">
      <div class=" col-3 divLeftModal">
        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Tarifa Actual</label>
      </div>
      <div class="col-4 divRightModal">
        <p class="modalTableSubTitle pDivModalContent">${{infoSubEsp.sub_spe_pri_price | puntoMiles}}</p>
      </div>
    </div>
    <div class="row cardModalTable">
      <div class="col-3 divLeftModal">
        <label for="duracionActual" class="row-md-4 col-form-label colorletra">Duración Actual</label>
      </div>
      <div class="col-4 divRightModal">
        <p class="modalTableSubTitle pDivModalContent">30 minutos</p>
      </div>
    </div>

    <div class="row cardModalTable">
      <div class="col-3 divLeftModal">
        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Nueva Tarifa</label>
      </div>

      <div class="form-group col-6 divRightModal">
        <input class="form-control form-control-static nuevaTarifa" type="number" [(ngModel)]="infoSubEsp.newPrice"
          placeholder="Ingrese nueva tarifa" />
      </div>

      <br /><br />

    </div>

    <div class="row cardModalTable">
      <div class="col-3 divLeftModal">
        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Nueva Duración</label>
      </div>

      <div class="form-group col-6 divRightModal">
        <input class="form-control form-control-static nuevaDuracion" type="number" placeholder="Ingrese Nueva Duracion"
          disable />
      </div>
    </div>



    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <button type="button" class="botonVolver colorLetraVolver" data-dismiss="modal" data-toggle="modal"
            data-target="">
            Volver atras
          </button>
        </div>
        <div class="col-md-6 letrabotonagregar">
          <button type="button" class="btn btn-primary botonModalModificar" data-dismiss="modal" data-toggle="modal"
            data-target="#aceptaModificarTarifa">
            Modificar Tarifa
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL DE CONFIRMACION-->

<div class="modal fade" id="aceptaModificarTarifa" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row ">
          <div class="row-md-12">
            <p class="tituloModal">
              ¿Estás seguro que deseas guardar estos cambios?
            </p>
            <p class="tituloModal"></p>
          </div>
        </div>
        <br /><br />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class=" botonVolver colorLetraVolver" data-dismiss="modal" data-toggle="modal"
                data-target="#showEspecialidadModal">
                Volver atras
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="button" (click)="confirmUpdatePriceSubSpe()" class="btn btn-primary botonModalModificar"
                data-dismiss="modal" data-toggle="modal">
                Modificar tarifa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--------------------------------------ESPECIALISTAS------------------------------------------------>

<!-- LISTAR ESPECIALISTAS-->

<div class="card col-12" *ngIf="card.especialistas">
  <div class="subTitle2">Especialistas</div>
  <br />
  <div class="container" style="margin-top: 20px">
    <div class="table-responsive">
      <table class="table table-hover col-12">
        <thead>
          <tr>
            <th scope="col-4">Nombres</th>
            <th scope="col-4">Apellidos</th>
            <th scope="col-4">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let especialista of especialistas">
            <th scope="row">{{ especialista.use_name }}</th>
            <th scope="row">{{ especialista.use_paternal_surname }}</th>
            <td>
              <button type="button" class="buttonModificar" (click)="btnVerEspecialidades(especialista)"
                data-toggle="modal" tabindex="-1">
                <p class="textButton">Ver Especialidades</p>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-------------------------- Especialidades de  ESPECIALISTA---------------------->

<div *ngIf="card.especialidadesDoc">
  <ng-container>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 cardTarifa">
      <div class="row align-items-center ">
        <div class="col-4 col-sm-2 ml-sm-4 pl-sm-4 pt-sm-4 pb-sm-4 pr-sm-0 ml-3">
          <ng-container *ngIf="especialista_selected.att_path">
            <img style="border-radius: 50%;" [src]="URL_DOCS+especialista_selected.att_path" alt="auto" width="60%"
              class="centeredElement"/>
          </ng-container>
          <ng-container *ngIf="!especialista_selected.att_path">
            <img style="border-radius: 50%;" src="../../../../assets/images/no-image.png" alt="auto" width="60%"
              class="centeredElement" />
          </ng-container>

        </div>
        <div class="col-5 col-sm-3 pl-sm-0 pl-0">
          <h3 class="nombretitular">
            {{especialista_selected.use_name}} {{especialista_selected.use_paternal_surname}}
          </h3>
          <p class="nombreTitular">{{ especialista_selected.rut }}</p>
        </div>
      </div>
    </div>

  </ng-container>

  <br />

  <div class="card col-12" *ngIf="especialista_selected">
    <div class="subTitle2">Especialidades</div>

    <div class="container" style="margin-top: 20px">
      <div class="table-responsive">
        <table class="table table-hover col-12">
          <thead>
            <tr>
              <th scope="col-4">Nombre</th>
              <th scope="col-4">Tarifa</th>
              <th scope="col-4">Duracion consulta</th>
              <th scope="col-4">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let especialidadDoctor of especialista_selected.specialties">
              <th scope="row">
                {{ especialidadDoctor.spe_name }}
              </th>
              <td *ngIf="especialidadDoctor.pri_doc_price > 0">${{especialidadDoctor.pri_doc_price | puntoMiles}}</td>
              <td *ngIf="especialidadDoctor.pri_doc_price == 0">${{especialidadDoctor.sub_spe_pri_price | puntoMiles}}
              </td>
              <td>30 minutos</td>
              <td>
                <button class="buttonModificar" (click)="updateDoctorEspecialidad(especialidadDoctor)"
                  data-toggle="modal" tabindex="-1" data-target="#showEspecialistaModal">
                  <p class="textButton">Modificar tarifa</p>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!------ Modal desplegable especialidadDoctor seleccionada-->

<div class="form-group modal fade" id="showEspecialistaModal" aria-labelledby="showEspecialistaModal" aria-hidden="true"
  tabindex="-1">
  <div class="form-group modal-dialog modalShowTarifa">
    <div class="row">
      <div class="col-12" style="align-self: center">
        <p class="modalTitle">Modificar Tarifa</p>
      </div>
    </div>

    <ng-container>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 cardTarifa">
        <div class="row align-items-center">
          <div class="col-4 col-sm-2 ml-sm-4 pl-sm-4 pt-sm-4 pb-sm-4 pr-sm-0 ml-3">
            <ng-container *ngIf="especialista_selected.att_path">
              <img style="border-radius: 50%;" [src]="URL_DOCS+especialista_selected.att_path" alt="auto" width="50%"
                class="centeredElement" />
            </ng-container>
            <ng-container *ngIf="!especialista_selected.att_path">
              <img style="border-radius: 50%;" src="../../../../assets/images/no-image.png" alt="auto" width="50%"
                class="centeredElement"/>
            </ng-container>
          </div>
          <div class="col-5 col-sm-3 pl-sm-0 pl-0">
            <h3 class="nombretitular">
              {{especialista_selected.use_name}} {{especialista_selected.use_paternal_surname}}
            </h3>
            <p class="nombreTitular">{{especialidadDoctor_selected.rut}}</p>
          </div>
        </div>
      </div>
    </ng-container>

    <br>



    <div *ngIf="infoPost.spe_name" class="subTitle">{{ infoPost.spe_name}}</div>
    <br /><br />

    <div class="row cardModalTable">
      <div class="col-3 divLeftModal">
        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Tarifa Actual</label>
      </div>
      <div class="col-4 divRightModal">
        <!-- <p class="modalTableSubTitle pDivModalContent">"{{infoPost.price | currency:'CLP'}}"</p> -->
        <p *ngIf="infoPost.pri_doc_price > 0">${{infoPost.pri_doc_price | puntoMiles}}</p>
        <p *ngIf="infoPost.pri_doc_price == 0">${{infoPost.sub_spe_pri_price | puntoMiles}}</p>
      </div>

      <br /><br />
    </div>


    <div class="row cardModalTable">
      <div class="col-3 divLeftModal">
        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Nueva Tarifa</label>
      </div>

      <div class="form-group col-6 divRightModal">
        <input class="form-control form-control-static nuevaTarifa" type="number" [(ngModel)]="infoPost.newPrice"
          placeholder="Ingrese nueva tarifa" />
      </div>
    </div>
    <br>

    <div class="container">
      <div class="form-check">
        <input mdbCheckbox class="form-check-input" type="checkbox" [(ngModel)]="infoPost.can_udp_price"
          id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
          Puede configurar su propia tarifa
        </label>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <button type="button" class="botonVolver colorLetraVolver" data-dismiss="modal" data-toggle="modal"
            data-target="">
            Volver atras
          </button>
        </div>
        <div class="col-md-6 letrabotonagregar">
          <button type="submit" class="btn btn-primary botonModalModificar" data-dismiss="modal" data-toggle="modal"
            data-target="#aceptaModificarTarifaDoc">
            Modificar Tarifa
          </button>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- MODAL DE CONFIRMACION  Especialidad Doctor-->

<div class="modal fade" id="aceptaModificarTarifaDoc" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row">
          <div class="col-md-12">
            <p class="tituloModal">
              ¿Estás seguro que desea guardar estos cambios?
            </p>
            <p class="tituloModal"></p>
          </div>
        </div>
        <br /><br />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal"
                data-toggle="modal" data-target="#showEspecialistaModal">
                Volver atras
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="button" (click)="confirmUpdateDoctorPriceSubSpe()"
                class="btn btn-primary botonModalModificar" data-dismiss="modal" data-toggle="modal">
                Modificar tarifa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!----- Modal cambios guardados-->