<!-- PACIENTE -->
<div class="card h-100 p-0 m-0 border-confirm">
  <!-- <div class="row card-block px-2 px-sm-3 pt-2 pt-sm-3" style="margin:10px;">
    <div style="width: 100%;" class="m-0 p-0 first-line">
      <app-profile-img 
        [img]="data.consulta.att_path" 
        [doctorName]="data.doctorName" 
        [doctorLastName]="data.doctorLastName"
        [color]="data.goLobbyFlag ? 'goLobby' : data.confirmFlag ? 'confirm' : 'default'">
      </app-profile-img>
      <div class="ml-3 align-self-center">
        <p class="card-text m-0 p-0 appt-date">{{ data.date[0] }} {{ data.date[1] }}</p>
        <p class="card-text pt-3 mr-5 doc-data">{{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }} </p>
      </div>
    </div>
    <br><br>
    <div style="width: 100%;" class="row col-md-12 align-items-end justify-content-end">
      <div class="col-md-6">
        <div class="col-md-6 col-xs-6 align-self-center" style="margin-left: 30px; align-items: center; size: 20px;">
          <p *ngIf="data.consulta.tel_doc_app_boo_sta_id == 39" class="card-text flag-helper"><li>Finalizada</li></p>
          <p *ngIf="data.consulta.tel_doc_app_boo_sta_id == 30" class="card-text flag-helper"><li>No Finalizada</li></p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-6 col-xs-6 align-self-center botones">
          <button style="float:right;"
            type="button"
            (click)="verDetalle()"
            class="btn btn-secondary btn-lg">
              Ver detalle
          </button>
        </div>
      </div>
    </div>
  </div> -->
    <div class="row card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
      <div class="col-md-7 col-xs-12 m-0 p-0 c-0 first-line">
          <app-profile-img [img]="data.consulta.att_path" style="margin-left: 10pximportant!;">
          </app-profile-img>
          <div class="ml-3 align-self-center">
            <p class="card-text m-0 p-0 appt-date">{{ data.date[0] }} {{ data.date[1] }}</p>
            <p class="card-text pt-3 mr-5 doc-data">{{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }} </p>
          </div>
      </div>
      <div class="col-md-5 col-xs-12">
          <div class="row">
              <div class="col-xs-12 ml-auto align-self-center" style="margin-right: 15px;">
                  <p *ngIf="data.consulta.tel_doc_app_boo_sta_id == 39" class="card-text flag-helper appt-gris">*Finalizada</p>
                  <p *ngIf="data.consulta.tel_doc_app_boo_sta_id == 44" class="card-text flag-helper appt-gris">*Paciente no se presenta</p>
                  <p *ngIf="(data.consulta.tel_doc_app_boo_sta_id ==30 || data.consulta.tel_doc_app_boo_sta_id ==10)" class="card-text flag-helper appt-gris">*No Finalizada</p>
              </div>
              <div class="col-xs-12 ml-auto-boton align-items-end justify-content-end flex-wrap" style="float: right; margin-right: 10px;">
                  <button type="button" class="btn btn-primary mr-auto"
                  (click)="verDetalle()">
                      Ver detalle
                  </button>
              </div>
          </div>
      </div>
      <br>
  </div>
    <!-- <p class="card-text pt-3 mr-5 doc-data">{{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }}  </p> -->
    <!-- <div class="d-flex align-items-end justify-content-end flex-wrap">
      <button *ngIf="data.confirmedFlag || data.goLobbyFlag" type="button" class="btn btn-primary mr-auto"
        [disabled]="!data.goLobbyFlag" (click)="goLobby()">
        Ir a Sala de Espera
      </button>
      <button *ngIf="(data.confirmFlag || !data.confirmedFlag) && !data.goLobbyFlag" 
        type="button"
        data-toggle="modal" [attr.data-target]="'#confirmarAppoModal' + data.id_consulta"
        class="btn btn-secondary mr-auto" [disabled]="!data.confirmFlag">
        Confirmar asistencia
      </button>
      <p class="card-text flag-helper">{{ data.confirmedFlag ? 'Confirmada' : 'Reservada' }}</p>
    </div> -->
</div>

