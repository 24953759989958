import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import { GestionService } from 'src/app/services/gestion.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';


@Component({
  selector: 'app-activacion',
  templateUrl: './activacion.component.html',
  styleUrls: ['./activacion.component.css']
})
export class ActivacionComponent implements OnInit {

  _title = 'Gestión';
  _subtitle = 'Activaciones'

  f_desde: any = '';
  f_hasta: any = '';
  use_rut: any = '';

  public input_email: any = '';

  public mails: any = [];
  public dominios: any = [];
  public evaluaciones: any = [];

  public activaciones: any = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  constructor(public customerService: CustomerService,
    public generalService: GeneralService,
    public gestionService: GestionService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'cus_id',
      textField: 'cus_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.postListarCustomer();
    this.authorizedDomains();

  }

  postListarCustomer() {
    this.customerService.postListarCustomer({})
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-postListarCustomer');
          this.dropdownList = data;
        }
      })
      .catch(e => console.error(e));
  }
  
  authorizedDomains(){
    this.gestionService.authorizedDomains()
      .then((data: any) => {
        // console.log(data, 'dominios autorizados');
        this.dominios = data        
      })
      .catch(e => console.error(e));

  }


  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  listarDatos() {

  }

  ExportarExcel() {
    //console.log('Exportar');
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    if (this.activaciones.length == 0) {
      Swal.fire({
        icon: 'info',
        text: 'Sin datos disponibles',
        timer: 2000
      })
    } else {
      Swal.close();
      this.downloadFileExcel(this.activaciones);
    }
  }

  ExportarCSV(){
    //console.log('Exportar');
    Swal.fire({
     icon: 'info',
     title: 'Espere por favor...',
     showConfirmButton: false,
     allowOutsideClick: false,
     timer: 10000
   })
   Swal.showLoading();
   if (this.activaciones.length == 0) {
     Swal.fire({
       icon: 'info',
       text: 'Sin datos disponibles',
       timer: 2000
     })
   } else {
     Swal.close();
     this.downloadFileCSV(this.activaciones);
   }
 }

  modalError(titulo, detalle) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: detalle,
      timer: 5000
    })
  }

  
  async actualizarData() {
    while (true) {
      this.Listar();
      await this.generalService.sleep(1000 * 60 * 5);
    }
  }

  ListarLoading() {
    this.generalService.modalCargando();
    this.Listar();
  }
  Listar() {
    // //console.log('Listar');
    let fecha_desde_post = null;
    let fecha_hasta_post = null;
    let rut = null;
    let email = []

    try {
      
      if(!this.generalService.validaRut(this.use_rut)){
        fecha_desde_post = this.validarFecha(this.f_desde);
        fecha_hasta_post = this.validarFecha(this.f_hasta);
        rut = null;
        email = null
      }else{
        fecha_desde_post = this.validarFecha(this.f_desde);
        fecha_hasta_post = this.validarFecha(this.f_hasta);
        rut = this.use_rut;
        email = null
      }
    } catch (error) {
      // console.log(error);
      this.modalError('Formato fecha incorrectos', 'Por favor escribir una fecha valida.');
      return;
    }
    // console.log(fecha_desde_post);
    // console.log(fecha_hasta_post);
    // console.log(rut);

    this.gestionService.activatedUsers(email, rut, this.arrayStringCustomer(this.selectedItems), fecha_desde_post, fecha_hasta_post)
      .then((data: any) => {
        this.activaciones = data
        this.activaciones.reverse();
        // console.log(this.activaciones, 'horas filtradas');
        Swal.close();
      })
      .catch(error => {
        // console.log(error);
      });
  }

  validarFecha(fecha) {
    if (fecha.length > 0) {
      let array_fecha = fecha.split('/');
      array_fecha[0] = array_fecha[0].padStart(2, '0');
      array_fecha[1] = array_fecha[1].padStart(2, '0');
      array_fecha[2] = array_fecha[2].padStart(4, '20');
      // //console.log(array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0]);
      let nueva_fecha = new Date(array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0]);
      if (nueva_fecha.toString() != 'Invalid Date') {
        return array_fecha[2] + array_fecha[1] + array_fecha[0];
      } else {
        throw new Error("Fecha Invalida");
      }
    }
    return '';
  }



  changeRut() {
    var rut_Cliente: any = this.use_rut;
    var valor = rut_Cliente;
    // //console.log(this.rut)
    let resultado = valor.split('.').join('');
    let resultado2 = resultado.split('-').join('');
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    if (valor != "") {
      this.use_rut = cuerpo + "-" + dv;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
    }
    var dvEsperado
    dvEsperado = 11 - (suma % 11);
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;
    if (dvEsperado != dv) {
      // console.log("changeRut invalido");
    }
    else {
      // console.log("changeRut valido");
    }
  }


  downloadFileExcel(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // mapear valores nulos
    const header = Object.keys(data[0]);
    
    // Mapear fechas en el formato correcto
    const mappedData = data.map(row => {
      const mappedRow = {};
      for (const fieldName of header) {
        if (fieldName === 'fecha') { // Supongamos que la columna de fechas se llama 'fecha'
          mappedRow[fieldName] = format(new Date(row[fieldName]), 'yyyy-MM-dd'); // Formatear la fecha en el formato deseado
        } else {
          mappedRow[fieldName] = row[fieldName];
        }
      }
      return mappedRow;
    });
    
    const worksheet = XLSX.utils.json_to_sheet(mappedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, "activaciones.xlsx");
  }

  arrayStringCustomer(arrayStatusCustomer) {
    let textArray = [];
    for (let index = 0; index < arrayStatusCustomer.length; index++) {
      textArray.push(arrayStatusCustomer[index].cus_id);
    }
    return (arrayStatusCustomer.length > 0 ? textArray : null);
  }
  
  downloadFileCSV(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // majear valore nulos
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob(["\ufeff" + csvArray], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "activaciones.csv");
  }

  
  

  emailLoading() {
    let emails = null;
    this.input_email = this.input_email.replaceAll('\t', '');
    this.input_email = this.input_email.replaceAll('\n', '');
    this.input_email = this.input_email.replaceAll(' ', '');
    this.input_email = this.input_email.replaceAll('-', '');
    emails = this.input_email.split(',');
    // console.log(emails, 'array emails');
  
    let isValidEmails = true; // Variable para seguir el estado de validez de todos los correos
  
    for (let i = 0; i < emails.length; i++) {
      let element = emails[i];
      if (this.generalService.validaEmail(element)) {
        let emailParts = element.split('@');
        let username = emailParts[0];
        let domain = emailParts[1];
        let isValidDomain = false;
        for (let j = 0; j < this.dominios.length; j++) {
          const dominio = this.dominios[j];
          if (domain === dominio.aut_dom_domain) {
            let mail = username + '@' + domain;
            // console.log(mail, 'correo válido');
            this.mails.push(mail);
            isValidDomain = true;
            this.sendEmail();
            break;
          }
        }
        if (!isValidDomain) {
          isValidEmails = false; // Al menos un dominio es inválido
          break;
        }
      } else {
        isValidEmails = false; // Al menos un correo es inválido
        break;
      }
    }
  
    if (isValidEmails) {
      this.generalService.modalExitoso('Se ha enviado el informe a los correos seleccionados', '');
    } else {
      this.generalService.modalError('Uno o mas de los correos ingresados no corresponde a los dominios autorizados', 'Se enviara la informacion a los correos autorizados');
    }
  
    // console.log(this.mails, 'correos correctos');
    this.ngOnInit();
  }
  

  sendEmail(){
    let fecha_desde_post = null;
    let fecha_hasta_post = null;
    let rut = null;
    let email = [];
    try {
      if(!this.generalService.validaRut(this.use_rut)){
        fecha_desde_post = this.validarFecha(this.f_desde);
        fecha_hasta_post = this.validarFecha(this.f_hasta);
        rut = null;
        email = this.mails

      }else{
        fecha_desde_post = this.validarFecha(this.f_desde);
        fecha_hasta_post = this.validarFecha(this.f_hasta);
        rut = this.use_rut;
        email = this.mails

      }
    } catch (error) {
      // console.log(error);
      this.modalError('Formato fecha incorrectos', 'Por favor escribir una fecha valida.');
      return;
    }
    this.gestionService.activatedUsers(email, rut, this.arrayStringCustomer(this.selectedItems), fecha_desde_post, fecha_hasta_post)
    .then((data: any) => {
      this.evaluaciones = data
      this.evaluaciones.reverse();
      // console.log(this.evaluaciones, 'horas filtradas');
      Swal.close();
    })
    .catch(error => {
      // console.log(error);
    });
  }

}
