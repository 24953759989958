<div class="row" style="width: 100%;">
    <div class="col-md-12">
        <br>
        <div class="titulo">
            Solicitudes de generación de licencias
        </div>
        <br>
    </div>
    <div class="col-md-12">
        <p class="subtituloLicencia">Solicitudes pendientes</p>
        <div class="row">
            <div class="col-md-5">
                <input type="text" name="info" id="info" placeholder='Numero de consulta, paciente, médico…' class="estiloInput" [(ngModel)]="info_input_uno">

            </div>
            <div class="col-md-5">
                <table style="width: 100%;">
                    <td><input type="text" name="f_desde" id="f_desde" [(ngModel)]="f_desde_uno" placeholder='Fecha desde dd/mm/aaaa' class="estiloInput"></td>
                    <td><input type="text" name="f_hasta" id="f_hasta" [(ngModel)]="f_hasta_uno" placeholder='Fecha hasta dd/mm/aaaa' class="estiloInput"></td>
                </table>
            </div>
            <div class="col-md-2">
                <button type="buton" (click)="listarUno()" class="btn btn-outline-primary botonListar">Listar</button>
            </div>
        </div>
    </div>
    <div class="row" style="width: 100%;">
        <div class="card col-md-12">
            <div class="card-body bordeCardBeneficiario">
                <div class="flex-row comment-row" >
                        <div style="overflow-x:auto;">
                                <table class="table">
                                    <thead style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 16px; color: #171994;align-items: center; text-align: center;background-color:#3166d84a;">
                                        <tr>
                                            <th>Número consulta </th>
                                            <th>Fecha</th>
                                            <th>Hora</th>
                                            <th>Paciente</th>
                                            <th>Médico tratante</th>
                                            <th>Médico licencia</th>
                                            <th>Fecha y hora generación licencia</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-family: Mulish; font-size: 13px;color: #3F3F3F; align-items: center; text-align: center;">
                                            <tr *ngFor="let item of licenciasNoFinalizadas" ngDefaultControl >
                                                <td>{{item.med_lea_app_boo_id}}</td>
                                                <td>{{item.tel_doc_app_appointment_date | date:'dd-MM-yyyy'}}</td>
                                                <td>{{item.tel_doc_app_appointment_hour}}</td>
                                                <td>{{item.beneficiario.nombre_completo}}</td>
                                                <td>{{item.doctor_consulta.nombre_completo}}</td>
                                                <td *ngIf="item.doctor_asignado">{{item.doctor_asignado.nombre_completo}}</td>
                                                <td *ngIf="!item.doctor_asignado">Por asignar</td>
                                                <td *ngIf="item.doctor_asignado">{{item.fecha_asignacion| date:'dd/MM/yyyy'}} {{item.hora_asignacion}}</td>
                                                <td *ngIf="!item.doctor_asignado">Por asignar</td>
                                                <td>
                                                    <mat-icon *ngIf="(usuario.typ_id == 8)" class="iconos" (click)='anular(item)' style="font-size: 18px;color: #316AD8;">description</mat-icon>
                                                    <img *ngIf="(usuario.typ_id == 7) && !item.doctor_asignado" src="../../../assets/images/liciencia-icons/icono_asignacion.png" (click)='asignar(item)'  data-toggle="modal" data-target="#asignarLicencia" class="iconos" alt="icono asignacion" style=" cursor:pointer">
                                                    <img *ngIf="(usuario.typ_id == 7 || usuario.typ_id == 8) && item.doctor_asignado" src="../../../assets/images/liciencia-icons/icono_reasignacion.png" (click)='reasignar(item)'  data-toggle="modal" data-target="#asignarLicencia" class="iconos" alt="icono reasignacion" style=" cursor:pointer">
                                                    <button *ngIf="(usuario.typ_id == 1)" class="btn btn-outline-primary botonListar" type="buton" (click)='generar(item)'  data-toggle="modal" data-target="#generarLicencia">Generar licencia</button>
                                                    <mat-icon *ngIf="(usuario.typ_id == 8)" class=" iconos" (click)='ver(item)' style="font-size: 18px;color: #316AD8;"  data-toggle="modal" data-target="#verLicencia">search</mat-icon>
                                                    
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="row" style="width: 100%;">
    <div class="col-md-12">
        <p class="subtituloLicencia">Solicitudes finalizadas</p>
        <div class="row">
            <div class="col-md-5">
                <input type="text" name="info" id="info" placeholder='Numero de consulta, paciente, médico…' class="estiloInput" [(ngModel)]="info_input_dos">

            </div>
            <div class="col-md-5">
                <table style="width: 100%;">
                    <td><input type="text" name="f_desde" id="f_desde" [(ngModel)]="f_desde_dos" placeholder='Fecha desde dd/mm/aaaa' class="estiloInput"></td>
                    <td><input type="text" name="f_hasta" id="f_hasta" [(ngModel)]="f_hasta_dos" placeholder='Fecha hasta dd/mm/aaaa' class="estiloInput"></td>
                </table>
            </div>
            <div class="col-md-2">
                <button type="buton" (click)="listarDos()" class="btn btn-outline-primary botonListar">Listar</button>
            </div>
        </div>
    </div>
    <div class="row"style="width: 100%;">
        <div class="card col-md-12">
            <div class="card-body bordeCardBeneficiario">
                <div class="flex-row comment-row" >
                        <div style="overflow-x:auto;">
                                <table class="table">
                                    <thead style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 16px; color: #171994;align-items: center; text-align: center;background-color:#3166d84a;">
                                        <tr>
                                            <th>Número consulta </th>
                                            <th>Fecha</th>
                                            <th>Hora</th>
                                            <th>Paciente</th>
                                            <th>Médico tratante</th>
                                            <th>Médico licencia</th>
                                            <th>Fecha y hora generación licencia</th>
                                            <th *ngIf="(usuario.typ_id != 7)">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-family: Mulish; font-size: 13px;color: #3F3F3F; align-items: center; text-align: center;">
                                            <tr *ngFor="let item of licenciasFinalizadas" ngDefaultControl >
                                                <td>{{item.med_lea_app_boo_id}}</td>
                                                <td>{{item.tel_doc_app_appointment_date | date:'dd-MM-yyyy'}}</td>
                                                <td>{{item.tel_doc_app_appointment_hour}}</td>
                                                <td>{{item.beneficiario.nombre_completo}}</td>
                                                <td>{{item.doctor_consulta.nombre_completo}}</td>
                                                <td *ngIf="item.doctor_asignado">{{item.doctor_asignado.nombre_completo}}</td>
                                                <td *ngIf="!item.doctor_asignado">Por asignar</td>
                                                <td *ngIf="item.doctor_asignado">{{item.fecha_asignacion| date:'dd/MM/yyyy'}} {{item.hora_asignacion}}</td>
                                                <td *ngIf="!item.doctor_asignado">Por asignar</td>
                                                <td *ngIf="(usuario.typ_id != 7)">
                                                    <mat-icon *ngIf="(usuario.typ_id == 8 || usuario.typ_id == 1)" class=" iconos" (click)='ver(item)' style="font-size: 18px;color: #316AD8;"  data-toggle="modal" data-target="#verLicencia">search</mat-icon>
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
    
                                <br><br>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>

          
          
<!-- modal asignar licencia-->
<div class="modal fade" id="asignarLicencia" aria-labelledby="exampleModalLabel" aria-hidden="true" #asignarLicencia>
    <div class="modal-dialog  modal-lg">
    <div class="modal-content estiloModalLicencia" >
        <div class="modal-body">
            <ng-container *ngIf="solicitud_licencia_seleccionada.doctor_asignado && solicitud_licencia_seleccionada.doctor_asignado.nombre_completo">
                <p class="tituloModal" style="margin: 0%;width: auto; font-size: 22px;">Reasignar Solicitud</p>
                <hr>
                <p class="tituloModal" style="margin: 0%;width: auto;font-size: 18px;" >Datos de la solicitud</p>
                <br>
                <table style="width: 100%;">
                    <tr class="trModificado">
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Número consulta: {{solicitud_licencia_seleccionada.med_lea_app_boo_id}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha consulta: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_date | date:'dd-MM-yyyy'}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Médico tratante: {{solicitud_licencia_seleccionada.doctor_consulta.nombre_completo}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha generación: {{solicitud_licencia_seleccionada.fecha_asignacion | date:'dd-MM-yyyy'}}</p></td>
                    </tr>
                    <tr></tr>
                    <tr class="trModificado">
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Paciente: {{solicitud_licencia_seleccionada.beneficiario.nombre_completo}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Hora consulta: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_hour}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Médico licencia: {{solicitud_licencia_seleccionada.doctor_asignado.nombre_completo}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Hora generación: {{solicitud_licencia_seleccionada.hora_asignacion}}</p></td>
                    </tr>
                </table>
                <hr>
            </ng-container>
            <table style="width: 100%;">
                <tr class="trModificado">
                    <td><p class="tituloModal" style="margin: 0%;width: auto;">1. Fecha</p></td>
                    <td><p class="tituloModal" style="margin: 0%;width: auto;">2. Horario</p></td>
                    <td><p class="tituloModal" style="margin: 0%;width: auto;">3. Médico</p></td>
                </tr>
                <tr class="trModificado"> 
                    <td>
                        <select class="selectModal" name="fecha_elegida" (change)="seleccionaFecha($event.target.value)"  id="fecha_elegida" style="width: 90%;" [(ngModel)]="fecha_seleccionada">
                            <option value="">Selecciona una fecha</option>
                            <option *ngFor="let fecha of lista_fechas" [value]="fecha">{{fecha}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="selectModal" name="horario_elegido" (change)="seleccionaHorario($event.target.value)" id="horario_elegido" style="width: 90%;" [(ngModel)]="horario_seleccionado">
                            <option value="">Selecciona un horario</option>
                            <option *ngFor="let horario of lista_horarios" [value]="horario">{{horario}}</option>
                        </select>                    </td>
                    <td>
                        <select class="selectModal" name="horario_elegido" (change)="seleccionaDoctor($event.target.value)" id="horario_elegido" style="width: 90%;" [(ngModel)]="doctor_seleccionado">
                            <option value="">Selecciona un médico</option>
                            <option *ngFor="let doctor of lista_doctores" [value]="doctor">{{doctor}}</option>
                        </select>                    
                    </td>
                </tr>
            </table>

            <br><br><br>
        <!-- <div class="container-fluid" style="padding: 10px 10px;align-items:center;text-align: center;">
            <button type="button" class="btn btn-secondary letraBotonModal" data-dismiss="modal">Cerrar</button>
        </div> -->
        <div class="row">
            <div class="col-6">
                <button type="button" class="btn btn-secondary licencia-cancel" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-6">
                <button type="button" class="btn btn-secondary licencia-confirm" (click)="seguroAsignar()" data-dismiss="modal">Asignar</button>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
<!-- fin modal asignar licencia-->

        
<!-- modal ver licencia-->
<div class="modal fade" id="verLicencia" aria-labelledby="exampleModalLabel" aria-hidden="true" #verLicencia>
    <div class="modal-dialog  modal-lg">
    <div class="modal-content estiloModalLicencia" >
        <div class="modal-body">
            <p class="tituloModal" style="margin: 0%;width: auto; font-size: 22px;">Detalle de la solicitud de generación de licencia</p>
            <hr>
            <p class="tituloModal" style="margin: 0%;width: auto;font-size: 18px;" >Datos de la solicitud</p>
            <br>
            <table style="width: 100%;">
                <tr class="trModificado">
                    <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Número consulta: {{solicitud_licencia_seleccionada.med_lea_app_boo_id}}</p></td>
                    <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha consulta: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_date | date:'dd-MM-yyyy'}}</p></td>
                    <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Médico tratante: {{solicitud_licencia_seleccionada.doctor_consulta.nombre_completo}}</p></td>
                    <td><p *ngIf="solicitud_licencia_seleccionada.doctor_asignado && solicitud_licencia_seleccionada.doctor_asignado.nombre_completo" class="letraTablaModal" style="margin: 0%;width: auto;">Fecha generación: {{solicitud_licencia_seleccionada.fecha_asignacion | date:'dd-MM-yyyy'}}</p></td>
                </tr>
                <tr></tr>
                <tr class="trModificado">
                    <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Paciente: {{solicitud_licencia_seleccionada.beneficiario.nombre_completo}}</p></td>
                    <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Hora consulta: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_hour}}</p></td>
                    <td><p *ngIf="solicitud_licencia_seleccionada.doctor_asignado && solicitud_licencia_seleccionada.doctor_asignado.nombre_completo" class="letraTablaModal" style="margin: 0%;width: auto;">Médico licencia: {{solicitud_licencia_seleccionada.doctor_asignado.nombre_completo}}</p></td>
                    <td><p *ngIf="solicitud_licencia_seleccionada.doctor_asignado && solicitud_licencia_seleccionada.doctor_asignado.nombre_completo" class="letraTablaModal" style="margin: 0%;width: auto;">Hora generación: {{solicitud_licencia_seleccionada.hora_asignacion}}</p></td>
                </tr>
            </table>
            <hr>

            <p class="tituloModal" style="margin: 0%;width: auto;font-size: 18px;" >Datos de la licencia</p>

            <p class="letraTablaModal" style="margin: 0%;width: auto;">Rut trabajador: {{solicitud_licencia_seleccionada.beneficiario.rut}}</p>
            <br>
            <p class="letraTablaModal" style="margin: 0%;width: auto;">Código diagnostico CIE10: {{solicitud_licencia_seleccionada.dia_cie10}}</p>
            <br>
            <p class="letraTablaModal" style="margin: 0%;width: auto;">Diagnostico médico: {{solicitud_licencia_seleccionada.dia_det_diagnostic_detail}}</p>
            <br>
                
            <div class="row">
                <div class="col-6">
                    <p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha inicio de reposo: {{solicitud_licencia_seleccionada.med_lea_start_date  | date:'dd/MM/yyyy'}}</p>
                    <br>
                </div>
                <div *ngIf="solicitud_licencia_seleccionada.med_lev_typ_id == 4" class="col-6">
                    <p class="letraTablaModal" style="margin: 0%;width: auto;">Rut hijo: {{solicitud_licencia_seleccionada.med_lea_son_rut}}</p>
                    <br>
                </div>

                <div class="col-6">
                    <p class="letraTablaModal" style="margin: 0%;width: auto;">Días de reposo: {{solicitud_licencia_seleccionada.med_lea_days}}</p>
                    <br>
                </div>
                <div *ngIf="solicitud_licencia_seleccionada.med_lev_typ_id == 4" class="col-6">
                    <p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha de nacimiento hijo: {{solicitud_licencia_seleccionada.med_lea_son_birth_date  | date:'dd/MM/yyyy'}}</p>
                    <br>
                </div>

                <div class="col-6">
                    <p class="letraTablaModal" style="margin: 0%;width: auto;">Tipo de licencia: {{solicitud_licencia_seleccionada.med_lev_typ_name}}</p>
                    <br>
                </div>

                <div *ngIf="solicitud_licencia_seleccionada.med_lea_url" class="col-12">
                    <p class="letraTablaModal" style="margin: 0%;width: auto;">URL: {{solicitud_licencia_seleccionada.med_lea_url}}</p>
                    <br>
                </div>
            </div>


            <br><br><br>
        <!-- <div class="container-fluid" style="padding: 10px 10px;align-items:center;text-align: center;">
            <button type="button" class="btn btn-secondary letraBotonModal" data-dismiss="modal">Cerrar</button>
        </div> -->
        <div class="row" style="justify-content: center;">
            <div class="col-6">
                <button type="button" class="btn btn-secondary licencia-cancel" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
<!-- fin modal ver licencia-->
 
<!-- modal generar licencia-->
<div class="modal fade" id="generarLicencia" aria-labelledby="exampleModalLabel" aria-hidden="true" #generarLicencia>
    <div class="modal-dialog  modal-lg">
    <div class="modal-content estiloModalLicencia" >
        <div class="modal-body">
            <ng-container *ngIf="solicitud_licencia_seleccionada.doctor_asignado">
                <p class="tituloModal" style="margin: 0%;width: auto; font-size: 22px;">Generar Solicitud</p>
                <hr>
                <p class="tituloModal" style="margin: 0%;width: auto;font-size: 18px;" >Datos de la solicitud</p>
                <br>
                <table style="width: 100%;">
                    <tr class="trModificado">
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Número consulta: {{solicitud_licencia_seleccionada.med_lea_app_boo_id}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha consulta: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_date | date:'dd-MM-yyyy'}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Médico tratante: {{solicitud_licencia_seleccionada.doctor_consulta.nombre_completo}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha generación: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_date | date:'dd-MM-yyyy'}}</p></td>
                    </tr>
                    <tr></tr>
                    <tr class="trModificado">
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Paciente: {{solicitud_licencia_seleccionada.beneficiario.nombre_completo}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Hora consulta: {{solicitud_licencia_seleccionada.tel_doc_app_appointment_hour}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Médico licencia: {{solicitud_licencia_seleccionada.doctor_asignado.nombre_completo}}</p></td>
                        <td><p class="letraTablaModal" style="margin: 0%;width: auto;">Hora generación: {{solicitud_licencia_seleccionada.hora_asignacion}}</p></td>
                    </tr>
                </table>
                <hr>
            </ng-container>
            <p class="tituloModal" style="margin: 0%;width: auto;font-size: 18px;" >Datos de la licencia</p>

            <p class="letraTablaModal" style="margin: 0%;width: auto;">Rut trabajador: {{solicitud_licencia_seleccionada.beneficiario.rut}}</p>
            <p class="letraTablaModal" style="margin: 0%;width: auto;">Código diagnostico CIE10: {{solicitud_licencia_seleccionada.dia_cie10}}</p>
            <p class="letraTablaModal" style="margin: 0%;width: auto;">Diagnostico médico: {{solicitud_licencia_seleccionada.dia_det_diagnostic_detail}}</p>

            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-5"> 
                            <p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha inicio de reposo: </p>
                        </div>
                        <div class="col-7"> 
                            <input placeholder="Selecciona una fecha dd/mm/yyyy" class="texto-parrafo" style="width: 100%;" [(ngModel)]="licencia.fecha_inicio_reposo" >
                        </div>
                    </div>
                </div>
                <div *ngIf="licencia.tipo_seleccionado == 4" class="col-6">
                    <div class="row">
                        <div class="col-5"> 
                            <p class="letraTablaModal" style="margin: 0%;width: auto;">Rut hijo: </p>
                        </div>
                        <div class="col-7"> 
                            <input placeholder="Ingrese rut" class="texto-parrafo" style="width: 100%;" [(ngModel)]="licencia.rut_hijo">
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="row">
                        <div class="col-5"> 
                            <p class="letraTablaModal" style="margin: 0%;width: auto;">Días de reposo: </p>
                        </div>
                        <div class="col-7"> 
                            <input placeholder="Ingresa días de reposo" type="number" class="texto-parrafo" style="width: 100%;" [(ngModel)]="licencia.dias_reposo" >
                        </div>
                    </div>

                </div>
                <div *ngIf="licencia.tipo_seleccionado == 4" class="col-6">
                    <div class="row">
                        <div class="col-5"> 
                            <p class="letraTablaModal" style="margin: 0%;width: auto;">Fecha de nacimiento hijo: </p>
                        </div>
                        <div class="col-7"> 
                            <input placeholder="Selecciona una fecha dd/mm/yyyy" class="texto-parrafo" style="width: 100%;"  [(ngModel)]="licencia.fecha_nacimiento_hijo">
                        </div>
                    </div>

                </div>

                <div class="col-6">
                    <div class="row">
                        <div class="col-5"> 
                            <p class="letraTablaModal" style="margin: 0%;width: auto;">Tipo de licencia:</p>
                        </div>
                        <div class="col-7"> 
                            <select placeholder="Selecciona un tipo de licencia" class="texto-parrafo" style="width: 100%;" [(ngModel)]="licencia.tipo_seleccionado" >
                                <option value="">Selecciona un tipo de licencia</option>
                                <option *ngFor="let item of tipos_licencia_medica" [value]="item.med_lev_typ_id">{{item.med_lev_typ_name}}</option>
                              </select>
                        </div>
                    </div>

                </div>
            </div>



        


            <br><br><br>
        <div class="row">
            <div class="col-6">
                <button type="button" class="btn btn-secondary licencia-cancel" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-6">
                <button type="button" class="btn btn-secondary licencia-confirm" (click)="generaLicenciaPost()" data-dismiss="modal">Generar Licencia</button>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
<!-- fin modal generar licencia-->
