<div *ngIf="!ver_ficha_previa">
    <div class="row d-none d-sm-flex" style="width: 100%;">
      <div *ngFor="let appt of consultas_pasadas" class="col-12" style="margin-bottom: 30px;width: 100%;">
        <div class="card " style="border-color:#316AD8;width: 100%;">
          
          <table style="width: 100%;">
            <tr>
              <td>
                  <p style="font-family: Mulish;
                                font-style: normal;
                                font-weight: 800;
                                font-size: 18px;
                                line-height: 20px;
                                color: #171994;">Fecha de la consulta: {{ appt.fecha_consulta.day}} de {{ appt.fecha_consulta.month}} del {{ appt.fecha_consulta.year}} a las {{ appt.hora}}</p>
                  <p style="font-family: Mulish;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 20px;
                                color: #3F3F3F;">{{ appt.nombre_doctor }} {{ appt.apellido_paterno_doctor }} {{ appt.apellido_materno_doctor }} - {{ appt.spe_name }}</p>
                  <p  *ngFor="let diagnostico of appt.diagnosticos  ; let y = index;" class="card-text m-0 p-0 ">Diagnóstico N°{{y+1}}: {{ diagnostico.diagnostico }} </p>
              </td>
              <td>
                <button 
                  type="button"
                  (click)="verDetalle(appt)"
                  class="btn btn-secondary mr-auto">
                  Ver detalle
                </button>
                <p style="font-family: Mulish;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 16px;
                              line-height: 24px;        
                              text-align: center;
                              float: right;
                              color: #B8B8B8;"><li>Finalizada</li></p>
  
  
              </td>
            </tr>
            </table>
          
                
                
                
                
        </div>
      </div>
    </div>
  </div>
          
  <div *ngIf="ver_ficha_previa">
  
    <div style="width: 100%;">
      <a (click)="volver()" style="float:right;cursor: pointer; display: flex;">
        <mat-icon>chevron_left</mat-icon>
        <span class="ml-2">volver</span>
      </a>
  
    </div>
    <br><br>
  
    <p style="font-family: Mulish;
              font-style: normal;
              font-weight: 800;
              font-size: 24px;
              line-height: 28px;
              color: #0E0087;">Detalle de la consulta</p>
    <div class="row">
      <div class="col-12">
          <div class="row d-sm-flex">
              <div class="col-md-12">
                  <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
                  <div class="card" style="border-bottom:0px">
                    <table style="width: 100%;">
                      <tr>
                        <td>
                          <app-profile-img 
                            [img]="consulta_aux.att_path" 
                            [doctorName]="consulta_aux.nombre_doctor|slice:0:1" 
                            [doctorLastName]="consulta_aux.apellido_paterno_doctor|slice:0:1">
                          </app-profile-img>
    
                        </td>
                        <td>
                          <p class="card-text m-0 p-0 appt-date" style="font-weight: bold">Fecha de la consulta: {{ consulta_aux.fecha_consulta.day}} de {{ consulta_aux.fecha_consulta.month}} del {{ consulta_aux.fecha_consulta.year}} a las {{ consulta_aux.hora}}</p>
                          <p class="card-text m-0 p-0 ">{{ consulta_aux.nombre_doctor}} {{ consulta_aux.apellido_paterno_doctor}} - {{ consulta_aux.spe_name}}</p> <!-- {{ appt.spec }} -->
                          <p class="card-text m-0 p-0 "> </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <br>
  
                  <br>
              </div>
          </div>
      </div>
  </div>
  
    <nav>
      <div class="nav nav-tabs" role="tablist">
        <a class="nav-item nav-link active" (click)="cambiar_pestana('6')"data-toggle="tab" href="#" role="tab" aria-controls="nav-anm" aria-selected="true"  >Anamnesis</a>
        <a class="nav-item nav-link" (click)="cambiar_pestana('1')"data-toggle="tab" href="#" role="tab" aria-controls="nav-exm" aria-selected="false"  >Diagnósticos e indicaciones</a>
        <a class="nav-item nav-link" (click)="cambiar_pestana('2')" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Certificados</a>
        <a class="nav-item nav-link" (click)="cambiar_pestana('3')" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Recetas</a>
        <a class="nav-item nav-link" (click)="cambiar_pestana('4')"data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Órdenes de exámenes</a>
        <a class="nav-item nav-link" (click)="cambiar_pestana('5')" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Otros documentos</a>
      </div>
    </nav>
  

        <!-- --------------------------------Anamnesis-------------------------------- -->
        <div *ngIf="accion == 6">
          <div *ngFor="let item of ficha_consulta_aux.anamnesis" >
            <br>
            <div class="card " style="border-color:#316AD8;width: 100%;">
            <h1 class="titulo">Anamnesis</h1>  
            <h3 class="fecha">Fecha: {{item.med_con_his_date_attention| date:'dd-MM-yyyy h:mm a'}}</h3>
            <h3 class="descripcion">{{item.med_con_his_anamnesis}}</h3>      
          </div>
            
          </div>
          <div *ngIf="ficha_consulta_aux.anamnesis.length<1" class="col-md-12">
            <br>
            <div class="card-alerta" >
              <table>
                <tr>
                  <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
                  <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
                  </td>
                  
                </tr>
              </table>
            </div>
          </div>
        </div>

    <!-- --------------------------------Diagnosticos-------------------------------- -->
    <div *ngIf="accion == 1">
      <div *ngFor="let item of ficha_consulta_aux.diagnostics" >
        <br>
        <div class="card " style="border-color:#316AD8;width: 100%;">
        <h1 class="titulo">{{item.file_name_desc}}</h1>  
        <h3 class="fecha">Fecha: {{item.diag_det_his_date| date:'dd-MM-yyyy h:mm a'}}</h3>
        <h3 class="descripcion">{{item.dia_name}}</h3>
        <h3 class="descripcion">{{item.diag_det_his_diagnostic_detail}}</h3>
        <div style="width: 100%;height: 60px;">
          <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon> </button>
        </div>
  
        </div>
        
      </div>
      <div *ngIf="ficha_consulta_aux.diagnostics.length<1" class="col-md-12">
        <br>
        <div class="card-alerta" >
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
              <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>
              
            </tr>
          </table>
        </div>
      </div>
    </div>
  
      <!-- --------------------------------Certificados-------------------------------- -->
    <div *ngIf="accion == 2">
      <div *ngFor="let item of ficha_consulta_aux.certificados" >
        <br>
        <div class="card " style="border-color:#316AD8;width: 100%;">
          <h1 class="titulo">{{item.file_name_desc}}</h1>  
          <h3 class="fecha">Fecha: {{item.med_ord_date| date:'dd-MM-yyyy h:mm a'}}</h3>
          <h3 class="descripcion">{{item.med_ord_his_comment}}</h3>
          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon> </button>
          </div>
  
        </div>
        
      </div>
      <div *ngIf="ficha_consulta_aux.certificados.length<1" class="col-md-12">
        <br>
        <div class="card-alerta" >
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
              <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>
              
            </tr>
          </table>
        </div>
      </div>
    </div>
  
    <!-- --------------------------------Recetas-------------------------------- -->
    <div *ngIf="accion == 3">
      <div *ngFor="let item of ficha_consulta_aux.prescriptions" >
        <br>
        <div class="card " style="border-color:#316AD8;width: 100%;">
          <h1 class="titulo">{{item.file_name_desc}}</h1> 
          <h3 class="fecha">Fecha: {{item.pre_his_date| date:'dd-MM-yyyy h:mm a'}}</h3>
          <h3 class="descripcion">{{item.pre_typ_name}}: {{item.pre_his_indication}}</h3>
          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon>  </button>
          </div>
  
        </div>
        
      </div>
      <div *ngIf="ficha_consulta_aux.prescriptions.length<1" class="col-md-12">
        <br>
        <div class="card-alerta" >
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
              <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>
              
            </tr>
          </table>
        </div>
      </div>
    </div>
  
    <!-- --------------------------------Oredenes de Examenes-------------------------------- -->
    <div *ngIf="accion == 4">
      <div *ngFor="let item of ficha_consulta_aux.orders" >
        <br>
        <div class="card " style="border-color:#316AD8;width: 100%;">
          <h1 class="titulo">{{item.file_name_desc}}</h1>  
          <h3 class="fecha">Fecha: {{item.med_ord_date| date:'dd-MM-yyyy h:mm a'}}</h3>
          <h3 class="descripcion">{{item.med_ord_typ_name}}: {{item.med_ord_his_comment}}</h3>
          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon>  </button>
          </div>
  
        </div>
        
      </div>
      <div *ngIf="ficha_consulta_aux.orders.length<1" class="col-md-12">
        <br>
        <div class="card-alerta" >
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
              <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>
              
            </tr>
          </table>
        </div>
      </div>
    </div>
  
    <!-- --------------------------------Otros Documentos-------------------------------- -->
    <div *ngIf="accion == 5">
      <div *ngFor="let item of documentos_aux" >
        <br>
        <div class="card " style="border-color:#316AD8;width: 100%;">
          <h1 class="titulo">{{item.file_name_desc}}</h1>  
          <h3 class="fecha">{{item.att_name| slice:13:1000}}</h3>
          <h3 class="descripcion">Adjuntado el: {{item.att_date| date:'dd-MM-yyyy h:mm a'}}</h3>
          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)" class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon> </button>
          </div>
  
        </div>
        
      </div>
      <div *ngIf="documentos_aux.length<1" class="col-md-12">
        <br>
        <div class="card-alerta" >
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
              <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>
              
            </tr>
          </table>
        </div>
      </div>
    </div>
  
  </div>