import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.css']
})
export class PaymentButtonComponent implements OnInit {
  @ViewChild('butonPayTransbank') butonPayTransbank: ElementRef;
  @Input() payment_button: any = {
    url: null,
    token: null,
  };

  constructor(
    private renderer: Renderer2,
    private generalService : GeneralService
  ) { }

  ngOnInit(): void {
  }

  pay() {
    while (true) {      
      if (this.payment_button.url && this.payment_button.token) {
        this.generalService.sleep(500);
        this.renderer.selectRootElement(this.butonPayTransbank.nativeElement).click();
        break;
      }
      this.generalService.sleep(500);
    }
  }


}
