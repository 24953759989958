import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-middleware',
  templateUrl: './login-middleware.component.html',
  styleUrls: ['./login-middleware.component.css']
})
export class LoginMiddlewareComponent implements OnInit {

  constructor(
    private generalService: GeneralService,
    private usuarioService: UsuarioService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.generalService.modalCargando();
    this.activatedRoute.params.subscribe(params => {

      if (params.idTransaction) {
        this.usuarioService.loginMiddleware(params.idTransaction).then(result => {
          // console.log(result);

        }).catch(error => {
          this.router.navigate(['login']) //navigateByUrl('/dashboard');
          Swal.close();
        });
      }
    });
  }

}
