


<div class="modal-dialog">
    <div class="modal-body">
        <div class="form-group row">
            <div class="col-md-12">
                <p class="tituloModal">
                    Los datos que ingresaste se perderan
                </p>
                <p class="subTituloModal">
                    Si decides salir de este paso, al avanzar se perdera la informacion de la reserva. </p>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <button type="button" class="btn botonCancelar colorLetraVolver" mat-dialog-close>Volver a
                reserva</button>
        </div>
        <div class="col-md-6 letrabotonagregar">
            <button type="button" class="btn botonVolver" [mat-dialog-close]="true">Salir de
                reserva</button>
        </div>
    </div>
</div>