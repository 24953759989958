<div class="row">
  <ng-container *ngIf="especialidadesDoctor.length>0">
    <ng-container *ngFor="let especialidades of especialidadesDoctor[0].specialties">
      <div *ngIf="especialidades.sub_spe_pri_price" class="cardNgFor col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="cardTarifa">
          <div class="row" style="margin: 10px 0px">
            <div class="col-4 divImgPlan">
              <img style="border-radius: 50%;" [src]="URL_DOCS+especialidadesDoctor[0].att_path" alt="auto" width="80%"
                class="centeredElement" />
            </div>
            <div class="col-8">
              <p class="textCardTitle">
                {{ especialidades.spe_name}}
              </p>
              <table>
                <tr style="margin-bottom: 10px">
                  <td>
                    <p class="textCardSubTitle">Tarifa:</p>
                  </td>
                  <td *ngIf="especialidades.doc_spe_sub_spe_can_upd_price">
                    <p class="textCardSubTitle">{{especialidades.pri_doc_price | currency:'CLP'}}</p>
                  </td>

                  <td *ngIf="!especialidades.doc_spe_sub_spe_can_upd_price">
                    <p class="textCardSubTitle">{{especialidades.sub_spe_pri_price | currency:'CLP'}}</p>
                  </td>
                </tr>

                <tr style="margin-bottom: 10px">
                  <td>
                    <p class="textCardSubTitle">Duracion consulta:</p>
                  </td>
                  <td>
                    <p class="textCardSubTitle"></p>
                  </td>
                </tr>
              </table>
              <div class="divButtonModificar">
                <button *ngIf="especialidades.doc_spe_sub_spe_can_upd_price" class="buttonModificar"
                  (click)="updateDoctorEspecialidad(especialidades)" data-toggle="modal" tabindex="-1"
                  data-target="#showTarifaModal">
                  <p class="textButton">Modificar Tarifa</p>
                </button>

                <button *ngIf="!especialidades.doc_spe_sub_spe_can_upd_price" class="buttonModificarDesactivado"
                  data-toggle="modal" (click)="modalTarifaBloqueada()">
                  <p class="textButton">Modificar Tarifa</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>


  </ng-container>

</div>

<!------------------ Modal desplegable--------------->

<div class="modal fade" id="showTarifaModal" aria-labelledby="showTarifaModal" aria-hidden="true" tabindex="-1">

  <div class="form-group modal-dialog modalShowTarifa">
    <div class="row">
      <div class="col-12" style="align-self: center">
        <p class="modalTitle">Modificar Tarifa</p>
      </div>
    </div>
    <br /><br />

    <div *ngIf="infoPost.spe_name" class="subTitle">{{ infoPost.spe_name}}</div>
    <br /><br />

    <div class="row cardModalTable">
      <div class=" col-3 divLeftModal">
        <label for="tarifaActual" class="row-md-4 col-form-label colorletra">Tarifa Actual</label>
      </div>
      <div *ngIf="infoPost.doc_spe_sub_spe_can_upd_price" class="col-4 divRightModal">
        <p class="modalTableSubTitle pDivModalContent">"{{infoPost.pri_doc_price | currency:'CLP'}}"</p>
      </div>

      <div *ngIf="!infoPost.doc_spe_sub_spe_can_upd_price" class="col-4 divRightModal">
        <p class="modalTableSubTitle pDivModalContent">"{{infoPost.sub_spe_pri_price | currency:'CLP'}}"</p>
      </div>

      <br /><br />
    </div>

    <div class="row cardModalTable">
      <div class="col-3 divLeftModal">
        <p class="modalTableSubTitle">Nueva Tarifa:</p>
      </div>

      <div class="form-group col-6 divRightModal">
        <input class="form-control form-control-static nuevaTarifa" type="number" [(ngModel)]="infoPost.newPrice"
          placeholder="Ingrese nueva tarifa" />
      </div>

    </div>

    <div class="container">
      <br /><br />
      <p>
        Se notificara a tu coordinador medico cada vez que modifiques la tarifa
        de tus consultas.
      </p>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <button type="button" class="botonVolver colorLetraVolver" data-dismiss="modal" data-toggle="modal"
            data-target="">
            Volver atras
          </button>
        </div>
        <div class="col-md-6 letrabotonagregar">
          <button type="button" class="btn btn-primary botonModalModificar" data-dismiss="modal" data-toggle="modal"
            data-target="#aceptaModificarTarifa">
            Modificar Tarifa
          </button>
        </div>
      </div>
    </div>
  </div>
</div>






<!-- MODAL DE CONFIRMACION-->

<div class="modal fade" id="aceptaModificarTarifa" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row">
          <div class="col-md-12">
            <p class="tituloModal">
              ¿Estás seguro que desea modificar esta tarifa?
            </p>
          </div>
        </div>
        <br /><br />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal"
                data-toggle="modal" data-target="#showTarifaModal">
                Volver atras
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="button" (click)="confirmUpdateDoctorPriceSubSpe()"
                class="btn btn-primary botonModalModificar" data-dismiss="modal" data-toggle="modal">
                Modificar tarifa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>