import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any[] = [
    /*{
      titulo: 'Home',
      icono: 'far fa-user',
      submenu: [
        { titulo: 'Main', url: '/dashboard' },
        { titulo: 'progress', url: '/progress' },
        { titulo: 'Grafica', url: '/grafica1' },
        { titulo: 'Promesas', url: '/promesas' },
        { titulo: 'RXJS', url: '/rxjs' }
      ]
    },{
      titulo: 'Grupo familiar',
      icono: 'fas fa-users',
      submenu: [
        { titulo: 'Mi Grupo familiar', url: '/familyGroup' }
      ]
    }*/
    {
      titulo: 'Inicio',
      icono: 'fas fa-home',
      submenu: [
        { titulo: 'Inicio', url: '/dashboard' },
      ]
    },{
      titulo: 'Pide Tu Hora',
      icono: 'far fa-calendar-alt',
      submenu: [
        { titulo: 'Reserva de Hora', url: '/rxjs' }
      ]
    },{
      titulo: 'Sala de Espera',
      icono: 'fas fa-user-nurse',
      /*icono: 'fas fa-phone-volume',*/
      submenu: [
        { titulo: 'Consultas Actuales', url: '/meetingRoom' }
      ]
    },{
      titulo: 'Mis Consultas',
      icono: 'fas fa-mobile-alt',
      submenu: [
        { titulo: 'Proximas Consultas', url: '/rxjs' },
        { titulo: 'Historial de Consultas', url: '/grafica1' }
      ]
    },{
      titulo: 'Chat',
      icono: 'far fa-comments',
      submenu: [
        { titulo: 'Contacta tu Medico', url: '/rxjs' }
      ]
    },{
      titulo: 'Mis Antecedentes Medicos',
      icono: 'far fa-address-card',
      submenu: [
        { titulo: 'Alergias', url: '/rxjs' },
        { titulo: 'Antecedentes Familiares', url: '/grafica1' },
        { titulo: 'Antecedentes Quirurjicos', url: '/promesas' },
        { titulo: 'Enfermedades Cronicas', url: '/promesas' },
        { titulo: 'Subir Examenes', url: '/rxjs' },
        { titulo: 'Habitos y Estilo de Vida', url: '/progress' },
        { titulo: 'Hospitalizaciones', url: '/rxjs' },
        { titulo: 'Medicamentos en Uso', url: '/rxjs' },
        { titulo: 'Vacunas', url: '/rxjs' }
      ]
    },{
      titulo: 'Mis Documentos',
      icono: 'far fa-folder-open',
      submenu: [
        { titulo: 'Mis Recetas', url: '/rxjs' },
        { titulo: 'Mis Ordenes de Examenes', url: '/grafica1' },
        { titulo: 'Mis Certificados', url: '/promesas' },
        { titulo: 'Mis Indicaciones', url: '/promesas' },
        { titulo: 'Subir Documento', url: '/rxjs' },
      ]
    },{
      titulo: 'Contacto',
      icono: 'fas fa-headset',
      submenu: [
        { titulo: 'Contacto', url: '/rxjs' }
      ]
    }

  ]

  constructor() { }
}
