<ng-container *ngIf="pages.user_list">
     <div style="width: 100%;" class="row divContent">
          <div style="width: 100%;" class="row divCard">
               <div class="col-md-6 col-12" style="
               display: flex;
               align-items: center;">
                    <div class="row">
                         <div class="col-8">
                              <input autocomplete="disabled" type="search" placeholder='Nombre, apellido, rut…'
                                   class="inputStyle" [(ngModel)]="pageConfig.filter_aux">
                         </div>
                         <div class="col-4">
                              <button type="button" class="primary buttonStylePrimary" style="padding: 3px;width: 100%;"
                                   (click)="ListarLoading()">Listar</button>
                         </div>
                    </div>
               </div>
               <div class="col-md-6 col-12">
                    <div style="float: right; margin: 10px;">
                         <ng-container *ngIf=" pageConfig && pageConfig.pages">
                              <ng-container *ngFor="let i of arrayNumber()">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="changeLengthTable(i)">{{i}}</button>
                              </ng-container>
                         </ng-container>
                    </div>
               </div>
               <div class="col-12">
                    <table style="width: 100%;" class="table">
                         <thead
                              style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 24px; color: #171994;align-items: center; text-align: center;background-color:#3166d84a;">
                              <tr>
                                   <th>
                                        <p class="pAddTitle">Nombre</p>
                                   </th>
                                   <th>
                                        <p class="pAddTitle">RUT</p>
                                   </th>
                                   <th>
                                        <p class="pAddTitle">Mail</p>
                                   </th>
                                   <th>
                                        <p class="pAddTitle">Perfiles</p>
                                   </th>
                                   <th>
                                        <p class="pAddTitle">Acción</p>
                                   </th>
                              </tr>
                         </thead>
                         <tbody
                              style="font-family: Mulish; font-size: 18px;color: #3F3F3F; align-items: center; text-align: center;">

                              <ng-container *ngFor="let user of users">
                                   <tr>
                                        <td>
                                             <p class="pTable">{{user.name}} {{user.p_surname}}</p>
                                        </td>
                                        <td>
                                             <p class="pTable">{{user.rut}}</p>
                                        </td>
                                        <td>
                                             <p class="pTable">{{user.mail}}</p>
                                        </td>
                                        <td>
                                             <ng-container *ngIf="user.user_types">
                                                  <ul>
                                                       <li *ngFor="let type of user.user_types" class="pTable">
                                                            {{type.use_typ_name}}
                                                       </li>
                                                  </ul>
                                             </ng-container>
                                        </td>
                                        <td>
                                             <button type="button" class="btn btn-secondary actionButton"
                                                  (click)="editUser(user)"><mat-icon>edit</mat-icon></button>
                                             <button type="button" class="btn btn-secondary actionButton"
                                                  (click)="deleteUser(user)"><mat-icon>delete</mat-icon></button>
                                           
                                        </td>
                                   </tr>
                              </ng-container>
                         </tbody>
                    </table>
               </div>
          </div>
     </div>
</ng-container>

<ng-container *ngIf="pages.edit_user && auxUser.use_id">
     <app-add-user [newUser]="auxUser"></app-add-user>
</ng-container>

<div class="modal fade hide" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Desactivar usuario</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">


                         <div class="col-12">

                              <p class="pTableTitle" style="padding: 40px;">¿Está seguro de desactivar al usuario
                                   {{userSelected.name}} {{userSelected.p_surname}}?</p>

                              <hr>
                         </div>
                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="primary buttonStylePrimary"
                                   (click)="confirmDelete()">Guardar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<button #triDeleteModal type="button" class="btn d-none" data-toggle="modal" data-target="#deleteModal"></button>