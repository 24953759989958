<div style="width: 100%;" class="row divContent">
     <div style="width: 100%;" class="row divCard">
          <div class="col-12">
               <p class="pTableTitle">Agregar usuario</p>
               <hr>
          </div>

          <div class="col-md-4 col-12">
               <p class="pAddTitle">Nombre</p>
               <input type="text" [(ngModel)]="newUser.name">
          </div>
          <div class="col-md-4 col-12">
               <p class="pAddTitle">A. Paterno</p>
               <input type="text" [(ngModel)]="newUser.p_surname">
          </div>
          <div class="col-md-4 col-12">
               <p class="pAddTitle">A. Materno</p>
               <input type="text" [(ngModel)]="newUser.m_surname">
          </div>
          <!-- <div class="col-12">
               <hr>
          </div> -->

          <div class="col-md-4 col-12">
               <p class="pAddTitle">Rut</p>
               <input type="text" [(ngModel)]="newUser.rut" [disabled]="newUser.use_id ? true : false">
          </div>
          <div class="col-md-4 col-12">
               <p class="pAddTitle">Correo</p>
               <input type="text" [(ngModel)]="newUser.mail">
          </div>
          <div class="col-md-4 col-12">
               <p class="pAddTitle">Telefono</p>
               <input type="text" [(ngModel)]="newUser.phone">
          </div>

          <div class="col-md-6 col-12">
               <p class="pAddTitle">F. nacimiento</p>
               <mat-form-field style="width: 100%;" appearance="fill">
                    <mat-label>F. nacimiento</mat-label>
                    <input style="width: auto;" matInput [matDatepicker]="dp3" disabled
                         [(ngModel)]="newUser.birth_date">
                    <mat-datepicker-toggle matIconSuffix [for]="dp3" style="float: right;"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
               </mat-form-field>
          </div>

          <div class="col-12">
               <hr>
          </div>
          <div class="col-md-6 col-12">
               <p class="pAddTitle">Perfiles</p>
               <ng-container *ngIf="userTypes.length > 0">
                    <ng-multiselect-dropdown [placeholder]="'Seleccione perfiles'" [settings]="multiSelectSettings"
                         [data]="userTypes" [(ngModel)]="newUser.user_types" (onSelect)="onItemSelectArea($event)"
                         (onSelectAll)="onSelectAllArea($event)" (onDropDownClose)="onDropDownClose($event)">
                    </ng-multiselect-dropdown>
               </ng-container>
          </div>
          <div class="col-md-6 col-12">
               <p class="pAddTitle">Género</p>
               <select class="form-control" [(ngModel)]="newUser.gender" name="gender">
                    <option value='MASCULINO'>MASCULINO</option>
                    <option value='FEMENINO'>FEMENINO</option>
                    <option value='N/A'>N/A</option>
               </select>
          </div>
          <ng-container *ngIf="isDoctor()">
               <div class="col-12">
                    <hr>
               </div>
               <div class="col-12">
                    <p class="pAddTitle">Imagen<mat-icon *ngIf="!newUser.image"
                              (click)="showModalFile('image')">add</mat-icon></p>
                    <ng-container *ngIf="newUser.image; else addImage">
                         <div class="row" style="width: 100%;">
                              <div class="col-6">
                                   <p class="pAddTitle">Nombre</p>
                              </div>
                              <div class="col-6">
                                   <p class="pAddTitle">Acción</p>
                              </div>
                              <div class="col-6">
                                   <ng-container *ngIf="newUser['image'].name; else showImage">
                                        <p>{{newUser['image'].name}}</p>
                                   </ng-container>
                                   <ng-template #showImage>
                                        <img [src]="URL_DOCUMENTS+newUser['image']" style="border-radius: 50%;height: 100px;">
                                   </ng-template>
                              </div>
                              <div class="col-6">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="deleteFile('image')"><mat-icon>delete</mat-icon></button>
                              </div>
                         </div>
                    </ng-container>
                    <ng-template #addImage>
                         <div class="not-plans">
                              <div style="display: flex; flex-direction: row; ">
                                   <mat-icon>error_outline</mat-icon>
                                   <span>Debe seleccionar una imagen.</span>
                              </div>
                         </div>
                    </ng-template>
               </div>
               <div class="col-12">
                    <p class="pAddTitle">Firma<mat-icon *ngIf="!newUser.sign"
                              (click)="showModalFile('sign')">add</mat-icon></p>
                    <ng-container *ngIf="newUser.sign; else addSign">
                         <div class="row" style="width: 100%;">
                              <div class="col-6">
                                   <p class="pAddTitle">Nombre</p>
                              </div>
                              <div class="col-6">
                                   <p class="pAddTitle">Acción</p>
                              </div>
                              <div class="col-6">
                                   <ng-container *ngIf="newUser['sign'].name; else showSign">
                                        <p>{{newUser['sign'].name}}</p>
                                   </ng-container>
                                   <ng-template #showSign>
                                        <img [src]="URL_DOCUMENTS+newUser['sign']" style="border-radius: 50%;height: 100px;">
                                   </ng-template>                              
                              </div>
                              <div class="col-6">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="deleteFile('sign')"><mat-icon>delete</mat-icon></button>
                              </div>
                         </div>
                    </ng-container>
                    <ng-template #addSign>
                         <div class="not-plans">
                              <div style="display: flex; flex-direction: row; ">
                                   <mat-icon>error_outline</mat-icon>
                                   <span>Debe seleccionar una firma</span>
                              </div>
                         </div>
                    </ng-template>
               </div>
               <div class="col-12">
                    <hr>
                    <p class="pTableTitle">Título</p>
               </div>
               <div class="col-md-6 col-12">
                    <p class="pAddTitle">F. de egreso</p>
                    <mat-form-field style="width: 100%;" appearance="fill">
                         <mat-label>F. de egreso</mat-label>
                         <input style="width: auto;" matInput [matDatepicker]="dp3" disabled
                              [(ngModel)]="newUser.egress_date">
                         <mat-datepicker-toggle matIconSuffix [for]="dp3" style="float: right;"></mat-datepicker-toggle>
                         <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    </mat-form-field>
               </div>
               <div class="col-md-6 col-12">
                    <p class="pAddTitle">Grado</p>
                    <ng2-completer name="newUser.id_title" (selected)="changeTitle($event)"
                         [textNoResults]="'Título no encontrado'" [datasource]="titles" [(ngModel)]="searchTitle"
                         [minSearchLength]="0" [placeholder]="'Seleccione Título'" #newUser.id_title="ngModel"
                         [autoMatch]="true" [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true"
                         required>
                    </ng2-completer>
               </div>
               <div class="col-md-6 col-12">
                    <p class="pAddTitle">Institución Académica</p>
                    <ng2-completer name="#newUser.id_institution" (selected)="changeInstitution($event)"
                         [textNoResults]="'Institución no encontrada'" [datasource]="institutions"
                         [(ngModel)]="searchInstitution" [minSearchLength]="0" [placeholder]="'Seleccione Institución'"
                         #newUser.id_institution="ngModel" [autoMatch]="true" [inputClass]="'form-control'"
                         [clearUnselected]="true" [openOnFocus]="true" required>
                    </ng2-completer>
               </div>
               <div class="col-md-6 col-12">
                    <p class="pAddTitle">Especialidades</p>
                    <ng-container *ngIf="subspecialitys_data.length > 0">
                         <ng-multiselect-dropdown [placeholder]="'Seleccione especialidades'"
                              [settings]="multiSelectSpeSettings" [data]="subspecialitys_data"
                              [(ngModel)]="newUser.specialities" (onSelect)="onItemSelectArea($event)"
                              (onSelectAll)="onSelectAllArea($event)" (onDropDownClose)="onDropDownClose($event)">
                         </ng-multiselect-dropdown>
                    </ng-container>
               </div>
               <div class="col-md-6 col-12">
                    <p class="pAddTitle">SIS Médico</p>
                    <input type="text" [(ngModel)]="newUser.sis_doctor">
               </div>
               <div class="col-12">
               </div>
               <div class="col-md-6 col-12" style="text-align: start;">
                    <p class="pAddTitle">FEA</p>
                    <input id="checkbox-firma" style="border: 2px solid #0E0087;padding-top: 8px;
                    box-sizing: border-box;
                    border-radius: 2px;" type="checkbox" [(ngModel)]="newUser.has_fea" name="check_firma">
                    <label for="checkbox-firma" style="font-family: Mulish;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 24px;
                      color: #171994;">Firma electrónica avanzada</label>
               </div>
               <div class="col-md-6 col-12" style="text-align: start;">
                    <p class="pAddTitle">Licencia Médica</p>
                    <input id="checkbox-lme" style="border: 2px solid #0E0087;padding-top: 8px;
                    box-sizing: border-box;
                    border-radius: 2px;" type="checkbox" [(ngModel)]="newUser.has_lme" name="check_lme">
                    <label for="checkbox-lme" style="font-family: Mulish;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 18px;
                      line-height: 24px;
                      color: #171994;">Inscrito en MediPass</label>
               </div>
          </ng-container>
          <div class="col-12">
               <hr>
          </div>
          <div class="col-md-4 col-6">
               <button type="button" class="buttonStyleSecondary" style="width: 100%;"
                    (click)="cancel()">Cancelar</button>
          </div>
          <div class="col-md-4 col-6">
               <button type="button" class="buttonStylePrimary" style="width: 100%;"
                    (click)="createUser()">{{newUser.use_id ? 'Modificar' : 'Crear' }}</button>
          </div>
     </div>
</div>

<div class="modal fade hide" id="modalFile" tabindex="-1" role="dialog" aria-labelledby="modalFile" aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Subir imagen</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <ng-container *ngIf="file_type && newUser[file_type]">
                              <div class="col-6">
                                   <p class="pAddTitle">Nombre</p>
                              </div>
                              <div class="col-6">
                                   <p class="pAddTitle">Acción</p>
                              </div>
                              <div class="col-6">
                                   <p>{{newUser[file_type].name}}</p>
                              </div>
                              <div class="col-6">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="deleteFile(file_type)"><mat-icon>delete</mat-icon></button>
                              </div>
                         </ng-container>
                         <ng-container *ngIf="!file_type || !newUser[file_type]">
                              <div class="col-12">
                                   <div class="not-plans">
                                        <div style="display: flex; flex-direction: row; ">
                                             <mat-icon>error_outline</mat-icon>
                                             <span>Debe seleccionar una imagen</span>
                                        </div>
                                   </div>
                              </div>
                              <div style="margin: 10px;">
                                   <label class="buttonStyleSecondary" style="height: 40px;">Examinar
                                        <input type="file" style="cursor: pointer !important;"
                                             (change)="onFileSelected($event)" max-file-size="1024" required />
                                   </label>
                              </div>
                              <div class="col-12">
                                   <ng-container *ngIf="newUser[file_type]">
                                        <p>{{newUser[file_type].name}}</p>
                                   </ng-container>
                              </div>
                              <div class="col-12">
                                   <p>
                                        <strong>El documento no puede ser superior a 20 MB (PDF, JPG o PNG)</strong>
                                   </p>
                              </div>
                         </ng-container>
                         <div class="col-12">
                              <hr>
                         </div>
                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="primary buttonStylePrimary">Guardar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<button #insModalFile type="button" class="btn d-none" data-toggle="modal" data-target="#modalFile"></button>