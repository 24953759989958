<ng-container *ngIf="beneficiaries && beneficiaries._data">
     <div class="row" style="width: 100%;">
          <div class="col-10" >
               <p class="pPlan" style="margin-bottom: 10px;">Beneficiarios <mat-icon (click)="addBen()">add</mat-icon></p>
               <ng2-completer name="id_ben" (selected)="changeBeneficiary($event)"
                    [textNoResults]="'Beneficiario no encontrado'" [datasource]="beneficiaries" [(ngModel)]="searchStr"
                    [minSearchLength]="0" [placeholder]="'Seleccione Beneficiario'" #id_ben="ngModel" [autoMatch]="true"
                    [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true" required>
               </ng2-completer>
          </div>
          <div class="col-2">
               <button
                    *ngIf="beneficiarySelected && (beneficiarySelected.nombre_beneficiario || beneficiarySelected.cus_dat_rut)"
                    type="button" class="buttonStylePrimary" (click)="seeBeneficiary()">Ver</button>
          </div>
     </div>
</ng-container>

<div class="modal fade hide" id="seeBeneficiary" tabindex="-1" role="dialog" aria-labelledby="seeBeneficiary"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Beneficiario</h2>
               </div>
               <div class="modal-body">
                    <div style="width: 100%;">
                         <p *ngIf="beneficiarySelected.nombre_beneficiario" class="pTableTitle">
                              {{beneficiarySelected.nombre_beneficiario}}</p>
                         <p class="!beneficiarySelected.nombre_beneficiario && beneficiarySelected.cus_dat_rut">RUT: {{
                              beneficiarySelected.cus_dat_rut}}</p>
                    </div>
                    <div class="row modalBody" style="width: 100%;">
                         <ng-container
                              *ngIf="beneficiarySelected.nombre_beneficiario && beneficiarySelected.planes.length > 0">
                              <ng-container *ngFor="let plan of beneficiarySelected.planes">
                                   <div class="col-6">
                                        <div class="card">
                                             <div class="row">
                                                  <div class="col-10">
                                                       <p class="pPlan">Plan</p>
                                                       <ng-container *ngIf="plan.cus_ben_name">
                                                            <p class="pTableNotInfo">{{plan.cus_ben_name}}</p>
                                                       </ng-container>
                                                       <ng-container *ngIf="!plan.cus_ben_name">
                                                            <p class="pTableNotInfo">
                                                                 <mat-icon>error_outline</mat-icon> No tiene
                                                            </p>
                                                       </ng-container>
                                                  </div>
                                                  <div class="col-2" style="padding: 0px;">
                                                       <mat-icon (click)="deleteBen(plan)">delete</mat-icon>
                                                  </div>
                                             </div>
                                             <p class="pPlan">Titular</p>
                                             <ng-container *ngIf="plan.nombre_titular">
                                                  <p class="pTableNotInfo">{{plan.nombre_titular}}</p>
                                             </ng-container>
                                             <ng-container *ngIf="!plan.nombre_titular">
                                                  <p class="pTableNotInfo"><mat-icon>error_outline</mat-icon> No
                                                       tiene
                                                  </p>
                                             </ng-container>
                                        </div>
                                   </div>
                              </ng-container>
                         </ng-container>

                         <ng-container
                              *ngIf="!beneficiarySelected.nombre_beneficiario && beneficiarySelected.cus_dat_rut">
                              <div class="col-6">
                                   <div class="card">
                                        <div class="row">
                                             <div class="col-10">
                                                  <p class="pPlan">Plan</p>
                                                  <ng-container *ngIf="beneficiarySelected.cus_ben_name">
                                                       <p class="pTableNotInfo">{{beneficiarySelected.cus_ben_name}}</p>
                                                  </ng-container>
                                                  <ng-container *ngIf="!beneficiarySelected.cus_ben_name">
                                                       <p class="pTableNotInfo">
                                                            <mat-icon>error_outline</mat-icon> No tiene
                                                       </p>
                                                  </ng-container>
                                             </div>
                                             <div class="col-2" style="padding: 0px;">
                                                  <mat-icon class=" actionButton" (click)="deleteBen(beneficiarySelected)">delete</mat-icon>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </ng-container>

                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="deleteBeneficiary" tabindex="-1" role="dialog" aria-labelledby="deleteBeneficiary"
     aria-hidden="true">
     <div class="modal-dialog" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Eliminar</h2>
               </div>
               <div class="modal-body">
                    <div style="width: 100%;margin-top: 30px;margin-bottom: 30px;">
                         <p class="pTableTitle">¿Está seguro de eliminar al beneficiario
                              {{beneficiarySelected.nombre_beneficiario}} del plan {{deleteSelected.cus_ben_name}}.</p>
                    </div>
                    <div class="row" style="justify-content: space-around;margin-top: 30px;margin-bottom: 30px;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStylePrimary"
                                   (click)="confirmDelete()">Eliminar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="addBeneficiary" tabindex="-1" role="dialog" aria-labelledby="addBeneficiary"
     aria-hidden="true">
     <div class="modal-dialog" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Agregar</h2>
               </div>
               <div class="modal-body">
                    <div style="width: 100%;margin-top: 30px;margin-bottom: 30px;">
                         <div class="row modalBody" style="width: 100%;">
                              <div class="col-6">
                                   <p class="pTableTitle">Rut nuevo:</p>
                              </div>
                              <div class="col-6">
                                   <input type="text" [(ngModel)]="newRut">
                              </div>
                              <div class="col-6">
                                   <p class="pTableTitle">Plan:</p>
                              </div>
                              <div class="col-6">
                                   <select [(ngModel)]="planSelected">
                                        <option value="">Seleccione plan</option>
                                        <option *ngFor="let plan of planList" [value]="plan.cus_ben_id">{{plan.cus_ben_name}}</option>
                                   </select>
                              </div>
                         </div>
                    </div>
                    <div class="row" style="justify-content: space-around;margin-top: 30px;margin-bottom: 30px;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStylePrimary"
                                   (click)="confirmAdd()">Agregar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<button #seeBeneficiaryTri type="button" class="btn d-none" data-toggle="modal" data-target="#seeBeneficiary"></button>
<button #deleteBeneficiaryTri type="button" class="btn d-none" data-toggle="modal"
     data-target="#deleteBeneficiary"></button>
<button #addBeneficiaryTri type="button" class="btn d-none" data-toggle="modal" data-target="#addBeneficiary"></button>