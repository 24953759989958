import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ScheduleService } from 'src/app/services/schedule.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';



@Component({
  selector: 'app-disponibilidad-horario',
  templateUrl: './disponibilidad-horario.component.html',
  styleUrls: ['./disponibilidad-horario.component.css']
})
export class DisponibilidadHorarioComponent implements OnInit {

  _title = 'Mi perfil';
  _subtitle = 'Disponibilidad de horarios'

  _usuario: any = {};

  hoursDay: Array<any> = [];
  hours_list_doc: Array<any> = [];          //listado de horas del doctor, del mes
  hours_selected_day: Array<any> = [];      //horas del dia seleccionado

  selected_day: any = {};                   //día seleccionado
  dia_disponible: boolean = false;          //si el dia tiene horas disponibles

  weekdays: Array<any> = [
    {name: 'Lunes'},
    {name: 'Martes'},
    {name: 'Miércoles'},
    {name: 'Jueves'},
    {name: 'Viernes'},
    {name: 'Sábado'},
    {name: 'Domingo'},
  ]

  constructor(
    public _schedule: ScheduleService,

  ) { }

  ngOnInit() {
    this._usuario = new EncriptacionService().getUserInfo();
    this.getHorasBloques();
    this.getHorasMes(this._usuario.doc_id,null);
    
    this.makeWeek();
  }

  makeWeek(){
    let hoy = new Date();
    let hoy_int = `${hoy.getFullYear()}${((hoy.getMonth() +101).toString()).substring(1, 3)}${((hoy.getDate() + 100).toString()).substring(1, 3)}`;

    for (let [index, item] of this.weekdays.entries()){ //for(let item of this.weekdays){ //
      let dia = new Date();
      dia.setDate(hoy.getDate() - hoy.getDay() + 1 + index);
      item.date = dia;
      item.int = `${dia.getFullYear()}${((dia.getMonth() +101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`;
      item.selected = (item.int == hoy_int )? true: false;
    }
  }

  getHorasBloques(){ 
    this._schedule.getScheduleTimes()
    .then( (data: any) => {
      this.hoursDay = data;
    })
  }

  // consulta las horas del doctor del mes
  getHorasMes(doctor_id, mes){
    this._schedule.getScheduleHoursDoc(doctor_id, mes, null)
      .then( (data: any) => {
        this.hours_list_doc = data;
        for (let dia_sch of this.hours_list_doc){
          let dia = new Date(dia_sch.sch_date)
          dia_sch.int = `${dia.getFullYear()}${((dia.getMonth() +101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`;
        }
        this.clickDayWeek(new Date()) // se posiciona en el dia 
    })
  }

  clickDayWeek(dia){
    this.dia_disponible = false;
    this.selected_day.int = `${dia.getFullYear()}${((dia.getMonth() +101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`;

    this.hours_selected_day = this.hoursDay;

    for (let itemWeek of this.weekdays){ 
      itemWeek.selected = (itemWeek.int == this.selected_day.int )? true: false;
    }

    for (let dia_sch of this.hours_list_doc){
      if(this.selected_day.int == dia_sch.int){ //el doctor tine horas el dia seleccionado
        this.dia_disponible = true

        let blocks = [];
        for (let block of this.hoursDay){
          let ind = dia_sch.hours.findIndex( (hour) => hour.from == block.from );
          if ( ind !== -1 ) {
            blocks.push(dia_sch.hours[ind]);
          }
          else{
            blocks.push(block)
          }
        }
        this.hours_selected_day = blocks;
        break;
      }
    }
  }

  

}