import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';



@Injectable({
  providedIn: 'root'
})
export class PendingSurveysService {
  
  private showModal: boolean = true;


  constructor(
    public http: HttpClient,
    public _errors: ErrorsService,  ) { }

    setShowModal(value: boolean) {
      this.showModal = value;
    }
  
    getShowModal() {
      return this.showModal;
    }

    async postPendingSurveys(){

      let httpOptions = {
        headers: new HttpHeaders({
          'authorization': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        })
      };
      let params ={}

      try {
        let data = await this.http.post(URL_SERVICIOS + '/pendingSurveys', (params), httpOptions).toPromise();
        return data;
      }
      catch (err) {
        this._errors.requestError(err);
        throw err;
      }

    }

    async updateSurveySkipped(telDocAppBooId: number) {
      let httpOptions = {
        headers: new HttpHeaders({
          'authorization': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        })
      };
      let params = { tel_doc_app_boo_id: telDocAppBooId };
  
      try {
        let data = await this.http.post(URL_SERVICIOS + '/updateSurveySkipped', params, httpOptions).toPromise();
        console.log('Survey marked as skipped:', data);
        return data;
      } catch (err) {
        console.error('Error in updateSurveySkipped:', err);
        throw err;
      }
    }
    

}
