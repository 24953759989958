<div *ngIf="eventos">

  <div *ngIf="consulta">
    <button class="btn btn-outline-primary" (click)="verConsultas()"
      style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;" data-toggle="collapse"
      aria-controls="carta">Mostrar Consultas Pasadas
    </button>
  </div>

  <div *ngIf="consultas.length > 0" ngDefaultControl>
    <table class="table">
      <thead style="font-size: 20px;font-weight: bold; color: #000000">
        <tr>
          <th>
            <div *ngIf="!solo_ver">
              Médico
            </div>
            <div *ngIf="solo_ver">
              Paciente
            </div>
          </th>
          <th>Especialidad</th>
          <th>Fecha</th>
          <th>Hora</th>
          <th>Consulta</th>
          <th>Chat</th>
        </tr>
      </thead>
      <tbody style="font-size: 20px;color: #000000">

        <!-- <th scope="row">15</th> -->
        <tr *ngFor="let item of consultas">

          <td>
            <div *ngIf="!solo_ver">
              {{item.nombre_doctor}} {{item.apellido_paterno_doctor}} {{item.apellido_materno_doctor}}
            </div>
            <div *ngIf="solo_ver">
              {{item.nombre_paciente}} {{item.apellido_paterno_paciente}}
              {{item.apellido_materno_paciente}}
            </div>
          </td>
          <td>{{item.spe_name}}</td>
          <td>{{item.fecha_consulta| date:'dd-MM-yyyy'}}</td>
          <td>{{item.hora}}</td>
          <td>
            <button *ngIf="usuario.typ_name == 'MEDICO'" class="btn btn-outline-primary"
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="VerFicha(item)">Ver Ficha Médica
            </button>

            <button *ngIf="usuario.typ_name == 'PACIENTE'" class="btn btn-outline-primary" 
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="VerFicha(item)">Más información
            </button>
          </td>
          <td>            
            <button  class="btn btn-outline-primary" 
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="irChat(item)">Ir al Chat
            </button>
          </td>
        </tr>

      </tbody>
    </table>

  </div>




  <div *ngIf="ficha" >

  <div *ngIf="accion>0 && !enable" id="edicion">
    <tr>
      <td style="padding:20px;">


        <div *ngIf="accion>0 ">
          <div class="doctor-card" style="padding:20px;" id="card-consulta">
            <h2>{{titulo}}</h2> 
            <br>

            <h2>{{con_aux.nombre_doctor}} {{con_aux.apellido_paterno_doctor}}
              {{con_aux.apellido_materno_doctor}}</h2>
            <h3>{{con_aux.spe_name}}</h3>
            <br>

            <form name="form" style="text-align: left" #f1="ngForm">

              <div *ngIf="!enable" ngModel ngDefaultControl>
                <button data-toggle="modal" data-target="#HistorialModal"
                class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;">
                    Historial
                </button>
              </div>

              <div *ngIf="accion==1">
                <label for="">Diágnostico:</label>
                <select class="form-control" [(ngModel)]="id_diagnostico" name="id_diagnostico" [disabled]="enable">
                  <option *ngFor="let diagnostico of diagnosticos.diagnosticos" [value]='diagnostico.dia_id'>
                    {{diagnostico.dia_name}}</option>

                </select>
                <br>
              </div>

              <div *ngIf="accion==2">
                <label for="">Tipo Exámen:</label>
                <select class="form-control" [(ngModel)]="id_exam" name="id_exam" [disabled]="enable">
                  <option *ngFor="let examen of examenes.examenes" [value]='examen.exa_typ_id'>
                    {{examen.exa_typ_name}}</option>

                </select>
                <br>
              </div>

              <div *ngIf="accion==3">
                <label for="">Tipo Receta:</label>
                <select class="form-control" [(ngModel)]="id_receta" name="id_receta" [disabled]="enable">
                  <option *ngFor="let receta of recetas.recetas" [value]='receta.pre_typ_id'>
                    {{receta.pre_typ_name}}</option>

                </select>
                <br>
              </div>

              <div *ngIf="accion==7">

                <label >Seleccione Centro Médico</label>
                <div >
                    <ng2-completer name="id_clinica"
                        (selected)="changeClinica($event)"
                        [textNoResults]="'Centro no encontrado'"
                        [datasource]="clinicas"
                        [(ngModel)]="searchStr"
                        [minSearchLength]="0"
                        [placeholder]="'Seleccione Centro Médico'"
                        #id_clinica="ngModel"
                        [autoMatch]="true"
                        [inputClass]="'form-control'"
                        [clearUnselected]="true"
                        [openOnFocus]="true"
                        [disabled]="enable"
                        required>
                    </ng2-completer>
                </div>

              </div>

              <textarea class="form-control" [(ngModel)]="comentario" name="comentario" rows="6" [disabled]="enable"></textarea>

                <label for="">Motivo Actualización:</label>
                <select class="form-control" name="id_causal" [(ngModel)]="id_causal" [disabled]="enable">
                  <option *ngFor="let causal of causales.causales" [value]='causal.cau_id'>
                    {{causal.cau_description}}</option>

                </select>
                <br>
              

              <h3>Fecha: {{fecha_sistema|date:'dd-MM-yyyy'}}</h3>
                <button type="submit" class="btn btn-outline-primary"
                  style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                  (click)="crearOrden(f1)" [disabled]="enable">{{subtitulo}}</button>
            </form>

          </div>
        </div>
      </td>
    </tr>

  </div>

  <div *ngIf="accion>0 && enable" >
    <tr>
      <td style="padding:20px;">


        <div *ngIf="accion>0">
          <div class="doctor-card" style="padding:20px;" id="card-consulta" id="pdf">
            <h2>{{titulo}}</h2> 
            <br>
            <h2>Paciente: {{con_aux.nombre_paciente}} {{con_aux.apellido_paterno_paciente}}
              {{con_aux.apellido_materno_paciente}}</h2>
            <!-- <h3>{{con_aux.spe_name}}</h3> -->

            <br>
            <div class="doctor-card" style="padding:10px;">
                <div *ngIf="accion==1">
                  <h3 for="">Diágnostico: {{aux.dia_name}}</h3>
                  <br>
                </div>

                <div *ngIf="accion==2">
                  <h3 for="">Tipo Exámen: {{aux.exa_typ_name}}</h3>
                  <br>
                </div>

                <div *ngIf="accion==3">
                  <h2>Tipo Receta: {{aux.pre_typ_name}}</h2>
                  <br>
                </div>

                <div *ngIf="accion==7">

                  <h3 >Centro Médico: {{aux.hos_name}}</h3>


                </div>

                <br>
                <div class="card">
                  <h3>{{comentario}}</h3>
                </div>
                <br>
                  
              </div>

              <h2>Médico: {{con_aux.nombre_doctor}} {{con_aux.apellido_paterno_doctor}}
                {{con_aux.apellido_materno_doctor}}</h2>
              <h3>Especialidad: {{con_aux.spe_name}}</h3>
              <br>
              <br>
              <br>
              <br>
              <br>
              <h3>Fecha: {{fecha_sistema|date:'dd-MM-yyyy'}}</h3>
                <!-- <button class="btn btn-outline-primary"
                  style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                  (click)="generarPdf()">Descargar {{subtitulo}}</button> -->
            

          </div>
        </div>
      </td>
    </tr>

  </div>

    <table class="table">
      <thead style="font-size: 20px;font-weight: bold; color: #000000">
        <tr>
          <th>Tipo</th>
          <th>Fecha</th>
          <th>Descripción:</th>
          <th *ngIf="usuario.typ_name == 'MEDICO'">Acción</th>
        </tr>
      </thead>
      <tbody style="font-size: 20px;color: #000000">

        <!-- <th scope="row">15</th> -->
        <tr *ngFor="let item of ficha.orders">
          <td>{{item.med_ord_typ_name}} </td>
          <td>{{item.med_ord_date| date:'dd-MM-yyyy'}}</td>
          <td>{{item.med_ord_his_comment}}</td>
          <td > 
            <div>
             
              <button *ngIf="modificar" class="btn btn-outline-primary"
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="verOrder(item)">Editar</button>

              <button *ngIf="modificar" class="btn btn-outline-primary"
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="eliminarOrder(item)">Eliminar</button>
                             
              <button class="btn btn-outline-primary"
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="SoloVerOrder(item)">Ver</button>

              <button class="btn btn-outline-primary"
              style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
              (click)="descargarOrder(item)">Descargar</button>
              
            </div>

          </td>


        </tr>

        <tr *ngFor="let item of ficha.diagnostics">
          <td>DIAGNOSTICO </td>
          <td>{{item.diag_det_his_date| date:'dd-MM-yyyy'}}</td>
          <td>{{item.dia_name}}</td>
          <td>
            <div >
              <button *ngIf="modificar" class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="verDiagnostic(item)">Editar</button>

              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="SoloVerDiagnostic(item)">Ver</button>

                <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="descargarDiagnostic(item)">Descargar</button>
            </div>
          </td>
        </tr>

        <tr *ngFor="let item of ficha.prescriptions">
          <td>RECETA</td>
          <td>{{item.pre_his_date| date:'dd-MM-yyyy'}}</td>
          <td>{{item.pre_typ_name}}: {{item.pre_his_indication}}</td>
          <td>
            <div *ngIf="modificar">
              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="verPrescription(item)">Editar</button>
                
              <button *ngIf="modificar" class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="eliminarPrescription(item)">Eliminar</button>
                
              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="SoloVerPrescription(item)">Ver</button>
              
              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="descargarPrescription(item)">Descargar</button>
            </div>
            


          </td>
        </tr>

        <tr *ngFor="let item of ficha.anamnesis">
          <td>CARTA PACIENTE</td>
          <td>{{item.med_con_his_date_attention| date:'dd-MM-yyyy'}}</td>
          <td>{{item.med_con_his_anamnesis}}</td>
          <td>
            <div>
              <button  *ngIf="modificar" class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="verAnamnesis(item)">Editar</button>

              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="SoloVerAnamnesis(item)">Ver</button>

              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="descargarAnamnesis(item)">Descargar</button>
            </div>

          </td>
        </tr>

        <tr *ngFor="let item of ficha.derivation">
          <td>DERIVACIÓN</td>
          <td>{{item.der_his_date| date:'dd-MM-yyyy'}}</td>
          <td>{{item.der_his_doctor}}</td>
          <td>
            <div >
              <button *ngIf="modificar" class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="verDerivation(item)">Editar</button>

              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="SoloVerDerivation(item)">Ver</button>

              <button class="btn btn-outline-primary"
                style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                (click)="descargarDerivation(item)">Descargar</button>
            </div>

          </td>
        </tr>

      </tbody>
    </table>




  </div>
</div>





<!-- Modal historial hora -->
<!-- <div *ngIf="false" class="modal fade" id="historialModal" tabindex="-1" role="dialog" aria-labelledby="historialModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title titulo-modal" id="historialModalLabel">Datos de la reserva</h5>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead style="font-size: 20px;font-weight: bold; color: #000000">
            <tr>
              <th>Tipo</th>
              <th>Fecha</th>
              <th>Descripción:</th>
              <th>Modificar</th>
            </tr>
          </thead>
          <tbody style="font-size: 20px;color: #000000">
            <tr *ngFor="let item of ficha.orders" ngDefaultControl>
              <td>{{item.med_ord_typ_name}} </td>
              <td>{{item.med_ord_date| date:'dd-MM-yyyy'}}</td>
              <td>{{item.med_ord_his_comment}}</td>
            </tr>
            <tr *ngFor="let item of ficha.diagnostics" ngDefaultControl>
              <td>DIAGNOSTICO </td>
              <td>{{item.diag_det_his_date| date:'dd-MM-yyyy'}}</td>
              <td>{{item.dia_name}}</td>
            </tr>
            <tr *ngFor="let item of ficha.prescriptions" ngDefaultControl>
              <td>RECETA</td>
              <td>{{item.pre_his_date| date:'dd-MM-yyyy'}}</td>
              <td>{{item.pre_typ_name}}: {{item.pre_his_indication}}</td>
            </tr>
            <tr *ngFor="let item of ficha.anamnesis" ngDefaultControl>
              <td>CARTA PACIENTE</td>
              <td>{{item.med_con_his_date_attention| date:'dd-MM-yyyy'}}</td>
              <td>{{item.med_con_his_anamnesis}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cerrar</button>
      </div>
    </div>
  </div>
</div> -->



<!-- Modal -->

<div class="modal fade" id="HistorialModal" tabindex="-1" role="dialog" aria-labelledby="historialModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title titulo-modal" id="historialModalLabel"></h5>
      </div>
      <div class="modal-body">  

        <div style="text-align: center">
          <table class="table">
            <thead style="font-size: 20px;font-weight: bold; color: #000000">
              <tr>
                <th>Fecha</th>
                <th>Descripción:</th>
                <th>Motivo Actualización:</th>
              </tr>
            </thead>
            <tbody style="font-size: 20px;color: #000000">
      
              <!-- <th scope="row">15</th> -->
              <tr *ngFor="let item of historial.consulta" ngModel ngDefaultControl>
                <td>{{item.fecha| date:'dd-MM-yyyy'}}</td>
                <td>{{item.descripcion}} </td>
                <td>{{item.motivo}} </td>

      
      
              </tr>

      
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  </div>
</div>