
<div class="col-12 divContent">
     <ng-container *ngIf="page.main_page">
          <button class="primary buttonStyle" (click)="setPage({add:true})">Agregar Usuario</button>
          <br>
          <button class="primary buttonStyle" (click)="setPage({edit:true})">Editar Usuario</button>

     </ng-container>
     <ng-container *ngIf="!page.main_page">
          <div class="col-12 divContent">
               <button class=" buttonGoBack" (click)="setPage({main_page:true})"><mat-icon style="margin-right: 5px;">chevron_left</mat-icon>Volver</button>
          </div><br>
     </ng-container>

     <ng-container *ngIf="page.add">
          <app-add-user></app-add-user>
     </ng-container>

     <ng-container *ngIf="page.edit">
          <app-edit-user></app-edit-user>
     </ng-container>

</div>


