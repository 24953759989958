import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }

  async listBeneficiariesCollaborators() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/listBeneficiariesCollaborators', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async deleteCollaborator({ben_id = null, cus_dat_id = null}) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {ben_id,cus_dat_id};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/deleteCollaborator',params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async addCollaborator({rut = null, cus_ben_id = null}) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {rut,cus_ben_id};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/addCollaborator',params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }


  async searchBeneficiary({rut = null}) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {rut};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/searchBeneficiary',params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

}
