import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { CusIdService } from '../../services/cus_id.service';
import { AntecedentesMedicosService } from '../../services/antecedentes_medicos.service';

import Swal from 'sweetalert2';
import { Usuario } from '../../models/usuario.model';
import { EncriptacionService } from 'src/app/services/encriptacion.service';


@Component({
  selector: 'app-antecedentes',
  templateUrl: './antecedentes.component.html',
  styleUrls: ['./antecedentes.component.css']
})
export class AntecedentesComponent {
  _usuario: any = {};

  constructor(private fb: FormBuilder,
    public antecedentesMedicos: AntecedentesMedicosService,
    private cusidService: CusIdService) {
      this._usuario = new EncriptacionService().getUserInfo();
    }

}
