<!--Titulo-->
<div class="col-md-12">
  <br>
  <div class="titulo">
    Mesa de Ayuda
  </div>
  <div class="minititulo" style="font-weight: 800;">
    Canales de atención
  </div>
  <br>
</div>
<div class="row">
  <div class="col-12">
    <div class="row d-sm-flex">
      <div class="col-md-6">
        <div class="card cardBorde p-0 m-0">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 d-flex">
            <div class="m-0 p-0 first-line" style="width: 100%;">
              <div class="img-cirlce-wrapper">
                <span class="card-body-img d-flex"><mat-icon>phone</mat-icon></span>
              </div>
              <div class="ml-3 align-self-center">
                <p class="card-body-titulo"> Puedes llamarnos al: </p>
                <p *ngIf="pageInfo.cus_url_phone" class="card-body-color">{{pageInfo.cus_url_phone}}</p>
              </div>
            </div>
            <br>
          </div>
        </div>
        <br>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="card cardBorde p-0 m-0">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 d-flex">
            <div class="m-0 p-0 first-line"  style="width: 100%;">
              <div class="img-cirlce-wrapper">
                <span class="card-body-img d-flex"><mat-icon>mail</mat-icon></span>
              </div>
              <div class="ml-3 align-self-center">
                <p class="card-body-titulo"> Escribenos por correo electrónico: </p>
                <p class="card-body-color"> contacto@almamedis.cl </p>
              </div>
            </div>
            <br>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

<!--Mas ayuda-->
<div class="col-md-12">
  <br>
  <div class="minititulo" style="font-weight: 800;">
    ¿En qué te podemos ayudar?
  </div>
</div>

<!--Card carousel-->
<div class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
  <div class="col-12">
    <div class="row">
      <div class="col-6 col-sm-4 p-4 canHelp">
        <div class="row h-100 align-items-start align-items-sm-center p-4">
          <div class="col-4 col-sm-2 m-0 p-0">
            <img src="assets/images/icon/doctor-b.svg" alt="utilizar alamedis 1">
          </div>
          <div class="col m-0 p-0 ml-sm-2">
            <p>
              <span>Orientación médica telefónica.</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-4 p-4 canHelp">
        <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
          <div class="col-4 col-sm-2 m-0 p-0">
            <img src="assets/images/icon/protection-b.svg" alt="utilizar alamedis 1">
          </div>
          <div class="col m-0 p-0 ml-sm-2">
            <p>
              <span>Gestión de agenda y consultas.</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-4 p-4 canHelp">
        <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
          <div class="col-4 col-sm-2 m-0 p-0">
            <img src="assets/images/icon/file-b.svg" alt="utilizar alamedis 1">
          </div>
          <div class="col m-0 p-0 ml-sm-2">
            <p>
              <span>Solución de problemas durante la videoconsulta.</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-4 p-4 canHelp">
        <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
          <div class="col-4 col-sm-2 m-0 p-0">
            <img src="assets/images/icon/mobile-phone.svg" alt="utilizar alamedis 1">
          </div>
          <div class="col m-0 p-0 ml-sm-2">
            <p>
              <span>Soporte tecnológico sobre la plataforma.</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-4 p-4 canHelp">
        <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
          <div class="col-4 col-sm-2 m-0 p-0">
            <img src="assets/images/icon/doctor-chat-b.svg" alt="utilizar alamedis 1">
          </div>
          <div class="col m-0 p-0 ml-sm-2">
            <p>
              <span>Resolución de dudas sobre servicio.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Mas ayuda-->
<div class="col-md-12">
  <br>
  <div class="minititulo" style="font-weight: 800;">
    Preguntas frecuentes
  </div>
</div>
<br>
<div class="card-faqs">
  <div id="accordion2" role="tablist" aria-multiselectable="true">

    <ng-container *ngFor="let section of questions; let i = index;">
      <div class="card-faqs m-b-0">
        <div [class]="i == 0 ? 'card-header card-borde-top': i == (questions.length-1) ? 'card-header card-borde-bottom' : 'card-header'" role="tab" [id]="section.faq_tit_order">
          <h5 class="mb-0">
            <a class="multi-collapse link color-title-faqs" data-toggle="collapse" data-parent="#accordion2"
            [attr.href]="'#collapseSection'+section.faq_tit_order" aria-expanded="true" [attr.aria-controls]="'collapseSection'+section.faq_tit_order">
              {{section.faq_tit_title_group}}
            </a>
          </h5>
        </div>
        <div [id]="'collapseSection'+section.faq_tit_order" class="collapse" role="tabpanel" [attr.aria-labelledby]="section.faq_tit_order">
          <!--PREGUNTA 1-->
          <ng-container *ngFor="let question of section.questions">
            <div class="card-faqs card-body card-body-faqs">
              <div class="card-faqs col-md-12 col-md-12-padding">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-1 col-xs-1">
                      <p class="estilo-numero">{{question.faq_sub_tit_order}}</p>
                    </div>
                    <div class="card-faqs card-body card-body-faqs col-md-10 col-xs-10" role="tab">
                      <div class="col-md-11 col-xs-11">
                        <div class="row">
                          <div class="col-md-10 col-xs-11">
                            <h5 class="color-subtitle-faqs">
                              <a class="collapsed link color-subtitle-faqs" data-toggle="collapse" [attr.href]="'#pregunta'+question.faq_sub_tit_order+'Section'+section.faq_tit_order"
                                aria-expanded="false" [attr.aria-controls]="'pregunta'+question.faq_sub_tit_order+'Section'+section.faq_tit_order">
                                {{question.faq_sub_tit_question}}
                              </a>
                            </h5>
                          </div>
  
                        </div>
                      </div>
                      <div [id]="'pregunta'+question.faq_sub_tit_order+'Section'+section.faq_tit_order" class="collapse multi-collapse">
                        <div class="col-md-10 color-text-faqs">
                          <ng-container *ngFor="let answer of question.faq_sub_tit_answer|split:['\n']">
                            <p [innerHtml]="answer"></p>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- FIN PREGUNTA 1-->
          <!-- pregunta 2 seccion 1-->
        </div>
        <!-- FIN COLLAPSE-->
      </div>
    </ng-container>
  </div>
</div>