<h1 *ngIf="!on_dashboard" class="title" style="margin: 25px 0">Otras prestaciones y beneficios de salud</h1>



<div class="row" style="margin: 20px 0;" *ngIf="!on_dashboard">
    <div class="col-md-12 col-12">
        <div class="row">
            <div class="col-md-6 col-12">
                <h2 *ngIf="!on_dashboard" class="subTitle">Prestaciones</h2>
            </div>
            <div class="col-md-6 col-12">
                <input autocomplete="disabled" type="search" placeholder='Ingrese una prestación…' class="inputStyle"
                    [(ngModel)]="searchText">
            </div>
        </div>
    </div>
</div>

<h2 *ngIf="on_dashboard" class="subTitle" (click)="goToAgreements()" style="cursor: pointer;">Otras prestaciones de salud para ti</h2>

<ng-container *ngIf="agreements && agreements.length > 0;else noAgreements">
    <div class="row row-agreement">
        <ng-container *ngIf="!on_dashboard;">
            <ng-container *ngFor="let agreement of agreements | filter:searchText">
                <div class="col-lg-3 col-md-4 col-sm-6 " style="padding: 15px;">
                    <div class="card cardAgreement" (click)="goToAgreement(agreement)">
                        <img (click)="goToAgreement(agreement)" class="svgItem"
                            [src]="URL_DOCS+agreement.agre_typ_svg |safeUrl " />
                        <p class="agreementStyle">{{agreement.agre_typ_title}}</p>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="on_dashboard;">

            <ng-template #onDashboardDesktop class="d-none d-md-block">
                <div class="col-12">

                    <div id="carouselExampleControls" class="row carousel slide" data-ride="carousel"
                        style="width: 100%; display: flex; margin-bottom: 1.5rem;">
                        <div class="col-1" style="display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;">
                            <div class="arrowCarrousel" href="#carrouserAgreements2" role="button" data-slide="prev">
                                <mat-icon style="color:#316AD8;">
                                    chevron_left
                                </mat-icon>
                            </div>
                        </div>
                        <div class="col-10">
                            <div id="carrouserAgreements2" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div *ngFor="let chunk of chunkArray(agreements, 3); let i = index"
                                        [class]="(i == 0) ? 'carousel-item active' : 'carousel-item'">
                                        <div class="row">
                                            <ng-container *ngFor="let agreement of chunk">
                                                <div class="col-md-4" style="padding: 15px;">
                                                    <div class="card cardAgreement" (click)="goToAgreement(agreement)">
                                                        <img (click)="goToAgreement(agreement)" class="svgItem"
                                                            [src]="URL_DOCS+agreement.agre_typ_svg | safeUrl" />
                                                        <p class="agreementStyle">{{agreement.agre_typ_title}}</p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1" style="display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;">
                            <div class="arrowCarrousel" href="#carrouserAgreements2" role="button" data-slide="next">
                                <mat-icon style="color:#316AD8;">
                                    chevron_right
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template #onDashboardMobile class="d-block d-md-none">
                <div class="col-12">

                    <div id="carouselExampleControls" class="row carousel slide" data-ride="carousel"
                        style="width: auto; display: flex; margin-bottom: 1.5rem; padding: 10px 0;     flex-wrap: nowrap;
                        ">
                        <div class="col-1" style="display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;">
                            <div class="arrowCarrousel" href="#carrouserAgreements2" role="button" data-slide="prev"
                                style="margin-left: 10px;">
                                <mat-icon style="color:#316AD8;">
                                    chevron_left
                                </mat-icon>
                            </div>
                        </div>

                        <div class="col-10">
                            <div id="carrouserAgreements2" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div *ngFor="let agreement of agreements; let i = index"
                                        [class]="(i == 0) ? 'carousel-item active' : 'carousel-item'">
                                        <div class="row">
                                            <div class="col-12" style="padding: 15px;">
                                                <div class="card cardAgreement" (click)="goToAgreement(agreement)">
                                                    <img (click)="goToAgreement(agreement)" class="svgItem"
                                                        [src]="URL_DOCS+agreement.agre_typ_svg | safeUrl" />
                                                    <p class="agreementStyle">{{agreement.agre_typ_title}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1" style="display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;">
                            <div class="arrowCarrousel" href="#carrouserAgreements2" role="button" data-slide="next"
                                style="margin-right: 10px;">
                                <mat-icon style="color:#316AD8;">
                                    chevron_right
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-container *ngIf="isDesktop; then onDashboardDesktop else onDashboardMobile"></ng-container>

        </ng-container>

    </div>
</ng-container>

<ng-template #noAgreements>
    <div class="row cardInfo">
        <div class="col-1" style="display: flex;
        justify-content: center;
        align-items: center;">            <mat-icon style="color: #171994;">info</mat-icon>
        </div>
        <div class="col-11" style="align-self: center;">
            <p class="infoStyleText" style="text-align: justify;">Por el momento no contamos con prestaciones de salud complementarias para ti.
                Nos encontramos trabajando fuertemente para poder brindarte la mejor y más integral experiencia en
                salud.</p>
        </div>
    </div>
</ng-template>

<h2 class="subTitle">{{on_dashboard?'Beneficios exclusivos que te entrega AlmaMedis':'Beneficios'}}</h2>
<app-benefits [benefits]="benefits"></app-benefits>

