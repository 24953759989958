<!-- welcome -->
<div class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12 text-center text-sm-left">
        <h2>Te damos la bienvenida {{ usuario.nombre }}</h2>
    </div>
</div>


<!-- next appointments -->
<div *ngIf="appointments.length > 0 &&(usuario.typ_id == 2)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="pb-3">Mis próximas consultas:</h3>
        <div class="row d-flex d-sm-none">
            <div *ngFor="let appt of appointments" class="col-12 pt-4">
                <app-next-appointment-dashboard [data]="appt"></app-next-appointment-dashboard>
            </div>
        </div>

        <div class="row d-none d-sm-flex">
            <div *ngIf="appointments.length > 2" class="col-1 text-center m-0 p-0 arrow" (click)="nextCard(-1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_left</mat-icon></span>
                </div>
            </div>
            <div *ngFor="let appt of auxAppts"
                [ngClass]="{'col-5': appointments.length > 2, 'col-6': appointments.length === 1 || appointments.length === 2 }">
                <app-next-appointment [data]="appt"></app-next-appointment>
            </div>
            <div *ngIf="appointments.length > 2" class="col-1 text-center m-0 p-0 arrow" (click)="nextCard(1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_right</mat-icon></span>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- next appointments -->
<div *ngIf="pendingAppointmentList.length > 0 &&(usuario.typ_id == 2)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="pb-3">Mis reservas pendientes</h3>
        <div class="row d-flex d-sm-none">
            <div *ngFor="let pendiente of pendingAppointmentList" class="col-12 pt-4">
                <app-next-pending-appointment-dashboard [data]="pendiente"></app-next-pending-appointment-dashboard>
            </div>
        </div>

        <div class="row d-none d-sm-flex">
            <div *ngIf="pendingAppointmentList.length > 2" class="col-1 text-center m-0 p-0 arrow"
                (click)="nextCardPending(-1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_left</mat-icon></span>
                </div>
            </div>
            <div *ngFor="let pendiente of auxPending"
                [ngClass]="{'col-5': pendingAppointmentList.length > 2, 'col-6': pendingAppointmentList.length === 1 || pendingAppointmentList.length === 2 }">
                <app-next-pending-appointment [data]="pendiente"></app-next-pending-appointment>
            </div>
            <div *ngIf="pendingAppointmentList.length > 2" class="col-1 text-center m-0 p-0 arrow"
                (click)="nextCardPending(1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_right</mat-icon></span>
                </div>
            </div>
        </div>
    </div>
</div>







<!-- next appointments acompanantes-->
<div *ngIf="consultas_acomp.length > 0 &&(usuario.typ_id == 2)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="pb-3">Mis próximas consultas como acompañante:</h3>
        <div class="row d-none d-sm-flex">
            <div *ngIf="consultas_acomp.length > 2" class="col-1 text-center m-0 p-0 arrow" (click)="nextCard(-1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_left</mat-icon></span>
                </div>
            </div>
            <div *ngFor="let appt of auxAppts_acomp"
                [ngClass]="{'col-5': consultas_acomp.length > 2, 'col-6': consultas_acomp.length === 1 || consultas_acomp.length === 2 }">
                <app-next-appointment [data]="appt"></app-next-appointment>
            </div>
            <div *ngIf="consultas_acomp.length > 2" class="col-1 text-center m-0 p-0 arrow" (click)="nextCard(1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_right</mat-icon></span>
                </div>
            </div>
        </div>
        <div class="row d-flex d-sm-none">
            <div *ngFor="let appt of consultas_acomp" class="col-12 pt-4">
                <app-next-appointment [data]="appt"></app-next-appointment>
            </div>
        </div>
    </div>
</div>

<!-- next appointments MEDICO-->
<div *ngIf="appointments.length > 0 && (usuario.typ_id == 1)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="pb-3">Mis próximas consultas:</h3>
        <div class="row d-none d-sm-flex">
            <div *ngIf="appointments.length > 2" class="col-1 text-center m-0 p-0 arrow" (click)="nextCard(-1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_left</mat-icon></span>
                </div>
            </div>
            <div *ngFor="let appt of auxAppts"
                [ngClass]="{'col-5': appointments.length > 2, 'col-6': appointments.length === 1 || appointments.length === 2 }">
                <app-next-appointment [data]="appt"></app-next-appointment>
            </div>
            <div *ngIf="appointments.length > 2" class="col-1 text-center m-0 p-0 arrow" (click)="nextCard(1)">
                <div class="m-0 p-0">
                    <span><mat-icon>chevron_right</mat-icon></span>
                </div>
            </div>
        </div>
        <div class="row d-flex d-sm-none">
            <div *ngFor="let appt of appointments" class="col-12 pt-4">
                <app-next-appointment [data]="appt"></app-next-appointment>
            </div>
        </div>
    </div>
</div>
<!-- dashboard gestion -->
<div class="card" *ngIf="usuario.typ_id == 13">
    <div class="card-body">
        <h5 class="card-title">Disponibilidad Medica</h5>
        <br>

        <table class="table table-sm table-hover">
            <thead>
                <tr>
                    <th scope="col">FECHA</th>
                    <th scope="col" class="header-table">TOTAL DE HORAS HABILITADAS</th>
                    <th scope="col" class="header-table">HORAS DISPONIBLES</th>
                    <th scope="col" class="header-table">RESERVADAS</th>
                    <th scope="col" class="header-table">PORCENTAJE DISPONIBILIDAD</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dias of disponibilidadKPI">
                    <th scope="row">{{dias.fecha | date}}</th>
                    <td>{{dias.horas_totales}}</td>
                    <td>{{dias.horas_disponibles}}</td>
                    <td>{{dias.horas_reservadas}}</td>
                    <td>{{dias.porcentaje_de_disponibilidad}}%</td>
                </tr>


            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-12" style="justify-content: flex-end;
            display: flex;">
                <button type="button" routerLink="/gestion-disponibilidad" class="btn btn-primary">
                    Ver más
                </button>
            </div>
        </div>
    </div>
</div>
<div class="card-columns" *ngIf="usuario.typ_id == 13">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Activación</h5>
            <br>
            <ng-container *ngIf="activacionesKPI">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Indicador</th>
                            <th scope="col" style="text-align:center;">N° / %</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Total Usuarios:</th>
                            <td>{{activacionesKPI.usuarios_asignados}}</td>

                        </tr>
                        <tr>
                            <th scope="row">Total activos:</th>
                            <td>{{activacionesKPI.active_users}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Total inactivos:</th>
                            <td>{{activacionesKPI.inactive_users}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Porcentaje activos:</th>
                            <td>{{activacionesKPI.active_users_percentage}} %</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12" style="justify-content: flex-end;
                display: flex;">
                    <button type="button" routerLink="/gestion-activacion" class="btn btn-primary">
                        Ver más
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Evaluación del servicio</h5>
            <br>
            <ng-container *ngIf="surveyListKPI">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Indicador</th>
                            <th scope="col" style="text-align: center">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Cantidad consultas:</th>
                            <td>{{evaluacionesKPI.total_consultas}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Cantidad consultas evaluadas:</th>
                            <td>{{evaluacionesKPI.consultas_respondidas}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Pregunta N°1:</th>
                            <td>{{evaluacionesKPI.pregunta_1}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Pregunta N°2:</th>
                            <td>{{evaluacionesKPI.pregunta_2}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Pregunta N°3:</th>
                            <td>{{evaluacionesKPI.pregunta_3}}</td>
                        </tr>

                    </tbody>
                </table>
            </ng-container>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12" style="justify-content: flex-end;
                display: flex;">
                    <button type="button" routerLink="/gestion-evaluaciones" class="btn btn-primary">
                        Ver más
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="card-group">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Disponibilidad dia siguiente</h5>

        <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-12" style="justify-content: flex-end;
            display: flex;">
                <button type="button" routerLink="/gestion-disponibilidad" class="btn btn-primary">
                    Ver más
                </button>
            </div>
        </div>
    </div>
  </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Disponibilidad dia sub-siguiente</h5>

        <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-12" style="justify-content: flex-end;
            display: flex;">
                <button type="button" routerLink="/gestion-disponibilidad" class="btn btn-primary">
                    Ver más
                </button>
            </div>
        </div>
    </div>
    </div>
   
  </div> -->


<!-- how to? -->
<div *ngIf="(usuario.typ_id == 2)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="mb-3">¿Cómo utilizar AlmaMedis?</h3>
        <div class="card-deck">
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-1.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">1</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Reserva tu hora</p>
                    <p class="card-text">Selecciona el área médica o un médico/profesional específico.</p>
                    <button type="button" (click)="reservaHora()" class="btn btn-primary">
                        Reserva tu hora
                    </button>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-2.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">2</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Selecciona la fecha y la hora</p>
                    <p class="card-text">Elige el día que más te acomode para tu consulta y paga utilizando un convenio
                        o medio de pago personal.</p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-3.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">3</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Conéctate 5 minutos antes de tu cita</p>
                    <p class="card-text">Y participa de tu consulta de telemedicina.</p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-4.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">4</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Recibe tu diagnóstico y tus documentos</p>
                    <p class="card-text">Si algo se te olvidó ¡no te preocupes! Tendrás 24 horas para conversar con tu
                        médico.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="(usuario.typ_id == 2)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <app-otras-prestaciones style="display: flex;
        flex-direction: column;
        align-items: center;" [on_dashboard]="true"></app-otras-prestaciones>
    </div>
</div>

<!-- perifl contactcenter -->
<div *ngIf="(usuario.typ_id == 7)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class=" col-12">
        <app-busca-info></app-busca-info>
    </div>
</div>

<div *ngIf="(usuario.typ_id == 7) || (usuario.typ_id == 12)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="mb-3">¿Cómo utilizar AlmaMedis?</h3>
        <div class="card-deck">
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-1.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">1</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Reserva tu hora</p>
                    <p class="card-text">Selecciona el área médica o un médico/profesional específico.</p>
                    <button type="button" (click)="reservaHora()" class="btn btn-primary">
                        Reserva tu hora
                    </button>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-2.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">2</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Selecciona la fecha y la hora</p>
                    <p class="card-text">Elige el día que más te acomode para tu consulta.</p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-3.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">3</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Conéctate 5 minutos antes de tu cita</p>
                    <p class="card-text">Y participa de tu consulta de telemedicina.</p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="assets/images/home/utilizar-4.jpg" alt="utilizar alamedis 1">
                <div class="mr-4 step-overlay pt-3 pb-2 px-3">4</div>
                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                    <p class="card-text">Recibe tu diagnóstico y tus documentos</p>
                    <p class="card-text">Si algo se te olvidó ¡no te preocupes! Tendrás 24 horas para conversar con tu
                        médico.</p>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- benefits -->
<div *ngIf="(usuario.typ_id == 2) || (usuario.typ_id == 8)" class="row mb-2 mb-sm-4 mt-1 mt-sm-4">
    <div class="col-12">
        <h3 class="mb-3">Beneficios de AlmaMedis</h3>
        <div class="row">
            <div class="col-6 col-sm-4 p-4 benefit">
                <div class="row h-100 align-items-start align-items-sm-center p-4">
                    <div class="col-4 col-sm-2 m-0 p-0">
                        <img src="assets/images/icon/doctor-b.svg" alt="utilizar alamedis 1">
                    </div>
                    <div class="col m-0 p-0 ml-sm-2">
                        <p>
                            <span>Atención personalizada</span>
                            de médicos generales y especialistas.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 p-4 benefit">
                <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
                    <div class="col-4 col-sm-2 m-0 p-0">
                        <img src="assets/images/icon/protection-b.svg" alt="utilizar alamedis 1">
                    </div>
                    <div class="col m-0 p-0 ml-sm-2">
                        <p>
                            <span>Protección a tu información</span>
                            personal con los más altos estándares de seguridad.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 p-4 benefit">
                <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
                    <div class="col-4 col-sm-2 m-0 p-0">
                        <img src="assets/images/icon/file-b.svg" alt="utilizar alamedis 1">
                    </div>
                    <div class="col m-0 p-0 ml-sm-2">
                        <p>
                            <span>Acceso inmediato</span>
                            a tus órdenes de exámenes y recetas médicas.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 p-4 benefit">
                <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
                    <div class="col-4 col-sm-2 m-0 p-0">
                        <img src="assets/images/icon/healthcare-b.svg" alt="utilizar alamedis 1">
                    </div>
                    <div class="col m-0 p-0 ml-sm-2">
                        <p>
                            <span>Orientación médica telefónica</span>
                            disponible las 24 horas.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 p-4 benefit">
                <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
                    <div class="col-4 col-sm-2 m-0 p-0">
                        <img src="assets/images/icon/doctor-chat-b.svg" alt="utilizar alamedis 1">
                    </div>
                    <div class="col m-0 p-0 ml-sm-2">
                        <p>
                            <span>Chat con tu médico</span>
                            por 24 horas para resolver dudas pendientes.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-4 p-4 benefit">
                <div class="row h-100 align-items-start align-items-sm-center py-2 px-4">
                    <div class="col-4 col-sm-2 m-0 p-0">
                        <img src="assets/images/icon/hospital-b.svg" alt="utilizar alamedis 1">
                    </div>
                    <div class="col m-0 p-0 ml-sm-2">
                        <p>
                            <span>Acceso a una mesa de ayuda</span>
                            telefónica durante horarios de atención.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="usuario.typ_id == 1">
    <app-con-sin-finalizar [use_id]='usuario._id'>
    </app-con-sin-finalizar>
    <br><br>
    <h3 style="font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 32px;
                color: #0E0087;
                mix-blend-mode: normal;
                opacity: 0.9;">Conversaciones pendientes</h3>
    <app-listado-chats>
    </app-listado-chats>
</div>

<div class="modal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Modal body text goes here.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>

<!-- <div *ngIf="usuario.typ_id == 2" class="modal-container" id="surveyModal">
    <div class="modal-dialog" style="padding: 10px;
    border-radius: 20px; height: fit-content;">

            <app-encuesta-modal [pendingSurveys]="pendingSurveysList" (modalClosed)="onModalClosed()"></app-encuesta-modal>
    </div>
</div> -->