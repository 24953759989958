import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { EncriptacionService } from './encriptacion.service';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }





  
  async postCrearRegistroChat(med_con_id,message) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      use_id:sessionStorage.getItem('use_id'),
      med_con_id: med_con_id,
      message: message,
      message_from:new EncriptacionService().getUserInfo().typ_name,

    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postCrearRegistroChat', JSON.stringify(params), httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }


  
  
  async postListarRegistroChat(med_con_id) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      use_id:sessionStorage.getItem('use_id'),
      med_con_id: med_con_id,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarRegistroChat', JSON.stringify(params), httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }
  async postListarMensajePosterior(med_con_id,tel_cha_det_id) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      use_id:sessionStorage.getItem('use_id'),
      med_con_id: med_con_id,
      tel_cha_det_id:tel_cha_det_id
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarMensajePosterior', JSON.stringify(params), httpOptions ).toPromise()
      ////console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }
}