import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { SocketWebRtcService } from 'src/app/services/socket-web-rtc.service';
import { ZoomService } from 'src/app/services/zoom.service';

@Component({
  selector: 'app-video-web-rtc',
  templateUrl: './video-web-rtc.component.html',
  styleUrls: ['./video-web-rtc.component.css']
})
export class VideoWebRtcComponent implements OnDestroy {
  @Input() video_iniciado: any = false;
  public consulta: any = {};

  private usuario: any = {};
  private iceServers: any = {
    iceServers: [ //lista de stun servers a los que se podra conectar el usuario que retornara su ip publica
      {
        urls: "stun:stun.almamedis.cl:3478"
      },
      {
        urls: "turn:turn.almamedis.cl:3478",
        username: "almamedis",
        credential: "%S3Chile%"
      },
      // {
      //   urls: "turn:turn.almamedis.cl:3478",
      //   username: "almamedis",
      //   credential: "%S3Chile%"
      // }
    ]
  }

  public muteFlag = false;
  public hideCameraFlag = false;
  public userVideoList: any[] = [];

  private isStartMeeting: boolean = false;
  private urlParams: any = {};
  // private creator = false;
  public enterSound: any = new Audio('assets/sounds/enter.mp3');
  public leaveSound: any = new Audio('assets/sounds/leave.mp3');

  public userVideoSelected: any = '';
  private codecs_audio: any = {};
  private codecs_video: any = {};
  private codecs_audio_mimeType: any = {};
  private codecs_video_mimeType: any = {};

  

  constructor(
    private _zoomService: ZoomService,
    public web_rtc_socket: SocketWebRtcService,
    private route: ActivatedRoute,
    private router: Router,
    private general_services: GeneralService
  ) {
    this.usuario = new EncriptacionService().getUserInfo();

    this.codecs_audio = RTCRtpReceiver.getCapabilities('audio').codecs;
    //console.log(this.codecs_audio, 'codecs_audio-constructor');
    this.codecs_video = RTCRtpReceiver.getCapabilities('video').codecs;
    //console.log(this.codecs_video, 'codecs_video-constructor');

    this.codecs_audio_mimeType = this.getUniqueListBy(this.codecs_audio, 'mimeType');
    this.codecs_video_mimeType = this.getUniqueListBy(this.codecs_video, 'mimeType');

    //console.log(this.codecs_audio_mimeType, 'codecs_audio_mimeType-constructor');
    //console.log(this.codecs_audio_mimeType, 'codecs_audio_mimeType-constructor');

    this.web_rtc_socket.joined().subscribe(data => this.joined(data));
    this.web_rtc_socket.created().subscribe(data => this.created(data));
    this.web_rtc_socket.full().subscribe(data => this.full(data));
    this.web_rtc_socket.ready().subscribe(data => this.ready(data));
    this.web_rtc_socket.candidate().subscribe(data => this.candidate(data));
    this.web_rtc_socket.answer().subscribe(data => this.answer(data));
    this.web_rtc_socket.leave().subscribe(data => this.leave(data));
    this.web_rtc_socket.offer().subscribe(data => this.offer(data));
    this.web_rtc_socket.participants().subscribe(data => this.participants(data));
    this.web_rtc_socket.endMeetingByDoctor().subscribe(data => this.endMeetingByDoctor(data));
    this.web_rtc_socket.isNotCompatible().subscribe(data => this.isNotCompatible(data));
    this.web_rtc_socket.isNotAuthorized().subscribe(data => this.isNotAuthorized(data));

    this.route.url
      .subscribe((params: any) => {
        this.urlParams = params[0];
        //console.log(this.urlParams); 
      }
      );

  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  // const arr1 = getUniqueListBy(arr, 'place'); 
  joinRoom(consult) {
    this.consulta = consult;
    //console.log(consult);
    //console.log(consult.zoo_mee_room_id);

    let params = {
      roomName: consult.id_consulta,
      token: sessionStorage.getItem('token')
    }
    this.web_rtc_socket.joinRoom(params);
  }

  joined(data) {
    //console.log(data, 'data-joined');
    // this.creator = false;
    //console.log(navigator.mediaDevices, 'navigator.mediaDevices');

    try {
      navigator.mediaDevices
        .getUserMedia({
          audio: true, // o false en caso que no quiera tomar el audio
          video: true,
        })
        .then(stream => {
          this.userVideoSelected = data.idSocket;
          this.userVideoList.push({
            videollamada: stream,
            idSocket: data.idSocket,
            rtcPeerConnection: {},
            userName: this.usuario.use_name,//+' '+this.usuario.use_paternal_surname,
          });
          let data_device = {
            roomName: this.consulta.id_consulta,
            codecs_audio: this.codecs_audio,
            codecs_video: this.codecs_video,
            codecs_audio_mimeType: this.codecs_audio_mimeType,
            codecs_video_mimeType: this.codecs_video_mimeType,
          }
          this.muteFlag = false;
          this.hideCameraFlag = false;
          this.startCall();
          // this.muteButtonFunction();
          // this.hideButtonFunction();
          this.web_rtc_socket.socketEmit("ready", (data_device), null);
        })
        .catch(err => {
          //console.log(err, 'err-getUserMedia');
          this.general_services.modalError('Error al generar video llamada', 'No se puede acceder a los dispositivos, verifique que tiene dispositivos de video y audio.');
          let url = 'dashboard/';
          this.router.navigate([url], { replaceUrl: true });
        });
    } catch (e) {
      //console.log(e, 'e-getUserMedia');
      this.general_services.modalError('Error al generar video llamada', 'No se puede acceder a los dispositivos, verifique que tiene dispositivos de video y audio.');
      let url = 'dashboard/';
      this.router.navigate([url], { replaceUrl: true });
    }

  }

  created(data) {
    try {
      //console.log(data, 'data-created');
      //console.log(this.userVideoList);
      //console.log(navigator.mediaDevices, 'navigator.mediaDevices');
      navigator.mediaDevices
        .getUserMedia({
          audio: true, // o false en caso que no quiera tomar el audio
          video: true,
        })
        .then(stream => {
          this.userVideoSelected = data.idSocket;
          this.userVideoList.push({
            videollamada: stream,
            idSocket: data.idSocket,
            rtcPeerConnection: {},
            userName: this.usuario.use_name//+' '+this.usuario.use_paternal_surname
          });
          //console.log(this.userVideoList, 'this.userVideoList-created');

          this.muteFlag = false;
          this.hideCameraFlag = false;
          this.startCall();
          // this.muteButtonFunction();
          // this.hideButtonFunction();
        })
        .catch(err => {
          console.log(err, 'err-getUserMedia');
          this.general_services.modalError('Error al generar video llamada', 'No se puede acceder a los dispositivos.');
          let url = 'dashboard/';
          this.router.navigate([url], { replaceUrl: true });
        });
    } catch (e) {
      //console.log(e, 'e-getUserMedia');
      this.general_services.modalError('Error al generar video llamada', 'No se puede acceder a los dispositivos, verifique que tiene dispositivos de video y audio.');
      let url = 'dashboard/';
      this.router.navigate([url], { replaceUrl: true });
    }

  }

  full(data) {
    //console.log(data, 'data-full');
    alert("room is full, cant join");
  }

  participants(data) {
    //console.log(data, 'data-participants');
  }

  ready(data) {
    // console.log(data, 'data-ready');
    let isCompatibleData: any = this.codecsCompatibility(data);
    if (isCompatibleData && isCompatibleData.array_audio && isCompatibleData.array_video) {
      let index = this.userVideoList.push({
        videollamada: null,
        idSocket: data.idSocket,
        rtcPeerConnection: new RTCPeerConnection(this.iceServers),
        userName: '',
        codecs_audio: isCompatibleData.array_audio,
        codecs_video: isCompatibleData.array_video
      });
      if (data && data.idSocket && data.idSocket != this.userVideoList[0].idSocket) {
        this.userVideoSelected = data.idSocket;
      }

      index--;
      let data_set_codecs = {
        data: {
          codecs_audio: isCompatibleData.array_audio,
          codecs_video: isCompatibleData.array_video
        }
      };
      this.userVideoList[index].rtcPeerConnection.onicecandidate = event => {

        if (event.candidate) {
          this.web_rtc_socket.socketEmit("candidate", ({
            roomName: this.consulta.id_consulta,
            idSocketFrom: this.userVideoList[0].idSocket, //id_socket propio 
            idSocketTo: data.idSocket,
            userName: this.usuario.use_name,//+' '+this.usuario.use_paternal_surname //id_socket a quien va dirigido 
            userTypeId: this.usuario.typ_id,
            codecs_audio_mimeType: [isCompatibleData.array_audio[0]],
            codecs_video_mimeType: [isCompatibleData.array_video[0]],
          }), event.candidate);
        }
      };
      this.userVideoList[index].rtcPeerConnection.ontrack = event => {
        if (event.streams && event.streams[0]) {
          this.userVideoList[index].videollamada = event.streams[0];
        }
      };
      this.userVideoList[index].rtcPeerConnection.addTrack(this.userVideoList[0].videollamada.getTracks()[0], this.userVideoList[0].videollamada);
      this.userVideoList[index].rtcPeerConnection.addTrack(this.userVideoList[0].videollamada.getTracks()[1], this.userVideoList[0].videollamada);
      this.setCodecs(index, data_set_codecs);
      // //console.log(this.userVideoList[index].rtcPeerConnection.getTransceivers(),'getTransceivers-ready');
      this.userVideoList[index].rtcPeerConnection
        .createOffer({ iceRestart: true })
        .then((offer) => {
          this.userVideoList[index].rtcPeerConnection.setLocalDescription(offer);
          this.web_rtc_socket.socketEmit("offer", ({
            roomName: this.consulta.id_consulta,
            idSocketFrom: this.userVideoList[0].idSocket, //id_socket propio 
            idSocketTo: data.idSocket, //id_socket a quien va dirigido 
            userName: this.usuario.use_name,//+' '+this.usuario.use_paternal_surname //id_socket a quien va dirigido 
            userTypeId: this.usuario.typ_id,
            codecs_audio_mimeType: this.codecs_audio_mimeType,
            codecs_video_mimeType: this.codecs_video_mimeType,
          }), offer);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //console.log('No tiene compatibilidad');
      this.web_rtc_socket.socketEmit("isNotCompatible", ({
        roomName: this.consulta.id_consulta,
        idSocketFrom: this.userVideoList[0].idSocket, //id_socket propio 
        idSocketTo: data.idSocket,
        userName: this.usuario.use_name,//+' '+this.usuario.use_paternal_surname //id_socket a quien va dirigido 
        userTypeId: this.usuario.typ_id,
      }), null);
    }
  }

  candidate(data) {
    //console.log(data, 'data-candidate');
    //console.log(this.userVideoList);
    let icecandidate = new RTCIceCandidate(data.candidate);
    let index = this.userVideoList.findIndex(x => x.idSocket == data.data.idSocketFrom);
    //console.log(index, 'index-candidate');
    if (data.data.codecs_audio_mimeType && data.data.codecs_video_mimeType) {
      let isCompatibleData: any = this.codecsCompatibility(data.data);
      let data_set_codecs = {
        data: {
          codecs_audio: isCompatibleData.array_audio,
          codecs_video: isCompatibleData.array_video
        }
      };
      this.setCodecs(index, data_set_codecs);
    }
    this.userVideoList[index].rtcPeerConnection.addIceCandidate(icecandidate);
    this.userVideoList[index].userName = data.data.userName;
    this.userVideoList[index].userTypeId = data.data.userTypeId;
  }

  offer(data) {
    console.log(data, 'data-offer');
    // if (!this.creator) {
    let index = this.userVideoList.push({
      videollamada: null,
      idSocket: data.data.idSocketFrom,
      rtcPeerConnection: new RTCPeerConnection(this.iceServers),
      userName: data.data.userName,
      userTypeId: data.data.userTypeId
    });
    index--;
    
    if (this.userVideoList && this.userVideoList[index] && this.userVideoList[index].idSocket) {
      this.userVideoSelected = this.userVideoList[index].idSocket;
    }
    //console.log(index, 'index-offer');
    // console.log(this.userVideoList, 'userVideoList');
    // console.log(this.userVideoSelected, 'userVideoSelected');

    // this.setCodecs(index, data);
    // this.userVideoList[index].rtcPeerConnection = new RTCPeerConnection(this.iceServers);
    this.userVideoList[index].rtcPeerConnection.onicecandidate = event => {
      //console.log(event, "event-OnIceCandidateFunction");
      // //console.log(this.web_rtc_socket,'this.web_rtc_socket');
      if (event.candidate) {
        this.web_rtc_socket.socketEmit("candidate", ({
          roomName: this.consulta.id_consulta,
          idSocketFrom: this.userVideoList[0].idSocket, //id_socket propio 
          idSocketTo: data.data.idSocketFrom,
          userName: this.usuario.use_name,//+' '+this.usuario.use_paternal_surname //id_socket a quien va dirigido
          userTypeId: this.usuario.typ_id
        }), event.candidate);
      }
    };
    this.userVideoList[index].rtcPeerConnection.ontrack = event => {
      if (event.streams && event.streams[0]) {
        this.userVideoList[index].videollamada = event.streams[0];
      }
    };
    this.userVideoList[index].rtcPeerConnection.addTrack(this.userVideoList[0].videollamada.getTracks()[0], this.userVideoList[0].videollamada);
    this.userVideoList[index].rtcPeerConnection.addTrack(this.userVideoList[0].videollamada.getTracks()[1], this.userVideoList[0].videollamada);
    //console.log(this.userVideoList[index].rtcPeerConnection.getTransceivers(), 'getTransceivers-offer');
    this.userVideoList[index].rtcPeerConnection.setRemoteDescription(data.offer);

    this.userVideoList[index].rtcPeerConnection
      .createAnswer()
      .then((answer) => {
        this.userVideoList[index].rtcPeerConnection.setLocalDescription(answer);
        this.web_rtc_socket.socketEmit("answer", ({
          roomName: this.consulta.id_consulta,
          idSocketFrom: this.userVideoList[0].idSocket, //id_socket propio 
          idSocketTo: data.data.idSocketFrom, //id_socket a quien va dirigido 
        }), answer);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  answer(data) {
    //console.log(data, 'data-answer');
    // this.rtcPeerConnection.setRemoteDescription(data);

    let index = this.userVideoList.findIndex(x => x.idSocket == data.data.idSocketFrom);
    //console.log(index,'index-answer');
    this.userVideoList[index].rtcPeerConnection.setRemoteDescription(data.answer);
    //console.log(this.userVideoList);
  }

  isNotCompatible(data) {
    //console.log(data, 'data-isNotCompatible');
    // this.rtcPeerConnection.setRemoteDescription(data);
    this.isStartMeeting = false;
    let url = 'error-video';
    this.router.navigate([url], { replaceUrl: true });
    setTimeout(() => window.location.reload(), 500);

  }

  isNotAuthorized(data) {
    //console.log(data, 'data-isNotCompatible');
    this.isStartMeeting = false;
    let url = 'dashboard';
    this.router.navigate([url], { replaceUrl: true });
    setTimeout(() => window.location.reload(), 500);

  }
  leave(data) {
    //console.log(data, 'data-leave');
    // this.creator = true;
    //el que se sale de la conexion tambien debe matar la sesion del peerConnection y de los componentes del otro participante
    let index = this.userVideoList.findIndex(x => x.idSocket == data.idSocket);
    //console.log(index, 'index-leave');
    try {
      this.userVideoSelected = this.userVideoSelected == data.idSocket ? this.userVideoList[0].idSocket : this.userVideoSelected;
    } catch (e) {
      //console.log(e,'e-leave');
    }
    if (index != -1) {
      try {
        this.userVideoList[index].videollamada.getTracks()[0].stop();
        this.userVideoList[index].videollamada.getTracks()[1].stop();
        this.userVideoList[index].rtcPeerConnection.ontrack = null;
        this.userVideoList[index].rtcPeerConnection.onicecandidate = null;
        this.userVideoList[index].rtcPeerConnection.close();
        this.userVideoList[index].rtcPeerConnection = null;
      } catch (error) {
        //console.log(error,'error-leave');
      }

      this.userVideoList.splice(index, 1);
      //console.log(this.userVideoList, 'this.userVideoList-leave');

      this.leaveSound.play();
    }
    // this.web_rtc_socket.socketExit();
  }

  muteButtonFunction() {
    this.muteFlag = !this.muteFlag;
    if (this.muteFlag) {
      this.userVideoList[0].videollamada.getAudioTracks()[0].enabled = false; // el [0] representa al audio de la funcion
    } else {
      this.userVideoList[0].videollamada.getAudioTracks()[0].enabled = true;
    }
    //console.log(this.userVideoList);
    //console.log(this.userVideoList[0].videollamada.getTracks());
    //console.log(this.userVideoList[0].videollamada.getAudioTracks());


  }

  hideButtonFunction() {
    this.hideCameraFlag = !this.hideCameraFlag;
    if (this.hideCameraFlag) {
      this.userVideoList[0].videollamada.getVideoTracks()[0].enabled = false; // el [0] representa al video de la funcion
    } else {
      this.userVideoList[0].videollamada.getVideoTracks()[0].enabled = true;
    }
    //console.log(this.userVideoList);
    //console.log(this.userVideoList[0].videollamada.getTracks());
    //console.log(this.userVideoList[0].videollamada.getVideoTracks());


  }

  leaveButtonFunction() {
    // this.divVideoChatLobby.style=" display: block;"; //oculto el div donde se agrega el room ID para cargar video
    // this.divButtonGroup.style=" display: none;" //muestro los botones de la camara
    //console.log('leaveButtonFunction');

    try {
      //console.log(this.userVideoList[0].videollamada.getTracks());

      if (this.userVideoList[0] && this.userVideoList[0].videollamada) {
        this.userVideoList[0].videollamada.getTracks()[0].stop();
        this.userVideoList[0].videollamada.getTracks()[1].stop();
      }

      if (this.userVideoList.length >= 2) {
        for (let index = 1; index < this.userVideoList.length; index++) {
          this.userVideoList[index].videollamada.getTracks()[0].stop();
          this.userVideoList[index].videollamada.getTracks()[1].stop();
          this.userVideoList[index].rtcPeerConnection.ontrack = null;
          this.userVideoList[index].rtcPeerConnection.onicecandidate = null;
          this.userVideoList[index].rtcPeerConnection.close();
          this.userVideoList[index].rtcPeerConnection = null;
        }
      }

      // this.web_rtc_socket.socketEmit("leave", ({ roomName: this.consulta.id_consulta }), null);
      // this.web_rtc_socket.socketExit();
      this.userVideoList = [];
    } catch (error) {
      // console.log(error);
    }
    this.endCall();
    sessionStorage.setItem('zoom_ud_video', 'false');
    sessionStorage.setItem('zoom_up_med', 'false');

  }


  startCall() {
    this.isStartMeeting = true;
    this._zoomService.postUpdateStartMeeting({ id_consulta: this.consulta.id_consulta })
      .then(result => {
        //console.log(result,'result-startCall');
      })
      .catch(error => {
        console.log(error);
      });
  }

  async endCall() {
    let url = '';
    if (this.isStartMeeting) {
      await this._zoomService.postUpdateEndMeeting({ id_consulta: this.consulta.id_consulta })
      .then((data: any[]) => {
        //console.log(data);
        setTimeout(() => window.location.reload(), 500);
      })
      .catch(error => {
        console.log(error)
      })

      if (this.usuario.typ_id == 1) {
        url = 'finvideoconsulta/' + this.consulta.id_consulta;
        this.router.navigate([url], { replaceUrl: true });
      }
      else if (this.usuario.typ_id == 2 && this.urlParams.path == 'mivideoconsulta') {
        url = 'encuesta/' + this.consulta.med_con_id + '/' + this.consulta.id_consulta;
        this.router.navigate([url], { replaceUrl: true });
      } else {
        url = 'dashboard/';
        this.router.navigate([url], { replaceUrl: true });
        setTimeout(() => window.location.reload(), 500);
      }
    } else {
      //console.log("No inició video");
    }
  }


  ngOnDestroy() {
    this.leaveButtonFunction();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.leaveButtonFunction();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.leaveButtonFunction();
  }

  codecsCompatibility(data) {

    let array_video = [];
    let array_audio = [];
    let find_audio_mimeType = false;
    let find_video_mimeType = false;
    for (let i = 0; i < data.codecs_audio_mimeType.length; i++) {
      if (!find_audio_mimeType) {
        const element = data.codecs_audio_mimeType[i];
        for (let j = 0; j < this.codecs_audio.length; j++) {
          const element_audio = this.codecs_audio[j];
          if (element_audio.mimeType == element.mimeType) {
            array_audio.push(element_audio);
            find_audio_mimeType = true;
          }
        }
      }
    }
    for (let i = 0; i < data.codecs_video_mimeType.length; i++) {
      if (!find_video_mimeType) {
        const element = data.codecs_video_mimeType[i];
        for (let j = 0; j < this.codecs_video.length; j++) {
          const element_video = this.codecs_video[j];
          if (element_video.mimeType == element.mimeType) {
            array_video.push(element_video);
            find_video_mimeType = true;
          }
        }
      }
    }
    //console.log(array_audio,'array_audio-codecsCompatibility');
    //console.log(array_video, 'array_video-codecsCompatibility');
    if (array_audio.length == 0 || array_video.length == 0) {
      return false;
    }
    return {
      array_audio: array_audio,
      array_video: array_video
    };
  }

  setCodecs(index, data) {
    //console.log(this.userVideoList,'userVideoList-setCodecs');

    if (!this.userVideoList[index].codecs_audio || this.userVideoList[index].codecs_audio.length == 0) {
      this.userVideoList[index].codecs_audio = data.data.codecs_audio;
      this.userVideoList[index].codecs_video = data.data.codecs_video;
    }
    let audio_index = 0;
    let video_index = 1;
    let transceiver = this.userVideoList[index].rtcPeerConnection.getTransceivers()[0];
    //console.log(transceiver,'transceiver-setCodecs');

    if (transceiver.sender
      && transceiver.sender.track
      && transceiver.sender.track.kind) {
      if (transceiver.sender.track.kind == 'audio') {//Aqui se verifica la posicion del codec de audio
        audio_index = 0;
        video_index = 1;
      } else {
        audio_index = 1;
        video_index = 0;
      }
      if (this.userVideoList[index].rtcPeerConnection.getTransceivers()[audio_index].setCodecPreferences != undefined) {
        //console.log('Entra en if new_audio_codecs');
        this.userVideoList[index].rtcPeerConnection.getTransceivers()[audio_index].setCodecPreferences(this.userVideoList[index].codecs_audio);
      }
      if (this.userVideoList[index].rtcPeerConnection.getTransceivers()[video_index].setCodecPreferences != undefined) {
        //console.log('Entra en if new_video_codecs');
        this.userVideoList[index].rtcPeerConnection.getTransceivers()[video_index].setCodecPreferences(this.userVideoList[index].codecs_video);
      }
      //console.log(this.userVideoList[index].rtcPeerConnection.getTransceivers(),'getTransceivers-setCodecs');
    } else {
      //console.log('No se pueden procesar los codecs');
    }
  }


  endMeeting() {
    if (this.usuario.typ_id == 1) { //Si es el medico el que apreta el boton, se finaliza la llamada para todos
      try {
        this.web_rtc_socket.socketEmit("endMeetingByDoctor", ({ roomName: this.consulta.id_consulta }), null);
      } catch (e) {
        //console.log(e, 'e-endMeeting');
      }
    }
  }

  endMeetingByDoctor(data) {
    // this.web_rtc_socket.socketEmit("leave", ({ roomName: this.consulta.id_consulta }), null);
    this.leaveButtonFunction();
  }

    pinUser(elementVideo) {
    console.log(elementVideo, 'elementVideo-pinUser');
    this.userVideoSelected = elementVideo.idSocket;
  }
}
