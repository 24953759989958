import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import { GestionService } from 'src/app/services/gestion.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-disponibilidad',
  templateUrl: './disponibilidad.component.html',
  styleUrls: ['./disponibilidad.component.css']
})
export class DisponibilidadComponent implements OnInit {
  _title = 'Gestión';
  _subtitle = 'Disponibilidad medica'


  public input_email: any = '';

  public mails: any = [];
  public dominios: any = [];

  info_input: any = '';


  public disponibilidad: any = [];



  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};


  constructor( public customerService: CustomerService,
              public gestionService: GestionService,
              public generalService: GeneralService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'doc_id',
      textField: 'use_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    // this.scheduleDisponibility();
    this.getDoctorList();
    this.authorizedDomains();

  }

  

  getDoctorList() {
    this.generalService.getDoctorList()
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-getDoctorList');

          this.dropdownList = data;
        }
      })
      .catch(e => console.error(e));
  }

  authorizedDomains(){
    this.gestionService.authorizedDomains()
      .then((data: any) => {
        // console.log(data, 'dominios autorizados');
        this.dominios = data        
      })
      .catch(e => console.error(e));

  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

 


  ExportarExcel() {
    //console.log('Exportar');
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    if (this.disponibilidad.length == 0) {
      Swal.fire({
        icon: 'info',
        text: 'Sin datos disponibles',
        timer: 2000
      })
    } else {
      Swal.close();
      this.downloadFileExcel(this.disponibilidad);
    }
  }

  ExportarCSV(){
    //console.log('Exportar');
    Swal.fire({
     icon: 'info',
     title: 'Espere por favor...',
     showConfirmButton: false,
     allowOutsideClick: false,
     timer: 10000
   })
   Swal.showLoading();
   if (this.disponibilidad.length == 0) {
     Swal.fire({
       icon: 'info',
       text: 'Sin datos disponibles',
       timer: 2000
     })
   } else {
     Swal.close();
     this.downloadFileCSV(this.disponibilidad);
   }
 }

  modalError(titulo, detalle) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: detalle,
      timer: 5000
    })
  }

  async actualizarData() {
    while (true) {
      this.Listar();
      await this.generalService.sleep(1000 * 60 * 5);
    }
  }

  ListarLoading() {
    this.generalService.modalCargando();
    this.Listar();
  }

  Listar() {
    let email = []

    this.gestionService.scheduleDisponibility(email, this.arrayStringDoctor(this.selectedItems))
      .then((data: any) => {
        this.disponibilidad = data
        this.disponibilidad.reverse();
        // console.log(this.disponibilidad, 'horas filtradas');
        Swal.close();
      })
      .catch(error => {
        // console.log(error);
      });
  }

  downloadFileExcel(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // mapear valores nulos
    const header = Object.keys(data[0]);
    
    // Mapear fechas en el formato correcto
    const mappedData = data.map(row => {
      const mappedRow = {};
      for (const fieldName of header) {
        if (fieldName === 'fecha') { // Supongamos que la columna de fechas se llama 'fecha'
          mappedRow[fieldName] = new Date(row[fieldName]); // Formatear la fecha en el formato deseado
        } else {
          mappedRow[fieldName] = row[fieldName];
        }
      }
      return mappedRow;
    });
    
    const worksheet = XLSX.utils.json_to_sheet(mappedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, "disponibilidad.xlsx");
  }

  downloadFileCSV(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // majear valore nulos
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob(["\ufeff" + csvArray], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "disponibilidad.csv");
  }


  arrayStringDoctor(arrayStatus) {
    let textArray = [];
    for (let index = 0; index < arrayStatus.length; index++) {
      textArray.push(arrayStatus[index].doc_id);
    }
    return (arrayStatus.length > 0 ? textArray : null);
  }

  

  emailLoading() {
    let emails = null;
    this.input_email = this.input_email.replaceAll('\t', '');
    this.input_email = this.input_email.replaceAll('\n', '');
    this.input_email = this.input_email.replaceAll(' ', '');
    this.input_email = this.input_email.replaceAll('-', '');
    emails = this.input_email.split(',');
    // console.log(emails, 'array emails');
  
    let isValidEmails = true; // Variable para seguir el estado de validez de todos los correos
  
    for (let i = 0; i < emails.length; i++) {
      let element = emails[i];
      if (this.generalService.validaEmail(element)) {
        let emailParts = element.split('@');
        let username = emailParts[0];
        let domain = emailParts[1];
        let isValidDomain = false;
        for (let j = 0; j < this.dominios.length; j++) {
          const dominio = this.dominios[j];
          if (domain === dominio.aut_dom_domain) {
            let mail = username + '@' + domain;
            // console.log(mail, 'correo válido');
            this.mails.push(mail);
            isValidDomain = true;
            this.sendEmail();
            break;
          }
        }
        if (!isValidDomain) {
          isValidEmails = false; // Al menos un dominio es inválido
          break;
        }
      } else {
        isValidEmails = false; // Al menos un correo es inválido
        break;
      }
    }
  
    if (isValidEmails) {
      this.generalService.modalExitoso('Se ha enviado el informe a los correos seleccionados', '');
    } else {
      this.generalService.modalError('Uno o mas de los correos ingresados no corresponde a los dominios autorizados', 'Se enviara la informacion a los correos autorizados');
    }
  
    // console.log(this.mails, 'correos correctos');
    this.ngOnInit();
  }

  sendEmail(){
    let email = [];
    try {
      email = this.mails
    } catch (error) {
      // console.log(error);
      this.modalError('Formato fecha incorrectos', 'Por favor escribir una fecha valida.');
      return;
    }
    this.gestionService.scheduleDisponibility(email, this.arrayStringDoctor(this.selectedItems))
    .then((data: any) => {
      this.disponibilidad = data.reverse()
      // this.disponibilidad.reverse();
      // console.log(this.disponibilidad, 'horas filtradas');
      Swal.close();
    })
    .catch(error => {
      // console.log(error);
    });
  }

}
