import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styles: []
})
export class AccountSettingsComponent implements OnInit {

  public linkTheme = document.querySelector('#theme')//saco el link del documento donde esta el tema
  public links = document.querySelectorAll('.selector');
  constructor() { }

  ngOnInit() {
    this.checkCurrentTheme();
    this.links = document.querySelectorAll('.selector');
  }

  changeTheme(theme: string){
    ////console.log(theme);

    //const linkTheme = document.querySelector('#theme')//saco el link del documento donde esta el tema

    const url = `./assets/css/colors/${ theme }.css`;
    ////console.log(linkTheme);
    ////console.log(url);
    
    this.linkTheme.setAttribute('href',url);

    this.checkCurrentTheme();
  }

  checkCurrentTheme(){
    

    this.links.forEach( elem =>{
      elem.classList.remove('working');

      const btnTheme = elem.getAttribute('data.theme');
      const btnThemeUrl = `./assets/css/colors/${ btnTheme }.css`;
      const currentTheme = this.linkTheme.getAttribute('href');

      if(btnThemeUrl === currentTheme){
        elem.classList.add('working');
      }

    })
    ////console.log(links);
    

  }


}
