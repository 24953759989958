import { Component } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-planes-convenios',
  templateUrl: './planes-convenios.component.html',
  styleUrls: ['./planes-convenios.component.css']
})
export class PlanesConveniosComponent {

  public planes: any = [];
  public usuario: any = {};
  public plan_selected: any = {};

  constructor(
    public generalService: GeneralService,
  ) { 
    this.usuario = new EncriptacionService().getUserInfo();
    this.usuario.use_id = this.usuario._id;
  }

}
 