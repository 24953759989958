import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';


import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FalilyGroupService } from 'src/app/services/family_group.service';
import { CusIdService } from 'src/app/services/cus_id.service';
import { GeneralService } from 'src/app/services/general.service';
import { log } from 'console';

@Component({
  selector: 'app-grupofamiliar',
  templateUrl: './grupofamiliar.component.html',
  styleUrls: ['./grupofamiliar.component.css']
})
export class GrupofamiliarComponent implements OnInit {

  public formSubmitted = false;

  usuario: any = {};
  beneficiary_selected: any = {};
  planes: any = [];
  plan_selected: any = {};

  page: any = {
    one: true,
    two: false
  }

  public familyForm = this.fb.group({
    rut: ['', [Validators.required, Validators.maxLength(12)]],
    rutTitular: [''],
    use_id: [''],
    cus_id: ['', [Validators.required]],
    rut_valido: [false]
  });

  constructor(
    private fb: FormBuilder,
    private falilyGroupService: FalilyGroupService,
    public generalService: GeneralService,


  ) { }

  ngOnInit() {
    this.usuario = new EncriptacionService().getUserInfo();
    this.postListPlansXBeneficiaries();
    // console.log(this.usuario);
    
  }



  postListPlansXBeneficiaries() {
    this.generalService.postListPlansXBeneficiaries(null)
      .then((result: any) => {
        this.planes = [];
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if (element.ben_main_use_id == this.usuario._id) {
            this.planes.push(element);
          }
        }
        // console.log(this.planes, 'planes-postListPlansXBeneficiaries');
      })
      .catch(error => {
        //console.log(error, 'error-postListPlansXBeneficiaries');
      });
  }
  showPlan(plan) {
    //console.log(plan, 'plan-showPlan');
    this.plan_selected = plan;
    this.page = {
      one: false,
      two: true
    };
    // console.log(plan, "entrando ")
  }

  goBack() {
    //console.log('goBack');
    this.page = {
      one: true,
      two: false
    };
    this.plan_selected = {};
  }

  resetComponent() {
    //console.log('goBack');
    this.page = {
      one: true,
      two: false
    };
    this.plan_selected = {};
    this.familyForm.controls['rut'].setValue('');
    this.postListPlansXBeneficiaries();
  }

  crearIntegrante() {
    this.familyForm.controls['rut'].setValue(this.familyForm.get('rut').value.split(' ').join(''));
    this.formSubmitted = true;

    if (this.campoNoValido('rut')) {
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Creando nuevo Beneficiario...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 1000000
    })
    this.falilyGroupService.postCrearBeneficiarios(this.familyForm.value.rut, this.plan_selected.cus_ben_id, this.plan_selected.ben_id)
      .then((data: any) => {
        Swal.fire({
          allowOutsideClick: true,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Beneficiario agregado exitosamente </h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
          showConfirmButton: false,
          timer: 3000
        })
        this.resetComponent();

      }).catch(err => {
        //console.log(err);
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >' + err.error.message + '</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Intenta con otro rut</p><br>',

        })
      });
  }

  eliminarBeneficiario(item) {
    this.beneficiary_selected = item;
  }

  botonEliminarBeneficiario() {
    this.falilyGroupService.postDesactivarBeneficiario((this.beneficiary_selected.use_rut ? this.beneficiary_selected.use_rut : this.beneficiary_selected.cus_dat_ben_rut), this.plan_selected.cus_ben_id, this.plan_selected.ben_id)
      .then((data: any) => {
        if (data.status) {
          Swal.fire({
            allowOutsideClick: true,
            html:
              ' <i class="material-iconsx" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
              ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" > Eliminación exitosa </h1> <br>',
            showConfirmButton: false,
            timer: 2000
          })
          this.resetComponent();
        } else {
          Swal.fire({
            html:
              `<div style="text-align: center; font-family: Mulish; font-style: normal;">

              <h1 style="text-align: center;font-family: Mulish;font-style: normal;font-weight: 800;font-size: 28px;line-height: 48px;text-align: center;color: #171994;">Atención</h1>
              <br><br>
              <p style="font-family: Mulish;font-style: normal;font-weight: normal;font-size: 18px;line-height: 24px;text-align: center;color: #000000;">No puedes eliminar el beneficiario, debe cumplir con un minimo de 6 meses antes de generar algún cambio en este</p>
            </div>`,
            showCancelButton: true,
            cancelButtonText: 'Aceptar',
            confirmButtonText: 'Volver',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'botonVolver',
              cancelButton: 'btn-aceptar',
              popup: 'container-swal-customer'
            }
          })
        }
      }, (err) => {
        Swal.fire('Error', err.error.msg, 'error');
      });
  }

  campoNoValido(campo: string): boolean {

    if (this.familyForm.get(campo).invalid && this.formSubmitted) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    } else {
      return false;
    }
  }

  validaRut() {
    // Despejar Puntos
    var rut_Cliente = this.familyForm.get('rut').value
    var valor = rut_Cliente;
    //console.log(this.familyForm.get('rut').value)
    //reemplazo los puntos por vacio
    let resultado = valor.split('.').join('');
    // reemplazo los - por vacio
    let resultado2 = resultado.split('-').join('');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    // Formatear RUN
    if (valor != "") {
      this.familyForm.controls['rut'].setValue(cuerpo + "-" + dv);
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;

    // Para cada dígito del Cuerpo
    for (i = 1; i <= cuerpo.length; i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado
    dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Rut ingresado no es valido.',
      })

    }
    else {
      this.familyForm.controls['rut_valido'].setValue(true);
    }
  }


}
