import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-material',
  templateUrl: './sidenav-material.component.html',
  styleUrls: ['./sidenav-material.component.css']
})
export class SidenavMaterialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
