import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promesas',
  templateUrl: './promesas.component.html',
  styles: []
})
export class PromesasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    //this.getUsuarios();

    this.getUsuarios().then( usuarios => {
      //console.log(usuarios);
      
    })

    const promesa = new Promise( ( resolve, reject ) => {
      
      if ( false ){
        resolve("Hola mundo");
      }else{
        reject("Es un error");
      }
      
    })

    promesa.then ( ( mensaje ) => { // le paso el mensaje de la promesa al then para que lo muestre en pantalla
      //console.log(mensaje);
    })
    .catch( error => console.log(error));

    //console.log("FIN DEL INIT");
  }


  getUsuarios(){
    const promesa = new Promise ( resolve => {
      fetch('https://reqres.in/api/users')
      .then( respuesta => respuesta.json() )
      .then( body => resolve( body.data ) )
    }) ;

    return promesa;
  }

}
