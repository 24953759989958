
<div class="calendar-container generic-calendar">

  <div class="title">

    <div class="title-month">
      <div>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</div>
    </div>

    <div class="title-prev"
      (click)="nextPrev()"
      mwlCalendarPreviousView 
      [view]="view" 
      [(viewDate)]="viewDate"><mat-icon>chevron_left</mat-icon>
    </div>
    
    <div class="title-next"
      (click)="nextMes()"
      mwlCalendarNextView 
      [view]="view" 
      [(viewDate)]="viewDate"><mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="calendar-days">
    <mwl-calendar-month-view
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [refresh]="refresh" 
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [activeDayIsOpen]="activeDayIsOpen" 
      (dayClicked)="dayEnabledClicked($event.day)"
      (beforeViewRender)="beforeMonthViewRender($event)"> 
    </mwl-calendar-month-view>
  </div>



  <br>

  
</div>
