<!-- <button class="btn btn-primary" (click)="pacienteDoctor('pac')">Paciente</button>
<button class="btn btn-secondary" (click)="pacienteDoctor('DOCTOR')">Doctor</button>
<br> -->
<h1>{{ tipo_usuario_name }}</h1>
<br>


<!-- <div class="row">
    <div class="col-4"> 
      <div class="day-available" *ngIf="!day_available && tipo_usuario_name == 'MEDICO'">
        <span>Selecciona un día con horas disponibles</span>
      </div>
      <div class="day-available" *ngIf="day_available && tipo_usuario_name == 'MEDICO' ">
        <span>Día: {{ day_available_date }}</span>
        <br><br>
        <table>
          <tr *ngFor='let hor of hours_available_day'>
            <td>{{ hor.sch_hou_from }} - {{ hor.sch_hou_to }}</td>
            <td *ngIf="hor.estado_hora == 'RESERVADA'">
              <button title="Ir a la consulta" type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" routerLink="/meetingRoom">{{ hor.estado_hora }}</button>
            </td>
            <td *ngIf="hor.estado_hora != 'RESERVADA'">{{ hor.estado_hora }}</td>
          </tr>
        </table>
      </div>
    </div>
  
    <div class="col-8">
      <div class="card-calendar">
        <app-calendar class="agenda-user-calendar"
          [eventos] = 'eventos'
          (messageEvent)='receiveMessage($event)'>
        </app-calendar>
      </div>
      
      <i class="fas fa-square" style="color: #98d7e2;"></i> Días con horas 
      <i class="far fa-square" style="color: #585858; margin-left: 20px;"></i> Días sin horas
    </div>
    
  </div> -->

<!-- <div *ngIf="tipo_usuario_name == 'PACIENTE'"> 
   -->
<form name="form" style="text-align: left" #f1="ngForm" class="">

  <label for="">Rut o Nombre Beneficiario</label>
  <ng2-completer name="id_beneficiario" (selected)="changeBeneficiario($event)"
    [textNoResults]="'Beneficiario no encontrado.'" [datasource]="beneficiarios" [(ngModel)]="searchStrMain"
    [minSearchLength]="0" [placeholder]="'Seleccione Beneficiario'" #id_clinica="ngModel" [autoMatch]="true"
    [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true" required>
  </ng2-completer>

  <br>
  <br>
  <button type="submit" class="btn btn-primary" (click)="rut(f1)">Cargar Datos</button>
</form>

<br><br>

<div *ngIf="ver_horas">
  <!-- PROXIMAS CONSULTA -->
  <h4>Próximas consultas</h4>
  <div *ngIf="appointments_user_prox.length">
    <table class="table-appo">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Hora</th>
          <th>Área Médica</th>
          <th>Médico</th>
          <th>Tiempo faltante</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let appo of appointments_user_prox'>
          <td>{{ appo.date_text }}</td>
          <td>{{ appo.sch_hou_from }} - {{ appo.sch_hou_to }}</td>
          <td>{{ appo.spe_name }} </td>
          <td>{{ appo.doc_use_name }} {{ appo.doc_use_paternal_surname }}</td>
          <td>{{ appo.date_dt }}</td>
          <td class="dropdown">
            <button class=" btn btn-primary nav-link dropdown-toggle waves-effect waves-dark" href=""
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Opciones
            </button>
            <div class="dropdown-menu dropdown-menu-right animated fadeIn fast py-0">
              <ul class="mx-0 my-0 px-0 py-0">
                <li class="btn btn-outline-info nav-link"><a data-toggle="modal" data-target="#editAppoModal"
                    (click)="editarHora(appo)">Modificar</a></li>
                <li class="btn btn-outline-info nav-link"><a data-toggle="modal" data-target="#cancelAppoModal"
                    (click)="cancelarHora(appo)">Cancelar</a></li>
              </ul>
            </div>
          </td>
          <!-- <td>
                <button class="btn btn-warning btn-sm mx-2" data-toggle="modal" data-target="#editAppoModal" (click)="editarHora(appo)"><i class="fas fa-edit"></i></button>
                <button class="btn btn-danger btn-sm mx-2" data-toggle="modal" data-target="#cancelAppoModal" (click)="cancelarHora(appo)"><i class="fas fa-trash-alt"></i></button>
              </td> -->
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="appointments_user_prox.length == 0">
    No tiene consultas agendadas
  </div>
</div>
<br>
<br>

<div *ngIf="ver_horas">
  <!-- HISTORIAL DE CONSULTA -->
  <h4>Historial de consultas</h4>
  <table class="table-appo">
    <thead>
      <tr>
        <th>Fecha</th>
        <th>Hora</th>
        <th>Área Médica</th>
        <th>Médico</th>
        <th>Estado</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor='let appo of appointments_user_past'>
        <td title="{{ appo.date_dt }}">{{ appo.date_text }}</td>
        <td>{{ appo.sch_hou_from }} - {{ appo.sch_hou_to }}</td>
        <td>{{ appo.spe_name }}</td>
        <td>{{ appo.doc_use_name }} {{ appo.doc_use_paternal_surname }}</td>
        <td>{{ appo.estado_past }}</td>
        <td class="dropdown">
          <!-- <button *ngIf="appo.estado_past == 'FINALIZADA'"
            class=" btn btn-primary nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" (click)="historialConsult(appo)">
            Opciones
          </button> -->
          <button class=" btn btn-primary waves-effect waves-dark" href=""
            data-toggle="modal" data-target="#historialModal" (click)="historialConsult(appo)">
            Más información
          </button>
          <!-- <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ddddd </a> -->

          <div class="dropdown-menu dropdown-menu-right animated fadeIn fast py-0">
            <ul class="mx-0 my-0 px-0 py-0">
              <li class="btn btn-outline-info nav-link"><a>Diagnostico e indicaciones</a></li>
              <li class="btn btn-outline-info nav-link"><a>Receta Médica</a></li>
              <li class="btn btn-outline-info nav-link"><a>Órden Médica</a></li>
              <li class="btn btn-outline-info nav-link"><a>Certificados Médicos</a></li>
              <li class="btn btn-outline-info nav-link"><a>Archivos</a></li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>










<!-- Modal tabla de procesos historico-->
<!-- <div class="modal fade" id="cancelAppoModal" tabindex="-1" role="dialog" aria-labelledby="cancelAppoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header mx-auto">
          <h4 class="modal-title titulo-modal" id="cancelAppoModalLabel">Reserva de Hora</h4>
        </div>
        <div class="modal-body">
                  ¿Confirma que desea cancelar la hora con el (la) Dr. {{data_selected.name_doc}} el {{data_selected.name_day}}
                  a las {{data_selected.name_hour}}?
        </div>
        <div class="modal-footer row">
                  <button type="button" class="btn btn-secondary col" data-dismiss="modal" >Cancelar</button>
                  <button class="btn btn-primary col" data-dismiss="modal" (click)="confirmarCancelAppo(data_selected.num_reserva, '')">Si</button>
        </div>
      </div>
    </div>
  </div> -->



<!-- Modal historial hora -->
<div class="modal fade" id="historialModal" tabindex="-1" role="dialog" aria-labelledby="historialModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title titulo-modal" id="historialModalLabel">Datos de la reserva</h5>
      </div>
      <div class="modal-body">
        <ul>
          <li>Beneficiario: <strong>{{data_selected.name_ben}}</strong></li>
          <li>Doctor: <strong>{{data_selected.name_doc}}</strong></li>
          <li>Área: <strong>{{data_selected.name_area}}</strong></li>
          <li>Día: <strong>{{data_selected.name_day}}</strong></li>
          <li>Hora: <strong>{{data_selected.name_hour}}</strong></li>
          <li>Razón: <strong>{{data_selected.name_reason}}</strong></li>
          <li>Síntomas: <strong>{{data_selected.name_symptoms}}</strong></li>
          <li>Estado consulta: <strong>{{data_selected.estado_appo}}</strong></li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>



<!-- Modal resumen hora Edit-->
<div class="modal fade" id="editAppoModal" tabindex="-1" role="dialog" aria-labelledby="editAppoModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title titulo-modal" id="editAppoModalLabel">Datos de la reserva</h5>
      </div>
      <div class="modal-body">
        <ul>
          <li>Beneficiario: <strong>{{data_selected.name_ben}}</strong></li>
          <li>Doctor: <strong>{{data_selected.name_doc}}</strong></li>
          <li>Área: <strong>{{data_selected.name_area}}</strong></li>
          <li>Día: <strong>{{data_selected.name_day}}</strong></li>
          <li>Hora: <strong>{{data_selected.name_hour}}</strong></li>
          <!-- <li>Razón: <strong>{{data_selected.name_reason}}</strong></li>
                      <li>Síntomas: <strong>{{data_selected.name_symptoms}}</strong></li> -->
        </ul>
        <div class="card-calendar" *ngIf="calendar_edit_bool">
          Seleccionar una nueva hora
          <div>
            <app-calendar class="schedule-calendar" [eventos]='eventos2' (messageEvent)='receiveMessage2($event)'>
            </app-calendar>
          </div>
          <div class="day-available2" *ngIf="day_available2">

            <div>{{ day_available_date2 }}</div>
            <br>

            <ul>
              <li *ngFor='let hor of hours_available_day2'>
                {{ hor.sch_hou_from }} - {{ hor.sch_hou_to }}
                <button class="btn btn-outline-info btn-sm" (click)="reservarEdit(hor)">Reservar hora</button>
              </li>
            </ul>
          </div>
          <br>
          <div *ngIf="hora_seleccionada">
            <h5>Confirmar modificación</h5>
            <p><mat-icon>arrow_forward</mat-icon>Nueva hora: <span style="font-weight: 600;">{{hora_new}}</span></p>
            <br>
          </div>
          <p>Modificado por:</p>
          <select name="modify_by" id="modify_by" style="padding-top: 10px;" class="form-control camposModalAgregar"
            [(ngModel)]="modify_by">
            <option [value]="userType.type_user_id" *ngFor="let userType of listUserType">{{userType.type_user_name}}
            </option>
          </select>
          <br>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="acepta_edicion">
            <label class="form-check-label" for="exampleCheck1">Confirmar</label>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-warning" data-dismiss="modal" [disabled]="!acepta_edicion"
            (click)="confirmaEdicion()">Modificar Hora</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade cancelarAppoModal" id="cancelAppoModal" tabindex="-1" role="dialog" aria-hidden="true"
  #cancelAppoModal>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="text-align: center;" class="tituloModal">Anulación de consulta</h2>
      </div>
      <div class="modal-body">
        <div class="card p-0 m-0 border-go-lobby border-card-modal">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
            <div class="m-0 p-0 first-line">
              <app-profile-img [img]="data_selected.consulta.att_path">
              </app-profile-img>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ data_selected.consulta.date_text }}</p>
                <p class="card-text pt-3 mr-5 doc-data">{{ data_selected.consulta.doc_use_name }} {{
                  data_selected.consulta.doc_use_paternal_surname }} {{ data_selected.consulta.doc_use_maternal_surname
                  }}- {{ data_selected.consulta.spe_name }} </p>
                <p class="card-text m-0 p-0 doc-data">Nº de reserva: {{ data_selected.consulta.tel_doc_app_boo_id}} </p>
              </div>
            </div>
          </div>
        </div>
        <br>
        <select name="cancel_by" id="cancel_by" style="padding-top: 10px;" class="form-control camposModalAgregar"
          [(ngModel)]="cancel_by" placeholder="Cancelado por: ">
          <option value="" disabled selected hidden>Cancelado por: </option>
          <option [value]="userType.type_user_name" *ngFor="let userType of listUserType">{{userType.type_user_name}}
          </option>
        </select>
        <br><br>
        <textarea class="form-control" rows="4" style="padding-top: 10px;" name="cancel_reason"
          [(ngModel)]="cancel_reason" id="cancel_reason" placeholder="Motivo de anulación"></textarea>
        <div class="text-danger" *ngIf="(cancel_reason.length < 4)">
          <p><mat-icon>highlight_off</mat-icon> Debes ingresar un motivo de anulación</p>
        </div>

      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-md-3" style="height: 40px;float: left;">
            <button type="button" class="btn btn-volver" style="float: left;width: 100%;" data-dismiss="modal">
              <div style="margin: auto;">
                Cerrar formulario
              </div>
            </button>
          </div>
          <div class="col-md-6 d-none d-sm-block"></div>
          <div class="col-md-3" style="height: 40px;float: right;">
            <button class="btn btn-confirmar" data-dismiss="modal" style="float: right;width: 100%;"
              (click)="confirmarCancelAppo()" [disabled]="(cancel_reason.length < 4)">
              <div style="margin: auto;">
                Anular consulta
              </div>
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>