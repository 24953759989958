<div class="row" *ngIf="steps[0]">
  <div class="col-md-12">
    <br>
    <div class="minititulo">
      Mi buscador
    </div>
    <div class="titulo">
      Historial de consultas
    </div>
    <br><br>
    <div>
      <form id="buscaInfo" autocomplete="off" [formGroup]="rutABuscar" (ngSubmit)="buscarRut()">
        <label for="">Rut o Nombre usuario</label>
        <ng2-completer name="id_beneficiario" formControlName="rutBuscarPlataforma"
          (selected)="changeBeneficiario($event)" [textNoResults]="'Beneficiario no encontrado.'"
          [datasource]="beneficiarios" [(ngModel)]="searchStrMain" [minSearchLength]="0"
          [placeholder]="'Seleccione el usuario'" [autoMatch]="true" [inputClass]="'form-control'"
          [clearUnselected]="true" [openOnFocus]="true" required>
        </ng2-completer>

        <br>
        <button type="submit" class="btn btn-outline-secondary btn-lg boton">Buscar</button>
      </form>
    </div>
  </div>
</div>

<br><br>




<div id="past-appointment" *ngIf="steps[0]">

  <div class="row d-sm-flex">
    <div *ngFor="let appt of appointments" class="col-12" style="margin-bottom: 30px;">
      <app-past-appointment [data]="appt" (messageEvent)="verDetalle($event)">
      </app-past-appointment>
    </div>
  </div>
</div>

<ng-container *ngIf="rutBuscar && appointments.length == 0">
  <div class="not-appointment">
    <div style="    display: flex;
    padding: 0 10px;
    color: #171994;">
      <mat-icon>info</mat-icon>
      <span>No existen consultas pasadas.</span>
    </div>
  </div>
</ng-container>

<div class="detalle-consulta" *ngIf="steps[1]" style="margin-bottom: 36px;">
  <div class="col-12 mt-4 text-sm-left container-subtitle-bar">
    <div class="row">
      <div class="col-8 col-md-6">
        <h3 class="pb-3">Detalle de la consulta</h3>
      </div>
      <div class="col col-md-6 text-right">
        <a href="javascript:void(0);" (click)="checkStep()">
          <mat-icon>chevron_left</mat-icon>
          <span class="ml-2">volver</span>
        </a>
      </div>
    </div>
  </div>

  <div class="card h-100 p-0 m-0 border-confirm">
    <!-- <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
      <div class="m-0 p-0 first-line">
        <app-profile-img 
          [img]="consulta_selected.att_path" 
          [doctorName]="appointment_selected.doctorName" 
          [doctorLastName]="appointment_selected.doctorLastName"
          [color]="appointment_selected.goLobbyFlag ? 'goLobby' : appointment_selected.confirmFlag ? 'confirm' : 'default'">
        </app-profile-img>
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 appt-date">{{ appointment_selected.date[0] }} {{ appointment_selected.date[1] }}</p>
          <p class="card-text pt-3 mr-5 doc-data">{{ appointment_selected.doctorName }} {{ appointment_selected.doctorLastName }} - {{ appointment_selected.spec }} </p>
        </div>
      </div>
      <div class="ml-3 align-self-center confirmada" style=" width: 114px;">
        <p class="text-right"><li>Confirmada</li></p>
      </div>
    </div> -->

    <div class="row card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
      <div class="col-md-7 col-xs-12 m-0 p-0 c-0 first-line">
        <app-profile-img [img]="consulta_selected.att_path" style="margin-left: 10pximportant!;">
        </app-profile-img>
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 appt-date">{{ appointment_selected.date[0] }} {{ appointment_selected.date[1] }}
          </p>
          <p class="card-text pt-3 mr-5 doc-data">{{ appointment_selected.doctorName }} {{
            appointment_selected.doctorLastName }} - {{ appointment_selected.spec }} </p>
        </div>
      </div>
      <div class="col-md-5 col-xs-12">
        <div class="row">
          <div class="col-xs-12 ml-auto align-self-center" style="margin-right: 15px;">
            <p *ngIf="appointment_selected.consulta.tel_doc_app_boo_sta_id == 39"
              class="card-text flag-helper appt-gris">*Finalizada</p>
            <p *ngIf="appointment_selected.consulta.tel_doc_app_boo_sta_id == 44"
              class="card-text flag-helper appt-gris">*Paciente no se presenta</p>
            <p *ngIf="(appointment_selected.consulta.tel_doc_app_boo_sta_id ==30 || appointment_selected.consulta.tel_doc_app_boo_sta_id ==10)"
              class="card-text flag-helper appt-gris">*No Finalizada</p>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
  <br><br>


  <div class="container-fluid" style="padding-left: 0;">
    <div class="row">
      <div class="col-md-6 m-0 p-0 first-line">
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 tituloDescripcion">Paciente</p>
          <p class="card-text m-0 p-0 cuerpoDescripcion">
            {{ consulta_selected.nombre_paciente}} {{consulta_selected.apellido_paterno_paciente}} {{
            consulta_selected.apellido_materno_paciente}}</p>
          <p class="card-text m-0 p-0 "> </p>
        </div>
      </div>
      <br>
      <div class="col-md-6 m-0 p-0 first-line">
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 tituloDescripcion">Acompañante del paciente</p>
          <p *ngIf="consulta_selected.nombre_acompanante != null" class="card-text m-0 p-0 cuerpoDescripcion">{{
            consulta_selected.nombre_acompanante}} {{consulta_selected.apellido_paterno_acompanante}} {{
            consulta_selected.apellido_materno_acompanante}}</p>
          <p *ngIf="consulta_selected.nombre_acompanante == null" class="card-text m-0 p-0 cuerpoDescripcion">**
            Paciente no presenta acompañante a la consulta **</p>
          <p class="m-0 p-0 "> </p>
        </div>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-md-12 m-0 p-0 first-line">
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 tituloDescripcion">Motivo de la consulta</p>
          <p *ngIf="consulta_selected.razon != ''" class="card-text m-0 p-0 cuerpoDescripcion">
            {{consulta_selected.razon}}</p>
          <p *ngIf="consulta_selected.razon == ''" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no especifica
            motivo de la consulta **</p>
          <p class="m-0 p-0 "> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <app-ficha-medica [eventos]='ficha_eventos' [consulta]='consulta_selected'>
  </app-ficha-medica>
</div>