import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { GeneralService } from 'src/app/services/general.service';
import { LicenciaMedicaService } from 'src/app/services/licencia-medica.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-licencias-medicas',
  templateUrl: './licencias-medicas.component.html',
  styleUrls: ['./licencias-medicas.component.css']
})
export class LicenciasMedicasComponent implements OnInit {
  usuario: Usuario;
  
  licenciasNoFinalizadas: any = [];
  licenciasFinalizadas: any = [];

  info_input_uno: any = '';
  f_desde_uno: any = '';
  f_hasta_uno: any = '';

  info_input_dos: any = '';
  f_desde_dos: any = '';
  f_hasta_dos: any = '';

  lista_horarios_disponibles: any = [];
  lista_fechas: any = [];
  lista_horarios: any = [];
  lista_doctores: any = [];

  fecha_seleccionada: any = '';
  horario_seleccionado: any = '';
  doctor_seleccionado: any = '';

  hora_seleccionada: any = {};

  solicitud_licencia_seleccionada: any = {
    beneficiario: {},
    doctor_consulta: {},
    doctor_asignado: {},
  };
  reasignacion_seleccionada: any = {};
  tipos_licencia_medica: any = [];

  tipo_seleccionado_licencia: any = "";

  licencia: any = {
    fecha_inicio_reposo: '',
    diagnostico_seleccionado: '',
    dias_reposo: null,
    tipo_seleccionado: '',
    rut_hijo: '',
    fecha_nacimiento_hijo: '',
  }
  constructor(
    private _generalServices: GeneralService,
    private _fichaMedicaServices: FichaMedicaService,
    private _licenciaMedicaServices: LicenciaMedicaService,    
  ) {
    this.usuario = new EncriptacionService().getUserInfo();
  }

  ngOnInit(): void {
    this.obtenerLicenciasMedicas();
    this.obtenerHorariosDisponibles();
    this.postListMedicalLeaveType();
  }

  obtenerLicenciasMedicas() {
    this._licenciaMedicaServices.postListMedicalLeaves(null,null,null,null)
    .then((result:any) => {
      this.licenciasFinalizadas = result.finalizadas;
      this.licenciasNoFinalizadas = result.noFinalizadas;
      //console.log(this.licenciasFinalizadas, 'obtenerLicenciasMedicas-licenciasFinalizadas');
      //console.log(this.licenciasNoFinalizadas,'obtenerLicenciasMedicas-licenciasNoFinalizadas');
      
      
    })
    .catch(error => {
      //console.log(error, 'error-postListMedicalLeaves');
      this.modalError('Error','Error al cargar licencias.');
    });
  }

  obtenerHorariosDisponibles() {
    if (this.usuario.typ_id==1) {
      return;
    }
    this._licenciaMedicaServices.postListAvailableMedicalLeaves()
    .then((result:any) => {
      this.lista_horarios_disponibles = result;
      //console.log(this.lista_horarios_disponibles, 'postListAvailableMedicalLeaves-lista_horarios_disponibles');
      this.filtrarFechas();
    })
    .catch(error => {
      //console.log(error, 'error-postListAvailableMedicalLeaves');
      this.modalError('Error','Error al cargar horarios licencias.');
    });
  }

  modalError(titulo,descripcion) {
    Swal.close();
    Swal.fire({
      allowOutsideClick: true,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >'+ titulo +'</h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >' + descripcion + '</p><br>',
      timer: 3000,
      showCancelButton: false,
      showConfirmButton: false,
    }) 
  }

  listarUno() {
    //console.log('listarUno');
    let f_desde_uno_aux:any = this._generalServices.verificarFecha(this.f_desde_uno);
    let f_hasta_uno_aux:any = this._generalServices.verificarFecha(this.f_hasta_uno);
    
    if ((this.f_desde_uno && this.f_desde_uno.length>0 && !f_desde_uno_aux) || (this.f_hasta_uno && this.f_hasta_uno.length>0 && !f_hasta_uno_aux)) {
      this.modalError('Fecha Inválida', 'Por favor ingrese fechas válidas.');
      return;
    }
    
    this._licenciaMedicaServices.postListMedicalLeaves(f_desde_uno_aux.fecha_date_string,f_hasta_uno_aux.fecha_date_string,null,null)
    .then((result:any) => {
      this.licenciasNoFinalizadas = result.noFinalizadas;
      //console.log(this.licenciasNoFinalizadas, 'listarUno-licenciasNoFinalizadas');
      if (this.info_input_uno && this.info_input_uno.length > 0) {
        this.filtrarDataUno();
      }
      this.resetInputs();
    })
    .catch(error => {
      //console.log(error, 'error-postListMedicalLeaves');
      this.modalError('Error','Error al cargar licencias.');
    });

  }

  listarDos() {
    //console.log('listarDos');
    
    let f_desde_dos_aux:any = this._generalServices.verificarFecha(this.f_desde_dos);
    let f_hasta_dos_aux:any = this._generalServices.verificarFecha(this.f_hasta_dos);
    
    if ((this.f_desde_dos && this.f_desde_dos.length>0 && !f_desde_dos_aux) || (this.f_hasta_dos && this.f_hasta_dos.length>0 && !f_hasta_dos_aux)) {
      this.modalError('Fecha Inválida', 'Por favor ingrese fechas válidas.');
      return;
    }
    
    this._licenciaMedicaServices.postListMedicalLeaves(null,null,f_desde_dos_aux.fecha_date_string,f_hasta_dos_aux.fecha_date_string)
    .then((result:any) => {
      this.licenciasFinalizadas = result.finalizadas;
      //console.log(this.licenciasFinalizadas, 'listarDos-licenciasFinalizadas');
      if (this.info_input_dos && this.info_input_dos.length > 0) {
        this.filtrarDataDos();
      }
      this.resetInputs();
    })
    .catch(error => {
      //console.log(error, 'error-postListMedicalLeaves');
      this.modalError('Error','Error al cargar licencias.');
    });
  }

  filtrarDataUno() {
    let lista_filtrada = [];

    for (let index = 0; index < this.licenciasNoFinalizadas.length; index++) {
      const element = this.licenciasNoFinalizadas[index];
      if (
        element.beneficiario.nombre_completo.toUpperCase().includes(this.info_input_uno.toUpperCase())
        || element.doctor_consulta.nombre_completo.toUpperCase().includes(this.info_input_uno.toUpperCase())
        || (element.doctor_asignado && element.doctor_asignado.nombre_completo.toUpperCase().includes(this.info_input_uno.toUpperCase()))
        || element.med_lea_app_boo_id.toString().toUpperCase().includes(this.info_input_uno.toUpperCase())
      ) {
        lista_filtrada.push(element);
      }
    }
    this.licenciasNoFinalizadas = lista_filtrada;
  }
  filtrarDataDos() {
    let lista_filtrada = [];

    for (let index = 0; index < this.licenciasFinalizadas.length; index++) {
      const element = this.licenciasFinalizadas[index];
      // //console.log(element);
      if (
        element.beneficiario.nombre_completo.toUpperCase().includes(this.info_input_dos.toUpperCase())
        || element.doctor_consulta.nombre_completo.toUpperCase().includes(this.info_input_dos.toUpperCase())
        || (element.doctor_asignado && element.doctor_asignado.nombre_completo.toUpperCase().includes(this.info_input_dos.toUpperCase()))
        || element.med_lea_app_boo_id.toString().toUpperCase().includes(this.info_input_dos.toUpperCase())
      ) {
        lista_filtrada.push(element);
      }
    }
    this.licenciasFinalizadas = lista_filtrada;
  }

  resetInputs(){
    this.f_desde_uno = '';
    this.f_hasta_uno = '';
    this.f_desde_dos = '';
    this.f_hasta_dos = '';
    this.info_input_uno = '';
    this.info_input_dos = '';
  }

  filtrarFechas() {
    this.fecha_seleccionada = '';
    this.horario_seleccionado = '';
    this.doctor_seleccionado = '';

    this.lista_fechas = [];
    this.lista_horarios = [];
    this.lista_doctores = [];

    this.hora_seleccionada = {};

    for (let index = 0; index < this.lista_horarios_disponibles.length; index++) {
      const element = this.lista_horarios_disponibles[index];
      if (this.lista_fechas.indexOf(element.fecha)==-1) {
        this.lista_fechas.push(element.fecha);
      }
    }
    //console.log(this.lista_fechas);
    
  }

  seleccionaFecha(fecha) {
    //console.log(fecha, 'fecha-seleccionaFecha');
    //console.log(this.fecha_seleccionada, 'fecha_seleccionada-seleccionaFecha');
    this.horario_seleccionado = '';
    this.doctor_seleccionado = '';

    this.lista_horarios = [];
    this.lista_doctores = [];
    
    this.hora_seleccionada = {};

    for (let index = 0; index < this.lista_horarios_disponibles.length; index++) {
      const element = this.lista_horarios_disponibles[index];
      if (this.lista_horarios.indexOf(element.horario_desde)==-1 && element.fecha==fecha) {
        this.lista_horarios.push(element.horario_desde);
      }
    }
    //console.log(this.lista_horarios,'lista_horarios-seleccionaFecha');
  }

  seleccionaHorario(horario) {
    //console.log(horario, 'horario-seleccionaHorario');
    //console.log(this.horario_seleccionado,'horario_seleccionado-seleccionaHorario');
    this.lista_doctores = [];
    
    for (let index = 0; index < this.lista_horarios_disponibles.length; index++) {
      const element = this.lista_horarios_disponibles[index];
      if (this.lista_doctores.indexOf(element.nombre_doctor)==-1 && element.fecha==this.fecha_seleccionada && element.horario_desde==horario ) {
        this.lista_doctores.push(element.nombre_doctor);
      }
    }
    //console.log(this.lista_doctores,'lista_doctores-seleccionaHorario');
  }

  
  seleccionaDoctor(doctor) {
    //console.log(doctor, 'doctor-seleccionaDoctor');
    //console.log(this.doctor_seleccionado,'doctor_seleccionado-seleccionaDoctor');
    this.hora_seleccionada = {};

    
    for (let index = 0; index < this.lista_horarios_disponibles.length; index++) {
      const element = this.lista_horarios_disponibles[index];
      if (element.horario_desde==this.horario_seleccionado && element.fecha==this.fecha_seleccionada && this.doctor_seleccionado==doctor) {
        this.hora_seleccionada = element;
        //console.log(this.hora_seleccionada, 'hora_seleccionada-seleccionaDoctor');
        return;
      }
    }
  }

  asignar(item) {
    //console.log(item, 'item-asignar');
    this.solicitud_licencia_seleccionada = item;
  }
  
  generar(item) {
    //console.log(item, 'item-generarLicencia');
    this.solicitud_licencia_seleccionada = item;
    this.tipo_seleccionado_licencia= item.med_lev_typ_id;

    this.licencia= {
      fecha_inicio_reposo: this.limpiarFecha(item.med_lea_start_date),
      dias_reposo: item.med_lea_days,
      tipo_seleccionado: item.med_lev_typ_id,
      rut_hijo: item.med_lea_son_rut,
      fecha_nacimiento_hijo: this.limpiarFecha(item.med_lea_son_birth_date),
      diagnostico_seleccionado: item.med_lea_dia_det_id,
    }

  }

  limpiarFecha(fecha) {
    if (!fecha) {
      return '';
    }

    let fecha_aux = fecha.substring(0, 10).split('-');
    fecha_aux = fecha_aux[2] + '-' + fecha_aux[1] + '-' + fecha_aux[0];
    return fecha_aux;
  }

  reasignar(item) {
    //console.log(item, 'item-reasignar');
    this.reasignacion_seleccionada = item;
    this.solicitud_licencia_seleccionada = item;

  }
  
  seguroAsignar() {
    //console.log(this.hora_seleccionada,'this.hora_seleccionada');
    
    if (!this.hora_seleccionada || !this.hora_seleccionada.doc_lme_ava_id) {
      this.modalError('Faltan Datos', 'Faltan datos por ingresar.');
      return;
    }
    Swal.close();
    Swal.fire({

      html:
      `
        <div style="text-align: center; font-family: Mulish; font-style: normal;">
          <div class="col-12">
            <h1 style="padding: 0 50px; color: #171994; font-size: 20px; line-height: 22px;float: left;">Fecha: `+ this.hora_seleccionada.fecha +`</h1>
          </div>
          <div class="col-12">
            <h1 style="padding: 0 50px; color: #171994; font-size: 20px; line-height: 22px;float: left;">Horario: `+this.hora_seleccionada.horario_desde+`</h1>
          </div>
          <div class="col-12">
            <h1 style="padding: 0 50px; color: #171994; font-size: 20px; line-height: 22px;float: left;text-align: justify;">Médico: `+ this.hora_seleccionada.nombre_doctor +`</h1> <br> <br>
          </div>
          <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 20px; line-height: 28px;">¿Estás seguro de que deseas asignar esta solicitud?</h1>
        </div>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Asignar',
      buttonsStyling: false,
      reverseButtons:true,
      customClass: {
        confirmButton: 'btn-swal-licencia-confirm',
        cancelButton: 'btn-swal-licencia-cancel',
        popup: 'container-swal-licencia-customer'
      }
    })
      .then((result) => {
        //console.log(result,'result-seguroAsignar');
        if (result.isConfirmed) {
          this.confirmarAsignar();
        }
      })

  }
  confirmarAsignar() {
    this._licenciaMedicaServices.postAssignMedicalLeave(this.hora_seleccionada.doc_lme_ava_id,this.solicitud_licencia_seleccionada.med_lea_id,this.hora_seleccionada.doc_use_id)
      .then(result => {
        //console.log(result, 'result-confirmarAsignar');
        this.resetVariables();
        this.modalLicenciaExitosa('Solicitud asignada con éxito');
      })
      .catch(error => {
        //console.log(error,'error-asignar');
        this.modalError('Error al asignar hora.','Por favor recargue y verifique que esa hora está disponible.');
      });
  }
  
  anular(item) {
    this.solicitud_licencia_seleccionada = item;
    //console.log(this.solicitud_licencia_seleccionada,'this.solicitud_licencia_seleccionada');
    
    Swal.close();
    Swal.fire({

      html:
      `
        <div style="text-align: center; font-family: Mulish; font-style: normal;">
          <div>
            <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 20px; line-height: 28px;">¿Estás seguro qué deseas anular esta solicitud de generación de licencia médica?</h1>
          </div>
        </div>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Anular solicitud',
      buttonsStyling: false,
      reverseButtons:true,
      customClass: {
        confirmButton: 'btn-swal-licencia-confirm',
        cancelButton: 'btn-swal-licencia-cancel',
        popup: 'container-swal-licencia-customer'
      }
    })
      .then((result) => {
        //console.log(result,'result-seguroAsignar');
        if (result.isConfirmed) {
          this.confirmarAnular();
        }
      })

  }

  confirmarAnular() {
    
    this._licenciaMedicaServices.postDeleteMedicalLeave(this.solicitud_licencia_seleccionada.med_lea_id)
    .then(result => {
      //console.log(result, 'result-confirmarAnular');
      this.resetVariables();
      this.modalLicenciaExitosa('Solicitud de generación de licencia anulada con éxito');
    })
    .catch(error => {
      //console.log(error,'error-asignar');
      this.modalError('Error al anular generación de licencia.','Por favor recargue y verifique que esa hora ya estaba anulada.');
    });

  }
  
  ver(item) {
    this.solicitud_licencia_seleccionada = item;
    //console.log(this.solicitud_licencia_seleccionada,'this.solicitud_licencia_seleccionada');
  }
  modalLicenciaExitosa(titulo) {
    Swal.close();    
    Swal.fire({
      html:
      ` <div style="text-align: center; font-family: Mulish; font-style: normal;">
          <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>
          <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">`+titulo+`</h1>
          <br>
        </div>`,
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      showConfirmButton:false,
      buttonsStyling: false,
      reverseButtons:false,
      customClass: {
        cancelButton: 'btn-swal-licencia-cancel',
        popup: 'container-swal-licencia-customer'
      },
      timer: 3000
    })
  }

  generaLicenciaPost() {
    //console.log('generaLicenciaPost');
    if (!this.verificarDatosLicencia()) {
      return;
    }

    let fecha:any = this._generalServices.verificarFecha(this.licencia.fecha_inicio_reposo);
    let fecha_hijo:any = this._generalServices.verificarFecha(this.licencia.fecha_nacimiento_hijo);
    let rut_hijo = (this.licencia.rut_hijo) ? this.licencia.rut_hijo : false;

    let params = {};
    this._licenciaMedicaServices.postGenerateMedicalLeave(
                                                          fecha.fecha_date_string,
                                                          this.licencia.dias_reposo,
                                                          this.licencia.tipo_seleccionado,
                                                          rut_hijo,
                                                          fecha_hijo.fecha_date_string,
                                                          this.solicitud_licencia_seleccionada.med_lea_app_boo_id,
                                                          this.solicitud_licencia_seleccionada.med_lea_id,
                                                        )
      .then(result => {
        this.modalLicenciaExitosa('Solicitud de generación de licencia generada con éxito.');
        this.resetVariables();
      })
      .catch(error => {
        //console.log(error,'error-solicitarLicencia');
        this.modalError('Error en la generación de licencia', 'Error en la generación de licencia.');
      })
  }

  verificarDatosLicencia() {
    //console.log('verificarDatosLicencia');
    let fecha = this._generalServices.verificarFecha(this.licencia.fecha_inicio_reposo);
    //console.log(fecha, 'fecha-verificarDatosLicencia');
    let fecha_hijo = this._generalServices.verificarFecha(this.licencia.fecha_nacimiento_hijo);
    //console.log(fecha_hijo,'fecha_hijo-verificarDatosLicencia');
    if (!fecha) {
      this.modalError('Fecha Inválida', 'Por favor ingrese una fecha válida.');
      return false;
    } else if (!this.licencia.dias_reposo || this.licencia.dias_reposo<1) {
      this.modalError('Días Inválidos', 'Por favor ingrese una cantidad de días válida.');
      return false;
    }else if (!this.licencia.tipo_seleccionado || this.licencia.tipo_seleccionado == '') {
      this.modalError('Tipo licencia Inválido', 'Por favor ingrese un tipo de licencia válido.');
      return false;
    } else if (this.licencia.rut_hijo && this.licencia.rut_hijo.length>0 && !this._generalServices.validaRut(this.licencia.rut_hijo)) {
      this.modalError('Rut del hijo Inválido', 'Por favor ingrese un rut válido.');
      return false;
    } else if (this.licencia.fecha_nacimiento_hijo && this.licencia.fecha_nacimiento_hijo.length>0 && !fecha_hijo) {
      this.modalError('Fecha hijo Inválida', 'Por favor ingrese una fecha válida.');
      return false;
    } else if (this.licencia.tipo_seleccionado == 4 && (!this.licencia.rut_hijo || !this.licencia.fecha_nacimiento_hijo)) {
      this.modalError('Datos hijo inválidos', 'Por favor los datos del hijo correctamente.');
      return false;
    } else {
      //console.log('Datos correctos-verificarDatosLicencia');
      return true;
    }
  }




  resetVariables() {
    this.obtenerLicenciasMedicas();
    this.obtenerHorariosDisponibles();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////      servicios_listar       //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

  postListMedicalLeaveType() {
    this._licenciaMedicaServices.postListMedicalLeaveType()
    .then(result => {
      this.tipos_licencia_medica = result;
      //console.log(this.tipos_licencia_medica);
    })
    
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////      servicios_listar       //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
}
