
<div class="calendar-container">

  <div class="row text-center">
    <div class="col-1">
      <div 
        class="btn btn-primary" 
        mwlCalendarPreviousView 
        [view]="view" 
        [(viewDate)]="viewDate"><mat-icon>arrow_back</mat-icon>
      </div>
    </div>
    <div class="col-10">
      <div>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</div>
    </div>
    <div class="col-1">
      <div 
        class="btn btn-primary" 
        mwlCalendarNextView 
        [view]="view" 
        [(viewDate)]="viewDate"><mat-icon>arrow_forward</mat-icon>
      </div>
    </div>
  </div>

  <mwl-calendar-month-view
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [refresh]="refresh" 
    [weekStartsOn]="weekStartsOn"
    [weekendDays]="weekendDays"
    [activeDayIsOpen]="activeDayIsOpen" 
    (dayClicked)="dayEnabledClicked($event.day)"> 
  </mwl-calendar-month-view>

  <br>

  
</div>
