import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZoomService } from 'src/app/services/zoom.service';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';


@Component({
  selector: 'app-card-consulta',
  templateUrl: './card-consulta.component.html',
  styleUrls: ['./card-consulta.component.css']
})
export class CardConsultaComponent implements OnInit {
  @Input() idconsulta: any; 
  @Output() messageEvent = new EventEmitter<string>();

  consulta: any = {};
  doctorName: any;
  doctorLastName: any;
  appointments: any;
  URL_DOCUMENTS = URL_DOCS;

  constructor(
    private _zoomService: ZoomService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    }
    ngOnInit(): void {
      
      this._zoomService.getConsulta(this.idconsulta)
        .then((resultado:any) => {
          // //console.log(resultado);
          resultado.fecha_consulta = resultado.fecha_consulta.substr(0, 10).replaceAll('-', '/');
          this.consulta = resultado;
          const appt = this.consulta;
          const hour = appt.hora.substr(0, 2);
          const minute = appt.hora.substr(3, 2);
          var apptDate = new Date(appt.fecha_consulta);
          apptDate.setHours(hour);
          apptDate.setMinutes(minute);
          const now = new Date();
          const formatedApptDate = this.formatApptDate(apptDate, now);
          //console.log(apptDate);
          
          if (formatedApptDate.length > 0) {
            let confirmFlag: boolean = false;
            const msToAppt = this.timeToX(apptDate, now);
            const minToAppt = this.msToMin(msToAppt);
            if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
              confirmFlag = true;
            }
            let goLobbyFlag: boolean = false;
            if (minToAppt <= 60) {
              goLobbyFlag = true;
            }
            const apptData = {
              date: formatedApptDate,
              doctorName: appt.nombre_doctor.split(' ', 1)[0],
              doctorLastName: appt.apellido_paterno_doctor,
              spec: appt.spe_name,
              subSpec: appt.sub_spe_name,
              img: appt.img ? appt.img : '',
              confirmFlag,
              confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
              goLobbyFlag,
              consulta:appt
            };
            this.appointments = apptData;
            this.doctorName = this.appointments.doctorName.charAt(0).toUpperCase();
            this.doctorLastName = this.appointments.doctorLastName.charAt(0).toUpperCase();

            
          }
          
          
        })  
  }

  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Hoy ' + returnDate[0];
    }
    if (minToAppt < 0) {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
       returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    return returnDate;
  }

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  
  goLobby = () => {
    this.router.navigate(['/', 'meetingRoom']);
  }

  confirmAppt = () => {
    this.router.navigate(['/', 'meetingRoom']);
  }
    
}
