<header class="topbar header">
    <div class="row">
        <div class="col-2   d-sm-none pl-0">
            <a class="sidebartoggler icon-bars-mobile">
                <i class="fas fa-bars"></i>
            </a>
        </div>
        <div class="col-6 col-md-5 pl-0">
            <a class="navbar-brand" (click)="ruta('dashboard')">
                <img src="./assets/images/logo-almamedis.png" alt="homepage" class="dark-logo" />
            </a>

        </div>
        
        <div class="col-md-2 notification text-center">
            <ng-container *ngIf="pageInfo.cus_url_phone">
                <a [attr.href]="'tel:'+pageInfo.cus_url_phone.replaceAll(' ','')"><i class="fas fa-phone-alt"></i></a>
                <span class="counter d-none d-md-inline-flex">{{pageInfo.cus_url_phone}}</span>
            </ng-container>
        </div>

        <div *ngIf="(usuario.typ_id == 2)" class="col-md-2 text-right d-none d-sm-block">
            <button (click)="ruta('reserva-hora')" class="btn btn-primary btn-agendar">Reserva tu hora</button>
        </div>
        <div *ngIf="(usuario.typ_id == 7)" class="col-md-2 text-right d-none d-sm-block">
            <button (click)="reservadeHora()" class="btn btn-primary btn-agendar">Reserva tu hora</button>
        </div>
        <div *ngIf="(usuario.typ_id == 1) || (usuario.typ_id == 8) " class="col-md-2 text-right d-none d-sm-block">

        </div>
        <div class="col-4 col-md-3 d-flex direction">
            <!--<div class="notification">
                <i class="fas fa-phone-alt"></i>
                <span class="counter d-none d-md-inline-flex">800 248 030</span>
            </div> -->

            <div class="profile d-none d-sm-block">
                <img class="img-fluid" src="./assets/images/MEDIS.LOGO.RRSS-06.png" width="36" alt="imagen de perfil">
            </div>
            <div class="profile_menu d-none d-sm-block">
                <div class="dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ usuario.nombre }}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item pointer" data-toggle="modal" data-target="#cambiacontrasena">Cambiar
                            contraseña</a>
                        <a *ngIf="usuario.typ_id == 1" class="dropdown-item" routerLink="/login-doctor">Cerrar
                            Sesión</a>
                        <a *ngIf="usuario.typ_id == 2" class="dropdown-item" routerLink="/login">Cerrar Sesión</a>
                        <a *ngIf="usuario.typ_id == 7" class="dropdown-item" routerLink="/login-contact">Cerrar
                            Sesión</a>
                        <a *ngIf="usuario.typ_id == 8" class="dropdown-item" routerLink="/login-coordinador">Cerrar
                            Sesión</a>
                        <a *ngIf="usuario.typ_id == 11" class="dropdown-item" routerLink="/login-customer">Cerrar
                            Sesión</a>
                        <a *ngIf="usuario.typ_id == 12" class="dropdown-item" routerLink="/login-admin">Cerrar
                            Sesión</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</header>
<!-- Modal Cambiar Contraseña-->
<div class="modal fade" id="cambiacontrasena" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <br>
                <p class="tituloModal">Cambiar contraseña</p>
                <br>
                <br><br><br>
                <form class="form col-md-12" id="CambiaPass" autocomplete="off" [formGroup]="cambiaPass"
                    (ngSubmit)="cambiaPassword()">
                    <br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <input class="form-control camposModalAgregar" type="password"
                                placeholder="Contraseña actual*" formControlName="contrasena_actual"
                                id="contrasena_actual">
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <input class="form-control camposModalAgregar" type="password"
                                placeholder="Nueva contraseña*" formControlName="contrasena_nueva"
                                (input)="checkPass1()" id="contrasena_nueva">
                        </div>
                    </div>
                    <div class="password-desc">
                        <p class="{{ passLength === 0 && !formSubmitted ?
                        'default' :
                        passMinLength ?
                        'ok' :
                        'nok' }}">
                            <i class="{{ passLength === 0 && !formSubmitted ?
                            'fal fa-circle' :
                            passMinLength ?
                            'fas fa-check-circle' :
                            'fas fa-times-circle' }}"></i>
                            Debe contener al menos 8 caracteres
                        </p>
                        <p class="{{ passLength === 0 && !formSubmitted ?
                        'default' :
                        passCapital ?
                        'ok' :
                        'nok' }}">
                            <i class="{{ passLength === 0 && !formSubmitted ?
                            'fal fa-circle' :
                            passCapital ?
                            'fas fa-check-circle' :
                            'fas fa-times-circle' }}"></i>
                            Debe contener al menos 1 mayúscula
                        </p>
                        <p class="{{ passLength === 0 && !formSubmitted ?
                        'default' :
                        passLetter ?
                        'ok' :
                        'nok' }}">
                            <i class="{{ passLength === 0 && !formSubmitted ?
                            'fal fa-circle' :
                            passLetter ?
                            'fas fa-check-circle' :
                            'fas fa-times-circle' }}"></i>
                            Debe contener al menos 1 minúscula
                        </p>
                        <p class="{{ passLength === 0 && !formSubmitted ?
                        'default' :
                        passNumber ?
                        'ok' :
                        'nok' }}">
                            <i class="{{ passLength === 0 && !formSubmitted ?
                            'fal fa-circle' :
                            passNumber ?
                            'fas fa-check-circle' :
                            'fas fa-times-circle' }}"></i>
                            Debe contener al menos 1 numero
                        </p>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <input class="form-control camposModalAgregar" type="password"
                                formControlName="contrasena_nueva_2" placeholder="Repite tu contraseña nueva*"
                                id="contrasena_nueva_2">
                        </div>
                    </div>
                    <p *ngIf="cambiaPass.hasError('noEsIgual')" class="mb-0 pb-0 mt-1 field-error">
                        <i class="fas fa-times-circle"></i>Debes ingresar la misma contraseña
                        del campo anterior.
                    </p>
                    <!--<div class="row">
                    <div class="col text-danger">
                        <p *ngIf="campoNoValidoPassword('contrasena_nueva_2')"> Las contraseñas deben ser iguales</p>
                    </div>
                </div>-->
                    <br>
                    <br>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                                    data-dismiss="modal">Cancelar</button>
                            </div>
                            <div class="col-md-6 letrabotonagregar">
                                <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar">Guarda
                                    cambios</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalFinLlamada1" aria-labelledby="exampleModalLabel" aria-hidden="true" #modalFinLlamada1>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <br>
                <!-- <p class="tituloModal">Cambiar contraseña</p>
            <br> -->
                <div style="text-align: center; font-family: Mulish; font-style: normal;">
                    <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">
                        Estás a punto de salir de tu consulta virtual</h1>
                    <br>
                    <p style="color: #171994; font-size: 18px; line-height: 24px;">Para volver a tu video consulta haz
                        click en <strong>No quiero salir,</strong> de lo contrario haz click en <strong>Salir de mi
                            consulta.</strong></p>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <!-- <div class="col-md-6">
                        <button type="button" class="btn-swal-cancel" style="text-align: center;" data-dismiss="modal">No quiero salir</button>
                    </div>
                    <div class="col-md-6">
                        <button type="submit" class="btn-swal-confirm" (click)="navegar()">Salir de mi consulta</button>
                    </div> -->

                        <div class="col-md-6">
                            <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                                (click)="navegar()">Salir de mi consulta</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                            <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar"
                                data-dismiss="modal" style="font-size: 16px;line-height: 24px;">No quiero salir</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<button #modalFinLlamada type="button" class="btn d-none" data-toggle="modal" data-target="#modalFinLlamada1">
    aux trigger for login loading modal
</button>