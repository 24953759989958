<app-title-header  [title]='_title' [subtitle]='_subtitle'>
</app-title-header>


<div class="col-md-12">
    <div class="row">
        <div class="col-md-6">
            <input autocomplete="disabled" type="search" placeholder='N° de interacción...' class="estiloInput inputStyle"
                [(ngModel)]="info_input">
        </div>
        <div class="col-md-6 ">
            <ng-multiselect-dropdown [placeholder]="'Seleccione un cliente'" [settings]="dropdownSettings"
                [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-md-6"><input autocomplete="disabled" type="search" [(ngModel)]="f_desde"
                placeholder='Fecha desde dd/mm/aaaa' class="estiloInput inputStyle"></div>
        <div class="col-md-6"><input autocomplete="disabled" type="search" [(ngModel)]="f_hasta"
                placeholder='Fecha hasta dd/mm/aaaa' class="estiloInput inputStyle"></div>
        <div class="col-md-12 asistenciaInput">
            <div class="row">
                <div class="col-6"><button (click)="exportarExcel()"
                        class="btn btn-outline-primary botonExportar">Exportar a Excel</button></div>
                <div class="col-6"><button (click)="listarDatos()"
                        class="btn btn-outline-primary botonListar">Listar</button></div>
            </div>
        </div>

        <div class="col-md-8" style="padding: 5px;">
            <input autocomplete="disabled" type="search" [(ngModel)]="email"
                placeholder='ingrese correo' class="estiloInput inputStyle">
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-12">
                    <button (click)="sendEmail()" class="btn btn-outline-primary botonExportar">Enviar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<table class="table table-sm table-hover">
    <thead style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 24px; color: #171994;align-items: center; text-align: center;background-color:#3166d84a;">
      <tr>
        <th scope="col">#</th>
        <th scope="col">First</th>
        <th scope="col">Last</th>
        <th scope="col">Handle</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">1</th>
        <td>Mark</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>
      <tr>
        <th scope="row">2</th>
        <td>Jacob</td>
        <td>Thornton</td>
        <td>@fat</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>Larry</td>
        <td>the Bird</td>
        <td>@twitter</td>
      </tr>
      <tr>
        <th scope="row">4</th>
        <td>Larry</td>
        <td>the Bird</td>
        <td>@twitter</td>
      </tr>
    </tbody>
  </table>