import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sala-chat',
  templateUrl: './sala-chat.component.html',
  styleUrls: ['./sala-chat.component.css']
})
export class SalaChatComponent implements OnInit {

  chat: boolean = true;
  consulta: any;
  constructor(
    private activatedRoute: ActivatedRoute,
  ) { 

    // var parametros_url;

    // this.activatedRoute.params.subscribe(parametros => {
      
    //   this.consulta = parametros;
    // });

  }

  ngOnInit(): void {
  }

}
