import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';

import { ScheduleService } from 'src/app/services/schedule.service';

import { CalendarMonthComponent } from 'src/app/components/calendar/calendar-month/calendar-month.component';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';


@Component({
  selector: 'app-agenda-medico-proximas',
  templateUrl: './agenda-medico-proximas.component.html',
  styleUrls: ['./agenda-medico-proximas.component.css']
})

export class AgendaMedicoProximasComponent implements OnInit {
  // @Input() vistaProximasConsultas: boolean = true; 
  // @Input() list_hours_doc: any; 
  @Output() messageEventHour = new EventEmitter<any>();   //al clickear 1 hora
  @Output() messageEventDay = new EventEmitter<any>();    //al clickear 1 dia
  @ViewChild(CalendarMonthComponent) CALENDAR: CalendarMonthComponent;

  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha
  options_week = { weekday: 'long'}; //opciones labels dia de la semana
  options_month = { month: 'long'}; //opciones labels mes

  list_hours: Array<any>;               //listado de horas disponibles en el componente
  events_calendar: Array<any> = [];
  hoursDay: Array<any> = [];              // Array de bloques de horas 
  daySelected: any = {};

  horas_calendar: boolean = true;
  horasAuxiliares = [];

  dia_seleccionado: boolean = false;      //si se selecciona un dia


  constructor(
    public scheduleService: ScheduleService,
  ) { }

  ngOnInit(): void {
    this.getHorasBloques();
  }

  //bloques de horarios
  getHorasBloques(){ 
    this.scheduleService.getScheduleTimes()
    .then( (data: any) => {
      this.hoursDay = data;
    })
  }



  sendToAgendaMedico(list_hours_doc: any, dia_inicial: Date = null){
    //console.log(list_hours_doc, dia_inicial)
    this.list_hours = list_hours_doc;
    this.sendToCalendar(this.list_hours, dia_inicial);
  }

  // Carga los eventos en la variable usada por el calendario
  sendToCalendar(eventsX, dia_inicial) {
    let events_calendar: Array<CalendarEvent> = []; //Creacion de los eventos para enviarlos al calendario
  
    for (let eve of eventsX){
      events_calendar.push({
        start: new Date(eve.sch_date.substr(0, 10).replaceAll('-','/')), //va solo date, podrian ir timestamp
        title: eve.sch_id,
      });
    }
    //console.log(events_calendar);
    
    this.CALENDAR.sendToCalendar(events_calendar, dia_inicial);
  }

  //respuesta que emite este componente al clickear una hora disponible
  clickHour(hora){
    this.messageEventHour.emit({ day: this.daySelected, hour: hora }) 
  }

  //emitida desde componente: al clickear flechas de cambio de mes
  clickMonthCalendar(data: any) {    
    //console.log("clickMonthCalendar");
  }

  //emitida desde componente: al clickear un dia en el calendario mensual
  clickDayCalendar(dayCalendar: CalendarMonthViewDay) {
    this.dia_seleccionado = true
    this.horasAuxiliares = [];
    this.daySelected.date =  dayCalendar.date;
    this.daySelected.dateInt = this.daySelected.date.getFullYear()*10000 + (this.daySelected.date.getMonth() +1)*100 + this.daySelected.date.getDate();
    this.daySelected.dateString =  (this.daySelected.date).toLocaleString('es-US', this.options);
    this.daySelected.dayTitle = (this.daySelected.date).toLocaleString('es-US', this.options_week) +' '+ this.daySelected.date.getDate() +' de '+ (this.daySelected.date).toLocaleString('es-US', this.options_month);

    let hoursX = [];
    if ( dayCalendar.events.length == 0 ) {
      this.daySelected.available = false;
      this.daySelected.hours_available = [];
    } else {
      this.daySelected.available = true;
      this.daySelected.sch_id = dayCalendar.events[0].title;
      
      for (let hour of this.list_hours){
        
        if (hour.sch_id == this.daySelected.sch_id){
          if(hour.hours){
            for (let xx of hour.hours){
              hoursX.push({
                sch_hou_id: xx.id,
                sch_hou_from: xx.from,
                sch_hou_to: xx.to,
                sch_sta_id: xx.sta_id,
              })
            }
          }
          else{
            hoursX.push({
              sch_hou_id: hour.sch_hou_id,
              sch_hou_from: hour.sch_hou_from,
              sch_hou_to: hour.sch_hou_to
            })
          }
        }
      }
      this.daySelected.hours_available = hoursX;

      for (let block of this.hoursDay){
        block.available = false;
        block.sch_hou_id = null;
        block.existe = false;
        for(let hour of this.daySelected.hours_available){
          block.existe = (hour.sch_sta_id)? block.existe : true;
          if (block.from == hour.sch_hou_from){
            block.available = (hour.sch_sta_id)? ( (hour.sch_sta_id == 21)? true: false ) : true;
            block.existe = true;
            this.horasAuxiliares.push(block);
          }
          else{ 
            block.existe = block.existe;
            block.available = block.available;
          }
          block.sch_hou_id = (block.from == hour.sch_hou_from)? hour.sch_hou_id: block.sch_hou_id; 
        }
      }
    }
    //console.log(this.horasAuxiliares);
    // this.messageEventDay.emit(this.daySelected)
    this.messageEventDay.emit({
      date: this.daySelected.date,
      dateInt: this.daySelected.dateInt,
      dateString: this.daySelected.dateString,
      dayTitle: this.daySelected.dayTitle
    })
  }





}
