<div class="col-md-12">
  <br>
  <div class="minititulo">
    Mi agenda
  </div>

  <div *ngIf="usuario.typ_id == 1">
    <app-con-sin-finalizar 
      [use_id]='usuario._id'>
    </app-con-sin-finalizar>
  </div>
</div>


<!-- Historial de consultas -->
<div style="margin: 0 5px;">
  <div class="titulo">Historial de consultas</div>

  <!-- filtros -->
  <div class="text-sm-left"  style="margin-bottom: 30px;">
    <div class="container-gray">
      <div class="col-12">
        <div class="row h-100 align-items-md-center pt-3 pb-3">
          <div class="col-sm-2">
            <span class="text-filter">Puedes filtrar por:</span>
          </div>
          <div class="col-sm-3 pl-0 mt-2">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="fecha-filter" name="fecha-filter" class="custom-control-input" 
                [checked]="showOptionFecha" (change)="showOptionFecha = true; strPacienteFilter = ''; " />
              <label class="custom-control-label label-description" for="fecha-filter">Fecha</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="nombre-filter" name="nombre-filter" class="custom-control-input"
              [checked]="!showOptionFecha" (change)="showOptionFecha = !showOptionFecha; strPacienteFilter = '';" />
              <label class="custom-control-label label-description" for="nombre-filter">Nombre</label>
            </div>
          </div>
          <div class="col-sm">
            <div class="border-left"></div>
          </div>
          <div class="col-sm-6">
            <div class="row form-group" *ngIf="showOptionFecha">
              <div class="col-sm-6 col-xs-12">
                <!-- <input type="text" name="f_desde" id="f_desde" [(ngModel)]="f_desde" placeholder='Fecha desde dd/mm/aaaa' style="padding: 15px; font-size: 18px; border-radius: 10px; max-width: 100%;"> -->
                <input type="date" class="form-control" (change)="changeFechas($event.value)"[(ngModel)]="strPacienteFilter" style="padding: 15px; font-size: 18px;">
              </div>
              <div class="col-sm-6 col-xs-12">
                <input type="date" class="form-control" (change)="changeFechas($event.value)" [(ngModel)]="strPacienteFilter_fin" style="padding: 15px; font-size: 18px;">
                <!-- <input type="text" name="f_hasta" id="f_hasta" [(ngModel)]="f_hasta" placeholder='Fecha hasta dd/mm/aaaa' style="padding: 15px; font-size: 18px; border-radius: 10px; max-width: 100%"> -->
              </div>
            </div>
            <div class="form-group" *ngIf="!showOptionFecha">
              <mat-icon class="icon-search">search</mat-icon>
              <ng2-completer name="nombreRequired" (selected)="changeNamePaciente($event)" [textNoResults]="'Paciente no encontrado'"
                [datasource]="completerData" [(ngModel)]="strPacienteFilter" [minSearchLength]="3" style="font-size: 18px;"
                [placeholder]="'Buscar paciente'" #nombreRequired="ngModel" [autoMatch]="true" [inputClass]="'form-control'"
                [clearUnselected]="true" [openOnFocus]="true">
              </ng2-completer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- next appointments MEDICO-->
  <div class="row" *ngIf="!solo_fecha">
    <div class="col-12">
      <div class="row d-sm-flex">
        <!-- <div *ngFor="let appt of auxAppts | filter: strPacienteFilter ; let i = index;" class="col-md-12"> -->
        <div *ngFor="let appt of auxAppts | filter:strPacienteFilter  ; let i = index;" class="col-md-12">
          <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
          <div class="card p-0 m-0" [ngClass]="{'border-confirm': appt.confirmFlag, 'border-go-lobby': appt.confirmFlag}">
            <div class="row card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
              <div class="col-md-7 col-xs-12 m-0 p-0 c-0 first-line">
                <app-profile-img [img]="appt.img" [doctorName]="appt.nombre_paciente"
                  [doctorLastName]="appt.apellido_paterno_paciente" style="margin-left: 10pximportant!;"
                  [color]="appt.goLobbyFlag ? 'confirm' : appt.confirmFlag ? 'confirm' : 'confirm'">
                </app-profile-img>
                <div class="ml-3 align-self-center">
                  <p class="card-text m-0 p-0 appt-date">{{ appt.nombre_paciente }} {{ appt.apellido_paterno_paciente }}
                    {{appt.apellido_materno_paciente}}</p>
                  <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{
                    appt.consulta.fecha_consulta | date:'dd-MM-yyyy'}} a las {{ appt.consulta.hora}}</p>
                  <p class="card-text m-0 p-0 ">{{ appt.spec }} </p>
                  <p *ngFor="let diagnostico of appt.consulta.diagnosticos  ; let y = index;" class="card-text m-0 p-0 ">
                    Diagnóstico N°{{y+1}}: {{ diagnostico.diagnostico }} </p>
                </div>
              </div>
              <div class="col-md-5 col-xs-12">
                <div class="row">
                  <div class="col-xs-12 ml-auto align-self-center" style="margin-right: 15px;">
                    <p *ngIf="appt.consulta.tel_doc_app_boo_sta_id == 39" class="card-text flag-helper appt-gris">*Finalizada</p>
                    <p *ngIf="appt.consulta.tel_doc_app_boo_sta_id == 44" class="card-text flag-helper appt-gris">*Paciente no se presenta</p>
                    <p *ngIf="appt.consulta.tel_doc_app_boo_sta_id != 39 && appt.consulta.tel_doc_app_boo_sta_id !=44" class="card-text flag-helper appt-gris">*No Finalizada</p>
                  </div>
                  <div class="col-xs-12 ml-auto-boton align-items-end justify-content-end flex-wrap" style="float: right; margin-right: 10px;">
                    <button type="button" class="btn btn-primary mr-auto" (click)="irdetallesPacientes(appt)">Detalles de la consulta</button>
                  </div>
                </div>
              </div>
              <br>
            </div>
          </div>
          <br>


        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="solo_fecha">
    <div class="col-12">
      <div class="row d-sm-flex">
          <div *ngFor="let appt of auxAppts_fin ; let i = index;" class="col-md-12">
            <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
            <div *ngIf="appt.consulta.tel_doc_app_boo_sta_id != 44" class="card p-0 m-0" [ngClass]="{'border-confirm': appt.confirmFlag, 'border-go-lobby': appt.confirmFlag}">
              <div class="row card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                <div class="col-md-7 col-xs-12 m-0 p-0 c-0 first-line">
                  <app-profile-img [img]="appt.img" [doctorName]="appt.nombre_paciente"
                    [doctorLastName]="appt.apellido_paterno_paciente" style="margin-left: 10pximportant!;"
                    [color]="appt.goLobbyFlag ? 'confirm' : appt.confirmFlag ? 'confirm' : 'confirm'">
                  </app-profile-img>
                  <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 appt-date">{{ appt.nombre_paciente }} {{ appt.apellido_paterno_paciente }}
                      {{appt.apellido_materno_paciente}}</p>
                    <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{
                      appt.consulta.fecha_consulta | date:'dd-MM-yyyy'}} a las {{ appt.consulta.hora}}</p>
                    <p class="card-text m-0 p-0 ">{{ appt.spec }} </p>
                    <p *ngFor="let diagnostico of appt.consulta.diagnosticos  ; let y = index;" class="card-text m-0 p-0 ">
                      Diagnóstico N°{{y+1}}: {{ diagnostico.diagnostico }} </p>
                  </div>
                </div>
                <div class="col-md-5 col-xs-12">
                  <div class="row">
                    <div class="col-xs-12 ml-auto align-self-center" style="margin-right: 15px;">
                      <p *ngIf="appt.consulta.tel_doc_app_boo_sta_id == 39" class="card-text flag-helper appt-gris">*Finalizada</p>
                      <p *ngIf="appt.consulta.tel_doc_app_boo_sta_id == 44" class="card-text flag-helper appt-gris">*Paciente no se presenta</p>
                      <p *ngIf="appt.consulta.tel_doc_app_boo_sta_id != 39 && appt.consulta.tel_doc_app_boo_sta_id !=44" class="card-text flag-helper appt-gris">*No Finalizada</p>
                    </div>
                    <div class="col-xs-12 ml-auto-boton align-items-end justify-content-end flex-wrap" style="float: right; margin-right: 10px;">
                      <button type="button" class="btn btn-primary mr-auto" (click)="irdetallesPacientes(appt)">Detalles de la consulta</button>
                    </div>
                  </div>
                </div>
                <br>
              </div>
            </div>
            <br>
  
  
          </div>
      </div>
    </div>
  </div>
  
</div>