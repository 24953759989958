<div class="card h-100 p-0 m-0" style="border-color: #316ad8;">
  <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
    <div class="m-0 p-0 first-line">
      <app-profile-img [img]="data.att_path" [doctorName]="data.n_doctor" [doctorLastName]="data.a_doctor">
      </app-profile-img>
      <div class="ml-3 align-self-center">
        <p class="card-text m-0 p-0 appt-date">{{ data.dia}} de {{data.mes}}</p>
        <p class="card-text m-0 p-0 appt-date">a las {{ data.hora}} horas.</p>
      </div>

      <div class="ml-3 align-self-center">
        <p class="card-text m-0 p-0 appt-date">Monto pendiente:</p>
        <p class="card-text m-0 p-0 doc-data">${{data.cha_final_value | puntoMiles}}</p>

      </div>

    </div>
    <p class="card-text pt-3 mr-5 doc-data">
      {{ data.nombre_doctor }} - {{ data.spe_name }}
    </p>
    <div style="text-align: center;">
      <!-- Distinto Día -->
      <div class="row">
        <div class="col-6">
          <button type="button" class="btn btn-outline-secondary mr-auto" (click)="cancelPending(data)"
            data-toggle="modal" tabindex="-1" [attr.data-target]="'#cancelPendingAppoiment'+data.id_consulta"
            style="float: left;">
            Cancelar reserva
          </button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary mr-auto" style="float: right;" (click)="finishPending()">
            Finalizar reserva
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirmacion -->

<div class="modal fade" [id]="'cancelPendingAppoiment'+data.id_consulta" [attr.aria-label]="'#cancelPendingAppoiment'+data.id_consulta"
  aria-hidden="true" tabindex="-1">

  <div class="modal-dialog modalCancelPending">
    <div class="row">
      <div class="col-12" style="align-self: center">
        <p class="modalTitle">¿Estás seguro que deseas cancelar esta reserva pendiente?</p>
      </div>
    </div>


    <div class="row box-cancel">
      <div class="col-12">
        <div class="card cancelappo h-100 p-0 m-0" style="border-color:#2babb7;">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
            <div class="m-0 p-0 first-line">
              <app-profile-img [img]="data.att_path" [doctorName]="data.n_doctor" [doctorLastName]="data.a_doctor">
              </app-profile-img>
              <div class="ml-5 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ data.dia}} de
                  {{data.mes}} a las {{ data.hora}} horas.</p>

                <p class="card-text pt-3 mr-5 doc-data">
                  {{ data.nombre_doctor }} - {{ data.spe_name }}
                </p>
              </div>

            </div>


          </div>
        </div>
      </div>

    </div>
    

    <div class="col-12 modal-btn" style="text-align: center; line-height: 20px;">
      <!-- Distinto Día -->
      <div class="row ">
        <div class="col-6">
          <button type="button" class="btn btn-outline-secondary mr-auto" 
             tabindex="-1" data-dismiss="modal"
             data-toggle="modal" style="float: left;">
            Volver atrás
          </button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary mr-auto" (click)="confirmcancelPending()"
          data-toggle="modal" style="float: right;">
            Sí, estoy seguro
          </button>
        </div>
      </div>

    </div>
  </div>
</div>