import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.css']
})
export class BenefitComponent implements OnInit {
  @Input() benefit: any = {};
  URL_DOCS = URL_DOCS;

  constructor(
    private router: Router,
    private generalServices: GeneralService,
  ) {

  }

  ngOnInit(): void {
  }

  goToBenefit() {
    if (!this.benefit || !this.benefit.agre_id_) {
      this.generalServices.modalError('Error','No se puede acceder a esa información.');
      return;
    }
    let url = '/beneficio/info/'+this.benefit.agre_id_;
    this.router.navigateByUrl(url);
  }

}
