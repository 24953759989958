<app-title-header *ngIf="!vista_externa" [title]='_title' [subtitle]='_subtitle'>
</app-title-header>

<app-steps [steps]="steps" [showSuccessMessage]="showSuccessMessage"></app-steps>


<div class="col-12 mt-4 sm-7 text-sm-left container-subtitle-bar">
  <div class="row">
    <div
      [ngClass]="{ 'col-12': steps.searchDoctor,'col-8': steps.selectDate || steps.showSummary || steps.completeForm  || steps.payment}"
      class="col-8 col-md-8 col-sm-7">
      <h3 class="text-select-date">{{ subtitle }}</h3>
    </div>


    <div class="col col-md-4 text-right" style="display: flex;
    align-items: center;
    justify-content: flex-end;" *ngIf="!data_pending_appo.id_consulta">
      <a href="javascript:void(0);" (click)="checkStep()" *ngIf="!steps.searchDoctor && !showSuccessMessage"
        class="d-flex">
        <i class="material-icons">chevron_left</i>
        <span class="ml-2">Volver</span>
      </a>
    </div>
    <div class="col col-md-4 txt-right" style="display: flex;
    align-items: center;
    justify-content: flex-end;" *ngIf="data_pending_appo.id_consulta">
      <a href="javascript:void(0);" routerLink="/dashboard" class="d-flex">
        <i class="material-icons">chevron_left</i>
        <span class="ml-2">Salir de la reserva</span>
      </a>
    </div>
  </div>
</div>



<!-- Modal volver -->

<div class="modal fade modal-volver" id="volerStep1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p></p>
        <br />
        <div class="form-group row">
          <div class="col-md-12">
            <p class="tituloModal">
              Los datos que ingresaste se perderan
            </p>
            <p class="subTituloModal">
              Si decides salir de este paso, al avanzar se perdera la informacion de la reserva. </p>
          </div>
        </div>
        <br /><br />
        <div>
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn botonCancelar colorLetraVolver" data-dismiss="modal" data-toggle="modal"
                data-target="#showEspecialistaModal">
                Volver a reserva
              </button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="button" (click)="checkStep()" class="btn btn-primary botonVolver" data-dismiss="modal"
                data-toggle="modal">
                Salir de reserva
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Paso 1 -->
<div class="col-12 text-sm-left" *ngIf="steps.searchDoctor">
  <div class="container-gray">
    <div class="col-12">
      <div class="row h-100 align-items-md-center pt-3 pb-3">
        <div class="col-sm-2">
          <span class="text-filter">También puedes filtrar por:</span>
        </div>
        <div class="col-sm-5 pl-0 mt-2">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="area-filter1" name="area-filter" class="custom-control-input"
              [checked]="showOptionMedical" (change)="showOptionMedical = true;" />
            <label class="custom-control-label label-description" for="area-filter1">Médico/Profesional</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="area-filter2" name="area-filter" [checked]="!showOptionMedical"
              class="custom-control-input" (change)="showOptionMedical = !showOptionMedical" />
            <label class="custom-control-label label-description" for="area-filter2">Área médica</label>
          </div>
        </div>
        <div class="col-sm">
          <div class="border-left"></div>
        </div>
        <div class="col-sm-4">
          <div class="form-group" [ngClass]="{'d-none': !showOptionMedical}">
            <i class="material-icons icon-search">search</i>
            <ng2-completer name="docRequired" (selected)="changeDoctor($event)" [textNoResults]="'Médico no encontrado'"
              [datasource]="completerData" [(ngModel)]="strDoctorName" [minSearchLength]="0"
              [placeholder]="'Busca tu médico'" #docRequired="ngModel" [autoMatch]="true" [inputClass]="'form-control'"
              [clearUnselected]="true" [openOnFocus]="true">
            </ng2-completer>
          </div>
          <div class="form-group" [ngClass]="{'d-none': showOptionMedical}">
            <select (change)="onChangeSpeciality($event.target.value)" class="custom-select select-speciality"
              name="search-speciality">
              <option value="">Buscar por área médica</option>
              <option *ngFor="let speciality of specialityList" [value]="speciality.spe_id">{{ speciality.spe_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-sm-12" *ngIf="steps.searchDoctor">
  <p style="color:gray; font-size: 20px; font-weight: 800;margin-top: 10px;"><b> Primeras horas disponibles:</b> </p>
  <div class="container-profesional" *ngIf="doctorList.length > 0">
    <div class="item-hour" *ngFor="let item of doctorList">
      <div class="row align-items-center pt-2">
        <div class="col-4 col-sm-2 ml-sm-4 pl-sm-4 pt-sm-4 pb-sm-4 pr-sm-0 ml-3">
          <img *ngIf="!item.att_path" src="./assets/images/no-image.png" alt="imagen del medico" class="img-thumbnail">
          <img *ngIf="item.att_path" [src]="URL_DOCUMENTS+item.att_path" alt="imagen del medico" class="img-thumbnail">
        </div>
        <div class="col-5 col-sm-3 pl-sm-0 pl-0">
          <div class="row ml-2">
            <div class="col-sm-12 mt-1">
              <span class="name-profesional"><strong>{{ item.use_name }} {{ item.use_paternal_surname }} {{
                  item.use_maternal_surname }}</strong></span>
            </div>
            <div class="col-sm-12 mt-2">
              <span *ngFor="let spe of item.specialties" class="medical-area">{{ spe.spe_name }}<br></span>
            </div>
            <div class="col-sm-12 mt-2">
              <div class="row">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.primera_hora" class="d-none d-sm-block" style="align-items: center;text-align: center">
          <p class="name-profesional" style="margin-bottom: 5px;">Primeras horas disponibles:</p>
          <button *ngIf="item.primera_hora" class="btn btn-primary boton-hora-extra"
            (click)="clickHourDoctor(item,0,item.primera_hora)">
            Fecha: <b>{{item.primera_fecha}}</b> <br>
            Hora: <b>{{item.horas_disponibles[0].sch_hou_from}}</b><br>
            <p class="p-boton-extra">Reservar</p>
          </button><br>
          <button *ngIf="item.segunda_hora" class="btn btn-primary boton-hora-extra"
            (click)="clickHourDoctor(item,1,item.segunda_hora)">
            Fecha: <b>{{item.segunda_fecha}}</b> <br>
            Hora: <b>{{item.horas_disponibles[1].sch_hou_from}}</b><br>
            <p class="p-boton-extra">Reservar</p>
          </button><br>
        </div>
        <div *ngIf="item.primera_hora" class="d-sm-none" style="align-items: center;text-align: center ;width: 100%;">
          <p class="name-profesional">Primeras horas disponibles:</p>
          <button *ngIf="item.primera_hora" class="btn btn-primary boton-hora-extra"
            (click)="clickHourDoctor(item,0,item.primera_hora)" style="width: auto !important;">
            Fecha: <b>{{item.primera_fecha}}</b> <br>
            Hora: <b>{{item.horas_disponibles[0].sch_hou_from}}</b><br>
            <p class="p-boton-extra">Reservar</p>
          </button><br>
          <button *ngIf="item.segunda_hora" class="btn btn-primary boton-hora-extra"
            (click)="clickHourDoctor(item,1,item.segunda_hora)" style="width: auto !important;">
            Fecha: <b>{{item.segunda_fecha}}</b> <br>
            Hora: <b>{{item.horas_disponibles[1].sch_hou_from}}</b><br>
            <p class="p-boton-extra">Reservar</p>
          </button><br>
        </div>
        <div class="col-12 col-sm-3 text-sm-right text-center pt-3 pb-3">
          <button (click)="redirectToStep2(item)"
            [ngClass]="!(item.hours_available>0)?  'unavailable-hours':'available-hours' "
            [disabled]="!(item.hours_available>0)" class="btn btn-primary">
            Más horas
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Fin Paso 1 -->

<!-- Paso 2 -->
<div class="col-12 mt-sm-4 text-sm-left ml-sm-3 p-0 ml-3 pl-sm-3 pr-sm-3" *ngIf="steps.selectDate">
  <div class="row container-profesional-detail p-3 mb-sm-0">
    <div class="col-6 col-sm-1 mr-sm-4 pr-0">
      <img *ngIf="!doctorSelected.att_path" src="./assets/images/imagen_doctor.png" alt="imagen del medico"
        class="image-doctor">
      <img *ngIf="doctorSelected.att_path" [src]="URL_DOCUMENTS+doctorSelected.att_path" alt="imagen del medico"
        class="image-doctor">
    </div>
    <div class="col-6 col-sm text-md-left m-0 pl-sm-0">
      <div class="row align-items-center h-100">
        <div class="col-sm-12 pl-0 pl-sm-3 mb-3 mb-sm-0">
          <span class="name"><strong>{{ doctorSelected.nombre_completo }}</strong></span>
        </div>
        <!-- <div class="col-sm-12 pl-0 pl-sm-3">
          <span class="area">{{ doctorSelected.specialties[0].spe_name }}</span>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div *ngIf="steps.selectDate" style="margin-left: 5px;">
  <app-agenda-medico2 class="" (messageEvent)='clickHourAgendaMedico($event)'>
  </app-agenda-medico2>
</div>

<!-- Fin Paso 2 -->

<!-- Paso 3 -->
<div class="col-12 mt-sm-4 text-sm-left ml-sm-3 ml-3" *ngIf="steps.completeForm">
  <div class="row container-profesional-detail p-3">
    <div class="col-6 col-sm-1 mr-sm-4 pr-0">
      <img *ngIf="!doctorSelected.att_path" src="./assets/images/imagen_doctor.png" alt="imagen del medico"
        class="image-doctor">
      <img *ngIf="doctorSelected.att_path" [src]="URL_DOCUMENTS+doctorSelected.att_path" alt="imagen del medico"
        class="image-doctor">
    </div>
    <div class="col-6 col-sm text-md-left m-0 pl-sm-0">
      <div class="row align-items-center h-100">
        <div class="col-sm-12 pl-0 pl-sm-3 mb-3 mb-sm-0">
          <span class="name"><strong>{{ doctorSelected.nombre_completo }}</strong></span>
        </div>
        <div class="col-sm-12 pl-0 pl-sm-3">
          <span class="date">{{ daySelected.dateString }} de {{ hourSelected.from }} a {{ hourSelected.to }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row container-profesional-detail p-3">
    <div class="col-12 col-sm-4 text-center text-sm-left mt-sm-2">
      <div class="row">

        <ng-container>
          <div class="col-12 info-col">
            <span class="remember-text">Paciente <span style="font-size: 20px; font-weight: 600; "> *</span></span>
            <div class="row mb-3 info-select">
              <select class="custom-select  select-user mb-sm-2" [(ngModel)]="beneficiary_id"
                (ngModelChange)="changeBeneficiary($event)" name="beneficiary_id" [disabled]='vista_externa'>
                <option value='' selected>Seleccione paciente</option>
                <ng-container *ngFor="let ben of beneficiariesXPlans">
                  <option [selected]="beneficiary_id == ben.use_id ? true : false" [ngValue]="ben.use_id">
                    {{ben.use_name}}
                    {{ben.use_paternal_surname}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </ng-container>

        <div class="col-12 info-col">
          <div>
            <span class="remember-text">Área médica <span style="font-size: 20px; font-weight: 600; "> *</span></span>
          </div>
          <div class="row mb-3 info-select">
            <select class="custom-select  select-user mb-sm-2" name="speciality" [(ngModel)]="specialitySelectedValue"
              (ngModelChange)="changeSpeciality($event)">
              <option value='' selected>Seleccione un área médica</option>
              <ng-container *ngFor="let speciality of compatibleSpecialities">
                <option [ngValue]="speciality.sub_spe_id">{{speciality.spe_name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <p *ngIf="compatibleSpecialities.length <= 0" class="description remember-text">No posees planes o convenios
          disponibles para atenderte con este profesional</p>


        <ng-container>
          <div class="col-12 info-col">
            <span class="remember-text">Acompañante<span *ngIf="beneficiarySelected.user_age < 15"
                style="font-size: 20px; font-weight: 600; "> *</span>
              <a *ngIf="beneficiarySelected.user_age > 15" style="font-weight: 600; margin-left: 5px;">
                (opcional)</a></span>

            <div class="col-12 info-col">
              <p *ngIf="beneficiarySelected.user_age < 15" class="description remember-text">Todo menor de edad debe
                venir acompañado de un mayor de edad.</p>
            </div>
            <!-- DESABILITO ACOMPAÑANTE POR REQUERIMIENTO -->
            <div class="row mb-3 info-select">
              <select class="custom-select select-user mb-sm-1" id="acompanante" [(ngModel)]="acompanante_id"
                (ngModelChange)="changeAcompanante($event)" name="acompanante">
                <option value='0' selected>Seleccione un acompañante</option>
                <ng-container *ngFor="let aco of acompList">
                  <option [ngValue]="aco.use_id">{{aco.use_name}}
                    {{aco.use_paternal_surname}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </ng-container>


      </div>
    </div>
    <div class="col-12 col-sm pl-0 pl-sm-3 mt-sm-2">
      <span class="remember-text">Motivo de consulta / síntomas <span style="font-size: 20px; font-weight: 600; ">
          *</span></span>
      <textarea class="reason p-3 p-sm-3 info-select" placeholder="Motivo de consulta / síntomas"
        name="appointment_reason" [(ngModel)]="appointment_reason" id="appointment_reason"></textarea>

      <!------------------ Modelo B2C--------------------->


      <br>
      <br>






    </div>

    <div class="col-12 footerReserva">
      <div class="col-6 col-sm-6 col-md-6 text-sm-left pl-0" style="max-width: 100%; display: flex;">
        <span class="text-danger" style="font-size: 16px; font-weight: 600;">(*) Campos obligatorios</span>
      </div>
      <div class="col-6 col-sm-6 col-md-6 text-center mt-3 pr-sm-0  text-sm-right" style="max-width: 100%;">
        <button *ngIf="(usuario.typ_id == 2)" class="btn btn-primary btn-available" (click)="redirectToStep4()">Ir al
          pago</button>
        <button *ngIf="(usuario.typ_id == 7)" class="btn btn-primary btn-available" (click)="redirectToStep4()">Ingresar
          reserva</button>
      </div>



    </div>
  </div>
</div>
<!-- Fin Paso 3 -->

<!--paso 4 -->

<div class="col-12 mt-sm-4 text-sm-left ml-sm-3 ml-3" *ngIf="steps.payment">
  <div class="row container-profesional-detail p-3">
    <div class="col-6 col-sm-1 mr-sm-4 pr-0">
      <img *ngIf="!doctorSelected.att_path" src="./assets/images/imagen_doctor.png" alt="imagen del medico"
        class="image-doctor">
      <img *ngIf="doctorSelected.att_path" [src]="URL_DOCUMENTS+doctorSelected.att_path" alt="imagen del medico"
        class="image-doctor">
    </div>
    <div class="col-6 col-sm text-md-left m-0 pl-sm-0">
      <div class="row align-items-center h-100">
        <div class="col-sm-12 pl-0 pl-sm-3 mb-3 mb-sm-0">
          <span class="name"><strong>{{ doctorSelected.nombre_completo }}</strong></span>
        </div>
        <div class="col-sm-12 pl-0 pl-sm-3">
          <span class="date"> {{specialitySelected.spe_name }}</span>
        </div>
        <div class="col-sm-12 pl-0 pl-sm-3">
          <span class="date">{{ daySelected.dateString }} de {{ hourSelected.from }} a {{ hourSelected.to }}</span>
        </div>
      </div>
    </div>
  </div>




  <div class="row container-profesional-detail p-3">

    <div class="col-12 medio-pago">
      <span class="remember-text mb-1">Prestación</span>
      <div>
        <ng-container>
          <div class="row">
            <div class="col-md-12">
              <span class="detalle">{{specialitySelected.spe_name }}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <hr>
    </div>




    <ng-container *ngIf="pageInfo.cus_can_pay">
      <div class="col-12 medio-pago">
        <span class="remember-text">Medio de pago</span>
        <div style="margin: 15px 0px 30px 10px;">
          <br>
          <ng-container class="planes">
            <div class="row pagos-items">
              <br>
              <div class="col-md-1">
                <mat-checkbox class="example-margin primary" [checked]="false" [disabled]="false"
                  [(ngModel)]="paymentMethod">
                </mat-checkbox>
              </div>
              <div class="col-md-5">
                <img class="image-medio" src="./assets/images/gallery/webpay-logo.png" width="60%"
                  alt="imagen medio de pago">
              </div>
            </div>
          </ng-container>
        </div>
        <hr>
      </div>
    </ng-container>





    <div class="col-12 container-convenios" *ngIf="!data_pending_appo.id_consulta">
      <span class="remember-text convenios ">Convenio</span>

      <div class="not-plans" *ngIf="planesDisponibles == 0">
        <div style="display: flex; flex-direction: row; ">
          <i class="material-icons">info</i>
          <span>No tienes planes o convenios activos que incluyan esta prestación. Para contratar un plan o convenio
            dirigete a https://almamedis.cl o comunícate con tu proveedor o sponsor.</span>
        </div>
      </div>


      <div style="margin: 40px 0px 10px 10px;" *ngFor="let plan of planesDisponibles" class="planes">
        <ng-container>

          <div class="row planes-items" (click)="!data_pending_appo.id_consulta?planRowSelected(plan):null">
            <br>
            <div class="col-md-1 ">
              <mat-checkbox class="example-margin primary" [checked]="plan.ben_id==planSelected.ben_id ? true : false"
                [disabled]="true">
              </mat-checkbox>
            </div>
            <div class="col-md-4">
              <img [src]="plan.image_plan" alt="auto" width="50%" class="image-medio">
            </div>
            <div class="col-md-4">
              {{ plan.cus_ben_name }}
            </div>
            <div class="col-md-3" style="text-align: left;">
              <ng-container *ngFor="let especialidad of plan.especialidades">
                <ng-container *ngIf="specialitySelected.spe_id == especialidad.spe_id">
                  <a>Subvención:
                    {{especialidad.porcentaje_descuento}}%</a>
                    <br>
                  <a *ngIf="especialidad.cantidad_consultas">Consultas disponibles:
                    {{especialidad.cantidad_consultas - especialidad.consultas_usadas}}</a>
                  <a *ngIf="!especialidad.cantidad_consultas">Consultas disponibles:
                    Ilimitadas</a>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <br>
        </ng-container>
      </div>
      <br>
      <hr>
    </div>

    <div class="col-12 planes" *ngIf="!data_pending_appo.id_consulta">
      <span class="remember-text terminos-condiciones">Código de descuento </span>

      <div>
        <ng-container>
          <div class=row>
            <div class="col-md-6">
              <br>
              <input type="text" class="form-control" [(ngModel)]="discountCode"
                placeholder="Ingrese un codigo de descuento">

              <p class="form-text text-success"
                *ngIf="discountCodeObject.dis_id && discountCodeObject.status; else codigoValido">
                *Código de descuento ingresado exitosamente
              </p>
              <ng-template #codigoValido>
              </ng-template>

              <p class="form-text text-danger" *ngIf="discountCode && !discountCodeObject.status; else codigoInvalido">
                *Código de descuento no valido
              </p>
              <ng-template #codigoInvalido>
              </ng-template>

              <!-- <p *ngIf="discountCodeObject.dis_id && discountCodeObject.status" class="form-text text-success">****Código de descuento ingresado exitosamente</p>
                <p *ngIf="discountCode && !discountCodeObject.status" class="form-text text-danger">*Código de descuento expirado</p> -->
            </div>

            <div class="col-md-6">
              <br>
              <button class="btn btn-aplicar" (click)="validarDcto()">Aplicar</button>
            </div>
          </div>

        </ng-container>

      </div>
      <hr>
    </div>

    <div class="col-md-4 col-sm-12 medio-pago">
      <span class="remember-text">Resumen del pago</span>
      <br>
      <div class="medio-pago">
        <ng-container>
          <div class=" row texto-pago">

            <div class="col-md-6 col-sm-12">
              <p>Subtotal: </p>
            </div>
            <div class="col-md-6 monto">
              <p>${{paymentInfo.originalValue | puntoMiles}}</p>
            </div>
          </div>
        </ng-container>
        <!-- Descuento convenio -->
        <!-- <ng-container *ngIf="!data_pending_appo.id_consulta"> -->
        <div class=" row texto-pago">
          <div class="col-md-6">
            <p>Descuento convenio: </p>
          </div>
          <div class="col-md-6 monto " *ngIf="planSelected.ben_id">
            <p>-${{paymentInfo.inssuranceDiscountValue | puntoMiles }}</p>
          </div>
          <div class="col-md-6 monto" *ngIf="!planSelected.ben_id">
            <p>-$0</p>
          </div>
        </div>
        <!-- </ng-container> -->
        <!-- Descuento codigo -->
        <ng-container>
          <div class=" row texto-pago">
            <div class="col-md-6">
              <p>Descuento código: </p>
            </div>
            <div class="col-md-6 monto">
              <p>-${{paymentInfo.discountValue | puntoMiles }}</p>
            </div>
          </div>
        </ng-container>
        <hr>
        <!-- Valor total -->
        <ng-container>
          <div class=" row texto-pago-total">
            <div class="col-md-6">
              <p>Valor total: </p>
            </div>
            <div class="col-md-6 monto">
              <p>${{paymentInfo.finalValue | puntoMiles }}</p>
            </div>
            <!-- <div class="col-md-6 monto" *ngIf="planSelected.ben_id">
              <p>${{paymentInfo.finalValue | puntoMiles }}</p>
            </div>
            <div class="col-md-6 monto" *ngIf="!planSelected.ben_id && !paymentInfo.discountValue">
              <p>${{paymentInfo.originalValue | puntoMiles }}</p>
            </div> -->
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-12 termCon" *ngIf="usuario.typ_id == 2">
      <hr>
      <div class>
        <span class="remember-text planes ">Términos y condiciones </span>
      </div>


      <div class="form-check planes">
        <input mdbCheckbox class="form-check-input" type="checkbox" [(ngModel)]="acceptTerms" id="flexCheckTerms" />
        <label class="form-check-label" for="flexCheckTerms">
          Acepto términos y condiciones.
        </label>

        <b class="terminos-condiciones" data-toggle="modal" data-target="#modalTerms"><span class="verMas"> Ver
            mas...</span></b>

        <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong">
        Terminos y condiciones
      </button> -->
      </div>

    </div>





    <div class="col-12 col-sm-12 text-sm-right text-center mt-3 pr-sm-0">
      <button *ngIf="(!data_pending_appo.id_consulta) && (usuario.typ_id == 2) && (paymentInfo.finalValue > 0)"
        class="btn btn-primary btn-available" data-toggle="modal" (click)="validateTerms()">Continuar</button>
      <button *ngIf="(!data_pending_appo.id_consulta) && (usuario.typ_id == 7) && (paymentInfo.finalValue > 0)"
        class="btn btn-primary btn-available" (click)="redirectToStep5()">Reservar</button>
      <button *ngIf="data_pending_appo.id_consulta" class="btn btn-primary btn-available" data-toggle="modal"
        (click)="validateTerms()">Pagar</button>
      <button *ngIf="!data_pending_appo.id_consulta && paymentInfo.finalValue == 0"
        class="btn btn-primary btn-available" data-toggle="modal" (click)="validateTerms()">Continuar</button>
    </div>

  </div>
</div>


<!-- Paso 5 -->

<div class="col-12 mt-sm-4 text-sm-left ml-3" *ngIf="steps.showSummary">
  <div class="row container-summary p-3">
    <div class="col-12 col-sm-12 text-center mt-3">
      <img *ngIf="!doctorSelected.att_path" src="./assets/images/imagen_doctor.png" alt="imagen del medico"
        class="image-doctor">
      <img *ngIf="doctorSelected.att_path" [src]="URL_DOCUMENTS+doctorSelected.att_path" alt="imagen del medico"
        class="image-doctor">
    </div>
    <div class="col-12 col-sm-12 text-center mt-4">
      <small>Tienes una hora con</small>
    </div>
    <div class="col-12 col-sm-12 text-center">
      <h3 class="doctor-name"><strong>Dr. {{ doctorSelected.nombre_completo }}</strong></h3>
    </div>
    <div class="col-12 col-sm-12 text-center">
      <span class="medical-area">{{ doctorSelected.specialties[0].spe_name }} </span>
    </div>
    <div class="col-12 col-sm-12 text-center mt-4">
      <small>El día</small>
    </div>
    <div class="col-12 col-sm-12 text-center">
      <h3 class="date">{{ daySelected.dateString }} de {{ hourSelected.from }} a {{ hourSelected.to }} </h3>
    </div>
    <div class="col-12 col-sm-12 text-center mt-5"
      *ngIf="(!data_pending_appo.id_consulta) && (usuario.typ_id == 2) && (paymentInfo.finalValue > 0)">
      <button class="btn btn-primary btn-available" (click)="confirm()">Pagar</button>
    </div>
    <div class="col-12 col-sm-12 text-center mt-5" *ngIf="paymentInfo.finalValue == 0">
      <button class="btn btn-primary btn-available" (click)="confirm()">Confirmar</button>
    </div>
    <div class="col-12 col-sm-12 text-center mt-5"
      *ngIf="(!data_pending_appo.id_consulta) && (usuario.typ_id == 7) && (paymentInfo.finalValue > 0)">
      <button class="btn btn-primary btn-available" (click)="confirm()">Confirmar</button>
    </div>

  </div>
</div>


<!-- Fin Paso 5 -->

<!-- Mensaje exito -->
<div class="col-12 mt-sm-4 text-sm-left ml-3" *ngIf="showSuccessMessage ">

  <div class="row container-success-message p-3">
    <div class="col-12 col-sm-12 text-center mt-3">
      <img src="./assets/images/reserva.png" alt="imagen del medico" class="image-doctor">
    </div>
    <div class="col-12 col-sm-12 text-center mt-3" *ngIf="usuario.typ_id == 2">
      <h3 class="title">¡Tu reserva ha sido generada con éxito!</h3>
    </div>
    <div class="col-12 col-sm-12 text-center mt-3" *ngIf="usuario.typ_id == 7 && paymentInfo.finalValue > 0">
      <h3 class="title">¡Una reserva pendiente se ha generado con éxito!</h3>
    </div>
    <div class="col-12 col-sm-12 text-center mt-3" *ngIf="usuario.typ_id == 7 && paymentInfo.finalValue == 0">
      <h3 class="title">¡Una reserva ha sido generada con éxito!</h3>
    </div>
    <div class="col-12 col-sm-12 text-center mt-3">
      <h3 class="titleReserva">Nº de reserva: {{id_consulta_reservada}}</h3>
    </div>
    <div class="col-12 col-sm-12 text-center" *ngIf="usuario.typ_id == 2">
      <small>Se ha enviado el detalle de la reserva a tu correo electrónico.</small>
    </div>
    <div class="col-12 col-sm-12 text-center" *ngIf="usuario.typ_id == 7 && paymentInfo.finalValue > 0">
      <small>La reserva ha quedado pendiente de confirmación y pago por parte del paciente.</small>
      <br>
      <small>Se enviará un correo electrónico al paciente indicando que tiene 1 hora para realizar el pago y confirmar.
        la reserva de la consulta.</small>
    </div>
    <div class="col-12 col-sm-12 text-center" *ngIf="usuario.typ_id == 7 && paymentInfo.finalValue == 0">
      <small>Se ha enviado el detalle de la reserva al correo electrónico del paciente.</small>
    </div>
    <div class="col-12 col-sm-12 text-center mt-5">
      <button class="btn btn-primary btn-available" (click)="aMisProximasConsultas()">Ir al Inicio</button>
      <!--(click)="ngOnInit()"-->
    </div>
  </div>
</div>


<!-- Fin Mensaje exito -->

<div class="modal fade hide" id="errorAppointment" tabindex="-1" role="dialog" aria-labelledby="errorAppointment"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content m-0 p-0" style="border-radius: 20px; align-items: center;">
      <div class="modal-body m-0 p-0">
        <div class="container-fluid m-0 p-0 text-center">
          <i class="material-icons " style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br>
          <h1
            style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;">
            {{errorMsg}}</h1>
          <br>
          <button class="btn swal2-custom-cancel-btn" type="button" data-dismiss="modal" aria-label="Close">Volver
            atrás</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalTerms" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body modal-scrollable">
        <!-- Agrega la clase "modal-scrollable" al elemento "modal-body" -->
        <div class="modal-content-scroll">

          <h5 id="exampleModalLongTitle">TÉRMINOS Y CONDICIONES DE USO DE PLATAFORMA DE TELEMEDICINA ALMAMEDIS.<br>
            NO UTILICE ESTE SITIO WEB EN CASO DE UNA EMERGENCIA O URGENCIA MEDICAS<br>
            SI TIENE UNA EMERGENCIA O URGENCIA DE SALUD DIRIJASE DE INMEDIATO A UN CENTRO DE ATENCIÓN HOSPITALARIA O
            CLINICA.</h5>
          <br>
          <div class="terminos-condiciones">

            <h4>BIENVENIDO</h4>
            <br>
            Usted está accediendo a esta plataforma para poder hacer uso del servicio de asistencia de telemedicina.
            Para estos efectos usted ha contratado los servicios de la asistencia de telemedicina con Almamedis, quien
            ha dispuesto los Términos y Condiciones para el uso de este sitio web, así como de los servicios y las
            herramientas provistas a través de éste, lo que está condicionado a la aceptación por parte de usted de los
            términos de uso descritos a continuación.
            El acceso y uso de este sitio web se rige por los Términos y Condiciones descritos a continuación, así como
            por la legislación que se aplique en la República de Chile a los mismos. En consecuencia, todas las visitas
            y todos los servicios que se realicen en este sitio, como asimismo sus efectos jurídicos, quedarán regidos
            por estas condiciones o reglamento y sometidas a esa legislación.
            Este documento describe los términos, condiciones generales y las condiciones particulares, en adelante
            ("Términos y Condiciones” aplicables al uso de los servicios ofrecidos y/o provistos por Almamedis), dentro
            del sitio web https://telemedicina.almamedis.cl, incluidas las herramientas provistas a través del mismo
            para la realización de video-consultas a distancia e intercambio de información y/o datos personales, en
            adelante también referidos como “Servicios”.
            Cualquier persona que desee usar el Sitio o los Servicios podrá hacerlo previa aceptación de los Términos y
            Condiciones acá descritos.
            <br>
            <br>
            <h4>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO
              Y
              VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS ASOCIADOS AL MISMO.</h4>
            <br>
            Usted debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones, previo
            a su inscripción como Usuario de Almamedis.
            Debe entender que las interacciones con los proveedores de servicios de consulta médica a través de
            Almamedis son un complemento de la relación personal que pueda tener con un profesional de la salud. Por
            consiguiente, los servicios ofrecidos por Almamedis no están necesariamente destinados a sustituir los
            exámenes físicos o controles regulares de salud que puede realizar o le aconseje un profesional de salud.
            <br>
            <br>
            <h4>INDEPENDENCIA DE CRITERIO PROFESIONAL</h4>
            <br>
            Usted entiende y acepta que Almamedis presta sus servicios vía plataforma web, para atenciones de salud, las
            que son efectuadas bajo el conocimiento y criterio del profesional respectivo.
            Todos los profesionales de la salud que se encuentran disponibles para consultas “on-line”, ofrecen sus
            servicios en el libre ejercicio de su profesión y utilizan Almamedis como un medio para comunicarse con
            Usted o su beneficiario.
            Cualquier información, recomendación, indicación o diagnóstico recibido de un “Profesional de la salud” a
            través de Almamedis, proviene exclusivamente de aquel, no pudiendo Almamedis intervenir en el acto
            profesional mismo, ya sea que se trate de diagnóstico, medicación, tratamiento u otros. Sin perjuicio de
            ello, Almamedis, es responsable de la elección de los profesionales que acceden a la plataforma y de
            chequear sus títulos o habilitaciones profesionales, de manera que quienes prestan su servicio mediante la
            plataforma Almamedis sean profesionales habilitados en Chile para el ejercicio de la profesión médica.
            <br>
            <br>
            <h4>SEGURIDAD Y FUNCIONAMIENTO DE LA PLATAFORMA Y EL SERVICIO</h4>
            <br>
            Queda establecido que Almamedis implementará y ejecutará todos los procedimientos y estándares de seguridad
            de la información que adhieren a la norma ISO27001, de modo de proteger su privacidad y limitar el riesgo de
            pérdida de información.
            Usted conoce y acepta que para el adecuado funcionamiento de la plataforma Almamedis debe contar con una
            conexión a internet de banda ancha estable y un dispositivo electrónico con las características técnicas
            suficientes para reproducir una videoconferencia. Almamedis no se responsabiliza de una interrupción en el
            servicio a causa de fallas en la conexión que provengan de la calidad de la banda ancha y/o de los equipos
            electrónicos de su propiedad.
            <br>
            <br>
            <h4>SELECCIÓN DE PROFESIONALES MÉDICOS DISPONIBLES</h4>
            <br>
            Cuando el Paciente o “Usuario” solicita una consulta para obtener la atención de un “Profesional de la
            salud”, Almamedis le ofrecerá la opción de consultar con cualquier profesional independiente afiliado a
            nuestra red, que se encuentre disponible para atenderlo. Para su tranquilidad, Almamedis selecciona a
            profesionales de la salud que cumplen con los requisitos que la legislación vigente en nuestro país para
            proveer los servicios que ofrecen.
            Cuando Usted solicita una consulta, está aceptando específicamente que el “Profesional de la salud” que
            Usted ha seleccionado podrá, desde ese momento, acceder a su historial clínico que se encuentre disponible
            en la plataforma. Igualmente, al solicitar una consulta, usted está dando inicio a una relación directa
            “Profesional de la salud” – “Usuario”, a través de la plataforma de telemedicina de Almamedis.
            <br>
            <br>
            <h4>RESPONSABILIDAD</h4>
            <br>
            Almamedis es responsable de contar con una red de profesionales de salud idóneos que cumplan con la
            normativa vigente para ejercer su profesión en Chile.
            <br>
            <br>
            <h4>REGISTRO COMO USUARIO</h4>
            <br>
            Para tener acceso a los servicios en línea, deberá registrarse como “Usuario” y deberá crear una cuenta
            personal, suministrando la información que se solicita. Con la excepción de las cuentas de sus asegurados
            adicionales cuando corresponda, Usted acuerda no crear más de una cuenta de usuario, ni crear una cuenta
            para otra persona, si no ha sido autorizado de manera expresa y escrita. Asimismo, Usted se compromete a
            proveer información correcta, verdadera y actualizada al momento de registrarse o cuando realice la
            actualización de su ficha de información personal para así permitir una buena gestión en trámites como
            cambios de contraseña, recepción de comprobantes de venta y confirmación de horas médicas.
            <br>
            <br>
            <h4>SEGURIDAD</h4>
            <br>
            Al registrarse, deberá ingresar sus datos y una clave de acceso (la cual podrá modificar regularmente).
            Estos datos son personales e intransferibles y usted es responsable de mantener la seguridad de su cuenta,
            cuidando en todo momento la seguridad y confidencialidad de sus claves de acceso. Usted entiende y acepta
            que en ningún caso podrá facilitar el acceso de terceros a Almamedis mediante el uso de su nombre de usuario
            y contraseña.
            <br>
            <br>
            <h4>POLÍTICA DE PRIVACIDAD</h4>
            <br>
            Almamedis garantiza la confidencialidad “Profesional de la Salud” – “Usuario”. Toda información que
            relacione su identidad con las condiciones físicas y mentales presentes, pasadas o futuras y su historia de
            salud, es considerada información de carácter privado y será tratada como tal.
            Como parte de su proceso operativo, Almamedis registra su información personal y se obliga a no cederla,
            licenciarla o compartirla fuera de Almamedis ni a sus entidades asociadas. Con la aceptación de estos
            Términos y Condiciones, usted nos autoriza desde ya en forma expresa a almacenar y emplear dicha información
            para permitir que Almamedis provea los servicios de telemedicina.
            El usuario gozará de todos los derechos que le reconoce la Ley Nº19.628 sobre protección de datos y la ley
            del consumidor en Chile.
            <br>
            <br>
            <h4>COOKIES</h4>
            <br>
            Como la mayoría de los sitios web, utilizamos "cookies" y archivos de registro web para analizar el uso del
            sitio, para mejorar la calidad de nuestro servicio y con la finalidad de personalizar en la medida de lo
            posible su experiencia en nuestro sitio web. Una cookie es un archivo de datos que reside en su computadora,
            teléfono inteligente, tableta o cualquier otro dispositivo desde el que ingrese y permite su reconocimiento
            como usuario cuando regrese a nuestro sitio web desde el mismo equipo, siempre que utilice el mismo
            navegador.
            Usted tiene el derecho de eliminar o bloquear las cookies de nuestro sitio web utilizando la configuración
            de su navegador, pero esto podría afectar su capacidad para ingresar o utilizar nuestros servicios.
            <br>
            <br>
            <h4>POLÍTICAS Y CONDICIONES DE PAGO</h4>
            <br>
            Al realizar el pago a través del sistema web de Transbank o cualquier otro método que Almamedis estime
            conveniente, usted acepta las condiciones que se establecen a continuación, manifestando su total
            conformidad. Por lo que solicitamos leer con detención los términos y condiciones que rigen el uso de
            nuestra página web, junto con el alcance de la contratación electrónica del servicio de telemedicina.

            El valor del servicio de atención de telemedicina deberá ser pagado antes de recibir la atención, pudiendo
            utilizar las opciones de pago que estarán disponibles en el proceso de agendamiento de la consulta. Las
            formas de pago podrán ser, pero no se limitarán: Transbank y otras tecnologías de pago virtual.

            El servicio de telemedicina deberá ser pagado de forma particular en su totalidad o en parte, dependiendo de
            si se selecciona un plan o convenio. Se podrá gestionar posteriormente el reembolso con la institución de
            salud previsional que tenga el paciente atendido, sin embargo, Almamedis no se responsabiliza en caso de que
            el paciente no tenga la cobertura o la institución de salud previsional se niegue a efectuar el reembolso.
            Si existiera un cobro por uso de un servicio adicional específico, no cubierto en el precio final de la
            atención, usted será informado, de forma previa y a través del sistema, del cargo correspondiente a aplicar
            en el momento de acceder al servicio en cuestión.
            Los servicios ofrecidos en esta plataforma, salvo que se señale un método diferente para casos particulares
            u ofertas de determinados servicios, sólo podrán ser pagados con los medios disponibles y que se indican en
            el sistema. Las tarjetas emitidas en el extranjero no admiten la modalidad de pago en cuotas.
            El uso de las tarjetas se sujetará a lo establecido en estos términos y condiciones y en relación con su
            emisor a lo pactado en los respectivos contratos de apertura y reglamento de uso. En caso de contradicción,
            predominará lo expresado en ese último instrumento. A mayor abundamiento, tratándose de tarjetas bancarias o
            de tarjetas no bancarias aceptadas en este sitio, los aspectos relativos a ésta, tales como la fecha de
            emisión, caducidad, cupo, bloqueos, etc., se regirán por el respectivo contrato de apertura y reglamento de
            uso, de tal forma que Almamedis no tendrá responsabilidad en cualquiera de los aspectos señalados. El sitio
            podrá indicar determinadas condiciones de compra según el medio de pago que se utilice por el usuario.
            <br>
            <br>
            <h4>POLÍTICAS DE CANCELACIÓN, REEMBOLSOS Y NO PRESENTACIÓN</h4>
            <br>
            La cancelación o modificación del servicio debe realizarse antes de las 20:00 horas del día anterior a la
            consulta para que se procese la devolución del cargo de la reserva. Si se realiza con menos tiempo del
            descrito, Almamedis no podrá realizar modificaciones al servicio ni realizar ningún tipo de devolución por
            los servicios contratados.
            En el caso de la cancelación, los servicios que hayan sido reservados utilizando algún plan o convenio en la
            plataforma, no se descontarán de la cantidad total establecida, solo en los casos que dichas cancelaciones
            se hayan realizado con al menos 1 hora de anticipación a la hora de la consulta.

            El profesional de la salud, por protocolo, esperará al paciente hasta 15 minutos posterior a iniciada la
            hora de la consulta. Si en ese periodo de tiempo el paciente no se conecta, es posible que la atención no se
            concrete, a menos que exista una coordinación externa entre paciente y profesional de la salud apoyada por
            la mesa de ayuda. En caso de no presentación del paciente, no existirá devolución del dinero.

            El paciente deberá esperar un máximo de 10 minutos al profesional de la salud. Si en ese periodo el
            profesional de la salud no se conecta, la mesa de ayuda llamará al paciente para ofrecer alternativas para
            un reagendamiento o reembolso.
            Los reembolsos por citas canceladas se procesarán dentro de los 30 días hábiles posteriores a la
            cancelación, siempre que la cancelación se haya realizado dentro del plazo estipulado. Los reembolsos se
            realizarán al método de pago original utilizado para la cita u otro señalado por el paciente en un proceso
            manual de reembolso.
            <br>
            <br>
            <h4>PRECIOS</h4>
            <br>
            El precio de las consultas en la plataforma puede estar sujeto a cambios. Los usuarios podrán visualizar el
            precio actualizado del servicio al momento de realizar el agendamiento y el pago.
            <br>
            <br>
            <h4>PROPIEDAD INTELECTUAL E INDUSTRIAL – ENLACES</h4>
            <br>
            Todos los derechos de propiedad industrial e intelectual de este sitio, así como de los elementos contenidos
            en el mismo (incluyendo con carácter enunciativo pero no limitativo, marcas, signos distintivos, nombres
            comerciales, dominios, imágenes, fotografías, sonido, audio, video, diseño gráfico, código fuente, logos,
            contenidos, textos, gráficos, ilustraciones, bases de datos y demás elementos) son de la propiedad exclusiva
            de Almamedis, quien se reserva expresamente todos los derechos derivados del mismo.
            Los contenidos de las pantallas relativas a los servicios de Almamedis, así como también los programas,
            bases de datos, redes y/o archivos que permitan al usuario acceder y usar su cuenta, son propiedad de
            Almamedis y están protegidos por las leyes y los tratados internacionales de derecho de autor, marcas,
            patentes, modelos y diseños industriales.
            El Sitio puede contener enlaces a otros sitios web, lo cual no indica que sean propiedad u operados por
            Almamedis. En virtud de que Almamedis no tiene control sobre tales sitios, no será responsable por los
            contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por eventuales daños o pérdidas
            ocasionadas por la utilización de éstos, sean causadas directa o indirectamente. La existencia de enlaces a
            otros sitios web no implica una sociedad, relación, aprobación, respaldo de Almamedis a dichos sitios y/o su
            contenido.
            Queda expresamente prohibida la reproducción, distribución o difusión de la totalidad o parte de los
            contenidos de este sitio con fines comerciales, sin la autorización expresa y escrita de Almamedis. El uso
            indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización
            expresa y por escrito de Almamedis.
            El Usuario se compromete a respetar los derechos de propiedad Intelectual e Industrial de Almamedis y deberá
            abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de
            seguridad que estuviera instalado en este sitio web.
            <br>
            <br>
            <h4>MODIFICACIONES</h4>
            <br>
            Almamedis se reserva el derecho de efectuar sin previo aviso las modificaciones y/o mejoras que considere
            oportunas en este sitio web, o en sus aplicaciones, pudiendo cambiar, suprimir o añadir enlaces, contenidos
            y servicios que se prestan a través del mismo y/o la forma en que aparezcan presentados.
            <br>
            <br>
            <h4>DERECHO DE EXCLUSIÓN</h4>
            <br>
            Almamedis se reserva el derecho de denegar o retirar el acceso a su sitio web y/o servicios ofrecidos a
            cualquier usuario que incumpla las condiciones estipuladas en estos Términos y Condiciones. El uso indebido
            o malicioso será notificado al Usuario.
            <br>
            <br>
            <h4>MODIFICACIONES DEL PRESENTE AVISO LEGAL</h4>
            <br>
            Almamedis se reserva el derecho a modificar, desarrollar o actualizar en cualquier momento, con previa
            notificación, los Términos y Condiciones de este sitio web. El Usuario deberá aceptar los Términos y
            Condiciones que se hallen vigentes en el momento en que acceda y haga uso de los servicios.
            <br>
            <br>
            <h4>DOMICILIO</h4>
            <br>
            Se fija como domicilio de Almamedis: Huérfanos #865, of. 1603, comuna y ciudad de Santiago, República de
            Chile.
            Si Usted tiene alguna duda sobre los Términos y Condiciones que rigen el Sitio o sobre los Servicios
            ofrecidos, deberá comunicarse con nuestro equipo de Atención al Cliente (contacto@almamedis.cl) para
            resolver dicha duda.
            <br>
            <br>
            <h4>NOTIFICACIONES</h4>
            <br>
            Almamedis utilizará diferentes medios de comunicación para notificar el resultado de ciertas acciones,
            procesos o procedimientos realizados por usted o de manera automática a nuestros sistemas. Dentro de estos
            canales de comunicación se encuentran los canales telefónicos, correo electrónico, mensajes de texto,
            mensajes instantáneos por la aplicación de mensajería WhatsApp, notificaciones de escritorio en los
            navegadores y notificaciones push provenientes de nuestra aplicación. Por la aceptación de los Términos y
            Condiciones, usted acepta recibir estas notificaciones en la línea telefónica, aplicaciones y cuentas que
            registre como propias en nuestra aplicación.
            <br>
            <br>
            <h4>ACEPTACIÓN Y AUTORIZACIÓN</h4>
            <br>

            Usted manifiesta que ha leído con detenimiento este documento, se obliga a cumplir con lo aquí estipulado,
            acepta expresamente todas las disposiciones en él contenidas y cumple con otorgar la autorización
            correspondiente en favor de Almamedis por el uso de esta plataforma y en beneficio de los Profesionales de
            la Salud, para los procedimientos y/o consultas que obtenga a través de la misma.
            El Usuario entiende que la aceptación de los Términos y Condiciones aquí contenidos, es equivalente a un
            contrato escrito y con este protocolo se da cumplimiento expreso a la legislación nacional vigente.
            <br>
            <br>
            <br>
            <br>
          </div>

        </div>
      </div>
    </div>
    <div class="footer">
      <button type="button" class="btn btn-aplicar" data-dismiss="modal" style="padding: 0 !important">Cerrar</button>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body modal-scrollable">
        <!-- Agrega la clase "modal-scrollable" al elemento "modal-body" -->
        <div class="modal-content-scroll">

          <h5 id="exampleModalLongTitle" style="font-size: 20px;">Consentimiento informado</h5>
          <br>
          <div class="terminos-condiciones">

            <h4>Teleconsulta</h4>
            <p>
              Una teleconsulta en AlmaMedis, consiste en una atención realizada por un profesional de la salud, a través
              de
              una videollamada (video y audio), en la cual participan el profesional, un paciente y un acompañante, en
              caso
              de ser necesario. Esta atención se realiza utilizando nuestra plataforma digital de telemedicina. La
              plataforma permite a los participantes interactuar sin la necesidad de estar ubicados en el mismo lugar
              geográfico, dando acceso a los usuarios a una atención de salud en el hogar, el trabajo o en cualquier
              otro
              lugar.
            </p>
            <h4>¿En qué casos debes preferir una atención por telemedicina?</h4>
            <p>
              Nuestro servicio de telemedicina le permite a los pacientes recibir una atención de salud a distancia, de
              manera segura, con algún miembro de nuestro equipo. La telemedicina presenta limitaciones que impiden
              resolver
              de manera telemática situaciones médicas que constituyen urgencias médicas, entre otras: problemas agudos
              al
              respirar, fracturas, heridas, quemaduras, dolores agudos en el tórax o abdomen, sincope o desmayos.
              <br>
              Adicionalmente, también deberás preferir la atención presencial por sobre la telemática cuando se requiera
              realizar un examen de manera física o una evaluación diagnóstica como una ecografía o una tomografía
              computad,; por lo que en caso de tener una emergencia o urgencia médica que requiera atención inmediata,
              debe
              asistir de manera presencial a un centro de salud.
            </p>
            <h4>Consentimiento</h4>

            Con la aceptación de este documento usted entiende que:
            <br>
            <ul>
              <li> Su información personal será compartida única y exclusivamente con los profesionales de la salud con
                los
                cuales se atenderá, lo cual ocurrirá sólo a través de la plataforma digital de telemedicina AlmaMedis.
              </li>
              <br>
              <li> Su información se encuentra resguardada con los más altos estándares de seguridad, utilizando las
                herramientas más avanzadas para asegurar la confidencialidad de la información, como se establece en las
                mejores empresas de Telemedicina.</li>
              <br>
              <li> La consulta de telemedicina no reemplaza una consulta presencial en cuanto a que existen ciertas
                limitantes que impiden la realización de un diagnóstico más certero como la imposibilidad de realizar un
                examen físico completo.</li>
              <br>
              <li> Para la correcta realización de la teleconsulta usted deberá encontrarse en lugar seguro, privado y
                que
                cuente con las condiciones tecnológicas necesarias para el correcto funcionamiento de la videollamada,
                es
                decir, un dispositivo y conexión a internet estable. </li>
              <br>
              <li> No contar con las condiciones tecnológicas mínimas, puede determinar problemas o fallas de conexión
                durante la videollamada, los cuales son y serán ajenos a AlmaMedis y al profesional que lo está
                atendiendo.
              </li>
              <br>
              <li> Los diagnósticos, órdenes, certificados y recetas son generados, a partir de la información que usted
                proporciona a través de la plataforma y/o durante la videollamada. En caso de que la información
                proporcionada sea incompleta o errónea, los documentos e indicaciones generados podrían contener
                errores.
              </li>
              <br>
              <li> Los diagnósticos e indicaciones que reciba son de exclusiva responsabilidad de quien las emita. En
                ningún
                caso será responsabilidad de Almamedis un posible error en su diagnóstico y enfrentamiento terapéutico.
              </li>
              <br>
              <li> Por tratarse de una consulta a distancia (telemedicina), el diagnóstico puede variar drásticamente
                luego
                de realizarse un examen físico completo. </li>
              <br>
              <li> En caso de ser necesario y según las indicaciones del médico, usted deberá consultar con otro
                especialista para obtener un diagnóstico más preciso, continuar con un tratamiento más avanzando o de
                manera
                presencial.</li>
              <br>
              <li> La teleconsulta AlmaMedis contempla una mesa de ayuda telefónica o contact center, a través del cual
                se
                entrega soporte y apoyo a los participantes y usuarios de la plataforma digital.</li>
              <br>
              <li> Para una correcta comunicación con su médico o tratante, usted debe conectarse a su videoconsulta en
                un
                lugar tranquilo, cerrado, sin ruidos externos y con buena conexión de internet. De no contar con estas
                condiciones básicas, la consulta podría ser cancelada y requerir reagendar, a costo del paciente. </li>
              <br>
              <li> Si la consulta es cancelada con menos de una hora de anticipación, en caso de haber sido reservada
                utilizando un plan o convenio, esta será descontada del total de consultas disponibles para dicho plan o
                convenio. </li>

            </ul>
            <p>Al iniciar la videollamada, el médico le consultará si está de acuerdo con los términos y condiciones
              mencionadas en este documento. Usted puede, en todo momento, revocar su consentimiento y optar por una
              atención presencial. Lo anterior lo deberá declarar al profesional que lo está atendiendo, quién dará por
              finalizada la consulta.</p>

            <p>Si usted está de acuerdo con los términos mencionados en este documento, a continuación procede a firmar
              y
              hacer entrega de este consentimiento vía digital al profesional. </p>



            <div class="row credenciales" style="justify-content: space-around;">
              <div class="col-md-6 col-sm-12 firmas">
                <div class="nombre-paciente">
                  <div class="contenedor-nombre" style="margin-bottom: 30%; font-size: 15px;">
                    <span>{{beneficiarySelected.use_name}} </span>
                    <span>{{beneficiarySelected.use_paternal_surname}}
                      {{beneficiarySelected.use_maternal_surname}}</span>
                  </div>
                </div>
                <div>
                  <span class="footer-firma">Nombre paciente</span>
                  <hr style="margin-top: 20px; margin-bottom: 4px;">
                </div>
              </div>
              <div class="col-md-6 col-sm-12 firmas">
                <div style="    display: flex;
                    flex-direction: column;
                    align-items: center;">
                  <img src="assets/images/Firma TG Negra.png" style="width: 60%;">
                  <span class="footer-firma">Tomás Godoy Segovia</span>
                  <span class="footer-firma2">Gerente General AlmaMedis</span>
                </div>
              </div>
            </div>

            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-consentimiento">
      <div class="row" style="display: felx; flex-wrap: nowrap">
        <div class="col-md-6 sm-6">
          <button type="button" class="btn btn-secondary botonCancelar colorLetraVolver" data-dismiss="modal"
            data-toggle="modal" data-target="#showEspecialistaModal">
            Volver atras
          </button>
        </div>
        <div class="col-md-6 letrabotonagregar">
          <button *ngIf="!data_pending_appo.id_consulta" type="button" (click)="redirectToStep5()"
            class="btn btn-primary botonVolver" data-dismiss="modal" data-toggle="modal">
            Aceptar
          </button>
          <button *ngIf="data_pending_appo.id_consulta" type="button" (click)="redirectToPayment()"
            class="btn btn-primary botonVolver" data-dismiss="modal" data-toggle="modal">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<button #ErrorModal type="button" class="btn d-none" data-toggle="modal" data-target="#errorAppointment">
  aux trigger for recovery success modal
</button>

<button #acceptTerminos type="button" class="btn d-none" data-toggle="modal" data-target="#exampleModalLong">
  aux trigger for validate Terms&Conditions
</button>

<!-- <ng-container *ngIf="paymentInfo.transaction && paymentInfo.transaction.url && paymentInfo.transaction.token"> -->
<ng-container>
  <app-payment-button [payment_button]='paymentInfo.transaction'></app-payment-button>
</ng-container>