import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-next-pending-appointment-dashboard',
  templateUrl: './next-pending-appointment-dashboard.component.html',
  styleUrls: ['../../../../../assets/css/styles.css', './next-pending-appointment-dashboard.component.css']
})
export class NextPendingAppointmentDashboardComponent implements OnInit {

  @Input() data: any = {};
  usuario: any;

  constructor(
    private scheduleService: ScheduleService,
    private generalService: GeneralService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
  }




  cancelPending(data) {
    // this.data = data
  }

  confirmcancelPending() {
    this.generalService.modalCargando();
    this.scheduleService.cancelAppointment(this.data.id_consulta, this.usuario._id, null, null, null)
      .then(data => {
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Reserva pendiente cancelada exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
          showConfirmButton: false,
        })
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(error => console.log(error))
  }



  finishPending() {
    var url = "reserva-pendiente/" + this.data.id_consulta;
    this.router.navigate([url]);
  }


}
