import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meetinglist',
  templateUrl: './meetinglist.component.html',
  styleUrls: ['./meetinglist.component.css']
})
export class MeetinglistComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }

}
