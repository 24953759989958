import { Component, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { CompleterService, CompleterData } from 'ng2-completer';
import { ScheduleService } from 'src/app/services/schedule.service';

import Swal from 'sweetalert2';

import { AgendaMedico2Component } from 'src/app/components/agenda-medico2/agenda-medico2.component';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-reserva-hora-cc',
  templateUrl: './reserva-hora-cc.component.html',
  styleUrls: ['./reserva-hora-cc.component.css']
})
export class ReservaHoraCcComponent implements OnInit {

  _title = 'Agenda Paciente';
  _subtitle = 'Reserva de hora'

  _usuario: any = {};  // usuario actual
  hoursDay: any = {};              // Array de bloques de horas 
  daySelected: any = {};    //dia seleccionado
  @ViewChild(AgendaMedico2Component) AGENDA_MEDICO: AgendaMedico2Component; //para llamar las funciones contenidas en ese componente
  data_appo: any = {};
  appointment_reason = '';
  beneficiary_list;
  beneficiary_selected;
  beneficiary_id;
  acompanante_list;
  acompanante_selected;
  acompanante_id;
  sub_speciality_id;
  id_consulta_reservada: string;


  doctorList: any = [];
  specialityList: any = [];
  availabilityDoctorHourList: any = [];

  showOptionMedical: boolean = true;
  showSuccessMessage: boolean;

  strDoctorName: string;
  subtitle: string;
  completerData: CompleterData;

  doctorSelected: any = {};
  hourSelected: any = {};

  steps: any = {
    searchDoctor: false,
    selectDate: false,
    completeForm: false,
    showSummary: false
  }




  titular_list: any = [];
  titular_selected: any = [];
  rut_titular: string;
  titulares: CompleterData;
  searchStrMain: string;
  tit_cargado: boolean = false;

  constructor(
    public generalService: GeneralService,
    private completerService: CompleterService,
    public scheduleService: ScheduleService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.tit_cargado = false;

    this._usuario = new EncriptacionService().getUserInfo();

    if (this._usuario.typ_id != 7) {
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
    else {
      this.getTitulares();
    }
    this.showSuccessMessage = false;
    this.steps.searchDoctor = true;
  }




  getTitulares() {
    this.generalService.postListarBeneficiarioMain()
      .then(results => {
        this.titular_list = results;
        // console.log(results, 'lista de usuarios');

        for (let tit of this.titular_list) {
          tit.nombre_completo = tit.use_name + ' ' + tit.use_paternal_surname + ' ' + tit.use_maternal_surname + '    |    ' + tit.use_username
        }
        this.titulares = this.completerService.local(this.titular_list, 'nombre_completo', 'nombre_completo')
      })
      .catch(error => {
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar los beneficiarios.',
          timer: 2000
        })
      })
  }

  changeTitular($event) {
    if ($event) {
      this.titular_selected = $event.originalObject;
      //console.log(this.titular_selected);

    }
  }

  rutTitular() {

    if (!this.titular_selected.use_username || !this.titular_selected) {
      this.generalService.modalError('Debe ingresar un rut valido.', '');

      this.tit_cargado = false;
      this.beneficiary_selected = {}
    }else{

      // console.log(this.titular_selected.use_username);
      // console.log(this.titular_selected);
      // console.log(this.tit_cargado);

      this.rut_titular = this.titular_selected.use_username;
      this.beneficiary_selected = this.titular_selected;
      this.tit_cargado = true;
    }
  }

  change(){
    this.tit_cargado = false;
    this.beneficiary_selected = {};
    this.titular_selected = {};

  }


}