import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class FichaMedicaService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }




  async getListarOrderType() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarOrderType', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async getListarExamType() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarExamType', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async getListarClinicas() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarClinica', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async getListarPrescriptionType() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarPrescriptionType', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async getListarConDiagnostic() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConDiagnostic', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async getListarConCausal() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarCausalType', JSON.stringify({}), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async postCreateOrder(params, accion) {
    //console.log("----------------------------------------");


    //console.log(params);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + accion, params, httpOptions).toPromise()

      return data
    }
    catch (error) {
      Swal.close();
      console.log(error);


      //this._errors.requestError(error)
      throw 1;
    }
  }



  async getListarConsultasPasadas(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = data;
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsultasPasadas', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }


  async getListarConsultasNoFinalizadas(use_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      use_id: use_id
    };
    //console.log(params);

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsultasNoFinalizadas', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw 1;
    }
  }



  async getListarFichaConsulta(item) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      tel_med_con_id: item.med_con_id,
      use_rut: item.rut_paciente,
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarFichaConsulta', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }


  async postListarHistorial(parametro, accion) {
    //console.log("----------------------------------------");

    let params = { parametro: parametro }
    //console.log(params);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + accion, JSON.stringify(params), httpOptions).toPromise()
      Swal.close();


      return data
    }
    catch (error) {
      Swal.close();
      this._errors.requestError(error)
      throw 1;
    }
  }

  async getListarConsultas(id_doc = null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = {
      id_doc: id_doc
    }
    try {
      let data = this.http.post(URL_SERVICIOS + '/getListarConsultas', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }

  async getListarConsultas2(id_doc, mes) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = {
      id_doc: id_doc,
      mes: mes
    }
    try {
      let data = this.http.post(URL_SERVICIOS + '/getListarConsultas', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }
  async getListarEstadoConsultasDia(estado_consulta, f_desde, f_hasta, area_medica, planes) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    let params = {
      estado_consulta: estado_consulta,
      f_desde: f_desde,
      f_hasta: f_hasta,
      area_medica: area_medica,
      cus_ben_id: planes
    };

    // //console.log(params);


    try {
      let data = this.http.post(URL_SERVICIOS + '/getListarEstadoConsultasDia', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }

  async getListarConsultasConfirmadas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/getListarConsultasConfirmadas', '', httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }

  async postDeletePrescription(parametro) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = { pre_id: parametro }
    //console.log(params);
    try {
      let data = this.http.post(URL_SERVICIOS + '/postDeletePrescription', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }

  async postDeleteOrder(parametro) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = { med_ord_id: parametro }
    //console.log(params);
    try {
      let data = this.http.post(URL_SERVICIOS + '/postDeleteOrder', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }

  async postDeleteDerivation(parametro) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = { der_id: parametro }
    //console.log(params);
    try {
      let data = this.http.post(URL_SERVICIOS + '/postDeleteDerivation', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }

  async postDeleteDiagnostic(parametro) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = { dia_det_id: parametro }
    //console.log(params);
    try {
      let data = this.http.post(URL_SERVICIOS + '/postDeleteDiagnostic', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }

  async postVerOrder(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    try {
      let data = this.http.post(URL_SERVICIOS + '/postVerOrder', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }


  async postFirmaDocumento(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    try {
      let data = this.http.post(URL_SERVICIOS + '/postFirmaDocumento', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }





}
