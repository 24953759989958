<div class="row">
    <div class="card-consul" >


        <table>
            <tr>
                <td >             
                    <div class="img-cirlce-wrapper" style="margin: 20px;">
                        <img *ngIf="consulta.att_path" class="img-fluid" [src]="URL_DOCUMENTS+consulta.att_path" alt="profile-img" title="profile-img">
                        <p  *ngIf="!consulta.att_path" class="m-0 p-0">
                            {{ doctorName }}{{ doctorLastName }}
                        </p>
                    </div>
                <td>
                    <p class="texto-titulo-card">Dr. {{consulta.nombre_doctor}} {{consulta.apellido_paterno_doctor}} {{consulta.apellido_materno_doctor}}</p>
                    <p class="texto-subtitulo-uno">{{consulta.spe_name}}</p>
                    <p class="texto-subtitulo-dos">Fecha Consulta: {{consulta.fecha_consulta | date:'dd-MM-yyyy'}} a las {{consulta.hora}}.</p>
                </td>

            </tr>
        </table>


    </div>
</div>