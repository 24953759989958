
import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, Input, EventEmitter, Output} from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { 
  CalendarEvent, 
  CalendarMonthViewDay,
  CalendarView, 
  CalendarDateFormatter,
  DAYS_OF_WEEK } from 'angular-calendar';
import { CustomDateFormatter } from '../calendar/custom-date-formatter.provider';

import { ScheduleService } from 'src/app/services/schedule.service';
import { GeneralService } from 'src/app/services/general.service';

import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-calendar-doc',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-doc.component.html',
  styleUrls: ['./calendar-doc.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})


export class CalendarDocComponent {

  constructor(
    public _schedule: ScheduleService,
    public _general: GeneralService,
  ) { }

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Output() messageEvent = new EventEmitter<string>();

  _usuario: any = {};

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SUNDAY] //[DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  clickedDate: Date;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  activeDayIsOpen: boolean = false; //def: true

  id_doctor: number;                  //id del doctor seleccionado

  selectedDay: CalendarMonthViewDay;   //dia-calendio seleccionado
  // selectedDays: any = [];            //array de dias seleccionados
  day_selected_doctor: any = {};      // info del dia del calendario clickeado {check: true, name:'martes 10 blabla', date_str: '20201210'}
  to_day: any = {};                   // info del dia {name, date_str, month: 'yyyymm'}
  month_calendar: string;             // 'yyyymm'
  
  hours_list_doc: any;                // horas disponibles del medico totales (todos los dias)
  hours_day: any;                     // horas disponibles del medico del día cliqueado
  hour_blocks: any = {};              // Array de bloques de horas 
  hour_blocks_pot: any = {};          // bloques de horas que se pueden agregar al medico

  item_disp = { ver: true, edit: false, add: false }   //item consultado
  options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }; //opciones labels fecha
  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();
    this.getHorasBloques();

    

    let hoy = new Date();
    this.to_day.date = hoy;
    this.to_day.name = hoy.toLocaleString('es-US', this.options);
    this.to_day.date_str = (hoy.getFullYear()*10000 + (hoy.getMonth() +1)*100 + hoy.getDate()).toString();
    this.to_day.month = (hoy.getFullYear()*100 + (hoy.getMonth() +1)).toString();
    this.month_calendar = this.to_day.month;    
  }

  date2number(date){
    return (date.getFullYear()*10000 + (date.getMonth() +1)*100 + date.getDate())
  }

  //bloques de horario
  getHorasBloques(){ 
    this._schedule.getScheduleTimes()
    .then( (data: any) => {
      this.hour_blocks = data;
    })
  }

  // funcion que arma el calendario segun el tipo
  agendaMedico(doctor_id, item){
    this.day_selected_doctor.check = false;
    this.id_doctor = doctor_id;
    this.getHorasMes(this.id_doctor, this.month_calendar);
    switch (item) {
      case 'edit':
        this.item_disp = { ver: false, edit: true,  add: false }
        break;
      case 'add':
        this.item_disp = { ver: false, edit: false,  add: true }
        break;
      default:
        this.item_disp = { ver: true, edit: false,  add: false }
        break;
    }
  }

  // consulta las horas del doctor del mes
  getHorasMes(doctor_id, mes){
    let eve = []
    this._schedule.getScheduleHoursDoc(doctor_id, mes,null)
      .then( (data: any) => {
        this.hours_list_doc = data;
        //console.log(data);
        
        for (let dia_sch of this.hours_list_doc){
          eve.push({
            start: new Date(dia_sch.sch_date), 
            title: dia_sch.sch_id,
          });
          for (let hour of dia_sch.hours){
            if (dia_sch.sta_id == 8 || hour.sta_id == 22){
              hour.estado_hora = 'BLOQUEADA';
            }
            else{
              if (hour.sta_id == 24) {
                hour.estado_hora = 'CONSULTA';
              } else {}
              if (hour.sta_id == 21) {
                hour.estado_hora = 'DISPONIBLE';
              } else {}
            }
          }
        }
        this.events = eve;
        this.refresh.next();
    })
  }

  // ver agenda
  data_reserva: any = {};
  consulta_cargada = false;
  async verReserva(id_hour_sch, hora){    
    let data: any = await this._schedule.getAppointment(null, this.id_doctor, null, id_hour_sch);
    let reserva_sel = data[0];
    // //console.log(data[0]);
    //console.log(reserva_sel);
    
        
    this.data_reserva.num_reserva = reserva_sel.tel_doc_app_boo_id,
    this.data_reserva.name_doc = reserva_sel.doc_use_name + ' ' + reserva_sel.doc_use_paternal_surname + ' ' + reserva_sel.doc_use_maternal_surname;
    this.data_reserva.name_area = reserva_sel.spe_name + ' - ' + reserva_sel.sub_spe_name;
    this.data_reserva.name_day = (new Date(reserva_sel.sch_date)).toLocaleString('es-US', this.options) 
    this.data_reserva.name_ben = reserva_sel.ben_use_name + ' ' + reserva_sel.ben_use_paternal_surname + ' ' + reserva_sel.ben_use_maternal_surname;
    this.data_reserva.name_hour = reserva_sel.sch_hou_from + ' '+ reserva_sel.sch_hou_to;
    this.data_reserva.name_reason = reserva_sel.tel_doc_app_boo_reason;
    this.data_reserva.name_symptoms = reserva_sel.tel_doc_app_boo_symptoms; 
    this.data_reserva.cancelable = hora.cancelable;
    this.data_reserva.use_email = reserva_sel.ben_use_email;
    this.data_reserva.use_phone = reserva_sel.ben_use_telefono;
    this.data_reserva.doc_email = reserva_sel.doc_use_email;
    this.data_reserva.doc_phone = reserva_sel.doc_use_telefono;
    this.data_reserva.id_consulta = reserva_sel.tel_doc_app_boo_id;
    this.data_reserva.ben_use_birthday = reserva_sel.ben_use_birthday;
    this.data_reserva.ben_use_rut = reserva_sel.ben_use_rut;
    let btn = document.getElementById('boton1'); //para que el modal se vea con data al inicio
    btn.click();
    this.refresh.next();
  }

  confirmarCancelAppo(boo_id, cancel_reason){
    this._schedule.cancelAppointment(boo_id, this._usuario._id, cancel_reason, this.data_reserva,null) 
      .then(data => {
        Swal.fire({
          icon: 'success',
          title: 'Hora cancelada con Éxito',
          text: '',
          timer: 2000
        })
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(error => console.log(error))
  }


  // Click sobre el dia. Muestra los eventos del dia
  dayEnabledClicked(day: CalendarMonthViewDay): void {
    // //console.log(day)
    let date = day.date;
    let events = day.events;
    let date_str = date.getFullYear()*10000 + (date.getMonth() +1)*100 + date.getDate();
  
    if ( this.selectedDay ){
      delete this.selectedDay.cssClass;
    }
    day.cssClass = 'amc-cal-day-selected'

    this.day_selected_doctor.name = date.toLocaleString('es-US', this.options);
    this.day_selected_doctor.check = true;
    this.day_selected_doctor.date_str = (date.getFullYear()*10000 + (date.getMonth() +1)*100 + date.getDate()).toString();
    this.day_selected_doctor.disp = true; //disponibilidad de bloquera dia completo
    this.day_selected_doctor.status = true; //status dia completo

    let hours_disp = [];          // Horas disponibles del medico ese dia
    if ( events.length === 0 ) { // sin horas ese día || (this.date2number(day.date) < this.date2number(this.to_day.date))
    } 
    else { //hay eventos
      for ( let hours_day of this.hours_list_doc) {                      
        if ( hours_day.sch_id == events[0].title ) { //dia seleccionado del listado de dias
          this.day_selected_doctor.sch_id = hours_day.sch_id; // id del dia
          
          hours_disp = hours_day.hours
          for ( let hou of hours_disp ){
            hou.status = (hou.sta_id == 22)? false: true;       //22 = no activa
            hou.new_status = (hou.sta_id == 22)? false: true;
            hou.disp = (hou.sta_id == 23 || hou.sta_id == 24)? false: true;     //si está reservada o bloqueda (para reservar) no se puede modificar

            // //console.log(this.date2number(day.date))
            // //console.log(this.to_day)
            hou.disp = (this.date2number(day.date) >= this.date2number(this.to_day.date))? hou.disp : false;  //si es una fecha previa no está disponible para modificar
            hou.cancelable = (this.date2number(day.date) >= this.date2number(this.to_day.date))? true : false; 
            
            let restr = new Date();
            restr.setDate(restr.getDate() + 2);
            hou.disp = (this.date2number(day.date) >= this.date2number(restr) && this._usuario.typ_id == 1 )? false : hou.disp; //mayor a 2 dias no esta disponible para el medico
            
            this.day_selected_doctor.disp = (hou.disp)? this.day_selected_doctor.disp : false;  //disponibilidad de bloquera dia completo
          }
        }
      }        
    }
    
    this.hours_day = hours_disp;                    //////////////////////////////////////////////// HORAS DEL DIA SELECCIONADO

    let hours_pot = [];           // Horas posibles para ser agregadas

    if ( date_str >= parseInt(this.to_day.date_str) ){
      for ( let blo of this.hour_blocks ){
        let xx = true;
        for ( let hour of this.hours_day ){
          if (  hour.from == blo.from ){
            xx = false;
            break;
          }
        }
        if (xx){
          hours_pot.push({
            id: blo.id,
            from: blo.from,
            to: blo.to,
            status: false,
          })
        }
      }
    } else {}
    this.hour_blocks_pot = hours_pot;

    this.selectedDay = day;

  }


  diaCompleto(sch_id){
    let hours_disp = []; 
    for ( let hours_day of this.hours_list_doc) {                      
      if ( hours_day.sch_id == sch_id ) { //dia seleccionado del listado de dias
        hours_disp = hours_day.hours
        for ( let hou of hours_disp ){
          hou.new_status = this.day_selected_doctor.status? true: false;
        }
      }
    }
    this.hours_day = hours_disp; 
  }

  





  //////////////////////////////////////////////////////////////////////////// edit horas /////////////////////////////////////////////////////////////////////
  hours_selected_edit: any = [];

  // Boton editar horas
  editar_horas(){
    let hours = [];
    for ( let hou of this.hours_day){
      if ( hou.status != hou.new_status ) { // se cambio el stado de la hora
        hours.push({ id: hou.id, activa: hou.new_status })
      }
    }
    this.hours_selected_edit = hours;
  }

  // Modal confirmar
  confirmarEditHours(){
    this.editHoras( this.id_doctor, this.hours_selected_edit)
  }

  // Funcion agrega horas
  editHoras(id_doctor, hours){
    this._schedule.editScheduleHours(id_doctor, hours) 
      .then( (data: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Horas editadas',
          // text: 'Horas agregadas: '+data.hours_add ,
          timer: 3000
        })
        this.day_selected_doctor.check = false;  //desseleccionar dia
        this.getHorasMes(this.id_doctor, this.month_calendar); //actualizar las info en pantalla
    })
  }




  //////////////////////////////////////////////////////////////////////////// Add horas /////////////////////////////////////////////////////////////////////
  hours_selected_add: any = [];

  // Boton Agregar horas
  agregar_horas(){
    let hours = [];
    for ( let hou of this.hour_blocks_pot ){
      if ( hou.status ) {
        hours.push({ from: hou.from, to: hou.to })
      }
    }
    this.hours_selected_add = hours;
  }

  // Modal confirmar
  confirmarAddHours(){
    this.addHoras( this.id_doctor, this.day_selected_doctor.date_str, this.hours_selected_add)
  }

  // Funcion agrega horas
  addHoras(id_doctor, day, hours){
    this._schedule.addScheduleHours(id_doctor, day, hours) 
      .then( (data: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Horas agregadas',
          text: 'Horas agregadas: '+data.hours_add ,
          timer: 3000
        })
        this.day_selected_doctor.check = false;  //desseleccionar dia
        this.getHorasMes(this.id_doctor, this.month_calendar); //actualizar las info en pantalla
    })
  }



  //
  nextMes(){
    let fecha = new Date(this.month_calendar.substr(0,4)+'-'+this.month_calendar.substr(4)+'-10'); 
    fecha.setMonth(fecha.getMonth() + 1);
    this.month_calendar = (fecha.getFullYear()*100 + (fecha.getMonth() +1)).toString();
    this.getHorasMes(this.id_doctor, this.month_calendar);
  }

  prevMes(){
    let fecha = new Date(this.month_calendar.substr(0,4)+'-'+this.month_calendar.substr(4)+'-10'); 
    fecha.setMonth(fecha.getMonth() - 1);
    this.month_calendar = (fecha.getFullYear()*100 + (fecha.getMonth() +1)).toString();
    this.getHorasMes(this.id_doctor, this.month_calendar);
  }




 



}



