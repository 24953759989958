
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from "@angular/router";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { Ng2CompleterModule } from "ng2-completer";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AdminMedicosComponent } from './admin-medicos/admin-medicos.component';
import { AgendaDelMedicoComponent } from './agenda-del-medico/agenda-del-medico.component';
import { AgendaMedicosComponent } from './agenda-medicos/agenda-medicos.component';
import { AntecedentesComponent } from './antecedentes/antecedentes.component';
import { GrupofamiliarComponent } from './beneficiaries/grupofamiliar/grupofamiliar.component';
import { PlanesConveniosComponent } from './beneficiaries/planes-convenios/planes-convenios.component';
import { BuscaHistorialConsultaComponent } from './buscadores/busca-historial-consulta/busca-historial-consulta.component';
import { BuscaInfoComponent } from './buscadores/busca-info/busca-info.component';
import { BuscaUserComponent } from './buscadores/busca-user/busca-user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetalleConsultaComponent } from './detalle-consulta/detalle-consulta.component';
import { DocumentsUserConsultComponent } from './documents-user-consult/documents-user-consult.component';
import { DocumentsUserComponent } from './documents-user/documents-user.component';
import { EncuestaComponent } from './encuesta/encuesta.component';
import { EndurlComponent } from './endurl/endurl.component';
import { ErrorVideoComponent } from './error-video/error-video.component';
import { EstadoConsultaComponent } from './estado-consulta/estado-consulta.component';
import { ActivacionComponent } from './gestion/activacion/activacion.component';
import { DisponibilidadComponent } from './gestion/disponibilidad/disponibilidad.component';
import { EvaluacionesComponent } from './gestion/evaluaciones/evaluaciones.component';
import { GestionIVRComponent } from './gestion/gestion-ivr/gestion-ivr.component';
import { UsoAsistenciaComponent } from './gestion/uso-asistencia/uso-asistencia.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { HistorialConsultasMedicoComponent } from './historial-consultas-medico/historial-consultas-medico.component';
import { HoraPorIdContactComponent } from './hora-por-id-contact/hora-por-id-contact.component';
import { LicenciasMedicasComponent } from './licencias-medicas/licencias-medicas.component';
import { ListadoConsultasComponent } from './listado-consultas/listado-consultas.component';
import { ListarHorasContactComponent } from './listar-horas-contact/listar-horas-contact.component';
import { LobbyAcompComponent } from './lobby-acomp/lobby-acomp.component';
import { LobbyComponent } from './lobby/lobby.component';
import { AdminDatabaseLoadingComponent } from './mantenedor/admin-database-loading/admin-database-loading.component';
import { AdminPlansComponent } from './mantenedor/admin-plans/admin-plans.component';
import { AdminUsersComponent } from './mantenedor/admin-users/admin-users.component';
import { MeetinglistComponent } from './meetinglist/meetinglist.component';
import { MesaAyudaComponent } from './mesa-ayuda/mesa-ayuda.component';
import { ReservaHoraCcComponent } from './mi-agenda-cc/reserva-hora-cc/reserva-hora-cc.component';
import { ProximasConsultasMedicoComponent } from './mi-agenda-medico/proximas-consultas-medico/proximas-consultas-medico.component';
import { ConsultasPendientesPagoComponent } from './mi-agenda/consultas-pendientes-pago/consultas-pendientes-pago.component';
import { HistorialConsultasPacienteComponent } from './mi-agenda/historial-consultas-paciente/historial-consultas-paciente.component';
import { ProximasConsultasPacienteComponent } from './mi-agenda/proximas-consultas-paciente/proximas-consultas-paciente.component';
import { ConfirmDialogVideoComponent } from './mi-agenda/reserva-hora/confirm-dialog-video/confirm-dialog-video.component';
import { ConfirmDialogComponent } from './mi-agenda/reserva-hora/confirm-dialog/confirm-dialog.component';
import { ReservaHoraComponent } from './mi-agenda/reserva-hora/reserva-hora.component';
import { PagesComponent } from './pages.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { DisponibilidadHorarioComponent } from './perfil-medico/disponibilidad-horario/disponibilidad-horario.component';
import { PerfilComponent } from './perfil/perfil.component';
import { ProgressComponent } from './progress/progress.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { SalaChatComponent } from './sala-chat/sala-chat.component';
import { SalaesperaComponent } from './salaespera/salaespera.component';
import { VideoconsultaAcompananteComponent } from './videoconsulta-acompanante/videoconsulta-acompanante.component';
import { VideoconsultaMedicoFinComponent } from './videoconsulta-medico-fin/videoconsulta-medico-fin.component';
import { VideoconsultaMedicoComponent } from './videoconsulta-medico/videoconsulta-medico.component';
import { VideoconsultaPacienteComponent } from './videoconsulta-paciente/videoconsulta-paciente.component';
import { OtrasPrestacionesComponent } from './otras-prestaciones/otras-prestaciones.component';
import { OtraPrestacionComponent } from './otra-prestacion/otra-prestacion.component';
import { BeneficioComponent } from './beneficio/beneficio.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [    
    AccountSettingsComponent,
    AdminMedicosComponent,
    AdminPlansComponent,
    AgendaDelMedicoComponent,
    AgendaMedicosComponent,
    AntecedentesComponent,
    BuscaInfoComponent,
    DashboardComponent,
    DetalleConsultaComponent,
    DisponibilidadHorarioComponent,
    DocumentsUserComponent,
    DocumentsUserConsultComponent,
    EncuestaComponent,
    EndurlComponent,
    ErrorVideoComponent,
    EstadoConsultaComponent,
    Grafica1Component,
    GrupofamiliarComponent,
    HistorialConsultasMedicoComponent,
    HistorialConsultasPacienteComponent,
    HoraPorIdContactComponent,
    LicenciasMedicasComponent,
    ListadoConsultasComponent,
    ListarHorasContactComponent,
    LobbyAcompComponent,
    LobbyComponent,
    MeetinglistComponent,
    MesaAyudaComponent,
    PagesComponent,
    PerfilComponent,
    PlanesConveniosComponent,
    ProgressComponent,
    PromesasComponent,
    ProximasConsultasMedicoComponent,
    ProximasConsultasPacienteComponent,
    ReservaHoraCcComponent,
    ReservaHoraComponent,
    RxjsComponent,
    SalaChatComponent,
    SalaesperaComponent,
    VideoconsultaAcompananteComponent,
    VideoconsultaMedicoComponent,
    VideoconsultaMedicoFinComponent,
    VideoconsultaPacienteComponent,
    PaymentInfoComponent,
    ConsultasPendientesPagoComponent,
    AdminUsersComponent,
    AdminDatabaseLoadingComponent,
    ConfirmDialogComponent,
    ConfirmDialogVideoComponent,
    BuscaUserComponent,
    BuscaHistorialConsultaComponent,
    UsoAsistenciaComponent,
    GestionIVRComponent,
    ActivacionComponent,
    EvaluacionesComponent,
    DisponibilidadComponent,
    OtrasPrestacionesComponent,
    OtraPrestacionComponent,
    BeneficioComponent,
  ],
  exports: [
    AccountSettingsComponent,
    ChartsModule,
    DashboardComponent,
    Ng2CompleterModule,
    Ng2SearchPipeModule,
    PagesComponent,
    ProgressComponent,
  ],
  imports: [
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ChartsModule,
    CommonModule,
    ComponentsModule,
    FlatpickrModule.forRoot(),
    FormsModule,
    Ng2CompleterModule,
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule,
    PipesModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule
  ]
})
export class PagesModule { }
