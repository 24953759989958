import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-salaespera',
  templateUrl: './salaespera.component.html',
  styleUrls: ['./salaespera.component.css']
})
export class SalaesperaComponent implements OnInit {
  public parametros_url: any;
  consultas_list: any = []; 
  consultas: any = []; 
  
  constructor(
    public _usuarioService: UsuarioService,
    private http: HttpClient, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _medicosServices: MedicosService,
    private _fichaMServices: FichaMedicaService
  ) {


   }

  usuario: any; // Usuario logeado

  ngOnInit(): void {
    this.getConsultas();

    this.usuario = new EncriptacionService().getUserInfo()
  }



  getConsultas() {

    this._fichaMServices.getListarConsultas()
      .then((data: any[]) => {
        this.consultas_list = data;     
        //console.log(this.consultas_list);
        
        var largo = this.consultas_list.consultas.length;
        for (var i = 0 ; i < largo; i++){
          var fecha_sistema = new Date();
          const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');
          if (this.consultas_list.consultas[i].fecha_consulta.substring(0, 10) != fecha.toString()) {
            //this.consultas.push(this.consultas_list.consultas[i]);
            //console.log("Consulta para otro día");
            //console.log(this.consultas_list.consultas[i]);
          } else {
            this.consultas_list.consultas[i].fecha_consulta = new Date(this.consultas_list.consultas[i].fecha_consulta.substr(0, 10).replaceAll('-', '/'));
            //console.log("Consulta para este día");
            //console.log(this.consultas_list.consultas[i]);
            this.consultas.push(this.consultas_list.consultas[i]);
          }
        }
       })
      .catch(error => { 

      })


  }

  unirseReunionPaciente(item)
  {
    //console.log(item);
    // var resta_minutos = this.obtenerResta(item.hora);
    // var fecha_sistema = new Date();
    // //console.log("Fecha actual: " + fecha_sistema);

    // const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');

    // //console.log(fecha);

    // //console.log(item.fecha_consulta.substring(0,10));

    // if (item.fecha_consulta.substring(0,10)!=fecha.toString()) { 

    //   Swal.fire({
    //     icon: 'info',
    //     title: 'Consulta en más días.',
    //     text: 'Aún faltan días para acceder a la consulta...',
    //     timer: 5000
    //   })
    // }else if (resta_minutos > 60) {

    //   Swal.fire({
    //     icon: 'info',
    //     title: 'Fuera de horario.',
    //     text: 'Aún falta para acceder a la consulta...',
    //     timer: 5000
    //   })
      
    // } else if (resta_minutos < -60) {
    //   Swal.fire({
    //     icon: 'info',
    //     title: 'Fuera de horario.',
    //     text: 'El tiempo para acceder a la consulta ya ha terminado...',
    //     timer: 5000
    //   })

    // } else
    // {
      var url = "salaespera/"+item.zoo_mee_room_id+"/"+item.id_consulta+"/"+item.med_con_id;
      this.router.navigate([url]);

    // }


   }

   unirseReunionDoctor(item)
   {
     //console.log(item);

    //  var resta_minutos = this.obtenerResta(item.hora);
    //  var fecha_sistema = new Date();
    //  //console.log("Fecha actual: " + fecha_sistema);
     // //console.log(fecha_sistema.().toString());
     // //console.log(fecha_sistema.getMonth().toString());
     // //console.log(fecha_sistema.getDay().toString());
    //  const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');
 
    //  //console.log(fecha);
 
    //  //console.log(item.fecha_consulta.substring(0,10));
 
    //  if (item.fecha_consulta.substring(0,10)!=fecha.toString()) { 
 
    //    Swal.fire({
    //      icon: 'info',
    //      title: 'Consulta en más días.',
    //      text: 'Aún faltan días para acceder a la consulta...',
    //      timer: 5000
    //    })
    //  }
    //  else if (resta_minutos > 60) {

    //    Swal.fire({
    //      icon: 'info',
    //      title: 'Fuera de horario.',
    //      text: 'Aún falta para acceder a la consulta...',
    //      timer: 5000
    //    })
       
    //  }
    //  else if (resta_minutos < -60) {
    //    Swal.fire({
    //      icon: 'info',
    //      title: 'Fuera de horario.',
    //      text: 'El tiempo para acceder a la consulta ya ha terminado...',
    //      timer: 5000
    //    })

    //  } else
    //  {
      //var url = "zoom/"+item.zoo_mee_room_id+"/"+item.zoo_acc_email+"/"+1+"/"+item.email_doctor;
      var url = "videoconsulta/"+item.id_consulta;
      this.router.navigate([url]);
       
    // }

 
   }
   unirseReunion()
   {
    Swal.fire({
      icon: 'info',
      title: 'Consulta en más días.',
      text: 'Aún faltan días para acceder a la consulta...',
      timer: 5000
    })
 
  }


  ConfirmarAsistencia(item) { 
    this._medicosServices.postUpdateSheduleState(item.id_consulta)
      .then(result => { 
        setTimeout(() => window.location.reload(), 100);
      })
      .catch(error => { 
        console.log(error);
      })
  }
  
  obtenerResta(hora)
  {

    var fecha = new Date(Date.now());
    var minutos = fecha.getHours() * 60 + fecha.getMinutes();
    var minutos_consulta = parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
    var diferencia = minutos_consulta - minutos;
    //console.log("Diferencia "+diferencia);
    return diferencia;
  }
  
  showError(err){
    //console.log(err);
    switch (err.status) {
      case 401: //Autenticación: servidor no recive el token
        Swal.fire({
          icon: 'error',
          title: 'Favor reingresar',
          text: '401 - Error de autenticación',
          timer: 2000
        }) // router.navigate ?
        // setTimeout(() => router.navigate(['login']), 2000);
        //setTimeout(() => this._usuarioService.logout(), 2000);
        break;

      case 403: //Autenticación, token expirado
        Swal.fire({
          icon: 'error',
          title: 'Favor reingresar',
          text: '403 - Código de autenticación expirado',
          timer: 2000
        })
        // setTimeout(() => router.navigate(['login']), 2000);
        setTimeout(() => this._usuarioService.logout(), 2000);
        break;
      // case 404: //Not Found
      //   //console.log(err.message);
      //   break;

      case 500: // Error entre servidor - BD, problemas de query, etc.
        //console.log(err.error);
        Swal.fire({
          icon: 'error',
          title: '500 - Error interno',  
          text: err.error.detail,
        })
        break;

      default:
        Swal.fire({
          icon: 'error',
          title: 'Contactar a TI',  
          text: 'Problemas de conexión (default)',
        })
        break;
    }
  }


}
