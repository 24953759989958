import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { FamilyGroup } from '../interfaces/register-family-group.interface';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class FalilyGroupService {

  constructor(
    private http: HttpClient,
    private router: Router,
    public _errors: ErrorsService,
  ) { }

  traeFamilyGroup(usuario: FamilyGroup) {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem("token")//le paso el token para el backend
      })
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/family_group_list', usuario, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  traeBeneficiariosTotalDisponible(usuario: FamilyGroup) {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem("token")//le paso el token para el backend
      })
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/family_group_number_beneficiaries', usuario, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  traeFamilyGroupGP(usuario: FamilyGroup) {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem("token")//le paso el token para el backend
      })
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/family_group_list_gp', usuario, httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  postCrearBeneficiarios(rut, plan,ben_id) {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem("token")//le paso el token para el backend
      })
    };
    try {
      let params = {
        rut: rut,
        cus_ben_id: plan,
        ben_id: ben_id,
      };
      let data = this.http.post(URL_SERVICIOS + '/postCrearBeneficiarios', params, httpOptions).toPromise()
      return data;
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  postDesactivarBeneficiario(rut, plan, ben_id) {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem("token")//le paso el token para el backend
      })
    };
    let params = {
      rut: rut,
      plan: plan,
      ben_id
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/postDesactivarBeneficiario', params, httpOptions).toPromise();
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  creaAdultoMayor(usuario: FamilyGroup) {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem("token")//le paso el token para el backend
      })
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/family_group_insert_gp', usuario, httpOptions)
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

}