import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
  ) {}


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       Formato de hora        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  options_month: any = { month: 'long'}; //opciones labels mes
  options_week: any = { weekday: 'long'}; //opciones labels dia de la semana

  formatApptDate = (apptDate: Date, time: any) => {
    let now = new Date();
    let returnDate: any = {};
    returnDate.date = apptDate;
    returnDate.date.setHours(time.substr(0, 2));
    returnDate.date.setMinutes(time.substr(3, 2));
    returnDate.day = apptDate.getDate();
    returnDate.year = apptDate.getFullYear();
    returnDate.month = apptDate.toLocaleString('es-US', this.options_month) 
    returnDate.weekday = apptDate.toLocaleString('es-US', this.options_week) 

    returnDate.int = `${apptDate.getFullYear()}${((apptDate.getMonth() +101).toString()).substring(1, 3)}${((apptDate.getDate() + 100).toString()).substring(1, 3)}`;

    let msGap = returnDate.date.getTime() - now.getTime(); 
    let minGap = Math.floor(msGap / 60000);

    returnDate.time = time.substr(0, 5);

    if (returnDate.year === now.getFullYear() && apptDate.getMonth() === now.getMonth() && returnDate.day === now.getDate() ) {
      if (minGap <= 60) {
        returnDate.text = 'En ' +minGap+ ' minutos más';
      } else {
        returnDate.text = 'Hoy ' +returnDate.day +' de ' +returnDate.month+ ' a las ' +returnDate.time+ ' horas';
      }
    }
    else{
      returnDate.text = returnDate.day +' de ' +returnDate.month+ ' del ' +returnDate.year+ ' a las ' +returnDate.time+ ' horas';
    }
    return returnDate
  }
 






}