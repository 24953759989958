import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http'; 

import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';
import { EncriptacionService } from './encriptacion.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    private router: Router,
    public _errors: ErrorsService,
  ) { }
  


  async postSaveDocument(doc:File , url , nombre_doc) {
    
    const fd = new FormData();
    fd.append('use_id', new EncriptacionService().getUserInfo()._id);

    fd.append('file', doc, doc.name);
    fd.append('nombre_doc',nombre_doc);
    fd.append('type_file',url);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postSaveExam',fd, httpOptions ).toPromise()
      // //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }


  async postSaveOtro(doc:File , id_app_boo, nombre_doc,use_id = null ) {
    
    const fd = new FormData();
    fd.append('file',doc,doc.name);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    fd.append('id_app_boo', id_app_boo);
    fd.append('use_id', use_id);
    fd.append('nombre_doc',nombre_doc);

    //console.log(fd);
    
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postSaveOtro',fd, httpOptions ).toPromise()
      // //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListaArchivosConsulta(id_app_boo , use_id ) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };
    // //console.log("-------------------------");
    
    // //console.log(id_app_boo);
    // //console.log(use_id);

    
    var params;
    if (use_id) { 
      // fd.append('id_app_boo', id_app_boo);
      // fd.append('use_id',use_id);
      params = {
            id_app_boo: id_app_boo,
            use_id: use_id
      };
    } else {
      params = {
            use_id: new EncriptacionService().getUserInfo()._id,
            id_app_boo: id_app_boo
      }
    }
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListaArchivosConsulta',params, httpOptions ).toPromise()
      // //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListaMisArchivosConsulta() {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListaMisArchivosConsulta', {}, httpOptions ).toPromise()
      // //console.log(data);
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListarArchivosPaciente(use_id ) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };
    // //console.log("-------------------------");
    
    // //console.log(use_id);
    let params = {
      use_id: use_id
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarArchivosPaciente',params, httpOptions ).toPromise()
      // //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListarDocExam() {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarDocExam', JSON.stringify({}), httpOptions ).toPromise()
      // //console.log(data);
      
      return data;
    }
    catch (error) {
      console.log(error);
      
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListarOrdenMedica() {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarOrdenMedica', JSON.stringify({}), httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      console.log(error);
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListarRecetas() {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarRecetas', JSON.stringify({}), httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      console.log(error);
      this._errors.requestError(error)
      throw error;
    }
  }

  async postListarInformeExam() {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarInformeExam', JSON.stringify({}), httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      console.log(error);
      this._errors.requestError(error)
      throw error;
    }
  }


  async postEliminarArchivo(archivo) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    let params = {
      nombreArchivo:archivo
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postEliminarArchivo', params, httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      console.log(error);
      this._errors.requestError(error)
      throw error;
    }
  }


  
}