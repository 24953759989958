<div class="row">
    <div class="col-md-12">
        <br>
        <div class="minititulo">
            Mi buscador
        </div>
        <div class="titulo">
            Buscador de usuarios
        </div>
        <br><br>
        <div>
            <form [formGroup]="rutABuscar" (ngSubmit)="buscarRut()">
                <label for="">Rut Beneficiario</label>

                <div class="form-group">

                    <input type="text" class="form-control" formControlName="rutBbdd" id="rutBdd"
                        placeholder="Seleccione el usuario" style="min-height: 56px;" (input)="changeRut()" required>
                </div>

                <br>
                <button type="submit" class="btn btn-outline-secondary btn-lg boton">Buscar</button>
            </form>
        </div>
    </div>
</div>

<div *ngIf="!rutABuscar && plansList.length == 0" class="row">
    <div class="not-appointment" >
        <div>
          <mat-icon>info</mat-icon>
          <span>El rut ingresado no es parte de ningun plan.</span>
        </div>
      </div>
</div>

<div class="card m-t-40" *ngIf="plansList.length > 0">
    <div class="card-header">
        <span class="header-card">Información Rut: {{plansList[0].r_benefciciario}}</span>
        <p class="card-description" >
            Estado de cuenta: <span [ngClass]="{'active': plansList[0].status == 'activo', 'inactive':plansList[0].status == 'pendiente'}">{{plansList[0].status | uppercase }}</span> 
        </p>
        <p class="card-description">
            Estado de planes contratados:
        </p>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Plan</th>
                        <th>N° poliza</th>
                        <th>Tipo Usuario</th>
                        <th>Descripcion</th>
                        <th>Fecha activación</th>
                        <th>Fecha desactivación</th>
                        <th>Fecha creación</th>
                        <th>Estado plan</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let carga of plansList">
                        <td><img src="{{carga.i_plan}}" style="width: 100%; max-height: 30px;" /></td>
                        <td>{{carga.poliza}}</td>
                        <td>{{carga.t_usuario}}</td>
                        <td>{{carga.n_plan | titlecase }}</td>
                        <td>{{carga.f_activation | date: 'dd/MM/yyyy'}}</td>
                        <td>{{carga.deactivation | date: 'dd/MM/yyyy'}}</td>
                        <td>{{carga.creation_acount | date: 'dd/MM/yyyy'}}</td>
                        <td>{{carga.d_status | titlecase }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>