import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { io } from 'socket.io-client';
import { URL_SERVICIOS_SOCKET } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class SocketWebRtcService {
  public socket = io(URL_SERVICIOS_SOCKET, {
    transports: ['websocket']
});

  constructor() { 
    // this.socket = 
  }

  joinRoom(data) {
    this.socket.emit('webRTC:join',data);
  }

  joined(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:joined', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }
  
  created(){
      let observable = new Observable(observer=>{
          this.socket.on('webRTC:created', (data)=>{
              observer.next(data);
          });
          return () => {this.socket.disconnect();}
      });
      return observable;
  }

  offer(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:offer', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }
  
  full(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:full', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  ready(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:ready', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  candidate(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:candidate', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  answer(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:answer', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  leave(){
    let observable = new Observable(observer=>{
        this.socket.on('leave', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  participants(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:participants', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  endMeetingByDoctor(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:endMeetingByDoctor', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }
  
  isNotCompatible(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:isNotCompatible', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }

  isNotAuthorized(){
    let observable = new Observable(observer=>{
        this.socket.on('webRTC:isNotAuthorized', (data)=>{
            observer.next(data);
        });
        return () => {this.socket.disconnect();}
    });
    return observable;
  }
  
  socketEmit(topic, data, event) {
    // //console.log(topic,'topic-socketEmit');
    // //console.log(data, 'data-socketEmit');
    // //console.log(event, 'event-socketEmit');

    if (!event) {
      this.socket.emit('webRTC:'+topic, data);
      return;
    }
    this.socket.emit('webRTC:'+topic, event, data);
  }

  socketExit() {
    this.socket.disconnect();
  }
}