<div class="row" style="margin-bottom: 25px; display: flex; flex-direction: row; flex-wrap: wrap;">
     <ng-container *ngIf="benefits && benefits.length > 0;else noBenefits">
          <!-- <div id="benefitsCarrousel" class="carousel slide" data-ride="carousel">

               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <img src="la.jpg" alt="Los Angeles">
                 </div>
                 <div class="carousel-item">
                   <img src="chicago.jpg" alt="Chicago">
                 </div>
                 <div class="carousel-item">
                   <img src="ny.jpg" alt="New York">
                 </div>
               </div> -->

          <ng-container *ngFor="let benefit of benefits">
               <div class="col-lg-6 col-md-6 col-sm-12 " style="margin-top: 10px;">
                    <app-benefit [benefit]="benefit"></app-benefit>
               </div>
          </ng-container>
          <!-- </div>
          <a class="carousel-control-prev" href="#benefitsCarrousel" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </a>
          <a class="carousel-control-next" href="#benefitsCarrousel" data-slide="next">
            <span class="carousel-control-next-icon"></span>
          </a> -->
     </ng-container>
</div>
<ng-template #noBenefits>
     <div class="row cardInfo" style="display: flex; flex-direction: row;">
       <div class="col-md-1 col-2" style="display: flex; justify-content: center; align-items: center;">
         <mat-icon style="color: #171994; font-size: 30px;">info</mat-icon>
       </div>
       
       <ng-container *ngIf="isDashboard; else otherText">
         <div class="col-md-11 col-10" style="align-self: center; padding-left: 0;">
           <p class="infoStyleText" style="text-align: justify;">Por el momento no contamos con beneficios de salud para ti. Nos encontramos
             trabajando en la creación de alianzas que nos permitan facilitar el acceso a diferentes prestaciones
             de salud para toda nuestra comunidad.</p>
         </div>
       </ng-container>
   
       <ng-template #otherText>
         <div class="col-md-11 col-10" style="align-self: center; padding-left: 0;">
           <p class="infoStyleText" style="text-align: justify;">Por el momento no contamos con beneficios de salud en esta prestación. Nos encontramos
             trabajando en la creación de alianzas que nos permitan facilitar el acceso a diferentes prestaciones
             de salud para toda nuestra comunidad.</p>
         </div>
       </ng-template>
       
     </div>
   </ng-template>
   