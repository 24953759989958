<!-- <app-title-header 
  [title]='_title' 
  [subtitle]='_subtitle'>
</app-title-header>
<br> -->

<div class="videoconsulta"> <!-- no responsive -->
  <div class="row" style="margin: 20px 0px;">
    <div class="col-md-4 col-sm-12">
      <app-title-header [title]='_title' [subtitle]='_subtitle'>
      </app-title-header>
    </div>
    <div class="col-md-8 col-sm-12 container-buttons">

      <div class="col-md-4 sm-12" style="margin-bottom: 5px;">
        <button *ngIf="anulacionConsulta"  type="button" class="btn botonCancelar colorLetraVolver" data-toggle="modal" tabindex="-1"
        data-target="#anulacionAppoModal" (click)="infoCancelaConsulta()"> Anular consulta</button>
      </div>
      <div class="col-md-4 sm-12" style="margin-bottom: 5px;">
        <button *ngIf="!videoIniciado" type="button" class="btn btn-secondary botonVolver"
          (click)="iniciarVideoconsulta()" [disabled]="(btnIniciar == false)">Iniciar videollamada
        </button>
        <button *ngIf="videoIniciado" type="button" class="btn botonCancelar colorLetraVolver"
          (click)="finalizarVideoconsulta()">Finalizar videollamada
        </button>
      </div>

    </div>
  </div>


  <div class="row">

    <div class="col-md-4 col-sm-12">

      <div class="detalle-consulta">
        <h4 style="margin-bottom: 10px;">Hoy {{consulta.fecha_consulta.day}} de {{consulta.fecha_consulta.month}} - a
          las {{consulta.fecha_consulta.time}} horas</h4>
        <div class="align-self-center">
          <p class="card-text m-0 p-0">Paciente: {{consulta.nombre_paciente}} {{consulta.apellido_paterno_paciente}}
            {{consulta.apellido_materno_paciente}}</p>
          <p class="card-text m-0 p-0 ">Edad: {{consulta.edad}}</p>
          <p class="card-text m-0 p-0">Acompañante: {{consulta.nombre_acompanante}}
            {{consulta.apellido_paterno_acompanante}} {{consulta.apellido_materno_acompanante}}</p>
          <p class="card-text m-0 p-0">Área médica: {{consulta.spe_name}} </p>
        </div>
        <h4 style="margin: 12px 0">Motivo de consulta</h4>
        <div class="align-self-center">
          <p class="card-text m-0 p-0" style="padding: 10px auto;">{{consulta.razon}}</p>
        </div>
      </div>

      <div class="card border-confirm card-fono">
        <div class="card-block ">
          <p class="card-text m-0 p-0">Si tienes algún problema técnico, o el paciente no se presenta, debes comunicarte
            con nuestra Mesa de Ayuda.</p>
          <p *ngIf="consulta.telefono_plan" class="fono p-0">{{consulta.telefono_plan}}</p>
        </div>
      </div>

    </div>

    <div class="col-md-8 col-sm-12" style="height: fit-content;">
 
      <app-video-web-rtc [video_iniciado]='videoIniciado'>
      </app-video-web-rtc>
    </div>
  </div>

</div>

<div class="options">
  <div class="row" style="margin: 0;">
    <button class="col-md-3 col-sm-6" id="consulta" [class]="options[0] ? 'checked' : 'nochecked'"
      (click)="optionsButton(0)">Consulta actual y documentos</button>
    <button class="col-md-3 col-sm-6" [class]="options[1] ? 'checked' : 'nochecked'"
      (click)="optionsButton(1)">Antecedentes del paciente</button>
    <button class="col-md-3 col-sm-6" [class]="options[2] ? 'checked' : 'nochecked'"
      (click)="optionsButton(2)">Consultas previas del paciente</button>
    <button class="col-md-3 col-sm-6" id="agenda" [class]="options[3] ? 'checked' : 'nochecked'"
      (click)="optionsButton(3)">Mi agenda</button>
  </div>
</div>



<div *ngIf="options[0]">
  <app-ficha-medica [eventos]='ficha_eventos' [consulta]='consulta'>
  </app-ficha-medica>
</div>


<div *ngIf="options[1]">
  <app-antecedentes-paciente [consulta]='consulta'>
  </app-antecedentes-paciente>
</div>

<div *ngIf="options[2]">
  <app-consultas-previas [consulta]='consulta'>
  </app-consultas-previas>
</div>



<div style="margin-left: 5px;" id="agenda_medico" style="display: none;">
  <app-agenda-medico2 class="" (messageEvent)='clickHourAgendaMedico($event)'>
  </app-agenda-medico2>
</div>

<!-- Modal Anulacion consulta -->

<!-- MODAL ANULAR CONSULTA -->
<div class="modal fade" id="anulacionAppoModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">Anulación de consulta</p>
        <br>
        <div class="form col-md-12" id="ConsultaCancelarData" autocomplete="off">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 border-card-modal">
            <div class="m-0 p-0 first-line">
              <div class="img-cirlce-wrapper">
                <p class="m-0 p-0 text-default">
                  {{ pacienteName }}{{ pacienteLastName }}
                </p>
              </div>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ consulta.nombre_paciente }} {{
                  consulta.apellido_paterno_paciente }} {{consulta.apellido_materno_paciente}}</p>
                <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{
                  consulta.fecha_consulta.day }} de {{ consulta.fecha_consulta.month}} a las
                  {{consulta.fecha_consulta.time}} horas.</p>
                <p class="card-text m-0 p-0 ">{{ consulta.spe_name }}</p>
                <p class="card-text m-0 p-0 "> </p>
              </div>
            </div>
            <br>
          </div>
          <br><br>
          <div class="form-group">
            <textarea class="form-control" placeholder="Motivo de la cancelación*" [(ngModel)]="infoConsulta.comentario" rows="5" id="comentario"></textarea>
          </div>
          <!-- <div class="row">
            <div class="col text-danger">
              <p *ngIf="campoNoValido('comentario')"> El motivo de la cancelación obligatorio</p>
            </div>
          </div> -->
          <br>
          <br>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver2 colorLetraVolver" data-dismiss="modal">Cerrar
                  formulario</button>
              </div>
              <div class="col-md-6 letrabotonagregar">
                <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-toggle="modal"
                  data-target="#confirmaAnular">Cancelar consulta</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL CONFIRMA ANULAR CONSULTA -->
<div  class="modal fade" id="confirmaAnular" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">¿Esta seguro que desea anular la consulta?</p>
        <br>
        <br><br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver2 colorLetraVolver"
                data-dismiss="modal">Cerrar</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" (click)="confirmaEliminar()" class="btn btn-outline-primary btn-lg botonModalAgregar"
                data-dismiss="modal" data-toggle="modal" data-target="#exitoAnulacion">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>