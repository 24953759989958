import { Component, OnInit } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.css']
})
export class TarifasComponent implements OnInit {



  public usuario: any = {};
  public tarifa_selected: any = {};



  constructor( public generalService: GeneralService)
   {
    this.usuario = new EncriptacionService().getUserInfo();
    this.usuario.use_id = this.usuario._id;
   }

  ngOnInit(): void {
  }

}
