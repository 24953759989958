import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { MedicoComponent } from './medico/medico.component';
import { PacienteComponent } from './paciente/paciente.component';
import { OnboardingComponent } from './onboarding.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [MedicoComponent, PacienteComponent, OnboardingComponent],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    MatIconModule,
    ReactiveFormsModule
  ]
})
export class OnboardingModule { }
