<ng-container *ngIf="payment_button && payment_button.url">
     <form #form [action]="payment_button.url" method="post">
          <input type="hidden" name="token_ws" [value]="payment_button.token" />
          <!-- <button type="submit" class="btn">pagar</button> -->
          <button #butonPayTransbank id="butonPayTransbank" class="btn d-none" type="submit" (click)="form.submit()">Submit</button>
          <!-- <input type="submit" value="Ir a pagar" /> -->
     </form>
</ng-container>

<!-- <button *ngIf="payment_button.url && payment_button.token" (click)="pay()">
     Pagar
</button> -->