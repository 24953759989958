import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompleterService } from 'ng2-completer';
import { BeneficiaryService } from 'src/app/services/beneficiary.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-admin-beneficiary-plans',
  templateUrl: './admin-beneficiary-plans.component.html',
  styleUrls: ['./admin-beneficiary-plans.component.css']
})
export class AdminBeneficiaryPlansComponent implements OnInit {
  @ViewChild('seeBeneficiaryTri') seeBeneficiaryTri: ElementRef;
  @ViewChild('deleteBeneficiaryTri') deleteBeneficiaryTri: ElementRef;
  @ViewChild('addBeneficiaryTri') addBeneficiaryTri: ElementRef;
  
  beneficiarySelected: any = {};
  deleteSelected: any = {};
  beneficiaries: any;
  beneficiaries_data: any = [];
  id_ben: any;
  searchStr: any = '';

  planList: any[] = [];
  newRut: any = '';
  planSelected: any = '';

  constructor(
    private beneficiaryService: BeneficiaryService,
    private generalService: GeneralService,
    private renderer: Renderer2,
    private _completerService: CompleterService,
    private customerService: CustomerService,

  ) { }

  ngOnInit(): void {
    this.postListarCustomer();
    this.listBeneficiariesCollaborators();
    this.searchStr = '';
    this.newRut = '';
    this.planSelected = '';
  }

  postListarCustomer() {    
    this.customerService.postListarCustomer({main : true})
    .then((result: any) => {
      // console.log(result);
      if (result && result.length > 0 && result[0].planes && result[0].planes.length > 0 ) {
        this.planList = result[0].planes;
      }
      // Swal.close();
    })
    .catch((error: any) => {
      Swal.close();
    });
  }

  listBeneficiariesCollaborators() {

    this.generalService.modalCargando();
    this.beneficiaries = {};
    this.beneficiaryService.listBeneficiariesCollaborators()
      .then((result: any) => {
        // console.log(result);
        if (result.activos && result.incativos) {
          this.listBeneficiaries(result.activos, result.incativos);
        }
        Swal.close();
      })
      .catch(error => {
        this.generalService.modalError('Error', 'Error al traer beneficiarios');
      });
  }

  // this.renderer.selectRootElement(this.seeBeneficiaryTri.nativeElement).click();
  seeBeneficiary() {
    // this.beneficiarySelected = beneficiary;
    this.renderer.selectRootElement(this.seeBeneficiaryTri.nativeElement).click();
  }

  listBeneficiaries(arrayBeneficiaries, arrayBenInactivos) {
    // console.log(arrayBeneficiaries);
    this.beneficiaries_data = arrayBeneficiaries;
    for (let ben of this.beneficiaries_data) {
      ben.description = ben.nombre_beneficiario + ': ' + ben.use_rut;
    }

    for (let ben of arrayBenInactivos) {
      ben.description = 'Plan: ' + ben.cus_ben_name + ' | RUT: ' + ben.cus_dat_rut;
      this.beneficiaries_data.push(ben);
    }
    this.beneficiaries = this._completerService.local(this.beneficiaries_data, 'description', 'description');
  }

  changeBeneficiary($event) {
    if ($event && $event.originalObject) {
      this.beneficiarySelected = $event.originalObject;
    }
  }

  deleteBen(plan) {
    // console.log(plan);
    this.deleteSelected = {};
    if (!plan || (!plan.ben_id && !plan.cus_dat_id)) {
      this.generalService.modalError('Error', 'Seleccione un beneficiario válido.');
      return;
    }
    this.deleteSelected = plan;
    this.renderer.selectRootElement(this.seeBeneficiaryTri.nativeElement).click();
    this.renderer.selectRootElement(this.deleteBeneficiaryTri.nativeElement).click();
  }

  confirmDelete() {
    if ((!this.deleteSelected || !this.deleteSelected.ben_id) && (!this.beneficiarySelected || !this.beneficiarySelected.cus_dat_id)) {
      this.generalService.modalError('Error', 'Seleccione un beneficiario válido.');
      return;
    }
    this.renderer.selectRootElement(this.deleteBeneficiaryTri.nativeElement).click();
    this.generalService.modalCargando();
    this.beneficiaryService.deleteCollaborator({ ben_id: this.deleteSelected.ben_id, cus_dat_id: this.beneficiarySelected.cus_dat_id })
      .then(async (result: any) => {
        this.generalService.modalExitoso('Plan eliminado', 'El plan se eliminó exitosamente del beneficiario.');
        await this.generalService.sleep(1000);
        this.ngOnInit();
      })
      .catch(error => {
        this.generalService.modalError('Error', 'Error al desactivar beneficiario');
      });
  }

  addBen() {
    this.newRut = '';
    this.planSelected = '';
    this.renderer.selectRootElement(this.addBeneficiaryTri.nativeElement).click();
  }

  confirmAdd() {

    if (!this.newRut || this.newRut.length < 4 || !this.generalService.validaRut(this.newRut)) {
      this.generalService.modalError('Error en rut', 'Rut no válido.');
      return;
    }else if (!this.planSelected ) {
      this.generalService.modalError('Error en plan', 'Plan no válido.');
      return;
    }

    this.renderer.selectRootElement(this.addBeneficiaryTri.nativeElement).click();
    this.generalService.modalCargando();
    this.beneficiaryService.addCollaborator({rut:this.newRut,cus_ben_id:this.planSelected})
    .then(async (result: any) => {
      this.generalService.modalExitoso('Beneficiario agregado', 'El beneficiario se agregó exitosamente.');
      await this.generalService.sleep(1000);
      this.ngOnInit();
    })
    .catch(error => {
      this.generalService.modalError('Error', 'Error al agregar beneficiario');
    });
  }

}
