import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';

import { ScheduleService } from 'src/app/services/schedule.service';

import { CalendarMonthComponent } from 'src/app/components/calendar/calendar-month/calendar-month.component';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';


@Component({
  selector: 'app-agenda-medico2',
  templateUrl: './agenda-medico2.component.html',
  styleUrls: ['./agenda-medico2.component.css']
})

export class AgendaMedico2Component implements OnInit {
  @Input() vistaProximasConsultas: boolean = false;
  // @Input() list_hours_doc: any; 
  @Output() messageEvent = new EventEmitter<any>();
  @Output() messageMonth = new EventEmitter<any>();
  @ViewChild(CalendarMonthComponent) CALENDAR: CalendarMonthComponent;

  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha
  options_week = { weekday: 'long' }; //opciones labels dia de la semana
  options_month = { month: 'long' }; //opciones labels mes

  list_hours: Array<any>;               //listado de horas disponibles en el componente
  events_calendar: Array<any> = [];
  hoursDay: Array<any> = [];              // Array de bloques de horas 
  daySelected: any = {};

  horas_calendar: boolean = true;
  horasAuxiliares = [];

  currentHour: number;
  currentTab: string;


  constructor(
    public scheduleService: ScheduleService,
  ) { }

  ngOnInit(): void {
    this.getHorasBloques();
    //console.log(this.vistaProximasConsultas);
    this.currentHour = new Date().getHours();
    // console.log(this.currentHour);
    
    if (this.currentHour >= 0 && this.currentHour < 11.5) {
      this.currentTab = 'AM';
    }
    else {
      this.currentTab = 'PM';
    }

  }

  //bloques de horarios
  getHorasBloques() {
    this.scheduleService.getScheduleTimes()
      .then((data: any) => {
        this.hoursDay = data;
      })
  }

  sendToAgendaMedico(list_hours_doc: any, dia_inicial: Date = null) {
    this.list_hours = list_hours_doc;
    this.sendToCalendar(this.list_hours, dia_inicial);
    if (dia_inicial) {
      this.clickDiaInicial(dia_inicial)
    }
  }

  // Carga los eventos en la variable usada por el calendario
  sendToCalendar(eventsX, dia_inicial) {
    let events_calendar: Array<CalendarEvent> = []; //Creacion de los eventos para enviarlos al calendario

    for (let eve of eventsX) {
      events_calendar.push({
        start: new Date(eve.sch_date.substr(0, 10).replaceAll('-', '/')), //va solo date, podrian ir timestamp
        title: eve.sch_id,
      });
    }
    // //console.log(events_calendar);

    this.CALENDAR.sendToCalendar(events_calendar, dia_inicial);
  }

  //respuesta desde el calendario {date: date, events: [{start,title},]}
  clickDayCalendar(dayCalendar: CalendarMonthViewDay) {
    this.daySelected.date = dayCalendar.date;
    this.daySelected.dateInt = this.daySelected.date.getFullYear() * 10000 + (this.daySelected.date.getMonth() + 1) * 100 + this.daySelected.date.getDate();
    this.daySelected.dateString = (this.daySelected.date).toLocaleString('es-US', this.options);
    this.daySelected.dayTitle = (this.daySelected.date).toLocaleString('es-US', this.options_week) + ' ' + this.daySelected.date.getDate() + ' de ' + (this.daySelected.date).toLocaleString('es-US', this.options_month);

    let hoursX = [];
    if (dayCalendar.events.length == 0) {
      this.daySelected.available = false;
      this.daySelected.hours_available = [];
    } else {
      this.daySelected.available = true;
      this.daySelected.sch_id = dayCalendar.events[0].title;

      for (let hour of this.list_hours) {

        if (hour.sch_id == this.daySelected.sch_id) {
          if (hour.hours) {
            for (let xx of hour.hours) {
              hoursX.push({
                sch_hou_id: xx.id,
                sch_hou_from: xx.from,
                sch_hou_to: xx.to,
                sch_sta_id: xx.sta_id,
              })
            }
          }
          else {
            hoursX.push({
              sch_hou_id: hour.sch_hou_id,
              sch_hou_from: hour.sch_hou_from,
              sch_hou_to: hour.sch_hou_to
            })
          }
        }
      }
      this.daySelected.hours_available = hoursX;

      for (let block of this.hoursDay) {
        block.available = false;
        block.sch_hou_id = null;
        block.existe = false;
        for (let hour of this.daySelected.hours_available) {
          block.existe = (hour.sch_sta_id) ? block.existe : true;
          if (block.from == hour.sch_hou_from) {
            block.available = (hour.sch_sta_id) ? ((hour.sch_sta_id == 21) ? true : false) : true;
            block.existe = true;
          }
          else {
            block.existe = block.existe;
            block.available = block.available;
          }
          block.hour_number = Number(block.from.replaceAll(':', ''));
          block.sch_hou_id = (block.from == hour.sch_hou_from) ? hour.sch_hou_id : block.sch_hou_id;
        }
      }
      // console.log(this.hoursDay, ' Horarios disponibles');

    }
  }

  //respuesta que emite este componente al clickear una hora disponible
  clickHour(hora) {
    this.messageEvent.emit({ day: this.daySelected, hour: hora })
  }

  clickMonthCalendar(data: any) {
    this.messageMonth.emit(data)
  }
  clickDayCalendar2(dayCalendar: CalendarMonthViewDay) {
    //console.log("clickDayCalendar2");
    this.horasAuxiliares = [];
    this.daySelected.date = dayCalendar.date;
    this.daySelected.dateInt = this.daySelected.date.getFullYear() * 10000 + (this.daySelected.date.getMonth() + 1) * 100 + this.daySelected.date.getDate();
    this.daySelected.dateString = (this.daySelected.date).toLocaleString('es-US', this.options);
    this.daySelected.dayTitle = (this.daySelected.date).toLocaleString('es-US', this.options_week) + ' ' + this.daySelected.date.getDate() + ' de ' + (this.daySelected.date).toLocaleString('es-US', this.options_month);

    let hoursX = [];
    if (dayCalendar.events.length == 0) {
      this.daySelected.available = false;
      this.daySelected.hours_available = [];
    } else {
      this.daySelected.available = true;
      this.daySelected.sch_id = dayCalendar.events[0].title;

      for (let hour of this.list_hours) {

        if (hour.sch_id == this.daySelected.sch_id) {
          if (hour.hours) {
            for (let xx of hour.hours) {
              hoursX.push({
                sch_hou_id: xx.id,
                sch_hou_from: xx.from,
                sch_hou_to: xx.to,
                sch_sta_id: xx.sta_id,
              })
            }
          }
          else {
            hoursX.push({
              sch_hou_id: hour.sch_hou_id,
              sch_hou_from: hour.sch_hou_from,
              sch_hou_to: hour.sch_hou_to
            })
          }
        }
      }
      this.daySelected.hours_available = hoursX;

      for (let block of this.hoursDay) {
        block.available = false;
        block.sch_hou_id = null;
        block.existe = false;
        for (let hour of this.daySelected.hours_available) {
          block.existe = (hour.sch_sta_id) ? block.existe : true;
          if (block.from == hour.sch_hou_from) {
            block.available = (hour.sch_sta_id) ? ((hour.sch_sta_id == 21) ? true : false) : true;
            block.existe = true;
            this.horasAuxiliares.push(block);
          }
          else {
            block.existe = block.existe;
            block.available = block.available;
          }
          block.sch_hou_id = (block.from == hour.sch_hou_from) ? hour.sch_hou_id : block.sch_hou_id;
        }
      }
    }
    //console.log(this.horasAuxiliares);

  }

  clickDiaInicial(dia: Date) {
    let dia_int = `${dia.getFullYear()}${((dia.getMonth() + 101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`; //'yyyymmdd'
    let hours_dia: Array<CalendarEvent> = [];

    for (let eve of this.list_hours) {
      let dia2 = new Date(eve.sch_date.substr(0, 10).replaceAll('-', '/')); //dia actual
      let dia2_int = `${dia2.getFullYear()}${((dia2.getMonth() + 101).toString()).substring(1, 3)}${((dia2.getDate() + 100).toString()).substring(1, 3)}`;
      if (dia2_int == dia_int) {
        hours_dia.push({
          start: new Date(eve.sch_date.substr(0, 10).replaceAll('-', '/')), //va solo date, podrian ir timestamp
          title: eve.sch_id,
        });
      }
    }

    let to_day_calendar: CalendarMonthViewDay = {
      badgeTotal: 1,
      cssClass: "amc-cal-day-selected",
      date: dia,
      day: dia.getDay(),
      events: hours_dia,
      inMonth: true,
      isFuture: false,
      isPast: false,
      isToday: true,
      isWeekend: false,
    }
    this.clickDayCalendar(to_day_calendar)    //emulo un elemento del calendario (con el que responde cuando se clickea un dia)
  }

}
