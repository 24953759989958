import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-encuesta-post-consulta',
  templateUrl: './encuesta-post-consulta.component.html',
  styleUrls: ['./encuesta-post-consulta.component.css']
})
export class EncuestaPostConsultaComponent implements OnInit {
  @Input() med_con_id: any;
  @Input() consulta: any = {};

  number_page: number = -1;
  calificaciones = [0, 0, 0, 0];
  comentario: String = "";
  constructor(
    private _sheduleServices: ScheduleService,
    private router: Router,
    private generalServices: GeneralService,
  ) { }

  ngOnInit(): void {
    this.router.navigate(['dashboard']);
  }

  inicio() {
    this.router.navigate(['dashboard']);
  }
  sendSurvey() {

    let params = {
      med_con_id: this.med_con_id,
      doctor: this.calificaciones[0],
      video: this.calificaciones[1],
      platform: this.calificaciones[2],
      nps: this.calificaciones[3],
      comment: this.comentario
    }

    this._sheduleServices.postInsertSurvey(params)
      .then(async result => {

        Swal.fire({
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > Su encuesta ha sido enviada exitosamente</h1><br>',
          footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Salir</button> <button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Continuar</button>  ',
          
          showCancelButton: false,
          showConfirmButton: false,
        })

        await this.sleep(2000);

        Swal.close();
        this.navegar();
      })
      .catch(error => {
        this.generalServices.modalError('Error al insertar encuesta', 'Tienes que seleccionar entre 1 a 5 estrellas.');
      })

  }
  

  siguiente() {
    if (this.number_page > -1 && this.number_page < 4 && this.calificaciones[this.number_page] == 0) {
      this.generalServices.modalError('No puedes seguir', (this.number_page == 3 ? 'Tienes que seleccionar una puntuación entre 1 y 10.' : 'Tienes que seleccionar entre 1 y 5 estrellas.'));
      return;
    }
    this.number_page = this.number_page + 1;
  }

  volver() {
    this.number_page = this.number_page - 1;
  }

  calificacion(numero) {

    if (numero < 1) {
      this.generalServices.modalError('No puedes seguir', 'Tienes que seleccionar entre 1 a 5 estrellas.');
      return;
    }
    this.calificaciones[this.number_page] = numero;

  }

  navegar() {
    this.router.navigate(['dashboard'])
  }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }
}
