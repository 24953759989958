import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorsService } from './errors.service';
import { URL_SERVICIOS } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class GestionService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService
  ) { }


  async assistanceUse(email, n_consulta, cus_id, spe_id, f_desde, f_hasta){
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    let params = {
      email: email,
      n_consulta: n_consulta,
      cus_id: cus_id,
      spe_id: spe_id,
      f_desde: f_desde,
      f_hasta: f_hasta,
      
    };

    try {
      let data = this.http.post(URL_SERVICIOS + '/assistanceUse', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }

  }

  async scheduleDisponibilityKPI(){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/scheduleDisponibilityKPI ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

 

  async scheduleDisponibility(email, doc_id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    let params = {  
      email: email,
      doc_id: doc_id
      };

    try {
      let data = this.http.post(URL_SERVICIOS + '/scheduleDisponibility', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }

  async activatedUsersKPI(){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/activatedUsersKPI ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async activatedUsers(email, rut, cus_id, f_desde, f_hasta ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    let params = {  
      email: email,
      rut: rut,
      cus_id: cus_id,
      f_desde: f_desde,
      f_hasta: f_hasta
      };

    try {
      let data = this.http.post(URL_SERVICIOS + '/activatedUsers', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }


  async surveyListKPI(){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/surveyListKPI ', JSON.stringify(params), httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }

  async surveyList(email, n_consulta, cud_id, f_desde, f_hasta ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    let params = {  
      email: email,
      n_consulta: n_consulta,
      cud_id: cud_id,
      f_desde: f_desde,
      f_hasta: f_hasta
      };

    try {
      let data = this.http.post(URL_SERVICIOS + '/surveyList', params, httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }


  authorizedDomains(){
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };
    let params = {
    };
    try {
      let data = this.http.post(URL_SERVICIOS + '/authorizedDomains', JSON.stringify(params), httpOptions).toPromise();
      return data;
    } catch (error) {
      //this._errors.requestError(error);
      throw error;
    }
  }

}
