import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { read, utils } from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-admin-database-loading',
  templateUrl: './admin-database-loading.component.html',
  styleUrls: ['./admin-database-loading.component.css']
})
export class AdminDatabaseLoadingComponent implements OnInit {
  customers: any[] = [];
  cus_id: any = '';
  customerSelected: any = {};

  cus_ben_id: any = '';
  customerBenSelected: any = {};

  loadType: any = '';

  csvFile: any = null;
  headers: any[] = [];
  tableData: any[] = [];
  tableDataPreview: any[] = [];
  separationType: any = ',';

  constructor(
    private customerService: CustomerService,
    private generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this.generalService.modalCargando();
    this.postListarCustomer();
  }

  postListarCustomer() {
    this.separationType = ',';
    this.customers = [];
    this.customerSelected = {};
    this.customerBenSelected = {};
    this.loadType = '';
    this.csvFile = null;
    this.headers = [];
    this.tableData = [];
    this.tableDataPreview = [];
    this.cus_id = '';
    this.customerService.postListarCustomer({is_bulk_type : true})
      .then((result: any) => {
        // console.log(result);
        this.customers = result;
        Swal.close();
      })
      .catch((error: any) => {
        this.generalService.modalError('Error', 'Error al cargar clientes.');
      });
  }

  changeCustomer(event) {
    // console.log(event);
    this.customerSelected = {};
    this.customerBenSelected = {};
    this.cus_ben_id = '';
    if (event) {
      for (let index = 0; index < this.customers.length; index++) {
        const element = this.customers[index];
        if (element.cus_id == event) {
          this.customerSelected = JSON.parse(JSON.stringify(element));
          break;
        }
      }
    }
  }

  changeCustomerBenefits(event) {
    // console.log(event);
    this.customerBenSelected = {};
    this.loadType = '';
    if (event && this.customerSelected && this.customerSelected.planes) {
      for (let index = 0; index < this.customerSelected.planes.length; index++) {
        const element = this.customerSelected.planes[index];
        if (element.cus_ben_id == event) {
          this.customerBenSelected = JSON.parse(JSON.stringify(element));
          break;
        }
      }
    }
  }

  changeLoadType(event) {
    this.loadType = '';
    this.csvFile = null;
    if (event) {
      this.loadType = event;
    }
  }

  onFileSelected(event) {
    let arrayType = ['txt', 'xlsx', 'csv'];
    this.csvFile = null;
    this.tableData = [];
    this.tableDataPreview = [];
    this.headers = [];
    this.generalService.modalCargando();
    if (!event || !event.target || !event.target.files || !event.target.files[0] || !event.target.files[0].name || !this.generalService.verifyExtension(event.target.files[0].name, arrayType)) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido, solo con formato txt/xlsx/csv');
      return;
    }
    // console.log(event.target.files[0]);
    this.csvFile = event.target.files[0];
    this.filterData();
  }


  filterData() {
    const file = this.csvFile;
    let reader: FileReader = new FileReader();
    this.tableData = [];
    this.tableDataPreview = [];
    this.headers = [];
    reader.onload = (event: any) => {

      const wb = read(event.target.result, { type: 'binary', cellText: false, cellDates: true });
      const sheets = wb.SheetNames;
      // console.log(wb.Sheets[sheets[0]]);
      const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 0, raw: false, dateNF: 'YYYY-MM-DD' });
      let headers = [];
      // console.log(rows);
      if (rows && rows.length > 0) {
        for (var clave in rows[0]) {
          let config = this.modifyHeader(clave);
          if (config && config.clave) {
            headers.push({ name: config.clave, formula: config.formula });
          }
        }
        let arrayFiltered = [];
        // console.log(headers);
        
        this.headers = headers;
        try {
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            let newElement = this.modifyObject(element);
            arrayFiltered.push(newElement);
            if(!newElement['rut'] || !newElement['tipo'] || !newElement['fecha_inicio']){
              throw new Error('Uno de los campos solicitado en la base esta incompleto o vacio')
            }
          }
          this.tableData = arrayFiltered;
          this.tableDataPreview = arrayFiltered && arrayFiltered.length >= 20 ? arrayFiltered.slice(0, 20) : arrayFiltered;
          console.log(arrayFiltered);
          
        } catch (error) {
          this.generalService.modalError('Error en base','Uno de los campos solicitados en la base a cargar se encuentra incompleto o vacío.');
          // console.log(error);
          
        }
        
      }
    }
    reader.readAsArrayBuffer(file);
    Swal.close();
  }

  modifyHeader(clave) {
    let config = this.headersConfig[clave];
    return config;
  }

  modifyObject(elementRow: any) {
    let newElement = {};
    for (let index = 0; index < this.headers.length; index++) {
      const element = this.headers[index];
      // console.log(element);
      newElement[element.name] = eval(element.formula);
    }
    return newElement;
  }

  headersConfig = {
    //Onco
    "Rut Ase.": { clave: 'rut', formula: '(elementRow["Rut Ase."]+"-"+elementRow["DV"]).toUpperCase()' },
    "Poliza": { clave: 'poliza', formula: 'elementRow["Poliza"]' },
    "Tipo Aseg.": { clave: 'tipo', formula: 'elementRow["Tipo Aseg."].toUpperCase()' },
    "Nombre": { clave: 'nombre', formula: 'elementRow["Nombre"]+" "+elementRow["Apellido Paterno"]' },
    "Producto": { clave: 'producto', formula: 'elementRow["Producto"]' },
    "Fec. Inicio Vig.": { clave: 'fecha_inicio', formula: 'elementRow["Fec. Inicio Vig."].toString().substr(0,4)+"-"+elementRow["Fec. Inicio Vig."].toString().substr(4,2)+"-"+elementRow["Fec. Inicio Vig."].toString().substr(6,2)' },
    "Fec. Fin Vig.": { clave: 'fecha_termino', formula: 'elementRow["Fec. Fin Vig."].toString().substr(0,4)+"-"+elementRow["Fec. Fin Vig."].toString().substr(4,2)+"-"+elementRow["Fec. Fin Vig."].toString().substr(6,2)' },
    "Propuesta": { clave: 'folio', formula: 'elementRow["Propuesta"]' },
    "Fecha Nacimiento Tit": { clave: 'fecha_nacimiento', formula: 'elementRow["Fecha Nacimiento Tit"].toString().substr(0,4)+"-"+elementRow["Fecha Nacimiento Tit"].toString().substr(4,2)+"-"+elementRow["Fecha Nacimiento Tit"].toString().substr(6,2)' },

    //Renta-Diaria
    "rut_asegurado": { clave: 'rut', formula: 'elementRow["rut_asegurado"].toString().substr(0,elementRow["rut_asegurado"].toString().length-1)+"-"+elementRow["rut_asegurado"].toString().substr(elementRow["rut_asegurado"].toString().length-1,1).toUpperCase()' },
    "numero_poliza": { clave: 'poliza', formula: 'elementRow["numero_poliza"]' },
    "tipo_asegurado": { clave: 'tipo', formula: 'elementRow["tipo_asegurado"] == "MI"?"TITULAR":"CARGA"' },
    "inicio_vigencia_poliza": { clave: 'fecha_inicio', formula: 'this.formatDate(elementRow["inicio_vigencia_poliza"])' },
    "inicio_vigencia_poliza2": { clave: 'fecha_inicio', formula: 'elementRow["inicio_vigencia_poliza2"]' },
    "numero_propuesta": { clave: 'folio', formula: 'elementRow["numero_propuesta"]' },
    "codigo_producto": { clave: 'producto', formula: 'elementRow["codigo_producto"]' },

    //Renta-Semanal
    "RUT": { clave: 'rut', formula: 'elementRow["RUT"].toString().trim().substr(0, elementRow["RUT"].toString().trim().length - 1) + "-" + elementRow["RUT"].toString().trim().charAt(elementRow["RUT"].toString().trim().length - 1).toUpperCase()' },
    "Numero de Poliza": { clave: 'poliza', formula: 'elementRow["Numero de Poliza"]' },
    "Tipo de beneficiario": { clave: 'tipo', formula: 'elementRow["Tipo de beneficiario"].replaceAll(" ","") == "Titular"?"TITULAR":"CARGA"' },
    "Primer Nombre": { clave: 'nombre', formula: 'elementRow["Primer Nombre"].trim().split(" ")[0]' },
    "Apellido": { clave: 'apellido', formula: 'elementRow["Apellido"].trim().split(" ")[0]' },
    "Fecha de efectividad": { clave: 'fecha_inicio', formula: 'this.formatDate(elementRow["Fecha de efectividad"]).toString().replaceAll(" ","")' },
    "Fin Vigencia Renovacion": { clave: 'fecha_termino', formula: 'this.formatDate(elementRow["Fin Vigencia Renovacion"]).toString().replaceAll(" ","")' },
    "Email": { clave: 'email', formula: 'elementRow["Email"].trim()' },
    "Reference 1": { clave: 'folio', formula: 'elementRow["Reference 1"]' },
    "Codigo del producto": { clave: 'producto', formula: 'elementRow["Codigo del producto"].replaceAll(" ","")' },
    "Fecha de Nacimiento": { clave: 'fecha_nacimiento', formula: 'this.formatDate(elementRow["Fecha de Nacimiento"]).toString().replaceAll(" ","")' },

    
    //Estandar
    "rut": { clave: 'rut', formula: 'elementRow["rut"].toUpperCase()' },
    "poliza": { clave: 'poliza', formula: 'elementRow["poliza"]' },
    "producto": { clave: 'producto', formula: 'elementRow["producto"]' },
    "tipo": { clave: 'tipo', formula: 'elementRow["tipo"].toUpperCase()' },
    "nombre": { clave: 'nombre', formula: 'elementRow["nombre"]' },
    "fecha_inicio": { clave: 'fecha_inicio', formula: 'elementRow["fecha_inicio"].replaceAll("/","-")' },
    "fecha_termino": { clave: 'fecha_termino', formula: 'elementRow["fecha_termino"]  .replaceAll("/","-")' },
  };

  formatDate(date) {
    date = date.replaceAll("/", "-");
    let newDate = date;
    let array = date.split("-");
    if (array[0].toString().length < 4) {
      newDate = array[2] + "-" + array[1] + "-" + array[0];
    }
    return newDate;
  }

  loadData() {

    if (!this.csvFile.name || (!this.cus_ben_id && !this.cus_id)|| !this.loadType || !this.tableData || this.tableData.length == 0) {
      this.generalService.modalError('Información no válida', 'La base que está intentando cargar no es válida.');
      return;
    }
    let bulkData = {
      name: this.csvFile.name,
      cus_ben_id: this.cus_ben_id,
      cus_id: this.cus_id,
      type: this.loadType,
    };
    this.generalService.modalCargando();
    this.customerService.loadBulkFile(this.tableData, bulkData)
      .then(async (result: any) => {
        // console.log(result);
        this.generalService.modalExitoso('Base cargada', 'La base se está cargando esto puede tomar unos minutos, le llegará un mail con la información del proceso de carga cuando éste finalice...');
        await this.generalService.sleep(2500);
        this.ngOnInit();
      })
      .catch((error: any) => {
        console.log(error);
        this.generalService.modalError('Error', error.error && error.error.message ? error.error.message : 'Error al cargar clientes.');
      });
  }

  downloadFile() {
    // console.log('downloadFile');
    saveAs('','sadadssaddas.xlsx');
  }
}
