import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomService } from 'src/app/services/zoom.service';
import { FunctionsService } from 'src/app/services/functions.service';
import Swal from 'sweetalert2';
import { ScheduleService } from 'src/app/services/schedule.service';
import { AgendaMedico2Component } from 'src/app/components/agenda-medico2/agenda-medico2.component';
import { URL_DOCS } from 'src/app/config/config';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { VideoWebRtcComponent } from 'src/app/components/video-web-rtc/video-web-rtc.component';
import { GeneralService } from 'src/app/services/general.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { DocumentsService } from 'src/app/services/documents.service';
@Component({
  selector: 'app-videoconsulta-medico',
  templateUrl: './videoconsulta-medico.component.html',
  styleUrls: ['../../../assets/css/styles.css','./videoconsulta-medico.component.css']
})
export class VideoconsultaMedicoComponent {

  @Input() data: any = {};

  _title = 'Videoconsulta';
  _subtitle = 'Detalle de la consulta';
  archivos_consulta: any = {
    diagnostics: [],
    certificados: [],
    prescriptions: [],
    orders: []
  };
  documentos_consulta: any = [];

  use_id_paciente: any;

  @ViewChild(AgendaMedico2Component) AGENDA_MEDICO: AgendaMedico2Component; //para llamar las funciones contenidas en ese componente
  @ViewChild(VideoWebRtcComponent) WEB_RTC: VideoWebRtcComponent;
  
  _usuario: any = {};  // usuario actual

  consulta: any = {
    fecha_consulta: {}
  };
  btnIniciar: boolean = false;              //habilita el boton de iniciar videoconsulta
  id_de_consulta:string='';



  URL_DOCUMENTS = URL_DOCS;
  ficha_eventos: boolean = false;     //[evento]	
  video_iniciado: boolean = false;    //Maneja si la video-llamada ha sido iniciada
  

  //Anulacion consulta 
  infoConsulta: any = {};

  cancel_reason: string = '';
  anulacionConsulta: Boolean = false;
  pacienteName: string;
  pacienteLastName: string;
  boo_id: any;
  use_id: any;
  data_selected: any;
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private _zoomService: ZoomService,
    private _functions: FunctionsService,
    public scheduleService: ScheduleService,
    private router: Router,
    public generalService: GeneralService,
    private _fichaMedicaServices: FichaMedicaService,
    private _documentServices: DocumentsService,

  ) {   }


  

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();
    localStorage.setItem('zoom_up_med', 'true');

    if ( this._usuario.typ_id != 1 ){
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
    else {
      this.activatedRoute.params.subscribe(parametros => {
        console.log(parametros.idConsulta);
        this.obtenerConsulta(parametros.idConsulta);
        this.id_de_consulta = parametros.idConsulta;
        
      });
      this.horasDisponibles(this._usuario.doc_id);
      this.agendaMedicoDiv = document.getElementById('agenda_medico');  
      // this.observador();
    }
    
    const interval = setInterval(() => {
      this.tiempoPasadoConsulta();
    }, 60000); // 60000 ms = 1 minuto

    // Limpia el intervalo después de 4 minutos (opcional)
    setTimeout(() => {
      clearInterval(interval);
    }, 60000); 
  }

  agendaMedicoDiv: any;

  obtenerConsulta(consulta_id) {
    
    this._zoomService.getConsulta(consulta_id)
      .then(async resultado => {
        // console.log(resultado);
        this.consulta = resultado;
        // this.pacienteNoSePresentaState();
        this.use_id_paciente = await this.consulta.user_id;
        this.consulta.fecha_consulta = this._functions.formatApptDate(new Date(this.consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/')), this.consulta.hora)
        this.tiempoPasadoConsulta();
        this.consulta.dateText = this.consulta.fecha_consulta.text
        this.btnIniciar = true;
        this.ficha_eventos = true;
        this.boo_id = this.consulta.id_consulta;
        this.pacienteName = this.consulta.nombre_paciente.charAt(0).toUpperCase();
        this.pacienteLastName = this.consulta.apellido_paterno_paciente.charAt(0).toUpperCase();
        this._fichaMedicaServices.getListarFichaConsulta(this.consulta)
          .then(results => {
            //console.log(results);
            this.archivos_consulta = results;
          })
          .catch(error => {
            console.log(error);
          });
        this._documentServices.postListaArchivosConsulta(this.consulta.id_consulta, this.use_id_paciente)
          .then(results => {
            // console.log(results);
            this.documentos_consulta = results;

          })
          .catch(error => {
            console.log(error);
          });

      }).catch(error => {
        console.log(error);
      });
  }
    
    
    videoIniciado = false;
    
    
    async iniciarVideoconsulta () {
      this.videoIniciado = true;
      this.btnIniciar = false;
      this.WEB_RTC.joinRoom(this.consulta);
  }

  infoCancelaConsulta() {

    this.infoConsulta = this.consulta;
    // console.log(this.infoConsulta, 'Data consulta');

  }

  async confirmaEliminar() {


    // console.log(this.infoConsulta,);
    this.generalService.modalCargando();

    if (this.infoConsulta.comentario && this.infoConsulta.comentario.length > 3) {


      await this.scheduleService.cancelAppointment(this.boo_id, this._usuario._id, this.infoConsulta.comentario, {}, null)
        .then(result => {
          // console.log(result, 'Comentario y anulacion exitosa');
          this.generalService.modalExitoso('Anulacion de consulta exitosa', '')
        })
        .catch(error => {
          console.log(error, 'Error en la anulacion');
          this.generalService.modalError('Error al anular la consulta', '')
        })
    } else {
      this.generalService.modalError('Error al anular la consulta', 'Ingrese comentario válido')

    }
    this.router.navigate(['/', 'proximasConsultas']);

  }


  options = [false, false, false, false];     // habilitacion de botones

  optionsButton($event){
    this.agendaMedicoDiv.style.display = 'none'
    this.options = [false, false, false, false];
    this.options[$event] = true;
    if ($event == 3){
      this.agendaMedicoDiv.style.display = 'block'
      this.AGENDA_MEDICO.sendToAgendaMedico(this.horas_medico ); // envia la data al componentes
    }
  }



  finalizarVideoconsulta(){
    let params = {
      id_consulta:this.consulta.id_consulta
    }
    var url = "finvideoconsulta/"+this.id_de_consulta;
    this.router.navigate([url]);
    /*Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();

    this._zoomService.postUpdateSheduleEndState(params)
      .then(result => { 
        //console.log(result);
        // Swal.fire({
        //   allowOutsideClick: false,
        //   html:
        //     ' <i class=“far fa-grin fa-3x” style=“color:#171994;font-size: 58px;margin: 20px 40px 10px;“></i> <br><br>' +
        //     ' <h1 style=“font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;” >La consulta fue finalizada de forma exitosa </h1> <br>' +
        //     ' <p style=“font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;” ></p><br>',
        //     showConfirmButton: false
        // })
        Swal.fire({
          html:
            ' <i class="far fa-laugh" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;"></i> <br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > La consulta fue finalizada de forma exitosa</h1><br>',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000
        })
        // this.router.navigate(['/', 'proximasConsultas']);
        // setTimeout(() => window.location.reload(), 0);
      })
      .catch(error => { 
        console.log(error);
        
      })*/
    
  }



//   anularVideoconsultaDoc(){
// console.log('Hola');

//   }

  confirmarAnulacionAppo(){
    this.scheduleService.cancelAppointment(this.data.id_consulta, this._usuario._id, this.cancel_reason,null,null)
    .then(data => {
      Swal.fire({
        allowOutsideClick: false,
        html:
          ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
          ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Su consulta ha sido anulada exitosamente</h1> <br>' +
          ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
          showConfirmButton: false,
      })
      setTimeout(() => window.location.reload(), 2000);
    })
    .catch(error => console.log(error))

  }


  clickHourAgendaMedico($event){
    //console.log($event)
  }

  

  tiempoPasadoConsulta() {
    //this.hora_final = new Date(this.consulta.fecha_consulta.substr(0, 4), (this.consulta.fecha_consulta.substr(5, 2) - 1), this.consulta.fecha_consulta.substr(8, 2), this.consulta.hora.substr(0, 2), this.consulta.hora.substr(3, 2));
    if (this.consulta && this.consulta.id_consulta) {      
      let fecha_actual = new Date();
      ////console.log(fecha_actual);
  
      // console.log(this.consulta.fecha_consulta.date);
      try {
        ////console.log((fecha_actual - this.consulta.fecha_consulta.date) / (1000 * 60));
        if (((fecha_actual.getTime() - this.consulta.fecha_consulta.date.getTime()) / (1000 * 60)) >= 15) {
          this.anulacionConsulta = true;
        } else {
          this.anulacionConsulta = false;
        }
        //console.log(this.asistencia_paciente);
      } catch (e) {
        console.log(e);
      }
    }
  }

  

  horas_medico: any;

  // horas disponibles doctor
  horasDisponibles(doc_id){
    this.scheduleService.getScheduleHoursDisp(doc_id, null, null) 
    .then( data => {    
      this.horas_medico = data;
    })
  }

















  
  // async observador() {
  //   let zoom_up_med = 'true';
        
  //   while (zoom_up_med == 'true') {
  //     zoom_up_med = localStorage.zoom_up_med;

  //     if(zoom_up_med == 'false'){ //se cerro la consulta en el iframe
  //       let leaveUrl =  'finvideoconsulta/'+ this.id_de_consulta;
  //       this.router.navigate([leaveUrl])
  //       //console.log("inactiva");
  //       // window.location.reload();
  //       // setTimeout(() => window.location.reload(), 1000);
        
  //     }
  //     await this.sleep(2000);
  //   }
    
  // }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }


}




  


