<div *ngIf="eventos">

  <div *ngIf="consulta">

    <!-- Toolbar -->
    <div class="content" role="main">


      <div class="container" [ngStyle]="{'height':alto+'px','background':'estilo'}">

        <div class="inbox_msg" style="height: 100%;">

          <div *ngIf="!maximixado" [ngStyle]="{'height':alto+'px', 'width':'80%', 'position':'absolute', 'opacity':'50%', 'z-index':'100', 'cursor':'pointer'}" (click)="verMas()"></div>

          <div class="mesgs">
            <!--  ----------------------------------------------------  -->
            <div class="card-infoX" style="height: 100px;">

              <div class="card-info-item" style="width: 10%;">
                <div *ngIf="tipo_user == 'PACIENTE'" class="img-cirlce-wrapper">
                  <img *ngIf="consul.att_path" class="img-fluid" [src]="URL_DOCUMENTS+consul.att_path" alt="profile-img" title="profile-img">
                  <p *ngIf="!consul.att_path" class="m-0 p-0">{{ doctorName }}{{ doctorLastName }}</p>
                </div>
                <div *ngIf="tipo_user == 'MEDICO'" class="img-cirlce-wrapper">
                  <p class="m-0 p-0">{{ pacienteName }}{{ pacienteLastName }}</p>
                </div>
              </div>

              <div class="card-info-item" style="width: 60%;">
                <p *ngIf="tipo_user == 'PACIENTE'" class="texto-titulo-card">Dr. {{consul.nombre_doctor}} {{consul.apellido_paterno_doctor}} {{consul.apellido_materno_doctor}}</p>
                <p *ngIf="tipo_user == 'MEDICO'" class="texto-titulo-card">Paciente {{consul.nombre_paciente}} {{consul.apellido_paterno_paciente}} {{consul.apellido_materno_paciente}}</p>
                <p class="texto-titulo-card">{{consul.spe_name}}</p>
                <!-- <p class="texto-titulo-card">{{ appointments.date[0] }} {{ appointments.date[1] }}</p> -->
                <p class="texto-titulo-card">{{ consul.fecha_consulta | date:'dd-MM-yyyy'}}</p>
              </div>

              <div class="card-info-item" style="width: 25%; text-align: right;">             
                <p >{{tiempo_restante}} horas restantes</p>
              </div>

              <div class="card-info-item" style="width: 5%;">
                <div class="d-flex no-block" style="z-index: 1000;">
                  <div class="btn-group ml-auto" style="color: #171994;">
                    <a class="icon-options-vertical link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 20px;"></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a *ngIf="!maximixado" class="dropdown-item cursor" data-toggle="modal" (click)="verMas()">Ver más</a>
                      <a *ngIf="maximixado" class="dropdown-item cursor" data-toggle="modal" (click)="verMenos()">Ver menos</a>
                      <a *ngIf="tipo_user == 'MEDICO'" class="dropdown-item cursor" data-toggle="modal" (click)="navegarDetalle()">Ir al detalle de la consulta</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!--  ----------------------------------------------------  -->
            <div class="msg_history" style="height: 360px;">
              <li class="message" *ngFor="let message of messages">
                <div *ngIf="message.tel_cha_det_message_from != tipo_user; then incoming_msg; else outgoing_msg"></div>

                <ng-template #incoming_msg>
                  <div class="incoming_msg">
                    <div class="received_msg">
                      <p class="texto-mensaje">{{ message.tel_cha_message }}</p>
                      <p class="texto-fecha">{{ message.tel_cha_det_date| date:'dd-MM-yyyy h:mm a'}}</p>
                    </div>
                  </div>
                </ng-template>

                <ng-template #outgoing_msg>
                  <div class="outgoing_msg">
                    <div class="sent_msg">
                      <p class="texto-mensaje">{{ message.tel_cha_message }}</p>
                      <p class="texto-fecha">{{ message.tel_cha_det_date| date:'dd-MM-yyyy h:mm a'}}</p>
                    </div>
                  </div>
                </ng-template>
              </li>
            </div>


            <div class="type_msg">

              <form class="write_msg">
                <textarea placeholder="Escribe tu mensaje acá" name="newMessage" [(ngModel)]="newMessage" rows="2" style="width: 95%;"></textarea>
                <button class="msg_send_btn" type="button" (click)="sendMessage()" type="submit">
                  <mat-icon aria-hidden="true">send</mat-icon>
                </button>
              </form>
            </div>

            <br>
          </div>
        </div>

        <div *ngIf="!maximixado" style="width: 95%; margin-top: -60px;z-index: 100;position: relative;">
          <div class="img-cirlce-wrapper-count" style="float: right;">
            <p class="m-0 p-0">{{messages.length}} </p>
          </div>
        </div>



      </div>
    </div>


  </div>

</div>