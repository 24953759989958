<app-title-header [title]='_title' [subtitle]='_subtitle'>
</app-title-header>

<div style="margin-left: 5px;">
  <div class="row">
    <div class="col-md-12 titulobeneficiario">
      Selecciona el día que quieres visualizar
    </div>
  </div>
  <app-agenda-medico-proximas (messageEventHour)='clickHourAgendaMedico($event)'
    (messageEventDay)='clickDayAgendaMedico($event)'>
  </app-agenda-medico-proximas>
</div>

<div class="container-fluid" *ngIf="dia_seleccionado">
  <div class="row">
    <div class="col-md-12 titulobeneficiario">
      Consultas del {{fechaSelected .dayTitle}}
    </div>
  </div>
</div>
<div class="row" *ngIf="dia_seleccionado">
  <div class="col-12">
    <div class="row d-sm-flex">
      <div *ngFor="let appt of appointmentsDay ; let i = index;" class="col-md-12">
        <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
        <div class="card p-0 m-0" [ngClass]="{'border-confirm': appt.confirmFlag, 'border-go-lobby': appt.confirmFlag}">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
            <div class="m-0 p-0 first-line">
              <app-profile-img [img]="appt.img" [doctorName]="appt.nombre_paciente"
                [doctorLastName]="appt.apellido_paterno_paciente"
                [color]="appt.goLobbyFlag ? 'confirm' : appt.confirmFlag ? 'confirm' : 'confirm'">
              </app-profile-img>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ appt.nombre_paciente }} {{ appt.apellido_paterno_paciente }}
                  {{appt.apellido_materno_paciente}}</p>
                <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ appt.fecha | date:
                  'dd-MM-yyyy'}} a las {{appt.consulta.hora}}</p>
                <p class="card-text m-0 p-0 ">{{ appt.spec }} </p>
                <p class="card-text m-0 p-0 "> </p>
              </div>
              <app-popover class="ml-auto mr-2">
                <p class="pointer" (click)="infoCancelaConsulta(i)" data-toggle="modal" data-target="#eliminarPaciente">
                  Anular consulta</p>
                <p class="pointer" (click)="irdetallesPacientes(appt)">Ver detalles de la consulta</p>
              </app-popover>
            </div>
            <br>
            <div class="d-flex align-items-end justify-content-end flex-wrap">
              <button *ngIf="appt.fecha==date3" type="button" class="btn btn-primary mr-auto" (click)="goLobby(appt)">Ir
                a la videoconsulta</button>
              <button *ngIf="appt.fecha!=date3" type="button" class="btn btn-primary mr-auto" (click)="goLobby(appt)"
                disabled>Ir a la videoconsulta</button>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

<!-- Todas las consultas -->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 titulobeneficiario">
      Todas las consultas
    </div>
  </div>
</div>
<!-- next appointments MEDICO-->
<div class="row">
  <div class="col-12">
    <div class="row d-sm-flex">
      <div *ngFor="let appt of auxAppts ; let i = index;" class="col-md-12">
        <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
        <div class="card p-0 m-0" [ngClass]="{'border-confirm': appt.confirmFlag, 'border-go-lobby': appt.confirmFlag}">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
            <div class="m-0 p-0 first-line">
              <app-profile-img [img]="appt.img" [doctorName]="appt.nombre_paciente"
                [doctorLastName]="appt.apellido_paterno_paciente"
                [color]="appt.goLobbyFlag ? 'confirm' : appt.confirmFlag ? 'confirm' : 'confirm'">
              </app-profile-img>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{ appt.nombre_paciente }} {{ appt.apellido_paterno_paciente }}
                  {{appt.apellido_materno_paciente}}</p>
                <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ appt.fecha | date:
                  'dd-MM-yyyy'}} a las {{appt.consulta.hora}}</p>
                <p class="card-text m-0 p-0 ">{{ appt.spec }} </p>
                <p class="card-text m-0 p-0 "> </p>
              </div>
              <app-popover class="ml-auto mr-2">
                <p class="pointer" (click)="infoCancelaConsulta(i)" data-toggle="modal" data-target="#eliminarPaciente">
                  Anular consulta</p>
                <p class="pointer" (click)="irdetallesPacientes(appt)">Ver detalles de la consulta</p>
              </app-popover>
            </div>
            <br>
            <div class="d-flex align-items-end justify-content-end flex-wrap">
              <button *ngIf="appt.fecha==date3" type="button" class="btn btn-primary mr-auto" (click)="goLobby(appt)">
                Ir a la videoconsulta
              </button>
              <button *ngIf="appt.fecha!=date3" type="button" class="btn btn-primary mr-auto" (click)="goLobby(appt)"
                disabled>
                Ir a la videoconsulta
              </button>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

<!-- MODAL ANULAR CONSULTA -->
<div class="modal fade" id="eliminarPaciente" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">Anulación de consulta</p>
        <br>
        <form class="form col-md-12" id="ConsultaCancelarData" autocomplete="off" [formGroup]="consultaCancelarData"
          (ngSubmit)="eliminaConsulta()">
          <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4 border-card-modal">
            <div class="m-0 p-0 first-line">
              <div class="img-cirlce-wrapper">
                <p class="m-0 p-0 text-default">
                  {{ pacienteName }}{{ pacienteLastName }}
                </p>
              </div>
              <div class="ml-3 align-self-center">
                <p class="card-text m-0 p-0 appt-date">{{nombre_paciente}} {{apellido_paterno_paciente}}
                  {{apellido_materno_paciente}}</p>
                <p class="card-text m-0 p-0" style="font-weight: bold">Fecha de la consulta: {{ date }} {{ date2 }}</p>
                <p class="card-text m-0 p-0 ">{{ spec }} - {{ subSpec }}</p>
                <p class="card-text m-0 p-0 "> </p>
              </div>
            </div>
            <br>
          </div>
          <br><br>
          <div class="form-group">
            <textarea class="form-control" placeholder="Motivo de la cancelación*" formControlName="comentario" rows="5"
              id="comentario"></textarea>
          </div>
          <div class="row">
            <div class="col text-danger">
              <p *ngIf="campoNoValido('comentario')"> El motivo de la cancelación obligatorio</p>
            </div>
          </div>
          <br>
          <br>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar
                  formulario</button>
              </div>
              <div class="col-md-6 letrabotonagregar">
                <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-toggle="modal"
                  data-target="#confirmaAnular">Cancelar consulta</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- MODAL CONFIRMA ANULAR CONSULTA -->
<div *ngIf="!consultaCancelarData.invalid" class="modal fade" id="confirmaAnular" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">¿Esta seguro que desea anular la consulta?</p>
        <br>
        <br><br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                data-dismiss="modal">Cerrar</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" (click)="confirmaEliminar()" class="btn btn-outline-primary btn-lg botonModalAgregar"
                data-dismiss="modal" data-toggle="modal" data-target="#exitoAnulacion">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL ELIMINACION EXITOSA CONSULTA -->
<div class="modal fade" id="exitoAnulacion" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <P class="tituloModalConfirma"> <mat-icon style="font-size: 58px">mood</mat-icon> </P>
        <br>
        <p class="tituloModalConfirma">La consulta ha sido anulada exitosamente</p>
        <br>
        <br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 letrabotonagregar">
              <button type="buton" (click)="reload()" data-dismiss="modal"
                class="btn btn-outline-primary btn-lg botonModalConfirmar">Aceptar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>