import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
    public _generalServices: GeneralService
  ) { }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getScheduleHours        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Consulta todas las horas disponibles del doctor
  async getZoomCredentials() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postGetZoomCredentials', JSON.stringify({}), httpOptions).toPromise()



      return data
    }
    catch (error) {
      this._errors.requestError(error);
      throw error;
    }
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       getListarConsultasAcompanante      /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async getListarConsultasAcompanante() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsultasAcompanante', JSON.stringify({}), httpOptions).toPromise()
      return data;
    }
    catch (error) {
      //this._errors.requestError(error)
      throw error;
    }
  }



  async getConsulta(id_consulta) {
    // //console.log("Consulta: "+id_consulta);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsulta', JSON.stringify({ id_consulta: id_consulta }), httpOptions).toPromise()
      // //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async getConsultaMed(med_con_id) {
    // //console.log("Consulta: "+med_con_id);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getListarConsulta', JSON.stringify({ med_con_id: med_con_id }), httpOptions).toPromise()
      // //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }



  async postListarZoomAccounts() {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarZoomUsers', JSON.stringify({}), httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }


  async postListarZoomAccountsUnused() {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarZoomUsersUnused', JSON.stringify({}), httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postCreateZoomUser(email, nombre, apellido) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      email: email,
      nombre: nombre,
      apellido: apellido
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postCreateZoomUser', JSON.stringify(params), httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postDeleteZoomUser(email) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      email: email,
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postDeleteZoomUser', JSON.stringify(params), httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postAssignZoomAccount(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };


    try {
      let data = await this.http.post(URL_SERVICIOS + '/postAssignZoomAccount', JSON.stringify(params), httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postDeAssignZoomAccount(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };


    try {
      let data = await this.http.post(URL_SERVICIOS + '/postDeAssignZoomAccount', JSON.stringify(params), httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////Sala de Zoom///////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postUpdateStartLobby(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let dispositivo = this._generalServices.getDevice();
    let data_post: any = params;
    data_post.device = dispositivo.type;
    //console.log(data_post);
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postUpdateStartLobby', data_post, httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postUpdateStartMeeting(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let dispositivo = this._generalServices.getDevice();
    let data_post: any = params;
    data_post.device = dispositivo.type;
    data_post.connection = dispositivo.connection;

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postUpdateStartMeeting', data_post, httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postUpdateEndMeeting(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let dispositivo = this._generalServices.getDevice();
    let data_post: any = params;
    data_post.device = dispositivo.type;
    data_post.connection = dispositivo.connection;
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postUpdateEndMeeting', params, httpOptions).toPromise()
      //console.log(data);
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }
  async postUpdateSheduleEndState(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postUpdateSheduleEndState', params, httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }


  async postUpdateSheduleNoPatient(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };


    try {
      let data = await this.http.post(URL_SERVICIOS + '/postUpdateSheduleNoPatient', params, httpOptions).toPromise()
      ////console.log(data);

      return data;
    }
    catch (error) {
      //this._errors.requestError(error)
      throw error;
    }
  }

  async postSheduleNoPatientState(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };


    try {
      let data = await this.http.post(URL_SERVICIOS + '/postSheduleNoPatientState', params, httpOptions).toPromise()
      //console.log(data);

      return data;
    }
    catch (error) {
      //this._errors.requestError(error)
      throw error;
    }
  }

  async getMediaList(id_consulta) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      id_consulta: id_consulta,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getMediaList', params, httpOptions).toPromise()

      return data;
    }
    catch (error) {
      //this._errors.requestError(error)
      throw error;
    }
  }


}
