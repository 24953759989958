import { Component } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: [ './progress.component.css' ]
})
export class ProgressComponent  {

  progreso1:number = 25;
  progreso2:number = 45;

 get getPorcentaje1() {
    return `${ this.progreso1 }%`;
  }

  get getPorcentaje2() {
    return `${ this.progreso2 }%`;
  }
/* forma 1 de mostrar el valor
  cambioValorHijo( valor:number ){ //le paso el valor calculado en el componente incrementador y lo muestro en e log
    //console.log("hey cambio valor"+valor);
    this.progreso1 = valor
  }*/
}
