<!-- Login modal error - style you can find in recovery.component.css -->
<!-- ============================================================== -->
<div class="modal fade hide" id="loginErrorModal" tabindex="-1" role="dialog" aria-labelledby="loginErrorModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 p-0">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/face-sad.svg" alt="Responsive image">
                    <div class="m-0 px-1 px-sm-5">
                        <p class="m-0 p-0">Lo sentimos, la información que ingresaste es incorrecta.</p>
                        <br>
                        <p class="m-0 p-0">Por favor, vuelve a intentar.</p>
                    </div>
                    <button class="btn" type="button" data-dismiss="modal" aria-label="Close">Volver a intentar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Login modal error -->