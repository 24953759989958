import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http'; 

import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

import { CusId } from '../interfaces/cus-id.interface';
import { Alergia } from '../interfaces/alergia.interface';
import { Antecedentesfamiliares } from '../interfaces/antecedentes_familiares.interface';
import { Hospitalizaciones, Patologias, Medicamentos } from '../interfaces/hospitalizaciones.interface';

@Injectable({
    providedIn: 'root'
  })
export class AntecedentesMedicosService {
  
    constructor(
      private http: HttpClient,
      private router: Router,
      public _errors: ErrorsService,
    ) {}

  traeSeveridad() {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/severidad', ({valor: 35}), httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  traeBen_Id( usuario: CusId) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/ben_id', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
    }
  traeRelacion() {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/relacion', ({valor: 35}), httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  creaAlergia( usuario: Alergia) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAlergia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  eliminaAlergia( usuario: Alergia) {
    //console.log("++++++++++++++++++ METODO ELIMINA ALERGOIA++++++++++++++++++");
    //console.log(usuario);
    
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarAlergia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  modificaAlergia( usuario: Alergia) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAlergia', usuario, httpOptions )
        return data
    } catch (error) {
        this._errors.requestError(error)
    }
  }

  postListarAntecedentes( usuario: CusId) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postListarAntecedentes', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  creaAntecedenteFamiliar( usuario: Antecedentesfamiliares) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAnteFamiliar', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  eliminaAntecedenteFamiliar( usuario: Antecedentesfamiliares) {
    //console.log(usuario);
    
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarAnteFam', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  modificaAntecedenteFamiliar( usuario: Antecedentesfamiliares) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAlergia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  creaHospitalizacion( usuario: Hospitalizaciones) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearHospitalizacion', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  eliminaHospitalizacion( usuario: Hospitalizaciones) {
    //console.log(usuario);
    
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarHospi', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  modificaHospitalizacion( usuario: Hospitalizaciones) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAlergia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  creaPatologia( usuario: Patologias) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearPatologia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  eliminaPatologia( usuario: Patologias) {
    
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarPatologia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  modificaPatologia( usuario: Patologias) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAlergia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  creaMedicamento( usuario: Medicamentos) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearMedicamento', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  eliminaMedicamento( usuario: Medicamentos) {

    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarMedicamento', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  modificaMedicamento( usuario: Medicamentos) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
      };
    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearAlergia', usuario, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  postListConHabits( ben_id) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
    };
    
    let params = {
      ben_id: ben_id
    };

    try {
        let data = this.http.post( URL_SERVICIOS + '/postListConHabits', params, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  postListConFrecuency( ) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
    };
    
    let params = {
    };

    try {
        let data = this.http.post( URL_SERVICIOS + '/postListConFrecuency', params, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  
  postCrearCirugia( cirugia) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
    };

    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearCirugia', cirugia, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
  postCrearHabitos( habitos) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
    };

    let params = {
      habitos: habitos
    };

    try {
        let data = this.http.post( URL_SERVICIOS + '/postCrearHabitos', params, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  postEliminarHabito( use_ben_fil_hab_id,ben_id) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
    };

    let params = {
      use_ben_fil_hab_id: use_ben_fil_hab_id,
      ben_id: ben_id
    };

    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarHabito', params, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }

  postEliminarCirugia( ben_fil_sur_id,ben_id) {
    const httpOptions = {
        headers: new HttpHeaders({
          'authorization':  sessionStorage.getItem("token")//le paso el token para el backend
        })
    };

    let params = {
      ben_fil_sur_id: ben_fil_sur_id,
      ben_id: ben_id
    };

    try {
        let data = this.http.post( URL_SERVICIOS + '/postEliminarCirugia', params, httpOptions )
        return data
      }
      catch (error) {
        this._errors.requestError(error)
      }
  }
}