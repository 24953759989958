import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomService } from 'src/app/services/zoom.service';
import { FunctionsService } from 'src/app/services/functions.service';
import Swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';

import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { VideoWebRtcComponent } from 'src/app/components/video-web-rtc/video-web-rtc.component';
import { DocumentsConsultComponent } from 'src/app/components/documents-consult/documents-consult.component';
import { DocumentsComponent } from 'src/app/components/documents/documents.component';

@Component({
  selector: 'app-videoconsulta-paciente',
  templateUrl: './videoconsulta-paciente.component.html',
  styleUrls: ['./videoconsulta-paciente.component.css']
})
export class VideoconsultaPacienteComponent {
  @ViewChild(DocumentsConsultComponent) documentsConsultComponent: DocumentsConsultComponent;
  @ViewChild(DocumentsComponent) documentsComponent: DocumentsComponent;



  _title = 'Mi videoconsulta';
  _subtitle = '';
  zoomVideo: any;

  @ViewChild(VideoWebRtcComponent) WEB_RTC: VideoWebRtcComponent;

  _usuario: any = {};  // usuario actual


  chat: boolean = false;

  consulta: any = {};
  btnIniciar: boolean = false;                //habilita el boton de iniciar videoconsulta
  
  parametros: any;
  datos_consulta: any = {};
  data_cargada = false;
  videoIniciado: any = false;
  device: any ={};

  public info_usuario = this.fb.group({
    use_id: ['', [Validators.required]],
    con_ana_page: ['', [Validators.required]],
    con_ana_connection_type: ['', [Validators.required]],
    con_ana_connection_mode: ['', [Validators.required]]
  });
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public _usuarioService: UsuarioService,
    private _zoomService: ZoomService,
    private _functions: FunctionsService,
    private _generalServices: GeneralService,
    private router: Router, 
  ) {   }



  ngOnInit(): void {
    this.device = this._generalServices.getDevice();
    this._usuario = new EncriptacionService().getUserInfo();
    //console.log(this._usuario,'this._usuario');
    
    sessionStorage.setItem('zoom_ud_video', 'true');
    if ( this._usuario.typ_id != 2 ){
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
    else {
      this.activatedRoute.params.subscribe(parametros => {
        this.parametros = parametros;
        this.consulta = {
          med_con_id: parametros.idMedCon
        } 
        this.obtenerConsulta(parametros.idConsulta);  
      });
    }

    var ua = navigator.userAgent;

    if(/iPhone|iPad|iPod/i.test(ua))
    {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
       this.info_usuario.controls['use_id'].setValue(this._usuario._id);
       this.info_usuario.controls['con_ana_page'].setValue('VideoConsulta');
       this.info_usuario.controls['con_ana_connection_type'].setValue('IOS');
       this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
       //console.log(this.info_usuario.value);
       this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe( (data:any) => {
          //console.log("registro almacenado");
          
        })
       
    }
    else if(/Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
    {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
       this.info_usuario.controls['use_id'].setValue(this._usuario._id);
       this.info_usuario.controls['con_ana_page'].setValue('VideoConsulta');
       this.info_usuario.controls['con_ana_connection_type'].setValue('Android');
       this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
       //console.log(this.info_usuario.value);
       this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe( (data:any) => {
          //console.log("registro almacenado");
          
        })
    }
    else
    {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
       this.info_usuario.controls['use_id'].setValue(this._usuario._id);
       this.info_usuario.controls['con_ana_page'].setValue('VideoConsulta');
       this.info_usuario.controls['con_ana_connection_type'].setValue('Desktop');
       this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
       //console.log(this.info_usuario.value);
       this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe( (data:any) => {
          //console.log("registro almacenado");
          
        })
    }
  }

  


  obtenerConsulta(consulta_id) { 
    this._zoomService.getConsulta(consulta_id)
      .then(resultado => {
        // console.log(resultado);
        
        this.datos_consulta = resultado;  
        this.datos_consulta.fecha_consulta = this._functions.formatApptDate(new Date(this.datos_consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/')), this.datos_consulta.hora)
        this.datos_consulta.dateText = this.datos_consulta.fecha_consulta.text;
        this.datos_consulta.img = '';
        this.data_cargada = true;
        this.iniciarConsulta(resultado);
      }).catch(error => {
        console.log(error);
      });
  }

  async iniciarConsulta(resultado) {
    await this.sleep(2000);
    this.videoIniciado = true;
    
    //console.log(resultado)    
    this.WEB_RTC.joinRoom(resultado);
  }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }


  options = [false, false];     // habilitacion de botones
  options2 = [false, false];     // habilitacion de botones

  optionsButton($event){
    this.options = [false, false];
    this.options2 = [false, false];
    this.options[$event] = true;
    if(this.options[0]){
      this.options2 = [true, false]
    }

  }
  optionsButton2($event){
    this.options2 = [false, false];
    this.options2[$event] = true;

  }
  updateDocuments() {
    if (this.documentsComponent) {
      this.documentsComponent.update_doc();
    }
  }
  updateDocuments2() {
    if (this.documentsConsultComponent) {
      this.documentsConsultComponent.update_doc();
    }
  }
  

}
  



  


