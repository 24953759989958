<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->


<!-- <div style="text-align: center; font-family: Mulish; font-style: normal;">
  <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">Estás a punto de salir de tu consulta virtual</h1>
  <br>
  <p style="color: #171994; font-size: 18px; line-height: 24px;">Para volver a tu video consulta haz click en <strong>No quiero salir,</strong> de lo contrario haz click en <strong>Salir de mi consulta.</strong></p>
</div> -->


<aside class="left-sidebar" id="sidebar-angular">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
    <!-- Sidebar navigation-->
    <nav class="sidebar-nav">

      <ul class="menu-collapse">
        <li>
          <!-- <button #cerrarSideBar type="button" class="btn" data-toggle="button" data-target="#close-menu">
            aux trigger for close sidebar
          </button> -->
          <span class="hide-menu w-100 d-flex">
            <span class="btn-menu">Menú</span>
            <span #closemenu class="sidebartoggler close-bar" id="toggle">
              <i class="far fa-times"></i>
            </span>
         <li class="icon-bars d-none">
          <a style="padding-right: 0;" class="sidebartoggler" href="javascript:void(0)">
            <i class="fas fa-bars"></i>
          </a>
        </li> </span>
        </li>
        
      </ul>
      <div class="profile-mobile">
        <img class="img-fluid" src="./assets/images/MEDIS.LOGO.RRSS-06.png" width="36" alt="imagen de perfil">
        <span class="user-name">{{ _usuario.nombre }}</span>
      </div>

      <ul id="sidebarnav">

        <!-- Inicio -->
        <li class="mt-4 mb-4">
          <a class="waves-effect waves-dark" (click)="ruta('dashboard')" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-home"></i>
            <span class="hide-menu ml-3">Inicio</span>
          </a>
        </li>

        <!-- ----------------------------------------- MEDICO (1) --------------------------------------->
        <!-- Mi perfil MEDICO -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 1)">
          <a class="has-arrow waves-effect waves-dark"
            [ngClass]="{'active': antecedentesLink.isActive || disponibilidadHorariosLink.isActive}"
            aria-expanded="false">
            <i class="fas fa-user"></i>
            <span class="hide-menu ml-3">Mi perfil</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a routerLink="/profile" routerLinkActive="active" #antecedentesLink="routerLinkActive">Mis
                antecedentes</a></li>
            <li><a routerLink="/rates" routerLinkActive="active" #tarifasLink="routerLinkActive">Mis tarifas</a></li>
            <li><a routerLink="/disponibilidad-horarios" routerLinkActive="active"
                #disponibilidadHorariosLink="routerLinkActive">Disponibilidad de horarios</a></li>
          </ul>
        </li>

        <!-- Mi Agenda MEDICO -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 1)">
          <a class="has-arrow waves-effect waves-dark"
            [ngClass]="{ 'active': verAgendaMedLink.isActive || historialMedLink.isActive || confAgendaMedLink.isActive}"
            aria-expanded="false">
            <i class="fas fa-calendar-alt"></i>
            <span class="hide-menu ml-3">Mi agenda</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5 pointer">
            <li><a routerLink="/proximasConsultas" routerLinkActive="active" #verAgendaMedLink="routerLinkActive">Mis
                próximas consultas</a></li>
            <li><a routerLink="/configurar-agenda" routerLinkActive="active"
                #confAgendaMedLink="routerLinkActive">Configurar agenda</a></li>
            <li><a routerLink="/historialConsultasDoc" routerLinkActive="active" #historialMedLink="routerLinkActive">Mi
                historial de consultas</a></li>
          </ul>
        </li>

        <!-- Mi videoconsulta MEDICO -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 1)">
          <a class="waves-effect waves-dark" routerLink="/meetingRoom" routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-video"></i>
            <span class="hide-menu ml-3">Videoconsulta</span>
          </a>
        </li>
        <!-- routerLink="/meetingRoom"  -->

        <!-- ----------------------------------------- PACIENTE (2) --------------------------------------->
        <!-- Mi Perfil PACIENTE-->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 2)">
          <a class="has-arrow waves-effect waves-dark"
            [ngClass]="{'active': antecedentesLink.isActive || antecedentesMedLink.isActive}" aria-expanded="false">
            <i class="fas fa-user"></i>
            <span class="hide-menu ml-3">Mi Perfil</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a (click)="ruta('profile')" routerLinkActive="active" #antecedentesLink="routerLinkActive">Mis
                antecedentes</a></li>
            <li><a (click)="ruta('mis-antecedentes')" routerLinkActive="active"
                #antecedentesMedLink="routerLinkActive">Mis antecedentes médicos</a></li>
            <li><a (click)="ruta('planes')" routerLinkActive="active" #beneficiariosLink="routerLinkActive">Mis planes y
                convenios</a></li>
            <li><a (click)="ruta('familyGroup')" routerLinkActive="active" #beneficiariosLink="routerLinkActive">Mis
                beneficiarios</a></li>
          </ul>
        </li>

        <!-- Mi Agenda PACIENTE-->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 2)">
          <a [ngClass]="{'active': reservaLink.isActive || proximasConsultasLink.isActive || historialConsultasLink.isActive}"
            class="has-arrow waves-effect waves-dark" aria-expanded="false">
            <i class="fas fa-calendar-alt"></i>
            <span class="hide-menu ml-3">Mi agenda</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a (click)="ruta('reserva-hora')" routerLinkActive="active" #reservaLink="routerLinkActive">Reserva tu
                hora</a></li>
            <li><a (click)="ruta('mis-proximas-consultas')" routerLinkActive="active"
                #proximasConsultasLink="routerLinkActive">Mis próximas consultas</a></li>
            <li><a (click)="ruta('mi-historial-consulta')" routerLinkActive="active"
                #historialConsultasLink="routerLinkActive">Mi historial de consultas</a></li>
            <li><a (click)="ruta('consultas-pendientes-pago')" routerLinkActive="active"
                #historialConsultasLink="routerLinkActive">Mis reservas pendientes</a></li>

          </ul>
        </li>
        <!-- Mi videoconsulta PACIENTE-->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 2)">
          <a class="waves-effect waves-dark" (click)="ruta('meetingRoom')" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-video"></i>
            <span class="hide-menu ml-3">Videoconsulta</span>
          </a>
          <!-- <ul aria-expanded="false" class="collapse ml-5">
            <li><a class="waves-effect waves-dark" routerLink="/meetingRoom" routerLinkActive="active" aria-expanded="false">Entrar a sala de espera</a></li>
          </ul> -->
        </li>
        <!-- Mis documentos PACIENTE-->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 2)">
          <a [ngClass]="{ 'active': archivosAdjLink.isActive || archivosConLink.isActive }"
            class="has-arrow waves-effect waves-dark" aria-expanded="false">
            <i class="fas fa-book-medical"></i>
            <span class="hide-menu ml-3">Mis documentos</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a (click)="ruta('documents')" routerLinkActive="active" #archivosAdjLink="routerLinkActive">Ver y
                adjuntar</a></li>
            <li><a (click)="ruta('documents-consult')" routerLinkActive="active"
                #archivosConLink="routerLinkActive">Recetas y otros</a></li>
          </ul>
        </li>

        <!-- ----------------------------------------- CONTACT CENTER (7) y COORDINADOR MEDICO (8) --------------------------------------->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7) || (_usuario.typ_id == 8)">
          <a class="has-arrow waves-effect waves-dark" aria-expanded="false">
            <i class="fas fa-user"></i>
            <span class="hide-menu ml-3">Mi perfil</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a routerLink="/profile" routerLinkActive="active" #antecedentesLink="routerLinkActive">Mis datos</a>
            </li>
          </ul>
        </li>
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7) || (_usuario.typ_id == 8)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/estado_consulta" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-search-plus"></i>
            <span class="hide-menu ml-3">Estado de consultas</span>
          </a>
        </li>


        <!-- ----------------------------------------- CONTACT CENTER (7) --------------------------------------->
        <!-- Buscador de paciente o medico -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/buscador" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-search"></i>
            <span class="hide-menu ml-3">Buscador</span>
          </a>
        </li>
        <!-- Reservar horas Pacientes CC -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/reservar-hora-contact" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-calendar-alt"></i>
            <span class="hide-menu ml-3">Reserva tu hora paciente</span>
          </a>
        </li>
        <!-- Ver Horas Pacientes CC -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/horas-contact" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-user-clock"></i>
            <span class="hide-menu ml-3">Ver Horas paciente</span>
          </a>
        </li>
        <!-- Editar Hora Pacientes por ID CC -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/hora-contact-id" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-user-clock"></i>
            <span class="hide-menu ml-3">Hora por ID</span>
          </a>
        </li>
        <!-- Agenda de los medicos CC -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 7)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/agenda-medicos" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-user-nurse"></i>
            <span class="hide-menu ml-3">Agenda médicos</span>
          </a>
        </li>
        <!-- ----------------------------------------- COORDINADOR MEDICO (8) --------------------------------------->
        <!-- Administrador -->
        <!-- Médicos CM -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 8)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/medicos" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-mobile-alt"></i>
            <span class="hide-menu ml-3">Médicos</span>
          </a>
        </li>
        <!-- Agenda de los medicos CM -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 8)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/agenda-medicos" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-user-nurse"></i>
            <span class="hide-menu ml-3">Agenda médicos</span>
          </a>
        </li>
        <!-- Listado de consultas -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 8)">
          <a class="has-arrow waves-effect waves-dark" routerLink="/listado-consultas" routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-list-ul"></i>
            <span class="hide-menu ml-3">Listado de consultas</span>
          </a>
        </li>

        <!-- ----------------------------------------- SOLO MEDICO Y PACIENTE --------------------------------------->
        <!-- Chat con tu médico -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 2)">
          <a class="waves-effect waves-dark" routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-comment-medical"></i>
            <span class="hide-menu ml-3" (click)="ruta('chat')" routerLinkActive="active">Chat con tu médico</span>
          </a>
        </li>
        <!-- Chat con tu paciente -->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 1)">
          <a class="waves-effect waves-dark" routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-comment-medical"></i>
            <span class="hide-menu ml-3" (click)="ruta('chat')" routerLinkActive="active">Chat con tu paciente</span>
          </a>
        </li>
        <!-- Licencias médicas -->
        <li class="mt-4 mb-4"
          *ngIf="(_usuario.typ_id == 1 && _usuario.doc_lme == true) || _usuario.typ_id == 7 || _usuario.typ_id == 8 ">
          <a class="waves-effect waves-dark" routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-hospital"></i>
            <span class="hide-menu ml-3" (click)="ruta('licencias-medicas')" routerLinkActive="active">Licencias
              médicas</span>
          </a>
        </li>

        <!--------------------------- TARIFAS Y DESCUENTOS COORDINADOR MEDICO-------------------------->
        
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 8)">
          <a [ngClass]="{ 'active': tarifasCoordinadorLink.isActive || codigoDescuentoLink.isActive }"
            class="has-arrow waves-effect waves-dark" aria-expanded="false">
            <i class="far fa-usd-circle"></i>
            <span class="hide-menu ml-3">Tarifas y Descuentos</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a (click)="ruta('coordinatorRates')" routerLinkActive="active"
                #tarifasCoordinadorLink="routerLinkActive">Tarifas</a></li>
            <li><a (click)="ruta('discountCodes')" routerLinkActive="active"
                #codigoDescuentoLink="routerLinkActive">Codigos de Descuento</a></li>
          </ul>
        </li>

        <!-- Administrador-->
        <li class="mt-4 mb-4" *ngIf="(_usuario.typ_id == 12)">
          <a [ngClass]="{'active': planesLink.isActive || usersLink.isActive}"
            class="has-arrow waves-effect waves-dark" aria-expanded="false">
            <i class="fas fa-cogs"></i>
            <span class="hide-menu ml-3">Administrador</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5">
            <li><a (click)="ruta('admin-planes')" routerLinkActive="active" #planesLink="routerLinkActive">Clientes y planes</a></li>
            <li><a (click)="ruta('admin-users')" routerLinkActive="active" #usersLink="routerLinkActive">Usuarios</a></li>
          </ul>
        </li>
        <!-- ----------------------------------------- GENERALES SIN PAGINA --------------------------------------->
        <!-- Mesa de ayuda -->
        <li class="mt-4 mb-4">
          <a class="waves-effect waves-dark" routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-phone-alt"></i>
            <span class="hide-menu ml-3" (click)="ruta('mesaAyuda')" routerLinkActive="active">Mesa de ayuda</span>
          </a>
        </li>
        <!--<li class="mt-4 mb-4">
          <a
            class="has-arrow waves-effect waves-dark"
            routerLinkActive="active"
            aria-expanded="false">
            <i class="fas fa-phone-alt"></i>
            <span class="hide-menu ml-3">Mesa de ayuda</span>
          </a>
          <ul aria-expanded="false" class="collapse ml-5"> mesaAyuda
            <li><a href="javascript:void()" routerLinkActive="active">Preguntas frecuentes</a></li>
          </ul>
        </li>-->
        <!-- Cerrar sesión MOVIL -->
        <li class="close-sesion mt-4 mb-4">
          <a class="waves-effect waves-dark pointer" data-toggle="modal" data-target="#cambiacontrasena"
            routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-key"></i>
            <span class="hide-menu ml-3">Cambiar contraseña</span>
          </a>
        </li>
        <li class="close-sesion mt-4 mb-4">
          <a class="waves-effect waves-dark" routerLink="/login" routerLinkActive="active" aria-expanded="false">
            <i class="fas fa-sign-out-alt"></i>
            <span class="hide-menu ml-3">Cerrar sesión</span>
          </a>
        </li>
      </ul>

    </nav>
    <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<div class="modal fade" id="cambiacontrasena" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <p class="tituloModal">Cambiar contraseña</p>
        <br>
        <br><br><br>
        <form class="form col-md-12" id="CambiaPass" autocomplete="off" [formGroup]="cambiaPass"
          (ngSubmit)="cambiaPassword()">
          <br>
          <div class="form-group row signup-input-wrapper">
            <div class="col-md-12">
              <!-- <input class="form-control camposModalAgregar" type="password" placeholder="Contraseña actual*"
                formControlName="contrasena_actual" id="contrasena_actual"> -->
            </div>
            <div class="col-12 m-0 signup-custom-input">
                                    <input class="form-control" type="text" name="rut_valida" id="rut"
                                        formControlName="contrasena_actual" placeholder="Contraseña actual*" >
                                </div>
          </div>
          <br>
          <div class="form-group row">
            <div class="col-md-12">
              <input class="form-control camposModalAgregar" type="password" placeholder="Nueva contraseña*"
                formControlName="contrasena_nueva" (input)="checkPass1()" id="contrasena_nueva">
            </div>
          </div>
          <div class="password-desc">
            <p class="{{ passLength === 0 && !formSubmitted ?
                      'default' :
                      passMinLength ?
                      'ok' :
                      'nok' }}">
              <i class="{{ passLength === 0 && !formSubmitted ?
                          'fal fa-circle' :
                          passMinLength ?
                          'fas fa-check-circle' :
                          'fas fa-times-circle' }}"></i>
              Debe contener al menos 8 caracteres
            </p>
            <p class="{{ passLength === 0 && !formSubmitted ?
                      'default' :
                      passCapital ?
                      'ok' :
                      'nok' }}">
              <i class="{{ passLength === 0 && !formSubmitted ?
                          'fal fa-circle' :
                          passCapital ?
                          'fas fa-check-circle' :
                          'fas fa-times-circle' }}"></i>
              Debe contener al menos 1 mayúscula
            </p>
            <p class="{{ passLength === 0 && !formSubmitted ?
                      'default' :
                      passLetter ?
                      'ok' :
                      'nok' }}">
              <i class="{{ passLength === 0 && !formSubmitted ?
                          'fal fa-circle' :
                          passLetter ?
                          'fas fa-check-circle' :
                          'fas fa-times-circle' }}"></i>
              Debe contener al menos 1 minúscula
            </p>
            <p class="{{ passLength === 0 && !formSubmitted ?
                      'default' :
                      passNumber ?
                      'ok' :
                      'nok' }}">
              <i class="{{ passLength === 0 && !formSubmitted ?
                          'fal fa-circle' :
                          passNumber ?
                          'fas fa-check-circle' :
                          'fas fa-times-circle' }}"></i>
              Debe contener al menos 1 numero
            </p>
          </div>
          <br>
          <div class="form-group row">
            <div class="col-md-12">
              <input class="form-control camposModalAgregar" type="password" formControlName="contrasena_nueva_2"
                placeholder="Repite tu contraseña nueva*" id="contrasena_nueva_2">
            </div>
          </div>
          <p *ngIf="cambiaPass.hasError('noEsIgual')" class="mb-0 pb-0 mt-1 field-error">
            <i class="fas fa-times-circle"></i>Debes ingresar la misma contraseña
            del campo anterior.
          </p>
          <!--<div class="row">
                  <div class="col text-danger">
                      <p *ngIf="campoNoValidoPassword('contrasena_nueva_2')"> Las contraseñas deben ser iguales</p>
                  </div>
              </div>-->
          <br>
          <br>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                  data-dismiss="modal">Cancelar</button>
              </div>
              <div class="col-md-6 letrabotonagregar">
                <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar">Guarda cambios</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="modalFinLlamada2" aria-labelledby="exampleModalLabel" aria-hidden="true" #modalFinLlamada2>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <!-- <p class="tituloModal">Cambiar contraseña</p>
          <br> -->
        <div style="text-align: center; font-family: Mulish; font-style: normal;">
          <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">Estás a
            punto de salir de tu consulta virtual</h1>
          <br>
          <p style="color: #171994; font-size: 18px; line-height: 24px;">Para volver a tu video consulta haz click en
            <strong>No quiero salir,</strong> de lo contrario haz click en <strong>Salir de mi consulta.</strong></p>
        </div>
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-md-6">
                      <button type="button" class="btn-swal-cancel" style="text-align: center;" data-dismiss="modal">No quiero salir</button>
                  </div>
                  <div class="col-md-6">
                      <button type="submit" class="btn-swal-confirm" (click)="navegar()">Salir de mi consulta</button>
                  </div> -->

            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" (click)="navegar()">Salir de
                mi consulta</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-dismiss="modal"
                style="font-size: 16px;line-height: 24px;">No quiero salir</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<button #modalFinLlamadaSide type="button" class="btn d-none" data-toggle="modal" data-target="#modalFinLlamada2">
  aux trigger for login loading modal
</button>