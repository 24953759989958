import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ZoomService } from 'src/app/services/zoom.service';
import { AgendaMedico2Component } from '../agenda-medico2/agenda-medico2.component';
import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-modificar-consulta',
  templateUrl: './modificar-consulta.component.html',
  styleUrls: ['./../../../assets/css/styles.css', './modificar-consulta.component.css']
})
export class ModificarConsultaComponent implements OnInit {
  hoursDay: any = {};       // Array de bloques de horas 
  daySelected: any = {};    //dia seleccionado
  doctorSelected: any = {};
  hourSelected: any = {};
  subtitle: string;

  @ViewChild(AgendaMedico2Component) AGENDA_MEDICO: AgendaMedico2Component; //para llamar las funciones contenidas en ese componente

  pacienteName: string;
  pacienteLastName: string;
  doctorName: string;
  doctorLastName: string;
  date: any = [];
  boo_id: any;
  use_id: any;
  cancel_reason: any;
  data_selected: any;
  usuario: string;
  typ_id: string;

  ben_id: string;
  use_typ_name: string;

  datos_paciente: any;

  datos_consulta: any = {};

  doc_id: string;

  hora_seleccionada: any = {};

  fecha_nueva_consulta: string;
  hora_nueva_consulta: string;

  public formSubmitted = false;
  public seleccion: boolean = false;

  public nuevaHora = this.fb.group({
    fecha: ['', [Validators.required]],
    hora: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder,
    private router: Router,
    public _scheduleService: ScheduleService,
    private activatedRoute: ActivatedRoute,
    private generalService: GeneralService,
    private _zoomService: ZoomService) {

    this.activatedRoute.params.subscribe(parametros => {
      this.datos_paciente = parametros;

      //console.log( parametros);
    });
    this._zoomService.getConsultaMed(this.datos_paciente.med_con_id)
      .then(resultado => {
        //console.log(resultado);
        this.datos_consulta = resultado;
        this.pacienteName = this.datos_consulta.nombre_paciente.charAt(0).toUpperCase();
        this.pacienteLastName = this.datos_consulta.apellido_paterno_paciente.charAt(0).toUpperCase();
        this.doctorName = this.datos_consulta.nombre_doctor.charAt(0).toUpperCase();
        this.doctorLastName = this.datos_consulta.apellido_paterno_doctor.charAt(0).toUpperCase();
        this.doc_id = this.datos_consulta.doc_id;
        const hour = this.datos_consulta.hora.substr(0, 2);
        const minute = this.datos_consulta.hora.substr(3, 2);
        let apptDate = new Date(this.datos_consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/'));
        apptDate.setHours(hour);
        apptDate.setMinutes(minute);
        const now = new Date();
        const formatedApptDate = this.formatApptDate(apptDate, now);
        const msToAppt = this.timeToX(apptDate, now);
        const minToAppt = this.msToMin(msToAppt);
        this.date = formatedApptDate;
        //console.log(this.datos_consulta);

        this.horasDisponibles(this.doc_id);

      })
  }

  ngOnInit() {
    this.ben_id = new EncriptacionService().getUserInfo().ben_id;
    this.use_typ_name = new EncriptacionService().getUserInfo().typ_name;
    //console.log(this.ben_id);
    //console.log(this.use_typ_name);

  }

  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Día ' + returnDate[0];
    }
    if (minToAppt < 0) {
      return [];
    }
    return returnDate;
  }
  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  horasDisponibles(doc_id) {
    this._scheduleService.getScheduleHoursDisp(doc_id, null, null)
      .then(data => {
        // //console.log(data);

        this.doctorSelected.hours_of_month = data;
        this.AGENDA_MEDICO.sendToAgendaMedico(this.doctorSelected.hours_of_month); // envia la data al componentes
      })
  }

  clickHourAgendaMedico(data) {
    //console.log(data);
    this.hora_seleccionada = data;
    this.fecha_nueva_consulta = data.day.dayTitle;
    this.hora_nueva_consulta = data.hour.from;

  }

  clickConfirmar() {
    let data = {
      sch_hou_id: this.hora_seleccionada.hour.sch_hou_id,
      ben_id: this.ben_id,
      sub_spe_id: this.datos_consulta.sub_spe_id,
      boo_id: this.datos_consulta.id_consulta,
      use_typ_name: this.use_typ_name
    }
    let data_selected = {
      razon: this.datos_consulta.razon,
      sintomas: this.datos_consulta.sintomas,
      acompanante_ben_id: null
    }
    this.generalService.modalCargando();
    this._scheduleService.editAppointment(data, data_selected)
      .then(resp => {
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Su consulta ha sido modificada con éxito</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Se ha enviado un correo con la confirmación de su modificación de fecha y hora de su consulta.</p><br>',
          showConfirmButton: false,
          timer: 2000
        })
        setTimeout(() => this.router.navigate(['dashboard']), 2000);
      })
      .catch(error => {
        if (error.error && error.error.msg) {
          this.generalService.modalError('Error al modificar su reserva.', error.error.msg);
          this.router.navigate(['dashboard']);
        } else {
          this.generalService.modalError('Error al modificar su reserva.', 'Comuniquese con el contact center.');
          this.router.navigate(['dashboard']);
        }
      })
  }
}
