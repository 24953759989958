import { Component, OnInit } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { TarifasService } from 'src/app/services/tarifas.service';
import { URL_DOCS } from '../../config/config';

@Component({
  selector: 'app-detalle-tarifa',
  templateUrl: './detalle-tarifa.component.html',
  styleUrls: ['./detalle-tarifa.component.css']
})
export class DetalleTarifaComponent implements OnInit {


  public usuario: any = {};

  public especialidades = [];

  public especialista = [];
  public especialidadesDoctor = [];


  public URL_DOCS = URL_DOCS;

  infoPost: any = {};

  hayError1: boolean = false;
  hayError2: boolean = false;



  constructor(
    public generalService: GeneralService,
    public tarifasService: TarifasService) { }


  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    // console.log(this.usuario);
    this.getDoctorList();

    // this.getSpecialityList();

  }


  //Listar especialidades doctor

  getDoctorList() {
    this.generalService.getDoctorList(this.usuario.doc_id)
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-getDoctorList');

          this.especialidadesDoctor = data;
        }
      })
      .catch(e => console.error(e));
  }


  modalTarifaBloqueada() {
    // console.log();
    this.tarifasService.modalTarifaBloqueada('Modificación de tarifa no disponible', 'Contacte a su coordinador Médico');

  }



  updateDoctorEspecialidad(especialidad) {

    // console.log(especialidad);
    this.infoPost = especialidad;

    this.infoPost.can_udp_price = especialidad.doc_spe_sub_spe_can_upd_price;
    this.infoPost.doc_spe_sub_id = especialidad.doc_spe_sub_id;
    this.infoPost.price = especialidad.pri_doc_price > 0 ? especialidad.pri_doc_price : especialidad.sub_spe_pri_price;
    this.infoPost.newPrice = especialidad.pri_doc_price > 0 ? especialidad.pri_doc_price : especialidad.sub_spe_pri_price;

    // console.log(this.infoPost, 'infoPost-updateDoctorEspecialidad');

  }

  async confirmUpdateDoctorPriceSubSpe() {


    // console.log(this.infoPost, 'infoPost-confirmUpdateDoctorPriceSubSpe');
    this.generalService.modalCargando();

    if (this.infoPost.newPrice > 1000  && this.infoPost.newPrice < 150000) {

    }else{
      this.tarifasService.modalValorInvalido('Valor ingresado no valido','Ingrese un valor entre $1.000 y $150.000');
      return;
    }





    //Trae la informacicon de la speDoctor Seleccionado

    if (this.infoPost.newPrice != this.infoPost.price && this.infoPost.can_udp_price) {
      //Llamar servicio
      await this.tarifasService.postUpdateSubSpeDoctorPrice(this.infoPost.doc_spe_sub_id, this.infoPost.newPrice)
        .then(result => {
          // console.log(result, 'tarifa modificada-postUpdateSubSpeDoctorPrice');
        })
        .catch(error => {
          console.log(error, 'error-postUpdateSubSpeDoctorPrice');
        });
    }



    this.generalService.modalExitoso('Cambios guardados exitosamente', '');
    this.ngOnInit();
  }





}




