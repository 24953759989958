import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";

import { RecoveryComponent } from './recovery/recovery.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { LoginWrapperDoctorComponent } from './login-wrapper-doctor/login-wrapper-doctor.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { LoginLoadingComponent } from './login-loading/login-loading.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { LoginWrapperWorkersComponent } from './login-wrapper-workers/login-wrapper-workers.component';
import { LoginMiddlewareComponent } from './login-middleware/login-middleware.component';
import { LoginErrorMiddlewareComponent } from './login-error-middleware/login-error-middleware.component';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    RecoveryComponent,
    LoginWrapperComponent,
    LoginWrapperDoctorComponent,
    LoginFooterComponent,
    LoginLoadingComponent,
    LoginErrorComponent,
    LoginWrapperWorkersComponent,
    LoginMiddlewareComponent,
    LoginErrorMiddlewareComponent,
  ],
  exports: [
    LoginComponent,
    RegisterComponent,
    RecoveryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module,
    SharedModule,
    
    MatIconModule

  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ]
})
export class AuthModule { }
