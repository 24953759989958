import { ChangeDetectorRef, OnDestroy, Component, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { SettingsService } from '../services/settings.service';

import { UsuarioService } from 'src/app/services/usuario.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EncriptacionService } from '../services/encriptacion.service';
import { FichaMedicaService } from '../services/ficha-medica.service';
import { SidebarService } from '../services/sidebar.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MediaMatcher } from '@angular/cdk/layout';
import { ErrorsService } from '../services/errors.service';
import { GeneralService } from '../services/general.service';
import { GestionService } from '../services/gestion.service';
import { PendingSurveysService } from '../services/pending-surveys.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  @Input() med_con_id: any;
  @Input() consulta: any = {};

  number_page: number = -1;
  calificaciones = [0, 0, 0, 0];
  comentario: String = "";

  rutaSalida: String = "";
  @ViewChild('modalFinLlamadaSide') modalFinLlamada: any;
  // @ViewChild('icon-bars-mobile') barsHeader: ElementRef;
  mobileQuery: MediaQueryList;

  isExpanded: boolean = true;
  isOpen: boolean = false;
  hideArrow: boolean = false;
  panelOpenState: boolean = false;


  public fieldTextType1: boolean = false;
  public fieldTextType2: boolean = false;
  public fieldTextType3: boolean = false;

  public signupPass: boolean = false;
  public signupPass1Focus: boolean = false;
  public signupPass2Focus: boolean = false;
  public signupcodVerificationFocus: boolean = false;

  public pendingSurveysList: any[] = [];



  // tamaños de pantalla

  contentMargin = 300;

  menuItems: any[];
  _usuario: any = {};

  primerNombre: string = '';
  tipo_usuario_name: string;
  user_type: number;
  consultas_list: any = [];
  consultas: any = [];

  //nabBar


  public cambiaPass = this.fb.group({
    contrasena_actual: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva_2: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    // codigo_verificacion: [''],
  }, {
    validators: this.passwordsIguales('contrasena_nueva', 'contrasena_nueva_2')
  });

  usuario: any = {};
  pageInfo: any = {};

  passLength: number = 0;
  passMinLength: boolean = false;
  passCapital: boolean = false;
  passLetter: boolean = false;
  passNumber: boolean = false;
  equalPass: boolean = false;
  contrasena_nueva_2: string;

  sidebar_element: any;

  private readonly Key = 'BA30U-NXORgEPxaOsy6zoJOUcJpZlg4xvXTzwu5S17rD8fEoQ8IO2hDTWurplzJ2JcJmyoPQjaqLwF14MDPJp_A';

  public formSubmitted = false;

  private _mobileQueryListener: () => void;


  constructor(
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    public sidebarService: SidebarService,
    private fb: FormBuilder,
    public _usuarioService: UsuarioService,
    private _fichaMServices: FichaMedicaService,
    private renderer: Renderer2,
    private router: Router,
    public _errors: ErrorsService,
    private generalService: GeneralService,
    private gestionService: GestionService,
    private pendingSurveys: PendingSurveysService,

  ) {
    this.menuItems = this.sidebarService.menu;

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();
    // console.log(this._usuario);
    this.primerNombre = this.getPrimerNombre(this._usuario.nombre);

    let user_nuevo = new EncriptacionService().getUserInfo();
    this.pageInfo = new EncriptacionService().getPageInfo();

    this.tipo_usuario_name = new EncriptacionService().getUserInfo().typ_name;

    this.sidebar_element = document.getElementById('sidebar-angular');
    if (this._usuario.typ_id == 2) {

      this.infoUsuario();
      this.getPendingSurveys()

    }
  }

  getPendingSurveys() {
    this.pendingSurveys.postPendingSurveys()
      .then((data: any) => {
        this.pendingSurveysList = data.pendingSurveys || [];
        // console.log(this.pendingSurveysList);

        // Verificar si hay encuestas pendientes
        if (this.pendingSurveysList.length > 0) {
          // Obtener la última encuesta de la lista
          const lastSurvey = this.pendingSurveysList[0];

          // Verificar si el campo tel_doc_app_boo_survey_skipped está en false
          if (!lastSurvey.tel_doc_app_boo_survey_skipped) {
            // Mostrar el modal solo si tel_doc_app_boo_survey_skipped es false
            this.showSurveyModal();
          }
        }
      });
  }
  onModalClosed() {
    window.location.reload();
    this.ngOnInit();
  }


  showSurveyModal() {
    // Obtener el modal
    const modal = document.getElementById('surveyModal');

    // Mostrar el modal
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
    }
  }

  closeSurveyModal() {
    // Obtener el modal
    const modal = document.getElementById('surveyModal');

    // Cerrar el modal
    if (modal) {
      modal.style.display = 'none';
    }
  }

  public info_usuario = this.fb.group({
    use_id: ['', [Validators.required]],
    con_ana_page: ['', [Validators.required]],
    con_ana_connection_type: ['', [Validators.required]],
    con_ana_connection_mode: ['', [Validators.required]]
  });

  infoUsuario() {
    var ua = navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(ua)) {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
      this.info_usuario.controls['use_id'].setValue(this._usuario._id);
      this.info_usuario.controls['con_ana_page'].setValue('Dashboard');
      this.info_usuario.controls['con_ana_connection_type'].setValue('IOS');
      this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
      //  //console.log(this.info_usuario.value);
      this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe((data: any) => {
          //console.log("registro almacenado");

        })

    }
    else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
      this.info_usuario.controls['use_id'].setValue(this._usuario._id);
      this.info_usuario.controls['con_ana_page'].setValue('Dashboard');
      this.info_usuario.controls['con_ana_connection_type'].setValue('Android');
      this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
      //  //console.log(this.info_usuario.value);
      this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe((data: any) => {
          //console.log("registro almacenado");

        })
    }
    else {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
      this.info_usuario.controls['use_id'].setValue(this._usuario._id);
      this.info_usuario.controls['con_ana_page'].setValue('Dashboard');
      this.info_usuario.controls['con_ana_connection_type'].setValue('Desktop');
      this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
      //  //console.log(this.info_usuario.value);
      this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe((data: any) => {
          // //console.log("registro almacenado");

        })
    }
  }

  getPrimerNombre(nombreCompleto: string): string {
    if (nombreCompleto && nombreCompleto.trim() !== '') {
      const nombres = nombreCompleto.trim().split(' ');
      return nombres.length > 0 ? nombres[0] : '';
    } else {
      return '';
    }
  }

  difConsultaMin = 12 * 30 * 24 * 60; //1 año
  consultaProxima: any = {};

  consultasConfirmadas() {

    this._fichaMServices.getListarConsultasConfirmadas()
      .then((data: any) => {
        let fechaSistema = new Date();
        this.consultas_list = data.consultas;

        for (let cons of this.consultas_list) {
          cons.fechaConsulta = new Date(cons.fecha_consulta);
          cons.fechaConsulta.setHours(cons.hora.substr(0, 2));
          cons.fechaConsulta.setMinutes(cons.hora.substr(3, 2));
          let msGap = cons.fechaConsulta.getTime() - fechaSistema.getTime();
          cons.minGap = Math.floor(msGap / 60000);                            //minutos de diferencia
          if (cons.minGap < this.difConsultaMin && cons.minGap > 0) {
            this.difConsultaMin = cons.minGap
            this.consultaProxima = cons;
          }
        }
      })
      .catch(error => {
        // console.log(error);
      })
  }

  unirseReunionPaciente() {
    if (this.consultas_list.length == 0 || !this.consultaProxima.id_consulta) {
      Swal.fire({
        icon: 'info',
        title: 'No tiene proximas consultas',
        timer: 2000
      })
    }
    else {

      let minGap = this.consultaProxima.minGap

      if (minGap > 60 || minGap < 0) {
        //console.log("No Puede");
      }
      else {
        var url = "salaespera/" + this.consultaProxima.zoo_mee_room_id + "/" + this.consultaProxima.id_consulta + "/" + this.consultaProxima.med_con_id;
        this.router.navigate([url]);
      }
    }
  }

  // Funcion que redirige a la proxima consulta
  async unirseReunionMedico() {
    if (this.consultas_list.length == 0 || !this.consultaProxima.id_consulta) {
      Swal.fire({
        icon: 'info',
        title: 'No tiene proximas consultas',
        timer: 2000
      })
    }
    else {
      // let consultasNext = await this.consultas_list.sort((a, b) => {
      //   if (a.fecha_consulta > b.fecha_consulta) {
      //     return 1;
      //   } else if (a.fecha_consulta < b.fecha_consulta) {
      //     return -1;
      //   } else {
      //     if (a.hora > b.hora) {
      //       return 1;
      //     } else if (a.hora < b.hora) {
      //       return -1;
      //     } else {
      //       return 0;
      //     }
      //   }
      // });


      let url = "videoconsulta/" + this.consultaProxima.id_consulta;
      this.router.navigate([url]);
    }
  }

  ruta(ruta) {
    //console.log(sessionStorage.getItem('zoom_ud_video')) // //console.log(sessionStorage.getItem('zoom_up'))
    if (sessionStorage.getItem('zoom_ud_video') == 'true' && this._usuario.typ_id == 2) { // if( sessionStorage.getItem('zoom_ud_video') == 'true' ){


      this.renderer.selectRootElement(this.modalFinLlamada.nativeElement).click();
      this.rutaSalida = ruta;
      // //console.log(ruta);

    }
    else {
      this.router.navigate([ruta]);
    }

    // if (this.sidebar_element.style.display === "none") {
    //   this.sidebar_element.style.display = "block";
    // } else {
    //   this.sidebar_element.style.display = "none";
    // }


    //  this.renderer.selectRootElement(this.barsHeader.nativeElement).click();
    // document.getElementById("close-menu").click();

    // let barsHeader = document.getElementsByClassName('icon-bars-mobile')[0] as HTMLElement;
    //console.log(barsHeader);
    // let barsHeader = document.getElementsByClassName('icon-bars-mobile')[0] as HTMLElement;
    let barsHeader = document.getElementsByClassName('sidebartoggler')[0] as HTMLElement;
    //console.log(barsHeader);



  }

  navegar() {
    this.router.navigate([this.rutaSalida]);
    this.renderer.selectRootElement(this.modalFinLlamada.nativeElement).click();
  }
  checkPass1 = () => {
    const pass1: string = this.cambiaPass.get('contrasena_nueva').value;
    this.passLength = pass1.length;
    if (this.passLength >= 8) {
      this.passMinLength = true;
    } else {
      this.passMinLength = false;
    }
    if (pass1.match(/[A-Z]/g)) {
      this.passCapital = true;
    } else {
      this.passCapital = false;
    }
    if (pass1.match(/[a-z]/g)) {
      this.passLetter = true;
    } else {
      this.passLetter = false;
    }
    if (pass1.match(/[0-9]/g)) {
      this.passNumber = true;
    } else {
      this.passNumber = false;
    }
  }

  campoNoValidoPassword(campo: string): boolean {

    if (this.cambiaPass.get(campo) != this.cambiaPass.get('contrasena_nueva') && this.formSubmitted) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    } else {
      return false;
    }
  }
  cambiaPassword() {

    this.formSubmitted = true;
    if (this.cambiaPass.invalid) {
      //console.log('formulario invalido');
      return;
      //no retorno nada si es invalido el formulario
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Espere por favor...',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 3000
      })
      this._usuarioService.modificarPassword(this.cambiaPass.value)
        .subscribe((data: any) => {
          Swal.fire({
            allowOutsideClick: false,
            html:
              ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
              ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >La contraseña ha sido modificada exitosamente </h1> <br>' +
              ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
            showConfirmButton: false,
          })
          setTimeout(() => window.location.reload(), 1000);
        }, (err) => {
          // Si sucede un error
          Swal.fire({
            allowOutsideClick: false,
            html:
              ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
              ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >' + err.error.message + '</h1> <br>' +
              ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >Intente nuevamente. </p><br>',

          })
          /*Swal.fire('Error', err.error.message, 'error' );*/
        })
    }

  }
  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      this.formSubmitted = false;
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value !== pass2Control.value) {
        return {
          noEsIgual: true
        };
      }
    }
  }
  reservaHora() {
    var url = "reserva-hora/";
    this.router.navigate([url]);
  }

  dashboard() {
    var url = "dashboard/";
    this.router.navigate([url]);
  }
  reservadeHora() {
    var url = "reservar-hora-contact/";
    this.router.navigate([url]);
  }


  //Sidebar destock
  onToolbarMenuToggle() {
    this.isExpanded = !this.isExpanded;
    this.hideArrow = false;
    this.isOpen = false;
    this.panelOpenState = false;


    if (!this.isExpanded) {
      this.contentMargin = 70;
      this.hideArrow = true;
      this.isOpen = false;
      this.panelOpenState = false;

    } else {
      this.contentMargin = 300;
      this.hideArrow = false;
      this.isOpen = false;
      this.panelOpenState = false;

    }
  }


  onToolbarMenuClose() {
    this.isExpanded = true;
    this.hideArrow = false;
    this.isOpen = false;
    this.panelOpenState = false;


    if (!this.isExpanded) {
      this.contentMargin = 70;
      this.hideArrow = true;
      this.isOpen = false;
      this.panelOpenState = false;

    } else {
      this.contentMargin = 300;
      this.hideArrow = false;
      this.isOpen = false;
      this.panelOpenState = false;

    }
  }

  logoutUser() {
    let url = '/login';
    if (this._usuario.typ_id == 1) {
      url = '/login-doctor';
    } else if (this._usuario.typ_id == 2) {
      url = '/login';
    } else if (this._usuario.typ_id == 7) {
      url = '/login-contact';
    } else if (this._usuario.typ_id == 8) {
      url = '/login-coordinador';
    } else if (this._usuario.typ_id == 11) {
      url = '/login-customer';
    } else if (this._usuario.typ_id == 12) {
      url = '/login-admin';
    }
    this._usuarioService.logoutUser()
      .then(result => {
        this.router.navigateByUrl(url);
      })
      .catch(error => {
        this.router.navigateByUrl(url);
      });
  }

  toggleFieldTextType1 = () => {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  toggleFieldTextType2 = () => {
    this.fieldTextType2 = !this.fieldTextType2;
  }
  toggleFieldTextType3 = () => {
    this.fieldTextType3 = !this.fieldTextType3;
  }

  private lastRequestTime: number | null = null;
  private cooldownPeriod = 5 * 60 * 1000;

  requestCode() {
    const currentTime = Date.now();

    if (this.lastRequestTime && (currentTime - this.lastRequestTime) < this.cooldownPeriod) {
      this.generalService.modalError(
        'El código de verificación ya fue solicitado.',
        'Podrás solicitar un nuevo código dentro de 5 minutos más.'
      );
    } else {
      this.lastRequestTime = currentTime;
      this.generalService.modalExitoso(
        'Código de verificación solicitado con éxito.',
        'Podrás solicitar un nuevo código dentro de 5 minutos más.'
      );
      this.disableButton();
    }
  }
  disableButton() {
    const button = document.getElementById('requestCodeButton') as HTMLButtonElement;
    if (button) {
      button.disabled = true;
      setTimeout(() => {
        button.disabled = false;
      }, this.cooldownPeriod);
    }
  }
  campoNoValido(campo: string): boolean {
    const valInvalid: boolean = this.cambiaPass.get(campo).invalid;
    if (valInvalid) {
      return true;
    } else {
      return false;
    }
  }
}
