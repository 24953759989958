<!-- PACIENTE -->
<div class="card h-100 p-0 m-0 border-confirm">
  <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
    <div class="m-0 p-0 first-line">

        <p class="card-text m-0 p-0 appt-date">Fecha de la consulta: {{ consulta.fecha_consulta.day}} de {{ consulta.fecha_consulta.month}} del {{ consulta.fecha_consulta.year}} a las {{ consulta.hora}}</p>
        <p class="card-text pt-3 mr-5 doc-data">{{ consulta.nombre_doctor }} {{ consulta.apellido_materno_doctor }} - {{ consulta.spe_name }} - {{ consulta.sub_spe_name }}</p>

        <p class="card-text m-0 p-0 appt-fin"><li>Finalizada</li></p>

      <div class="ml-3 align-self-center botones">
        <button 
          type="button"
          (click)="verDetalle()"
          data-toggle="modal" [attr.data-target]="'#detalle' + data_consulta.id_consulta"
          class="btn btn-secondary mr-auto">
            Ver detalle
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="ficha">

  <!-- <div class="row">
    <div class="col-md-12">
            <app-card-consulta
                [idconsulta]='consulta.id_consulta'>

            </app-card-consulta>
      </div>
  </div> -->


</div>


<!-- modal eliminar 1-->
<div class="modal fade" [id]="'detalle'+data_consulta.id_consulta" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid">
          <div >
            <div class="row">
                <div class="col-12">
                    <div class="row d-sm-flex">
                        <div class="col-md-12">
                            <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
                            <div class="card p-0 m-0" >
                                <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                                    <div class="m-0 p-0 first-line">
                                        <div class="img-cirlce-wrapper">
                                            <p class="m-0 p-0 text-default">
                                                {{data_consulta.nombre_doctor|slice:0:1}}{{data_consulta.apellido_paterno_doctor|slice:0:1}}
                                            </p>
                                        </div>
                                        <div class="ml-3 align-self-center">
                                            <p class="card-text m-0 p-0 appt-date" style="font-weight: bold">Fecha de la consulta: {{ data_consulta.fecha_consulta|date:'dd-MM-yyyy'}} - {{ data_consulta.hora}}</p>
                                            <p class="card-text m-0 p-0 ">{{ data_consulta.nombre_doctor}} {{ data_consulta.apellido_paterno_doctor}} - {{ data_consulta.spe_name}}</p> <!-- {{ appt.spec }} -->
                                            <p class="card-text m-0 p-0 "> </p>
                                        </div>
                                    </div>
                                    <br>

                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 titulobeneficiario">
                        Descripción de la consulta
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-md-6 m-0 p-0 first-line">
                        <div class="ml-3 align-self-center">
                            <p class="card-text m-0 p-0 tituloDescripcion" >Paciente</p>
                            <p class="card-text m-0 p-0 cuerpoDescripcion" >{{data_consulta.nombre_paciente}} {{data_consulta.apellido_paterno_paciente}} {{data_consulta.apellido_materno_paciente}}</p>
                            <p class="card-text m-0 p-0 "> </p>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-6 m-0 p-0 first-line">
                        <div class="ml-3 align-self-center">
                            <p class="card-text m-0 p-0 tituloDescripcion">Acompañante del paciente</p>
                            <p class="card-text m-0 p-0 cuerpoDescripcion">-------------</p>
                            <p class="m-0 p-0 "> </p>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-md-12 m-0 p-0 first-line">
                        <div class="ml-3 align-self-center">
                            <p class="card-text m-0 p-0 tituloDescripcion">Motivo de la consulta</p>
                            <p *ngIf="data_consulta.razon != ''" class="card-text m-0 p-0 cuerpoDescripcion">{{ data_consulta.razon}}</p>
                            <p *ngIf="data_consulta.razon == ''" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no especifica motivo de la consulta **</p>
                            <p class="m-0 p-0 "> </p>
                        </div>
                    </div>
                </div>
                <br><br>
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar Detalle Consulta</button>

            </div>
          </div>




      </div>
    </div>
  </div>
</div>
<!--modal-->