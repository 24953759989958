import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import { GestionService } from 'src/app/services/gestion.service';
import Swal from 'sweetalert2';
import { log } from 'console';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-uso-asistencia',
  templateUrl: './uso-asistencia.component.html',
  styleUrls: ['./uso-asistencia.component.css']
})
export class UsoAsistenciaComponent implements OnInit {
  _title = 'Gestión';
  _subtitle = 'Uso de asistencia'

  public asistencias: any = [];

  public dominios: any = [];

  public input_email: any = '';

  public mails: any = [];


  

  f_desde: any = '';
  f_hasta: any = '';
  info_input: any = '';


  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  dropdownListSpe = [];
  selectedItemsSpe = [];
  dropdownSettingsSpe: IDropdownSettings = {};


  constructor(public customerService: CustomerService,
    public generalService: GeneralService,
    public gestionService: GestionService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'cus_id',
      textField: 'cus_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettingsSpe = {
      singleSelection: false,
      idField: 'spe_id',
      textField: 'spe_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.postListarCustomer();
    this.getSpecialityList();
    this.authorizedDomains();
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }


  onItemSelectSpe(item: any) {
    // console.log(item);
  }
  onSelectAllSpe(items: any) {
    // console.log(items);
  }

  postListarCustomer() {
    this.customerService.postListarCustomer({})
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-postListarCustomer');
          this.dropdownList = data;
        }
      })
      .catch(e => console.error(e));
  }

  getSpecialityList() {
    this.generalService.getSpecialityList()
      .then((data: any) => {
        // console.log(data, 'data-getSpecialityList');
        this.dropdownListSpe = data;

      })
      .catch(e => console.error(e));
  }

  authorizedDomains(){
    this.gestionService.authorizedDomains()
      .then((data: any) => {
        // console.log(data, 'dominios autorizados');
        this.dominios = data        
      })
      .catch(e => console.error(e));

  }

  ExportarExcel() {
    //console.log('Exportar');
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    if (this.asistencias.length == 0) {
      Swal.fire({
        icon: 'info',
        text: 'Sin datos disponibles',
        timer: 2000
      })
    } else {
      Swal.close();
      this.downloadFileExcel(this.asistencias);
    }
  }

  ExportarCSV(){
     //console.log('Exportar');
     Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    if (this.asistencias.length == 0) {
      Swal.fire({
        icon: 'info',
        text: 'Sin datos disponibles',
        timer: 2000
      })
    } else {
      Swal.close();
      this.downloadFileCSV(this.asistencias);
    }
  }

  modalError(titulo, detalle) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: detalle,
      timer: 5000
    })
  }



  async actualizarData() {
    while (true) {
      this.Listar();
      await this.generalService.sleep(1000 * 60 * 5);
    }
  }

  ListarLoading() {
    this.generalService.modalCargando();
    this.Listar();
  }

  
  Listar() {
    //console.log('Listar');
    let fecha_desde_post = null;
    let fecha_hasta_post = null;
    let n_consulta = null;
    let email = []
    try {
      fecha_desde_post = this.validarFecha(this.f_desde);
      fecha_hasta_post = this.validarFecha(this.f_hasta);
      n_consulta = this.info_input;
      email = null
    } catch (error) {
      // console.log(error);
      this.modalError('Formato fecha incorrectos', 'Por favor escribir una fecha valida.');
      return;
    }
    // console.log(fecha_desde_post);
    // console.log(fecha_hasta_post);
    // console.log(n_consulta);

    this.gestionService.assistanceUse(email, n_consulta, this.arrayStringCustomer(this.selectedItems), this.arrayStringSpeciality(this.selectedItemsSpe),  fecha_desde_post, fecha_hasta_post)
      .then((data: any) => {
        this.asistencias = data
        this.asistencias.reverse();
        // console.log(this.asistencias, 'horas filtradas');
        Swal.close();
      })
      .catch(error => {
        // console.log(error);
      });
  }

  validarFecha(fecha) {
    if (fecha.length > 0) {
      let array_fecha = fecha.split('/');
      array_fecha[0] = array_fecha[0].padStart(2, '0');
      array_fecha[1] = array_fecha[1].padStart(2, '0');
      array_fecha[2] = array_fecha[2].padStart(4, '20');
      // //console.log(array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0]);
      let nueva_fecha = new Date(array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0]);
      if (nueva_fecha.toString() != 'Invalid Date') {
        return array_fecha[2] + array_fecha[1] + array_fecha[0];
      } else {
        throw new Error("Fecha Invalida");
      }
    }
    return '';
  }

  downloadFileExcel(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // mapear valores nulos
    const header = Object.keys(data[0]);
    
    // Mapear fechas en el formato correcto
    const mappedData = data.map(row => {
      const mappedRow = {};
      for (const fieldName of header) {
        if (fieldName === 'fecha') { // Supongamos que la columna de fechas se llama 'fecha'
          mappedRow[fieldName] = format(new Date(row[fieldName]), 'yyyy-MM-dd'); // Formatear la fecha en el formato deseado
        } else {
          mappedRow[fieldName] = row[fieldName];
        }
      }
      return mappedRow;
    });
    
    const worksheet = XLSX.utils.json_to_sheet(mappedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, "uso_asistencia.xlsx");
  }

  downloadFileCSV(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // majear valore nulos
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob(["\ufeff" + csvArray], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "uso_asistencia.csv");
  }

  arrayStringCustomer(arrayStatusCustomer) {
    let textArray = [];
    for (let index = 0; index < arrayStatusCustomer.length; index++) {
      textArray.push(arrayStatusCustomer[index].cus_id);
    }
    return (arrayStatusCustomer.length > 0 ? textArray : null);
  }

  arrayStringSpeciality(arraySpeciality) {
    let textArray = [];
    for (let index = 0; index < arraySpeciality.length; index++) {
      textArray.push(arraySpeciality[index].spe_id);
    }
    return (arraySpeciality.length > 0 ? textArray : null);
  }


  emailLoading() {
    let emails = null;
    this.input_email = this.input_email.replaceAll('\t', '');
    this.input_email = this.input_email.replaceAll('\n', '');
    this.input_email = this.input_email.replaceAll(' ', '');
    this.input_email = this.input_email.replaceAll('-', '');
    emails = this.input_email.split(',');
    // console.log(emails, 'array emails');
  
    let isValidEmails = true; // Variable para seguir el estado de validez de todos los correos
  
    for (let i = 0; i < emails.length; i++) {
      let element = emails[i];
      if (this.generalService.validaEmail(element)) {
        let emailParts = element.split('@');
        let username = emailParts[0];
        let domain = emailParts[1];
        let isValidDomain = false;
        for (let j = 0; j < this.dominios.length; j++) {
          const dominio = this.dominios[j];
          if (domain === dominio.aut_dom_domain) {
            let mail = username + '@' + domain;
            // console.log(mail, 'correo válido');
            this.mails.push(mail);
            isValidDomain = true;
            this.sendEmail();
            break;
          }
        }
        if (!isValidDomain) {
          isValidEmails = false; // Al menos un dominio es inválido
          break;
        }
      } else {
        isValidEmails = false; // Al menos un correo es inválido
        break;
      }
    }
  
    if (isValidEmails) {
      this.generalService.modalExitoso('Se ha enviado el informe a los correos seleccionados', '');
    } else {
      this.generalService.modalError('Uno o mas de los correos ingresados no corresponde a los dominios autorizados', 'Se enviara la informacion a los correos autorizados');
      this.generalService.sleep(3500);

    }
  
    // console.log(this.mails, 'correos correctos');
    this.ngOnInit();
  }
  

sendEmail(){
  let fecha_desde_post = null;
  let fecha_hasta_post = null;
  let n_consulta = null;
  let email = [];
  try {
    fecha_desde_post = this.validarFecha(this.f_desde);
    fecha_hasta_post = this.validarFecha(this.f_hasta);
    n_consulta = this.info_input;
    email = this.mails
  } catch (error) {
    // console.log(error);
    this.modalError('Formato fecha incorrectos', 'Por favor escribir una fecha valida.');
    return;
  }
  this.gestionService.assistanceUse(email, n_consulta, this.arrayStringCustomer(this.selectedItems), this.arrayStringSpeciality(this.selectedItemsSpe),  fecha_desde_post, fecha_hasta_post)
  .then((data: any) => {
    this.asistencias = data
    this.asistencias.reverse();
    // console.log(this.asistencias, 'horas filtradas');
    Swal.close();
  })
  .catch(error => {
    // console.log(error);
  });
}

}
