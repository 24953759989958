import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompleterService } from 'ng2-completer';
import { GeneralService } from 'src/app/services/general.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { log } from 'console';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  @ViewChild('triDeleteModal') triDeleteModal: ElementRef;
  auxUser: any = {};

  users: any[] = [];
  pageConfig: any = {
    pageNumber: 1,
    pageSize: 25,
    quantity: 0,
    pages: 0,
    filter: '',
    filter_aux: ''
  };

  pages: any = {
    user_list: true,
    edit_user: false
  };

  userSelected: any = {};

  constructor(
    private generalService: GeneralService,
    private renderer: Renderer2,
    private usuarioService: UsuarioService,
    private completerService: CompleterService,
  ) { }

  ngOnInit(): void {
    this.listUsers();
  }

  changeLengthTable(pageNumber) {
    if (pageNumber) {
      this.pageConfig.pageNumber = pageNumber;
      this.listUsers();
    }
  }


  arrayNumber() {
    let arr = [];
    for (let i = 1; i <= this.pageConfig.pages; i++) {
      arr.push(i);
    }
    return arr;
  }

  listUsers() {
    this.users = [];
    this.generalService.modalCargando();
    this.usuarioService.listUsers(this.pageConfig)
      .then((result: any) => {
        this.users = result.users;

        this.pageConfig = result.page;
        this.pageConfig.filter_aux = this.pageConfig.filter;
        // console.log(this.users);
        // console.log(this.pageConfig);
        Swal.close();
      })
      .catch(error => {
        this.generalService.modalError('Error', 'Error al cargar usuarios');
      });
  }

  confirmDelete() {
    if (!this.userSelected.use_id) {
      this.generalService.modalError('Error', 'Error al desactivar usuario');
      return;
    }
    this.generalService.modalCargando();
    this.renderer.selectRootElement(this.triDeleteModal.nativeElement).click();
    this.usuarioService.deleteUser({ use_id: this.userSelected.use_id })
      .then(async (result: any) => {
        this.generalService.modalExitoso('Exito', 'El usuario se ha desactivado con exito.');
        await this.generalService.sleep(1000);
        this.listUsers();
      })
      .catch(error => {
        this.generalService.modalError('Error', 'Error al desactivar usuario');
      });
  }

  deleteUser(user: any) {
    this.userSelected = {};
    if (!user.use_id) {
      this.generalService.modalError('Error', 'Error al desactivar usuario');
      return;
    }
    this.userSelected = user;
    this.renderer.selectRootElement(this.triDeleteModal.nativeElement).click();
    // console.log(this.userSelected);
    

  }

  // changeFea(user: any){
   
  // }

  ListarLoading() {
    this.pageConfig.pageNumber = 1;
    this.pageConfig.pageSize = 25;
    this.pageConfig.filter = this.pageConfig.filter_aux;
    this.listUsers();
  }

  editUser(user: any) {
    // console.log(user);
    user.birth_date = new Date(user.birth_date);
    if (user.egress_date) {
      user.egress_date = new Date(user.egress_date);
    }
    this.auxUser = user;
    this.pages = {
      user_list: false,
      edit_user: true
    };
  }
}
