import { Component, OnInit, ViewChild  } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { CalendarDocComponent } from 'src/app/components/calendar-doc/calendar-doc.component';
import Swal from 'sweetalert2';
import { CalendarEvent } from 'angular-calendar';
import { UsuarioService } from 'src/app/services/usuario.service';
import { GeneralService } from 'src/app/services/general.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { CalendarMonthComponent } from 'src/app/components/calendar/calendar-month/calendar-month.component';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-proximas-consultas-paciente',
  templateUrl: './proximas-consultas-paciente.component.html',
  styleUrls: ['./proximas-consultas-paciente.component.css']
})
export class ProximasConsultasPacienteComponent implements OnInit {
  @ViewChild(CalendarMonthComponent) CALENDAR: CalendarMonthComponent;
  _title = 'Mi agenda';
  _subtitle = 'Mis próximas consultas';

  _usuario: any = {};  // usuario actual

  toDay: any = {};      // dia actual
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha
  options_week = { weekday: 'long'}; //opciones labels dia de la semana
  options_month = { month: 'long'}; //opciones labels mes

  appointments: any[] = [];         //proximas consultas
  appointmentsToDay: any[] = [];    //del dia
  appointmentsNext: any[] = [];     //no del dia


  constructor(
    public _usuarioService: UsuarioService,
    public _general: GeneralService,
    private _fichaMedicaServices: FichaMedicaService,
  ) { }

  beneficiary_selected: any = {};

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo()

    this.toDay.date = new Date();
    this.toDay.dateInt = this.toDay.date.getFullYear()*10000 + (this.toDay.date.getMonth() +1)*100 + this.toDay.date.getDate();
    this.toDay.dateString =  (this.toDay.date).toLocaleString('es-US', this.options);
    this.toDay.dayTitle = this.toDay.date.getDate() +' de '+ (this.toDay.date).toLocaleString('es-US', this.options_month);



    this.getListarConsultas();

  }


  getListarConsultas = () => {
    this._fichaMedicaServices.getListarConsultas()
      .then( (result: any) => {
        //console.log(result);

        if ( !(result.consultas.length > 0) ) {
          //console.log('Sin proximas consultas');
        } else {
          let consultas = result.consultas.sort((a, b) => {
            if (a.fecha_consulta > b.fecha_consulta) {
              return 1;
            } else if (a.fecha_consulta < b.fecha_consulta) {
              return -1;
            } else {
              if (a.hora > b.hora) {
                return 1;
              } else if (a.hora < b.hora) {
                return -1;
              } else {
                return 0;
              }
            }
          });

          for (let appt of consultas) { //(let i = 0; i < consultas.length; i++) {
            // const appt = cos;
            let hour = appt.hora.substr(0, 2);
            let minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-','/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            let now = new Date();
            let formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              let msToAppt = this.timeToX(apptDate, now);
              let minToAppt = this.msToMin(msToAppt);
              if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
                confirmFlag = true;
              }
              let goLobbyFlag: boolean = false;
              if (minToAppt <= 60) {
                goLobbyFlag = true;
              }

              let apptData = {
                date: formatedApptDate,
                fecha_consulta : Date.parse(appt.fecha_consulta),
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
                goLobbyFlag,
                id_consulta: appt.id_consulta,
                zoo_mee_room_id:appt.zoo_mee_room_id,
                med_con_id: appt.med_con_id,
                acompanante: false,
                consulta:appt
              };
              this.appointments.push(apptData);

              let apptDateInt = apptDate.getFullYear()*10000 + (apptDate.getMonth() +1)*100 + apptDate.getDate();



              if (this.toDay.dateInt == apptDateInt) {
                this.appointmentsToDay.push(apptData);
              }
              else{
                this.appointmentsNext.push(apptData);
              }
            }
          }
          //console.log('Consultas de hoy');

          //console.log(this.appointmentsToDay);
          //console.log('Próximas consultas');

          //console.log(this.appointmentsNext);

        }

        this.sendToCalendar(this.appointments);
      })
      .catch(e => console.log(e));
  }

  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    const auxApptDateHour = apptDate.getHours();
    const auxApptDateMin = apptDate.getMinutes();
    let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
    let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
    returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    // if (minToAppt <= 1) {
    //   returnDate[1] = 'En 1 minuto más';
    // } else if (minToAppt <= 60) {
    //   returnDate[1] = `En ${minToAppt} minutos más`;
    // } else {
    //   const auxApptDateHour = apptDate.getHours();
    //   const auxApptDateMin = apptDate.getMinutes();
    //   let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
    //   let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
    //   returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    // }
    // if (this.isToday(apptDate, now)) {
    //   returnDate[0] = 'Hoy ' + returnDate[0];
    // }
    // if (minToAppt < 0) {
    //   return [];
    // }
    return returnDate;
  }

  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }



  dia_seleccionado: boolean = false;      //si se selecciona un dia
  fechaSelected: any = {};
  appointmentsDay: any;

  clickDayCalendar($event){ // Trae la informacion seleccionada
    this.dia_seleccionado = false;
    this.appointmentsDay = [];
    this.fechaSelected = $event;
    this.fechaSelected.dayTitle = this.fechaSelected.date.getDate() +' de '+ (this.fechaSelected.date).toLocaleString('es-US', this.options_month);
    //console.log(this.fechaSelected);
    //console.log(formatDate(this.fechaSelected.date, 'yyyyMMdd', 'en-US'));

    for (let appo of this.appointments){
      if( appo.consulta.fecha_consulta.substring(0,10).replace(/-/g,'') == formatDate(this.fechaSelected.date, 'yyyyMMdd', 'en-US')){
        this.dia_seleccionado = true;
        this.appointmentsDay.push(appo)
      }
    }
  }

  sendToCalendar(eventsX) {
    let events_calendar: Array<CalendarEvent> = []; //Creacion de los eventos para enviarlos al calendario
    //console.log(eventsX);

    for (let eve of eventsX){
      events_calendar.push({
        start: new Date(eve.consulta.fecha_consulta.substr(0, 10).replaceAll('-','/')), //va solo date, podrian ir timestamp
        title: eve.consulta.id_consulta,
      });
    }
    this.CALENDAR.sendToCalendar(events_calendar);
  }

}
