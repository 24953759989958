<div [class]="video_iniciado? 'divLlamada divConLLamada':'divLlamada divSinLLamada' ">
     <div *ngIf="!(video_iniciado)" class="divSinLLamadaCamara"><mat-icon
               style="font-size: 70px;height: auto; width: auto;">videocam</mat-icon></div>
     <div style="height: 5%;"></div>
     <div *ngIf="video_iniciado" class="divVideos">
          <ng-container *ngIf="userVideoList && userVideoList.length >0">
               <!-- <ng-container *ngIf="userVideoList && userVideoList[0] && userVideoList.length == 1; else moreThanOne">
                         <div class="video-container">
                              <p class="nombreParticipante" *ngIf="userVideoList[0].userName">{{userVideoList[0].userName}}</p>
                              <video class="videoElement" *ngIf="userVideoList[0].videollamada" [srcObject]="userVideoList[0].videollamada" [muted]=" true" playsinline autoplay>
                              </video>
                         </div>
                    </ng-container> -->
               <!-- <ng-container> -->
               <div class="row contenerdor-pantallas">
                    <div class="divVideosInferior col-md-2 ">
                    </div>
                    <!-- Si es que tiene mas de un participante -->
                    <div class="divVideosSuperior col-md-8 p-0">
                         <!-- Participantes de la fila superior -->
                         <ng-container *ngFor="let elementoVideo of userVideoList;">
                              <div class="videosSuperior" *ngIf="elementoVideo.idSocket == userVideoSelected">
                                   <div class="video-container">
                                        <p class="nombreParticipante" *ngIf="elementoVideo.userName">
                                             {{elementoVideo.userName}}</p>
                                        <video class="videoElement " *ngIf="elementoVideo.videollamada"
                                             [srcObject]="elementoVideo.videollamada"
                                             [muted]=" elementoVideo.idSocket == userVideoList[0].idSocket? true:false"
                                             playsinline autoplay></video>
                                        <div style="position: absolute;bottom: 2px;margin-left: 8%;">
                                             <!-- <button data-toggle="tooltip" data-placement="top" title="Fijar usuario" class="buttonWithoutBorder">
                                                  <mat-icon class="buttonBar extendButton">push_pin</mat-icon></button> -->
                                        </div>
                                   </div>
                              </div>
                         </ng-container>


                    </div>
                    <div class="divVideosInferior col-md-2 p-0">
                         <!-- Participante de la fila inferior -->
                         <ng-container *ngFor="let elementoVideo of userVideoList;">
                              <div *ngIf="elementoVideo.idSocket != userVideoSelected">
                                   <div class="video-container">
                                        <p class="nombreParticipante" *ngIf="elementoVideo.userName">{{
                                             elementoVideo.userName }}</p>
                                        <video class="videoElement" *ngIf="elementoVideo.videollamada"
                                             [srcObject]="elementoVideo.videollamada"
                                             [muted]="elementoVideo.idSocket == userVideoList[0].idSocket ? true : false"
                                             playsinline autoplay></video>
                                        <div style="position: absolute; bottom: 2px; margin-left: 8%;">
                                             <button data-toggle="tooltip" data-placement="top" title="Fijar usuario"
                                                  (click)="pinUser(elementoVideo)" class="buttonWithoutBorder">
                                                  <mat-icon class="buttonBar extendButton">push_pin</mat-icon>
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </ng-container>
                    </div>
               </div>
               <!-- </ng-container> -->
          </ng-container>
          <div class="contenedor-iconos-video" >
               <button data-toggle="tooltip" class="buttonWithoutBorder" data-placement="top"
                    [title]="!muteFlag? 'Desactivar micrófono':'Activar micrófono'" (click)='muteButtonFunction()'>
                    <mat-icon [ngClass]="{'buttonBar': true, 'buttonTrue': !muteFlag, 'buttonFalse': muteFlag}">
                         {{ muteFlag ? 'mic_off' : 'mic' }}
                    </mat-icon>
               </button>
               <button data-toggle="tooltip" data-placement="top"
                    [title]="!muteFlag? 'Desactivar cámara':'Activar cámara'" (click)='hideButtonFunction()'
                    class="centralButton buttonWithoutBorder">
                    <mat-icon
                         [ngClass]="{'buttonBar': true, 'buttonTrue': !hideCameraFlag, 'buttonFalse': hideCameraFlag}">
                         {{ !hideCameraFlag ? 'videocam' : 'videocam_off' }}
                    </mat-icon>
               </button>
               <button (click)='leaveButtonFunction();endMeeting()' class="buttonWithoutBorder" data-toggle="tooltip"
                    data-placement="top" title="Abandonar la llamada">
                    <mat-icon class=" buttonBar buttonFalse">call_end</mat-icon>
               </button>

          </div>
     </div>
</div>