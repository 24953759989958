import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class MedicosService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
    public _generalServices: GeneralService) { }
  
  
    
  
    async postListarMedicos() {
    
      let httpOptions = {
        headers: new HttpHeaders({
          'authorization': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        })
      };
      let params = {}
  
      try {
        let data = await this.http.post(URL_SERVICIOS + '/postListarMedicos', JSON.stringify(params), httpOptions ).toPromise()
        //console.log(data);
        
        return data;
      }
      catch (error) {
        this._errors.requestError(error)
        throw error;
      }
  }
  

  
  async postListarDoctor(nombre,apellido) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      nombre:nombre,
      apellidoPaterno:apellido
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListarDoctor', params, httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
}

  async postCrearDoctor(params,firma,foto) {
    const fd = new FormData();

    fd.append('firma', firma, firma.name);
    fd.append('foto', foto, foto.name);

    fd.append('id_especialidad', JSON.stringify(params.id_especialidad));
    fd.append('nombres', params.nombres);
    fd.append('apellido_paterno',params.apellido_paterno);
    fd.append('apellido_materno',params.apellido_materno);
    fd.append('rut',params.rut);
    fd.append('fecha_nacimiento',params.fecha_nacimiento);
    fd.append('genero',params.genero);
    fd.append('id_region',params.id_region);
    fd.append('id_provincia',params.id_provincia);
    fd.append('id_comuna',params.id_comuna);
    fd.append('calle',params.calle);
    fd.append('numero_calle',params.numero_calle);
    fd.append('telefono_movil',params.telefono_movil);
    fd.append('telefono',params.telefono);
    fd.append('email',params.email);
    fd.append('password',params.password);
    fd.append('id_titulo',params.id_titulo);
    fd.append('fecha_egreso',params.fecha_egreso);
    fd.append('id_institucion',params.id_institucion);
    fd.append('sis_doctor', params.sis_doctor);
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token')
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postCrearDoctor', fd, httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async postModificarDoctor(params) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postModificarDoctor', JSON.stringify(params), httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  

  async postDeleteDoctor(params) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postDeleteDoctor', params, httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }


  async postUpdateSheduleState(id_consulta) {
    
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let dispositivo = this._generalServices.getDevice();

    let params = {
      id_consulta: id_consulta,
      device_type: dispositivo.type
    } 

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postUpdateSheduleState', params, httpOptions ).toPromise()      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postSaveFirma(doc:File , use_id) {
    
    const fd = new FormData();
    fd.append('file',doc,doc.name);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    fd.append('use_id',use_id);

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postSaveDocFirm',fd, httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

  async postSaveDocImage(doc:File , use_id) {
    
    const fd = new FormData();
    fd.append('file',doc,doc.name);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };

    fd.append('use_id',use_id);

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postSaveDocImage',fd, httpOptions ).toPromise()
      //console.log(data);
      
      return data;
    }
    catch (error) {
      this._errors.requestError(error)
      throw error;
    }
  }

}
