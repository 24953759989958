<!-- Titular -->
<div>
  <br>
  <div class="minititulo">
    Mi perfil
  </div>
  <div class="titulo">
    Mis beneficiarios
  </div>
  <div class="divGoBack">
    <p *ngIf="page.two" class="pGoBack" (click)="goBack()"><mat-icon>chevron_left</mat-icon> volver</p>
  </div>
  <br>
  <br>
</div>

<ng-container *ngIf="page.one">
  <div class="card divHeader">
    <div class="card-body">
      <div class="row">
        <div class="d-flex flex-row comment-row">
          <div class="p-2"><span class=""><img class="img-circle img-responsive imagentamano"
                src="./assets/images/MEDIS.LOGO.RRSS-06.png" alt="user" width="50"></span></div>
          <div class="comment-text w-100">
            <p class="tamanoP">Titular</p>
            <h5 class="nombretitular">{{usuario.use_name}} {{usuario.use_paternal_surname}}</h5>
            <p class="ruttitular">{{usuario.rut}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="textTitle">
    Convenios y planes
  </p>

  <div class="cardAlerta">
    <table>
      <tr>
        <td>
          <mat-icon class="infoIcon ">info</mat-icon>
        </td>
        <td>
          <p *ngIf="planes && planes.length==0" class="textAlerta">No tienes convenios o planes activos donde seas el
            titular.</p>
          <p *ngIf="planes && planes.length>0" class="textAlerta">Convenios y planes activos donde eres el titular.</p>
        </td>
      </tr>
    </table>
  </div>

  <div class="row">
    <div *ngFor="let plan of planes" class="cardNgFor col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
      <div class="cardPlan">
        <div class="row" style="margin: 10px 0px;">
          <div class="col-4 divImgPlan">
            <img [src]="plan.image_plan" alt="auto" width="80%" class="centeredElement">
          </div>
          <div class="col-8">
            <p class="textCardTitle">
              {{plan.cus_ben_name}}
            </p>

            <table>
              <tr style="margin-bottom: 10px;">
                <td>
                  <p class="textCardSubTitle">Inicio de vigencia: </p>
                </td>
                <td>
                  <p *ngIf="plan.cus_dat_from_date" class="textCardSubTitle">
                    {{plan.cus_dat_from_date}}</p>
                  <p *ngIf="!plan.cus_dat_from_date" class="textCardSubTitle">-.</p>
                </td>
              </tr>
              <tr style="margin-bottom: 10px;">
                <td>
                  <p class="textCardSubTitle">Término de vigencia: </p>
                </td>
                <td>
                  <p *ngIf="plan.cus_dat_to_date" class="textCardSubTitle">
                    {{plan.cus_dat_to_date}}</p>
                  <p *ngIf="!plan.cus_dat_to_date" class="textCardSubTitle">-.</p>
                </td>
              </tr>
              <tr style="margin-bottom: 10px;">
                <td>
                  <p class="textCardSubTitle">Tipo de usuario: </p>
                </td>
                <td>
                  <p *ngIf="plan.ben_main_use_id == usuario._id" class="textCardSubTitle">Titular
                  </p>
                  <p *ngIf="plan.ben_main_use_id != usuario._id" class="textCardSubTitle">
                    Beneficiario</p>
                </td>
              </tr>
            </table>
            <div class="divButtonPlan">
              <button class="buttonPlan" (click)="showPlan(plan)" data-toggle="modal" data-target="#showPlanModal">
                <p class="textButton">Ver beneficiarios</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="page.two">
  <div class="cardPlanBeneficiaries">
    <div class="row" style="width:100%;">
      <div class="col-3">
        <div class="divImgPlan">
          <img [src]="plan_selected.image_plan" alt="auto" width="90%" class="centeredElement">
        </div>
      </div>
      <div class="col-9">
        <p class="titleHeaderPlan">{{plan_selected.cus_ben_name}}</p>
        <p *ngIf="plan_selected.cus_dat_from_date" class="subTitleHeaderPlan">Inicio de vigencia: {{plan_selected.cus_dat_from_date}}</p>
        <p *ngIf="!plan_selected.cus_dat_from_date" class="subTitleHeaderPlan">Inicio de vigencia: .-</p>

        <p *ngIf="plan_selected.cus_dat_from_date" class="subTitleHeaderPlan">Término de vigencia: {{plan_selected.cus_dat_to_date}}</p>
        <p *ngIf="!plan_selected.cus_dat_from_date" class="subTitleHeaderPlan">Término de vigencia: .-</p>
      </div>
    </div>
  </div>
  <!-- Beneficiario -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 titulobeneficiario">
        Beneficiarios agregados
        <!-- Beneficiarios agregados ({{this.beneficiarios_agregados}} de {{this.beneficiarios_totales}}) -->
      </div>
      <ng-container *ngIf="plan_selected.cus_ben_qty_beneficiaries">
        <div class="col-md-6 colorletrasboton">
          <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal"
            data-target="#agregarPaciente">
            Agregar beneficiario
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row card-body-beneficiario">
    <ng-container *ngFor="let item of plan_selected.beneficiarios ; let i = index;">
      <div class="card col-md-6">
        <div class="card-body bordeCardBeneficiario">
          <div class="d-flex flex-row comment-row">
            <div class="comment-text w-100">
              <div class="d-flex no-block">
                <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                  <a *ngIf="item.ben_use_id != item.ben_main_use_id" class="icon-options-vertical link" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item cursor" (click)="eliminarBeneficiario(item)" data-toggle="modal"
                      data-target="#eliminarPaciente">Eliminar de mis beneficiarios</a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">Nombre y apellido:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase"> {{item.use_name}}
                      {{item.use_paternal_surname}} </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">RUT:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase"> {{item.use_rut}} </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">Fecha de ingreso:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_creation_date}} </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">Tipo de usuario:</label>
                  <div class="col-md-7">
                    <p *ngIf="item.ben_use_id == item.ben_main_use_id"
                      class="form-control-static letrasCardBeneficiarioBase">Titular</p>
                    <p *ngIf="item.ben_use_id != item.ben_main_use_id"
                      class="form-control-static letrasCardBeneficiarioBase">Beneficiario </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>




    <!-- Beneficiarios sin usuarios -->


  <ng-container *ngIf="plan_selected.beneficiarios_sin_cuenta">
    <ng-container *ngFor="let item of plan_selected.beneficiarios_sin_cuenta ; let i = index;">
      <div class="card col-md-6">
        <div class="card-body bordeCardBeneficiario">
          <div class="d-flex flex-row comment-row">
            <div class="comment-text w-100">
              <div class="d-flex no-block">
                <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                  <a class="icon-options-vertical link" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item cursor" (click)="eliminarBeneficiario(item)" data-toggle="modal"
                      data-target="#eliminarPaciente">Eliminar de mis beneficiarios</a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">Nombre y apellido:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase">Sin información</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">RUT:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase">{{item.cus_dat_ben_rut}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">Fecha de ingreso:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase">{{item.fecha_creacion}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="letrasCardBeneficiario control-label text-left col-md-5">Tipo de usuario:</label>
                  <div class="col-md-7">
                    <p class="form-control-static letrasCardBeneficiarioBase">Beneficiario </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  </div>
  <!-- fin Beneficiario-->



<!-- Modal Agregar  -->
<div class="modal fade" id="agregarPaciente" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal">Agregar beneficiario</p>
        <br>
        <div class="">
          <br>
          <form class="form col-md-12" id="familyForm" autocomplete="off" [formGroup]="familyForm"
            (ngSubmit)="crearIntegrante()">
            <div class="form-group row">
              <div class="col-md-12">
                <input class="form-control inputModal" type="text" (change)="validaRut()"
                  placeholder="Ingresa el RUT del integrante" formControlName="rut">
              </div>
            </div>
            <div class="row">
              <div class="col text-danger">
                <p *ngIf="campoNoValido('rut')"> El rut es obligatorio</p>
                <p *ngIf="campoNoValido('rut')"> El rut ingresado no es valido</p>
              </div>
            </div>
            <br>
            <br>

            <div class="form-group row">
              <div class="col-md-12">
                <p class="textoModal">Una vez ingresado el beneficiario, </p>
                <p class="textoModal">este deberá crear su cuenta en {{plan_selected.url_customer}}</p>
              </div>
            </div>

            <br>
            <br>
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                    data-dismiss="modal">Cerrar formulario</button>
                </div>
                <div class="col-md-6 letrabotonagregar">
                  <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-toggle="modal"
                    data-target="#exitoAgregar">Agregar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Eliminar -->
<div class="modal fade" id="eliminarPaciente" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p class="tituloModal" style="text-align: center;">Atención</p>
        <br><br>
        <div class="form-group row">
          <div class="col-md-12">
            <p class="textoModal">Una vez eliminado, esta persona ya no podrá acceder a los beneficios de Almamedis.</p>
            <!-- <p class="textoModal">acceder a los beneficios de Almamedis</p> -->
          </div>
        </div>
        <br><br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" (click)="botonEliminarBeneficiario()"
                class="btn btn-outline-primary btn-lg botonModalAgregar">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
