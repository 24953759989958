import { AgendaMedico2Component } from 'src/app/components/agenda-medico2/agenda-medico2.component';
import { CompleterService, CompleterData } from 'ng2-completer';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { PlansService } from '../../../services/plans.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleService } from 'src/app/services/schedule.service';
import { URL_DOCS } from 'src/app/config/config';
import Swal from 'sweetalert2';
import { PaymentButtonComponent } from 'src/app/components/payment-button/payment-button.component';
import { CanDeactivateGuard } from '../../../services/can-deactivate.guard';

@Component({
  selector: 'app-reserva-hora',
  templateUrl: './reserva-hora.component.html',
  styleUrls: ['./reserva-hora.component.css']
})
export class ReservaHoraComponent implements OnInit {
  @Input() vista_externa: boolean = false; //Se ocupa cuando se llama el componente de un perfil que no es el propio del beneficiario
  @Input() beneficiario_externo: any = false; //Se ocupa cuando se llama el componente de un perfil que no es el propio del beneficiario
  _title = 'Mi agenda';
  _subtitle = 'Reserva tu hora'
  @ViewChild('ErrorModal') trigErrorModal: ElementRef;
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha

  _usuario: any = {};  // usuario actual
  hoursDay: any = {};              // Array de bloques de horas 
  daySelected: any = {};    //dia seleccionado
  @ViewChild(AgendaMedico2Component) AGENDA_MEDICO: AgendaMedico2Component; //para llamar las funciones contenidas en ese componente
  @ViewChild(PaymentButtonComponent) payment_button: PaymentButtonComponent; //para llamar las funciones contenidas en ese componente
  @ViewChild('acceptTerminos') exampleModalLong: ElementRef;

  data_appo: any = {};
  data_pending_appo: any = {};
  appointment_reason = '';
  benef_cargado;
  beneficiary_list;
  beneficiary_id;
  speciality_id;

  acompanantes_disp: any = {};
  acompanante_selected: any = {};
  acompanante_id = 0;
  sub_speciality_id;
  id_consulta_reservada: string;

  usuario: any = {};  // usuario actual
  // ben;

  URL_DOCUMENTS = URL_DOCS;

  doctorList: any = [];
  specialityList: any = [];
  availabilityDoctorHourList: any = [];

  showOptionMedical: boolean = true;
  showSuccessMessage: boolean;

  strDoctorName: string;
  subtitle: string;
  completerData: CompleterData;

  doctorSelected: any = {};
  hourSelected: any = {};

  steps: any = {
    searchDoctor: false,
    selectDate: false,
    completeForm: false,
    payment: false,
    showSummary: false
  }
  acceptTerms = false;
  paymentMethod = false;


  compatibleSpecialities: any = [];

  beneficiariesXPlans: any = [];
  plansXSpeciality: any = [];
  beneficiariesFilteredBySpeciality: any = [];
  beneficiarySelected: any = {};
  planesXBeneficiario: any = {};
  planesDisponibles: any = {};
  planesSelected: any = {};
  acompList: any[] = [];
  beneficiarieSelectedValue: any = '';


  specialityListByDoctor: any = [];
  specialitySelected: any = {};
  specialitySelectedValue: any = '';
  errorMsg: any = '';

  planSelected: any = {};
  discountCode: any = '';
  discountCodeObject: any = {};
  paymentSelected: any = {};



  paymentInfo: any = {
    originalValue: 0,
    inssuranceDiscount: null,
    inssuranceDiscountValue: 0,
    discountValue: 0,
    finalValue: 0,
    transaction: {
      url: null,
      token: null,
    },
  }
  MatDialogRef: any;
  pageInfo: any = {};

  constructor(
    public generalService: GeneralService,
    private completerService: CompleterService,
    public scheduleService: ScheduleService,
    public router: Router,
    private renderer: Renderer2,
    public plansService: PlansService,
    private activatedRoute: ActivatedRoute,
    public confirmDialog: CanDeactivateGuard
  ) {

    this.activatedRoute.params.subscribe(parametros => {
      if (parametros.id_consulta) {
        this.obtenerConsulta(parametros.id_consulta);
      }
    });
    this.pageInfo = new EncriptacionService().getPageInfo();
    // console.log(this.pageInfo, 'pageInfo');

  }



  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo()
    if (!this.vista_externa) {
      this._usuario = new EncriptacionService().getUserInfo();
    } else {
      this._usuario = this.beneficiario_externo;
    }
    // console.log(this._usuario, '_usuario-ReservaHoraComponent');
    this.showSuccessMessage = false;
    this.steps = {
      searchDoctor: true,
      selectDate: false,
      completeForm: false,
      payment: false,
      showSummary: false,
    }

    this.changeSubtitle(1);
    this.getDoctorList(null, null, null, null);
    this.getSpecialityList();
    this.postListBeneficiariesXPlans();
  }


  obtenerConsulta(id_consulta) {
    this.generalService.modalCargando();
    this.scheduleService.getListarConsultasPendientes(id_consulta)
      .then(resultado => {
        // console.log(resultado);
        Swal.close();
        if (!resultado) {
          this.generalService.modalError("Reserva no disponible", "Esta reserva ya no se encuentra como pendiente de pago");
          var url = "dashboard";
          this.router.navigate([url]);
          return;
        }
        this.data_pending_appo = resultado;
        this.steps = {
          searchDoctor: false,
          selectDate: false,
          completeForm: false,
          payment: true,
          showSummary: false,
        }
        this.modifyData(resultado);
        this.changeSubtitle(4);
      }).catch(error => {
        // console.log(error);
      });
  }

  modifyData(data: any) {
    this.doctorSelected.att_path = data.att_path;
    this.doctorSelected.nombre_completo = data.nombre_doctor;
    this.doctorSelected.att_path = data.att_path;
    this.specialitySelected.spe_name = data.spe_name;
    this.daySelected.dateString = data.hoy + data.dia + ' de ' + data.mes.toLowerCase();
    this.hourSelected.from = data.hora;
    this.hourSelected.to = data.hora_fin;
    this.planSelected.ben_id = data.ben_id;
    this.paymentInfo.finalValue = data.cha_final_value;
    this.paymentInfo.originalValue = data.cha_value_no_discount;
    this.paymentInfo.inssuranceDiscountValue = data.cha_insurance_discount_value;
    this.paymentInfo.discountValue = data.cha_discount_value;
  }

  postListBeneficiariesXPlans() {
    this.plansService.postListBeneficiariesXPlans(this._usuario.use_id ? this._usuario.use_id : this._usuario._id)
      .then((data: any) => {
        // console.log(data, 'data-postListBeneficiariesXPlans');
        if (data !== null && data.length > 0) {
          this.beneficiariesXPlans = data
          this.changeBeneficiary(this._usuario.use_id ? this._usuario.use_id : this._usuario._id);
        }
      })
      .catch(e => console.error(e));

  }



  //Consulta todas las especialidades
  getSpecialityList() {
    this.generalService.getSpecialityList()
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          this.specialityList = data;
          //console.log(this.specialityList,'specialityList-getSpecialityList');

        }
      })
      .catch(e => console.error(e));
  }

  onChangeSpeciality(specialityId: string) {
    this.getDoctorList(null, specialityId, null, false);
  }

  //Consulta medicos 
  getDoctorList(doctorID: number = null, specialityIdSelected: string = null, zoom: boolean = null, hours_disp: boolean = null) {
    this.generalService.modalCargando();
    this.generalService.getDoctorList(doctorID, specialityIdSelected, zoom, hours_disp)
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data);

          this.doctorList = data;

          for (let doc of this.doctorList) {
            doc.nombre_completo = doc.use_name + ' ' + doc.use_paternal_surname + ' ' + doc.use_maternal_surname;
            try {
              //doc.primera_hora = new Date(doc.horas_disponibles[0].sch_date, doc.horas_disponibles[0].sch_hou_from);
              doc.primera_hora = new Date(doc.horas_disponibles[0].sch_date.substr(0, 4), (doc.horas_disponibles[0].sch_date.substr(5, 2) - 1), doc.horas_disponibles[0].sch_date.substr(8, 2), doc.horas_disponibles[0].sch_hou_from.substr(0, 2), doc.horas_disponibles[0].sch_hou_from.substr(3, 2));
              doc.primera_fecha = doc.primera_hora.toLocaleString('es-US', this.options);
              doc.primera_fecha = this.normalizarFecha(doc.primera_fecha);
              // doc.primera_fecha = doc.primera_fecha.substring(0,doc.primera_fecha.length-8);
            } catch (e) {
              // console.log(e);
            }
            try {
              doc.segunda_hora = new Date(doc.horas_disponibles[1].sch_date.substr(0, 4), (doc.horas_disponibles[1].sch_date.substr(5, 2) - 1), doc.horas_disponibles[1].sch_date.substr(8, 2), doc.horas_disponibles[1].sch_hou_from.substr(0, 2), doc.horas_disponibles[1].sch_hou_from.substr(3, 2));
              doc.segunda_fecha = doc.segunda_hora.toLocaleString('es-US', this.options);
              doc.segunda_fecha = this.normalizarFecha(doc.segunda_fecha);
              //doc.segunda_fecha = doc.segunda_hora.toLocaleString('es-US', this.options);
              // doc.segunda_fecha = doc.segunda_fecha.substring(0,doc.segunda_fecha.length-8);
            } catch (e) {
              // console.log(e);
            }
          }
          this.doctorList.sort(this.compare);
          // this.doctorList.reverse();
          this.completerData = this.completerService.local(this.doctorList, 'nombre_completo', 'nombre_completo');
          // this.getSchedulesByDoctor();
          //console.log(this.doctorList);
          this.reordenarDoctorList();

        }
        else {
          this.doctorList = [];
        }
        Swal.close();
      })
      .catch(e => {
        console.error(e);
        Swal.close();
      });
  }

  normalizarFecha(input) {
    input = input.toLowerCase();
    input = input.replace(",", "");
    return input.substring(0, 1).toUpperCase() + input.substring(1);
  }

  changeDoctor($event) {
    if ($event) {
      this.doctorSelected = $event.originalObject;
      this.getDoctorList(this.doctorSelected.doc_id, null);
    } else {
      this.getDoctorList();
    }
  }

  compare(a, b) {
    // //console.log(a);
    // //console.log(b);
    try {
      if (!a.primera_hora && !b.primera_hora && !a.segunda_hora && !b.segunda_hora) {
        ////console.log("Iguales");
        return 0;
      } else if (a.primera_hora && !b.primera_hora) {
        ////console.log("A Mayor");
        return 1;
      } else if (!a.primera_hora && b.primera_hora) {
        ////console.log("A Menor");
        return -1;
      } else if (a.segunda_hora && !b.segunda_hora) {
        ////console.log("A Mayor");
        return 1;
      } else if (!a.segunda_hora && b.segunda_hora) {
        ////console.log("A Menor");
        return -1;
      } else {
        if (a.primera_hora > b.primera_hora) {
          ////console.log("A Mayor");
          return 1;
        } else if (b.primera_hora > a.primera_hora) {
          ////console.log("A Menor");
          return -1;
        } else if (a.segunda_hora > b.segunda_hora) {
          ////console.log("A Mayor");
          return 1;
        } else if (b.segunda_hora > a.segunda_hora) {
          ////console.log("B Menor");
          return -1;
        } else {

          ////console.log("Iguales");
          return 0;
        }
      }
    } catch (e) { console.log(e); }
    return 0;
  }

  reordenarDoctorList() {
    var list_con_horas = [];
    var list_sin_horas = [];
    var list_unida = [];
    for (let index = 0; index < this.doctorList.length; index++) {
      const element = this.doctorList[index];
      if (element.primera_hora) {
        list_con_horas.push(element);
      } else {
        list_sin_horas.push(element);
      }
    }
    for (let index = 0; index < list_con_horas.length; index++) {
      const element = list_con_horas[index];
      list_unida.push(element);
    }
    for (let index = 0; index < list_sin_horas.length; index++) {
      const element = list_sin_horas[index];
      list_unida.push(element);
    }

    this.doctorList = list_unida;
    //console.log(list_unida);

  }

  // horas disponibles doctor
  horasDisponibles(doc_id) {
    this.scheduleService.getScheduleHoursDisp(doc_id, null, true)
      .then(data => {
        // console.log(data);

        this.doctorSelected.hours_of_month = data;
        this.AGENDA_MEDICO.sendToAgendaMedico(this.doctorSelected.hours_of_month, this.doctorSelected.primera_hora); // envia la data al componentes, (array, date)
      })
  }

  // Respuesta del componente al hacer click sobre 1 hora
  clickHourAgendaMedico(data) {
    this.daySelected = data.day;
    this.hourSelected = data.hour;
    this.redirectToStep3(this.hourSelected)
    this.changeBeneficiary(this._usuario.use_id ? this._usuario.use_id : this._usuario._id)
  }

  // Respuesta del componente al hacer click sobre 1 hora
  clickHourDoctor(data, posicion, fecha) {
    this.doctorSelected = data; //this.doctor_selected = doctor;
    // console.log(data, 'doctorSelected');

    this.resetSpecialities(data);

    this.daySelected = {};                //reinicio el dia seleccionado
    this.appointment_reason = '';
    this.steps.searchDoctor = false;
    this.steps.selectDate = true;



    this.daySelected.dateInt = data.horas_disponibles[posicion].sch_date.substr(0, 4) + data.horas_disponibles[posicion].sch_date.substr(5, 2) + data.horas_disponibles[posicion].sch_date.substr(8, 2);
    this.daySelected.dateString = fecha.toLocaleString('es-US', this.options);

    this.hourSelected = data.horas_disponibles[posicion];
    this.hourSelected.from = this.hourSelected.sch_hou_from;
    this.hourSelected.to = this.hourSelected.sch_hou_to;
    this.redirectToStep3(this.hourSelected)
    this.changeBeneficiary(this._usuario.use_id ? this._usuario.use_id : this._usuario._id)
  }


  reservar() {
    this.data_appo.reason = this.appointment_reason;
    this.data_appo.symptoms = '';
    //Revisar
    this.data_appo.acompanante_use_id = (this.acompanante_id == 0) ? null : this.acompanante_id;
  }





  planRowSelected(plan: any) {
    this.paymentInfo.inssuranceDiscount = null;
    this.paymentInfo.inssuranceDiscountValue = 0;

    if (plan.ben_id == this.planSelected.ben_id) {
      this.planSelected = {};
    } else {
      this.planSelected = plan;
      for (let index = 0; index < plan.especialidades.length; index++) {
        const element = plan.especialidades[index];
        if (this.specialitySelected.spe_id == element.spe_id) {
          this.paymentInfo.inssuranceDiscount = element.porcentaje_descuento / 100;
          break;
        }
      }
    }
    if (this.paymentInfo.inssuranceDiscount) {
      this.paymentInfo.inssuranceDiscountValue = this.paymentInfo.originalValue * this.paymentInfo.inssuranceDiscount;
    }

    this.calculateFinalValue();
  }



  calculateFinalValue() {
    this.paymentInfo.finalValue = this.paymentInfo.originalValue;
    if (this.paymentInfo.inssuranceDiscountValue && this.paymentInfo.inssuranceDiscountValue > 0) {
      this.paymentInfo.finalValue = this.paymentInfo.originalValue - this.paymentInfo.inssuranceDiscountValue;
    }

    if (this.discountCodeObject.dis_id) {
      let value = this.discountCodeObject.value;
      let subTotal = this.paymentInfo.finalValue;
      let finalValue = value;
      let delta;
      if (this.discountCodeObject.dis_typ_id == 1) {
        delta = subTotal * finalValue;
      } else if (this.discountCodeObject.dis_typ_id == 2) {
        delta = finalValue;
      }
      this.paymentInfo.discountValue = delta > subTotal ? subTotal : delta;

      this.paymentInfo.finalValue = subTotal - this.paymentInfo.discountValue;
      // console.log(this.paymentInfo.finalValue);
    }

  }


  confirm() {

    this.generalService.modalCargando();
    this.scheduleService.scheduleAppointment(
      this.hourSelected.sch_hou_id,
      this.planSelected.ben_id,
      this.specialitySelectedValue,
      this.data_appo,
      this.beneficiarySelected.use_id,
      this.discountCodeObject.code
    )
      .then(async (data: any) => {
        // console.log(data);
        this.id_consulta_reservada = data.id;
        this.showSuccessMessage = false;
        this.steps.showSummary = false;
        if (data.transaction && data.transaction.url) {
          this.paymentInfo.transaction = data.transaction;
          await this.generalService.sleep(500);
          this.payment_button.pay();
          this.showSuccessMessage = false;
        } else {
          this.changeSubtitle(6);
          this.showSuccessMessage = true;
          Swal.close();
        }
      })
      .catch(async error => {
        this.errorMsg = error.error.msg ? error.error.msg : 'Error a la hora de tomar la reserva';
        Swal.close();
        this.renderer.selectRootElement(this.trigErrorModal.nativeElement).click();
        if (error.status == 405) {
          this.ngOnInit();
        }
      })

  }

  redirectToStep1() {
    this.steps.searchDoctor = true;
  }

  redirectToStep2(doctorSelected: object) {        //hhoras disponibles doctor
    //console.log(doctorSelected, 'doctorSelected');

    this.doctorSelected = doctorSelected; //this.doctor_selected = doctor;
    this.resetSpecialities(doctorSelected);
    this.daySelected = {};                //reinicio el dia seleccionado
    this.appointment_reason = '';
    this.steps.searchDoctor = false;
    this.steps.selectDate = true;

    this.horasDisponibles(this.doctorSelected.doc_id)
    this.changeSubtitle(2);
  }

  redirectToStep3(item: any) {       //selecciona una hora de un dia
    this.steps.selectDate = false;
    this.steps.completeForm = true;
    this.changeSubtitle(3);
  }



  changeBeneficiary(use_id) {
    this.acompList = [];
    this.beneficiary_id = use_id;
    this.beneficiarySelected = undefined; // Agregamos esta línea para asegurar que beneficiarySelected esté definido.
    this.specialitySelectedValue = '';
    for (let index = 0; index < this.beneficiariesXPlans.length; index++) {
      const element = this.beneficiariesXPlans[index];

      if (element.use_id == use_id) {
        this.beneficiarySelected = element;
      } else {
        if (element.user_age > 15) {
          this.acompList.push(element);
        }
      }
    }

    this.compatibleSpecialities = [];
    // console.log(this.doctorSelected);
    if (this.doctorSelected && this.doctorSelected.specialties) {      
      if (this.beneficiarySelected && this.beneficiarySelected.planes && this.beneficiarySelected.planes.length > 0 && !this.pageInfo.cus_can_pay) {
  
        const uniqueSpeIds = new Set(); // Conjunto para almacenar spe_ids únicos
  
        for (let i = 0; i < this.beneficiarySelected.planes.length; i++) {
          const plan = this.beneficiarySelected.planes[i];
          // console.log(plan);
  
          for (let j = 0; j < plan.especialidades.length; j++) {
            const speciality = plan.especialidades[j];
            for (let k = 0; k < this.doctorSelected.specialties.length; k++) {
              const doctorSpeciality = this.doctorSelected.specialties[k];
              if (
                doctorSpeciality.spe_id == speciality.spe_id &&
                this.beneficiarySelected.user_age >= doctorSpeciality.spe_min_age &&
                this.beneficiarySelected.user_age <= doctorSpeciality.spe_max_age && speciality.porcentaje_descuento >= 100
              ) {
                uniqueSpeIds.add(doctorSpeciality.spe_id); // Agregar a conjunto
              }
            }
          }
        }
  
        // Convertir el conjunto de spe_ids únicos en un array
        this.compatibleSpecialities = Array.from(uniqueSpeIds).map(spe_id => {
          return this.doctorSelected.specialties.find(specialty => specialty.spe_id === spe_id);
        });
      } else {
        const uniqueSpeIds = new Set(); // Conjunto para almacenar spe_ids únicos
  
        for (let i = 0; i < this.doctorSelected.specialties.length; i++) {
          const doctorSpeciality = this.doctorSelected.specialties[i];
          if (
            this.beneficiarySelected &&
            this.beneficiarySelected.user_age >= doctorSpeciality.spe_min_age &&
            this.beneficiarySelected.user_age <= doctorSpeciality.spe_max_age
          ) {
            uniqueSpeIds.add(doctorSpeciality.spe_id); // Agregar a conjunto
          }
        }
  
        // Convertir el conjunto de spe_ids únicos en un array
        this.compatibleSpecialities = Array.from(uniqueSpeIds).map(spe_id => {
          return this.doctorSelected.specialties.find(specialty => specialty.spe_id === spe_id);
        });
      }
    }



    // console.log(this.beneficiarySelected, 'beneficiarySelected');
    // console.log(this.compatibleSpecialities, 'compatibleSpecialities');
    // console.log(this.doctorSelected.specialties, 'compatibleSpecialities');

    this.changeSpecialityDisp();
  }






  changeSpeciality(spe_id) {
    this.specialitySelected = {};
    if (!spe_id) {
      return
    }

    for (let index = 0; index < this.doctorSelected.specialties.length; index++) {
      const element = this.doctorSelected.specialties[index];

      if (element.spe_id == spe_id) {
        this.specialitySelected = element;
        break;


      }
    }

    // console.log(this.specialitySelected);
    this.changeSpecialityDisp();
  }

  changeSpecialityDisp() {
    this.planesDisponibles = [];

    if (this.beneficiarySelected && this.beneficiarySelected.planes && this.specialitySelected.spe_id) {

      for (let index = 0; index < this.beneficiarySelected.planes.length; index++) {
        const element = this.beneficiarySelected.planes[index];
        if (element.especialidades.length > 0)

          for (let indice = 0; indice < element.especialidades.length; indice++) {
            const element2 = element.especialidades[indice];
            if (element2.spe_id == this.specialitySelected.spe_id && ((!this.pageInfo.cus_can_pay && element2.porcentaje_descuento >= 100) || this.pageInfo.cus_can_pay)) {
              this.planesDisponibles.push(element);
            }
          }


      }
    }
    // console.log(this.beneficiarySelected, ' planes disponibles');
  }

  // Cambiar Beneficiario





  redirectToStep4() {                  //ingresar reserva
    // if (this.beneficiarySelected.user_age < 15 && !this.acompanante_selected.use_id) {
    //   this.generalService.modalError('Los menores de edad deben ir acompañados de un adulto.', 'Favor seleccionar acompañante');
    //   return;
    // } else 
    if (!this.beneficiarySelected || !this.beneficiarySelected.use_id || !this.specialitySelectedValue || !this.appointment_reason) {
      this.generalService.modalError('Debes completar todos los campos para continuar.', '');
      return;
    } else if (!this.pageInfo.cus_can_pay && this.planesDisponibles.length == 0) {
      this.generalService.modalError('Sin planes disponibles.', 'No tienes planes o convenios activos que incluyan esta prestación.');
      return;
    }

    this.steps.completeForm = false;
    this.steps.payment = true;
    this.discountCodeObject = {};
    this.discountCode = '';

    this.paymentInfo.originalValue = 0;
    for (let index = 0; index < this.doctorSelected.specialties.length; index++) {
      const element = this.doctorSelected.specialties[index];
      if (element.spe_id == this.specialitySelected.spe_id) {
        this.paymentInfo.originalValue = element.pri_doc_price ? element.pri_doc_price : element.sub_spe_pri_price;
        break;
      }
    }

    this.calculateFinalValue();
    this.changeSubtitle(4);
  }

  validateTerms() {

    if (!this.paymentMethod && this.usuario.typ_id == 2 && this.paymentInfo.finalValue > 0 && !this.pageInfo.cus_can_pay) {
      this.generalService.modalError('Debes seleccionar un convenio para continuar.', '');

      return;
    }

    if (!this.paymentMethod && this.usuario.typ_id == 2 && this.paymentInfo.finalValue > 0 && this.pageInfo.cus_can_pay) {
      this.generalService.modalError('Debes seleccionar un metodo de pago para continuar.', '');

      return;
    }

    if (!this.acceptTerms && this.usuario.typ_id == 2) {
      this.generalService.modalError('Debe aceptar los términos y condiciones para poder atenderse a través de telemedicina.', '');

      return;
    }




    this.renderer.selectRootElement(this.exampleModalLong.nativeElement).click();



  }

  redirectToStep5() {                  //ingresar reserva
    // if (this.paymentInfo.finalValue > 0) {
    //   this.generalService.modalError('Error al pagar', 'Por el momento aun no esta implementado el pago.');
    //   return;
    // }

    this.reservar();
    // this.confirm();

    this.steps.payment = false;
    this.steps.showSummary = true;
    this.changeSubtitle(5);
  }

  redirectToPayment() {                  //ingresar reserva

    if (!this.acceptTerms && this.usuario.typ_id == 2 && !this.data_pending_appo.id_consulta) {
      this.generalService.modalError('Debe aceptar los términos y condiciones para poder atenderse a través de telemedicina.', '');

      return;
    }

    this.generalService.modalCargando();

    this.generalService.modalCargando();
    this.scheduleService.payAppointment(
      this.data_pending_appo.id_consulta
    )
      .then(async (data: any) => {
        // console.log(data);
        if (data.transaction && data.transaction.url) {
          this.paymentInfo.transaction = data.transaction;
          await this.generalService.sleep(500);
          this.payment_button.pay();
        } else {
          Swal.close();
        }
      })
      .catch(async error => {
        this.errorMsg = error.error.msg ? error.error.msg : 'Error a la hora de tomar la reserva';
        Swal.close();
        this.renderer.selectRootElement(this.trigErrorModal.nativeElement).click();
        if (error.status == 405) {
          this.ngOnInit();
        }
      })
  }


  changeSubtitle(step: number) {
    switch (step) {
      case 1:
        this.subtitle = 'Selecciona el médico y la fecha que más te acomode.';
        break;
      case 2:
        this.subtitle = 'Próxima hora disponible';
        break;
      case 3:
        this.subtitle = 'Completa tu formulario de reserva';
        break;
      case 4:
        this.subtitle = 'Pago de reserva';
        break;
      case 5:
        this.subtitle = 'Confirma tu reserva';
        break;
      case 6:
        this.subtitle = 'Reserva';
        break;
    }
  }

  checkStep() {

    this.steps.searchDoctor = true;
    this.steps.selectDate = false;
    this.steps.completeForm = false;
    this.steps.showSummary = false;
    this.steps.payment = false;
    this.changeBeneficiary(this._usuario.use_id ? this._usuario.use_id : this._usuario._id);
    this.changeSubtitle(1);
    this.resetSpecialities(null);


  }

  aMisProximasConsultas() {
    if (!this.vista_externa) {
      var url = "mis-proximas-consultas/";
      this.router.navigate([url]);
    } else {
      setTimeout(() => window.location.reload(), 1000);
    }
  }

  resetSpecialities(doctorData) {
    // //console.log(doctorData,'doctorData-resetSpecialities');

    this.specialityListByDoctor = doctorData ? doctorData.specialties : [];
    this.specialitySelected = {};
    this.specialitySelectedValue = '';
    // this.doctorSelected = {};
    this.acompanante_id = 0;
    this.appointment_reason = '';
    this.acceptTerms = false;
    this.paymentMethod = false;
    this.planSelected = {};
    this.paymentInfo = {
      originalValue: 0,
      inssuranceDiscount: null,
      inssuranceDiscountValue: 0,
      discountValue: 0,
      finalValue: 0
    }

    this.discountCode = '';
    this.discountCodeObject = {};
    // this.compatibleSpecialities = [];
  }





  // Cambiar acompañante
  changeAcompanante(use_id) {
    this.acompanante_selected = {};
    for (let aco of this.acompList) {
      if (aco.use_id == use_id) {
        this.acompanante_selected = aco;
        break;
      }
    }
    // console.log(this.acompanante_selected, 'acompanante_selected');

  }


  applyAgreement(porcentaje_descuento) {
    this.planSelected = {};
    if (!porcentaje_descuento) {
    } else {
    }
  }

  validarDcto() {
    this.discountCodeObject = {};
    if (!this.discountCode || this.discountCode.length < 16) {
      this.generalService.modalError('Codigo no válido', 'Ingrese el código nuevamente');
      return;
    }
    this.generalService.modalCargando();
    this.plansService.validateDiscounts(this.beneficiarySelected.use_id, this.discountCode, this.specialitySelectedValue)
      .then((result: any) => {
        // console.log(result, 'result-validateDiscounts');
        this.discountCodeObject = result;
        this.discountCodeObject.formula = this.discountCodeObject.formula.replaceAll('data.', '');
        this.calculateFinalValue();
        this.generalService.modalExitoso('Código válido', 'Descuento ingresado exitosamente.');
      }).catch((error: any) => {
        console.log(error, 'result-validateDiscounts');
        this.generalService.modalError('Error al validar código', error.error && error.error.msg ? error.error.msg : 'Error al validar código');
      });
  }
}