<!-- MEDICO -->
<div *ngIf="(usuario.typ_id == 1)" class="card h-100 p-0 m-0" [ngClass]="{'border-confirm': data.confirmFlag, 'border-go-lobby': data.goLobbyFlag}">
    <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
      <div class="m-0 p-0 first-line">
        <app-profile-img 
          [img]="data.img" 
          [doctorName]="data.nombre_paciente" 
          [doctorLastName]="data.apellido_paterno_paciente"
          [color]="data.goLobbyFlag ? 'goLobby' : data.confirmFlag ? 'confirm' : 'default'">
        </app-profile-img>
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 appt-date">{{ data.date[0] }} {{ data.date[1] }}</p>
          <p class="card-text m-0 p-0 ">{{ data.spec }} </p>
          <p class="card-text m-0 p-0 ">{{ data.nombre_paciente }} {{ data.apellido_paterno_paciente }} {{data.apellido_materno_paciente}} </p>
        </div>
      </div>
      <br>
      <div class="d-flex align-items-end justify-content-end flex-wrap">
        <!-- Distinto Día -->
        <button *ngIf="!video_bool" 
          type="button" class="btn btn-primary mr-auto" 
          [disabled]="!data.goLobbyFlag" 
          (click)="goVideoConsultaMedico()">Ir a la consulta
        </button>
        <!-- Distinto Día -->
        <!-- Mismo Día -->
        <button *ngIf="video_bool" 
          type="button" class="btn btn-primary mr-auto" 
          (click)="goVideoConsultaMedico()">Ir a la consulta
        </button>
        <!-- Mismo Día -->
        <p class="card-text flag-helper appt-date">{{ data.confirmedFlag ? 'Confirmada' : 'Reservada' }}</p>
      </div>
    </div>
  </div>
  
  <!-- PACIENTE -->
  <div *ngIf="(usuario.typ_id == 2 && data.acompanante == false)" class="card h-100 p-0 m-0" [ngClass]="{'border-confirm': data.confirmFlag, 'border-go-lobby': data.goLobbyFlag}">
    <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
      <div class="m-0 p-0 first-line">
        <app-profile-img 
          [img]="data.consulta.att_path" 
          [doctorName]="data.doctorName" 
          [doctorLastName]="data.doctorLastName"
          [color]="data.goLobbyFlag ? 'goLobby' : data.confirmFlag ? 'confirm' : 'default'">
        </app-profile-img>
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 appt-date">{{ data.date[0] }}</p>
          <p class="card-text m-0 p-0 appt-date">{{ data.date[1] }}</p>
        </div>
        <app-popover *ngIf="options_bool" class="ml-auto mr-2">
          <a data-toggle="modal" [attr.data-target]="'#confirmarAppoModal2' + data.id_consulta"><p *ngIf="data.confirmFlag">Confirmar asistencia</p></a>
          <a data-toggle="modal" class="pointer" (click)="modificarConsulta(data)"><p>Modificar consulta</p></a>
          <a data-toggle="modal" class="pointer" (click)="detallesConsulta(data)"><p>Detalle de mi consulta</p></a>
          <a data-toggle="modal" [attr.data-target]="'#cancelarAppoModal2' + data.id_consulta" click="cancelarHora()"><p>Anular consulta</p></a>
        </app-popover>
      </div>
      <p class="card-text pt-3 mr-5 doc-data">
        {{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }} 
      </p>
      <div class="d-flex align-items-end justify-content-end flex-wrap">
        <!-- Distinto Día -->
        <div *ngIf="!video_bool" style="width: 100%;" >
          <button *ngIf="data.confirmedFlag || data.goLobbyFlag" type="button" class="btn btn-primary mr-auto"
            [disabled]="!data.goLobbyFlag" (click)="goLobby()">
            Ir a Sala de Espera
          </button>
          <button *ngIf="(data.confirmFlag || !data.confirmedFlag) && !data.goLobbyFlag" 
            type="button"
            data-toggle="modal" [attr.data-target]="'#confirmarAppoModal2' + data.id_consulta"
            class="btn btn-secondary mr-auto" [disabled]="!data.confirmFlag">
            Confirmar asistencia
          </button>
        </div>
        <!-- Distinto Día -->
        <!-- Mismo Día -->
        <button *ngIf="video_bool"  type="button" class="btn btn-primary mr-auto"
          (click)="goLobby()">
          Ir a Sala de Espera
        </button>
        <!-- Mismo Día -->
        <p class="card-text flag-helper">{{ data.confirmedFlag ? 'Confirmada' : 'Reservada' }}</p>
      </div>
    </div>
  </div>
  
  
  <!-- acompanates-->
  <div *ngIf="(usuario.typ_id == 2 && data.acompanante)" class="card h-100 p-0 m-0" [ngClass]="{'border-confirm': data.confirmFlag, 'border-go-lobby': data.goLobbyFlag}">
    <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
      <div class="m-0 p-0 first-line">
        <app-profile-img 
          [img]="data.img" 
          [doctorName]="data.doctorName" 
          [doctorLastName]="data.doctorLastName"
          [color]="data.goLobbyFlag ? 'goLobby' : data.confirmFlag ? 'confirm' : 'default'">
        </app-profile-img>
        <div class="ml-3 align-self-center">
          <p class="card-text m-0 p-0 appt-date">{{ data.date[0] }}</p>
          <p class="card-text m-0 p-0 appt-date">{{ data.date[1] }}</p>
        </div>
      </div>
      <p class="card-text pt-3 mr-5 doc-data">
        {{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }} 
      </p>
      <div class="d-flex align-items-end justify-content-end flex-wrap">
        <button *ngIf="data.confirmedFlag || data.goLobbyFlag" type="button" class="btn btn-primary mr-auto"
          [disabled]="!data.goLobbyFlag" (click)="goLobbyAcomp()">
          Ir a Sala de Espera
        </button>
        <p class="card-text flag-helper">{{ data.confirmedFlag ? 'Confirmada' : 'Reservada' }}</p>
      </div>
    </div>
  </div>
  
  
  <!-- Modales -->
  <div class="modal fade confirmarAppoModal" id="confirmarAppoModal2{{data.id_consulta}}" tabindex="-1" role="dialog" aria-labelledby="confirmarAppoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 style="text-align: center;" >¿Deseas confirmar tu asistencia?</h2>
        </div>
        <div class="modal-body">
          <p class="hora">{{ data.date[0] }} {{ data.date[1] }}</p>
          <p class="detalle"> {{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }} </p>
                  
        </div>
        <div class="modal-footer">
          <div style="width: 50%;">
            <button type="button" class="btn btn-volver" style="float: left;" data-dismiss="modal"><div style="margin: auto;">Volver atrás</div></button>
          </div>
          <div style="width: 50%;">
            <button class="btn btn-confirmar" data-dismiss="modal"  style="float: right;" (click)="confirmAppt()"><div style="margin: auto;">Confirmar</div></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade cancelarAppoModal" id="cancelarAppoModal2{{data.id_consulta}}" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 style="text-align: center;" >Anulación de consulta</h2>
        </div>
        <div class="modal-body">
          <div class="card p-0 m-0" [ngClass]="{'border-confirm': data.confirmFlag, 'border-go-lobby': data.goLobbyFlag}">
            <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
              <div class="m-0 p-0 first-line">
                <app-profile-img 
                  [img]="data.img" 
                  [doctorName]="data.doctorName" 
                  [doctorLastName]="data.doctorLastName"
                  [color]="data.goLobbyFlag ? 'goLobby' : data.confirmFlag ? 'confirm' : 'default'">
                </app-profile-img>
                <div class="ml-3 align-self-center">
                  <p class="card-text m-0 p-0 appt-date">{{ data.date[0] }} {{ data.date[1] }}</p>
                  <p class="card-text pt-3 mr-5 doc-data">{{ data.doctorName }} {{ data.doctorLastName }} - {{ data.spec }} </p>
                </div>
              </div>
            </div>
          </div>
  
          <textarea class="form-control" name="cancel_reason" [(ngModel)]="cancel_reason" id="cancel_reason" placeholder="Motivo de anulación"></textarea>
          <div class="text-danger" *ngIf="(cancel_reason.length < 4)">
            <p><mat-icon>cancel</mat-icon> Debes ingresar un motivo de anulación</p>
          </div>
          <app-info-cancel-schedule></app-info-cancel-schedule>

        </div>
        <div class="modal-footer">
            <div style="width: 50%;">
              <button type="button" class="btn btn-volver" style="float: left;" data-dismiss="modal">
                <div style="margin: auto;">
                  Cerrar formulario
                </div>
              </button>
            </div>
            <div style="width: 50%;">
              <button class="btn btn-confirmar" data-dismiss="modal" style="float: right;" (click)="confirmarCancelAppo()" [disabled]="(cancel_reason.length < 4)">
                <div style="margin: auto;">
                  Anular consulta
                </div>
              </button>
            </div>
  
  
  
        </div>
      </div>
    </div>
  </div>
  