<div class="row">
    <div class="col-9">
        <mat-form-field appearance="standard">
            <mat-label>Filtrar por columna</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
        </mat-form-field>
    </div>
    <div class="col-3">
        <button type="submit" class="export" (click)="exportexcel()">
            Exportar a Excel</button>
    </div>

</div>


<mat-paginator [pageSizeOptions]="[15, 25, 35]" showFirstLastButtons aria-label="Select page of periodic elements">
</mat-paginator>

<div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" id="excel-codes">


        <ng-container matColumnDef="dis_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
            <td mat-cell *matCellDef="let element"> {{element.dis_id}} </td>
        </ng-container>

        <ng-container matColumnDef="dis_code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
            <td mat-cell *matCellDef="let element"> {{element.dis_code}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha_inicio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Inicio </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_inicio}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha_termino">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Limite </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_termino}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let element">
                <p *ngIf="element.status" class="badge badge-success">Activo </p>
                <p *ngIf="!element.status" class="badge badge-danger">Inactivo</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="dis_typ_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Descuento </th>
            <td mat-cell *matCellDef="let element"> {{element.dis_typ_name}} </td>
        </ng-container>

        <ng-container matColumnDef="dis_value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Descuento </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.dis_typ_name == 'MONTO'">{{element.dis_value | puntoMiles}} </a>
                <a *ngIf="element.dis_typ_name == 'PORCENTUAL'"> {{element.dis_value * 100}}%</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="cantidad_usos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Usos </th>
            <td mat-cell *matCellDef="let element"> {{element.cantidad_usos}} </td>
        </ng-container>

        <ng-container matColumnDef="dis_limit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Limite de uso </th>
            <td mat-cell *matCellDef="let element">
                <p *ngIf="element.dis_limit">{{element.dis_limit}} </p>
                <p *ngIf="!element.dis_limit"> Uso Ilimitado</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="specialities">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub Especialidad </th>
            <td mat-cell *matCellDef="let element">
                <p *ngIf="element.specialities"> {{element.specialities[0].spe_name}} </p>
                <p *ngIf="!element.specialities"> Uso General </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsable </th>
            <td mat-cell *matCellDef="let element"> {{element.created_by}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <!-- <mat-paginator [pageSizeOptions]="[15, 25, 35]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator> -->

    <br>
</div>