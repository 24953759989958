<div *ngIf="eventos">

  <!-- --------------------------------------- Nueva Ficha Médica ----------------------------------------->

  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
        class="nav-item nav-link active" (click)="Carta()" data-toggle="tab" href="#" role="tab" aria-controls="nav-exm"
        aria-selected="false">Anamnesis</a>
      <a *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'" class="nav-item nav-link"
        (click)="Diagnostico()" data-toggle="tab" href="#" role="tab" aria-controls="nav-exm"
        aria-selected="true">Diagnósticos e indicaciones</a>
      <a *ngIf="usuario.typ_name == 'PACIENTE'" class="nav-item nav-link active" (click)="Diagnostico()"
        data-toggle="tab" href="#" role="tab" aria-controls="nav-exm" aria-selected="true">Diagnósticos e
        indicaciones</a>
      <a class="nav-item nav-link" (click)="Receta()" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc"
        aria-selected="false">Recetas</a>
      <a class="nav-item nav-link" (click)="Certificado()" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc"
        aria-selected="false">Certificados</a>
      <a class="nav-item nav-link" (click)="Orden()" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc"
        aria-selected="false">Órdenes de exámenes</a>
      <a *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'PACIENTE'" class="nav-item nav-link"
        (click)="Licencia()" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc"
        aria-selected="false">Licencias</a>
      <a *ngIf="usuario.typ_name == 'PACIENTE'" class="nav-item nav-link" (click)="Adjuntar()" data-toggle="tab"
        href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Otros documentos</a>
      <a *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO' " class="nav-item nav-link"
        (click)="Adjuntar()" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Ver y
        adjuntar documentos</a>
      <a *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'" class="nav-item nav-link"
        (click)="Derivar()" data-toggle="tab" href="#" role="tab" aria-controls="nav-rcm"
        aria-selected="false">Derivaciones</a>
      <a *ngIf="usuario.typ_name == 'PACIENTE' || usuario.typ_name == 'COORDINADOR MEDICO'" class="nav-item nav-link"
        data-toggle="tab" (click)="verBoletas()" href="#" role="tab" aria-controls="nav-bol"
        aria-selected="false">Boletas</a>
      <!-- <a class="nav-item nav-link" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Modificaciones</a> -->

      <li class="nav-item ml-auto">
        <!-- <button type="button" class="subirbtn" data-toggle="modal" data-target="#subirdocumento">Subir documento</button> -->
      </li>
    </div>
  </nav>

  <br>
  <div *ngIf="(accion == 7) && (usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO')">
    <div class="col-md-12">
      <div class="card-alerta">
        <table>
          <tr>
            <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
            <td>
              <p class="letras-alerta" style="margin: 5px 5px 5px;">Sección de registro interno.</p>
            </td>

          </tr>
        </table>
      </div>
    </div>
    <br>
  </div>

  <div *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'">
    <div *ngIf="(!nuevo && accion!=6 && accion!=5) || (!nuevo && ficha.anamnesis.length<1 && accion==5)"
      style="width: 100%;height: 60px;">
      <button *ngIf="usuario.typ_name == 'MEDICO'" type="button" class="subirbtn" style="float: right;"
        (click)="agregar_nuevo()" [disabled]='(ficha.medical_license.length>0 && accion == 8)?  true : false'>Agregar
        nuevo</button>
    </div>
  </div>

  <div *ngIf="(usuario.typ_name == 'MEDICO' && accion==6) || (usuario.typ_name == 'COORDINADOR MEDICO' && accion==6)">
    <table style="width: 100%;">
      <tr>
        <td>
          <h1 class="titulo">Ver documentos adjuntados</h1>
        </td>
        <td style="display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;">
          <button *ngIf="usuario.typ_name == 'MEDICO'" type="button" class="updatebtn"
             data-toggle="modal"
            data-target="#subirdoc">Adjuntar documento</button>
            <button *ngIf="usuario.typ_name == 'MEDICO'" type="button" class="updatebtn"
            (click)="update_doc()">
            <p style="margin: 0 25px;">
                Actualizar
            </p>
        </button>
          <br>
        </td>
      </tr>

    </table>

  </div>




  <div *ngIf="sub_historial">



    <div *ngIf="accion == 1">
      <!-- Diagnóstico e indicación -->
      <div *ngFor="let item of ficha.diagnostics">
        <div class="card-info">
          <h1 class="titulo">{{item.file_name_desc}}</h1>
          <hr style="border: 1px solid #171994;">
          <table style="width: 100%;">
            <tr>
              <td>
                <h3 class="fecha">Fecha: {{item.diag_det_his_date| date:'dd-MM-yyyy h:mm a'}}</h3>
                <h3 class="descripcion">{{item.dia_name}}</h3>
                <h3 class="descripcion">{{item.diag_det_his_diagnostic_detail}}</h3>
              </td>
              <td style="align-items: flex-end;
              display: flex;
              flex-direction: column;
              margin-right: 10px;">

                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'" 
                  (click)="verDiagnostic(item)" style="height: 18;color: #2E3A59;float: right;">edit</mat-icon>
                <br>
                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                   (click)="eliminarDiagnostic(item)" data-toggle="modal"
                  data-target="#eliminardocumentocon" style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon>
                <br>
                <!-- <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'" 
                  (click)="verPdfModalVersionActual(item)" data-toggle="modal" data-target="#previsualizar"
                  style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon> -->
              </td>
            </tr>
          </table>
          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)"
              class="downloadButton  d-flex"> Descargar documento <mat-icon>file_download</mat-icon></button>
          </div>
          <div
            *ngIf="item.versiones.length>1 && (usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO')">
            <div *ngFor="let version of item.versiones ; let y = index;">
              <div *ngIf="y < (item.versiones.length-1)">
                <hr style="border: 1px solid #171994;">
                <table style="width: 100%;">
                  <tr>
                    <td>
                      <p class="fecha">version {{y+1}} - {{version.fecha | date:'dd-MM-yyyy h:mm a'}}</p>
                      <p>{{version.comentario}}</p>
                    </td>
                    <td>
                      <!-- <div><mat-icon (click)="verPdfModal(version)" data-toggle="modal"
                          data-target="#previsualizar" style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon></div> -->
                    </td>
                  </tr>
                </table>

              </div>
            </div>

          </div>

        </div>
        <br>
      </div>

      <div *ngIf="ficha.diagnostics.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>

    </div>

    <div *ngIf="accion == 2">
      <!-- Orden de exámen -->
      <div *ngFor="let item of ficha.orders">
        <div *ngIf="item.med_ord_typ_name != 'CERTIFICADO'" class="card-info">
          <h1 class="titulo">{{item.file_name_desc}}</h1>
          <hr style="border: 1px solid #171994;">
          <table style="width: 100%;">
            <tr>
              <td>
                <h3 class="fecha">Fecha: {{item.med_ord_date| date:'dd-MM-yyyy h:mm a'}}</h3>
                <h3 class="descripcion">{{item.med_ord_typ_name}}: {{item.med_ord_his_comment}}</h3>
              </td>
              <td style="align-items: flex-end;
              display: flex;
              flex-direction: column;
              margin-right: 10px">

                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                  (click)="verOrder(item)" style="height: 18;color: #2E3A59;float: right;">edit</mat-icon>
                <br>
                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                   (click)="eliminarOrder(item)" data-toggle="modal"
                  data-target="#eliminardocumentocon" style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon>
                <br>
                <!-- <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                  (click)="verPdfModalVersionActual(item)" data-toggle="modal" data-target="#previsualizar"
                  style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon> -->
              </td>
            </tr>
          </table>



          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)"
              class="downloadButton d-flex">Descargar documento <mat-icon>file_download</mat-icon></button>
          </div>

          <div
            *ngIf="item.versiones.length>1 && (usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO')">
            <div *ngFor="let version of item.versiones ; let y = index;">
              <div *ngIf="y < (item.versiones.length-1)">
                <hr style="border: 1px solid #171994;">
                <table style="width: 100%;">
                  <tr>
                    <td>
                      <p class="fecha">version {{y+1}} - {{version.fecha | date:'dd-MM-yyyy h:mm a'}}</p>
                      <p>{{version.comentario}}</p>
                    </td>
                    <td>
                      <!-- <div><mat-icon (click)="verPdfModal(version)" data-toggle="modal"
                          data-target="#previsualizar" style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon></div> -->
                    </td>
                  </tr>
                </table>

              </div>
            </div>

          </div>

        </div>
        <div *ngIf="item.med_ord_his_exa_typ_id != 4"><br></div>
      </div>
      <br>
      <div *ngIf="ficha.orders.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon></td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="accion == 3">
      <!-- Receta -->
      <div *ngFor="let item of ficha.prescriptions">
        <div class="card-info">

          <h1 class="titulo">{{item.file_name_desc}}</h1>
          <hr style="border: 1px solid #171994;">
          <table style="width: 100%;">
            <tr>
              <td>
                <h3 class="fecha">Fecha: {{item.pre_his_date| date:'dd-MM-yyyy h:mm a'}}</h3>
                <h3 class="descripcion">{{item.pre_typ_name}}: {{item.pre_his_indication}}</h3>
              </td>
              <td style="align-items: flex-end;
              display: flex;
              flex-direction: column;
              margin-right: 10px">
                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                   (click)="eliminarPrescription(item)" data-toggle="modal"
                  data-target="#eliminardocumentocon" style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon>
                <br>
                <!-- <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'" 
                  (click)="verPdfModalVersionActual(item)" data-toggle="modal" data-target="#previsualizar"
                  style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon> -->
              </td>
            </tr>
          </table>
          <div *ngIf="item.att_path" style="width: 100%; height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)"
              class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon></button>
          </div>

        </div>
        <br>
      </div>

      <div *ngIf="ficha.prescriptions.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>


    <div *ngIf="accion == 4">
      <!-- Certificado -->
      <div *ngFor="let item of ficha.certificados">
        <div *ngIf="item.med_ord_typ_name == 'CERTIFICADO'" class="card-info">
          <h1 class="titulo">{{item.file_name_desc}}</h1>
          <hr style="border: 1px solid #171994;">
          <table style="width: 100%;">
            <tr>
              <td>
                <h3 class="fecha">Fecha: {{item.med_ord_date| date:'dd-MM-yyyy h:mm a'}}</h3>
                <h3 class="descripcion">{{item.med_ord_his_comment}}</h3>
              </td>
              <td style="align-items: flex-end;
              display: flex;
              flex-direction: column;
              margin-right: 10px">

                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'" 
                  (click)="verOrder(item)" style="height: 18;color: #2E3A59;float: right;">edit</mat-icon>
                <br>
                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                   (click)="eliminarCertificado(item)" data-toggle="modal"
                  data-target="#eliminardocumentocon" style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon>
                <br>
                <!-- <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                  (click)="verPdfModalVersionActual(item)" data-toggle="modal" data-target="#previsualizar"
                  style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon> -->
              </td>
            </tr>
          </table>


          <div style="width: 100%;height: 60px;">
            <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)"
              class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon> </button>
          </div>
          <div *ngIf="item.versiones.length>1 && (usuario.typ_name == 'MEDICO')">
            <div *ngFor="let version of item.versiones ; let y = index;">
              <div *ngIf="y < (item.versiones.length-1)">
                <hr style="border: 1px solid #171994;">
                <table style="width: 100%;">
                  <tr>
                    <td>
                      <p class="fecha">version {{y+1}} - {{version.fecha | date:'dd-MM-yyyy h:mm a'}}</p>
                      <p>{{version.comentario}}</p>
                    </td>
                    <td>

                      <!-- <div><mat-icon (click)="verPdfModal(version)" data-toggle="modal"
                          data-target="#previsualizar" style="height: 18;color: #2E3A59;float: right;">visibility</mat-icon></div> -->
                    </td>
                  </tr>
                </table>

              </div>
            </div>

          </div>



        </div>
        <div *ngIf="item.med_ord_his_exa_typ_id == 4"><br></div>
      </div>
      <br>
      <div *ngIf="ficha.certificados.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>










    <div *ngIf="accion == 5">
      <!-- anamnesis -->
      <div *ngFor="let item of ficha.anamnesis" class="card-info">
        <h1 class="titulo">Anamnesis </h1>
        <hr style="border: 1px solid #171994;">
        <table style="width: 100%;">
          <tr>
            <td>
              <h3 class="fecha">Fecha: {{item.med_con_his_date_attention| date:'dd-MM-yyyy h:mm a'}}</h3>
              <h3 class="descripcion">{{item.med_con_his_anamnesis}}</h3>
            </td>
            <td style="align-items: center;">

              <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                (click)="verAnamnesis(item)" style="height: 18;color: #2E3A59;float: right;">edit</mat-icon>
              <br>
            </td>
          </tr>
        </table>
      </div>
      <br>
      <div *ngIf="ficha.anamnesis.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="accion == 6">
      <!-- Otros documentos-->
      <div *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'">
        <nav>
          <div class="row">

            <div class="col- nav nav-tabs" role="tablist" style="border-bottom: 0px">
              <a class="nav-item nav-link active boton-izquierdo" (click)="muestraArchivosPaciente()" data-toggle="tab"
                href="#" role="tab" aria-controls="nav-exm" aria-selected="true">Por el paciente</a>
              <a class="nav-item nav-link boton-derecho" (click)="muestraArchivosMedico()" data-toggle="tab" href="#"
                role="tab" aria-controls="nav-odc" aria-selected="false">Por los tratantes</a>
            </div>
          </div>
        </nav>
        <br><br>
        <div *ngIf="tab_archivos[0]">
          <table *ngIf="archivos_paciente.length>0" class="table table-striped">
            <thead>
              <tr>
                <th class="letras-tabla" style="font-weight: bold;">Título</th>
                <th class="letras-tabla" style="font-weight: bold;">Tipo</th>
                <th class="letras-tabla" style="font-weight: bold;">Fecha de subida</th>
                <th class="letras-tabla" style="font-weight: bold;">Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of archivos_paciente">
                <td class="letras-tabla">{{item.att_name| nombreArchivos}}</td>
                <td class="letras-tabla">{{item.att_typ_name}}</td>
                <td class="letras-tabla">{{item.att_date| date:'HH:mm - dd/MM/yyyy '}}</td>
                <td class="letras-tabla">
                  <a class="downloadButton" (click)="_generalServices.descargarFileAtt(item.att_id, item.att_name,null)"
                    style="background:transparent;box-shadow:0px 0px 0px transparent;border-radius: 0px;"><mat-icon>file_download</mat-icon></a>
                </td>

              </tr>

            </tbody>
          </table>

          <br>
          <div *ngIf="archivos_paciente.length<1" class="col-md-12">
            <div class="card-alerta">
              <table>
                <tr>
                  <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
                  <td>
                    <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
                  </td>

                </tr>
              </table>
            </div>
          </div>


        </div>

        <div *ngIf="tab_archivos[1]">
          <table *ngIf="archivos_consulta.length>0 " class="table table-striped">
            <thead>
              <tr>
                <th class="letras-tabla" style="font-weight: bold;">Título</th>
                <th class="letras-tabla" style="font-weight: bold;">Tipo</th>
                <th class="letras-tabla" style="font-weight: bold;">Fecha de subida</th>
                <th class="letras-tabla" style="font-weight: bold;">Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of archivos_consulta">
                <td class="letras-tabla">{{item.att_name| nombreArchivos}}</td>
                <td class="letras-tabla">{{item.att_typ_name}}</td>
                <td class="letras-tabla">{{item.att_date| date:'HH:mm - dd/MM/yyyy '}}</td>
                <td class="letras-tabla">


                  <div class="row">
                    <div style="width: 45%;">
                      <a style="background:transparent;box-shadow:0px 0px 0px transparent;border-radius: 0px;float: right;"
                        (click)="_generalServices.descargarFileAtt(item.att_id, item.att_name,null)">
                        <mat-icon>file_download</mat-icon></a>

                    </div>
                    <div style="width: 10%;"></div>
                    <div style="width: 45%;">
                      <mat-icon *ngIf="usuario.typ_name == 'MEDICO'"  (click)="eliminarArchivo(item)"
                        data-toggle="modal" data-target="#eliminardocumentocon"
                        style="height: 18;color: #2E3A59;float: left;">delete_outline</mat-icon>
                    </div>
                  </div>


                </td>

              </tr>

            </tbody>
          </table>


          <br>
          <div *ngIf="archivos_consulta.length<1" class="col-md-12">
            <div class="card-alerta">
              <table>
                <tr>
                  <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
                  <td>
                    <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
                  </td>

                </tr>
              </table>
            </div>
          </div>

        </div>





      </div>

      <div *ngIf="usuario.typ_name == 'PACIENTE'">
        <div *ngFor="let item of archivos_consulta">
          <div class="card-info" style="border-bottom: 10px solid #2BABB7;">
            <h1 class="titulo">{{item.file_name_desc}}</h1>
            <hr style="border: 1px solid #171994;">
            <table style="width: 100%;">
              <tr>
                <td>
                  <h3 class="fecha">{{item.att_name| nombreArchivos}}</h3>
                  <h3 class="descripcion">Adjuntado el: {{item.att_date| date:'dd-MM-yyyy h:mm a'}}</h3>
                </td>
                <td style="align-items: center;">

                </td>
              </tr>
            </table>



            <!-- <div style="width: 100%;height: 38px;"><button type="button" class="downloadButton" style="float: right;" (click)="downloadDocument(item)" >Descargar documento</button></div> -->
            <div style="width: 100%; height: 60px;">
              <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)"
                class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon> </button>
            </div>

          </div>
          <br>
        </div>

        <br>
        <div *ngIf="archivos_consulta.length<1" class="col-md-12">
          <div class="card-alerta">
            <table>
              <tr>
                <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
                <td>
                  <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
                </td>

              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- --------------------------------------------------------------------------------------------------------- -->


    </div>

    <div *ngIf="accion == 7">

      <div *ngFor="let item of ficha.derivation">
        <div class="card-info">
          <h1 class="titulo">Derivación</h1>
          <hr style="border: 1px solid #171994;">
          <table style="width: 100%;">
            <tr>
              <td>
                <h3 class="fecha">Fecha: {{item.der_his_date| date:'dd-MM-yyyy h:mm a'}}</h3>
                <h3 *ngIf="item.hos_name" class="descripcion">{{item.hos_name}}: {{item.der_his_doctor}}</h3>
                <h3 *ngIf="!item.hos_name" class="descripcion">{{item.der_his_doctor}}</h3>
              </td>
              <td style="align-items: center;">

                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                  (click)="verDerivation(item)" style="height: 18;color: #2E3A59;float: right;">edit</mat-icon>
                <br>
                <mat-icon *ngIf="usuario.typ_name == 'MEDICO' || usuario.typ_name == 'COORDINADOR MEDICO'"
                   (click)="eliminarDerivation(item)" data-toggle="modal"
                  data-target="#eliminardocumentocon" style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon>
              </td>
            </tr>
          </table>
        </div>
        <br>
      </div>
      <br>
      <div *ngIf="ficha.derivation.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>

    </div>

    <div *ngIf="accion == 8">

      <div *ngIf="ficha.medical_license.length>=1">
        <div class="card-info">
          <h1 class="titulo">Licencia</h1>
          <hr style="border: 1px solid #171994;">
          <table style="width: 100%;">
            <tr>
              <td>
                <h3 class="fecha">Fecha de generación: {{ficha.medical_license[0].med_lea_request_date| date:'dd/MM/yyyy
                  - h:mm a'}}</h3>
                <h3 class="descripcion">Diagnostico CIE10: {{ficha.medical_license[0].dia_cie10}}</h3>
                <h3 class="descripcion">Diagnostico médico: {{ficha.medical_license[0].dia_det_diagnostic_detail}}</h3>
                <h3 class="descripcion">Fecha inicio de reposo: {{ficha.medical_license[0].med_lea_start_date|
                  date:'dd/MM/yyyy'}}</h3>
                <h3 class="descripcion">Días de reposo: {{ficha.medical_license[0].med_lea_days}}</h3>
                <h3 class="descripcion">Tipo de licencia: {{ficha.medical_license[0].med_lev_typ_name}}</h3>
                <h3 *ngIf="usuario.doc_lme && ficha.medical_license[0].med_lea_url" class="descripcion">URL licencia:
                  {{ficha.medical_license[0].med_lea_url}}</h3>
              </td>
            </tr>
          </table>


          <!-- <div style="width: 100%;height: 38px;"><button type="button" class="downloadButton" style="float: right;" (click)="downloadDocument(item)" >Descargar documento</button></div> -->

        </div>
        <br>
      </div>
      <br>
      <div *ngIf="ficha.medical_license.length<1" class="col-md-12">
        <div class="card-alerta">
          <table>
            <tr>
              <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
              <td>
                <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
              </td>

            </tr>
          </table>
        </div>
      </div>

    </div>


  </div>

  <div *ngIf="accion == 9">
    <!-- Certificado -->
    <div *ngFor="let item of ficha.boletas">
      <div class="card-info">
        <h1 class="titulo">{{item.file_name_desc}}</h1>
        <hr style="border: 1px solid #171994;">
        <table style="width: 100%;">
          <tr>
            <td>
              <h3 class="fecha">Fecha: {{item.att_date| date:'dd-MM-yyyy h:mm a'}}</h3>
            </td>
          </tr>
        </table>


        <div style="width: 100%;height: 60px;">
          <button (click)="_generalServices.descargarFileAtt(item.att_id, item.file_name_desc,null)"
            class="downloadButton">Descargar documento <mat-icon>file_download</mat-icon> </button>
        </div>

      </div>
      <div *ngIf="item.med_ord_his_exa_typ_id == 4"><br></div>
    </div>
    <br>
    <div *ngIf="ficha.boletas.length<1" class="col-md-12">
      <div class="card-alerta">
        <table>
          <tr>
            <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
            <td>
              <p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene disponibles.</p>
            </td>

          </tr>
        </table>
      </div>
    </div>
  </div>




  <div *ngIf="nuevo" id="ficha_medica">

    <form *ngIf="accion != 8" name="form" style="text-align: left" #f1="ngForm">
      <div *ngIf="accion==2">
        <label for="">Tipo Exámen:</label>
        <select class="form-control" [(ngModel)]="id_exam" name="id_exam" (ngModelChange)="traerExamen()">
          <option *ngFor="let examen of examenes.examenes" [value]='examen.exa_typ_id'>{{examen.exa_typ_name}}</option>

        </select>
        <br>
      </div>

      <div *ngIf="accion==3">
        <label for="">Tipo Receta:</label>
        <select class="form-control" [(ngModel)]="id_receta" name="id_receta">
          <option *ngFor="let receta of recetas.recetas" [value]='receta.pre_typ_id'>{{receta.pre_typ_name}}</option>

        </select>
        <br>
      </div>

      <div *ngIf="accion==7">
        <label for="speciality_selected">Seleccione Centro Médico</label>
        <ng2-completer name="id_clinica" (selected)="changeClinica($event)" [textNoResults]="'Centro no encontrado'"
          [datasource]="clinicas" [(ngModel)]="searchStr" [minSearchLength]="0"
          [placeholder]="'Seleccione Centro Médico'" #id_clinica="ngModel" [autoMatch]="true"
          [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true" required>
        </ng2-completer>
      </div>
      <div *ngIf="accion==1">
        <label for="">Diagnóstico:</label>
      </div>
      <div style="text-align: center;">
        <br>
        <textarea [placeholder]="place_holder" class="texto-parrafo" style="width: 100%;" [(ngModel)]="comentario"
          name="comentario" rows="10"></textarea>
      </div>

      <div *ngIf="accion==3">

        <input id="checkbox-firma" style="border: 2px solid #0E0087;
                                                        box-sizing: border-box;
                                                        border-radius: 2px;" type="checkbox" [(ngModel)]="check_firma"
          name="check_firma">
        <label for="checkbox-firma" style="font-family: Mulish;
                                                          font-style: normal;
                                                          font-weight: normal;
                                                          font-size: 18px;
                                                          line-height: 24px;
                                                          color: #171994;">Enviar receta con firma electrónica
          avanzada</label>
      </div>


      <div *ngIf="accion==1">
        <div style="text-align: center;">
          <br>
          <textarea [placeholder]="place_holder2" class="texto-parrafo" style="width: 100%;" [(ngModel)]="comentario2"
            name="comentario2" rows="10"></textarea>
        </div>
        <br>
        <label for="">Diagnóstico CIE-10:</label>
        <ng2-completer name="id_diagnostico" (selected)="changeDiagnostico($event)"
          [textNoResults]="'Dianóstico no encontrado'" [datasource]="diagnosticos" [(ngModel)]="searchStrDia"
          [minSearchLength]="0" [placeholder]="'Seleccione Diagnóstico'" #id_diagnostico="ngModel" [autoMatch]="true"
          [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true">
        </ng2-completer>
        <br>
      </div>


      <!-- <h3>Fecha: {{fecha_sistema|date:'dd-MM-yyyy h:mm a'}}</h3> -->
      <!-- <button type="submit" class="btn btn-outline-primary"
                            style="border-radius: 40px; font-size: 18px; color: #ffffff; font-weight: bold;"
                            (click)="crearOrden(f1)">{{subtitulo}}</button> -->
      <div style="width: 100%;"><button type="submit" class="subirbtn" (click)="crearOrden(f1)"
          style="float: right;">Agregar</button></div>

    </form>

    <div *ngIf="accion == 8">

      <div class="row">
        <div class="col-sm-6">
          <p class="titulo">Fecha de inicio de reposo</p>
          <input placeholder="Selecciona una fecha dd/mm/yyyy" class="texto-parrafo" style="width: 100%;"
            [(ngModel)]="licencia.fecha_inicio_reposo">
          <br>
          <p class="titulo">Diagnóstico</p>
          <select placeholder="Selecciona el diagnostico" class="texto-parrafo"
            [(ngModel)]="licencia.diagnostico_seleccionado" style="width: 100%;" name="diagnostico_licencia"
            id="diagnostico_licencia">
            <option value="">Selecciona el diagnostico</option>
            <ng-container *ngFor="let item of ficha.diagnostics">
              <option *ngIf="item.dia_name" [value]="item.dia_det_id">{{item.dia_name}}</option>
            </ng-container>
          </select>
          <br>
        </div>
        <div class="col-sm-6">
          <p class="titulo">Días de reposo</p>
          <input placeholder="Ingresa días de reposo" type="number" class="texto-parrafo" style="width: 100%;"
            [(ngModel)]="licencia.dias_reposo">
          <br>
          <p class="titulo">Tipo de licencia</p>
          <select placeholder="Selecciona un tipo de licencia" class="texto-parrafo" style="width: 100%;"
            [(ngModel)]="licencia.tipo_seleccionado">
            <option value="">Selecciona un tipo de licencia</option>
            <option *ngFor="let item of tipos_licencia_medica" [value]="item.med_lev_typ_id">{{item.med_lev_typ_name}}
            </option>
          </select>
          <br>
        </div>
      </div>

      <hr style="border: 1px solid #171994;">
      <div class="row">
        <div *ngIf="licencia.tipo_seleccionado==4" class="col-sm-6">
          <p class="titulo">Rut Hijo (Opcional)</p>
          <input placeholder="Selecciona una fecha" class="texto-parrafo" style="width: 100%;"
            [(ngModel)]="licencia.rut_hijo">
          <br>
          <br>
          <br>
          <!-- <button type="button" class="solicitar-licencia-btn" style="float:right">Solicitar licencia</button> -->
        </div>
        <div *ngIf="licencia.tipo_seleccionado==4" class="col-sm-6">
          <p class="titulo">Fecha nacimiento hijo (opcional)</p>
          <input placeholder="Ingresa días de reposo" class="texto-parrafo" style="width: 100%;"
            [(ngModel)]="licencia.fecha_nacimiento_hijo">
          <br>
          <br>
          <br>
          <!-- <button type="button" class="generar-licencia-btn">Generar licencia</button> -->

        </div>

        <div *ngIf="usuario.doc_lme == true" class="col-sm-6">
          <button type="button" class="solicitar-licencia-btn" (click)="modalConfirmarLicencia()"
            style="float:right">Solicitar licencia</button>
        </div>
        <div *ngIf="usuario.doc_lme == true" class="col-sm-6">
          <button type="button" class="generar-licencia-btn" (click)="generarLicencia()" style="float:left">Generar
            licencia</button>
        </div>
        <div *ngIf="usuario.doc_lme == false || !usuario.doc_lme" class="col-sm-12" style="text-align: center;">
          <button type="button" class="solicitar-licencia-btn" (click)="modalConfirmarLicencia()">Solicitar
            licencia</button>
        </div>

      </div>
    </div>

    <!-- </div> -->
  </div>

  <!-- ----------------------------------------------------   Modificar   ---------------------------------------------------- -->

  <div *ngIf="modificar" id="ficha_medica">

    <form name="form" style="text-align: left" #f1="ngForm">

      <!-- <h2>{{titulo}}</h2> -->


      <div *ngIf="accion==2">
        <label for="">Tipo Exámen:</label>
        <select class="form-control" [(ngModel)]="id_exam" name="id_exam" (ngModelChange)="traerExamen()">
          <option *ngFor="let examen of examenes.examenes" [value]='examen.exa_typ_id'>{{examen.exa_typ_name}}</option>

        </select>
        <br>
      </div>

      <div *ngIf="accion==3">
        <label for="">Tipo Receta:</label>
        <select class="form-control" [(ngModel)]="id_receta" name="id_receta">
          <option *ngFor="let receta of recetas.recetas" [value]='receta.pre_typ_id'>{{receta.pre_typ_name}}</option>

        </select>
        <br>
      </div>

      <div *ngIf="accion==7">
        <label for="speciality_selected">Seleccione Centro Médico</label>
        <ng2-completer name="id_clinica" (selected)="changeClinica($event)" [textNoResults]="'Centro no encontrado'"
          [datasource]="clinicas" [(ngModel)]="searchStr" [minSearchLength]="0"
          [placeholder]="'Seleccione Centro Médico'" #id_clinica="ngModel" [autoMatch]="true"
          [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true" required>
        </ng2-completer>
      </div>

      <div style="text-align: center;">
        <br>
        <textarea [placeholder]="place_holder" class="texto-parrafo" style="width: 100%;" [(ngModel)]="comentario"
          name="comentario" rows="10"></textarea>
      </div>

      <div *ngIf="accion==3">

        <input id="checkbox-firma-modi" style="border: 2px solid #0E0087;
                                                  box-sizing: border-box;
                                                  border-radius: 2px;" type="checkbox" [(ngModel)]="check_firma"
          name="check_firma">
        <label for="checkbox-firma-modi" style="font-family: Mulish;
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 18px;
                                                    line-height: 24px;
                                                    color: #171994;">Enviar receta con firma electrónica
          avanzada</label>
      </div>

      <br>
      <div *ngIf="accion==1">
        <div style="text-align: center;">
          <br>
          <textarea [placeholder]="place_holder2" class="texto-parrafo" style="width: 100%;" [(ngModel)]="comentario2"
            name="comentario2" rows="10"></textarea>
        </div>
        <br>
        <label for="">Diagnóstico:</label>
        <ng2-completer name="id_diagnostico" (selected)="changeDiagnostico($event)"
          [textNoResults]="'Dianóstico no encontrado'" [datasource]="diagnosticos" [(ngModel)]="searchStrDia"
          [minSearchLength]="0" [placeholder]="'Seleccione Dianóstico'" #id_diagnostico="ngModel" [autoMatch]="true"
          [inputClass]="'form-control'" [clearUnselected]="true" [openOnFocus]="true" required>
        </ng2-completer>
        <br>
      </div>

      <label for="id_causal">Motivo de modificación:</label>
      <select class="form-control" name="id_causal" [(ngModel)]="id_causal">
        <option *ngFor="let causal of causales.causales" [value]='causal.cau_id'>
          {{causal.cau_description}}</option>
      </select>
      <br>
      <div style="width: 100%;"><br><button type="submit" class="subirbtn" (click)="crearOrden2(f1)"
          style="float: right;">Guardar cambios</button></div>

    </form>

    <!-- </div> -->
  </div>


  <br><br><br>



</div>








<!-- modal -->
<div class="modal fade" id="eli_" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <p class="tituloModal" style="text-align: center;">Subir documento</p>
        <br><br>

        <br><br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 letrabotonagregar">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <!-- <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar" (click)="guardar()">Subir documento</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- modal eliminar 1-->
<div class="modal fade" id="eliminardocumentocon" aria-labelledby="exampleModalLabel" aria-hidden="true"
  #eliminardocumentocon>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <p class="tituloModalEliminar" style="text-align: center;">¿Está seguro que desea eliminar este documento?</p>
        <br><br>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 letrabotoneliminar">
              <button type="button" class="btn botonCancelar colorLetraVolver" data-dismiss="modal">Volver
                atrás</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" class="btn btn-primary botonVolver" (click)="eliminarModal()">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--modal-->




<!-- modal subirdoc-->



<div class="modal fade" id="subirdoc" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog contenedor-formulario">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <p class="tituloModal" style="text-align: center;">Subir documento</p>
        <br><br>
        <div class="form-group row content-modal">
          <form name="form" style="text-align: left" #f1="ngForm">

            <br>
            <input type="text" class="form-control" [(ngModel)]="nombre_doc" name="nombre_doc" ngModel
              placeholder="Ingresar título del documento">
            <br>
            <br>
            <p style="font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: #171994;">Documento agregado</p>
            <div *ngIf="doc">
              <div *ngIf="doc.size <= 20048576" class="card-existe-doc" style="height: 40px;">
                <table style="width: 90%;margin: 15px auto 15px 15px;">
                  <tr>
                    <td>
                      <p style="font-family: Mulish;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 16px;
                              line-height: 24px;
                              display: flex;
                              align-items: center;
                              color: #1E4ECB;">{{doc.name}}</p>
                    </td>
                    <td><mat-icon (click)="delFileSelected()"
                        style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon></td>
                  </tr>
                </table>


                <br>
              </div>

              <div *ngIf="doc.size > 20048576">
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-alerta">
                      <table>
                        <tr>
                          <td><mat-icon 
                            style="color:#171994;font-size: 30px;margin: 20px 10px;">info</mat-icon> </td>
                            <td>
                            <p class="letras-alerta" style="margin: auto;">El documento supera el máximo peso
                              permitido.</p>
                          </td>

                        </tr>
                      </table>
                    </div>
                  </div>

                </div>

                <br>
              </div>
              <div *ngIf="!doc_permitido">
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-alerta">
                      <table>
                        <tr>
                          <td><mat-icon
                              style="color:#171994;font-size: 30px;margin: 20px 10px;">info</mat-icon> </td>
                          <td>
                            <p class="letras-alerta" style="margin: auto;">Solo puede adjuntar documentos PDF,
                              JPG o PNG.</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                </div>

                <br>
              </div>
            </div>

            <label class="col-md-6 custom-file-upload" style="height: 40px;">Examinar
              <input name="archivo" type="file" style="cursor: pointer !important;" (change)="onFileSelected($event)"
                max-file-size="1024" required />
            </label>
            <label>
              <strong>El documento no puede ser superior a 20 MB (PDF, JPG o PNG)</strong>
            </label>
            <br>
          </form>
        </div>
        
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 letrabotonagregar">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">

              <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar"
                (click)="guardar_doc()">Subir documento</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- modal pre-visualizar-->
<div class="modal fade" id="previsualizar" aria-labelledby="exampleModalLabel" aria-hidden="true" #previsualizar>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">

        <p class="tituloModalEliminar" style="text-align: center;margin: 0%;width: auto;">Ver documento</p>
        <div style="width: auto;height: 450px;">

          <!-- <embed src={{pdf_url|safeUrl}} frameborder="0" width="100%" height="400px"> -->
          <iframe [src]="pdf_url|safeUrl" type="application/pdf"
            style="width: 100%;border: none;margin: 0;padding: 0;overflow: hidden;z-index: 3;height: 100%;"></iframe>

        </div>
        <div class="container-fluid" style="padding: 10px 10px;">
          <div class="row">
            <div class="col-md-6 letrabotoneliminar" style="  font-size: 16px; line-height: 16px;">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                data-dismiss="modal">Cerrar</button>
            </div>
            <div class="col-md-6 letrabotonagregar" style="  font-size: 16px; line-height: 16px;">
              <div><a [href]="pdf_url" download="proposed_file_name" class="subirbtn"
                  style="float: right;font-size: 18px; height: auto;width: auto;">Descargar documento</a></div>
              <!-- <button type="button" class="subirbtn" style="float: right;font-size: 18px; height: auto;width: auto;" data-toggle="modal" data-target="#subirdoc"  >Adjuntar documento</button> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--modal-->

<button #cerrarSubirDoc type="button" class="btn d-none" data-toggle="modal" data-target="#subirdoc">
  aux trigger for login loading modal
</button>