import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-plans',
  templateUrl: './admin-plans.component.html',
  styleUrls: ['./admin-plans.component.css']
})
export class AdminPlansComponent implements OnInit {

  public page: any = {
    main_page: true,
    customer: false,
    plans: false,
    beneficiary_plans: false,
  };
  constructor() { }

  ngOnInit(): void {
  }


  setPage({ main_page = false, customer = false, plans = false, beneficiary_plans = false, }) {
    this.page = {
      main_page: main_page,
      customer: customer,
      plans: plans,
      beneficiary_plans: beneficiary_plans,
    };
  }

  resetPage() {
    this.page = {
      main_page: false,
      customer: false,
      plans: false,
      beneficiary_plans: false,
    };
  }

}
