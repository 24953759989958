import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VerificacionDocumentosRoutingModule } from './verificacion-documentos-routing.module';
import { VerificacionDocumentosComponent } from './verificacion-documentos.component';
import { DocumentoComponent } from './documento/documento.component';
import { IngresarDocumentoComponent } from './ingresar-documento/ingresar-documento.component';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [VerificacionDocumentosComponent, DocumentoComponent, IngresarDocumentoComponent],
  imports: [
    CommonModule,
    VerificacionDocumentosRoutingModule,
    PipesModule,
    FormsModule,
    MatIconModule
  ]
})
export class VerificacionDocumentosModule { }
