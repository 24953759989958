    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper" class="error-page">
        <div class="error-box">
            <div class="error-body text-center">
                <h1>404</h1>
                <h3 class="text-uppercase">No se ha encontrado la pagina !</h3>
                
                <a (click)="mandarLogin()" class="btn btn-info btn-rounded waves-effect waves-light m-b-40">Volver al Inicio</a> </div>
            <footer class="footer text-center">© {{year}} Medis by S3Chile.cl</footer>
        </div>
    </section>
    <!-- ============================================================== -->
    <!-- End Wrapper -->