import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }

  async postListBeneficiariesXPlans(use_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      use_id: use_id
    };
    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListBeneficiariesXPlans', params , httpOptions).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


  async validateDiscounts(use_id, code, sub_spe_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      sub_spe_id: sub_spe_id,
      code: code,
      use_id: use_id,
    };


    try {
      let data = await this.http.post(URL_SERVICIOS + '/validateDiscounts', params, httpOptions).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }
}
