import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { CalendarComponent } from '../../components/calendar/calendar.component';
import Swal from 'sweetalert2';

import { UsuarioService } from '../../services/usuario.service';
import { ScheduleService } from '../../services/schedule.service';
import { Usuario } from 'src/app/models/usuario.model';


// declare function init_plugins();

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-agenda-medico',
  templateUrl: './agenda-medico.component.html',
  styleUrls: ['./agenda-medico.component.css']
})
export class AgendaMedicoComponent implements OnInit {
  @Input() evento: any; 
  @Input() consulta: any; 
  @Output() messageEvent = new EventEmitter<string>();
  @ViewChild(CalendarComponent) CALENDAR: CalendarComponent;
  day_available: any;
  day_available_date: any;
  hours_available_day: any;
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha
  eventos = [];

  tipo_usuario_name: string;

  doctor_id: any;

  hours_doctor: any;                        //Todas las horas del doctor

  hours_available_doctor: any;              // horas disponibles doctor
  
  constructor(
    public _schedule: ScheduleService,
  ) { 

    this.tipo_usuario_name = 'MEDICO'
  }

  ngOnInit(): void {

    ////console.log("Consulta: ");
    ////console.log(this.consulta);
    this._schedule.getScheduleHours(this.consulta.id_doc, false, null, null, null, false)
      .then(data => {
      ////console.log(data);
      
      this.hours_doctor = data;
      for (let eve of this.hours_doctor){
        if (eve.sch_hou_sta_name == 'RESERVADA') { 
          eve.estado_hora = eve.sch_hou_sta_name;
        } else {}
        if (eve.sch_sta_name == 'NO ACTIVA' || eve.sch_hou_sta_name == 'NO ACTIVA') { 
          eve.estado_hora = 'BLOQUEADA';
        } else {}
        if (eve.sch_sta_name == 'ACTIVA' && eve.sch_hou_sta_name == 'ACTIVA') { 
          eve.estado_hora = 'DISPONIBLE';
        } else {}
      }
      this.sendToCalendar(this.hours_doctor)
    })
    .catch(error => console.log(error))
  }


  sendToCalendar(eventsX) {
    for (let eve of eventsX){
      let col = colors.yellow;
      if (eve.sch_hou_sta_name == 'RESERVADA') { 
        col = colors.blue; 
        eve.estado_hora = eve.sch_hou_sta_name;
      } else {}
      if (eve.sch_sta_name == 'NO ACTIVA') { col = colors.red; } else {}
      if (eve.sta_name == 'ACTIVA' && eve.sch_hou_sta_name == 'ACTIVA') { 
        col = colors.blue; 
        eve.estado_hora = eve.sch_hou_sta_name;
      }

      this.eventos.push({ //Creacion de los eventos (dias disponibles del doctor)
        start: new Date(eve.sch_date), //va solo date, podrian ir timestamp
        title: eve.sch_id,
        color: col,
      });
    }
    this.CALENDAR.refresh.next();
  }
  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }
  receiveMessage($event) {
    if ( this.tipo_usuario_name == 'MEDICO') {
      
      let hoursX = [];
      if ( $event.length === 0 ) {
        this.day_available = false; 
        this.day_available_date = '';
      } else {
        this.day_available_date = ($event.date).toLocaleString('es-US', this.options);
        for ( let hour of this.hours_doctor ) {                      
          if ( hour.sch_id == $event.id ) {
            hoursX.push(hour)
          }
        }
        this.day_available = true;  // visualizar horas del dia
      }
      this.hours_available_day = hoursX;
      
    }
    else {
      // ////console.log($event)
    }
  }
}
