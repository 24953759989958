import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { URL_DOCS } from 'src/app/config/config';
import { TarifasService } from '../../../services/tarifas.service';

@Component({
  selector: 'app-listar-especialidades',
  templateUrl: './listar-especialidades.component.html',
  styleUrls: ['./listar-especialidades.component.css']
})
export class ListarEspecialidadesComponent implements OnInit {

  card = {
    especilidades: false,
    especialistas: false,
    especialidadesDoc: false
  }

  public usuario: any = {};

  public especialidades = [];

  public especialistas = [];
  public especialista_selected: any = {};

  public especialidadesDoctor = [];
  public especialidadDoctor_selected: any = {};



  public URL_DOCS = URL_DOCS


  infoPost: any = {};

  infoSubEsp: any = {};

  constructor(
    public generalService: GeneralService,
    public tarifasService: TarifasService) { }


  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    this.getCompleteSubSpecialityList();
    this.getDoctorList();
    this.card = {
      especilidades: false,
      especialistas: false,
      especialidadesDoc: false
    };


  }


  //Listar especialidades

  getCompleteSubSpecialityList() {
    this.generalService.getCompleteSubSpecialityList()
      .then((data: any) => {
        // console.log(data, 'precios');

        this.especialidades = data;
      })
      .catch(e => console.error(e));

  }

  //Listar especialistas

  getDoctorList() {
    this.generalService.getDoctorList(this.usuario.doc_id)
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-getDoctorList');

          this.especialistas = data;
        }
      })
      .catch(e => console.error(e));
  }

  ///////////////////////////////modificacion tarifa Sub Especialidad /////////////////////////////////


  // Permite cambiar Tarifa subEspe


  updateSubEspecialidad(especialidad) {
    // console.log(especialidad);


    this.infoSubEsp = especialidad;

    this.infoSubEsp.sub_spe_id = especialidad.sub_spe_id;
    this.infoSubEsp.price = especialidad.sub_spe_pri_price > 0 == especialidad.sub_spe_pri_price;
    this.infoSubEsp.newPrice = especialidad.sub_spe_pri_price > 0 == especialidad.pri_doc_price;



    // console.log(this.infoSubEsp, 'infoSubEsp-updateDoctorEspecialidad');

  }


  async confirmUpdatePriceSubSpe() {

    // console.log(this.infoSubEsp, 'infoSubEsp-infoSubEsp-confirmUpdatePriceSubSpe');
    this.generalService.modalCargando();


    //Validacion de valor Min y Max
    if (this.infoSubEsp.newPrice > 1000 && this.infoSubEsp.newPrice < 150000) {

    } else {
      this.tarifasService.modalValorInvalido('Valor ingresado no valido', 'Ingrese un valor entre $1.000 y $150.000');
      return;
    }



    if (this.infoSubEsp.newPrice != this.infoSubEsp.price) {
      //Llamar servicio
      await this.tarifasService.postUpdateSubSpecialityPrice(this.infoSubEsp.sub_spe_id, this.infoSubEsp.newPrice)
        .then(result => {
          // console.log(result, 'tarifa modificada-postUpdateSubSpecialityPrice');
        })
        .catch(error => {
          // console.log(error, 'error-postUpdateSubSpecialityPrice');
        });
    }

    this.generalService.modalExitoso('Cambios guardados exitosamente', '');
    this.ngOnInit();



  }

  /////////////////////////////Modificacion Tarifa Doctor Sub Especialidad////////////////////////////////////



  //Trae la informacicon de la SpeDoc Seleccionado

  updateDoctorEspecialidad(especialidadDoctor) {
    // console.log(especialidadDoctor);

    this.infoPost = especialidadDoctor;

    this.infoPost.can_udp_price = especialidadDoctor.doc_spe_sub_spe_can_upd_price;
    this.infoPost.doc_spe_sub_id = especialidadDoctor.doc_spe_sub_id;
    this.infoPost.price = especialidadDoctor.pri_doc_price > 0 ? especialidadDoctor.pri_doc_price : especialidadDoctor.sub_spe_pri_price;
    this.infoPost.newPrice = especialidadDoctor.pri_doc_price > 0 ? especialidadDoctor.pri_doc_price : especialidadDoctor.sub_spe_pri_price;

    // console.log(this.infoPost, 'infoPost-updateDoctorEspecialidad');
    // this.infoPost = {
    //   doc_spe_sub_id: especialidadDoctor.doc_spe_sub_id,
    //   price: especialidadDoctor.pri_doc_price > 0 ? especialidadDoctor.pri_doc_price : especialidadDoctor.sub_spe_pri_price,
    // };

  }

  async confirmUpdateDoctorPriceSubSpe() {

    this.generalService.modalCargando();



    if (this.infoPost.newPrice >= 1000 && this.infoPost.newPrice <= 150000) {

    } else {
      this.tarifasService.modalValorInvalido('Valor ingresado no valido', 'Ingrese un valor entre $1.000 y $150.000');
      return;
    }



    //bloquear y desbloquear modificacion de tarifa COORDINADOR

    if (this.infoPost.can_udp_price != this.infoPost.doc_spe_sub_spe_can_upd_price) {
      //Llamar servicio
      await this.tarifasService.postLockPriceProperty(this.infoPost.can_udp_price, this.infoPost.doc_spe_sub_id)
        .then(result => {
          // console.log(result, 'result-postLockPriceProperty');
        })
        .catch(error => {
          // console.log(error, 'error-postLockPriceProperty');
        });
    }


    //Trae la informacicon de la speDoctor Seleccionado

    if (this.infoPost.newPrice != this.infoPost.price) {
      //Llamar servicio
      await this.tarifasService.postUpdateSubSpeDoctorPrice(this.infoPost.doc_spe_sub_id, this.infoPost.newPrice)
        .then(result => {
          // console.log(result, 'tarifa modificada-postUpdateSubSpeDoctorPrice');
        })
        .catch(error => {

          // console.log(error, 'error-postUpdateSubSpeDoctorPrice');
        });
    }



    this.generalService.modalExitoso('Cambios guardados exitosamente', '');
    this.ngOnInit();
  }

  btnListarEspecialidades() {
    if (this.card.especilidades) {
      this.card.especilidades = false;
    } else {
      this.card.especilidades = true;
      this.card.especialistas = false;
      this.card.especialidadesDoc = false;
    }

  }
  btnListarEspecialistas() {
    if (this.card.especialistas) {
      this.card.especialistas = false;
    } else {
      this.card.especialistas = true;
      this.card.especilidades = false;
      this.card.especialidadesDoc = false;
    }

  }

  btnVerEspecialidades(doctor: any) {
    this.especialista_selected = doctor;
    // console.log(doctor, 'Datos doctor');

    if (this.card.especialidadesDoc) {
      this.card.especialidadesDoc = false;
    } else {
      this.card.especialidadesDoc = true;
      this.card.especilidades = false;
      this.card.especialistas = false;
    }

  }

}
