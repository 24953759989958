<div class="d-none d-sm-block" style="width: 100%; align-items: center;text-align: center; height: auto;">
    <br>
    <img src="assets/images/logo-almamedis.png" style="height: 83px; padding-left: 50px; float: left;">
    <br>
    <br>    
    <br>  
    <br>
    <br>
</div>


<div class="d-sm-none" style="width: 100%; align-items: center;text-align: center;">
    <br>
    <img src="assets/images/logo-almamedis.png" style="width:70%;">
    <br>  
    <br>
    <br>  
</div>

<router-outlet></router-outlet>