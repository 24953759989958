

export class Usuario {
    constructor(
        public nombre: string,
        public rut: string,
        public _id?: string,
        public doc_id?: number,
        public doc_lme?: boolean,
        public email?: string,
        public nombre_usuario?: string,
        public typ_id?: number,
        public typ_name?: string,
        public use_maternal_surname?: string,
        public use_name?: string,
        public use_paternal_surname?: string,
        public use_sta_id?: number,
        public user?: string,
        public cou_id?: string,
        public cou_name?: string,
        public cou_end_url?: string,
        public cou_timezone?: string,
        public cou_language?: string,
    ) { }
}