import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild, NavigationEnd, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, } from '@angular/common/http'; 
import { URL_SERVICIOS } from '../config/config';

import { map, filter } from 'rxjs/operators';


import { ErrorsService } from './errors.service';
import { ReservaHoraComponent } from '../pages/mi-agenda/reserva-hora/reserva-hora.component';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<CanComponentDeactivate> {

  constructor(
    private router: Router,
    public http: HttpClient,
    public _errors: ErrorsService,
  ) {
  }

  canDeactivate(
    component: CanComponentDeactivate, 
    currentRoute: ActivatedRouteSnapshot, 
    currentState: RouterStateSnapshot, 
    state: RouterStateSnapshot): boolean | UrlTree | Observable< boolean | UrlTree > | Promise< boolean | UrlTree > {
   
      let url: string = state.url;
      // console.log('Url: '+ url);
      
      return component.canDeactivate ? component.canDeactivate() : true;
    }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
    if (!sessionStorage.getItem("token")){ //sin session
      return this.router.navigate(['/login']).then(() => false);
    }
    this.tokenValidate_ruta()
    return true 
  }

  canActivateChild (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
    if (!sessionStorage.getItem("token")){ //sin session
      return this.router.navigate(['/login']).then(() => false);
    }
    this.tokenValidate_ruta()
    return true
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       tokenValidate      /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Extraer ruta
  async tokenValidate_ruta() {
    let count = 1;
    this.router.events.pipe(filter( event => event instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      let url = e.url; //e.id
      if ( count > 0 ) {
        count = 0;
        this.tokenValidate(url)
      }
    });
  }
  // Valida si el token está activo
  async tokenValidate(url_actual) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = { 
      url: url_actual
    }
    try {
      let data = await this.http.post( URL_SERVICIOS + '/tokenValidate', JSON.stringify(params), httpOptions ).toPromise()
      // {status: true, url: '/login'}
      return true
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }
  
}
