import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nombreArchivos'
})
export class NombreArchivosPipe implements PipeTransform {

  transform(value: String, ...args: unknown[]): unknown {
    // //console.log(value);
    
    try {
      return value.split("-")[2];
    } catch (e) {
      return "archivo";
    }
  }

}
