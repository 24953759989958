import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';
import { DocumentsService } from 'src/app/services/documents.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ZoomService } from 'src/app/services/zoom.service';
import { GeneralService } from 'src/app/services/general.service';



@Component({
  selector: 'app-consultas-previas',
  templateUrl: './consultas-previas.component.html',
  styleUrls: ['./consultas-previas.component.css']
})
export class ConsultasPreviasComponent implements OnInit {
  @Input() consulta: any = {};
  ver_ficha_previa: boolean = false;
  consulta_aux: any = {};
  ficha_consulta_aux: any = {
    diagnostics: [],
    certificados: [],
    prescriptions: [],
    orders: []    
  };
  documentos_aux: any = [];
  accion: number = 1;
  URL_DOCUMENTS = URL_DOCS;
  consultas_pasadas: Array<any> = [];

  constructor(  
    public _generalServices: GeneralService,
    private activatedRoute: ActivatedRoute,
    private _zoomService: ZoomService,
    private _fichaMedicaServices: FichaMedicaService,
    private _functions: FunctionsService,
    public scheduleService: ScheduleService,
    private router: Router,
    private _documentServices: DocumentsService
  ) {}

  ngOnInit(): void {
    this.getListarConsultasPasadas();
  }

  getListarConsultasPasadas = () => {
    this._fichaMedicaServices.getListarConsultasPasadas({paciente: this.consulta.rut_paciente, doc_id: null, date: null})
      .then( (result: any) => {
        this.consultas_pasadas = result.consultas;
        if ( !(result.consultas.length > 0) ) {
          //console.log('Sin consultas pasadas');
        } else {
          let consultas = result.consultas;
        }
        for (let appt of this.consultas_pasadas) {
          appt.fecha_consulta = this._functions.formatApptDate(new Date(appt.fecha_consulta), appt.hora)
          appt.dateText = appt.fecha_consulta.text
        }
      })
      .catch(e => console.log(e));
  }


  

  async verDetalle(consulta) {
    //console.log(consulta);
    this.consulta_aux = consulta;
    this._fichaMedicaServices.getListarFichaConsulta(consulta)
      .then(results => {
        //console.log(results);
        
        this.ficha_consulta_aux = results;
        this.accion = 6;
        this.ver_ficha_previa = true;

        for (let docum in this.ficha_consulta_aux){ //numero correlativo para los documentos de una misma consultaId
          let ind = 1;
          for (let file of this.ficha_consulta_aux[docum]){
            if( file.file_name ){
              let ind_st = (100+ind).toString().substring(1)
              file.file_name_desc = file.file_name.replace('XX', ind_st)
              ind++;
            }
          }
        }

      })
      .catch(error => {
        console.log(error);
      });
    // let use_id = await consulta.user_id;
    // //console.log(use_id);

    this._documentServices.postListaArchivosConsulta(consulta.id_consulta, this.consulta.user_id)
      .then(results => {
        this.documentos_aux = results;

        let ind = 1;
        for (let file of this.documentos_aux){
          if( file.file_name ){
            let ind_st = (100+ind).toString().substring(1)
            file.file_name_desc = file.file_name.replace('XX', ind_st)
            ind++;
          }
        }
        
      })
      .catch(error => {
        console.log(error);
      });
    
  }

  cambiar_pestana(accion) {
    //console.log(accion);
    this.accion = accion;
  }

  volver() {
    this.ver_ficha_previa = false;
  }

}
