<div class="row">
     <div class="col-12">
       <p class="infoStyleText2" style="margin: 25px 0 15px; font-size: 16px;">Otras prestaciones y beneficios de salud</p>
     </div>

   </div>
<div class="row">
     <div class="col-md-6 col-sm-10">
      <h1 *ngIf="agreement && agreement.agre_typ_title" class="title">{{agreement.agre_typ_title}}</h1>
    </div>
     
     <div class="col-md-6 col-sm-2" style="display: flex;
     align-items: center;
     justify-content: flex-end;">
       <a (click)="volver()" style="float:right;cursor: pointer;color: #0E0087;font-weight: 650;">
        <mat-icon>chevron_left</mat-icon>
        <span class="ml-2" style="font-weight: 650; font-size: 16px; font-family: Mulish;">Volver</span>
       </a>
     </div>
   </div>

<h2 class="subTitle">Prestadores</h2>
<app-agreements [agreements]="agreements"></app-agreements>

<h2 class="subTitle">Beneficios</h2>
<app-benefits [benefits]="benefits"></app-benefits>