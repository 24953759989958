
<ng-container>

<div class="row">
  <div class="col-md-12">
    <br />
    <div class="minititulo">Tarifas y Descuentos</div>
    <div class="titulo">Codigos de descuento</div>
  </div>
</div>

<hr>


<div class="row">
  <div class="col">

    <form (ngSubmit)="createCode()" autocomplete="off" [formGroup]="dctoPost">

      <div class="form-row">


        <div class="col-sm-6">
          <label class=" col-form-label colorletra">Prestación</label>
          <div class="form-check">
            <input type="radio" class="form-check-input" value="1" formControlName="prestacion" id="radioGeneral"
              name="prestacion" (click)="hideEspecialidades()">
            <label class="form-check-label" for="radioGeneral">
              Medicina General
            </label>
          </div>

          <div class="form-check">
            <input type="radio" class="form-check-input" value="2" formControlName="prestacion" id="radioEspecialidades"
              name="prestacion" (click)="showEspecialidades()" (change)="changeSpe()">
            <label class="form-check-label" for="radioEspecialidades">
              Especialidades
            </label>
            <p class="form-text text-danger" *ngIf="campoValido('prestacion')">
              Debe seleccionar una prestación
            </p>
          </div>
        </div>


        <!-- // -->
        <div class="col-sm-6" *ngIf="prestacionRadio.especialidades">
          <div class="form-group">
            <label class="col-form-label colorletra" for="inputState">Especialidades</label>
            <select id="inputState" class="form-control" formControlName="especialidades">
              <option selected>Seleccione especialidad...</option>
              <option value="">Todas las especialidades</option>
              <ng-container *ngFor="let especialidad of especialidadesList">
                <option [ngValue]="especialidad.sub_spe_id">{{especialidad.spe_name}}</option>
              </ng-container>
            </select>
          </div>
          <span class="form-text text-danger" *ngIf="campoValido('especialidades')">
            Debe seleccionar una especialidad
          </span>
        </div>
      </div>

      <hr>



      <div class="form-row">
        <div class="col-sm-6">
          <label class="col-form-label colorletra">Tipo de descuento</label>
          <div class="form-check">
            <input type="radio" class="form-check-input" value="1" formControlName="descuento" id="radioPorcentaje"
              name="descuento" (click)="showPorceDcto()" (change)="changePorcentaje()">
            <label class="form-check-label" for="radioPorcentaje">
              Porcentaje
            </label>
          </div>

          <div class="form-check">
            <input type="radio" class="form-check-input" value="2" formControlName="descuento" id="radioMonto"
              name="descuento" (click)="showMontoDcto()" (change)="changeMonto()">
            <label class="form-check-label" for="radioMonto">
              Monto
            </label>
          </div>
          <span class="form-text text-danger" *ngIf="campoValido('descuento')">
            Debe seleccionar un tipo de descuento
          </span>
        </div>
      </div>

      <div class="form-row">


        <div class="form-group col-md-6" *ngIf="descuentoRadio.valorPorcentaje">
          <label class="colorletra" for="descuentoRadioPorcentaje">Valor descuento %</label>
          <input type="number" class="form-control" formControlName="monto" placeholder="%">
          <span class="form-text text-danger" *ngIf="campoValido('monto')">
            Debe ingresar un valor entre 1% a 100%
          </span>
        </div>


        <div class="form-group col-md-6" *ngIf="descuentoRadio.valorMonto">
          <label class="colorletra" for="dctoMonto">Valor descuento $</label>
          <input type="number" class="form-control" formControlName="monto" placeholder="$">
          <span class="form-text text-danger" *ngIf="campoValido('monto')">
            Debe ingresar un valor en $1.000 y $100.000
          </span>

        </div>
      </div>


      <hr>

      <div class="form-row">

        <div class="col-sm-6">
          <label class="col-form-label colorletra">Asignacion</label>
          <div class="form-check">
            <input type="radio" class="form-check-input" value="1" formControlName="asignacion" id="radioAsignacionGen"
              name="asignacion" (click)="hideClientes()">
            <label class="form-check-label" for="radioAsignacionGen">
              General
            </label>
          </div>

          <div class="form-check">
            <input type="radio" class="form-check-input" value="2" formControlName="asignacion" id="radioAsignacionEsp"
              name="asignacion" (click)="showClientes()" (change)="changeCli()">
            <label class="form-check-label" for="radioAsignacionEsp">
              Por cliente
            </label>
          </div>

          <span class="form-text text-danger" *ngIf="campoValido('asignacion')">
            Debe seleccionar un tipo de asignacion
          </span>
        </div>

        <div class="col-sm-12" *ngIf="asignacionRadio.asignacionCliente">

          <!--Clientes-->

          <div class="col-sm-6">
            <div class="form-group">
              <label class="col-form-label colorletra" for="inputState">Clientes</label>
              <select (ngModelChange)="changeClient($event)" id="inputState" class="form-control"
                formControlName="cliente">
                <option value="" selected>Seleccione cliente...</option>
                <option value="">Todas los clientes</option>
                <ng-container *ngFor="let cliente of clientes">
                  <option [ngValue]="cliente.cus_id">{{cliente.cus_name}}</option>
                </ng-container>
              </select>
            </div>
            <span class="form-text text-danger" *ngIf="campoValido('cliente')">
              Debe seleccionar un cliente
            </span>
          </div>


          <!--Planes-->

          <div class="col-sm-6" *ngIf="changeClient">
            <div class="form-group">
              <label class="col-form-label colorletra" for="inputState2">Planes</label>
              <select (ngModelChange)="changePlanByClient($event)" id="inputState2" class="form-control"
                formControlName="plan">
                <option value="" selected>Seleccione plan...</option>
                <option value="">Todos los planes</option>
                <ng-container *ngFor="let plan of cliente_selected.planes">
                  <option [ngValue]="plan.cus_ben_id">{{plan.cus_ben_name}}</option>
                </ng-container>
              </select>
            </div>
            <span class="form-text text-danger" *ngIf="campoValido('plan')">
              Debe seleccionar un plan
            </span>
          </div>
        </div>
      </div>


      <hr>


      <div class="form-row">
        <div class="form-group col-md-6">
          <label class="colorletra" for="fechaInicio">Fecha Inicio</label>
          <input type="date" class="form-control" id="inputFechaInicio" placeholder="fechaInicio"
            formControlName="fechaInicio">
          <span class="form-text text-danger" *ngIf="campoValido('fechaInicio')">
            Debe seleccionar una fecha de inicio a contar del dia sguiente al actual
          </span>
        </div>
        <div class="form-group col-md-6">
          <label class="colorletra" for="fechaLimite">Fecha limite </label>
          <input type="date" class="form-control" id="inputFechaLimite" placeholder="fechaLimite"
            formControlName="fechaLimite">
          <span class="form-text text-danger" *ngIf="campoValido('fechaLimite')">
            Debe seleccionar una fecha de limite
          </span>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label class="colorletra" for="dctoPorcentaje">Limite de usos</label>
          <input type="number" class="form-control" formControlName="limiteUso" placeholder="Limite de uso">
          <span class="form-text text-danger" *ngIf="campoValido('limiteUso')">
            Ingrese un valor entre 0 y 10
            <br>
            <p class="nota">*Nota: Al ingresa limite de uso con valor "0" el limite del codigo sera ilimitado*</p>
          </span>
        </div>
      </div>


      <div>
        <button type="submit" class="subirbtn" click="campoValido()">
          Generar
          Codigo</button>
      </div>

    </form>
    <hr>
    <br>

  </div>
</div>




<div class="row">
  <div class="col-md-12">
    <br />
    <div class="titulo">Codigos creados</div>
  </div>
</div>
<div class="col-12">
  <app-table-discount-code 
[ELEMENT_DATA]="descuentos">

</app-table-discount-code>
</div>

<br>

</ng-container>






