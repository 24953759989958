import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AdminBeneficiaryPlansComponent } from './admin-planes/admin-beneficiary-plans/admin-beneficiary-plans.component';
import { AdminCustomerComponent } from './admin-planes/admin-customer/admin-customer.component';
import { AdminPlansComponent } from './admin-planes/admin-plans/admin-plans.component';
import { AgendaMedico2Component } from './agenda-medico2/agenda-medico2.component';
import { AgendaMedicoComponent } from './agenda-medico/agenda-medico.component';
import { AgendaMedicoProximasComponent } from './agenda-medico-proximas/agenda-medico-proximas.component';
import { AntecedentesMedicosComponent } from './antecedentes-medicos/antecedentes-medicos.component';
import { AntecedentesPacienteComponent } from './antecedentes-paciente/antecedentes-paciente.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarDocComponent } from './calendar-doc/calendar-doc.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { CalendarMonthComponent } from './calendar/calendar-month/calendar-month.component';
import { CardConsultaComponent } from './card-consulta/card-consulta.component';
import { CarruselComponent } from './carrusel/carrusel.component';
import { ChartsModule } from 'ng2-charts';
import { ChatComponent } from './chat/chat.component';
import { CodigoDescuentoComponent } from './tarifas-coordinador/codigo-descuento/codigo-descuento.component';
import { CommonModule } from '@angular/common';
import { ConfiguracionAgendaComponent } from './administrar-agenda-medico/configuracion-agenda/configuracion-agenda.component';
import { ConfigurarAgendaComponent } from '../pages/mi-agenda-medico/configurar-agenda/configurar-agenda.component';
import { ConSinFinalizarComponent } from './con-sin-finalizar/con-sin-finalizar.component';
import { ConsultasFinalizadasComponent } from './cards/consultas-finalizadas/consultas-finalizadas.component';
import { ConsultasPreviasComponent } from './consultas-previas/consultas-previas.component';
import { DetalleConsulPreviasComponent } from './detalle-consul-previas/detalle-consul-previas.component';
import { DetallesConsultaHistoricoComponent } from './detalles-consulta-historico/detalles-consulta-historico.component';
import { DetallesConsultaPacienteComponent } from './detalles-consulta-paciente/detalles-consulta-paciente.component';
import { DetalleTarifaComponent } from './detalle-tarifa/detalle-tarifa.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentsConsultComponent } from './documents-consult/documents-consult.component';
import { DonaComponent } from './dona/dona.component';
import { EncuestaPostConsultaComponent } from './encuesta-post-consulta/encuesta-post-consulta.component';
import { FichaMedicaComponent } from './ficha-medica/ficha-medica.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HistorialConsultasComponent } from './historial-consultas/historial-consultas.component';
import { IncrementadorComponent } from './incrementador/incrementador.component';
import { InfoCancelScheduleComponent } from './info-cancel-schedule/info-cancel-schedule.component';
import { ListadoChatsComponent } from './listado-chats/listado-chats.component';
import { ListarEspecialidadesComponent } from './tarifas-coordinador/listar-especialidades/listar-especialidades.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ModificarConsultaComponent } from './modificar-consulta/modificar-consulta.component';
import { NextAppointmentComponent } from './cards/next-appointment/next-appointment.component';
import { NextAppointmentDashboardComponent } from './cards/next-appointment-dashboard/next-appointment-dashboard.component';
import { NextPendingAppointmentComponent } from './cards/pending-appointments/next-pending-appointment/next-pending-appointment.component';
import { NextPendingAppointmentDashboardComponent } from './cards/pending-appointments/next-pending-appointment-dashboard/next-pending-appointment-dashboard.component';
import { Ng2CompleterModule } from 'ng2-completer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgModule } from '@angular/core';
import { PastAppointmentComponent } from './cards/past-appointment/past-appointment.component';
import { PaymentButtonComponent } from './payment-button/payment-button.component';
import { PipesModule } from '../pipes/pipes.module';
import { PlansComponent } from './plans/plans.component';
import { PopoverComponent } from './popover/popover.component';
import { ProfileImgComponent } from './cards/profile-img/profile-img.component';
import { ProximasConsultasDocComponent } from './cards/proximas-consultas-doc/proximas-consultas-doc.component';
import { StepsComponent } from './mi-agenda/comun/steps/steps.component';
import { TableDiscountCodeComponent } from './tarifas-coordinador/codigo-descuento/table-discount-code/table-discount-code.component';
import { TarifasComponent } from './tarifas/tarifas.component';
import { TarifasCoordinadorComponent } from './tarifas-coordinador/tarifas-coordinador.component';
import { TitleHeaderComponent } from './mi-agenda/comun/title-header/title-header.component';
import { UnfinishedAppointmentComponent } from './cards/unfinished-appointment/unfinished-appointment.component';
import { VerAgendaComponent } from './administrar-agenda-medico/ver-agenda/ver-agenda.component';
import { VideoWebRtcComponent } from './video-web-rtc/video-web-rtc.component';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import { AddUserComponent } from './admin-users/add-user/add-user.component';
import { EditUserComponent } from './admin-users/edit-user/edit-user.component';
import {MatTabsModule} from '@angular/material/tabs';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AgreementComponent } from './otras-prestaciones/agreement/agreement.component';
import { AgreementsComponent } from './otras-prestaciones/agreements/agreements.component';
import { BenefitsComponent } from './otras-prestaciones/benefits/benefits.component';
import { BenefitComponent } from './otras-prestaciones/benefit/benefit.component';
import { SharedModule } from '../shared/shared.module';
import { EncuestaModalComponent } from './encuesta-modal/encuesta-modal.component';
import { MatIconModule } from '@angular/material/icon';





@NgModule({
  declarations: [
    AdminBeneficiaryPlansComponent,
    AdminCustomerComponent,
    AdminPlansComponent,
    AgendaMedico2Component,
    AgendaMedicoComponent,
    AgendaMedicoProximasComponent,
    AntecedentesMedicosComponent,
    AntecedentesPacienteComponent,
    CalendarComponent,
    CalendarDocComponent,
    CalendarMonthComponent,
    CardConsultaComponent,
    CarruselComponent,
    ChatComponent,
    CodigoDescuentoComponent,
    ConfiguracionAgendaComponent,
    ConfigurarAgendaComponent,
    ConSinFinalizarComponent,
    ConsultasFinalizadasComponent,
    ConsultasPreviasComponent,
    DetalleConsulPreviasComponent,
    DetallesConsultaHistoricoComponent,
    DetallesConsultaPacienteComponent,
    DetalleTarifaComponent,
    DocumentsComponent,
    DocumentsConsultComponent,
    DonaComponent,
    EncuestaPostConsultaComponent,
    FichaMedicaComponent,
    HistorialConsultasComponent,
    IncrementadorComponent,
    InfoCancelScheduleComponent,
    ListadoChatsComponent,
    ListarEspecialidadesComponent,
    ModificarConsultaComponent,
    NextAppointmentComponent,
    NextAppointmentDashboardComponent,
    NextPendingAppointmentComponent,
    NextPendingAppointmentDashboardComponent,
    PastAppointmentComponent,
    PaymentButtonComponent,
    PlansComponent,
    PopoverComponent,
    ProfileImgComponent,
    ProximasConsultasDocComponent,
    StepsComponent,
    TableDiscountCodeComponent,
    TarifasComponent,
    TarifasCoordinadorComponent,
    TitleHeaderComponent,
    UnfinishedAppointmentComponent,
    VerAgendaComponent,
    VideoWebRtcComponent,
    AddUserComponent,
    EditUserComponent,
    AgreementComponent,
    AgreementsComponent,
    BenefitsComponent,
    BenefitComponent,
    EncuestaModalComponent,
  ],
  exports: [
    AdminBeneficiaryPlansComponent,
    AdminCustomerComponent,
    AdminPlansComponent,
    AgendaMedico2Component,
    AgendaMedicoComponent,
    AgendaMedicoProximasComponent,
    AntecedentesMedicosComponent,
    AntecedentesPacienteComponent,
    CalendarComponent,
    CalendarDocComponent,
    CalendarMonthComponent,
    CardConsultaComponent,
    CarruselComponent,
    ChatComponent,
    CodigoDescuentoComponent,
    ConfiguracionAgendaComponent,
    ConfigurarAgendaComponent,
    ConSinFinalizarComponent,
    ConsultasFinalizadasComponent,
    ConsultasPreviasComponent,
    DetallesConsultaHistoricoComponent,
    DetallesConsultaPacienteComponent,
    DocumentsComponent,
    DocumentsConsultComponent,
    DonaComponent, //lo exporto para que pueda ser utilizado en ell graficas1.component.html
    EncuestaPostConsultaComponent,
    FichaMedicaComponent,
    HistorialConsultasComponent,
    IncrementadorComponent,
    InfoCancelScheduleComponent,
    ListadoChatsComponent,
    ListarEspecialidadesComponent,
    ModificarConsultaComponent,
    NextAppointmentComponent,
    NextAppointmentDashboardComponent,
    NextPendingAppointmentComponent,
    NextPendingAppointmentDashboardComponent,
    PastAppointmentComponent,
    PaymentButtonComponent,
    PlansComponent,
    PopoverComponent,
    ProfileImgComponent,
    ProximasConsultasDocComponent,
    StepsComponent,
    TarifasCoordinadorComponent,
    TitleHeaderComponent,
    UnfinishedAppointmentComponent,
    VerAgendaComponent,
    VideoWebRtcComponent,
    AddUserComponent,
    EditUserComponent,
    BenefitsComponent,
    AgreementComponent,
    AgreementsComponent,
    EncuestaModalComponent
  ],
  imports: [
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ChartsModule,
    CommonModule,
    FlatpickrModule.forRoot(),
    FormsModule,
    Ng2CompleterModule,
    Ng2SearchPipeModule,
    PipesModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSortModule,
    MatInputModule,
    MatTabsModule,
    NgMultiSelectDropDownModule,
    MatDatepickerModule,
    SharedModule,
    MatIconModule
  ]
})
export class ComponentsModule { }
