import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-listado-chats',
  templateUrl: './listado-chats.component.html',
  styleUrls: ['./listado-chats.component.css']
})
export class ListadoChatsComponent implements OnInit {

  consultas_list: any = []; 
  consultas: any = []; 
  eventos = true;
  constructor(
    private _generalServices: GeneralService,
    private router: Router,
  ) { 

  }

  ngOnInit(): void {

    this._generalServices.getListarConsultasChat()
    .then((data: any[]) => {
      this.consultas_list = data;     
      //console.log(this.consultas_list);
      
      var largo = this.consultas_list.consultas.length;
      for (var i = 0 ; i < largo; i++){
        // this.consultas_list[i] = data[i];

        var resta_minutos = this.obtenerResta(this.consultas_list.consultas[i].hora);
        var fecha_sistema = new Date();
        const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');

        // //console.log(this.consultas_list.consultas[i].fecha_consulta.substring(0,10));

        if (this.consultas_list.consultas[i].fecha_consulta.substring(0, 10) == fecha.toString() ) {
          
          this.consultas.push(this.consultas_list.consultas[i]);


        } else if (this.consultas_list.consultas[i].fecha_consulta.substring(0, 10) != fecha.toString() && resta_minutos >= 0) {
          this.consultas.push(this.consultas_list.consultas[i]);

        } else { 
          // this.consultas.push(this.consultas_list.consultas[i]);
          //console.log("Consulta");          

        }
      }
      //console.log(this.consultas);
      
     })
      .catch(error => { 
        console.log(error);
        
      })
  }

  obtenerResta(hora)
  {

    var fecha = new Date(Date.now());
    var minutos = fecha.getHours() * 60 + fecha.getMinutes();
    var minutos_consulta = parseInt(hora.substr(0, 2)) * 60 + parseInt(hora.substr(3, 2));
    var diferencia = minutos_consulta - minutos;
    //console.log("Diferencia "+diferencia);
    return diferencia;
  }

  unirseReunionPaciente()
  {
    //console.log(this.consultas);
    
    //console.log(this.consultas[0]);
    var resta_minutos = this.obtenerResta(this.consultas[0].hora);
    var fecha_sistema = new Date();
    //console.log("Fecha actual: " + fecha_sistema);

    const fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');

    //console.log(fecha);

    //console.log(this.consultas[0].fecha_consulta.substring(0,10));

    if (this.consultas[0].fecha_consulta.substring(0, 10) != fecha.toString()) { 
      //console.log("No Puede");
    } else if (resta_minutos > 60) {
      //console.log("No Puede");
    } else if (resta_minutos < -60) {
      //console.log("No Puede");
    } else
    {
      var url = "salaespera/"+this.consultas[0].zoo_mee_room_id+"/"+this.consultas[0].id_consulta+"/"+this.consultas[0].med_con_id;
      this.router.navigate([url]);
    }


   }

}
