<div class="d-none d-sm-block" style="width: 100%; align-items: center;text-align: center;">
    <br>
    <img src="assets/images/logo-almamedis.png" alt="alamedis logo" style="height: 83px;">
    
    <p class="letra-sub">En almaMedis podrás</p>
    <p class="letra-tit">{{titulo[pagina]}}</p>
    <div>
        <img *ngIf="pagina==0" src="assets/images/onboarding/medico/desktop/pantallazo0.png" style="height: 45vh;">
        <img *ngIf="pagina==1" src="assets/images/onboarding/medico/desktop/pantallazo1.png" style="height: 45vh;">
        <img *ngIf="pagina==2" src="assets/images/onboarding/medico/desktop/pantallazo2.png" style="height: 45vh;">
        <img *ngIf="pagina>2" src="assets/images/onboarding/medico/desktop/pantallazo3.png" style="height: 45vh;">
    </div>
    <div>
        <mat-icon [ngStyle]="{color: estilo[0]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[1]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[2]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[3]}">circle</mat-icon>
    </div>
    <p class="letra-subtit">{{subtitulo[pagina]}}</p>
    <div class="d-flex align-items-center justify-content-center">
        <div *ngIf="pagina<3" style="width: 55%;height: 60px;align-items: center;text-align: center;">
            <button (click)="omitirBoton()" class="boton-omitir">Omitir</button>
            <button (click)="siguienteBoton()" class="boton-siguiente" style="float: right;">{{siguiente[pagina]}}</button>
        </div>
        <div *ngIf="pagina>2" style="width: 55%;height: 60px;align-items: center;text-align: center;">
            <button (click)="empezemosBoton()" class="boton-siguiente">¡Empecemos!</button>
        </div>
    </div>

</div>


<div class="d-sm-none" style="width: 100%; align-items: center;text-align: center;">
    <br>
    <img src="assets/images/logo-almamedis.png" alt="alamedis logo" style="width:70%;">
    
    <p class="letra-sub" style="font-size: 28px;">En Alamamedis podrás</p>
    <p class="letra-tit" style="font-size: 24px;">{{titulo[pagina]}}</p>
    <div>
        <img *ngIf="pagina==0" src="assets/images/onboarding/medico/mobile/pantallazo0.png" style="height: 70vh; margin-bottom: -33%;">
        <img *ngIf="pagina==1" src="assets/images/onboarding/medico/mobile/pantallazo1.png" style="height: 70vh; margin-bottom: -33%;" >
        <img *ngIf="pagina==2" src="assets/images/onboarding/medico/mobile/pantallazo2.png" style="height: 70vh; margin-bottom: -33%;" >
        <img *ngIf="pagina==3" src="assets/images/onboarding/medico/mobile/pantallazo3.png" style="height: 70vh; margin-bottom: -33%;" >
        <img *ngIf="pagina>3" src="assets/images/onboarding/medico/mobile/pantallazo4.png" style="height: 70vh; margin-bottom: -33%;" >

    </div>
    <div>
        <mat-icon [ngStyle]="{color: estilo[0]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[1]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[2]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[3]}">circle</mat-icon>
        <mat-icon [ngStyle]="{color: estilo[4]}">circle</mat-icon>
    </div>
    <!-- <p class="letra-subtit">{{subtitulo[pagina]}}</p> -->
    <div class="d-flex align-items-center justify-content-center">
        <div *ngIf="pagina<4" style="width: 100%;height: 60px;align-items: center;text-align: center;">
            <button (click)="omitirBoton()" class="boton-omitir" style="width: 40%;">Omitir</button>
            <button (click)="siguienteBoton()" class="boton-siguiente" style="float: right;width: 40%">{{siguiente[pagina]}}</button>
        </div>
        <div *ngIf="pagina>3" style="width: 100%;height: 60px;align-items: center;text-align: center;">
            <button (click)="empezemosBoton()" class="boton-siguiente" style="width: 40%">¡Empecemos!</button>
        </div>
    </div>

</div>