import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { EncriptacionService } from '../../../services/encriptacion.service';
import { ScheduleService } from '../../../services/schedule.service';

import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-consultas-pendientes-pago',
  templateUrl: './consultas-pendientes-pago.component.html',
  styleUrls: ['../../../../assets/css/styles.css', './consultas-pendientes-pago.component.css']
})
export class ConsultasPendientesPagoComponent implements OnInit {

  _title = 'Mi agenda';
  _subtitle = 'Mis reservas pendientes';

  
  public usuario: any;
  public tipo_usuario_name: string;

  public appointments: any[] = [];
  public auxAppts: any[] = [];
  private auxApptsPos: number = 0;


  public pendingAppointmentList: any[] = [];
  public auxPending: any[] = [];
  private auxPendingPos: number = 0;
  public apptDataPending: any = {};

  public pendingAppointment_selected: any = {};

 




  constructor(public _usuarioService: UsuarioService,
              private scheduleService: ScheduleService,
              private fb: FormBuilder,
  ) {


  }
  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();

    this.tipo_usuario_name = new EncriptacionService().getUserInfo().typ_name;
    // console.log(this.usuario);

    this.pendingAppointment();

  }


  


 
  pendingAppointment() {
    this.scheduleService.getListarConsultasPendientes(null)
      .then((data: any) => {
        // console.log(data, 'consultas pendientes');
        
        this.pendingAppointmentList = data;
        this.auxPending = [];

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (index < 2) {
            this.auxPending.push(element);

          }
        }

      })
      .catch(e => console.error(e));
  }










}
