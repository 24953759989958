import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-past-appointment',
  templateUrl: './past-appointment.component.html',
  styleUrls: ['../../../../assets/css/styles.css', './past-appointment.component.css']
})
export class PastAppointmentComponent implements OnInit {
  @Input() data: any = {};
  @Output() messageEvent = new EventEmitter<any>(); 

  data_selected: any = {}           //resumen del consulta
  _usuario: any = {};  // usuario actual
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha

  constructor(
    private router: Router,
    private _medicosServices: MedicosService,
    private _schedule: ScheduleService,
  ) { }

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo()
    
  }

  verDetalle(){
    this.messageEvent.emit(this.data) 
    // console.log(this.data);
    
  }



}
