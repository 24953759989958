<div class="col-md-12"> <!--  *ngIf="tipo_usuario_name=='PACIENTE'" -->
    <br>
    <div class="minititulo">
            Mi agenda
    </div>
    <div class="titulo">
            Historial de consultas
    </div>
</div>

 <div class="container-fluid">
      <div class="row">
          <div class="col-md-12 titulobeneficiario">
              Detalle de la consulta
          </div>
      </div>
</div>
<!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
    <button type="button" class= "btn boton-izquierdo"name="options" (click)="muestraDatos()"> Datos de la consulta</button>
    <button type="button" class= "btn boton-derecho"name="options" (click)="muestraFicha()"> Ficha médica</button>
</div> -->
<nav>
    <div class="nav nav-tabs" role="tablist">
        <a class="nav-item nav-link active boton-izquierdo" (click)="muestraDatos()"data-toggle="tab" href="#" role="tab" aria-controls="nav-exm" aria-selected="true"  >Datos de la consulta</a>
        <a class="nav-item nav-link boton-derecho" (click)="muestraFicha()" data-toggle="tab" href="#" role="tab" aria-controls="nav-odc" aria-selected="false">Ficha médica</a>
    </div>
  </nav>
<br><br>
<!-- next appointments MEDICO-->
<div *ngIf="!ficha_medica">
    <div class="row">
        <div class="col-12">
            <div class="row d-sm-flex">
                <div class="col-md-12">
                    <!--<app-proximas-consultas-doc [data]="appt" [index]="i"></app-proximas-consultas-doc> -->
                    <div class="card p-0 m-0" >
                        <div class="row card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
                            <div class="col-md-9 col-xs-12 m-0 p-0 c-0 first-line">
                                <div *ngIf="usuario.typ_name == 'MEDICO'" class="img-cirlce-wrapper" style="margin-left: 15px;">
                                    <p class="m-0 p-0 text-default">
                                        {{pacienteName}}{{pacienteLastName}}
                                    </p>
                                </div>
                                <div *ngIf="usuario.typ_name == 'PACIENTE'" class="img-cirlce-wrapper" style="margin-left: 15px;">
                                    <app-profile-img 
                                        [img]="datos_consulta.att_path">
                                    </app-profile-img>
                                </div>
                                <div class="ml-3 align-self-center">
                                    <p class="card-text m-0 p-0 appt-date" style="font-weight: bold">Fecha de la consulta: {{ date[0]}} del {{anio}} - {{ date[1]}}</p>
                                    <p *ngIf="usuario.typ_name == 'PACIENTE'" class="card-text m-0 p-0 ">{{ datos_consulta.nombre_doctor}} {{ datos_consulta.apellido_paterno_doctor}} - {{ datos_consulta.spe_name}}</p> <!-- {{ appt.spec }}  -->
                                    <p *ngIf="usuario.typ_name == 'MEDICO'" class="card-text m-0 p-0 ">{{datos_consulta.nombre_paciente}} {{datos_consulta.apellido_paterno_paciente}} {{datos_consulta.apellido_materno_paciente}}</p> <!-- {{ appt.spec }}  -->
                                </div>
                            </div>
                            <div class="col-md-3 col-xs-12">
                                <div class="ml-auto align-self-center" style="margin-right: 15px;float: center ;position: relative; top: 30%">
                                    <p *ngIf="datos_consulta.tel_doc_app_boo_sta_id == 39" class="card-text flag-helper appt-gris">*Finalizada</p>
                                    <p *ngIf="datos_consulta.tel_doc_app_boo_sta_id == 44" class="card-text flag-helper appt-gris">*Paciente no se presenta</p>
                                    <p *ngIf="datos_consulta.tel_doc_app_boo_sta_id != 39 && datos_consulta.tel_doc_app_boo_sta_id !=44" class="card-text flag-helper appt-gris">*No Finalizada</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 titulobeneficiario">
                Descripción de la consulta
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-6 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p *ngIf="usuario.typ_name == 'PACIENTE'" class="card-text m-0 p-0 tituloDescripcion" >Médico</p>
                    <p *ngIf="usuario.typ_name == 'PACIENTE'" class="card-text m-0 p-0 cuerpoDescripcion" >{{datos_consulta.nombre_doctor}} {{datos_consulta.apellido_paterno_doctor}} {{datos_consulta.apellido_materno_doctor}}</p>
                    <p *ngIf="usuario.typ_name == 'MEDICO'" class="card-text m-0 p-0 tituloDescripcion" >Paciente</p>
                    <p *ngIf="usuario.typ_name == 'MEDICO'" class="card-text m-0 p-0 cuerpoDescripcion" >{{datos_consulta.nombre_paciente}} {{datos_consulta.apellido_paterno_paciente}} {{datos_consulta.apellido_materno_paciente}}</p>
                    <p class="card-text m-0 p-0 "> </p>
                </div>
            </div>
            <br>
            <div class="col-md-6 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion">Acompañante del paciente</p>
                    <p *ngIf="datos_consulta.nombre_acompanante != null" class="card-text m-0 p-0 cuerpoDescripcion">{{ datos_consulta.nombre_acompanante}} {{datos_consulta.apellido_paterno_acompanante}} {{ datos_consulta.apellido_materno_acompanante}}</p>
                    <p *ngIf="datos_consulta.nombre_acompanante == null" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no presenta acompañante a la consulta **</p>
                    <p class="m-0 p-0 "> </p>
                </div>
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-12 m-0 p-0 first-line">
                <div class="ml-3 align-self-center">
                    <p class="card-text m-0 p-0 tituloDescripcion">Motivo de la consulta</p>
                    <p *ngIf="datos_consulta.razon != ''" class="card-text m-0 p-0 cuerpoDescripcion">{{ datos_consulta.razon}}</p>
                    <p *ngIf="datos_consulta.razon == ''" class="card-text m-0 p-0 cuerpoDescripcion">** Paciente no especifica motivo de la consulta **</p>
                    <p class="m-0 p-0 "> </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="ficha_medica">
    <app-ficha-medica 
        [eventos] = 'evento'
        [consulta] = 'datos_consulta'>
    </app-ficha-medica>
</div>

