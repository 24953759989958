<!-- VER AGENDA -->
<div *ngIf="item_disp.ver">
  <h2>Agenda </h2>
  <div class="" style="display: flex;">
    <div class="calendar-container agenda-user-calendar" >
      <div class="row text-center">
        <div class="col-1">
          <div 
            (click)="prevMes()"
            class="btn btn-primary" 
            mwlCalendarPreviousView 
            [view]="view" 
            [(viewDate)]="viewDate"><mat-icon>arrow_back</mat-icon>
          </div>
        </div>
        <div class="col-10">
          <div>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</div>
        </div>
        <div class="col-1">
          <div 
            (click)="nextMes()"
            class="btn btn-primary" 
            mwlCalendarNextView 
            [view]="view" 
            [(viewDate)]="viewDate"><mat-icon>arrow_forward</mat-icon>
          </div>
        </div>
      </div>

      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh" 
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [activeDayIsOpen]="activeDayIsOpen" 
        (dayClicked)="dayEnabledClicked($event.day)"> 
      </mwl-calendar-month-view>

      <div class="my-1">
        <mat-icon style="color: #98d7e2;">square</mat-icon> Días con horas 
        <mat-icon style="color: #6633CC; margin-left: 20px;">square</mat-icon> Día seleccionado
      </div>

    </div>

    <div class="ver-horas"> 

      <h4 *ngIf="!day_selected_doctor.check">Seleccionar un día</h4> 
      <h4 *ngIf="day_selected_doctor.check">{{ day_selected_doctor.name }}</h4>

      <div class="hour-blocks">
        <div *ngIf="day_selected_doctor.check">
          <ul>
            <li *ngFor='let hou of hours_day'>
              {{ hou.from }} - {{ hou.to }} 
              <button class="btn btn-success" *ngIf="(hou.sta_id != 24)">{{ hou.estado_hora }}</button>
              <button class="btn btn-info" *ngIf="(hou.sta_id == 24)" (click)="verReserva(hou.id, hou)">{{ hou.estado_hora }}</button>
              <a data-toggle="modal" href="#verHourModal" id="boton1"></a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- EDITAR AGENDA -->
<div *ngIf="item_disp.edit">
  <h2>Editar Agenda</h2>
  <div class="" style="display: flex;">
    <div class="calendar-container agenda-user-calendar" >
      <div class="row text-center">
        <div class="col-1">
          <div 
          (click)="prevMes()"
            class="btn btn-primary" 
            mwlCalendarPreviousView 
            [view]="view" 
            [(viewDate)]="viewDate"><mat-icon>arrow_back</mat-icon>
          </div>
        </div>
        <div class="col-10">
          <div>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</div>
        </div>
        <div class="col-1">
          <div 
          (click)="nextMes()"
            class="btn btn-primary" 
            mwlCalendarNextView 
            [view]="view" 
            [(viewDate)]="viewDate"><mat-icon>arrow_forward</mat-icon>
          </div>
        </div>
      </div>

      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh" 
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [activeDayIsOpen]="activeDayIsOpen" 
        (dayClicked)="dayEnabledClicked($event.day)"> 
      </mwl-calendar-month-view>

      <div class="my-1">
        <mat-icon style="color: #98d7e2;">square</mat-icon> Días con horas 
        <mat-icon style="color: #6633CC; margin-left: 20px;">square</mat-icon> Día seleccionado
      </div>
      
    </div>

    <div class="ver-horas"> 

      <h4 *ngIf="!day_selected_doctor.check">Seleccionar un día</h4> 
      <h4 *ngIf="day_selected_doctor.check">{{ day_selected_doctor.name }}</h4>

      <div class="hour-blocks">
        <div *ngIf="day_selected_doctor.check">
          <input id="{{day_selected_doctor.sch_id}}" type="checkbox" 
            [(ngModel)]="day_selected_doctor.status" 
            [disabled]="(day_selected_doctor.disp == false)"
            [ngClass]="(day_selected_doctor.disp == false)? 'filled-in':'filled-in chk-col-light-blue'"
            (change)="diaCompleto(day_selected_doctor.sch_id)">
          <label for="{{day_selected_doctor.sch_id}}">Día completo</label>
          <hr>
          <ul>
            <li *ngFor='let hou of hours_day'>
              <div class="checkbox ">
                <input id="{{hou.id}}" type="checkbox" 
                  [(ngModel)]="hou.new_status" 
                  [disabled]="(hou.disp == false)"
                  [ngClass]="(hou.disp == false)? 'filled-in':'filled-in chk-col-light-blue'">
                <label for="{{hou.id}}"> {{ hou.from }} - {{ hou.to }}  {{ hou.estado_hora }}</label>
              </div>
            </li>
          </ul>
        </div>

      </div>
      <br>
      <div style="text-align: center;">
        <button class="btn btn-warning" data-toggle="modal" data-target="#editHoursModal"(click)="editar_horas()">Editar Horas</button>
      </div>
    </div>
  </div>
</div>

<!-- HABILITAR HORAS -->
<div *ngIf="item_disp.add">
  <h2>Habilitar horas</h2>
  <div class="" style="display: flex;">
    <div class="calendar-container agenda-user-calendar" >
      <div class="row text-center">
        <div class="col-1">
          <div 
            (click)="prevMes()"
            class="btn btn-primary" 
            mwlCalendarPreviousView 
            [view]="view" 
            [(viewDate)]="viewDate"><mat-icon>arrow_back</mat-icon>
          </div>
        </div>
        <div class="col-10">
          <div>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</div>
        </div>
        <div class="col-1">
          <div 
            (click)="nextMes()"
            class="btn btn-primary" 
            mwlCalendarNextView 
            [view]="view" 
            [(viewDate)]="viewDate"><mat-icon>arrow_forward</mat-icon>
          </div>
        </div>
      </div>

      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh" 
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [activeDayIsOpen]="activeDayIsOpen" 
        (dayClicked)="dayEnabledClicked($event.day)"> 
      </mwl-calendar-month-view>

      <div class="my-1">
        <mat-icon style="color: #98d7e2;">square</mat-icon> Días con horas 
        <mat-icon style="color: #6633CC; margin-left: 20px;">square</mat-icon> Día seleccionado
      </div>
      
    </div>

    <div class="agregar-horas"> 

      <h4 *ngIf="!day_selected_doctor.check">Seleccionar un día</h4> 
      <h4 *ngIf="day_selected_doctor.check">{{ day_selected_doctor.name }}</h4>
 
      <div class="hour-blocks">
        <div *ngIf="day_selected_doctor.check">
          <ul>
            <li *ngFor='let blo of hour_blocks_pot'>
              <div class="checkbox ">
                <input id="{{blo.id}}" type="checkbox" class="filled-in chk-col-light-blue" [(ngModel)]="blo.status">
                <label for="{{blo.id}}">  {{ blo.from }} - {{ blo.to }} </label>
              </div>
            </li>
          </ul>
        </div>

      </div>
      <br>
      <div style="text-align: center;">
        <button class="btn btn-primary" data-toggle="modal" data-target="#addHoursModal" (click)="agregar_horas()">Agregar  Horas</button>
      </div>
    </div>
  </div>

  <br>

  <div class="horas-actuales">
    <div *ngIf="day_selected_doctor.check">
      <span>{{ day_selected_doctor.name }}</span>
      <div>
        Horas del médico existente: <label *ngFor='let hor of hours_day'>[{{ hor.from }} - {{ hor.to }}], </label>
      </div>
    </div>
  </div>

</div>



<!-- Modales --> 
<!-- Información de la consulta -->
<div class="modal fade" id="verHourModal" tabindex="-1" role="dialog" aria-labelledby="verHourModalLabel" aria-hidden="true" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header mx-auto">
        <h4 class="modal-title titulo-modal" id="verHourModalLabel">Datos de la reserva</h4>
      </div>
      <div class="modal-body">
        <ul>
          <li>ID consulta: <strong>{{data_reserva.id_consulta}}</strong></li>
          <li>Nombre paciente: <strong>{{data_reserva.name_ben}}</strong></li>
          <li>Rut: <strong>{{data_reserva.ben_use_rut}}</strong></li>
          <li>Fecha de nacimiento: <strong>{{data_reserva.ben_use_birthday}}</strong></li>
          <li>Email paciente: <strong>{{data_reserva.use_email}}</strong></li>
          <li>Telefono paciente: <strong>{{data_reserva.use_phone}}</strong></li>
          <li>Motivo consulta: <strong>{{data_reserva.name_reason}}</strong></li>
          <li>Doctor: <strong>{{data_reserva.name_doc}}</strong></li>
          <li>Email doctor: <strong>{{data_reserva.doc_email}}</strong></li>
          <li>Telefono doctor: <strong>{{data_reserva.doc_phone}}</strong></li>
          <li>Especialidad: <strong>{{data_reserva.name_area}}</strong></li>
          <li>Día consulta: <strong>{{data_reserva.name_day}}</strong></li>
          <li>Hora consulta: <strong>{{data_reserva.name_hour}}</strong></li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#cancelAppoModal" *ngIf="data_reserva.cancelable">Cancelar Consulta</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
<!-- Confirmar cancelacion de consulta -->
<div class="modal fade" id="cancelAppoModal" tabindex="-1" role="dialog" aria-labelledby="cancelAppoModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header mx-auto">
        <h4 class="modal-title titulo-modal" id="cancelAppoModalLabel">Reserva de Hora</h4>
      </div>
      <div class="modal-body">
				¿Confirma que desea cancelar la hora con el (la) Dr. {{data_reserva.name_doc}} el {{data_reserva.name_day}}
				a las {{data_reserva.name_hour}}?
      </div>
      <div class="modal-footer row">
				<button type="button" class="btn btn-secondary col" data-dismiss="modal" >Cancelar</button>
				<button class="btn btn-primary col" data-dismiss="modal" (click)="confirmarCancelAppo(data_reserva.num_reserva, '')">Si</button>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="addHoursModal" tabindex="-1" role="dialog" aria-labelledby="addHoursModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header mx-auto">
        <h4 class="modal-title titulo-modal" id="addHoursModalLabel">Agregar Horas</h4>
      </div>
      <div class="modal-body">
				¿Confirma que desea agregar las horas?
      </div>
      <div class="modal-footer row">
				<button type="button" class="btn btn-secondary col" data-dismiss="modal" >Cancelar</button>
				<button class="btn btn-primary col" data-dismiss="modal" (click)="confirmarAddHours()">Si</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="editHoursModal" tabindex="-1" role="dialog" aria-labelledby="editHoursModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header mx-auto">
        <h4 class="modal-title titulo-modal" id="editHoursModalLabel">Agregar Horas</h4>
      </div>
      <div class="modal-body">
				¿Confirma que desea EDITAR las horas?
      </div>
      <div class="modal-footer row">
				<button type="button" class="btn btn-secondary col" data-dismiss="modal" >Cancelar</button>
				<button class="btn btn-primary col" data-dismiss="modal" (click)="confirmarEditHours()">Si</button>
      </div>
    </div>
  </div>
</div>




