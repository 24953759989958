<div class="row">
     <div class="col-md-12">
         <br>
         <div class="titulo">
              Video llamada no compatible
         </div>
         <br><br>
         <div class="card" >

          <br><br>
              <mat-icon style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</mat-icon> 
              <br>
              <h2 class="texto-titulo" >Problemas de compatibilidad</h2> 
      
              <br>
              <div>
      
                  <h3 style="margin: 10px;">Por favor intente con otro navegador o con otro dispositivo.</h3>
                  
                  <h3 style="margin: 10px;">Gracias por su comprensión.</h3>
              </div>
              <br>
      
              <!-- <div class="card-deck">
      
                  <div style="width: auto;">
                      <button routerLink="/dashboard" routerLinkActive="active" class="btn btn-outline-primary btn-lg botonModalInicio" style="width: 100%;" >   Ir al inicio   </button>
                  </div>
                  <div style="margin-left: 20px; margin-right: 20px;">
                      <button  class="btn btn-outline-primary btn-lg botonModalAgregar" (click)='siguiente()' >Califica tu atención</button>
                  </div>
      
              </div>
           -->
              
              <br><br>
          </div>
     </div>
</div>
