<app-title-header [title]='_title' [subtitle]='_subtitle'>
</app-title-header>

<div class="col-md-6 col-xs-12" style="margin-bottom: 48px;">
  <app-calendar-month class="" (messageEvent)='clickDayCalendar($event)'>
  </app-calendar-month>
</div>


<!--Consultas dia seleccionado-->
<div *ngIf="fechaSelected" id="next-appointment-day">

  <h3 *ngIf="(dia_seleccionado)" class="pb-3">Consultas al {{fechaSelected.dayTitle}}</h3>

  <div class="not-appointment" *ngIf="(appointmentsDay == 0)">
    <div style="    display: flex;
    padding: 0 10px;
    color: #171994;">
      <mat-icon>info</mat-icon>
      <span>No existen horas para el día seleccionado.</span>
    </div>
  </div>

  <div class="row d-sm-flex">
    <div *ngFor="let appt of appointmentsDay" class="col-md-4 col-sx-12" style="margin-bottom: 30px;">
      <app-next-appointment id="{{appt.id_consulta}}" [data]="appt">
      </app-next-appointment>
    </div>
  </div>
</div>


<!-- Consultas del dia -->
<div id="next-appointment-day">

  <h3 class="pb-3">Consultas al {{toDay.dayTitle}}</h3>

  <div class="not-appointment" *ngIf="(appointmentsToDay.length == 0)">
    <div style="    display: flex;
    padding: 0 10px;
    color: #171994;">
      <mat-icon>info</mat-icon>
      <span>No existen horas para este día.</span>
    </div>
  </div>

  <div class="row d-sm-flex">
    <div *ngFor="let appt of appointmentsToDay" class="col-md-4 col-sx-12" style="margin-bottom: 30px;">
      <app-next-appointment id="{{appt.id_consulta}}" [data]="appt">
      </app-next-appointment>
    </div>
  </div>
</div>

<!-- Consultas futuras no del dia -->
<div id="next-appointment">
  <h3 class="pb-3">Futuras consultas</h3>

  <div class="not-appointment" *ngIf="(appointmentsNext.length == 0)">
    <div style="    display: flex;
    padding: 0 10px;
    color: #171994;">
      <mat-icon>info</mat-icon> 
      <span>No existen horas futuras.</span>
    </div>
  </div>

  <div class="row d-sm-flex">
    <div *ngFor="let appt of appointmentsNext" class="col-md-4 col-sx-12" style="margin-bottom: 30px;">
      <app-next-appointment [data]="appt">
      </app-next-appointment>
    </div>
  </div>
</div>