import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'puntoMiles'
})
export class PuntoMilesPipe implements PipeTransform {

  public transform(value: any) {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");;
    }
    return '0';
}
}
