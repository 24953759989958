import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DocumentsService } from 'src/app/services/documents.service';
import Swal from 'sweetalert2';
import { ReactiveFormsModule } from '@angular/forms';   
import { URL_DOCS } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  fecha_sistema = new Date();
  @Input() eventos: any = {};


  // formData = new FormData(); 
  ruta:any = '';
  doc = null;
  examenes: any;
  ordenes: any;
  recetas: any;
  informes: any;
  URL_DOCUMENTS = URL_DOCS;
  nombre_doc: string = "";

  fileToUpload: File = null;
  tipo_doc: any;
  doc_permitido: boolean = false;

  constructor(
    private _documentServices: DocumentsService,
    public _general: GeneralService
    //private uploadFileReactiveFormObject:ReactiveFormsModule
  ) { }

  ngOnInit(): void {

    this._documentServices.postListarDocExam()
      .then((result:any) => { 

        this.examenes = result;
        var largo = result.length;

        for (let ex of this.examenes){
          let x = ex.att_name.split('-');
          let y = x[0]+'-'+x[1]+'-';
          ex.att_name2 = ex.att_name;
          ex.att_name = (ex.att_name.split(y))[1];
        }
        
        for (let index = 0; index < largo; index++) {
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.pdf','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.png','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.jpg','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.jpeg','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.PDF','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.PNG','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.JPG','');
          this.examenes[index].att_name= this.examenes[index].att_name.replace('.JPEG','');
          // //console.log(this.examenes[index].att_name);
          
        }
        
      })
      .catch(error => { 
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar documentos pasados.',
          timer: 2000
        })
      })
    
    
      this._documentServices.postListarInformeExam()
      .then((result:any) => { 
        this.informes = result;
        var largo = result.length;

        for (let ex of this.informes) {
          ex.att_name2 = ex.att_name;
          let x = ex.att_name.split('-');
          let y = x[0]+'-'+x[1]+'-';

          ex.att_name = (ex.att_name.split(y))[1];
        }
        
        for (let index = 0; index < largo; index++) {
          this.informes[index].att_name= this.informes[index].att_name.replace('.pdf','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.png','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.jpg','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.jpeg','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.PDF','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.PNG','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.JPG','');
          this.informes[index].att_name= this.informes[index].att_name.replace('.JPEG','');
          // //console.log(this.informes[index].att_name);
          
        }
      })
      .catch(error => { 
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar documentos pasados.',
          timer: 2000
        })
      })


    this._documentServices.postListarRecetas()
      .then((result:any) => { 
        this.recetas = result;
        var largo = result.length;

        for (let ex of this.recetas){
          let x = ex.att_name.split('-');
          let y = x[0]+'-'+x[1]+'-';
          ex.att_name2 = ex.att_name;
          ex.att_name = (ex.att_name.split(y))[1];
        }


        for (let index = 0; index < largo; index++) {
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.png','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.jpg','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.jpeg','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.PDF','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.PNG','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.JPG','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.JPEG','');
          this.recetas[index].att_name= this.recetas[index].att_name.replace('.pdf','');
          // //console.log(this.recetas[index].att_name);
          
        }
        
      })
      .catch(error => { 
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar documentos pasados.',
          timer: 2000
        })
      })
  }

  onFileSelected(event) { 
    // //console.log("muestra elementos");
    
    this.doc = event.target.files[0];
    // //console.log(this.doc);
    
    
    var tipo=this.doc.name.split('.');
    // //console.log(tipo);
    this.tipo_doc = tipo[(tipo.length) - 1]
    // //console.log(this.tipo_doc);
    if (this.tipo_doc == 'pdf' || this.tipo_doc == 'png' || this.tipo_doc == 'jpg' || this.tipo_doc == 'jpeg' || this.tipo_doc == 'PDF' || this.tipo_doc == 'PNG' || this.tipo_doc == 'JPG' || this.tipo_doc == 'JPEG') {
      this.doc_permitido = true;
    } else {
      this.doc_permitido = false;
    }
    // //console.log(this.doc_permitido);
    // //console.log("Prueba" + "." + this.tipo_doc);
    
    // this.doc.name = "Prueba" + "." + this.tipo_doc;
    // //console.log(this.doc);
    
    
  }

  handleFileInput(files: FileList) {
    // //console.log("muestra elementos");
    this.fileToUpload = files.item(0);
  }

  delFileSelected() { 
    this.doc = null;
  }
  guardar() {
   

    // //console.log(this.doc);
    // //console.log(this.ruta);       
    if (this.doc && this.ruta) {
      if (this.doc.size <= 20048576 && this.doc_permitido && this.nombreValido()) {

        Swal.fire({
          icon: 'info',
          title: 'Espere por favor...',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 10000
        })
        Swal.showLoading();

        this.nombre_doc = this.nombre_doc + '.' + this.tipo_doc;
        // //console.log(this.nombre_doc);
        
        this._documentServices.postSaveDocument(this.doc, this.ruta, this.nombre_doc)
          .then(results => {
            Swal.close();
            Swal.fire({
              allowOutsideClick: false,
              html:
                ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
                ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El documento se ha subido exitosamente </h1> <br>' +
                ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
              showConfirmButton: false,
            })
            setTimeout(() => {
              Swal.close();
              // Cerrar el modal con id 'subirdocumento'
              const modal = document.getElementById('subirdocumento');
              if (modal) {
                modal.style.display = 'none';
                this.update_doc()
              }
            }, 2000);         
           })
          .catch(error => {
            this.nombre_doc = "";
            Swal.fire({
              allowOutsideClick: false,
              html:
                ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
                ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" > Campos Incompletos </h1> <br>' +
                ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Error al guardar archivo.</p><br>',
              
            }) 
          });
        } else if (!this.doc_permitido) {
          Swal.fire({
            allowOutsideClick: false,
            html:
              ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
              ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" > Tipo de documento no permitido. </h1> <br>' +
              ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Solo puede adjuntar documentos PDF, JPG o PNG.</p><br>',
            
          }) 
      } else {

        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" > Campos Incompletos </h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Por favor complete los campos del formulario.</p><br>',
          
        }) 
      }

    } else { 
      
      this._general.modalError('Campos Incompletos', 'Por favor complete los campos del formulario.');

    }
    

        
        // this._documentServices.postSaveDocument(params,'/postCreateDiagnostic')

  }

  nombreValido() {
    return true;
  }
  eliminarArchivo(item) { 
    //console.log(item);
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();


    this._documentServices.postEliminarArchivo(item.att_name2)
      .then(result => { 

        Swal.close();
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El documento se ha eliminado exitosamente </h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
            showConfirmButton: false,
        })
        setTimeout(() => window.location.reload(), 2000);       

      })
      .catch(error => { 
        Swal.fire({
          icon: 'warning',
          title: 'Error al eliminar documento.',
          timer: 2000
        })

      })
    


  }
  update_doc(){
    this.ngOnInit();
  }


  // onSubmitFile () {   

  //   const formData = new FormData();   

  //   formData.append('file', this.uploadFileReac5tiveFormObject.controls[fileData].value);    

  //   this._documentServices.sendFileDocuments(formData).subscribe((event: any) => {   

  //       if (typeof (event) === 'object') {   

  //       // After file is shared successfully, we can perform next operations from here. 

  //         //console.log (' file sharing is succesfully ');   

  //       }   

  //   });   

  // } 
  
}
