
<h1 class="texto-titulo">Mi Videocosulta</h1>

<br>
<div class="row">
    <div class="col-md-12">
            <app-card-consulta
                [idconsulta]='id_consulta'>

            </app-card-consulta>
    </div>
</div>

<br><br>
<app-encuesta-post-consulta
    [med_con_id]="med_con_id"
    [consulta]="consulta">
</app-encuesta-post-consulta>