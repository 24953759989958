
<div>

  <h2>{{doctor_selected.use_name}} {{doctor_selected.use_maternal_surname}} {{doctor_selected.use_paternal_surname}}</h2>

  <!-- <div >
    <app-calendar-doc id="calendar-doc" *ngIf="!btn_disp.ver"
      (messageEvent)='receiveMessage($event)'>
    </app-calendar-doc>
  </div> -->

  <app-configuracion-agenda *ngIf="!btn_disp.ver"
        [doctor]='doctor_selected'>
  </app-configuracion-agenda>

  <div >
    <app-ver-agenda *ngIf="btn_disp.ver && doctor_selected"
      [doctor]='doctor_selected'>
    </app-ver-agenda>
  </div>
  <br><br>
  <button class="btn btn-outline-secondary" (click)="volver()"  *ngIf="(_usuario.typ_id == 7 || _usuario.typ_id == 8 || _usuario._id == 37)">Volver al Listado Médicos</button>
  
</div>
