import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nombreMes'
})
export class NombreMesPipe implements PipeTransform {

  transform(mesNumero: number): string {
    const nombresMeses = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    return nombresMeses[mesNumero - 1] || 'Mes inválido';
  }

}
