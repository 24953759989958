import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncriptacionService {

  constructor() { }

  toBinary(text: any) {
    const codeUnits = new Uint16Array(text.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = text.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  }

  fromBinary(encoded: any) {

    let binary = atob(encoded)
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }

  getUserInfo() {
    let desencriptado = this.fromBinary(sessionStorage.getItem('usuario'));
    return JSON.parse(desencriptado);
  }

  getPageInfo() {
    let desencriptado = this.fromBinary(sessionStorage.getItem('page'));
    // console.log(JSON.parse(desencriptado));
    
    return JSON.parse(desencriptado);
  }

}
