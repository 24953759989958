import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoginValidationsService {

  constructor() { }

  validaRut = (control: FormControl) => {
    if (!control.value) {
      return { 'invalidRut': true };
    }
    // Despejar Puntos
    var rut_Cliente = control.value;
    var valor = rut_Cliente;
    //reemplazo los puntos por vacio
    let resultado = valor.split('.').join('');
    // reemplazo los - por vacio
    let resultado2 = resultado.split('-').join('');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    // Formatear RUN
    // if (valor != "") {
    //   this.registerForm.controls['rut'].setValue(cuerpo + "-" + dv);
    // }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;

    // Para cada dígito del Cuerpo
    for (i = 1; i <= cuerpo.length; i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado
    dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
      // //console.log("validaRut invalido");
      return { 'invalidRut': true };
    }
    else {
      // //console.log("validaRut valido");
      return null;
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
  }
}
