<div>
  <div id="doctor-list" *ngIf="(usuario.typ_id == 7 || usuario.typ_id == 8 || usuario._id == 37)">
    <div class="div-search">
      <input class="form-control" type="text" name="search" id="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Buscar Médico / Especialista">
    </div>
    <br>
    <div class="div-table">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Especialidad</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="">
          <tr *ngFor='let doc of doctor_list | filter:searchText'>
            <td title="{{doc.id_doctor}}">{{doc.use_name}} {{doc.use_paternal_surname}} {{doc.use_maternal_surname}}</td>
            <td><li *ngFor='let spe of doc.specialties'>{{spe.spe_name}}</li></td>
            <td class="dropdown">
              <button class=" btn btn-primary nav-link dropdown-toggle waves-effect waves-dark" 
                href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Opciones
              </button>
              <div class="dropdown-menu dropdown-menu-right animated fadeIn fast py-0">
                <ul class="mx-0 my-0 px-0 py-0">
                  <li class="btn btn-outline-info nav-link"><a (click)="verAgenda(doc.doc_id)">Ver agenda</a></li>
                  <li class="btn btn-outline-info nav-link"><a (click)="editAgenda(doc.doc_id)">Configurar agenda</a></li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  

</div>