<!-- <div id="main-wrapper">
  <app-header></app-header>
  <app-sidenav></app-sidenav>

  <div class="page-wrapper">

    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->

<div class="container-page" [class.is-mobile]="mobileQuery.matches">

  
  <mat-toolbar class="topbar header">
    <a [disableRipple]="true" mat-icon-button (click)="sidenav.toggle()" class="d-block d-sm-none">
      <mat-icon>menu</mat-icon>
    </a>


    <div class="row">

      <div class="col-6 col-md-5 pl-0 col-6 col-sm-10">
        <a class="navbar-brand">
          <img src="./assets/images/logo-almamedis.png" alt="homepage" class="dark-logo" style="width: auto;"
            routerLink="/dashboard" />
        </a>
      </div>

      <span class="spacer"></span>

      <!-- TELEFONO CONTACT -->
      <div class="col-md-2 notification text-center">
        <ng-container *ngIf="pageInfo.cus_url_phone">
          <a [attr.href]="'tel:' + pageInfo.cus_url_phone.replaceAll(' ', '')" class="d-flex">
            <mat-icon class="button-responsive">call</mat-icon>
          </a>
          <span class="counter d-none d-md-inline-flex">{{ pageInfo.cus_url_phone }}</span>
        </ng-container>
      </div>
      

      <div *ngIf="(_usuario.typ_id == 2)" class="col-md-3 text-right d-none d-sm-block">
        <button routerLink="/reserva-hora" class="btn btn-primary btn-agendar">Reserva tu hora</button>
      </div>
      <div *ngIf="(_usuario.typ_id == 7)" class="col-md-3 text-right d-none d-sm-block">
        <button (click)="reservadeHora()" class="btn btn-primary btn-agendar">Reserva tu hora</button>
      </div>
      <div
        *ngIf="(_usuario.typ_id == 1) ||  (_usuario.typ_id == 8) || (_usuario.typ_id == 12) || (_usuario.typ_id == 13)"
        class="col-md-3 text-right d-none d-sm-block">
      </div>

      <!-- DROP DOWN CERRAR CHANGE_PASSWORD -->
      <div class="col-4 col-md-2 col-sm-2 d-flex d-dm-none  direction justify-content-end ">

        <div class="profile d-none d-sm-block">
          <img class="img-fluid" src="./assets/images/MEDIS.LOGO.RRSS-06.png" width="36" alt="imagen de perfil">
        </div>
        <div class="profile_menu d-none d-sm-block">
          <div class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{ primerNombre | titlecase }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item pointer" data-toggle="modal" data-target="#cambiacontrasena">Cambiar
                contraseña</a>
              <a class="dropdown-item" (click)="logoutUser()">Cerrar Sesión</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>


  <div *ngIf="_usuario.typ_id == 2" class="modal-container" id="surveyModal">
    <div class="modal-dialog" style="padding: 10px;
    border-radius: 20px; height: fit-content;">

            <app-encuesta-modal [pendingSurveys]="pendingSurveysList" (modalClosed)="onModalClosed()"></app-encuesta-modal>
    </div>
</div>


  <mat-sidenav-container class="sidenav-container" #sidenavContainer
    [style.marginTop.px]="mobileQuery.matches ? 70 : 95" fullscreen>

    <mat-sidenav class="mat-drawer-inner-container" #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" [style.marginLeft.px]="mobileQuery.matches ? 0 : 0"
      [class.menu-open]="isExpanded" [class.menu-close]="!isExpanded" [ngStyle]="{ 'width.px': contentMargin }"
      [opened]="mobileQuery.matches ? 'false' : 'true'">
      <!--CLOSE-->

      <mat-toolbar class="menu-tittle" *ngIf="isExpanded">
        <span>Menú</span>
        <span class="spacer"></span>
        <a [disableRipple]="true" mat-icon-button (click)="sidenav.toggle()" class="d-block d-sm-none"
          style="margin-right: 4px;">
          <mat-icon>close</mat-icon>
        </a>
        <a [disableRipple]="true" mat-icon-button (click)="onToolbarMenuToggle()" class="d-none d-sm-block"
          *ngIf="isExpanded">
          <mat-icon *ngIf="isExpanded" style="font-size: 24px; margin-right: 4px;">close</mat-icon>
        </a>
      </mat-toolbar>

      <mat-toolbar *ngIf="!isExpanded">
        <a [disableRipple]="true" mat-list-item (click)="onToolbarMenuToggle()" *ngIf="!isExpanded">
          <mat-icon class="menu-item" mat-list-icon pointer>menu</mat-icon>
        </a>
      </mat-toolbar>

      <mat-nav-list>
        <mat-accordion>

          <a mat-list-item class="d-block d-sm-none">
            <img class="img-fluid" src="./assets/images/MEDIS.LOGO.RRSS-06.png" width="36" alt="imagen de perfil">
            <span *ngIf="isExpanded" style="margin-left: 1rem;" class="menu-item">{{primerNombre | titlecase}}</span>
          </a>
          <hr class="d-block d-sm-none">


          <a mat-list-item routerLink="/dashboard" routerLinkActive="active" [disableRipple]="true"
            class="d-none d-sm-block menu-item" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>home</mat-icon>
            <span *ngIf="isExpanded">Inicio</span>
          </a>

          <a mat-list-item routerLink="/dashboard" routerLinkActive="active" (click)="sidenav.toggle()"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>home</mat-icon>
            <span *ngIf="isExpanded">Inicio</span>
          </a>



          <!-- Mi perfil MEDICO -->

          <a mat-list-item (click)="onToolbarMenuClose()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 1 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">person</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 1 && isExpanded)"
            [expanded]="isOpen" [hideToggle]="hideArrow" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">person</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">person</mat-icon>
                <span *ngIf="isExpanded">Mi perfil</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item routerLink="/profile">
                <span>Mis antecedentes</span>
              </a>
              <a mat-list-item routerLink="/rates">
                <span>Mis tarifas</span>
              </a>
              <a mat-list-item routerLink="/disponibilidad-horarios">
                <span>Disponibilidad de horarios</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none ">
              <a mat-list-item routerLink="/profile">
                <span (click)="sidenav.toggle()">Mis antecedentes</span>
              </a>
              <a mat-list-item routerLink="/rates">
                <span (click)="sidenav.toggle()">Mis tarifas</span>
              </a>
              <a mat-list-item routerLink="/disponibilidad-horarios">
                <span (click)="sidenav.toggle()">Disponibilidad de horarios</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Mi Agenda MEDICO -->

          <a mat-list-item (click)="onToolbarMenuClose()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 1 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">calendar_month</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 1 && isExpanded)"
            [expanded]="isOpen" [hideToggle]="hideArrow" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">calendar_month</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">calendar_month</mat-icon>
                <span *ngIf="isExpanded">Mi agenda</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item routerLink="/proximasConsultas">
                <span>Mis próximas consultas</span>
              </a>
              <a mat-list-item routerLink="/configurar-agenda">
                <span>Configurar agenda</span>
              </a>
              <a mat-list-item routerLink="/historialConsultasDoc">
                <span>Historial de consultas</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item routerLink="/proximasConsultas" (click)="sidenav.toggle()">
                <span>Mis próximas consultas</span>
              </a>
              <a mat-list-item routerLink="/configurar-agenda" (click)="sidenav.toggle()">
                <span>Configurar agenda</span>
              </a>
              <a mat-list-item routerLink="/historialConsultasDoc" (click)="sidenav.toggle()">
                <span>Historial de consultas</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Mi videoconsulta MEDICO -->
          <a mat-list-item routerLink="/meetingRoom" routerLinkActive="active" *ngIf="(_usuario.typ_id == 1)"
            class="d-none d-sm-block" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>videocam</mat-icon>
            <span *ngIf="isExpanded">Videoconsulta</span>
          </a>

          <a mat-list-item routerLink="/meetingRoom" routerLinkActive="active" *ngIf="(_usuario.typ_id == 1)"
            (click)="sidenav.toggle()" class="d-block d-sm-none">
            <mat-icon mat-list-icon>videocam</mat-icon>
            <span *ngIf="isExpanded">Videoconsulta</span>
          </a>

          <!-- ----------------------------------------- PACIENTE (2) --------------------------------------->
          <!-- Mi Perfil PACIENTE-->

          <a mat-list-item (click)="onToolbarMenuClose()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 2 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">person</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 2 && isExpanded)"
            [hideToggle]="hideArrow" [expanded]="isOpen" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">person</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">person</mat-icon>
                <span *ngIf="isExpanded">Mi Perfil</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item routerLink="/profile">
                <span>Mis antecedentes</span>
              </a>
              <a mat-list-item routerLink="/mis-antecedentes">
                <span>Mis antecedentes médicos</span>
              </a>
              <a mat-list-item routerLink="/planes">
                <span>Mis planes y convenios</span>
              </a>
              <a mat-list-item routerLink="/familyGroup">
                <span>Mis beneficiarios</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item routerLink="/profile" (click)="sidenav.toggle()">
                <span>Mis antecedentes</span>
              </a>
              <a mat-list-item routerLink="/mis-antecedentes" (click)="sidenav.toggle()">
                <span>Mis antecedentes médicos</span>
              </a>
              <a mat-list-item routerLink="/planes" (click)="sidenav.toggle()">
                <span>Mis planes y convenios</span>
              </a>
              <a mat-list-item routerLink="/familyGroup" (click)="sidenav.toggle()">
                <span>Mis beneficiarios</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Mi Agenda PACIENTE-->

          <a mat-list-item (click)="onToolbarMenuToggle()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 2 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">calendar_month</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 2 && isExpanded)"
            [hideToggle]="hideArrow" [expanded]="isOpen" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">calendar_month</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">calendar_month</mat-icon>
                <span *ngIf="isExpanded">Mi agenda</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item routerLink="/reserva-hora" routerLinkActive="active">
                <span>Reserva tu hora</span>
              </a>
              <a mat-list-item routerLink="/mis-proximas-consultas" routerLinkActive="active">
                <span>Mis próximas consultas</span>
              </a>
              <a mat-list-item routerLink="/mi-historial-consulta" routerLinkActive="active">
                <span>Mi historial de consultas</span>
              </a>
              <a mat-list-item routerLink="/consultas-pendientes-pago" routerLinkActive="active">
                <span>Mis reservas pendientes</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item routerLink="/reserva-hora" (click)="sidenav.toggle()">
                <span>Reserva tu hora</span>
              </a>
              <a mat-list-item routerLink="/mis-proximas-consultas" (click)="sidenav.toggle()">
                <span>Mis próximas consultas</span>
              </a>
              <a mat-list-item routerLink="/mi-historial-consulta" (click)="sidenav.toggle()">
                <span>Mi historial de consultas</span>
              </a>
              <a mat-list-item routerLink="/consultas-pendientes-pago" (click)="sidenav.toggle()">
                <span>Mis reservas pendientes</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Mi videoconsulta PACIENTE-->
          <a mat-list-item routerLink="/meetingRoom" *ngIf="(_usuario.typ_id == 2)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>videocam</mat-icon>
            <span *ngIf="isExpanded">Videoconsulta</span>
          </a>
          <a mat-list-item routerLink="/meetingRoom" *ngIf="(_usuario.typ_id == 2)" (click)="sidenav.toggle()"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>videocam</mat-icon>
            <span *ngIf="isExpanded">Videoconsulta</span>
          </a>


          <!-- Mis documentos PACIENTE-->

          <a mat-list-item (click)="onToolbarMenuToggle()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 2 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">folder_shared</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 2 && isExpanded)"
            [expanded]="isOpen" [hideToggle]="hideArrow" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">folder_shared</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">folder_shared</mat-icon>
                <span *ngIf="isExpanded">Mis documentos</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item routerLink="/documents">
                <span>Ver y adjuntar</span>
              </a>
              <a mat-list-item routerLink="/documents-consult">
                <span>Recetas y otros</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item routerLink="/documents" (click)="sidenav.toggle()">
                <span>Ver y adjuntar</span>
              </a>
              <a mat-list-item routerLink="/documents-consult" (click)="sidenav.toggle()">
                <span>Recetas y otros</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- ----------------------------------------- CONTACT CENTER (7) y COORDINADOR MEDICO (8) --------------------------------------->

          <a mat-list-item (click)="onToolbarMenuClose()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 7 && !isExpanded) || (_usuario.typ_id == 8 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">person</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true"
            *ngIf="(_usuario.typ_id == 7 && isExpanded) || (_usuario.typ_id == 8 && isExpanded)" [expanded]="isOpen"
            [hideToggle]="hideArrow" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">person</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">person</mat-icon>
                <span *ngIf="isExpanded">Mi perfil</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item routerLink="/profile">
                <span>Mis datos</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item routerLink="/profile" (click)="sidenav.toggle()">
                <span (click)="sidenav.toggle()">Mis datos</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <a mat-list-item routerLink="/estado_consulta" *ngIf="(_usuario.typ_id == 7) || (_usuario.typ_id == 8)"
            class="d-none d-sm-block" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>zoom_in</mat-icon>
            <span *ngIf="isExpanded">Estado de consultas</span>
          </a>

          <a mat-list-item routerLink="/estado_consulta" (click)="sidenav.toggle()"
            *ngIf="(_usuario.typ_id == 7) || (_usuario.typ_id == 8)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>zoom_in</mat-icon>
            <span *ngIf="isExpanded">Estado de consultas</span>
          </a>


          <a mat-list-item routerLink="/historialConsultasCoordinador" *ngIf="(_usuario.typ_id == 8)"
            class="d-none d-sm-block" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>person_search</mat-icon>
            <span *ngIf="isExpanded">Historial de consultas</span>
          </a>

          <a mat-list-item routerLink="/historialConsultasCoordinador" (click)="sidenav.toggle()"
            *ngIf="(_usuario.typ_id == 8)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>person_search</mat-icon>
            <span *ngIf="isExpanded">Historial de consultas</span>
          </a>

          <!-- ----------------------------------------- CONTACT CENTER (7) --------------------------------------->

          <!-- Buscador de paciente o medico -->
          <a mat-list-item routerLink="/buscador" *ngIf="(_usuario.typ_id == 7)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>search</mat-icon>
            <span *ngIf="isExpanded">Buscador</span>
          </a>

          <a mat-list-item routerLink="/buscador" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 7)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>search</mat-icon>
            <span *ngIf="isExpanded">Buscador</span>
          </a>

          <!-- Buscador de usuario en BBDD -->
          <a mat-list-item routerLink="/buscador-user" *ngIf="(_usuario.typ_id == 7)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>person_search</mat-icon>
            <span *ngIf="isExpanded">Buscador de Usuarios</span>
          </a>

          <a mat-list-item routerLink="/buscador-user" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 7)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>person_search</mat-icon>
            <span *ngIf="isExpanded">Buscador de Usuarios</span>
          </a>


          <!-- Reservar horas Pacientes CC -->
          <a mat-list-item routerLink="/reservar-hora-contact" *ngIf="(_usuario.typ_id == 7)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>calendar_month</mat-icon>
            <span *ngIf="isExpanded">Reserva tu hora paciente </span>
          </a>

          <a mat-list-item routerLink="/reservar-hora-contact" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 7)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>calendar_month</mat-icon>
            <span *ngIf="isExpanded">Reserva tu hora paciente </span>
          </a>


          <!-- Ver Horas Pacientes CC -->
          <a mat-list-item routerLink="/horas-contact" *ngIf="(_usuario.typ_id == 7)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>event</mat-icon>
            <span *ngIf="isExpanded">Ver Horas paciente</span>
          </a>

          <a mat-list-item routerLink="/horas-contact" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 7)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>event</mat-icon>
            <span *ngIf="isExpanded">Ver Horas paciente</span>
          </a>


          <!-- Editar Hora Pacientes por ID CC -->
          <a mat-list-item routerLink="/hora-contact-id" *ngIf="(_usuario.typ_id == 7)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>edit_calendar</mat-icon>
            <span *ngIf="isExpanded">Hora por ID</span>
          </a>

          <a mat-list-item routerLink="/hora-contact-id" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 7)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>edit_calendar</mat-icon>
            <span *ngIf="isExpanded">Hora por ID</span>
          </a>


          <!-- Agenda de los medicos CC -->
          <a mat-list-item routerLink="/agenda-medicos" *ngIf="(_usuario.typ_id == 7)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>perm_contact_calendar</mat-icon>
            <span *ngIf="isExpanded">Agenda médicos</span>
          </a>

          <a mat-list-item routerLink="/agenda-medicos" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 7)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>perm_contact_calendar</mat-icon>
            <span *ngIf="isExpanded">Agenda médicos</span>
          </a>

          <!-- ----------------------------------------- COORDINADOR MEDICO (8) --------------------------------------->
          <!-- Administrador -->
          <!-- Médicos CM -->
          <a mat-list-item routerLink="/medicos" *ngIf="(_usuario.typ_id == 8)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>phone_android</mat-icon>
            <span *ngIf="isExpanded">Médicos</span>
          </a>

          <a mat-list-item routerLink="/medicos" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 8)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>phone_android</mat-icon>
            <span *ngIf="isExpanded">Médicos</span>
          </a>

          <!-- Agenda de los medicos CM -->
          <a mat-list-item routerLink="/agenda-medicos" *ngIf="(_usuario.typ_id == 8)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>perm_contact_calendar</mat-icon>
            <span *ngIf="isExpanded">Agenda médicos</span>
          </a>

          <a mat-list-item routerLink="/agenda-medicos" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 8)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>perm_contact_calendar</mat-icon>
            <span *ngIf="isExpanded">Agenda médicos</span>
          </a>

          <!-- Listado de consultas -->
          <a mat-list-item routerLink="/listado-consultas" *ngIf="(_usuario.typ_id == 8)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            <span *ngIf="isExpanded">Listado de consultas</span>
          </a>

          <a mat-list-item routerLink="/listado-consultas" (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 8)"
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            <span *ngIf="isExpanded">Listado de consultas</span>
          </a>

          <!-- ----------------------------------------- SOLO MEDICO Y PACIENTE --------------------------------------->
          <!-- Chat con tu médico -->
          <a mat-list-item (click)="ruta('chat')" *ngIf="(_usuario.typ_id == 2)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>chat</mat-icon>
            <span *ngIf="isExpanded">Chat con tu médico</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 2)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>chat</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('chat')">Chat con tu médico</span>
          </a>

          <!-- Chat con tu paciente -->
          <a mat-list-item (click)="ruta('chat')" *ngIf="(_usuario.typ_id == 1)" class="d-none d-sm-block"
            (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>chat</mat-icon>
            <span *ngIf="isExpanded">Chat con tu paciente</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 1)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>chat</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('chat')">Chat con tu paciente</span>
          </a>

          <!-- Otras prestaciones -->
          <a mat-list-item (click)="ruta('otras-prestraciones')" *ngIf="(_usuario.typ_id == 2)"
            class="d-none d-sm-block" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>volunteer_activism</mat-icon>
            <!-- <mat-icon mat-list-icon>otras-prestraciones</mat-icon> -->
            <span *ngIf="isExpanded">Otras prestaciones y beneficios</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 2)" class="d-block d-sm-none">
            <!-- <mat-icon mat-list-icon>otras-prestraciones</mat-icon> -->
            <mat-icon mat-list-icon>volunteer_activism</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('otras-prestraciones')">Otras prestaciones y beneficios</span>
          </a>
          <!-- Licencias médicas -->
          <a mat-list-item
            *ngIf="(_usuario.typ_id == 1 && _usuario.doc_lme == true) || _usuario.typ_id == 7 || _usuario.typ_id == 8 "
            class="d-none d-sm-block" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>apartment</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('licencias-medicas')">Licencias médicas</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()"
            *ngIf="(_usuario.typ_id == 1 && _usuario.doc_lme == true) || _usuario.typ_id == 7 || _usuario.typ_id == 8 "
            class="d-block d-sm-none">
            <mat-icon mat-list-icon>apartment</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('licencias-medicas')">Licencias médicas</span>
          </a>

          <!--------------------------- TARIFAS Y DESCUENTOS COORDINADOR MEDICO-------------------------->
          <!-- Tarifas y descuentos -->

          <a mat-list-item (click)="onToolbarMenuToggle()" class="d-none d-sm-block menu-item"
            *ngIf="(_usuario.typ_id == 8 && !isExpanded)">
            <mat-icon mat-list-icon *ngIf="!isExpanded">paid</mat-icon>
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 8 && isExpanded)"
            [hideToggle]="hideArrow" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">paid</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">paid</mat-icon>
                <span *ngIf="isExpanded">Tarifas y Descuentos</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item (click)="ruta('coordinatorRates')">
                <span *ngIf="isExpanded">Tarifas</span>
              </a>

              <a mat-list-item (click)="ruta('discountCodes')">
                <span *ngIf="isExpanded">Codigos de Descuento</span>
              </a>
            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item (click)="sidenav.toggle()" routerLink="coordinatorRates">
                <span *ngIf="isExpanded">Tarifas</span>
              </a>

              <a mat-list-item (click)="sidenav.toggle()" routerLink="discountCodes">
                <span *ngIf="isExpanded">Codigos de Descuento</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!--------------------------- ADMINISTRADOR-------------------------->

          <!-- Administrador-->
          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(_usuario.typ_id == 12)" [hideToggle]="hideArrow"
            (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title mat-list-item>
                <mat-icon mat-list-icon *ngIf="isExpanded">paid</mat-icon>
                <mat-icon mat-list-icon *ngIf="!isExpanded" (click)="onToolbarMenuToggle()">paid</mat-icon>
                <span *ngIf="isExpanded">Administrador</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="d-none d-sm-block" *ngIf="isExpanded">
              <a mat-list-item (click)="ruta('admin-planes')">
                <span *ngIf="isExpanded">Clientes y planes</span>
              </a>

              <a mat-list-item (click)="ruta('admin-users')">
                <span *ngIf="isExpanded">Usuarios</span>
              </a>

              <a mat-list-item routerLink="/admin-database">
                <span *ngIf="isExpanded">Carga de bases</span>
              </a>

            </mat-nav-list>
            <mat-nav-list class="d-block d-sm-none">
              <a mat-list-item routerLink="/admin-planes" (click)="sidenav.toggle()">
                <span *ngIf="isExpanded">Clientes y planes</span>
              </a>

              <a mat-list-item routerLink="/admin-users" (click)="sidenav.toggle()">
                <span *ngIf="isExpanded">Usuarios</span>
              </a>

              <a mat-list-item routerLink="/admin-database" (click)="sidenav.toggle()">
                <span *ngIf="isExpanded">Carga de bases</span>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- ----------------------------------------- GESTION --------------------------------------->

          <a mat-list-item class="d-none d-sm-block" *ngIf="(_usuario.typ_id == 13)" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>check_box</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-uso-de-asistencia')">Uso de asistencia</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 13)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>check_box</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-uso-de-asistencia')">Uso de asistencia</span>
          </a>


          <a mat-list-item class="d-none d-sm-block" *ngIf="(_usuario.typ_id == 13)" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>support_agent</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-ivr')">Gestion IVR</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 13)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>support_agent</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-ivr')">Gestion IVR</span>
          </a>


          <a mat-list-item class="d-none d-sm-block" *ngIf="(_usuario.typ_id == 13)" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>group_add</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-activacion')">Activaciones</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 13)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>group_add</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-activacion')">Activaciones</span>
          </a>



          <a mat-list-item class="d-none d-sm-block" *ngIf="(_usuario.typ_id == 13)" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>star_border</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-evaluaciones')">Evaluación del servicio</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 13)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>star_border</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-evaluaciones')">Evaluación del servicio</span>
          </a>


          <a mat-list-item class="d-none d-sm-block" *ngIf="(_usuario.typ_id == 13)" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>auto_stories</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-disponibilidad')">Disponibilidad medica</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" *ngIf="(_usuario.typ_id == 13)" class="d-block d-sm-none">
            <mat-icon mat-list-icon>auto_stories</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('gestion-disponibilidad')">Disponibilidad medica</span>
          </a>


          <!-- ----------------------------------------- GENERALES SIN PAGINA --------------------------------------->
          <!-- Mesa de ayuda -->
          <a mat-list-item class="d-none d-sm-block" (click)="onToolbarMenuClose()">
            <mat-icon mat-list-icon>phone</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('mesaAyuda')">Mesa de ayuda</span>
          </a>

          <a mat-list-item (click)="sidenav.toggle()" class="d-block d-sm-none">
            <mat-icon mat-list-icon>phone</mat-icon>
            <span *ngIf="isExpanded" (click)="ruta('mesaAyuda')">Mesa de ayuda</span>
          </a>

          <hr class="d-block d-sm-none">

          <!-- Cerrar sesión MOVIL -->
          <a class="d-block d-sm-none" mat-list-item data-toggle="modal" data-target="#cambiacontrasena"
            (click)="sidenav.toggle()">
            <mat-icon mat-list-icon>key</mat-icon>
            <span>Cambiar contraseña</span>
          </a>

          <!-- Mesa de ayuda -->
          <a class="d-block d-sm-none" mat-list-item routerLink="/login" (click)="sidenav.toggle()">
            <mat-icon mat-list-icon>logout</mat-icon>
            <span>Cerrar sesión</span>
          </a>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="padding: 20px;" [ngStyle]="{ 'margin-left.px': contentMargin }">
      <router-outlet></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>

<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<div class="modal fade" id="cambiacontrasena" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog2 modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <p class="tituloModal">Cambiar contraseña</p>
        <br>
        <form class="form col-md-12" id="CambiaPass" autocomplete="off" [formGroup]="cambiaPass"
          (ngSubmit)="cambiaPassword()">
          <br>
          <!-- <div class="form-group row">
            <div class="col-md-12">
              <input class="form-control camposModalAgregar" type="password" placeholder="Contraseña actual*"
                formControlName="contrasena_actual" id="contrasena_actual">
            </div>
          </div> -->
          <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
            [ngClass]="{'signup-input-wrapper-addon-blue': signupPass, 'signup-input-wrapper-addon-gray': !signupPass}">
            <div class="col-10 col-md-11 m-0 signup-custom-input">
              <div class="input-group m-0 p-0">
                <input class="form-control" [type]="fieldTextType1 ? 'text' : 'password'"
                  formControlName="contrasena_actual" id="contrasena_actual" name="contrasena_actual"
                  placeholder="Contraseña actual" onfocus="this.placeholder = '';"
                  onblur="this.placeholder = 'Contraseña actual';" (focus)="signupPass=true;" (blur)="signupPass=false;"
                  (input)="checkPass1()">
              </div>
            </div>
            <div class="col-2 col-md-1 m-0 m-0 align-self-center">
              <span class="calendar" (click)="toggleFieldTextType1()">
                <mat-icon>{{ fieldTextType1 ? 'visibility' : 'visibility_off' }}</mat-icon>
              </span>
            </div>
          </div>
          <br>

          <!-- <div class="form-group row">
            <div class="col-md-12">
              <input class="form-control camposModalAgregar" type="password" placeholder="Nueva contraseña*"
                formControlName="contrasena_nueva" (input)="checkPass1()" id="contrasena_nueva">
            </div>
          </div> -->

          <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
            [ngClass]="{'signup-input-wrapper-addon-blue': signupPass1Focus, 'signup-input-wrapper-addon-gray': !signupPass1Focus}">
            <div class="col-10 col-md-11 m-0 signup-custom-input">
              <div class="input-group m-0 p-0">
                <input class="form-control" [type]="fieldTextType2 ? 'text' : 'password'"
                  formControlName="contrasena_nueva" id="contrasena_nueva" name="contrasena_nueva"
                  placeholder="Contraseña nueva*" onfocus="this.placeholder = '';"
                  onblur="this.placeholder = 'Contraseña nueva*';" (focus)="signupPass1Focus=true;" (blur)="signupPass1Focus=false;"
                  (input)="checkPass1()">
              </div>
            </div>
            <div class="col-2 col-md-1 m-0 m-0 align-self-center">
              <span class="calendar" (click)="toggleFieldTextType2()">
                <mat-icon>{{ fieldTextType2 ? 'visibility' : 'visibility_off' }}</mat-icon>
              </span>
            </div>
          </div>
          <br>

          <div class="password-desc ">
            <p class="{{ passLength === 0 && !formSubmitted ? 
                'default' : 
                passMinLength ? 
                'ok' : 
                'nok' }}" style="display: flex; align-items: center;">
                <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ? 'radio_button_unchecked' : passMinLength ? 'check_circle' : 'cancel' }}</mat-icon>
                Debe contener al menos 8 caracteres
            </p>
            <p class="{{ passLength === 0 && !formSubmitted ?
                'default' :
                passCapital ?
                'ok' :
                'nok' }}" style="display: flex; align-items: center;">
                <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ? 'radio_button_unchecked' : passCapital ? 'check_circle' : 'cancel' }}</mat-icon>

                Debe contener al menos 1 mayúscula
            </p>
            <p class="{{ passLength === 0 && !formSubmitted ?
                'default' :
                passLetter ?
                'ok' :
                'nok' }}" style="display: flex; align-items: center;">
                <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ? 'radio_button_unchecked' : passLetter ? 'check_circle' : 'cancel' }}</mat-icon>
                Debe contener al menos 1 minúscula
            </p>
            <p class="{{ passLength === 0 && !formSubmitted ?
                'default' :
                passNumber ?
                'ok' :
                'nok' }}"   style="display: flex; align-items: center;">
                <mat-icon style="font-size: 15px;">{{ passLength === 0 && !formSubmitted ? 'radio_button_unchecked' : passNumber ? 'check_circle' : 'cancel' }}</mat-icon>
                Debe contener al menos 1 numero
            </p>
        </div>
          <div class="form-group row signup-input-wrapper signup-input-wrapper-addon"
            [ngClass]="{'signup-input-wrapper-addon-blue': signupPass2Focus, 'signup-input-wrapper-addon-gray': !signupPass2Focus}">
            <div class="col-10 col-md-11 m-0 signup-custom-input">
              <div class="input-group m-0 p-0">
                <input class="form-control" [type]="fieldTextType3 ? 'text' : 'password'"
                  formControlName="contrasena_nueva_2" id="contrasena_nueva_2" name="contrasena_nueva_2"
                  placeholder="Repite tu contraseña nueva*" onfocus="this.placeholder = '';"
                  onblur="this.placeholder = 'Repite tu contraseña nueva*';" (focus)="signupPass2Focus=true;" (blur)="signupPass2Focus=false;"
                  (input)="checkPass1()">
              </div>
            </div>
            <div class="col-2 col-md-1 m-0 m-0 align-self-center">
              <span class="calendar" (click)="toggleFieldTextType3()">
                <mat-icon>{{ fieldTextType3 ? 'visibility' : 'visibility_off' }}</mat-icon>
              </span>
            </div>
          </div>
          <p *ngIf="cambiaPass.hasError('noEsIgual')" class="mb-0 pb-0 mt-1 field-error">
            <mat-icon style="font-size: 15px;">cancel</mat-icon>            
            Debes ingresar la misma contraseña del campo anterior.
          </p>
          
          <!--<div class="row">
                  <div class="col text-danger">
                      <p *ngIf="campoNoValidoPassword('contrasena_nueva_2')"> Las contraseñas deben ser iguales</p>
                  </div>
              </div>-->
          <br>
          <br>
          <div class="container-fluid">
            <div class="row" style="text-align: center;">
              <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                  data-dismiss="modal">Cancelar</button>
              </div>
              <div class="col-md-6 letrabotonagregar">
                <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar">Guardar cambios</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalFinLlamada1" aria-labelledby="exampleModalLabel" aria-hidden="true" #modalFinLlamada1>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <!-- <p class="tituloModal">Cambiar contraseña</p>
          <br> -->
        <div style="text-align: center; font-family: Mulish; font-style: normal;">
          <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">
            Estás a punto de salir de tu consulta virtual</h1>
          <br>
          <p style="color: #171994; font-size: 18px; line-height: 24px;">Para volver a tu video consulta haz
            click en <strong>No quiero salir,</strong> de lo contrario haz click en <strong>Salir de mi
              consulta.</strong></p>
        </div>
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-md-6">
                      <button type="button" class="btn-swal-cancel" style="text-align: center;" data-dismiss="modal">No quiero salir</button>
                  </div>
                  <div class="col-md-6">
                      <button type="submit" class="btn-swal-confirm" (click)="navegar()">Salir de mi consulta</button>
                  </div> -->

            <div class="col-md-6">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" (click)="navegar()">Salir de
                mi consulta</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-dismiss="modal"
                style="font-size: 16px;line-height: 24px;">No quiero salir</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<button #modalFinLlamada type="button" class="btn d-none" data-toggle="modal" data-target="#modalFinLlamada1">
  aux trigger for login loading modal
</button>
