
import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { 
  CalendarEvent, 
  CalendarView, 
  CalendarDateFormatter,
  DAYS_OF_WEEK } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';


@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})


export class CalendarComponent {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  @Input() eventos: any; 
  @Output() messageEvent = new EventEmitter<string>();

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SUNDAY] //[DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  clickedDate: Date;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  activeDayIsOpen: boolean = false; //def: true
  day_selected_doctor: any; // dia del calendario clickeado

  constructor() { }

  ngOnInit(): void {
    this.events = this.eventos;
    // this.refresh.next();
  }

  // Click sobre el dia. Muestra los eventos del dia
    dayEnabledClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    // if (isSameMonth(date, this.viewDate)) {
      if ( events.length === 0 ) {
        // //console.log('Click sobre un dia sin evento')
        this.day_selected_doctor = { length: 0}
        
      } else {
        // //console.log('Hay eventos')
        this.day_selected_doctor = {
          date: events[0].start,
          id: events[0].title
        }        
      }
      this.messageEvent.emit(this.day_selected_doctor)
      this.viewDate = date;
    // }
  }


}



