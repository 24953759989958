<p style="font-family: Mulish;font-style: normal;font-weight: 800;font-size: 24px;line-height: 32px;color: #171994;mix-blend-mode: normal;opacity: 0.9;">Mis consultas por finalizar</p>


<!-- <div class="row d-flex d-sm-none">
    <div *ngFor="let appt of appointments" class="col-12 pt-4">
        <app-unfinished-appointment [data]="appt"></app-unfinished-appointment>
    </div>
</div> -->


<div *ngIf="appointments.length > 0" class="col-12">
    <div class="card-deck">

        <div *ngFor="let appt of appointments" class="col-sm-6">
            <div class="card">
                <app-unfinished-appointment [data]="appt"></app-unfinished-appointment>
            </div>
        </div>
    </div>
</div>

<div *ngIf="appointments.length == 0" class="col-12">
    <div class="card-alerta" >
        <table>
          <tr>
            <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon>   </td>
            <td><p class="letras-alerta" style="margin: 5px 5px 5px;">No tiene consultas sin finalizar.</p>
            </td>
            
          </tr>
        </table>
        <br>
    </div>
</div>