import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AdminMedicosComponent } from './admin-medicos/admin-medicos.component'; import { DocumentsUserComponent } from "./documents-user/documents-user.component";
import { AdminPlansComponent } from "./mantenedor/admin-plans/admin-plans.component";
import { AgendaDelMedicoComponent } from './agenda-del-medico/agenda-del-medico.component'; import { AuthGuard } from 'src/app/services/auth.guard';
import { AgendaMedicosComponent } from "./agenda-medicos/agenda-medicos.component";
import { AntecedentesComponent } from './antecedentes/antecedentes.component';
import { BuscaInfoComponent } from "./buscadores/busca-info/busca-info.component";
import { CodigoDescuentoComponent } from "../components/tarifas-coordinador/codigo-descuento/codigo-descuento.component";
import { ConfigurarAgendaComponent } from './mi-agenda-medico/configurar-agenda/configurar-agenda.component'// mi perfil (medico) - disponibilidad
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetalleConsultaComponent } from "./detalle-consulta/detalle-consulta.component";
import { DetallesConsultaPacienteComponent } from "../components/detalles-consulta-paciente/detalles-consulta-paciente.component"; import { DetallesConsultaHistoricoComponent } from "../components/detalles-consulta-historico/detalles-consulta-historico.component";
import { DisponibilidadHorarioComponent } from './perfil-medico/disponibilidad-horario/disponibilidad-horario.component';
import { DocumentsUserConsultComponent } from "./documents-user-consult/documents-user-consult.component";
import { EncuestaComponent } from "./encuesta/encuesta.component";
import { EndurlComponent } from './endurl/endurl.component';
import { ErrorVideoComponent } from "./error-video/error-video.component";
import { EstadoConsultaComponent } from './estado-consulta/estado-consulta.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { GrupofamiliarComponent } from "./beneficiaries/grupofamiliar/grupofamiliar.component";
import { HistorialConsultasMedicoComponent } from './historial-consultas-medico/historial-consultas-medico.component';
import { HistorialConsultasPacienteComponent } from './mi-agenda/historial-consultas-paciente/historial-consultas-paciente.component';// mi-agenda (medico)
import { HoraPorIdContactComponent } from "./hora-por-id-contact/hora-por-id-contact.component";
import { LicenciasMedicasComponent } from "./licencias-medicas/licencias-medicas.component";
import { ListadoConsultasComponent } from "./listado-consultas/listado-consultas.component";
import { ListarHorasContactComponent } from './listar-horas-contact/listar-horas-contact.component';
import { LobbyComponent } from './lobby/lobby.component';
import { LobbyAcompComponent } from './lobby-acomp/lobby-acomp.component';// mi-agenda/ (paciente)
import { MeetinglistComponent } from './meetinglist/meetinglist.component';
import { MesaAyudaComponent } from "./mesa-ayuda/mesa-ayuda.component";
import { ModificarConsultaComponent } from "../components/modificar-consulta/modificar-consulta.component";
import { NgModule, Component } from '@angular/core';
import { PagesComponent } from './pages.component';
import { PaymentInfoComponent } from "./payment-info/payment-info.component";
import { PerfilComponent } from './perfil/perfil.component';
import { PlanesConveniosComponent } from "./beneficiaries/planes-convenios/planes-convenios.component";
import { ProgressComponent } from './progress/progress.component';
import { PromesasComponent } from './promesas/promesas.component';
import { ProximasConsultasMedicoComponent } from './mi-agenda-medico/proximas-consultas-medico/proximas-consultas-medico.component';
import { ProximasConsultasPacienteComponent } from './mi-agenda/proximas-consultas-paciente/proximas-consultas-paciente.component';
import { ReservaHoraCcComponent } from './mi-agenda-cc/reserva-hora-cc/reserva-hora-cc.component';
import { ReservaHoraComponent } from './mi-agenda/reserva-hora/reserva-hora.component';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';
import { RxjsComponent } from './rxjs/rxjs.component';
import { SalaChatComponent } from "./sala-chat/sala-chat.component";
import { SalaesperaComponent } from './salaespera/salaespera.component';
import { TarifasComponent } from "../components/tarifas/tarifas.component";
import { TarifasCoordinadorComponent } from "../components/tarifas-coordinador/tarifas-coordinador.component";
import { VideoconsultaAcompananteComponent } from './videoconsulta-acompanante/videoconsulta-acompanante.component';
import { VideoconsultaMedicoFinComponent } from "./videoconsulta-medico-fin/videoconsulta-medico-fin.component";
import { VideoconsultaPacienteComponent } from './videoconsulta-paciente/videoconsulta-paciente.component';
import { VideoconsultaMedicoComponent } from './videoconsulta-medico/videoconsulta-medico.component';
import { ConsultasPendientesPagoComponent } from './mi-agenda/consultas-pendientes-pago/consultas-pendientes-pago.component';
import { AdminUsersComponent } from './mantenedor/admin-users/admin-users.component';
import { AdminDatabaseLoadingComponent } from './mantenedor/admin-database-loading/admin-database-loading.component';
import { CanDeactivateGuard } from '../services/can-deactivate.guard';
import { CanDeactivateVideoGuard } from '../services/can-deactivate-video.guard';
import { BuscaUserComponent } from './buscadores/busca-user/busca-user.component';
import { BuscaHistorialConsultaComponent } from './buscadores/busca-historial-consulta/busca-historial-consulta.component';
import { UsoAsistenciaComponent } from './gestion/uso-asistencia/uso-asistencia.component';
import { GestionIVRComponent } from './gestion/gestion-ivr/gestion-ivr.component';
import { ActivacionComponent } from './gestion/activacion/activacion.component';
import { EvaluacionesComponent } from './gestion/evaluaciones/evaluaciones.component';
import { DisponibilidadComponent } from './gestion/disponibilidad/disponibilidad.component';
import { OtrasPrestacionesComponent } from './otras-prestaciones/otras-prestaciones.component';
import { OtraPrestacionComponent } from './otra-prestacion/otra-prestacion.component';
import { BeneficioComponent } from './beneficio/beneficio.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: PagesComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },     // mi agenda paciente
      { path: 'account', component: AccountSettingsComponent, data: { titulo: 'Tema' } },
      { path: 'admin-planes', component: AdminPlansComponent, data: { titulo: 'Admin Planes' } },
      { path: 'admin-database', component: AdminDatabaseLoadingComponent, data: { titulo: 'Admin Carga de base' } },
      { path: 'admin-users', component: AdminUsersComponent, data: { titulo: 'Admin Usuarios' } },
      { path: 'agenda-medicos', component: AgendaMedicosComponent, data: { titulo: 'Agenda Médicos' } },
      { path: 'agenda/:id_doctor/:item', component: AgendaDelMedicoComponent, data: { titulo: 'Agenda' } },
      { path: 'buscador', component: BuscaInfoComponent, data: { titulo: 'Buscador' } },
      { path: 'buscador-user', component: BuscaUserComponent, data: { titulo: 'Buscador-user' } },
      { path: 'chat', component: SalaChatComponent, data: { titulo: 'Chat' } },
      { path: 'configurar-agenda', component: ConfigurarAgendaComponent, data: { titulo: 'Configurar agenda' } },     // mi perfil (medico) - disponibilidad
      { path: 'consultas-pendientes-pago', component: ConsultasPendientesPagoComponent, data: { titulo: 'Mis consultas pendientes por pagar' } },  // Consultas pendientes de pago
      { path: 'coordinatorRates', component: TarifasCoordinadorComponent, data: { titulo: 'Tarifas' } },
      { path: 'dashboard', component: DashboardComponent, data: { titulo: 'Inicio' } },
      { path: 'detalleconsulta/:med_con_id', component: DetallesConsultaPacienteComponent, data: { titulo: 'Detalle de mi consulta' } },
      { path: 'detalleconsultachat/:med_con_id', component: DetalleConsultaComponent, data: { titulo: 'Historial de consultas' } },
      { path: 'discountCodes', component: CodigoDescuentoComponent, data: { titulo: 'Codigos de Descuento' } },
      { path: 'disponibilidad-horarios', component: DisponibilidadHorarioComponent, data: { titulo: 'Disponibilidad de horarios' } },
      { path: 'documents-consult', component: DocumentsUserConsultComponent, data: { titulo: 'Mis Documentos' } },
      { path: 'documents', component: DocumentsUserComponent, data: { titulo: 'Mis Documentos' } },
      { path: 'encuesta/:med_con_id/:id_consulta', component: EncuestaComponent, data: { titulo: 'Encuesta' } },
      { path: 'endurl/:idReunion', component: EndurlComponent, data: { titulo: 'EndUrl' } },
      { path: 'error-video', component: ErrorVideoComponent, data: { titulo: 'Error Videollamada' } },
      { path: 'estado_consulta', component: EstadoConsultaComponent, data: { titulo: 'Estado Consulta' } },
      { path: 'familyGroup', component: GrupofamiliarComponent, data: { titulo: 'Mi Grupo Familiar' } },
      { path: 'finvideoconsulta/:idConsulta', component: VideoconsultaMedicoFinComponent, data: { titulo: 'Videoconsulta' } },
      { path: 'grafica1', component: Grafica1Component, data: { titulo: 'Graficas' } },
      { path: 'historialConsultasDoc', component: HistorialConsultasMedicoComponent, data: { titulo: 'Historial de consultas' } },
      { path: 'historialConsultasDoc/paciente/:med_con_id', component: DetallesConsultaHistoricoComponent, data: { titulo: 'Historial de consultas' } },
      { path: 'historialConsultasCoordinador', component: BuscaHistorialConsultaComponent, data: { titulo: 'Historial de consultas' } },
      { path: 'hora-contact-id', component: HoraPorIdContactComponent, data: { titulo: 'Horas Contact Center' } },
      { path: 'horas-contact', component: ListarHorasContactComponent, data: { titulo: 'Horas Contact Center' } },
      { path: 'licencias-medicas', component: LicenciasMedicasComponent, data: { titulo: 'Licencias Médicas' } },
      { path: 'listado-consultas', component: ListadoConsultasComponent, data: { titulo: 'Listado Consultas' } },     // Licencias Medicas
      { path: 'medicos', component: AdminMedicosComponent, data: { titulo: 'Médicos' } },
      { path: 'meetingRoom', component: SalaesperaComponent, data: { titulo: 'Sala de Espera' } },
      { path: 'mesaAyuda', component: MesaAyudaComponent, data: { titulo: 'Mesa de ayuda' } },     // { path: 'videozoom/:use_typ_id/:zoo_mee_room_id/:zoo_acc_email/:id_consulta', component: VideoZoomComponent, data: { titulo: 'VideoZoom' } },     { path: 'videoconsulta/:idConsulta', component: VideoconsultaMedicoComponent, data: { titulo: 'Videoconsulta' } },
      { path: 'mi-historial-consulta', component: HistorialConsultasPacienteComponent, data: { titulo: 'Mi historial de consultas' } },     // mi agenda medico
      { path: 'mis-antecedentes', component: AntecedentesComponent, data: { titulo: 'Mis Antecedentes Medicos' } },
      { path: 'mis-proximas-consultas', component: ProximasConsultasPacienteComponent, data: { titulo: 'Mis próximas consultas' } },
      { path: 'mivideoconsulta/:idReunion/:idConsulta/:idMedCon', canDeactivate: [CanDeactivateVideoGuard], component: VideoconsultaPacienteComponent, data: { titulo: 'Mi videoconsulta' } },
      { path: 'modificarConsulta/:med_con_id', component: ModificarConsultaComponent, data: { titulo: 'Modificar consulta' } },
      { path: 'payment-info/:idTransaction', component: PaymentInfoComponent, data: { titulo: 'Información de pago' } },
      { path: 'planes', component: PlanesConveniosComponent, data: { titulo: 'Mis planes y convenios' } },
      { path: 'profile', component: PerfilComponent, data: { titulo: 'Mi Perfil' } },
      { path: 'progress', component: ProgressComponent, data: { titulo: 'Progres Bar' } },
      { path: 'promesas', component: PromesasComponent, data: { titulo: 'Promesas' } },
      { path: 'otras-prestraciones', component: OtrasPrestacionesComponent, data: { titulo: 'Otras prestaciones de salud' } },
      { path: 'otra-prestracion/:agre_typ_id_', component: OtraPrestacionComponent, data: { titulo: 'Otras prestaciones de salud' } },
      { path: 'beneficio/:route/:agre_id_', component: BeneficioComponent, data: { titulo: 'Otras prestaciones de salud' } },
      { path: 'proximasConsultas', component: ProximasConsultasMedicoComponent, data: { titulo: 'Próximas consultas' } },
      { path: 'proximasConsultas/paciente/:med_con_id', component: DetallesConsultaPacienteComponent, data: { titulo: 'Próxima consulta paciente' } },
      { path: 'rates', component: TarifasComponent, data: { titulo: 'Mis Tarifas' } },
      { path: 'reserva-hora', canDeactivate: [CanDeactivateGuard], component: ReservaHoraComponent, data: { titulo: 'Reserva tu hora' } },
      { path: 'reserva-pendiente/:id_consulta', canDeactivate: [CanDeactivateGuard], component: ReservaHoraComponent, data: { titulo: 'Reserva tu hora' } },
      { path: 'reservar-hora-contact', canDeactivate: [CanDeactivateGuard], component: ReservaHoraCcComponent, data: { titulo: 'Reservar Hora CC' } },
      { path: 'room', component: MeetinglistComponent, data: { titulo: 'Video Consulta' } },
      { path: 'rxjs', component: RxjsComponent, data: { titulo: 'RXJS' } },
      { path: 'salaespera-group/:idReunion/:id_consulta/:med_con_id', component: LobbyAcompComponent, data: { titulo: 'Lobby' } },
      { path: 'salaespera/:idReunion/:id_consulta/:med_con_id', component: LobbyComponent, data: { titulo: 'Lobby' } },
      { path: 'videoconsulta-group/:idReunion/:idConsulta/:idMedCon', canDeactivate: [CanDeactivateVideoGuard], component: VideoconsultaAcompananteComponent, data: { titulo: 'Videoconsulta grupal' } },
      { path: 'videoconsulta/:idConsulta', component: VideoconsultaMedicoComponent, data: { titulo: 'Videoconsulta' } },
      { path: 'gestion-uso-de-asistencia', component: UsoAsistenciaComponent, data: { titulo: 'Uso de asistencia' } },
      { path: 'gestion-ivr', component: GestionIVRComponent, data: { titulo: 'Gestion IVR' } },
      { path: 'gestion-activacion', component: ActivacionComponent, data: { titulo: 'Activaciones' } },
      { path: 'gestion-evaluaciones', component: EvaluacionesComponent, data: { titulo: 'Evaluaciones' } },
      { path: 'gestion-disponibilidad', component: DisponibilidadComponent, data: { titulo: 'Disponilidad Medica' } },





    ]
  }]; @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

export class PagesRoutingModule { }
