import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NombreArchivosPipe } from './nombre-archivos.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { GetImagePipe } from './get-image.pipe';
import { SplitPipe } from './split.pipe';
import { PuntoMilesPipe } from './punto-miles.pipe';
import { NoSanitizePipe } from './no-sanitize.pipe';
import { NombreMesPipe } from './nombre-mes.pipe';



@NgModule({
  declarations: [
    NombreArchivosPipe,
    SafeUrlPipe,
    GetImagePipe,
    SplitPipe,
    PuntoMilesPipe,
    NoSanitizePipe,
    NombreMesPipe
  ],
  exports: [
    NombreArchivosPipe,
    SafeUrlPipe,
    GetImagePipe,
    SplitPipe,
    PuntoMilesPipe,
    NoSanitizePipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
