import { AuthRoutingModule } from './auth/auth.routing'; // rutas de auth
import { NgModule } from '@angular/core';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { OnboardingRoutingModule } from './onboarding/onboarding-routing.module';
import { PagesRoutingModule } from './pages/pages.routing'; // rutas de pages
import { RouterModule, Routes } from "@angular/router";
import { VerificacionDocumentosModule } from './verificacion-documentos/verificacion-documentos.module';


const routes: Routes = [
  {path: '**', component: NopagefoundComponent}, // en caso que sea una ruta que no existe le doy el no page found
];



@NgModule({
  declarations: [],
  imports: [ 
    RouterModule.forRoot( routes, {useHash:true} ),
    PagesRoutingModule,
    AuthRoutingModule,
    OnboardingRoutingModule,
    VerificacionDocumentosModule
   ],
  exports:[ RouterModule],
 
})
export class AppRoutingModule { }
