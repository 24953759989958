import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-customer',
  templateUrl: './admin-customer.component.html',
  styleUrls: ['./admin-customer.component.css']
})
export class AdminCustomerComponent implements OnInit {
  @ViewChild('editCustomerTri') editCustomerTri: ElementRef;
  @ViewChild('deleteCustomerTri') deleteCustomerTri: ElementRef;
  @ViewChild('addCustomerTri') addCustomerTri: ElementRef;
  @ViewChild('showProductsTri') showProductsTri: ElementRef;
  @ViewChild('insProductsTri') insProductsTri: ElementRef;

  customers: any[] = [];
  editSelected: any = {};
  deleteSelected: any = {};
  newCustomer: any = {
    cus_image: null,
    cus_name: '',
    cus_url: '',
    cus_phone: '',
  };
  editCustomerSelected: any = {
    cus_image: null,
    cus_name: '',
    cus_url_site: '',
    cus_url_phone: '',
  };
  customerSelected: any = {};
  newProduct: any = {
    cus_ben_id: '',
    pro_value: ''
  };
  constructor(
    private customerService: CustomerService,
    private generalService: GeneralService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.postListarCustomer();
    this.newCustomer = {
      cus_image: null,
      cus_name: '',
      cus_url_site: '',
      cus_url_phone: '',
      cus_can_pay: false,
    };
    this.editCustomerSelected = {
      cus_image: null,
      cus_name: '',
      cus_url: '',
      cus_phone: '',
      cus_can_pay: false,
    };
  }

  async postListarCustomer() {
    this.customers = [];
    this.generalService.modalCargando();
    await this.customerService.postListarCustomer({ products_list: true })
      .then((result: any) => {
        // console.log(result);
        this.customers = result;
        Swal.close();
      })
      .catch((error: any) => {
        Swal.close();
      });
  }

  deleteCustomer(customer) {
    this.deleteSelected = customer;
    this.renderer.selectRootElement(this.deleteCustomerTri.nativeElement).click();
  }

  confirmDelete() {
    if (!this.deleteSelected.cus_id) {
      this.generalService.modalError('Falta información', 'Debe seleccionar un cliente.');
      return;
    }
    this.generalService.modalCargando();
    this.renderer.selectRootElement(this.deleteCustomerTri.nativeElement).click();
    this.customerService.postBorrarCustomer(this.deleteSelected.cus_id)
      .then(async result => {
        this.generalService.modalExitoso('Cliente eliminado', 'El cliente se eliminó exitosamente.');
        await this.generalService.sleep(1000);
        this.ngOnInit();
      })
      .catch(error => {
        this.generalService.modalError('Error en eliminar', 'El cliente no se pudo eliminar.');
      });
  }

  editCustomer(customer) {
    this.editSelected = customer;
    this.renderer.selectRootElement(this.editCustomerTri.nativeElement).click();
    this.editCustomerSelected = JSON.parse(JSON.stringify(customer));

  }

  showProducts(customer) {
    this.customerSelected = customer;
    this.renderer.selectRootElement(this.showProductsTri.nativeElement).click();
  }

  addCustomer() {
    //console.log('addCustomer');
    this.renderer.selectRootElement(this.addCustomerTri.nativeElement).click();
  }

  onFileSelected(event) {
    //console.log(event);
    if (!event || !event.target || !event.target.files || !event.target.files[0]) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido');
    }
    this.newCustomer.cus_image = event.target.files[0];
  }

  onFileSelectedEdit(event) {
    if (!event || !event.target || !event.target.files || !event.target.files[0]) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido');
    }
    this.editCustomerSelected.cus_image = event.target.files[0];
    //console.log(this.editCustomerSelected.cus_image);

  }

  confirmAdd() {
    if (!this.newCustomer.cus_image) {
      this.generalService.modalError('Falta información', 'Debe seleccionar una imagen.');
      return;
    } else if (!this.newCustomer.cus_name) {
      this.generalService.modalError('Falta información', 'Debe indicar un nombre.');
      return;
    }
    this.renderer.selectRootElement(this.addCustomerTri.nativeElement).click();
    this.generalService.modalCargando();
    this.customerService.postCrearCustomer(this.newCustomer.cus_name, this.newCustomer.cus_url, this.newCustomer.cus_phone, this.newCustomer.cus_image, this.newCustomer.cus_can_pay)
      .then(async result => {
        this.generalService.modalExitoso('Ingresado', 'Se ha creado el cliente.');
        await this.generalService.sleep(500);
        this.ngOnInit();
      })
      .catch(error => {
        this.generalService.modalError('Error', 'Error al crear cliente.');
      });

  }

  confirmEdit() {
    if (!this.editCustomerSelected.cus_id) {
      this.generalService.modalError('Falta información', 'Debe seleccionar un cliente.');
      return;
    } else if (!this.editCustomerSelected.cus_name) {
      this.generalService.modalError('Falta información', 'Debe indicar un nombre.');
      return;
    }
    this.generalService.modalCargando();
    this.renderer.selectRootElement(this.editCustomerTri.nativeElement).click();
    this.customerService.postModificarCustomer(this.editCustomerSelected.cus_id,
      this.editCustomerSelected.cus_name,
      this.editCustomerSelected.cus_url_site,
      this.editCustomerSelected.cus_url_phone,
      this.editCustomerSelected.cus_image,
      this.editCustomerSelected.cus_can_pay
    )
      .then(async result => {
        this.generalService.modalExitoso('Modificado', 'Se ha modificado el cliente.');
        await this.generalService.sleep(500);
        this.ngOnInit();
      })
      .catch(error => {
        this.generalService.modalError('Error', 'Error al modificar cliente.');
      });
  }

  deleteProduct(product) {
    if (!product.cus_ben_pro_id) {
      this.generalService.modalError('Falta información', 'Debe seleccionar un producto.');
      return;
    }
    this.generalService.modalCargando();
    this.customerService.deleteProduct({ cus_ben_pro_id: product.cus_ben_pro_id })
    .then(async result => {
      // this.generalService.modalExitoso('Eliminado', 'Se ha eliminado el producto.');
      await this.postListarCustomer();
      for (let i = 0; i < this.customers.length; i++) {
        const element = this.customers[i];
        if (element.cus_id == this.customerSelected.cus_id) {
          this.customerSelected = JSON.parse(JSON.stringify(element));
        }
      }
    })
    .catch(error => {
      this.generalService.modalError('Error', 'Error al eliminar producto.');
    });
  }

  showProduct() {
    this.renderer.selectRootElement(this.showProductsTri.nativeElement).click();
    this.renderer.selectRootElement(this.insProductsTri.nativeElement).click();
    this.newProduct = {
      cus_ben_id: '',
      pro_value: ''
    };
  }

  addProduct() {
    if (!this.newProduct.cus_ben_id || !this.newProduct.pro_value) {
      this.generalService.modalError('Falta información', 'Debe llenar el formulario.');
      return;
    }
    this.generalService.modalCargando();
    this.customerService.insertProduct(this.newProduct)
    .then(async result => {
      // this.generalService.modalExitoso('Eliminado', 'Se ha eliminado el producto.');
      await this.postListarCustomer();
      for (let i = 0; i < this.customers.length; i++) {
        const element = this.customers[i];
        if (element.cus_id == this.customerSelected.cus_id) {
          this.customerSelected = JSON.parse(JSON.stringify(element));
        }
      }
      this.renderer.selectRootElement(this.insProductsTri.nativeElement).click();
      this.renderer.selectRootElement(this.showProductsTri.nativeElement).click();
    })
    .catch(error => {
      this.generalService.modalError('Error', 'Error al eliminar producto.');
    });
  }

}
