<div>
  <div id="consultation_list" *ngIf="(usuario.typ_id == 7 || usuario.typ_id == 8 || usuario._id == 37)">
		<div>
        <label>Médico</label>
        <div class="form-group col-4">
            <select [(ngModel)]="doctor_id" class="form-control" required>
                <option value="">Todos los médicos</option>
                <option *ngFor="let x of doctor_list" [value]="x.doc_id">{{x.use_name}} {{x.use_paternal_surname}} {{x.use_maternal_surname}}</option>
            </select>
        </div>
    </div>

    	  <div>
        <label>Estados de la Consulta</label>
        <div class="form-group col-4">
            <select [(ngModel)]="status_id" class="form-control" required>
                <option value="" >Todas las consultas</option>
                <option *ngFor="let x of status_list" [value]="x.sta_id">{{x.sta_name}}</option>
            </select>
        </div>
    </div>
	<div>
        <label>Periodo </label>
        <div>
		<label>Fecha Inicio: &nbsp;&nbsp; </label>
            <input 
      			type="date" class="form-control col-2" 
      			name="dateini" [ngModel]="dateini | date:'yyyy-MM-dd'" (ngModelChange)="dateini = $event" required>
		<label>&nbsp;&nbsp; Hasta &nbsp;&nbsp; </label>
  			<input 
      			type="date" class="form-control col-2" 
      			name="datefin" [ngModel]="datefin | date:'yyyy-MM-dd'" (ngModelChange)="datefin = $event" required>
				  <button class="form-control col-1" (click)="onListar(status_id, doctor_id, dateini, datefin)"> Listar </button>
				  <button class="form-control col-1" (click)="onExportar()"> Exportar </button>
        </div>
		
		<!-- <button (click)="onExportar()">Exportar</button> -->
    </div>

    <br>
    <div class="div-table">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Numero de reserva</th>
            <th>Nombre Médico</th>
            <th>Rut Médico</th>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Estado de la consulta</th>
            <th>Procentaje de Pago</th>
          </tr>
        </thead>
        <tbody class="">
          <tr *ngFor='let lis of consultation_list | filter:searchText'>
            <td>{{lis.numero_de_reserva}}</td>
            <td >{{lis.nombre_doctor}}</td>
            <td >{{lis.rut_doctor}}</td>
            <td >{{lis.fecha | date:'yyyy-MM-dd'}}</td>
            <td >{{lis.hora}} Hrs.</td>
            <td >{{lis.estado_de_la_consulta}}</td>
            <td >{{lis.porcentaje_de_pago}} %</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>  

</div>
