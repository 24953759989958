import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  constructor(
    public router: Router,
  ) {}


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       ERRORES desde el servidor       /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  requestError(err){
    switch (err.status) {
      case 401: //Autenticación: servidor no recive el token / token expirado
        Swal.fire({
          icon: 'error',
          title: 'Favor reingresar',
          text: '401 - ' + err.error.msg,
          timer: 2000
        })
        setTimeout(() => this.router.navigate(['login']), 2000); // setTimeout(() => ; this._usuarioService.logout(), 2000);
        break;
      case 403: // Servicio sin permiso
        Swal.fire({
          icon: 'error',
          title: 'Sin autorización',
          text: '403 - ' + err.error.msg,
        })
        // throw (err.error.msg);
        break;
      case 405: // Solicitudes no permitidas
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: '405 - ' + err.error.msg,
        })
        break;

      case 500: // Error entre servidor - BD, problemas de query, etc.
        Swal.fire({
          icon: 'error',
          title: 'Error servidor',  
          text: '500 - ' + err.error.msg,
        })
        break;
      
      case 0:
        Swal.fire({
          icon: 'error',
          title: 'Error de conexión',
          timer: 5000
        }) 
        setTimeout(() => window.location.reload(), 10*1000);
        break;

      default:
        Swal.fire({
          icon: 'error',
          title: 'Contactar a TI',  
          text: 'Problemas de conexión (default)',
          // timer: 2000
        })
        break;
    }
  }




}
