import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent implements OnInit {
  @Input() agreement: any = {};
  URL_DOCS = URL_DOCS;

  constructor(
    private router: Router,
    private generalServices: GeneralService,
  ) { }

  ngOnInit(): void {
  }

  goToAgreement() {
    if (!this.agreement || !this.agreement.agre_id_) {
      this.generalServices.modalError('Error','No se puede acceder a esa información.');
      return;
    }
    let url = '/beneficio/prestacion/'+this.agreement.agre_id_;
    this.router.navigateByUrl(url);
  }

}
