import { Routes, RouterModule } from "@angular/router"
import { NgModule } from "@angular/core";
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { LoginWrapperDoctorComponent } from './login-wrapper-doctor/login-wrapper-doctor.component';
import { LoginWrapperWorkersComponent } from "./login-wrapper-workers/login-wrapper-workers.component";
import { LoginMiddlewareComponent } from "./login-middleware/login-middleware.component";
import { LoginErrorMiddlewareComponent } from "./login-error-middleware/login-error-middleware.component";

const routes: Routes = [
    { path: 'login-admin', component: LoginWrapperWorkersComponent },
    { path: 'login-contact', component: LoginWrapperWorkersComponent },
    { path: 'login-coordinador', component: LoginWrapperWorkersComponent },
    { path: 'login-gestion', component: LoginWrapperWorkersComponent },
    { path: 'login-customer', component: LoginWrapperWorkersComponent },
    { path: 'login-doctor', component: LoginWrapperDoctorComponent },
    { path: 'login-error', component: LoginErrorMiddlewareComponent },
    { path: 'login-middleware/:idTransaction', component: LoginMiddlewareComponent },
    { path: 'login', component: LoginWrapperComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }