import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';
import { ZoomService } from 'src/app/services/zoom.service';

@Component({
  selector: 'app-lobby-acomp',
  templateUrl: './lobby-acomp.component.html',
  styleUrls: ['./lobby-acomp.component.css']
})
export class LobbyAcompComponent implements OnInit {

  public id_room: any;
  public parametros_url: any;
  public entro_anfitrion: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private _zoomServices: ZoomService)
  {
    this.activatedRoute.params.subscribe(parametros => {
      
      this.parametros_url = parametros;
      //console.log(this.parametros_url);
      
    });
    this.id_room = this.parametros_url.idReunion;
    let params = {
      id_consulta: this.parametros_url.id_consulta
    };
    this._zoomServices.postUpdateStartLobby(params)
      .then(result => {
        //console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
   }

  ngOnInit(): void {
    this.observador();
  }

  async observador() {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    }; 

        
    while (!this.entro_anfitrion) {

      this.http.post(URL_SERVICIOS+'/postMeetingState', ({ id_room: this.id_room }), httpOptions).subscribe((
        data: any[]) => {
        //console.log(data);
        if (data[0].zoo_mee_active) {
          //console.log("Ya entraron");
          
          //console.log(this.parametros_url);
          this.entro_anfitrion = true;
          var url = "videoconsulta-group/" + this.parametros_url.idReunion + "/" + this.parametros_url.id_consulta+"/"+this.parametros_url.med_con_id;
          
          this.router.navigate([url]);
        
        }
      }); 
      //console.log("No entrarron");
      await this.sleep(10000);
    }
    
   }
   sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  
  ngOnDestroy() {

    this.entro_anfitrion = true;
  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    this.entro_anfitrion = true;
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    this.entro_anfitrion = true;
  }

}
