<div class="col-12 divContent">
     <ng-container *ngIf="page.main_page">
          <button class="primary buttonStyle" (click)="setPage({customer:true})">Administrar Clientes</button>
          <br>
          <button class="primary buttonStyle" (click)="setPage({plans:true})">Administrar Planes</button>
          <br>
          <button class="primary buttonStyle" (click)="setPage({beneficiary_plans:true})">Administrar
               Beneficiarios</button>
     </ng-container>
</div>

<div style="width: 100%;" class="row">
     <ng-container *ngIf="!page.main_page">
          <div class="col-12 divContent">
               <button class=" buttonGoBack" (click)="setPage({main_page:true})"><mat-icon style="margin-right: 5px;">chevron_left</mat-icon>Volver</button>
          </div><br>
     </ng-container>

     <ng-container *ngIf="page.customer">
          <div class="col-12 divCard">
               <app-admin-customer></app-admin-customer>
          </div>
     </ng-container>

     <ng-container *ngIf="page.plans">
          <div class="col-12 divCard">
               <app-admin-plans-component></app-admin-plans-component>
          </div>
     </ng-container>

     <ng-container *ngIf="page.beneficiary_plans">
          <div class="col-12 divCard">
               <app-admin-beneficiary-plans></app-admin-beneficiary-plans>
          </div>
     </ng-container>
</div>