<!-- modal -->
<button
  #trigLoginLoadingModal
  type="button"
  class="btn d-none"
  data-toggle="modal"
  data-target="#loginLoadingModal"
>
  aux trigger for login loading modal
</button>
<button
  #trigLoginErrorModal
  type="button"
  class="btn d-none"
  data-toggle="modal"
  data-target="#loginErrorModal"
>
  aux trigger for login error modal
</button>

<!-- form -->
<!-- login form component -->
<div class="login-box">
  <form class="" ngNativeValidate #f="ngForm" (ngSubmit)="ingresar(f)">
    <div class="form-group">
      <input
        class="form-control login-input"
        type="text"
        name="rut"
        [(ngModel)]="rut"
        placeholder="Ingresa tu RUT"
        (input)="changeRut()"
        required
      />
    </div>
    <div class="form-group">
      <div class="input-group login-input">
        <input
          class="form-control"
          [type]="fieldTextType ? 'text' : 'password'"
          name="password"
          ngModel
          placeholder="Contraseña"
          required
        />
        <span class="input-group-addon" (click)="toggleFieldTextType()">
          <mat-icon>{{ fieldTextType ? 'visibility' : 'visibility_off' }}</mat-icon>
        </span>
      </div>
    </div>
    <div
      *ngIf="!loginMobile"
      class="form-group form-check checkbox text-right d-none d-sm-block"
    >
      <input
        id="checkbox-signup"
        type="checkbox"
        class="form-check-input filled-in chk-col-white-t"
        [(ngModel)]="recuerdame"
        name="recuerdame"
      />
      <label class="" for="checkbox-signup" class="form-check-label"
        >Recordar mi cuenta</label
      >
    </div>
    <div class="form-group text-center m-t-10 mb-1">
      <button class="btn btn-submit" type="submit" name="submit">Entrar</button>
    </div>
    <div class="form-group text-center">
      <button
        *ngIf="loginPaciente"
        type="button"
        class="btn btn-signup"
        data-toggle="modal"
        data-target="#signupModal"
      >
        Crear mi cuenta
      </button>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="link-recover-pass"
        data-toggle="modal"
        data-target="#recoveryModal"
      >
        ¿Olvidaste tu contraseña?
      </button>
    </div>
  </form>
</div>
