<div class="modal-dialog">
    <div class="modal-body">
        <div class="form-group row">
            <div class="col-md-12">
                <p class="tituloModal">
                    Estás a punto de salir de tu consulta virtual </p>
                <p class="subTituloModal">
                    Para volver a tu video consulta haz click en <b>No quiero salir,</b> de lo contrario haz click en <b> Salir de mi consulta.</b></p>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <button type="button" class="btn botonCancelar colorLetraVolver" mat-dialog-close>No quiero salir</button>
        </div>
        <div class="col-md-6 letrabotonagregar">
            <button type="button" class="btn botonVolver" [mat-dialog-close]="true">Salir de mi consulta</button>
        </div>
    </div>
</div>