import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';

@Component({
  selector: 'app-endurl',
  templateUrl: './endurl.component.html',
  styleUrls: ['./endurl.component.css']
})
export class EndurlComponent implements OnInit {
  public parametros_url: any;
  constructor(private activatedRoute: ActivatedRoute, private http:HttpClient, private router: Router) { 

    this.activatedRoute.params.subscribe(parametros => {
      
      this.parametros_url = parametros;
    });

    // let headers = new Headers();
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'authorization': sessionStorage.getItem('token'),
    //     'Content-Type': 'application/json'
    //   })
    // }; 
    // let url_post = URL_SERVICIOS + '/postUpdateEndMeeting';
    
    // this.http.post(url_post, ({ id_room: this.parametros_url.idReunion }), httpOptions).subscribe((
    //   data: any[]) => { 
    //   //console.log(data);

    //   this.router.navigate(['/', 'meetingRoom']);
      
    //   setTimeout(() => window.location.reload(), 1000);
    
    // });
    this.router.navigate(['/', 'meetingRoom']);
      
    setTimeout(() => window.location.reload(), 1000);
    
    //this.router.navigateByUrl('meetinglist');
  }

  ngOnInit(): void {
  }

}

