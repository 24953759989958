import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URL_DOCS } from 'src/app/config/config';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent implements OnInit {
  URL_DOCS = URL_DOCS;

  isDashboard: boolean = false;


  constructor(private router: Router) { }
  @Input() benefits: any[] = [];
  ngOnInit(): void {
    this.checkRoute();
    
  }
  
  checkRoute(): void {
    const currentRoute = this.router.url;
    
    // Verifica si la ruta es '/dashboard'
    if (currentRoute.includes('/dashboard')) {
      this.isDashboard = true;
    } else {
      this.isDashboard = false;
    }
  }

}
