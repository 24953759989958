import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { isThisQuarter } from 'date-fns';
import { CompleterData, CompleterService } from 'ng2-completer';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { Usuario } from 'src/app/models/usuario.model';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { GeneralService } from 'src/app/services/general.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ZoomService } from 'src/app/services/zoom.service';
import Swal from 'sweetalert2';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-hora-por-id-contact',
  templateUrl: './hora-por-id-contact.component.html',
  styleUrls: ['./hora-por-id-contact.component.css']
})
export class HoraPorIdContactComponent implements OnInit {

  @ViewChild(CalendarComponent) CALENDAR: CalendarComponent; //para llamar las funciones contenidas en ese componente

  options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }; //opciones labels fecha  
  tipo_usuario_name: string;                //medico, paciente
  eventos = [];                             // eventos para enviar al caliendario
  hours_doctor: any;                        //Todas las horas del doctor
  appointments_user: any;
  appointments_user_prox: any = []; 
  appointments_user_past: any = [];
  appointment_user_edit: any;              //hora a modificar
  day_available: boolean = false;           // disponibilidad del dia seleccionado
  day_available_date: string;               // texto fecha del dia disponible seleccionado en el calendario
  hours_available_day: any = [];            // horas disponiles por doctor del dia seleccionado
  hours_doctor2: any;                        //Todas las horas del doctor
  eventos2 = [];
  calendar_edit_bool = false;                 // existe el calendario
  day_available2: boolean = false;           // disponibilidad del dia seleccionado
  day_available_date2: string;               // texto fecha del dia disponible seleccionado en el calendario
  hours_available_day2: any = [];            // horas disponiles por doctor del dia seleccionado
  hora_seleccionada: boolean = false;   // despues de seleccionar |1 hora
  hora_old = '';
  hora_new = '';
  nueva_hora: any;
  acepta_edicion = false;
  solo_ver_historial = true;                    //Propiedad que permite ver el historial-consultas
  cancel_reason: any = '';
  cancel_by: any = '';

  listUserType: any[] = [
    {type_user_id:1,type_user_name:'MEDICO'},
    {type_user_id:2,type_user_name:'PACIENTE'},
    {type_user_id:8,type_user_name:'COORDINADOR'},
    {type_user_id:7,type_user_name:'CONTACT CENTER'},
  ];
  modify_by: any = '';

  usuario: Usuario;
  id_consulta: any;                               //Consulta que se le pasa a historial-consultas
  consulta: any = {};   

  data_selected: any = {                         // Resumen de la informacion seleccionada
    num_reserva: 0, //agregada
    name_doc: '',
    name_area: '',
    name_hour: '',
    name_day: '',
    name_ben: '',
    name_reason: '',
    name_symptoms: '',
    estado_apoo: '',
  }    

  rut_usuario: string;  // rut usuario actual
  use_id: any;
  list_usuario: any=[];


  beneficiarios: CompleterData;
  beneficiarios_data: any = [];

  id_beneficiario: any;
  beneficiario_selected: any;
  searchStrMain: string;  

  mostrar_horas= false;

  ver_horas = false;

  constructor(
    public _usuarioService: UsuarioService,
    public _schedule: ScheduleService,
    public _general: GeneralService,
    private router: Router,
    private zoomServices : ZoomService
  ) { }

  ngOnInit(): void {

  


    // this._general.postListarBeneficiarioMyB()
    // .then(results => { 
    //   this.beneficiarios_data = results;
    //   for (let ben of this.beneficiarios_data){
    //     ben.nombre_completo = ben.use_name + ' ' + ben.use_paternal_surname + ' ' + ben.use_maternal_surname + 
    //       ':' + ben.use_username + ' | Titular: ' +ben.main_use_name + 
    //       ' ' + ben.main_use_paternal_surname + ':' + ben.main_use_username;
    //   }
    //   this.beneficiarios = this.completerService.local(this.beneficiarios_data, 'nombre_completo', 'nombre_completo')    
    // })
    // .catch(error => { 
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'Error al cargar los beneficiarios.',
    //     timer: 2000
    //   })

    // })
  }


  // [PACIENTE] Consulta todas las horas reservadas del usuario actual 
  // getAppointmentUser(use_id){
  //   this._schedule.getAppointment(use_id, null) 
  //     .then(data => {
  //       //console.log("data");
        
  //       //console.log(data);
  //       this.ver_horas = true;
  //       this.appointments_user = [];
  //       this.appointments_user_prox = [];
  //       this.appointments_user_past = [];
        
  //       this.appointments_user = data;

  //       for (let appo of this.appointments_user ){
  //         appo.date_text = (new Date(appo.sch_date)).toLocaleString('es-US', this.options);
  //         appo.date_dt = (!appo.sch_date_dt.days? '': appo.sch_date_dt.days+'días ')+ appo.sch_date_dt.hours +':'+ appo.sch_date_dt.minutes +':'+ appo.sch_date_dt.seconds 
  //         appo.estado_past = 'ERRADA'
  //         appo.estado_past = appo.tel_doc_app_boo_sta_id == 10? (appo.zoo_mee_end_meeting? 'FINALIZADA':'NO FINALIZADA'):appo.estado_past;
  //         appo.estado_past = appo.tel_doc_app_boo_sta_id == 11? 'CANCELADA':appo.estado_past;
  //         if ( appo.sch_date_dt.milliseconds < 0 || appo.tel_doc_app_boo_sta_id == 11){ this.appointments_user_past.push(appo) }
  //         else { this.appointments_user_prox.push(appo) }
  //       }
  //       this.appointments_user_prox.reverse();
  //       this.sendToCalendar(this.appointments_user_prox)
  //     })
  //     .catch(error => console.log(error))
  // }

  
  // rut(f: NgForm) {

  //   //console.log(this.beneficiario_selected);


  //   if (this.beneficiario_selected) {
      

      
  //     this.getAppointmentUser(this.beneficiario_selected.use_id);
      

  //   } else { 
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Error al cargar datos del beneficiario.',
  //       timer: 2000
  //     })
  //   }
    
  // }
  

  buscarHoraId() {
    let fecha_sistema = new Date();
    let fecha = formatDate(fecha_sistema, 'yyyy-MM-dd', 'en-US');
    let fecha_consulta = new Date();
    if (!this.id_consulta) {
      this.mostrarError('Formato Incorrecto', 'Debe llenar el formulario');
      return null;
    }
    this.cargando();
    this.consulta = {};
    this.zoomServices.getConsulta(this.id_consulta)
      .then((result:any) => {
        
        // //console.log(result);
        Swal.close();

        if (result.msg == 'sin horas') {
          this.mostrarError('No se puede mostrar la hora', 'No se puede mostrar la hora');
        } else if (result.fecha_consulta) {
          fecha_consulta = new Date(result.fecha_consulta.substr(0, 4), (result.fecha_consulta.substr(5, 2) - 1), result.fecha_consulta.substr(8, 2), result.hora.substr(0, 2), result.hora.substr(3, 2));
          if (fecha_consulta < fecha_sistema) {
            this.mostrarError('No se puede mostrar la hora', 'La hora de esta consulta ya caducó');
          } else {
            result.fecha_consulta = result.fecha_consulta.substr(0, 10).replaceAll('-', '/');
            this.consulta = result;
          }
          
        }
      })
      .catch(error => {
        this.mostrarError('Error al obtener consulta','Error al obtener consulta');
      })
  }
  
  mostrarError(titulo,subtitulo) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: subtitulo,
    })
  }
  

  // [PACIENTE] Boton cancelar hora
  cancelarHora(appo){
    //console.log('Cancelar hora')
    //console.log(this.consulta);
    
    this.data_selected.num_reserva = appo.id_consulta,
    this.data_selected.name_doc = appo.nombre_doctor + ' ' + appo.apellido_paterno_doctor + ' ' + appo.apellido_materno_doctor;
    this.data_selected.name_area = appo.spe_name;
    this.data_selected.name_day = (new Date(appo.fecha_consulta)).toLocaleString('es-US', this.options);
    this.data_selected.name_ben = appo.nombre_paciente + ' ' + appo.apellido_paterno_paciente + ' ' + appo.apellido_materno_paciente;
    this.data_selected.name_hour = appo.hora;

  }

  // [PACIENTE] Boton confirmar cancelar reserva
  // confirmarCancelAppo(boo_id, cancel_reason){
  //   this._schedule.cancelAppointment(boo_id, null, cancel_reason, this.data_selected,null) 
  //     .then(data => {
  //       // //console.log(data)
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Hora cancelada con Éxito',
  //         text: '',
  //         timer: 2000
  //       })
  //       setTimeout(() => window.location.reload(), 2000);
  //     })
  //     .catch(error => console.log(error))
  // }


  

  // [MEDICO] Consulta todas las horas del doctor actual
  getAgendaDoctor(id_doctor){
    this._schedule.getScheduleHours(id_doctor, false, null, null, null, false)
      .then(data => {
        this.hours_doctor = data;
        for (let eve of this.hours_doctor){
          if (eve.sch_hou_sta_name == 'RESERVADA') { 
            eve.estado_hora = eve.sch_hou_sta_name;
          } else {}
          if (eve.sch_sta_name == 'NO ACTIVA' || eve.sch_hou_sta_name == 'NO ACTIVA') { 
            eve.estado_hora = 'BLOQUEADA';
          } else {}
          if (eve.sch_sta_name == 'ACTIVA' && eve.sch_hou_sta_name == 'ACTIVA') { 
            eve.estado_hora = 'DISPONIBLE';
          } else {}
        }
        this.sendToCalendar(this.hours_doctor)
      })
      .catch(error => console.log(error))
  }

  // Carga los eventos en la variable usada por el calendario
  sendToCalendar(eventsX) {
    for (let eve of eventsX){
      let col = colors.yellow;
      if (eve.sch_hou_sta_name == 'RESERVADA') { 
        col = colors.blue; 
        eve.estado_hora = eve.sch_hou_sta_name;
      } else {}
      if (eve.sch_sta_name == 'NO ACTIVA') { col = colors.red; } else {}
      if (eve.sta_name == 'ACTIVA' && eve.sch_hou_sta_name == 'ACTIVA') { 
        col = colors.blue; 
        eve.estado_hora = eve.sch_hou_sta_name;
      }

      this.eventos.push({ //Creacion de los eventos (dias disponibles del doctor)
        start: new Date(eve.sch_date.substr(0, 10).replaceAll('-','/')), //va solo date, podrian ir timestamp
        title: eve.sch_id,
        color: col,
      });
    }
    this.CALENDAR.refresh.next();
  }


  // Recibe desde el componentCalendar al aprentar sobre un dia 
  receiveMessage($event) {
    if ( this.tipo_usuario_name == 'MEDICO') {
      
      let hoursX = [];
      if ( $event.length === 0 ) {
        this.day_available = false; 
        this.day_available_date = '';
      } else {
        this.day_available_date = ($event.date).toLocaleString('es-US', this.options);
        for ( let hour of this.hours_doctor ) {                      
          if ( hour.sch_id == $event.id ) {
            hoursX.push(hour)
          }
        }
        this.day_available = true;  // visualizar horas del dia
      }
      this.hours_available_day = hoursX;
    }
    else {
      // //console.log($event)
    }
  }

  
  


  // [PACIENTE] Boton editar hora
  editarHora(appo){
    //console.log('Editar hora');
    //console.log(appo);
    

    this.calendar_edit_bool = false;
    this.day_available2 = false;  
    this.hours_available_day2 = [];
    this.appointment_user_edit = appo;

    this.data_selected.num_reserva = appo.id_consulta,
    this.data_selected.name_doc = appo.nombre_doctor + ' ' + appo.apellido_paterno_doctor + ' ' + appo.apellido_materno_doctor;
    this.data_selected.name_area = appo.spe_name;
    this.data_selected.name_day = (new Date(appo.fecha_consulta.substr(0, 10).replaceAll('-','/'))).toLocaleString('es-US', this.options) 
    this.data_selected.name_ben = appo.nombre_paciente + ' ' + appo.apellido_paterno_paciente + ' ' + appo.apellido_materno_paciente;
    this.data_selected.name_hour = appo.hora;
    this.data_selected.name_reason = appo.razon;
    this.data_selected.name_symptoms = appo.sintomas;

    
    while(this.eventos2.length > 0) {        //se reinician los eventos del calendar
      this.eventos2.pop();
    }
    
    

    

    this._schedule.getScheduleHours(this.appointment_user_edit.doc_id, true, null, null, null, false)
      .then(resp => {
        // //console.log(resp)2
        
        this.hours_doctor2 = resp;
        this.sendToCalendar2(resp);
        //this.CALENDAR.refresh.next();
        
      })
      .catch(e => console.log(e))
  }
  
  // Carga los eventos en la variable usada por el calendario
  sendToCalendar2(eventsX) {
    this.hora_seleccionada = false;
    this.acepta_edicion = false;
    for (let eve of eventsX){
      //Creacion de los eventos (dias disponibles del doctor)
      this.eventos2.push({
        start: new Date(eve.sch_date.substr(0, 10).replaceAll('-','/')), //va solo date, podrian ir timestamp
        title: eve.sch_id,
      });
    }
    //this.CALENDAR.refresh.next();
    this.calendar_edit_bool = true;
  }

  // Recibe desde el componentCalendar al aprentar sobre un dia 
  receiveMessage2($event) {
    this.hora_seleccionada = false;
    this.acepta_edicion = false;
    let hoursX = [];
    if ( $event.length === 0 ) {
      this.day_available2 = false; 
      this.day_available_date2 = '';
    } else {
      this.day_available_date2 = ($event.date).toLocaleString('es-US', this.options);
      for ( let hour of this.hours_doctor2 ) {                      
        if ( hour.sch_id == $event.id ) {
          hoursX.push(hour)
        }
      }
      this.day_available2 = true;  // visualizar horas del dia
    }
    this.hours_available_day2 = hoursX;
  }

  // [PACIENTE] Boton Reservar hora
  reservarEdit(hora){
    this.hora_old = '';
    this.hora_new = '';
    this.acepta_edicion = false;
    this.hora_seleccionada = true;
    // //console.log(hora)

    this.hora_old = this.data_selected.name_day + ' a ' + this.data_selected.name_hour;
    this.hora_new = this.day_available_date2 + ' a ' + hora.sch_hou_from + ' - ' + hora.sch_hou_to;

    this.nueva_hora = hora;
  }

  // [PACIENTE] Boton confirmar Modificar Hora
  confirmaEdicion() {
    if (!this.modify_by) { 
      this._general.modalError('Error', 'Ingrese quien esta haciendo la modificación de la hora');
      return;
    }
    let data = {
      sch_hou_id: this.nueva_hora.sch_hou_id,
      ben_id: this.appointment_user_edit.tel_doc_app_boo_ben_id,
      sub_spe_id: this.appointment_user_edit.sub_spe_id,
      boo_id: this.appointment_user_edit.id_consulta,
      use_typ_name: this.tipo_usuario_name,
    }

    this.data_selected.name_hour = this.nueva_hora.sch_hou_from + ' '+ this.nueva_hora.sch_hou_to; 
    this.data_selected.modify_by = this.modify_by;

    this.cargando();
    this._schedule.editAppointment (data, this.data_selected)
      .then( resp => {
        this._general.modalExitoso('Éxito','Hora modificada con Éxito');
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(error => {
        if (error.error && error.error.msg) {
          this._general.modalError('Error al modificar su reserva.', error.error.msg);
          setTimeout(() => window.location.reload(), 2000);
        } else {
          this._general.modalError('Error al modificar su reserva.', 'Comuniquese con el contact center.');
          setTimeout(() => window.location.reload(), 2000);
        }
      })

  }
  

  historialConsult(appo) {
    this.data_selected.num_reserva = appo.tel_doc_app_boo_id,
    this.data_selected.name_doc = appo.doc_use_name + ' ' + appo.doc_use_paternal_surname + ' ' + appo.doc_use_maternal_surname;
    this.data_selected.name_area = appo.spe_name + ' - ' + appo.sub_spe_name;
    this.data_selected.name_day = (new Date(appo.sch_date.substr(0, 10).replaceAll('-','/'))).toLocaleString('es-US', this.options) 
    this.data_selected.name_ben = appo.ben_use_name + ' ' + appo.ben_use_paternal_surname + ' ' + appo.ben_use_maternal_surname;
    this.data_selected.name_hour = appo.sch_hou_from + ' '+ appo.sch_hou_to;
    this.data_selected.name_reason = appo.tel_doc_app_boo_reason;
    this.data_selected.name_symptoms = appo.tel_doc_app_boo_symptoms;
    this.data_selected.estado_appo = appo.estado_past;
  }

  cargando() {
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 1000000
    })
  }

    // [PACIENTE] Boton confirmar cancelar reserva
    confirmarCancelAppo() {
    
      if (this.cancel_reason == '' || this.cancel_by == '') {
        this.modalError('Error', 'Ingrese bien los datos...');
        return; 
      }
      this._schedule.cancelAppointment(this.consulta.id_consulta, null, this.cancel_reason, this.data_selected,this.cancel_by) 
        .then(data => {
          // //console.log(data)
          Swal.fire({
            icon: 'success',
            title: 'Hora cancelada con Éxito',
            text: '',
            timer: 2000
          })
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(error => console.log(error))
    }
  
  
    modalError(titulo,detalle) {
      Swal.fire({
        icon: 'warning',
        title: titulo,
        text: detalle,
        timer: 5000
      })
    }
  
}
