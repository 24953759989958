<!-- <h5>Mis documentos</h5>

<h2 style="font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 48px;
  color: #0E0087;">Ver y adjuntar mis archivos</h2> -->
<nav>
  <div class="nav nav-tabs row" id="nav-tab" role="tablist">
    <div class="col-md-10 col-sm-12 p-0 ">
      <a class="nav-item nav-link active" id="nav-exm-tab" data-toggle="tab" href="#nav-exm" role="tab"
        aria-controls="nav-exm" aria-selected="true">Resultados de exámanes</a>
      <a class="nav-item nav-link" id="nav-exa-tab" data-toggle="tab" href="#nav-exa" role="tab" aria-controls="nav-exa"
        aria-selected="false">Imágenes</a>
      <a class="nav-item nav-link" id="nav-rcm-tab" data-toggle="tab" href="#nav-rcm" role="tab" aria-controls="nav-rcm"
        aria-selected="false">Otros documentos</a>
      
    </div>
    <div class="col-md-2 col-sm-12">

      <li class="nav-item">
        <!-- <button type="button" class="updatebtn" (click)="update_doc()">Actualizar</button> -->
        <button type="button" class="subirbtn" data-toggle="modal" data-target="#subirdocumento">Subir documento</button>
        <!-- <button type="button" class="updatebtn" (click)="update_doc()">Actualizar</button> -->
      </li>
    </div>
    <!-- <a class="nav-item nav-link" id="nav-odc-tab" data-toggle="tab" href="#nav-odc" role="tab" aria-controls="nav-odc" aria-selected="false">Orden médica</a> -->
  </div>
  
</nav>
<div class="tab-content" id="nav-tabContent">

  <div class="tab-pane fade show active" id="nav-exm" role="tabpanel" aria-labelledby="nav-exm-tab">
    <!-- tab1 -->
    <br>
    <div class="row">

      <div class="col-sm-6" *ngFor="let item of examenes" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <!-- -->
            <div class="dropdown dots">
              <h4 class="card-title">{{item.att_name}} </h4>
              <button class="test buttonWithoutBorder" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"></button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button class="dropdown-item" data-toggle="modal" user="'item'" data-target="#eliminardocumento"
                  click="sendInfo(item)">Eliminar documento</button>
              </div>
            </div>
            <!-- -->

            <span class="glyphicons glyphicons-option-vertical"></span>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.att_name,null)" class="card-btn d-flex"> <mat-icon
                >file_download</mat-icon> Descargar documento</button>

            <!-- modal eliminar 1-->
            <div class="modal fade" id="eliminardocumento" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <br>
                    <p class="tituloModalEliminar" style="text-align: center;">¿Está seguro que desea eliminar este
                      documento?</p>
                    <br><br>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-6 letrabotoneliminar">
                          <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                            data-dismiss="modal">Volver atrás</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                          <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar"
                            (click)="eliminarArchivo(item)">Eliminar documento</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--modal-->
          </div>
        </div>
      </div>
      <!--item for-->

    </div>
  </div>

  <div class="tab-pane fade" id="nav-exa" role="tabpanel" aria-labelledby="nav-exa-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of informes " ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.att_name}}</h4>
              <button class="test buttonWithoutBorder" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"></button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button class="dropdown-item" data-toggle="modal" user="'item'" data-target="#eliminardocumento2"
                  click="sendInfo(item)">Eliminar documento</button>
              </div>
            </div>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.att_name,null)" class="card-btn d-flex"> <mat-icon>file_download</mat-icon> Descargar documento</button>

            <!-- modal eliminar 1-->
            <div class="modal fade" id="eliminardocumento2" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <br>
                    <p class="tituloModalEliminar" style="text-align: center;">¿Está seguro que desea eliminar este
                      documento?</p>
                    <br><br>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-6 letrabotoneliminar">
                          <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                            data-dismiss="modal">Volver atrás</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                          <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar"
                            (click)="eliminarArchivo(item)">Eliminar documento</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--modal-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="nav-rcm" role="tabpanel" aria-labelledby="nav-rcm-tab"><br>
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of recetas" ngModel ngDefaultControl>
        <div class="card">
          <div class="card-body">
            <div class="dropdown dots">
              <h4 class="card-title">{{item.att_name}}</h4>
              <button class="test buttonWithoutBorder" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button class="dropdown-item" data-toggle="modal" user="'item'" data-target="#eliminardocumento3"
                  click="sendInfo(item)">Eliminar documento</button>
              </div>
            </div>
            <p class="card-text">Adjuntado el: {{item.att_date| date:'dd/MM/yyyy'}}</p>
            <button (click)="_general.descargarFileAtt(item.att_id, item.att_name,null)" class="card-btn d-flex"> <mat-icon>file_download</mat-icon> Descargar documento</button>

            <!-- modal eliminar 1-->
            <div class="modal fade" id="eliminardocumento3" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <br>
                    <p class="tituloModalEliminar" style="text-align: center;">¿Está seguro que desea eliminar este
                      documento?</p>
                    <br><br>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-6 letrabotoneliminar">
                          <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                            data-dismiss="modal">Volver atrás</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                          <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar"
                            (click)="eliminarArchivo(item)">Eliminar documento</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--modal-->
          </div>
        </div>
      </div>

    </div>
  </div>




</div>


<!-- modal -->
<div class="modal fade" id="subirdocumento" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog contenedor-formulario">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        <p class="tituloModal" style="text-align: center;">Subir documento</p>
        <br><br>
        <div class="form-group row content-modal">
          <form name="form" style="text-align: left" #f1="ngForm">
            <select class="form-control" [(ngModel)]="ruta" name="ruta_doc" ngModel>
              <option value="" selected disabled>Tipo de documento</option>
              <option value='12'>Resultados de Éxamenes</option>
              <option value='13'>Imágenes</option>
              <option value='14'>Otros Documentos</option>
            </select>
            <br>
            <br>
            <input type="text" class="form-control" [(ngModel)]="nombre_doc" name="nombre_doc" ngModel
              placeholder="Ingresar título del documento">
            <br>
            <br>
            <p style="font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #171994;">Documento agregado</p>
            <div *ngIf="doc">
              <div *ngIf="doc.size <= 20048576" class="card-existe-doc" style="display: flex; min-height: 40px; align-items: center; margin: 10px 0;">
                <table style="width: 90%;margin: 15px auto 15px 15px;">
                  <tr>
                    <td>
                      <p style="font-family: Mulish;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      display: flex;
                      align-items: center;
                      color: #1E4ECB;">{{doc.name}}</p>
                    </td>
                    <td><mat-icon (click)="delFileSelected()"
                        style="height: 18;color: #2E3A59;float: right;">delete_outline</mat-icon></td>
                  </tr>
                </table>
                <br>
              </div>

              <div *ngIf="doc.size > 20048576">
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-alerta">
                      <table>
                        <tr>
                          <td><mat-icon
                            style="color:#171994;font-size: 30px;margin: 20px 10px;">info</mat-icon> </td>
                            <td>
                            <p class="letras-alerta" style="margin: auto;">El documento supera el máximo peso
                              permitido.</p>
                          </td>

                        </tr>
                      </table>
                    </div>
                  </div>

                </div>

                <br>
              </div>
              <div *ngIf="!doc_permitido">
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-alerta">
                      <table>
                        <tr>
                          <td><mat-icon
                              style="color:#171994;font-size: 30px;margin: 20px 10px;">info</mat-icon> </td>
                          <td>
                            <p class="letras-alerta" style="margin: auto;">Solo puede adjuntar documentos PDF,
                              JPG o PNG.</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                </div>

                <br>
              </div>
            </div>

            <label class="col-md-6 custom-file-upload" style="height: 40px;">Examinar
              <input name="archivo" type="file" style="cursor: pointer !important;" (change)="onFileSelected($event)"
                max-file-size="1024" required />
            </label>
            <label>
              <strong>El documento no puede ser superior a 20 MB (PDF, JPG o PNG)</strong>
            </label>
            <br>
          </form>
        </div>
        
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 letrabotonagregar">
              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver"
                data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
              <button type="buton" class="btn btn-outline-primary btn-lg botonModalAgregar" (click)="guardar()">Subir
                documento</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--CARD-->

<div class="tab-content nav-tabs">

  <div id="menu3" class="tab-pane fade">
    <br>

  </div>
</div>