import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { CusIdService } from '../../services/cus_id.service';
import { AntecedentesMedicosService } from '../../services/antecedentes_medicos.service';
import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';


@Component({
  selector: 'app-antecedentes-paciente',
  templateUrl: './antecedentes-paciente.component.html',
  styleUrls: ['./antecedentes-paciente.component.css']
})
export class AntecedentesPacienteComponent implements OnInit {
  @Input() consulta: any = null; 

  

  public formSubmitted = false;

  public alergiaForm = this.fb.group({
    ben_id: ['', [Validators.required]],
    med_con_id:[null],
    alergia: ['', [Validators.required]],
    fecha_inicio: ['', [Validators.required]],
    severidad: ['', [Validators.required]],
    reaccion_producida: ['', [Validators.required]],
    ben_fil_all_id: ['']
  });

  public cusidForm = this.fb.group({
    use_id: ['']
  });

  public familiaForm = this.fb.group({
    ben_id: ['', [Validators.required]],
    med_con_id:[null],
    patologia: ['', [Validators.required]],
    relacion_familiar: ['', [Validators.required]],
    comentario: [''],
    ben_fil_fam_id: []
  });

  public hospitalizacionForm = this.fb.group({
    ben_id: ['', [Validators.required]],
    med_con_id:[null],
    motivo: ['', [Validators.required]],
    fecha_inicio: ['', [Validators.required]],
    fecha_fin: ['', [Validators.required]],
    comentario: [''],
    ben_file_hos_id: []
  });

  public patologiasForm = this.fb.group({
    ben_id: ['', [Validators.required]],
    med_con_id:[null],
    nombre: ['', [Validators.required]],
    fecha_inicio: ['', [Validators.required]],
    comentario: [''],
    ben_fil_pat_id: []
  });

  public habitosForm = this.fb.group({
    ben_id: ['', [Validators.required]],
    med_con_id:[null],
    cafe: ['', [Validators.required]],
    cafe_frecuencia: ['', [Validators.required]],
    alcohol: ['', [Validators.required]],
    alcohol_frecuencia: ['', [Validators.required]],
    drogas: ['', [Validators.required]],
    drogas_frecuencia: ['', [Validators.required]],
    cigarro: ['', [Validators.required]],
    cigarro_frecuencia: ['', [Validators.required]],
    comentario: ['',]
  });

  public medicamentosForm = this.fb.group({
    ben_id: ['', [Validators.required]],
    med_con_id:[null],
    medicamento: ['', [Validators.required]],
    fecha_inicio: ['', [Validators.required]],
    dosis: ['', [Validators.required]],
    frecuencia: ['', [Validators.required]],
    duracion: ['', [Validators.required]],
    comentario: [''],
    ben_fil_med_id: ['']
  });

  show = false;
  showPatologias = false;
  showHospitalizaciones = false;
  showDiagnosticos = false;
  showMedicamentos = false;
  showCirugias = false;
  showHabitos = false;
  //ALERGIAS
  cus_id:string;
  ben_id:string;
  severidades:any=[];
  relacion:any=[];
  mis_antecedentes:any=[];
  alergia_modifica:any=[];
  alergia_elimina:any=[];

  //ANTECEDENTES FAMILIARES
  mis_antecedentes_familiar:any=[];
  antecedentes_familiar_modifica:any=[];
  antecedentes_familiar_elimina:any=[];
  // PATOLOGIAS
  mis_patologias:any=[];
  patologias_modifica:any=[];
  patologias_elimina:any=[];
  // HOSPITALIZACIONES
  mis_hospitalizaciones:any=[];
  hospitalizaciones_modifica:any=[];
  hospitalizaciones_elimina:any=[];
  //MEDICAMENTOS
  mis_medicamentos:any=[];
  medicamentos_modifica:any=[];
  medicamentos_elimina:any=[];

  //CIRUGIAS
  cirugia_selected: any = {};
  cirugia_nueva: any = {
    nombre_cirugia: '',
    fecha_cirugia: '',
    nombre_medico: '',
    comentario: '',
    med_con_id: null,
    ben_id: null
  };
  //HABITOS
  habitos_list: any = [];
  habito_selected: any = {};
  habito_nueva: any = [{
    id_habito: '',
    id_frecuencia: '',
    comentario: '',
    med_con_id: null,
    ben_id: null
  }];
  frecuency_list: any = [];

  ben:any=1;
  usuario: any = {};
  constructor( private fb: FormBuilder,
    public antecedentesMedicos: AntecedentesMedicosService,
    public generalServices: GeneralService,
) { }

  

  ngOnInit() {

    this.usuario = new EncriptacionService().getUserInfo();
    let use_id = this.usuario._id;
    //console.log(this.consulta, 'consulta-ngOnInit');
    this.resetVariables();
    if (this.consulta && this.consulta.user_id) {
      use_id = this.consulta.user_id;
    }

    //console.log(use_id,'use_id-ngOnInit');
    
    this.cusidForm.controls['use_id'].setValue(use_id);
    this.antecedentesMedicos.traeBen_Id(this.cusidForm.value)
    .subscribe( (data:any) => {
      this.ben_id = data.info;
      //console.log(this.ben_id,'ben_id-ngOnInit');
      
      this.alergiaForm.controls['ben_id'].setValue(this.ben_id);
      this.familiaForm.controls['ben_id'].setValue(this.ben_id);
      this.hospitalizacionForm.controls['ben_id'].setValue(this.ben_id);
      this.patologiasForm.controls['ben_id'].setValue(this.ben_id);  
      this.medicamentosForm.controls['ben_id'].setValue(this.ben_id);
      this.cirugia_nueva.ben_id = this.ben_id;
      this.habito_nueva[0].ben_id = this.ben_id;
      //console.log(data);
      this.traeAntecedentes();
    })

    this.antecedentesMedicos.traeSeveridad()
    .subscribe( (data:any) => {
      this.severidades = data;      
    })
    this.antecedentesMedicos.traeRelacion()
    .subscribe( (data:any) => {
      this.relacion = data;    
    })

  }
  resetVariables() {
    this.cirugia_nueva = {
      nombre_cirugia: '',
      fecha_cirugia: '',
      nombre_medico: '',
      comentario: '',
      med_con_id: null,
      ben_id: null
    };
    this.habito_nueva = [{
      id_habito: '',
      id_frecuencia: '',
      comentario: '',
      med_con_id: null,
      ben_id: null
    }];
    if (this.consulta) {
      this.alergiaForm.controls['med_con_id'].setValue(this.consulta.med_con_id);
      this.familiaForm.controls['med_con_id'].setValue(this.consulta.med_con_id);
      this.hospitalizacionForm.controls['med_con_id'].setValue(this.consulta.med_con_id);
      this.patologiasForm.controls['med_con_id'].setValue(this.consulta.med_con_id);  
      this.medicamentosForm.controls['med_con_id'].setValue(this.consulta.med_con_id);
      this.cirugia_nueva.med_con_id = this.consulta.med_con_id;
      this.habito_nueva[0].med_con_id = this.consulta.med_con_id;
    }
  }

  traeAntecedentes() {
    this.antecedentesMedicos.postListarAntecedentes(this.cusidForm.value)
    .subscribe((data:any) => {
      this.mis_antecedentes = data;  
     //console.log(this.mis_antecedentes);
    })

    this.antecedentesMedicos.postListConHabits(this.ben_id).subscribe((data:any) => {
      this.habitos_list = data.habist;  
    })
    
    this.antecedentesMedicos.postListConFrecuency().subscribe((data:any) => {
      this.frecuency_list = data.frecuency;  
     //console.log(this.frecuency_list);
    })
  }

  campoNoValido( campo:string ): boolean {

    if ( this.alergiaForm.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }
  campoNoValidoFamilia( campo:string ): boolean {

    if ( this.familiaForm.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }

  campoNoValidoHospitalizacion( campo:string ): boolean {

    if ( this.hospitalizacionForm.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }

  campoNoValidoPatologias( campo:string ): boolean {

    if ( this.patologiasForm.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }
  campoNoValidoMedicamentos( campo:string ): boolean {

    if ( this.medicamentosForm.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }

  crearAlergia(){
    this.formSubmitted = true;
    //console.log(this.alergiaForm.value);

    if ( this.alergiaForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.creaAlergia(this.alergiaForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: false,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  infoAuditaAlergia(i){
    this.alergia_modifica= this.mis_antecedentes.allergic[i];
    this.alergiaForm.controls['ben_id'].setValue(this.alergia_modifica.ben_fil_all_ben_id);
    this.alergiaForm.controls['alergia'].setValue(this.alergia_modifica.ben_fil_all_allergic);
    this.alergiaForm.controls['fecha_inicio'].setValue(this.alergia_modifica.ben_fil_all_start_date);
    this.alergiaForm.controls['severidad'].setValue(this.alergia_modifica.ben_fil_all_sev_id);
    this.alergiaForm.controls['reaccion_producida'].setValue(this.alergia_modifica.ben_fil_all_reaction);
    this.alergiaForm.controls['ben_fil_all_id'].setValue(this.alergia_modifica.ben_fil_all_id);
    
  }

  infoAuditaCirugia(item) {
    this.cirugia_selected = item;
  }

  campoNoValidoCirugia(campo) {
    if(campo == 'nombre_cirugia') {
      if (!this.cirugia_nueva.nombre_cirugia || this.cirugia_nueva.nombre_cirugia.length<3) {
        return true;
      } else {
        return false;
      }
    } else if (campo == 'fecha_cirugia') {
      if (!this.cirugia_nueva.fecha_cirugia || this.cirugia_nueva.fecha_cirugia.length<3 || !this.generalServices.verificarFecha(this.cirugia_nueva.fecha_cirugia)) {
        return true;
      } else {
        return false;
      }
    } else if (campo == 'nombre_medico') {
      if (!this.cirugia_nueva.nombre_medico || this.cirugia_nueva.nombre_medico.length<3) {
        return true;
      } else {
        return false;
      }
    } else if (campo == 'comentario') {
      if (!this.cirugia_nueva.comentario || this.cirugia_nueva.comentario.length<3) {
        return true;
      } else {
        return false;
      }
    }
  }

  agregarCirugia() {
    //console.log(this.cirugia_nueva);
    if (this.campoNoValidoCirugia('nombre_cirugia')
      || this.campoNoValidoCirugia('fecha_cirugia')
      || this.campoNoValidoCirugia('nombre_medico')
      || this.campoNoValidoCirugia('comentario')) {
      return;
    }

    let fecha:any = this.generalServices.verificarFecha(this.cirugia_nueva.fecha_cirugia);
    this.cirugia_nueva.fecha_cirugia = fecha.fecha_date_string;
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.postCrearCirugia(this.cirugia_nueva)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: false,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >La cirugía se ha agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
  }

  infoAuditaHabito(item) {
    this.habito_selected = item;
  }
  campoNoValidoHabito(campo, i) {
    // //console.log(campo, i,'campoNoValidoHabito');
    
    if(campo == 'id_habito') {
      if (!this.habito_nueva[i].id_habito) {
        return true;
      } else {
        return false;
      }
    } else if (campo == 'id_frecuencia') {
      if (!this.habito_nueva[i].id_frecuencia) {
        return true;
      } else {
        return false;
      }
    } else if (campo == 'comentario') {
      if (!this.habito_nueva[i].comentario || this.habito_nueva[i].comentario.length<3) {
        return true;
      } else {
        return false;
      }
    }
  }

  agregarHabitoArray() {
    let largo = this.habito_nueva.length - 1;
    if (this.campoNoValidoHabito('nombre_cirugia',largo)
    || this.campoNoValidoHabito('fecha_cirugia',largo)
    || this.campoNoValidoHabito('nombre_medico',largo)
    || this.campoNoValidoHabito('comentario',largo)) {
    return;
    }

    let id_habito = this.habito_nueva[this.habito_nueva.length - 1].id_habito;
    for (let index = 0; index < this.habitos_list.length; index++) {
      const element = this.habitos_list[index];
      if (element.hab_id==id_habito) {
        this.habitos_list[index].disponible=false;
        break;
      }
    }
    
    let element = {
      id_habito: '',
      id_frecuencia: '',
      comentario: '',
      med_con_id: null,
      ben_id: null
    };
    element.ben_id = this.ben_id;
    if (this.consulta) {
      element.med_con_id = this.consulta.med_con_id;
    }
    //console.log(element,'agregarHabitoArray');
    this.habito_nueva.push(element);
  }
  
  agregarHabito() {
    //console.log(this.habito_nueva);
    if (this.campoNoValidoHabito('nombre_cirugia',(this.habito_nueva.length - 1))
    || this.campoNoValidoHabito('fecha_cirugia',(this.habito_nueva.length - 1))
    || this.campoNoValidoHabito('nombre_medico',(this.habito_nueva.length - 1))
    || this.campoNoValidoHabito('comentario',(this.habito_nueva.length - 1))) {
    return;
    }

    //console.log(this.habito_nueva,'habito_nueva-agregarHabito');
    


    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.postCrearHabitos(this.habito_nueva)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: false,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Los hábitos se han agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }
  eliminaAlergia(){
    this.formSubmitted = true;
    //console.log(this.alergiaForm.value);
    
    if ( this.alergiaForm.invalid ) {
      return;
    }
    Swal.fire({ 
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.eliminaAlergia(this.alergiaForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  eliminaCirugia(){
    Swal.fire({ 
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.postEliminarCirugia(this.cirugia_selected.ben_fil_sur_id,this.ben_id)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >La cirugía se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  eliminaHabito(){

    Swal.fire({ 
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.postEliminarHabito(this.habito_selected.use_ben_fil_hab_id,this.ben_id)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El hábito se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  modificaAlergia(){
    //console.log(this.alergiaForm.value);
    
  }

  crearAntecedenteFamiliar(){
    this.formSubmitted = true;
    //console.log(this.familiaForm.value)
    if ( this.familiaForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.creaAntecedenteFamiliar(this.familiaForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }
  infoAuditaFamilia(i){
    //console.log(this.mis_antecedentes.familiar[i]);
    
    this.mis_antecedentes_familiar= this.mis_antecedentes.familiar[i];
    this.familiaForm.controls['ben_id'].setValue(this.mis_antecedentes_familiar.ben_fil_fam_ben_id);
    this.familiaForm.controls['patologia'].setValue(this.mis_antecedentes_familiar.ben_fil_fam_pathology);
    this.familiaForm.controls['relacion_familiar'].setValue(this.mis_antecedentes_familiar.ben_fil_fam_ben_rel_id);
    this.familiaForm.controls['comentario'].setValue(this.mis_antecedentes_familiar.ben_fil_fam_comments);
    this.familiaForm.controls['ben_fil_fam_id'].setValue(this.mis_antecedentes_familiar.ben_fil_fam_id);
    
  }

  eliminaFamilia(){
    this.formSubmitted = true;
    //console.log(this.familiaForm.value);
    
    if ( this.familiaForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.eliminaAntecedenteFamiliar(this.familiaForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  modificaFamilia(){
    //console.log(this.alergiaForm.value);
    
  }

  crearHospitalizacion(){
    this.formSubmitted = true;
    //console.log(this.hospitalizacionForm.value)
    if ( this.hospitalizacionForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.creaHospitalizacion(this.hospitalizacionForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }
  infoAuditaHospitalizacion(i){
   
    this.mis_hospitalizaciones= this.mis_antecedentes.hospitalizations[i];
    this.hospitalizacionForm.controls['ben_id'].setValue(this.mis_hospitalizaciones.ben_file_hos_ben_id);
    this.hospitalizacionForm.controls['motivo'].setValue(this.mis_hospitalizaciones.ben_file_hos_reason);
    this.hospitalizacionForm.controls['fecha_inicio'].setValue(this.mis_hospitalizaciones.ben_file_hos_start_date);
    this.hospitalizacionForm.controls['fecha_fin'].setValue(this.mis_hospitalizaciones.ben_file_hos_end_date);
    this.hospitalizacionForm.controls['comentario'].setValue(this.mis_hospitalizaciones.ben_file_hos_comments);
    this.hospitalizacionForm.controls['ben_file_hos_id'].setValue(this.mis_hospitalizaciones.ben_file_hos_id);
    
  }

  eliminaHospitalizacion(){
    this.formSubmitted = true;
    //console.log(this.hospitalizacionForm.value);
    
    if ( this.hospitalizacionForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.eliminaHospitalizacion(this.hospitalizacionForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  modificaHospitalizacion(){
    //console.log(this.alergiaForm.value);
    
  }

  crearPatologia(){
    this.formSubmitted = true;
    //console.log(this.patologiasForm.value)
    if ( this.patologiasForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.creaPatologia(this.patologiasForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }
  infoAuditaPatologia(i){
   
    this.mis_patologias= this.mis_antecedentes.pathology[i];
    this.patologiasForm.controls['ben_id'].setValue(this.mis_patologias.ben_fil_pat_ben_id);
    this.patologiasForm.controls['nombre'].setValue(this.mis_patologias.ben_fil_pat_name);
    this.patologiasForm.controls['fecha_inicio'].setValue(this.mis_patologias.ben_fil_pat_diagnosis_date);
    this.patologiasForm.controls['comentario'].setValue(this.mis_patologias.ben_fil_pat_ben_comments);
    this.patologiasForm.controls['ben_fil_pat_id'].setValue(this.mis_patologias.ben_fil_pat_id);
    
  }

  eliminaPatologia(){
    this.formSubmitted = true;
    //console.log(this.patologiasForm.value);
    
    if ( this.patologiasForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.eliminaPatologia(this.patologiasForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  modificaPatologia(){
    //console.log(this.alergiaForm.value);
    
  }
  crearMedicamentos(){ //creaMedicamento
    this.formSubmitted = true;
    //console.log(this.medicamentosForm.value);
    if ( this.medicamentosForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Cargando .... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.creaMedicamento(this.medicamentosForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha agregado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }
  infoAudita(i){

  }
  infoAuditaMedicamento(i){
   
    this.mis_medicamentos= this.mis_antecedentes.medicine[i];
    this.medicamentosForm.controls['ben_id'].setValue(this.mis_medicamentos.ben_fil_med_ben_id);
    this.medicamentosForm.controls['medicamento'].setValue(this.mis_medicamentos.ben_fil_med_name);
    this.medicamentosForm.controls['fecha_inicio'].setValue(this.mis_medicamentos.ben_fil_med_start_date);
    this.medicamentosForm.controls['dosis'].setValue(this.mis_medicamentos.ben_fil_med_dosis);
    this.medicamentosForm.controls['frecuencia'].setValue(this.mis_medicamentos.ben_fil_med_frecuency);
    this.medicamentosForm.controls['duracion'].setValue(this.mis_medicamentos.ben_fil_med_duration);
    this.medicamentosForm.controls['comentario'].setValue(this.mis_medicamentos.ben_fil_med_comments);
    this.medicamentosForm.controls['ben_fil_med_id'].setValue(this.mis_medicamentos.ben_fil_med_id);
    
  }


  eliminaMedicamentos(){
    this.formSubmitted = true;
    //console.log(this.medicamentosForm.value);
    
    if ( this.medicamentosForm.invalid ) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">info</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Eliminando ... </h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
      showConfirmButton: false,
      timer: 2500
    })
    this.antecedentesMedicos.eliminaMedicamento(this.medicamentosForm.value)
    .subscribe( (data:any) => {
      Swal.fire({
        allowOutsideClick: true,
        html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >El antecedente se ha eliminado exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
        showConfirmButton: false,
        timer: 2500
      })
      // setTimeout(() => window.location.reload(), 2000);
      this.ngOnInit();
    })
    
  }

  modificaMedicamento(){
    //console.log(this.alergiaForm.value);
    
  }

  update_doc(){
    this.ngOnInit();
  }

}
