import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { SidebarService } from '../../services/sidebar.service';
import { UsuarioService } from '../../services/usuario.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  rutaSalida: String = "";
  @ViewChild('modalFinLlamadaSide') modalFinLlamada: any;
  // @ViewChild('icon-bars-mobile') barsHeader: ElementRef;

  menuItems: any[] ;
  _usuario: any = {}; 
  tipo_usuario_name: string; 
  user_type: number;
  consultas_list: any = []; 
  consultas: any = []; 

  public cambiaPass = this.fb.group({
    contrasena_actual:['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva_2: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
  }, {
    validators: this.passwordsIguales('contrasena_nueva', 'contrasena_nueva_2')
  });

  passLength: number = 0;
  passMinLength: boolean = false;
  passCapital: boolean = false;
  passLetter: boolean = false;
  passNumber: boolean = false;
  equalPass: boolean = false;
  contrasena_nueva_2: string;
  
  sidebar_element: any;

  private readonly Key ='BA30U-NXORgEPxaOsy6zoJOUcJpZlg4xvXTzwu5S17rD8fEoQ8IO2hDTWurplzJ2JcJmyoPQjaqLwF14MDPJp_A';

  public formSubmitted = false;

  constructor(
    public sidebarService: SidebarService, 
    private fb: FormBuilder,
    public _usuarioService: UsuarioService,
    private _fichaMServices: FichaMedicaService,
    private renderer: Renderer2,
    private router: Router,
  ) { 
    this.menuItems = this.sidebarService.menu;
  }

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();
    let user_nuevo = new EncriptacionService().getUserInfo();

    this.sidebar_element = document.getElementById('sidebar-angular');  

  }

  difConsultaMin = 12*30*24*60; //1 año
  consultaProxima: any = {};

  consultasConfirmadas(){
    
    this._fichaMServices.getListarConsultasConfirmadas()
      .then(  (data: any) => {
        let fechaSistema = new Date();
        this.consultas_list = data.consultas; 
        
        for (let cons of this.consultas_list){
          cons.fechaConsulta = new Date(cons.fecha_consulta);
          cons.fechaConsulta.setHours(cons.hora.substr(0, 2));
          cons.fechaConsulta.setMinutes(cons.hora.substr(3, 2));
          let msGap = cons.fechaConsulta.getTime() - fechaSistema.getTime();          
          cons.minGap = Math.floor(msGap / 60000);                            //minutos de diferencia
          if ( cons.minGap < this.difConsultaMin && cons.minGap > 0){
            this.difConsultaMin = cons.minGap
            this.consultaProxima = cons;
          }
        }
      })
      .catch(error => { 
        console.log(error);
      })
  }

  unirseReunionPaciente() {
    if (this.consultas_list.length == 0 || !this.consultaProxima.id_consulta){
      Swal.fire({
        icon: 'info',
        title: 'No tiene proximas consultas',
        timer: 2000
      })
    }
    else {

      let minGap = this.consultaProxima.minGap

      if (minGap > 60 || minGap < 0) {
        //console.log("No Puede");
      } 
      else {
        var url = "salaespera/" +this.consultaProxima.zoo_mee_room_id+ "/" +this.consultaProxima.id_consulta+ "/" +this.consultaProxima.med_con_id;
        this.router.navigate([url]);
      }
    }
  }
  
  // Funcion que redirige a la proxima consulta
  async unirseReunionMedico()  {
    if (this.consultas_list.length == 0 || !this.consultaProxima.id_consulta){
      Swal.fire({
        icon: 'info',
        title: 'No tiene proximas consultas',
        timer: 2000
      })
    }
    else{
      // let consultasNext = await this.consultas_list.sort((a, b) => {
      //   if (a.fecha_consulta > b.fecha_consulta) {
      //     return 1;
      //   } else if (a.fecha_consulta < b.fecha_consulta) {
      //     return -1;
      //   } else {
      //     if (a.hora > b.hora) {
      //       return 1;
      //     } else if (a.hora < b.hora) {
      //       return -1;
      //     } else {
      //       return 0;
      //     }
      //   }
      // });


      let url = "videoconsulta/"+ this.consultaProxima.id_consulta;
      this.router.navigate([url]);
    }
  }

  ruta(ruta){
    //console.log(sessionStorage.getItem('zoom_ud_video')) // //console.log(sessionStorage.getItem('zoom_up'))
    if( sessionStorage.getItem('zoom_ud_video') == 'true' && this._usuario.typ_id == 2){ // if( sessionStorage.getItem('zoom_ud_video') == 'true' ){


      this.renderer.selectRootElement(this.modalFinLlamada.nativeElement).click();
      this.rutaSalida = ruta;
      // //console.log(ruta);
    
    }
    else{
      this.router.navigate([ruta]);
    }

    // if (this.sidebar_element.style.display === "none") {
    //   this.sidebar_element.style.display = "block";
    // } else {
    //   this.sidebar_element.style.display = "none";
    // }


    //  this.renderer.selectRootElement(this.barsHeader.nativeElement).click();
    // document.getElementById("close-menu").click();

    // let barsHeader = document.getElementsByClassName('icon-bars-mobile')[0] as HTMLElement;
    let barsHeader = document.getElementsByClassName('sidebartoggler')[0] as HTMLElement;
    //console.log(barsHeader);
    
    barsHeader.click();

  }

  navegar() {
    this.router.navigate([this.rutaSalida]);
    this.renderer.selectRootElement(this.modalFinLlamada.nativeElement).click();
  }
  checkPass1 = () => {
    const pass1: string = this.cambiaPass.get('contrasena_nueva').value;
    this.passLength = pass1.length;
    if (this.passLength >= 8) {
      this.passMinLength = true;
    } else {
      this.passMinLength = false;
    }
    if (pass1.match(/[A-Z]/g)) {
      this.passCapital = true;
    } else {
      this.passCapital = false;
    }
    if (pass1.match(/[a-z]/g)) {
      this.passLetter = true;
    } else {
      this.passLetter = false;
    }
    if (pass1.match(/[0-9]/g)) {
      this.passNumber = true;
    } else {
      this.passNumber = false;
    }
  }

  campoNoValidoPassword( campo:string ): boolean {

    if ( this.cambiaPass.get(campo) != this.cambiaPass.get('contrasena_nueva') && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }
  cambiaPassword(){
    
    this.formSubmitted = true;
    if (this.cambiaPass.invalid) {
      //console.log('formulario invalido');
      return;
      //no retorno nada si es invalido el formulario
    }else{
      Swal.fire({
        icon: 'info',
        title: 'Espere por favor...',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 3000
      })
      this._usuarioService.modificarPassword(this.cambiaPass.value)
      .subscribe( (data:any) => {Swal.fire({
        allowOutsideClick: false,
        html:
          ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
          ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >La contraseña ha sido modificada exitosamente </h1> <br>' +
          ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
          showConfirmButton: false,
      })
        setTimeout(() => window.location.reload(), 1000);
      }, (err) => {
        // Si sucede un error
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >' + err.error.message + '</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >Intente nuevamente. </p><br>',
          
        }) 
        /*Swal.fire('Error', err.error.message, 'error' );*/
      })
    }
    
  }
  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      this.formSubmitted = false;
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value !== pass2Control.value) {
        return {
          noEsIgual: true
        };
      }
    }
  }

  clickSidebar() {
    //console.log("Se clickea 'x' en sidebar");
    
  }

}
