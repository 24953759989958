import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import Swal from 'sweetalert2';

import { Usuario } from '../../models/usuario.model';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  public formSubmitted = false;

  public muestraEditar = false;
  public muestraEditarPaciente = false;

  show = false;

  anio: string;
  mes: string;
  dia: string;
  fecha_chile: string;

  titular: String;
  rut = '';
  nombre: String;
  apellido_paterno: String;
  apellido_materno: String;
  fecha_nacimiento = '';
  usuario: Usuario;
  telefono: Number;
  email: String;
  genero: String;
  direccion: String;
  numero: string;
  comuna: String;
  region: String;
  especialidad: String;
  subespecialidad: String;
  titulo: String;
  fechaegreso: String;
  universidad: String;
  tipo_usuario_name: string;
  regiones: any = [];
  comunas: any = [];
  nombre_region: string;
  nombre_comuna: string;
  universidades: any = [];
  grados_Academicos: any = [];

  muestra_region: string;
  muestra_comuna: string;

  public registerForm = this.fb.group({
    rut: [{ value: '' }],
    use_id: [{ value: '', disabled: true }],
    nombre: [{ value: '', disabled: true }],
    apellido_paterno: [{ value: '', disabled: true }],
    apellido_materno: [{ value: '', disabled: true }],
    fecha_nacimiento: [{ value: '', disabled: true }],
    telefono: [{ value: '', disabled: true }],
    email: [{ value: '', disabled: true }],
    genero: [{ value: '', disabled: true }],
    direccion: [{ value: '', disabled: true }],
    numero: [{ value: '', disabled: true }],
    comuna: [{ value: '', disabled: true }],
    region: [{ value: '', disabled: true }],
    especialidad: [{ value: '', disabled: true }],
    subespecialidad: [{ value: '', disabled: true }],
    titulo: [{ value: '', disabled: true }],
    fechaegreso: [{ value: '', disabled: true }],
    fechaegresoX: [{ value: '', disabled: true }],
    universidad: [{ value: '', disabled: true }],
    fecha_nacimiento_chile: [{ value: '', disabled: true }]
  });
  

  constructor(private fb: FormBuilder,
    private usuarioService: UsuarioService) { }

  ngOnInit() {

    if (!sessionStorage.getItem('token')) { //sin session
      this.usuarioService.logout()
    } else {
      this.usuario = new EncriptacionService().getUserInfo();
      this.registerForm.controls['use_id'].setValue(this.usuario._id);
      //console.log(this.usuario);
      
      this.rut = this.usuario.rut;
      this.tipo_usuario_name = this.usuario.typ_name;
      this.usuarioService.informacionPerfil(this.rut, this.tipo_usuario_name)
        .subscribe((data: any) => {
          // console.log(data);

          let fecha_nacX = new Date(data.fecha_nacimiento);       //date fecha nacimiento
          let fecha_emiX = new Date(data.fecha_emision_titulo);   //date fecha emision

          let fecha_chileX = `${fecha_nacX.toLocaleString('es-US', { day: 'numeric' })} de ${fecha_nacX.toLocaleString('es-US', { month: 'long' })} de ${fecha_nacX.toLocaleString('es-US', { year: 'numeric' })}`;
          let fecha_egresoX = `${fecha_emiX.toLocaleString('es-US', { day: 'numeric' })} de ${fecha_emiX.toLocaleString('es-US', { month: 'long' })} de ${fecha_emiX.toLocaleString('es-US', { year: 'numeric' })}`;
          // let fecha_chileX = `${this.fecha_nacimiento.split('-')[2]} de ${fecha_nacX.toLocaleString('es-US', { month: 'long' })} de ${this.anio}`

          this.rut = data.rut;
          this.nombre = data.nombre;
          this.apellido_paterno = data.apellido_paterno;
          this.apellido_materno = data.apellido_materno;
          this.fecha_nacimiento = ((data.fecha_nacimiento).split('T'))[0];
          this.anio = this.fecha_nacimiento.split('-')[0];
          this.mes = this.fecha_nacimiento.split('-')[1];
          this.dia = this.fecha_nacimiento.split('-')[2];
          this.fecha_chile = this.dia + "-" + this.mes + "-" + this.anio;

          this.telefono = data.telefono;
          this.email = data.email;
          this.genero = data.genero;
          this.direccion = data.calle;
          this.numero = data.numerocasa;
          this.comuna = data.comuna;
          this.region = data.region;
          this.especialidad = data.nombre_especialidad;
          this.subespecialidad = data.nombre_subespecialidad;
          this.titulo = data.titulo;
          this.fechaegreso = ((data.fecha_emision_titulo).split('T'))[0];
          this.universidad = data.nombre_institucion;
          this.registerForm.controls['rut'].setValue(this.rut);
          this.registerForm.controls['nombre'].setValue(this.nombre);
          this.registerForm.controls['apellido_paterno'].setValue(this.apellido_paterno);
          this.registerForm.controls['apellido_materno'].setValue(this.apellido_materno);
          this.registerForm.controls['fecha_nacimiento'].setValue(fecha_chileX);
          this.registerForm.controls['telefono'].setValue(this.telefono);
          this.registerForm.controls['email'].setValue(this.email);
          this.registerForm.controls['genero'].setValue(this.genero);
          this.registerForm.controls['direccion'].setValue(this.direccion);
          this.registerForm.controls['numero'].setValue(this.numero);
          this.registerForm.controls['comuna'].setValue(this.comuna);
          this.registerForm.controls['region'].setValue(this.region);
          this.registerForm.controls['especialidad'].setValue(this.especialidad);
          this.registerForm.controls['subespecialidad'].setValue(this.subespecialidad);
          this.registerForm.controls['titulo'].setValue(this.titulo);
          this.registerForm.controls['fechaegreso'].setValue(this.fechaegreso);
          this.registerForm.controls['fechaegresoX'].setValue(fecha_egresoX);
          this.registerForm.controls['universidad'].setValue(this.universidad);
          this.registerForm.controls['fecha_nacimiento_chile'].setValue( this.fecha_nacimiento) //.setValue(this.fecha_chile);

        })
      this.usuarioService.InformacionRegion(this.rut)
        .subscribe((data: any) => {
          this.regiones = data;

        })
      if (this.usuario.typ_id == 1) {
        this.usuarioService.InformacionUniversidad(this.rut)
          .then((data: any) => {
            this.universidades = data.consulta;
          }).catch(error => {
            console.log(error);

          });
        this.usuarioService.InformacionGradoAcademico(this.rut)
          .then((data: any) => {
            this.grados_Academicos = data.consulta;
            //console.log(this.grados_Academicos);
          }).catch(error => {
            console.log(error);

          });
      }
    }

  }

  primeraSeccionInputs = [
    { id: 'nombre', label: 'Nombre:', type: 'text', formControlName: 'nombre', placeholder: 'Ingresa tu nombre' },
    { id: 'apellido_paterno', label: 'Primer apellido:', type: 'text', formControlName: 'apellido_paterno', placeholder: 'Ingresa tu apellido paterno' },
    { id: 'apellido_materno', label: 'Segundo apellido:', type: 'text', formControlName: 'apellido_materno', placeholder: 'Ingresa tu apellido materno' },
    { id: 'rut', label: 'RUT:', type: 'text', formControlName: 'rut', placeholder: 'Ingresa tu rut' },
    {id: 'fecha_nacimiento', label: 'Fecha de nacimiento:', type: 'date', formControlName: 'fecha_nacimiento', placeholder: 'Ingresa tu fecha de nacimiento' },
    { id: 'genero', label: 'Genero:', type: 'text', formControlName: 'genero', placeholder: 'Ingresa tu genero' },

  ];
  
  segundaSeccionInputs = [
    { id: 'telefono', label: 'Teléfono:', type: 'text', formControlName: 'telefono', placeholder: 'Ingresa tu teléfono' },
    { id: 'email', label: 'Correo electrónico:', type: 'email', formControlName: 'email', placeholder: 'Ingresa tu correo' },
  ];
  terceraSeccionInputs = [
    {id: 'region', label: 'Región:', type: 'text', formControlName: 'region', placeholder: 'Ingresa tu region' },
    {id: 'comuna', label: 'Comuna:', type: 'text', formControlName: 'comuna', placeholder: 'Ingresa tu comuna' },
    { id: 'direccion', label: 'Dirección:', type: 'text', formControlName: 'direccion', placeholder: 'Ingresa tu dirección' },
    { id: 'numero', label: 'N°:', type: 'text', formControlName: 'numero', placeholder: 'Ingresa tu n°' }
  ];
  


  tipo_comuna() {

    var region_buscar = this.registerForm.get('region').value;
    this.usuarioService.InformacionComuna(region_buscar)
      .subscribe((data: any) => {
        this.comunas = data;
      })
  }

  editarPerfil() {
    this.muestraEditar = true;
  }
  cancelarEditarPerfil() {
    this.muestraEditar = false;
  }
  editarPerfilPaciente() {
    this.muestraEditarPaciente = true;
Object.keys(this.registerForm.controls).forEach(controlName => {
    if (controlName !== 'rut' && controlName !== 'fecha_nacimiento') {
      this.registerForm.get(controlName)?.enable();
    }
  });
  }
  cancelarEditarPerfilPaciente() {
    this.muestraEditarPaciente = false;
    this.registerForm.disable(); // Deshabilita todos los campos

  }

  modificaMedico() {
    //postModificarDoctor
  }

  modificaPacienteAcepta() {
    this.formSubmitted = true;
    //console.log(this.registerForm.value);
    if (this.registerForm.invalid) {
      return;
    }

    if (this.registerForm.value.comuna) {
      Swal.fire({
        allowOutsideClick: false,
        html: ` <div style="margin: 40px 0;">
                  <i class="material-icons" style="color:#2BABB7; font-size: 58px; margin-bottom:25px">schedule</i>
                  <h1 style="font-family: Mulish; font-style: normal; font-weight: 700; font-size: 24px; line-height: 32px; text-align: center; color: #171994;">Cargando</h1>
                </div>`,
        showConfirmButton: false,
        // timer: 5000
      })
      this.usuarioService.modificarUsuario(this.registerForm.value)
        .then((data: any) => {
          Swal.fire({
            allowOutsideClick: false,
            html: ` <div style="margin: 40px 0;">
                      <i class="material-icons" style="color:#171994; font-size: 58px; margin-bottom:25px">mood</i>
                      <h1 style="font-family: Mulish; font-style: normal; font-weight: 800; font-size: 38px; line-height: 48px; text-align: center; color: #171994; margin-bottom:25px;">
                        Tus cambios se han guardado con éxito
                      </h1>
                      <button style="border-radius: 30px; background: #171994; font-family: Mulish; font-style: normal; font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF; padding: 8px 50px">Aceptar</button>
                    </div>`,
            showConfirmButton: false,
            timer: 5000
          })
          setTimeout(() => window.location.reload(), 1000);
        })
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Verifique que haya ingresado la región y comuna.',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000
      })
    }
  }
  modificaMedicoAcepta() {
    this.formSubmitted = true;
    //console.log(this.registerForm.value);
    if (this.registerForm.invalid) {
      return;
    }
    if (this.registerForm.value.comuna) {
      Swal.fire({
        icon: 'info',
        title: 'Modificando Información...',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 4000
      })
      this.usuarioService.modificarMedico(this.registerForm.value)
        .then((data: any) => {
          setTimeout(() => window.location.reload(), 1000);
        })
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Verifique que haya ingresado la región y comuna.',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000
      })
    }
  }

}
