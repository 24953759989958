import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';
import { ZoomService } from 'src/app/services/zoom.service';

import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.css']
})
export class LobbyComponent implements OnInit {

  public id_room: any;
  public parametros_url: any;
  public entro_anfitrion: boolean = false;
  consulta: any = {};

  _usuario: any = {}; 

  public info_usuario = this.fb.group({
    use_id: ['', [Validators.required]],
    con_ana_page: ['', [Validators.required]],
    con_ana_connection_type: ['', [Validators.required]],
    con_ana_connection_mode: ['', [Validators.required]]
  });
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public _usuarioService: UsuarioService,
    private http: HttpClient,
    private router: Router,
    private _zoomServices: ZoomService)
  {
    this.activatedRoute.params.subscribe(parametros => {
      
      this.parametros_url = parametros;
      //console.log(this.parametros_url);
      
    });
    this.id_room = this.parametros_url.idReunion;
    let params = {
      id_consulta: this.parametros_url.id_consulta
    };
    this._zoomServices.postUpdateStartLobby(params)
      .then(result => {
        //console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
    
      this.getConsulta(this.parametros_url.id_consulta);
    
    
   }

  ngOnInit(): void {
    this.observador();

    this._usuario = new EncriptacionService().getUserInfo();

    var ua = navigator.userAgent;

    if(/iPhone|iPad|iPod/i.test(ua))
    {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
       this.info_usuario.controls['use_id'].setValue(this._usuario._id);
       this.info_usuario.controls['con_ana_page'].setValue('Sala de espera');
       this.info_usuario.controls['con_ana_connection_type'].setValue('IOS');
       this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
       //console.log(this.info_usuario.value);
       this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe( (data:any) => {
          //console.log("registro almacenado");
          
        })
       
    }
    else if(/Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
    {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
       this.info_usuario.controls['use_id'].setValue(this._usuario._id);
       this.info_usuario.controls['con_ana_page'].setValue('Sala de espera');
       this.info_usuario.controls['con_ana_connection_type'].setValue('Android');
       this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
       //console.log(this.info_usuario.value);
       this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe( (data:any) => {
          //console.log("registro almacenado");
          
        })
    }
    else
    {
      var connection_mode = (window.navigator['connection'] as any)?.effectiveType;
       this.info_usuario.controls['use_id'].setValue(this._usuario._id);
       this.info_usuario.controls['con_ana_page'].setValue('Sala de espera');
       this.info_usuario.controls['con_ana_connection_type'].setValue('Desktop');
       this.info_usuario.controls['con_ana_connection_mode'].setValue(connection_mode);
       //console.log(this.info_usuario.value);
       this._usuarioService.infoUsuario(this.info_usuario.value)
        .subscribe( (data:any) => {
          //console.log("registro almacenado");
          
        })
    }
  }

  getConsulta(id_consulta) {
    this._zoomServices.getConsulta(id_consulta)
      .then(result => {
        // //console.log(result);
        this.consulta = result;
      })
      .catch(error => {
        console.log(error);
      });
  }


  async observador() {

    let headers = new Headers();
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    }; 
    let url_post = URL_SERVICIOS+'/postMeetingState';
        
    while (!this.entro_anfitrion) {

      this.http.post(url_post, ({ id_room: this.id_room }), httpOptions).subscribe((
        data: any[]) => {
        //console.log(data);
        if (data[0].zoo_mee_active) {
          //console.log("Ya entraron");
          
          //console.log(this.parametros_url);
          this.entro_anfitrion = true;
          var url = "mivideoconsulta/" + this.parametros_url.idReunion + "/" + this.parametros_url.id_consulta+"/"+this.parametros_url.med_con_id;
          
          this.router.navigate([url]);
        
        }
      }); 
      //console.log("No entrarron");
      await this.sleep(10000);
    }
    
   }
   sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  
  ngOnDestroy() {

    this.entro_anfitrion = true;
  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    this.entro_anfitrion = true;
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    this.entro_anfitrion = true;
  }

  clickZoomDescarga(){
    window.open('https://zoom.us/download');
  }
}
