import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import Swal from 'sweetalert2';
import { URL_DOCS } from 'src/app/config/config';
import { PendingSurveysService } from 'src/app/services/pending-surveys.service';


@Component({
  selector: 'app-encuesta-modal',
  templateUrl: './encuesta-modal.component.html',
  styleUrls: ['./encuesta-modal.component.css']
})
export class EncuestaModalComponent implements OnInit {

  @Input() med_con_id: any;
  @Input() consulta: any = {};
  @Input() pendingSurveys: any[] = [];
  @Output() modalClosed = new EventEmitter<void>();

  number_page: number = 0;
  calificaciones = [0, 0, 0, 0, 0, 0];
  comentario: String = "";

  pendingSurvey: any = {}
  showModal: boolean = true;
  URL_DOCUMENTS = URL_DOCS;


  constructor(
    private _sheduleServices: ScheduleService,
    private router: Router,
    private generalServices: GeneralService,
    private pendingSurveysService: PendingSurveysService
  ) { }

  ngOnInit(): void {
    // console.log('Received surveys:', this.pendingSurveys);

  }


  sendSurvey() {

    let params = {
      med_con_id: this.pendingSurveys[0].med_con_id,
      doctor: this.calificaciones[0],
      video: this.calificaciones[1],
      platform: this.calificaciones[2],
      nps: this.calificaciones[3],
      comment: this.comentario
    }

    this._sheduleServices.postInsertSurvey(params)
      .then(async result => {

        // Swal.fire({
        //   html:
        //     ' <i class="far fa-laugh" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;"></i> <br>' +
        //     ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > Su encuesta ha sido enviada exitosamente</h1><br>',
        //   footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Volver al inicio</button> ',
        //   showCancelButton: false,
        //   showConfirmButton: false,
        //   timer: 10000
        // })

        // await this.sleep(2000);

        // Swal.close();
        this.siguiente();
      })
      .catch(error => {
        this.generalServices.modalError('Error al insertar encuesta', 'Tienes que seleccionar entre 1 a 5 estrellas.');
      })

  }

  exitSurvey() {
    this.showModal = false;
  }


  resetModalSurvey() {
    this.modalClosed.emit();
  }


  siguiente() {
    if (this.number_page > -1 && this.number_page < 4 && this.calificaciones[this.number_page] == 0) {
      this.generalServices.modalError('No puedes seguir', (this.number_page == 3 ? 'Tienes que seleccionar una puntuación entre 1 y 10.' : 'Tienes que seleccionar entre 1 y 5 estrellas.'));
      return;
    }
    if(this.number_page == 4 && this.pendingSurveys.length == 1){
      this.number_page = 6;
    }else{
      this.number_page = this.number_page + 1;
    }
  }

  volver() {
    this.number_page = this.number_page - 1;
  }

  calificacion(numero) {

    if (numero < 1) {
      this.generalServices.modalError('No puedes seguir', 'Tienes que seleccionar entre 1 a 5 estrellas.');
      return;
    }
    this.calificaciones[this.number_page] = numero;

  }

  hoverIndex: number = 0;
  onMouseEnter(index: number) {
    this.hoverIndex = index;
  }

  onMouseLeave() {
    this.hoverIndex = 0;
  }

  navegar() {
    this.router.navigate(['dashboard'])
  }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  async closeSurveyModal() {
    if (this.pendingSurveys.length > 0) {
      const telDocAppBooId = this.pendingSurveys[0].tel_doc_app_boo_id;
      try {
        const response = await this.pendingSurveysService.updateSurveySkipped(telDocAppBooId);
        // console.log('Survey marked as skipped:', response);
        this.modalClosed.emit(); // Emit event to parent component to hide the modal
      } catch (error) {
        console.error('Error updating survey skipped:', error);
        this.modalClosed.emit(); // Still emit event to hide the modal in case of error
      }
    } else {
      this.modalClosed.emit(); // Just close the modal if no pending surveys
    }
  }

  obtenerNombreMes(mesNumero: number): string {
    const nombresMeses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return nombresMeses[mesNumero - 1] || 'Mes inválido';
  }
}

