import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { DocumentoComponent } from './documento/documento.component';
import { IngresarDocumentoComponent } from './ingresar-documento/ingresar-documento.component';
import { VerificacionDocumentosComponent } from './verificacion-documentos.component';


const routes: Routes = [
  {
    path: '', 
    component: VerificacionDocumentosComponent,
    children: [
      { path: 'verificaDocumento/:id_documento', component: DocumentoComponent, data: { titulo: 'Verificacion documento' } },
      { path: 'verificaDocumento', component: DocumentoComponent, data: { titulo: 'Ingresar documento' } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerificacionDocumentosRoutingModule { }
