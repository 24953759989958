<div class="row">
  <div class="col-md-12">
    <br>
    <div class="minititulo">
      Mi buscador
    </div>
    <div class="titulo">
      Buscador de información
    </div>
    <br><br>
    <div>
      <form id="buscaInfo" autocomplete="off" [formGroup]="rutABuscar" (ngSubmit)="buscarRut()">
        <label for="">Rut o Nombre usuario</label>
        <ng2-completer name="id_beneficiario" formControlName="rutBuscarPlataforma"
          (selected)="changeBeneficiario($event)" [textNoResults]="'Beneficiario no encontrado.'"
          [datasource]="beneficiarios" [(ngModel)]="searchStrMain" [minSearchLength]="0"
          [placeholder]="'Seleccione el usuario'" [autoMatch]="true" [inputClass]="'form-control'"
          [clearUnselected]="true" [openOnFocus]="true" required>
        </ng2-completer>

        <br>
        <button type="submit" class="btn btn-outline-secondary btn-lg boton">Buscar</button>
      </form>
    </div>
  </div>
</div>
<br>
<div class="row">
  <div class=col-md-12>
    <div *ngIf="!muestraPerfil" class="card">
      <div class="card-body form-material m-t-40 row">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="nombre" class="col-md-12 col-form-label colorletra">Tipo de usuario:</label>
            <ng-container *ngIf="tipos_de_usuario && tipos_de_usuario.length > 0">
              <ng-container *ngFor="let t_usuario of tipos_de_usuario">
                <input type="text" value="{{t_usuario.use_typ_name}} "
                  class="form-control form-control-static datospaciente" disabled>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="form-group col-md-12 m-t-20">
          <hr class="m-t-0 m-b-40 linea-color">
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="nombre" class="col-md-4 col-form-label colorletra">Nombre:</label>
          <input type="text" value="{{nombre}}" class="form-control form-control-static datospaciente" disabled>
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="apellido_paterno" class="col-md-4 col-form-label colorletra">Apellido paterno:</label>
          <input type="text" value="{{apellido_paterno}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="apellido_materno" class="col-md-4 col-form-label colorletra">Apellido materno:</label>
          <input type="text" value="{{apellido_materno}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-12 m-t-20">
          <hr class="m-t-0 m-b-40 linea-color">
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="rut" class="col-md-4 col-form-label colorletra">RUT:</label>
          <input type="text" value="{{rut}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="telefono" class="col-md-4 col-form-label colorletra">Teléfono:</label>
          <input type="text" value="{{telefono}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="genero" class="col-md-4 col-form-label colorletra">Género:</label>
          <input type="text" value="{{genero}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-12 m-t-20">
          <hr class="m-t-0 m-b-40 linea-color">
        </div>
        <div class="form-group col-md-6 m-t-20">
          <label for="nombre" class="col-md-12 col-form-label colorletra">Fecha de nacimiento:</label>
          <input type="text" value="{{fecha_chile}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-6 m-t-20">
          <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Correo electrónico:</label>
          <input type="text" value="{{email}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-12 m-t-20">
          <hr class="m-t-0 m-b-40 linea-color">
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Región:</label>
          <input type="text" value="{{region}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Comuna:</label>
          <input type="text" value="{{comuna}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-4 m-t-20">
          <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Dirección:</label>
          <input type="text" value="{{direccion}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div class="form-group col-md-12 m-t-20">
          <hr class="m-t-0 m-b-40 linea-color">
        </div>
        <div *ngIf="esmedico" class="form-group col-md-4 m-t-20">
          <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Grado / Título:</label>
          <input type="text" value="{{titulo}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div *ngIf="esmedico" class="form-group col-md-4 m-t-20">
          <label for="fecha_nacimiento" class="col-md-5 col-form-label colorletra">Universidad:</label>
          <input type="text" value="{{universidad}}" class="form-control form-control-line datospaciente" disabled>
        </div>
        <div *ngIf="esmedico" class="form-group col-md-4 m-t-20">
          <label for="fecha_nacimiento" class="col-md-12 col-form-label colorletra">Fecha de egreso:</label>
          <input type="text" value="{{fechaegreso}}" class="form-control form-control-line datospaciente" disabled>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-container *ngIf="usuario_buscado.use_id">
  <app-plans [usuario]="usuario_buscado"></app-plans>
</ng-container>

<div *ngIf="!noHayInfo" class="row">
  <div class="col-md-12">
      <div class="card-alerta">
          <table>
              <tr>
                  <td><mat-icon style="color:#171994;font-size: 30px;margin: 10px 10px;">info</mat-icon> </td>
                  <td><p class="letras-alerta" style="margin: 20px 5px 10px;">El rut ingresado no es parte del sistema.</p>
                  </td>
        </tr>
      </table>
    </div>
  </div>
</div>