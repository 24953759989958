import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../services/usuario.service';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';
import { CompleterData, CompleterService } from 'ng2-completer';
import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-busca-info',
  templateUrl: './busca-info.component.html',
  styleUrls: ['./busca-info.component.css']
})
export class BuscaInfoComponent implements OnInit {

  constructor( private fb: FormBuilder,
    public _general: GeneralService,
    private completerService: CompleterService,
    private usuarioService: UsuarioService) { }

    
  rutCuenta:string;
  tipo_usuario_name:string;
  usuario:any;

  rutBuscar:string;

  public muestraPerfil = true;
  public noHayInfo = true;
  public esmedico = false;

  usuario_buscado: any = {};
  rut = '';
  nombre: String;
  apellido_paterno:String;
  apellido_materno:String;
  fecha_nacimiento = '';
  telefono: Number;
  email:String;
  genero: String;
  direccion: String;
  numero:string;
  comuna: String;
  region: String;
  especialidad: String;
  subespecialidad: String;
  titulo: String;
  fechaegreso: String;
  universidad: String;
  nombre_region:string;
  nombre_comuna:string;
  anio:string;
  mes:string;
  dia:string;
  fecha_chile:string;
  tipo_de_usuario:string;
  tipos_de_usuario: any[] = [];
  beneficiarios_data: any = [];
  beneficiarios: CompleterData;
  beneficiario_selected: any;
  searchStrMain: string; 



  largo_data:any = [];

  public rutABuscar = this.fb.group({
    rutBuscarPlataforma: [ '']
  });

  ngOnInit() {
    if (!sessionStorage.getItem('token')){ //sin session
      this.usuarioService.logout()
    }
    
    this.usuario = new EncriptacionService().getUserInfo();
    this.rutCuenta = this.usuario.rut;
    this.tipo_usuario_name = this.usuario.typ_name;
    this._general.postListarusuariosSistema()
    .then(results => { 
      this.beneficiarios_data = results;
      for (let ben of this.beneficiarios_data){
        ben.nombre_completo = ben.use_name + ' ' + ben.use_paternal_surname + ' ' + ben.use_maternal_surname + 
          ':' + ben.use_username;
      }
      this.beneficiarios = this.completerService.local(this.beneficiarios_data, 'nombre_completo', 'nombre_completo')    
    })
    .catch(error => { 
      Swal.fire({
        icon: 'warning',
        title: 'Error al cargar los beneficiarios.',
        timer: 2000
      })

    })

  
    
  }

  changeBeneficiario($event) {

    if ($event){
      this.beneficiario_selected = $event.originalObject;
    }
    // console.log(this.beneficiario_selected);
    
  } 

  buscarRut(){
    this.rutBuscar = this.beneficiario_selected.use_username;
    this.usuario_buscado = {};
    //this.rutBuscar = this.rutABuscar.get('rutBuscarPlataforma').value;
    var buscarSinPuntos = this.rutBuscar.split('.').join('');//saco todos los caracteres        

    // console.log(this.rutCuenta, this.tipo_usuario_name, buscarSinPuntos);

    this.usuarioService.informacionPerfilContact(buscarSinPuntos, this.tipo_usuario_name)
    .subscribe((data: any) => {
    // console.log(data);
    if (data.status == false) {
      this.noHayInfo= false;
      this.muestraPerfil = true;
      this.usuario_buscado = {};
      //console.log("no hay informacion");
    } else {
      // console.log(data);
      this.rut = data.rut;
      this.nombre = data.nombre;
      this.apellido_paterno = data.apellido_paterno;
      this.apellido_materno = data.apellido_materno;
      this.fecha_nacimiento = ((data.fecha_nacimiento).split('T'))[0];
      this.anio = this.fecha_nacimiento.split('-')[0];
      this.mes = this.fecha_nacimiento.split('-')[1];
      this.dia = this.fecha_nacimiento.split('-')[2];
      this.fecha_chile = this.dia+"-"+this.mes+"-"+this.anio;
      this.telefono = data.telefono;
      this.email = data.email;
      this.genero = data.genero;
      this.direccion = data.calle;
      this.numero = data.numerocasa;
      this.comuna = data.comuna;
      this.region = data.region;
      this.especialidad = data.nombre_especialidad;
      this.subespecialidad = data.nombre_subespecialidad;
      this.titulo = data.titulo;
      this.fechaegreso = ((data.fecha_emision_titulo).split('T'))[0];
      this.universidad = data.nombre_institucion;
      if (data.tipo_usuario == 2) {
        this.tipo_de_usuario = 'PACIENTE';
        this.usuario_buscado.use_id = data.idusuario;
      }
      if (data.tipo_usuario == 1) {
        this.tipo_de_usuario = 'MEDICO';
        this.esmedico=true;
      }
      if (data.tipo_usuario == 7) {
        this.tipo_de_usuario = 'CONTACT CENTER';
      }
      if (data.tipo_usuario == 8) {
        this.tipo_de_usuario = 'COORDINADOR MEDICO';
      }
      this.muestraPerfil = false;
      this.tipos_de_usuario = data.user_types;
      this.noHayInfo= true;
    }   

  })
    
  }

}
