<div class="modal fade hide" id="loginLoadingModal" tabindex="-1" role="dialog" aria-labelledby="loginLoadingModal"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content m-0 py-4">
            <div class="modal-body m-0 p-0">
                <div class="container-fluid m-0 p-0 text-center">
                    <img src="assets/images/icon/clock-transicion.svg" alt="Responsive image">
                    <p class="m-0 px-0 px-sm-5">Cargando</p>
                </div>
            </div>
        </div>
    </div>
</div>