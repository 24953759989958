import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { UsuarioService } from 'src/app/services/usuario.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { GeneralService } from 'src/app/services/general.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  @Input() loginPaciente: boolean;
  @Input() loginMobile: boolean;
  @ViewChild('trigLoginLoadingModal') trigLoginLoadingModal: ElementRef;
  @ViewChild('trigLoginErrorModal') trigLoginErrorModal: ElementRef;

  public rut: string;
  public recuerdame: boolean = false;
  public name_typ_user: string;
  public id_typ_user: number;
  public fieldTextType: boolean = false;
  public domainInfo: any = {};

  public token_recaptcha: string;



  constructor(
    private router: Router,
    private _usuarioSerice: UsuarioService,
    public _errors: ErrorsService,
    private renderer: Renderer2,
    private generalService: GeneralService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    
  }

  ngOnInit() {
    let url = this.router.url;
    this.name_typ_user = this.loginPaciente ? 'PACIENTE' : 'MÉDICO';
    if (url == '/login') {
      this.id_typ_user = 2;
    } else if (url == '/login-doctor') {
      this.id_typ_user = 1;
    } else if (url == '/login-contact') {
      this.id_typ_user = 7;
    } else if (url == '/login-coordinador') {
      this.id_typ_user = 8;
    } else if (url == '/login-customer') {
      this.id_typ_user = 11;
    } else if (url == '/login-admin') {
      this.id_typ_user = 12;
    }else if (url == '/login-gestion') {
      this.id_typ_user = 13;
    }else {
      //console.log('URL no soportada');
    }
    
    // sessionStorage.clear();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');

    this.generalService.getInfoDomain()
      .then(result => {
        // //console.log(result,'result-getInfoDomain');
        this.domainInfo = result;
      })
      .catch(error => {
        //console.log(error,'error-getInfoDomain');

      });
  }

  // Boton INGRESAR 
  ingresar = (forma: NgForm) => {
    if (forma.invalid) {
      return;
    }
    this.renderer.selectRootElement(this.trigLoginLoadingModal.nativeElement).click();
    //console.log('Tipo de usuario ingresando: ', this.name_typ_user)
    this.recaptchaV3Service.execute('recaptcha')
      .subscribe((token_recaptcha: string) => {
        // console.log(token_recaptcha);
        this.token_recaptcha = token_recaptcha;


    this._usuarioSerice.login(forma.value.rut, forma.value.password, this.recuerdame, this.id_typ_user, this.token_recaptcha)
      .then((resp: any) => {
        this.renderer.selectRootElement(this.trigLoginLoadingModal.nativeElement).click();
        //console.log('Accede usuario: ', this.rut, ' tipo ', this.name_typ_user);
        //console.log(resp);
        sessionStorage.setItem('zoom_ud_video', 'false');
        sessionStorage.setItem('zoom_up_med', 'false');

        if (!resp.use_onboarding || resp.use_onboarding == false) {
          if (resp.use_typ_id == 1) {
            this.router.navigate(['onboarding/medico']) //navigateByUrl('/onboarding/medico');
          } else if (resp.use_typ_id == 2) {
            this.router.navigate(['onboarding/paciente']) //navigateByUrl('/onboarding/paciente');
          } else {
            this.router.navigate(['dashboard']) //navigateByUrl('/dashboard');
            setTimeout(() => window.location.reload(), 1000);
          }
        } else {
          this.router.navigate(['dashboard']) //navigateByUrl('/dashboard');
          setTimeout(() => window.location.reload(), 1000);
        }
      })
      .catch(error => {
        forma.reset();
        // console.log(error);
        this.renderer.selectRootElement(this.trigLoginErrorModal.nativeElement).click();
      })
      .finally(() => {
        this.renderer.selectRootElement(this.trigLoginLoadingModal.nativeElement).click();
      });
  });

  }

  obtenerTokenRecaptcha() {
    this.recaptchaV3Service.execute('recaptcha')
      .subscribe((token_recaptcha: string) => {
        // console.log(token_recaptcha);
        this.token_recaptcha = token_recaptcha;
      });
  }

   

  toggleFieldTextType = () => {
    this.fieldTextType = !this.fieldTextType;
  }

  changeRut() {
    var rut_Cliente: any = this.rut;
    var valor = rut_Cliente;
    //console.log(this.rut)
    let resultado = valor.split('.').join('');
    let resultado2 = resultado.split('-').join('');
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    if (valor != "") {
      this.rut = cuerpo + "-" + dv;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
    }
    var dvEsperado
    dvEsperado = 11 - (suma % 11);
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;
    if (dvEsperado != dv) {
      //console.log("changeRut invalido");
    }
    else {
      //console.log("changeRut valido");
    }
  }
}