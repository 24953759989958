import { Component, OnInit } from '@angular/core';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.css']
})
export class LoginFooterComponent implements OnInit {
  pageInfo: any = {}; 

  constructor(
    private generalService: GeneralService,
    private encriptacionService: EncriptacionService,
  ) { }

  ngOnInit(): void {
    this.observerPageInfo();
  }

  async observerPageInfo() {
    
    while (true) {
      try {
        this.pageInfo = this.encriptacionService.getPageInfo();  
      } catch (error) {
        
      }
      if (this.pageInfo && this.pageInfo.cus_url_phone) {
        break;
      }
      await this.generalService.sleep(2000);
    }

  }

}
