import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from '../services/general.service';

@Pipe({
  name: 'getImage'
})
export class GetImagePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private generalService: GeneralService
  ) {
  }

  async transform(file) {
    //console.log(file,'file-GetImagePipe');
    
    this.generalService.descargarFileAtt(file.att_id,null,true)
      .then(result => {
        //console.log(result,'result-GetImagePipe');
        let unsafeImageUrl = URL.createObjectURL(result);
        //console.log(unsafeImageUrl,'unsafeImageUrl-GetImagePipe');
        let imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        //console.log(imageUrl,'imageUrl-GetImagePipe');
        return imageUrl;
      })
      .catch(error => {
        return null;
      });
  }

}
