import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorsService } from 'src/app/services/errors.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  rutaSalida: String = "";
  @ViewChild('modalFinLlamada') modalFinLlamada: any;
  

  usuario: any = {}; 
  pageInfo: any = {}; 

  passLength: number = 0;
  passMinLength: boolean = false;
  passCapital: boolean = false;
  passLetter: boolean = false;
  passNumber: boolean = false;
  equalPass: boolean = false;
  contrasena_nueva_2: string;


  // private readonly Key ='BA30U-NXORgEPxaOsy6zoJOUcJpZlg4xvXTzwu5S17rD8fEoQ8IO2hDTWurplzJ2JcJmyoPQjaqLwF14MDPJp_A';

  public formSubmitted = false;

  public cambiaPass = this.fb.group({
    contrasena_actual:['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
    contrasena_nueva_2: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
  }, {
    validators: this.passwordsIguales('contrasena_nueva', 'contrasena_nueva_2')
  });
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public _usuarioService: UsuarioService,
    public _errors: ErrorsService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.usuario = new EncriptacionService().getUserInfo();    
    this.pageInfo = new EncriptacionService().getPageInfo();    
    // //console.log(this.pageInfo,'pageInfo-ngOnInit');
    

  }


  checkPass1 = () => {
    const pass1: string = this.cambiaPass.get('contrasena_nueva').value;
    this.passLength = pass1.length;
    if (this.passLength >= 8) {
      this.passMinLength = true;
    } else {
      this.passMinLength = false;
    }
    if (pass1.match(/[A-Z]/g)) {
      this.passCapital = true;
    } else {
      this.passCapital = false;
    }
    if (pass1.match(/[a-z]/g)) {
      this.passLetter = true;
    } else {
      this.passLetter = false;
    }
    if (pass1.match(/[0-9]/g)) {
      this.passNumber = true;
    } else {
      this.passNumber = false;
    }
  }

  campoNoValidoPassword( campo:string ): boolean {

    if ( this.cambiaPass.get(campo) != this.cambiaPass.get('contrasena_nueva') && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }
  cambiaPassword(){
    
    this.formSubmitted = true;
    if (this.cambiaPass.invalid) {
      //console.log('formulario invalido');
      return;
      //no retorno nada si es invalido el formulario
    }else{
      Swal.fire({
        icon: 'info',
        title: 'Espere por favor...',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 3000
      })
      this._usuarioService.modificarPassword(this.cambiaPass.value)
      .subscribe( (data:any) => {Swal.fire({
        allowOutsideClick: false,
        html:
          ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
          ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >La contraseña ha sido modificada exitosamente </h1> <br>' +
          ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
          showConfirmButton: false,
      })
        setTimeout(() => window.location.reload(), 1000);
      }, (err) => {
        // Si sucede un error
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >' + err.error.message + '</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >Intente nuevamente. </p><br>',
          
        }) 
        /*Swal.fire('Error', err.error.message, 'error' );*/
      })
    }
    
  }
  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      this.formSubmitted = false;
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value !== pass2Control.value) {
        return {
          noEsIgual: true
        };
      }
    }
  }

  reservaHora(){
    var url = "reserva-hora/";
    this.router.navigate([url]);
  }

  dashboard(){
    var url = "dashboard/";
    this.router.navigate([url]);
  }
  reservadeHora(){
    var url = "reservar-hora-contact/";
    this.router.navigate([url]);
  }



  
  ruta(ruta){
    //console.log(sessionStorage.getItem('zoom_ud_video')) // //console.log(sessionStorage.getItem('zoom_up'))
    if( sessionStorage.getItem('zoom_ud_video') == 'true' && this.usuario.typ_id == 2){ // if( sessionStorage.getItem('zoom_ud_video') == 'true' ){


      this.renderer.selectRootElement(this.modalFinLlamada.nativeElement).click();
      this.rutaSalida = ruta;
      // //console.log(ruta);
    
    }
    else{
      this.router.navigate([ruta]);
    }
  }

  navegar() {
    this.router.navigate([this.rutaSalida]);
    this.renderer.selectRootElement(this.modalFinLlamada.nativeElement).click();
  }
}

