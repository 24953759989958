import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-next-appointment-dashboard',
  templateUrl: './next-appointment-dashboard.component.html',
  styleUrls: ['../../../../assets/css/styles.css','./next-appointment-dashboard.component.css']
})
export class NextAppointmentDashboardComponent implements OnInit {

  @Input() data: any = {};

  
  usuario:any;

  data_selected: any = {}           //resumen del consulta
  _usuario: any = {};  // usuario actual
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha

  cancel_reason: string = '';
  date: any;
  video_bool: boolean = false;
  
  constructor(
    private router: Router,
    private _medicosServices: MedicosService,
    private _schedule: ScheduleService,
    private _functions: FunctionsService,
  ) { }

  date_consulta: Date;
  options_bool: boolean = true;

  ngOnInit(): void {
    
    this.date_consulta = this._functions.formatApptDate(new Date(this.data.consulta.fecha_consulta), this.data.consulta.hora).date;

    
    this.usuario = new EncriptacionService().getUserInfo();

    this._usuario = new EncriptacionService().getUserInfo()

    this.date = new Date().toISOString().slice(0, 10);
    
    if (this.date==this.data.consulta.fecha_consulta.substr(0,10)) {
      this.video_bool = true;
    }
    ////console.log(this.video_bool);

    this.observador();
    

  }

  async observador() {
    while( this.options_bool){
      let dateNow = new Date();
      this.options_bool = (this.date_consulta > dateNow ) ? true: false;
      // //console.log( this.options_bool  );
      await this.sleep(60*1000);
    }
  }


  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  goLobby = () => {
    var url = "salaespera/"+this.data.zoo_mee_room_id+"/"+this.data.id_consulta+"/"+this.data.med_con_id;
    this.router.navigate([url]);
  }

  goLobbyAcomp(){
    var url = "salaespera-group/"+this.data.zoo_mee_room_id+"/"+this.data.id_consulta+"/"+this.data.med_con_id;
    this.router.navigate([url]);
  }

  goVideoConsultaMedico = () => {
    var url = "videoconsulta/"+this.data.id_consulta;
    this.router.navigate([url]);
  }

  confirmAppt = () => {
    this._medicosServices.postUpdateSheduleState(this.data.id_consulta)
      .then(result => { 
        setTimeout(() => window.location.reload(), 100);
      })
      .catch(error => { 
        console.log(error);
      })
  }



  confirmarCancelAppo(){ 
    this._schedule.cancelAppointment(this.data.id_consulta, this._usuario._id, this.cancel_reason,null,null) 
      .then(data => {
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >Su consulta ha sido anulada exitosamente</h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" ></p><br>',
            showConfirmButton: false,
        })
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(error => console.log(error))
  }

  detallesConsulta(data){
    // //console.log(data);
    var url = "detalleconsulta/"+data.med_con_id;
    this.router.navigate([url]);
  }
  modificarConsulta(data){
    // //console.log(data);
    var url = "modificarConsulta/"+data.med_con_id;
    this.router.navigate([url]);
  }



}
