<div class="col-md-12">
	<!--  *ngIf="tipo_usuario_name=='PACIENTE'" -->
	<br>
	<div class="minititulo">Mi agenda</div>
	<div class="titulo">Mis próximas consultas</div>
</div>

<div class="container-fluid">
	<div class="row">
		<div class="col-md-12 titulobeneficiario" style="padding: 0 5px;">Modificar consulta</div>
		<div class="col-md-12" style="padding: 0 5px;">
			<app-agenda-medico2 class="" (messageEvent)='clickHourAgendaMedico($event)'>
			</app-agenda-medico2>
		</div>
	</div>
</div>

<div class="container-fluid">
	<div class="row">
		<div class="col-md-6 titulobeneficiario">Consulta actual</div>
		<div *ngIf="hora_nueva_consulta != null" class="col-md-6 titulobeneficiario">Nuevo horario</div>
	</div>
</div>

<div class="container-fluid" style="padding-top: 0;">
	<div class="row">
		<div class="col-12">
			<div class="row d-sm-flex">
				<div class="col-md-6">
					<div class="card cardColor p-0 m-0">
						<div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
							<div class="m-0 p-0 first-line">
								<app-profile-img [img]="datos_consulta.att_path">
								</app-profile-img>
								<div class="ml-3 align-self-center">
									<p class="card-text m-0 p-0 appt-date">Fecha de la consulta: {{ date[0]}} - {{ date[1]}}</p>
									<p class="card-text m-0 p-0">{{datos_consulta.nombre_doctor}} {{datos_consulta.apellido_paterno_doctor}}
										- {{ datos_consulta.spe_name}}</p>
									<p class="card-text m-0 p-0"> </p>
								</div>
							</div>
							<br>
						</div>
					</div>
					<br>
				</div>
				<div *ngIf="hora_nueva_consulta != null" class="col-md-6">
					<div class="card cardBorde p-0 m-0">
						<div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
							<div class="m-0 p-0 first-line">
								<app-profile-img [img]="datos_consulta.att_path">
								</app-profile-img>
								<div class="ml-3 align-self-center">
									<p class="card-text m-0 p-0 appt-date">Fecha de la consulta: {{fecha_nueva_consulta}} - a las
										{{hora_nueva_consulta}}</p>
									<p class="card-text m-0 p-0">{{datos_consulta.nombre_doctor}} {{datos_consulta.apellido_paterno_doctor}}
										- {{ datos_consulta.spe_name}}</p>
									<p class="card-text m-0 p-0"> </p>
								</div>
							</div>
							<br>
						</div>
					</div>
					<br>
				</div>
				<div *ngIf="hora_nueva_consulta != null" class="col-md-12">
					<div class="col-md-9 letrabotonagregar">
					</div>
					<div class="col-md-3 letrabotonagregar botonDerecha">
						<button type="submit" class="btn btn-outline-primary btn-lg botonModalAgregar" data-dismiss="modal"
							data-toggle="modal" data-target="#modificaConsulta">Modificar consulta</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- MODAL DE CONFIRMAR CAMBIO DE HORA -->
<div class="modal fade" id="modificaConsulta" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-body">
				<p class="tituloModificaConsulta align-self-center">Vas a realizar un cambio en tu hora de</p>
				<p class="tituloModificaConsulta align-self-center" style="margin: auto;">atención programada originalmente
					para:</p>
				<br>
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-6 titulobeneficiario">
							Consulta actual
						</div>
						<div *ngIf="hora_nueva_consulta != null" class="col-md-6 titulobeneficiario">
							Nuevo horario
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="row d-sm-flex">
							<div class="col-md-6">
								<div class="card cardColor p-0 m-0">
									<div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
										<div class="m-0 p-0 first-line">
											<app-profile-img [img]="datos_consulta.att_path">
											</app-profile-img>
											<div class="ml-3 align-self-center">
												<p class="card-text m-0 p-0 appt-date">Fecha de la consulta: {{ date[0]}} - {{ date[1]}}</p>
												<p class="card-text m-0 p-0">{{datos_consulta.nombre_doctor}}
													{{datos_consulta.apellido_paterno_doctor}} - {{ datos_consulta.spe_name}}</p>
												<p class="card-text m-0 p-0"> </p>
											</div>
										</div>
										<br>
									</div>
								</div>
								<br>
							</div>
							<div *ngIf="hora_nueva_consulta != null" class="col-md-6">
								<div class="card cardBorde p-0 m-0">
									<div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
										<div class="m-0 p-0 first-line">
											<app-profile-img [img]="datos_consulta.att_path">
											</app-profile-img>
											<div class="ml-3 align-self-center">
												<p class="card-text m-0 p-0 appt-date">Fecha de la consulta: {{fecha_nueva_consulta}} - a las
													{{hora_nueva_consulta}}</p>
												<p class="card-text m-0 p-0">{{datos_consulta.nombre_doctor}}
													{{datos_consulta.apellido_paterno_doctor}} - {{ datos_consulta.spe_name}}</p>
												<p class="card-text m-0 p-0"> </p>
											</div>
										</div>
										<br>
									</div>
								</div>
								<br>
							</div>
						</div>
					</div>
				</div>
				<br>
				<div class="container-fluid">
					<div class="row">
						<p class="tituloModificaConsulta">Por favor, confírmanos que la nueva consulta esté correcta.</p>
					</div>
				</div>
				<br>
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-6" style="text-align: center;">
							<button type="button" class="btn botonVolver colorLetraVolver" data-dismiss="modal">
								<div>Volver</div>
							</button>
						</div>
						<div class="col-md-6 letrabotonagregar">
							<button type="button" (click)="clickConfirmar()"
								class="btn btn-outline-primary botonModalConfirmar">Confirmar consulta</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>