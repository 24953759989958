import { Component, Input, OnInit } from '@angular/core';
import { URL_DOCS } from 'src/app/config/config';

@Component({
  selector: 'app-profile-img',
  templateUrl: './profile-img.component.html',
  styleUrls: ['./profile-img.component.css']
})
export class ProfileImgComponent implements OnInit {
  @Input() img: string = "";
  @Input() doctorName: string = "";
  @Input() doctorLastName: string = "";
  @Input() color: string = "default";
  URL_DOCUMENTS = URL_DOCS;

  constructor() { }

  ngOnInit(): void {
    if (this.doctorName && this.doctorLastName) {      
      this.doctorName = this.doctorName.charAt(0).toUpperCase();
      this.doctorLastName = this.doctorLastName.charAt(0).toUpperCase();
    }
    ////console.log(this.img);
    
  }

}
