import { Component, OnInit } from '@angular/core';
import { URL_DOCS } from 'src/app/config/config';


import { UsuarioService } from 'src/app/services/usuario.service';
import { GeneralService } from 'src/app/services/general.service';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import { ZoomService } from 'src/app/services/zoom.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { CompleterData, CompleterService } from 'ng2-completer';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-busca-historial-consulta',
  templateUrl: './busca-historial-consulta.component.html',
  styleUrls: ['../../../../assets/css/styles.css','./busca-historial-consulta.component.css']
})
export class BuscaHistorialConsultaComponent implements OnInit {

  usuario: any = {};  // usuario actual


  toDay: any = {};      // dia actual
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; //opciones labels fecha
  options_week = { weekday: 'long' }; //opciones labels dia de la semana
  options_month = { month: 'long' }; //opciones labels mes

  appointments: any[] = [];         //proximas consultas
  appointmentsToDay: any[] = [];    //del dia
  appointmentsPast: any[] = [];     //no del dia
  appointment_selected: any = {};

  URL_DOCUMENTS = URL_DOCS;
  ficha_eventos: boolean = false;     //[evento]
  consulta_selected: any = {};         //consulta

  usuario_buscado: any = {};


  // consulta_selected

  steps = [true, false];  //pasos de la pagina

  constructor(
    public _usuarioService: UsuarioService,
    public _general: GeneralService,
    private _fichaMedicaServices: FichaMedicaService,
    private _zoomService: ZoomService,
    private completerService: CompleterService,
    private fb: FormBuilder,
  ) { }

  rutCuenta: string;
  tipo_usuario_name: string;

  rutBuscar: string;

  public muestraPerfil = true;
  public noHayInfo = true;
  public esmedico = false;


  rut = '';
  tipo_de_usuario: string;



  beneficiarios_data: any = [];
  beneficiarios: CompleterData;
  beneficiario_selected: any;
  searchStrMain: string;

  largo_data: any = [];

  public rutABuscar = this.fb.group({
    rutBuscarPlataforma: ['']
  });

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo()
    this.rutCuenta = this.usuario.rut;
    this.tipo_usuario_name = this.usuario.typ_name;
    this._general.postListarusuariosSistema()
      .then(results => {
        this.beneficiarios_data = results;
        for (let ben of this.beneficiarios_data) {
          ben.nombre_completo = ben.use_name + ' ' + ben.use_paternal_surname + ' ' + ben.use_maternal_surname +
            ':' + ben.use_username;
        }
        this.beneficiarios = this.completerService.local(this.beneficiarios_data, 'nombre_completo', 'nombre_completo')
      })
      .catch(error => {
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar los beneficiarios.',
          timer: 2000
        })

      })

    this.toDay.date = new Date();
    this.toDay.dateInt = this.toDay.date.getFullYear() * 10000 + (this.toDay.date.getMonth() + 1) * 100 + this.toDay.date.getDate();
    this.toDay.dateString = (this.toDay.date).toLocaleString('es-US', this.options);
    this.toDay.dayTitle = this.toDay.date.getDate() + ' de ' + (this.toDay.date).toLocaleString('es-US', this.options_month);
  }

  changeBeneficiario($event) {

    if ($event) {
      this.beneficiario_selected = $event.originalObject;
    }
    // console.log(this.beneficiario_selected);

  }


  buscarRut() {
    // console.log(this.beneficiario_selected);
    
    this.rutBuscar = this.beneficiario_selected.use_username;

    this.usuario_buscado = {};
    this.appointments = [];
    //this.rutBuscar = this.rutABuscar.get('rutBuscarPlataforma').value;
    var buscarSinPuntos = this.rutBuscar.split('.').join('');//saco todos los caracteres   

    if(!this._general.validaRut(buscarSinPuntos)){
      this._general.modalError('El usuario buscado no existe en el sistema','Ingresar rut valido')
      return;
    }
    
    this._fichaMedicaServices.getListarConsultasPasadas({ paciente: buscarSinPuntos, doc_id: null, date: null })
      .then((result: any) => {
        if (!(result.consultas.length > 0)) {
          //console.log('Sin proximas consultas');
        } else {
          let consultas = result.consultas;
          // console.log(result);

          for (let appt of consultas) { //(let i = 0; i < consultas.length; i++) {
            // const appt = cos;
            let hour = appt.hora.substr(0, 2);
            let minute = appt.hora.substr(3, 2);
            let apptDate = new Date(appt.fecha_consulta.substr(0, 10).replaceAll('-', '/'));
            apptDate.setHours(hour);
            apptDate.setMinutes(minute);
            let now = new Date();
            let formatedApptDate = this.formatApptDate(apptDate, now);
            if (formatedApptDate.length > 0) {
              let confirmFlag: boolean = false;
              let msToAppt = this.timeToX(apptDate, now);
              let minToAppt = this.msToMin(msToAppt);
              if (appt.tel_doc_app_boo_sta_id === 10 && minToAppt <= 2880) {
                confirmFlag = true;
              }
              let goLobbyFlag: boolean = false;
              if (minToAppt <= 60) {
                goLobbyFlag = true;
              }

              let apptData = {
                date: formatedApptDate,
                doctorName: appt.nombre_doctor.split(' ', 1)[0],
                doctorLastName: appt.apellido_paterno_doctor,
                spec: appt.spe_name,
                subSpec: appt.sub_spe_name,
                img: appt.img ? appt.img : '',
                confirmFlag,
                confirmedFlag: appt.tel_doc_app_boo_sta_id === 30,
                goLobbyFlag,
                id_consulta: appt.id_consulta,
                zoo_mee_room_id: appt.zoo_mee_room_id,
                med_con_id: appt.med_con_id,
                consulta: appt
              };
              this.appointments.push(apptData);

              let apptDateInt = apptDate.getFullYear() * 10000 + (apptDate.getMonth() + 1) * 100 + apptDate.getDate();



              if (this.toDay.dateInt == apptDateInt) {
                this.appointmentsToDay.push(apptData);
              }
              else {
                this.appointmentsPast.push(apptData);
              }
            }
          }
        }
      })
      .catch(e => console.log(e));

  }


  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    // if (minToAppt <= 1) {
    //   returnDate[1] = 'En 1 minuto más';
    // } else if (minToAppt <= 60) {
    //   returnDate[1] = `En ${minToAppt} minutos más`;
    // } else {
    const auxApptDateHour = apptDate.getHours();
    const auxApptDateMin = apptDate.getMinutes();
    let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
    let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
    returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    // }
    // if (this.isToday(apptDate, now)) {
    //   returnDate[0] = 'Hoy ' + returnDate[0];
    // }
    // if (minToAppt < 0) {
    //   return [];
    // }
    return returnDate;
  }

  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

  msToMin = (ms: number) => Math.floor(ms / 60000);

  isToday = (apptDate: Date, now: Date): boolean => {
    if (
      apptDate.getFullYear() === now.getFullYear()
      && apptDate.getMonth() === now.getMonth()
      && apptDate.getDate() === now.getDate()) {
      return true;
    } else {
      return false;
    }
  }



  obtenerConsulta(boo_id) {
    this.steps = [false, true];
    this._zoomService.getConsulta(boo_id)
      .then(resultado => {
        this.consulta_selected = resultado;
        this.ficha_eventos = true;
        //console.log(this.consulta_selected);

      })
      .catch(error => {
        console.log(error);
      });
  }

  // this.consulta_selected = {
  // apellido_materno_doctor: " PUENTES"
  // apellido_materno_paciente: "HERRERA"
  // apellido_paterno_doctor: "FUENTES"
  // apellido_paterno_paciente: "CIFUENTES"
  // doc_id: 9
  // email_doctor: "tomasgodoys@almamedis.cl"
  // fecha_consulta: "2020-12-07T03:00:00.000Z"
  // hora: "16:00:00"
  // id_consulta: 366
  // mail_paciente: "rcifuentes@s3chile.cl"
  // med_con_id: 256
  // nombre_doctor: "MARÍA ANDREA"
  // nombre_paciente: "RICHARD"
  // numero_paciente: "999"
  // razon: ""
  // rut_doctor: "5627137-6"
  // rut_paciente: "16954751-3"
  // sintomas: ""
  // spe_name: "Cirugía Maxilofacial"
  // sub_spe_name: "[Todas]"
  // tel_doc_app_boo_sta_id: 10
  // user_id: 37
  // zoo_acc_email: "rcontreras@s3chile.cl"
  // zoo_mee_room_id: "98124461268"

  // }

  verDetalle(consulta_detalle) {
    this.appointment_selected = consulta_detalle;
    // console.log(consulta_detalle);
    this.obtenerConsulta(consulta_detalle.id_consulta)
  }


  checkStep() {
    this.steps = [true, false]
    this.ficha_eventos = false;
  }





}
