import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }


  async infoTransaction(buy_order){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      buy_order,
    }
    try {
      let data = await this.http.post( URL_SERVICIOS + '/infoTransaction ',params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }
}
