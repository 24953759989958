import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ZoomService } from 'src/app/services/zoom.service';

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.css']
})
export class CarruselComponent implements OnInit {
  @Input() consulta: any;

  @ViewChild('carouselControls') carrusel: ElementRef;
  public mediaList: any[] = [];
  public nameIframe: string = "videoIframe";
  constructor(
    private zooomService: ZoomService,
  ) { }

  ngOnInit(): void {
    this.getMediaList();
  }

  getMediaList() {
    this.zooomService.getMediaList(this.consulta)
      .then((result:any[]) => {
        // console.log(result, 'result-getMediaList');
        if (result.length>0) {          
          this.mediaList = result;
          // console.log(this.carrusel.nativeElement);
        }

      }).catch(error => {
        console.log(error, 'error-getMediaList');

      });
  }

  onLoadIframe(iframe) {
    let nameIframe = this.nameIframe + iframe.toString();
    let elementIframe = document.getElementById(nameIframe);
    if (elementIframe) {    
      try {

        var style = document.createElement('style');
        style.textContent =`body { 
                              background-color: transparent;
                            } `;

        // elementIframe.tagName;
        // console.log(elementIframe); 
            
      } catch (error) {
        console.log(error,'error-onLoadIframe');
        
      }
    }
    
  }

}
