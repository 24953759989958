import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-gestion-ivr',
  templateUrl: './gestion-ivr.component.html',
  styleUrls: ['./gestion-ivr.component.css']
})
export class GestionIVRComponent implements OnInit {
  _title = 'Gestión';
  _subtitle = 'Gestion IVR'


  f_desde: any = '';
  f_hasta: any = '';

  info_input: any = '';
  email: any = '';


  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  constructor(public customerService: CustomerService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'cus_id',
      textField: 'cus_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.postListarCustomer();
  }

  postListarCustomer() {
    this.customerService.postListarCustomer({})
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          // console.log(data, 'data-postListarCustomer');
          this.dropdownList = data;
        }
      })
      .catch(e => console.error(e));
  }

  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  listarDatos(){

  }

  exportarExcel(){

  }
  
  sendEmail(){

  }

}
