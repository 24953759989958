<p class="textTitle">
     Planes y convenios activos:
</p>

<div *ngIf="planes && planes.length==0" class="cardAlerta">
     <table>
          <tr>
               <td>
                    <mat-icon class="infoIcon">info</mat-icon>
               </td>
               <td>
                    <p class="textAlerta">No tienes planes o convenios activos. Para contratar un plan o convenio
                         dirígete a https://almamedis.cl.</p>
               </td>
          </tr>
     </table>
</div>

<div class="row">
     <div *ngFor="let plan of planes" class="cardNgFor col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
          <div class="cardPlan">
               <div class="row" style="margin: 10px 0px;">
                    <div class="col-4 divImgPlan">
                         <img [src]="plan.image_plan" alt="auto" width="65%" height="auto" class="centeredElement">
                    </div>
                    <div class="col-8">
                         <p class="textCardTitle">
                              {{plan.cus_ben_name}}
                         </p>

                         <table>
                              <tr style="margin-bottom: 10px;">
                                   <td>
                                        <p class="textCardSubTitle">Inicio de vigencia: </p>
                                   </td>
                                   <td>
                                        <p *ngIf="plan.cus_dat_from_date" class="textCardSubTitle">
                                             {{plan.cus_dat_from_date}}</p>
                                        <p *ngIf="!plan.cus_dat_from_date" class="textCardSubTitle">-.</p>
                                   </td>
                              </tr>
                              <tr style="margin-bottom: 10px;">
                                   <td>
                                        <p class="textCardSubTitle">Término de vigencia: </p>
                                   </td>
                                   <td>
                                        <p *ngIf="plan.cus_dat_to_date" class="textCardSubTitle">
                                             {{plan.cus_dat_to_date}}</p>
                                        <p *ngIf="!plan.cus_dat_to_date" class="textCardSubTitle">-.</p>
                                   </td>
                              </tr>
                              <tr style="margin-bottom: 10px;">
                                   <td>
                                        <p class="textCardSubTitle">Tipo de usuario: </p>
                                   </td>
                                   <td>
                                        <p *ngIf="plan.ben_main_use_id == usuario.use_id" class="textCardSubTitle">
                                             Titular
                                        </p>
                                        <p *ngIf="plan.ben_main_use_id != usuario.use_id" class="textCardSubTitle">
                                             Beneficiario</p>
                                   </td>
                              </tr>
                         </table>
                         <div class="divButtonPlan">
                              <button class="buttonPlan" (click)="showPlan(plan)" data-toggle="modal"
                                   data-target="#showPlanModal">
                                   <p class="textButton">Ver detalle</p>
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>







<div class="modal fade" id="showPlanModal" aria-labelledby="showPlanModal" aria-hidden="true">
     <div class="modal-dialog modalShowPlan">
          <div class="row">
               <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12" style="align-self: center;">
                    <p class="modalTitle">{{plan_selected.cus_ben_name}}</p>
               </div>
               <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" style="align-self: center;">
                    <div class="modalImg">
                         <img [src]="plan_selected.image_plan" alt="auto" width="90%">
                    </div>
               </div>
          </div>
          <br><br>

          <div class="row cardModalTable">

               <div class=" col-6 divLeftModal">
                    <p class="modalTableSubTitle">Descripción </p>
               </div>
               <div class="col-6 divRightModal">
                    <p *ngIf="!plan_selected.cus_ben_description" class="modalTableSubTitle pDivModalContent">.-
                    </p>
                    <p *ngIf="plan_selected.cus_ben_description" class="modalTableSubTitle pDivModalContent">
                         {{plan_selected.cus_ben_description}}</p>
               </div>

               <div class=" col-6 divLeftModal">
                    <p class="modalTableSubTitle">Tipo plan</p>
               </div>
               <div class="col-6 divRightModal">
                    <p *ngIf="plan_selected.cus_ben_particular" class="modalTableSubTitle pDivModalContent">
                         Particular
                    </p>
                    <p *ngIf="!plan_selected.cus_ben_particular" class="modalTableSubTitle pDivModalContent">
                         General</p>
               </div>

               <div class=" col-6 divLeftModal">
                    <p class="modalTableSubTitle">Inicio de vigencia </p>
               </div>
               <div class="col-6 divRightModal">
                    <p *ngIf="!plan_selected.cus_dat_from_date" class="modalTableSubTitle pDivModalContent">.-
                    </p>
                    <p *ngIf="plan_selected.cus_dat_from_date" class="modalTableSubTitle pDivModalContent">
                         {{plan_selected.cus_dat_from_date}}</p>
               </div>

               <div class=" col-6 divLeftModal">
                    <p class="modalTableSubTitle">Término de vigencia</p>
               </div>
               <div class="col-6 divRightModal">
                    <p *ngIf="!plan_selected.cus_dat_to_date" class="modalTableSubTitle pDivModalContent">.-
                    </p>
                    <p *ngIf="plan_selected.cus_dat_to_date" class="modalTableSubTitle pDivModalContent">
                         {{plan_selected.cus_dat_to_date}}</p>
               </div>

               <div class=" col-6 divLeftModal">
                    <p class="modalTableSubTitle">Tipo de usuario</p>
               </div>
               <div class="col-6 divRightModal">
                    <p *ngIf="plan_selected.ben_main_use_id == usuario.use_id"
                         class="modalTableSubTitle pDivModalContent">
                         Titular
                    </p>
                    <p *ngIf="plan_selected.ben_main_use_id != usuario.use_id"
                         class="modalTableSubTitle pDivModalContent">
                         Beneficiario</p>
               </div>

               <ng-container *ngFor="let plan of plan_selected.especialidades">
                    <ng-container *ngIf="plan.ben_main_use_id == plan.ben_use_id">
                         <div class=" col-6 divLeftModal">
                              <p class="modalTableSubTitle">Cantidad de consultas de {{plan.spe_name}} realizadas</p>
                         </div>
                         <div class="col-6 divRightModal">
                              <p *ngIf="!plan.cus_ben_qty_con_titular" class="modalTableSubTitle pDivModalContent">
                                   {{plan.consultas_usadas}} de ilimitadas
                              </p>
                              <p *ngIf="plan.cus_ben_qty_con_titular" class="modalTableSubTitle pDivModalContent">
                                   {{plan.consultas_usadas}} de {{plan.cus_ben_qty_con_titular}}</p>
                         </div>
                    </ng-container>

                    <div class=" col-6 divLeftModal">
                         <p class="modalTableSubTitle">Cantidad de consultas de {{plan.spe_name}} para beneficiarios</p>
                    </div>
                    <div class="col-6 divRightModal">
                         <p *ngIf="!plan.cus_ben_qty_con_beneficiaries" class="modalTableSubTitle pDivModalContent">
                              Ilimitadas
                         </p>
                         <p *ngIf="plan.cus_ben_qty_con_beneficiaries" class="modalTableSubTitle pDivModalContent">
                              {{plan.cus_ben_qty_con_beneficiaries}}</p>
                    </div>
               </ng-container>


                    <div class=" col-6 divLeftModalBottom">
                         <p class="modalTableSubTitle">Código identificador</p>
                    </div>
                    <div class="col-6 divRightModalBottom">
                         <p  *ngIf="plan_selected.ben_policy_number" class="modalTableSubTitle pDivModalContent">
                              {{plan_selected.ben_policy_number}}
                         </p>
                         <p  *ngIf="!plan_selected.ben_policy_number" class="modalTableSubTitle pDivModalContent">
                              .-
                         </p>
                    </div>
          </div>
          <div class="row" style="justify-content: center;">
               <!-- <div class="buttonFooterModal col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <button class="buttonPlanModal" data-dismiss="modal" (click)="deletePlan(plan_selected)">
                         <p class="textButtonModal">Volver atrás</p>
                    </button>
               </div> -->
               <!-- Implementar para el B2C -->
               <!-- <div *ngIf="plan_selected.ben_main_use_id == usuario.use_id"
                    class="buttonFooterModal col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <button class="buttonPlan" (click)="deletePlan(plan_selected)">
                         <p class="textButton">Eliminar</p>
                    </button>
               </div> -->
          </div>
          <br>
     </div>
</div>