<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h3>Sala de Espera:</h3>
                <p> El medico se encuentra terminando otra consulta y pronto a conectar, en cuanto se conecte usted sera redireccionado a la video consulta</p>
                <br>
                <p> En caso de demora del medico, una de nuestras ejecutivas se contactara con usted a la brevedad para explicarle la situacion</p>
                <br><br>

                <div style="text-align:center;">

                <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/LXb3EKWsInQ?start=2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                <iframe width="560" height="315" src="https://www.youtube.com/embed/SsibQvsjbr0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
                </div>
            </div>
        </div>
    </div>
</div>
