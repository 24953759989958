import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZoomService } from 'src/app/services/zoom.service';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.css']
})
export class EncuestaComponent {
  med_con_id: any;
  id_consulta: any;
  consulta: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private zoomService: ZoomService,
  ) {
    this.activatedRoute.params.subscribe(parametros => {

      this.med_con_id = parametros.med_con_id;
      this.id_consulta = parametros.id_consulta;
      this.getConsulta(this.id_consulta);
    });
  }

  getConsulta(id_consulta) {
    this.zoomService.getConsulta(id_consulta)
      .then(result => {
        // //console.log(result);
        this.consulta = result;
      })
      .catch(error => {
        console.log(error);
      });
  }


}
