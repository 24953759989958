import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';


import { map } from 'rxjs/operators';
import { RegisterForm } from "../interfaces/register-form.interface";
import { ActualizaForm } from "../interfaces/modifica_datos_paciente.interface";
import { ActualizaFormMedico } from "../interfaces/modifica_datos_medico.interface";

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Usuario } from '../models/usuario.model';
import { URL_SERVICIOS } from '../config/config';
import { Observable, throwError } from 'rxjs';
import { EncriptacionService } from './encriptacion.service';
import { GeneralService } from './general.service';


@Injectable({
  providedIn: 'root'
})

export class UsuarioService {



  //====================================================
  // definos las variables a utilizar en el guard mas adelante
  //=================================================================
  usuario: Usuario;
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    public generalServices: GeneralService,
  ) {
    // console.log('servicio de usuario listo');
  }

  estaLogeado() {
    try {
      if (this.token.length > 5) {
        return true;
      } else {
        return false;
      }

    } catch (error) {
      //console.log('Esto es falso');
      return true
      //return false;
    }
  }

  cargaStorage() {
    try {
      this.token = sessionStorage.getItem('token');
      this.usuario = new EncriptacionService().getUserInfo();
      //console.log('El usuario está logeado');
      //console.log(this.usuario.email);

    } catch (error) {
      //console.log('El usuario no está logeado');
      this.token = '';
      this.usuario = null;
    }

  }


  guardarStorage(token: string, usuario: Usuario, recordar: boolean) { // creo de manera global los elemento en el sessionStorage para ocuyparlos mas adelante con el guards
    let encriptador = new EncriptacionService();
    sessionStorage.setItem('token', token);

    let encriptado = encriptador.toBinary(JSON.stringify(usuario));
    sessionStorage.setItem('usuario', encriptado);

    this.usuario = usuario;
    this.token = token;

    recordar ? localStorage.setItem('rut', usuario.rut) : localStorage.removeItem('rut');
  }

  logout() {
    this.usuario = null;
    this.token = '';

    // sessionStorage.clear();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');
    this.router.navigate(['login']);

  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       login    /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async login(rut: string, password: string, recordar: boolean = false, use_typ_id: number, token_recaptcha: string) {

    //console.log('Dentro del metodo login');

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    let authData = {
      user: rut,
      pass: password,
      use_typ_id: use_typ_id,
      token_recaptcha
      // codecs: this.generalServices.getCodecs(),
      // device: this.generalServices.getDevice(),
    };

    try {
      let resp = await this.http.post(URL_SERVICIOS + '/getLoginUsuario', authData, httpOptions).toPromise(); // express 

      ////console.log(resp);

      let usuario = new Usuario(resp['use_name'], resp['use_rut']); // 
      usuario.user = resp['use_username'];
      usuario._id = resp['use_id'];
      usuario.nombre_usuario = resp['use_name'] + ' ' + resp['use_paternal_surname'] + ' ' + resp['use_maternal_surname'];
      usuario.use_name = resp['use_name'];
      usuario.use_paternal_surname = resp['use_paternal_surname'];
      usuario.use_maternal_surname = resp['use_maternal_surname'];
      usuario.email = resp['use_email'];
      usuario.typ_id = resp['use_typ_id'];
      usuario.typ_name = resp['use_typ_name'];
      usuario.use_sta_id = resp['use_sta_id'];
      usuario.doc_id = resp['doc_id'];
      usuario.doc_lme = resp['doc_lme'];

      this.token = resp['token'];

      this.guardarStorage(resp['token'], usuario, recordar);

      return resp;                                              // retorno respuesta
    }
    catch (error) {
      // sessionStorage.clear();
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('usuario');
      //console.log('ERROR EN SERVICIO LOGIN');
      throw error                                               // retorno error
    }

  }



  crearUsuario(usuario: RegisterForm) {

    let url = URL_SERVICIOS + '/postCrearUsuario';
    //console.log("en metodo crea usuario");

    return this.http.post(url, usuario)// aca estoy realizando la creacion en la base de datos
      .pipe(
        map((resp: any) => {
          return resp.usuario;
        })
      );
  }


  informacionPerfil(rut, tipo_usuario_name) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    //console.log("rut: "+rut + tipo_usuario_name );
    let params = {
      rut: rut,
      tipoUsuario: tipo_usuario_name
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postListarPerfil', JSON.stringify(params), httpOptions)
      //console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  informacionPerfilContact(rut, tipo_usuario_name) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    //console.log("rut: "+rut + tipo_usuario_name );
    let params = {
      rut: rut,
      tipoUsuario: tipo_usuario_name
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postListarPerfilContact', JSON.stringify(params), httpOptions)
      //console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  InformacionRegion(rut) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      rut: rut
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/getListarRegion', JSON.stringify(params), httpOptions)
      //console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  InformacionUniversidad(rut) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      rut: rut
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postListInstitution', JSON.stringify(params), httpOptions).toPromise();
      //console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
      throw error;
    }
  }
  InformacionGradoAcademico(rut) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      rut: rut
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postListTitle', JSON.stringify(params), httpOptions).toPromise();
      //console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  InformacionComuna(region_buscar) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      reg_id: region_buscar
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postListarComuna', JSON.stringify(params), httpOptions)
      //console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  async modificarUsuario(usuario: ActualizaForm) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postModificarUsuario', usuario, httpOptions).toPromise()
      // //console.log(data);
      // Swal.fire(
      //   'Informacion Modificada',
      //   'La informacion se modifico exitosamente',
      //   'success'
      // )
      return data
    }
    catch (error) {
      console.log(error);

      Swal.fire({
        icon: 'warning',
        title: 'Error al modificar datos, por favor verifique que los campos no tengan espacios en blanco.',
        timer: 2000
      })
      throw error;

    }
  }

  async modificarMedico(usuario: ActualizaFormMedico) {
    //console.log("en el modifica medico" );
    //console.log(usuario);

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    //console.log("antes del tru");

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postModificarDoctorPerfil', usuario, httpOptions).toPromise()
      //console.log(data);
      Swal.fire(
        'Informacion Modificada',
        'La informacion se modifico exitosamente',
        'success'
      )
      return data
    }
    catch (error) {
      console.log(error);

      Swal.fire({
        icon: 'warning',
        title: 'Error al modificar datos, por favor verifique que los campos no tengan espacios en blanco.',
        timer: 2000
      })
      throw error;

    }
  }

  modificarPassword(pass_nueva) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      old_pass: pass_nueva.contrasena_actual,
      nueva_pass: pass_nueva.contrasena_nueva
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postCrearHash', JSON.stringify(params), httpOptions)
      //console.log("respuesta metodo");

      return data;
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }


  async recoveryPass(params) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postModificarHash', params, httpOptions).toPromise()
      // console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  infoUsuario(info_usuario) {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {
      use_id: info_usuario.use_id,
      con_ana_page: info_usuario.con_ana_page,
      con_ana_connection_type: info_usuario.con_ana_connection_type,
      con_ana_connection_mode: info_usuario.con_ana_connection_mode
    }

    try {
      let data = this.http.post(URL_SERVICIOS + '/postInfoUsuario', JSON.stringify(params), httpOptions)
      //console.log("respuesta metodo");

      return data;
    }
    catch (error) {
      //console.log('error de entrada');
    }
  }

  async loginMiddleware(buy_order) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let params = {
      buy_order: buy_order,
    }

    try {
      let data: any = await this.http.post(URL_SERVICIOS + '/loginMiddleware', JSON.stringify(params), httpOptions).toPromise();
      // console.log(data);
      if (data.page_info && data.token && data.user_data) {
        let usuario_desencriptado = JSON.parse(new EncriptacionService().fromBinary(data.user_data));
        let pagina_desencriptada = JSON.parse(new EncriptacionService().fromBinary(data.page_info));
        // console.log(pagina_desencriptada);
        // console.log(usuario_desencriptado);

        sessionStorage.setItem('page', data.page_info);
        sessionStorage.setItem('token', data.token);

        let usuario = new Usuario(usuario_desencriptado.use_name, usuario_desencriptado.use_rut); // 
        usuario._id = usuario_desencriptado.use_id;
        usuario.doc_id = usuario_desencriptado.doc_id;
        usuario.doc_lme = usuario_desencriptado.doc_lme;
        usuario.email = usuario_desencriptado.use_email;
        usuario.nombre_usuario = usuario_desencriptado.use_name + ' ' + usuario_desencriptado.use_paternal_surname + ' ' + usuario_desencriptado.use_maternal_surname;
        usuario.typ_id = usuario_desencriptado.use_typ_id;
        usuario.typ_name = usuario_desencriptado.use_typ_name;
        usuario.use_maternal_surname = usuario_desencriptado.use_maternal_surname;
        usuario.use_name = usuario_desencriptado.use_name;
        usuario.use_paternal_surname = usuario_desencriptado.use_paternal_surname;
        usuario.use_sta_id = usuario_desencriptado.use_sta_id;
        usuario.user = usuario_desencriptado.use_rut;

        let encriptado = new EncriptacionService().toBinary(JSON.stringify(usuario));
        sessionStorage.setItem('usuario', encriptado);


        var url = "payment-info/" + buy_order;
        this.router.navigate([url]);
        setTimeout(() => window.location.reload(), 1000);
      }

      return data;
    }
    catch (error) {
      //console.log('error de entrada');
      throw error;
    }
  }

  async userTypeList() {

    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};

    try {
      let data = await this.http.post(URL_SERVICIOS + '/userTypeList', JSON.stringify(params), httpOptions).toPromise()
      //console.log(data);
      return data;
    }
    catch (error) {
      //console.log('error de entrada');
      throw error;
    }
  }

  async createUser(params) {
    let url = params.use_id ? URL_SERVICIOS + '/modifyUser' : URL_SERVICIOS + '/createUser';
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
      })
    };
    const fd = new FormData();
    for (var key in params) {
      if (key == 'specialities' || key == 'user_types') {
        fd.append(key, JSON.stringify(params[key]));
      } else {
        if (params[key]) {
          fd.append(key, params[key]);
        }
      }
    }
    try {
      let data = await this.http.post(url, fd, httpOptions).toPromise()
      //console.log(data);
      return data;
    }
    catch (error) {
      //console.log('error de entrada');
      throw error;
    }
  }

  async listUsers({ pageNumber = 1, pageSize = 25, filter = '', }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      pageSize,
      pageNumber,
      filter,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/listUsers', params, httpOptions).toPromise()
      return data;
    }
    catch (error) {
      throw error;
    }
  }

  async logoutUser() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};
    try {
      let data = await this.http.post(URL_SERVICIOS + '/logoutUser', params, httpOptions).toPromise();
      // console.log(data);
      return data;
    }
    catch (error) {
      console.log(error);
    }
  }

  async deleteUser({ use_id = null }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      use_id,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/deleteUser', params, httpOptions).toPromise()
      return data;
    }
    catch (error) {
      throw error;
    }
  }


  async getCodPaises() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getCodPais', JSON.stringify(params)).toPromise();
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPaises() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = {};

    try {
      let data = await this.http.post(URL_SERVICIOS + '/getPaises', JSON.stringify(params)).toPromise();
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegiones(cou_id: number): Promise<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post<any[]>(`${URL_SERVICIOS}/getRegiones/${cou_id}`, {}).toPromise();
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getComunas(reg_id: number) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    let params = { reg_id: reg_id };

    try {
      let data = await this.http.post<any[]>(`${URL_SERVICIOS}/getComunas/${reg_id}`, {}).toPromise();

      return data;
    } catch (error) {
      throw error;
    }
  }


  verificationCode(rut: string, telefono: string, email: string, nombre: string, apellido_paterno: string) {

    let params = { rut, telefono, email, nombre, apellido_paterno };


    try {
      let data = this.http.post(URL_SERVICIOS + '/verificationCode', params).toPromise();
      console.log(data);
      return data
    }
    catch (error) {
      //console.log('error de entrada');
      throw error;
    }
  }

  updateVerificationCode(data: any): Observable<any> {
    return this.http.post(`${URL_SERVICIOS}/updateVerificationCode`, data);
  }

}