import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ZoomService } from 'src/app/services/zoom.service';

@Component({
  selector: 'app-detalle-consul-previas',
  templateUrl: './detalle-consul-previas.component.html',
  styleUrls: ['./detalle-consul-previas.component.css']
})
export class DetalleConsulPreviasComponent implements OnInit {
  @Input() consulta: any = {};
  public signupRutFocus: boolean = false;
  public formSubmitted = false;
  pacienteName:string;
  pacienteLastName:string;
  doctorName:string;
  doctorLastName:string;
  date:any=[];
  boo_id:any;
  use_id:any;
  cancel_reason:any;
  data_selected: any;
  usuario:string;
  typ_id:string;

  datos_paciente:any;

  datos_consulta:any;
  public comentarioCancelacion = this.fb.group({
    comentario:['', [Validators.required]]
  });


  constructor( private router: Router,
    private fb: FormBuilder,
    public _scheduleService: ScheduleService,
    private activatedRoute: ActivatedRoute,
    private _zoomService: ZoomService) {
      //console.log(this.consulta);
      
    this._zoomService.getConsultaMed(this.consulta.med_con_id)
    .then(resultado => {
      this.datos_consulta = resultado;
      this.pacienteName = this.datos_consulta.nombre_paciente.charAt(0).toUpperCase();
      this.pacienteLastName = this.datos_consulta.apellido_paterno_paciente.charAt(0).toUpperCase();
      this.doctorName = this.datos_consulta.nombre_doctor.charAt(0).toUpperCase();
      this.doctorLastName = this.datos_consulta.apellido_paterno_doctor.charAt(0).toUpperCase();
      const hour = this.datos_consulta.hora.substr(0, 2);
      const minute = this.datos_consulta.hora.substr(3, 2);
      let apptDate = new Date(this.datos_consulta.fecha_consulta);
      apptDate.setHours(hour);
      apptDate.setMinutes(minute);
      const now = new Date();
      const formatedApptDate = this.formatApptDate(apptDate, now);
      const msToAppt = this.timeToX(apptDate, now);
      const minToAppt = this.msToMin(msToAppt);
      this.date= formatedApptDate;
      //console.log(this.date);
      
      //console.log(this.datos_consulta);
      
    }) 
   }

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo()
    this.typ_id = new EncriptacionService().getUserInfo().typ_id;
    //console.log(this.usuario);
    
  }

  campoNoValido( campo:string ): boolean {
    if ( this.comentarioCancelacion.get(campo).invalid && this.formSubmitted ) { // si el campo no es valido y se apreto el boton postear me retorna un true y muestra el mensaje de errro
      return true;
    }else{
      return false;
    }
  }

  volverProximasConsultas(){
    var url = "proximasConsultas/";
    this.router.navigate([url]);
  }
  timeToX = (someDate: Date, now: Date) => someDate.getTime() - now.getTime();

    msToMin = (ms: number) => Math.floor(ms / 60000);
  
    isToday = (apptDate: Date, now: Date): boolean => {
      if (
        apptDate.getFullYear() === now.getFullYear()
        && apptDate.getMonth() === now.getMonth()
        && apptDate.getDate() === now.getDate()) {
        return true;
      } else {
        return false;
      }
    }

  formatApptDate = (apptDate: Date, now: Date) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const msToAppt = this.timeToX(apptDate, now);
    const minToAppt = this.msToMin(msToAppt);
    const month = months[apptDate.getMonth()];
    let returnDate: string[] = [`${apptDate.getDate()} de ${month}`, ''];
    if (minToAppt <= 1) {
      returnDate[1] = 'En 1 minuto más';
    } else if (minToAppt <= 60) {
      returnDate[1] = `En ${minToAppt} minutos más`;
    } else {
      const auxApptDateHour = apptDate.getHours();
      const auxApptDateMin = apptDate.getMinutes();
      let auxApptFormHour = `${auxApptDateHour < 10 ? '0' + auxApptDateHour : auxApptDateHour}`;
      let auxApptFormMin = `${auxApptDateMin < 10 ? '0' + auxApptDateMin : auxApptDateMin}`;
      returnDate[1] = `a las ${auxApptFormHour}:${auxApptFormMin} horas`;
    }
    if (this.isToday(apptDate, now)) {
      returnDate[0] = 'Día ' + returnDate[0];
    }
    if (minToAppt < 0) {
      return [];
    }
    return returnDate;
  }

  eliminaConsulta(){
    //console.log(this.comentarioCancelacion.value);
    this.formSubmitted = true;
    if (this.comentarioCancelacion.invalid) {
      //console.log('formulario invalido');
      return;
    }else{
      //console.log("confimar primer paso");
      this.boo_id= this.datos_consulta.id_consulta;
      this.use_id= this.usuario
      this.data_selected={email_doctor: this.datos_consulta.email_doctor};
    }
    
  }
  
  confirmaEliminar(){
    //console.log(this.comentarioCancelacion.value);
    if (this.comentarioCancelacion.invalid) {
      //console.log('formulario invalido');
      return;
    }else{
      this._scheduleService.cancelAppointment(this.boo_id, this.use_id._id ,this.comentarioCancelacion.value.comentario, this.data_selected,null)
      .then((result: any) => {
        //console.log("eliminado Correctamente");
        
      })
      .catch(
        error =>{
          console.log(error);
          
        }
      );

    }
    
  }
  modificarConsulta(data){
    //console.log(data);
    var url = "modificarConsulta/"+data.med_con_id;
    this.router.navigate([url]);
  }

}
