/// ===================================================
/// ACA CREO SOLAMENE LA RUTA DONDE IRAN LAS PETICIONES
/// ===================================================
 
// export const URL_SERVICIOS = 'http://localhost:3031/api'; // desarrollo local
// export const URL_DOCS = 'https://archivos-almamedis.s3.amazonaws.com/desarrollo'; // desarrollo local
// export const URL_SERVICIOS_SOCKET = 'http://localhost:3032/';   // desarrollo local
 
export const URL_SERVICIOS = 'http://44.223.51.6:3031/api';   //ambiente desarrollo (modificar api por url)
export const URL_DOCS = 'https://archivos-almamedis.s3.amazonaws.com/productivo'; //ambiente desarrollo (modificar api por url)
export const URL_SERVICIOS_SOCKET = 'http://44.223.51.6:3032/api'; //ambiente desarrollo (modificar api por url)
 
// export const URL_SERVICIOS = 'https://avanti.almamedis.cl/contact/api';  //produccion
// export const URL_DOCS = 'https://archivos-almamedis.s3.amazonaws.com/productivo'; //produccion
// export const URL_SERVICIOS_SOCKET = 'https://avanti.almamedis.cl/'; //produccion