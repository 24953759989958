import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class TarifasService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
    ) {}


      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////       /postLockPriceProperty
         /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  async postLockPriceProperty(can_udp_price = null, doc_spe_sub_id = null){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      can_udp_price: can_udp_price,
      doc_spe_sub_id: doc_spe_sub_id
    }
    try {
      let data = await this.http.post( URL_SERVICIOS + '/postLockPriceProperty ', JSON.stringify(params), httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //this._errors.requestError(error)
      throw error;
    }
  }

  async postUpdateSubSpecialityPrice(sub_spe_id, price){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      sub_spe_id:  sub_spe_id,
      price: price

    }
    try {
      let data = await this.http.post( URL_SERVICIOS + '/postUpdateSubSpecialityPrice ', JSON.stringify(params), httpOptions ).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }

  async postUpdateSubSpeDoctorPrice(doc_spe_sub_id, price){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
    };
    let params = {
      doc_spe_sub_id: doc_spe_sub_id,
      price: price

    }
    try {
      let data = await this.http.post( URL_SERVICIOS + '/postUpdateSubSpeDoctorPrice ', JSON.stringify(params), httpOptions ).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }
  }


  modalTarifaBloqueada(titulo, descripcion) {
    Swal.close();
    Swal.fire({
      allowOutsideClick: true,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >' + titulo + '</h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >' + descripcion + '</p><br>',
      timer: 30000,
      showCancelButton: true,
      cancelButtonText: "Volver atrás",
      showConfirmButton: false,
      customClass: {
        popup: 'swal2-custom-border',
        cancelButton: 'swal2-custom-cancel-btn'
      },
    })
  }

  modalValorInvalido(titulo, descripcion) {
    Swal.close();
    Swal.fire({
      allowOutsideClick: true,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 24px; line-height: 26px; align-items: center; text-align: center; color: #171994;" >' + titulo + '</h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >' + descripcion + '</p><br>',
      timer: 30000,
      showCancelButton: true,
      cancelButtonText: "Volver atrás",
      showConfirmButton: false,
      customClass: {
        popup: 'swal2-custom-border',
        cancelButton: 'swal2-custom-cancel-btn'
      },
    })
  }


  async createDiscount(params){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    // console.log(params);
    

    try {
      let data = await this.http.post(URL_SERVICIOS + '/createDiscount', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      // this._errors.requestError(error)
      throw error;
    }

  }

  
  async listDiscounts(){
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/listDiscounts', JSON.stringify({}), httpOptions ).toPromise()
      return data
    }
    catch (error) {
      this._errors.requestError(error)
    }
  }


}


