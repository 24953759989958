import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';

import { UsuarioService } from 'src/app/services/usuario.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { EncriptacionService } from 'src/app/services/encriptacion.service';


@Component({
  selector: 'app-listado-consultas',
  templateUrl: './listado-consultas.component.html',
  styleUrls: ['./listado-consultas.component.css']
})
export class ListadoConsultasComponent implements OnInit {

  constructor(
    public router: Router,
    public _usuarioService: UsuarioService,
    public _schedule: ScheduleService,
    public _general: GeneralService,
  ) { }

  status_list: any = [];  
  consultation_list: any = [];     
  doctor_list: any = [];                                 // listado de doctores
  searchText: string = ''; 
  usuario: any = {};
  status_id: string ='';
  doctor_id: string ='';
  dateini = new Date(); //'02/10/2021';
  datefin = new Date();

  estado: string = '';
  doc_id: string = '';
  fecha_inicio= new Date();
  fecha_termino= new Date();

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    
    if ( this.usuario.typ_id == 7 || this.usuario.typ_id == 8 ){
      this.getAppBooStatus();
      this.getDoctorList();
      this.fecha_inicio = this.dateini;
      this.fecha_termino = this.datefin;
      //console.log(this.datefin);

    }
    else {
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
  }



  // Trae las consultas
  postListadoConsultas(estado, doc_id, fecha_inicio, fecha_termino){
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();

    
    this._general.postListarConsultasPorPagar(estado, doc_id, fecha_inicio, fecha_termino) 
      .then( (data: any) => {
        if ( data.length == 0 ) {
          Swal.fire({
            icon: 'info',
            text: 'Sin consultas disponibles',
            timer: 2000
          })
        }
        else {
          this.consultation_list = data;
          //console.log(data);
          
          Swal.close();          
        }
    })
  }

  onListar(estado, doc_id, fecha_inicio, fecha_termino){
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    //console.log('----------------------');

    //console.log(estado);
    //console.log(doc_id);
    //console.log('----------------------');
    this._general.postListarConsultasPorPagar(estado, doc_id, fecha_inicio, fecha_termino) 
      .then((data: any) => {
        //console.log(data, 'data-postListarConsultasPorPagar');
        let arrayConsultas:any[] = [];
        for (let index = 0; index < data.length; index++) {
          let element = data[index];
          element.fecha = element.fecha.substring(0, 10);
          arrayConsultas.push(element);
        }
        if ( data.length == 0 ) {
          Swal.fire({
            icon: 'info',
            text: 'Sin consultas disponibles',
            timer: 2000
          })
        }
        else {
          this.consultation_list = arrayConsultas;  
          Swal.close();        
        }
    })
  }

  onExportar(){
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();

        if ( this.consultation_list.length == 0 ) {
          Swal.fire({
            icon: 'info',
            text: 'Sin datos disponibles',
            timer: 2000
          })
        }
        else {
          this.consultation_list = this.consultation_list;
          Swal.close();  
          this.downloadFile(this.consultation_list);        
        }
  }
  getAppBooStatus(){
    this._general.getAppBooStatus()
      .then( (data: any) => {
        if ( data.length == 0 ) {
          Swal.fire({
            icon: 'info',
            text: 'Sin estados disponibles',
            timer: 2000
          })
        }
        else {
          this.status_list = data;  
          this.status_id = "";       
        }
    })
  }

  getDoctorList(){
    this._general.getDoctorList(null,null,null,null)
      .then( (data: any) => {
        if ( data.length == 0 ) {
          Swal.fire({
            icon: 'info',
            text: 'Sin doctores disponibles',
            timer: 2000
          })
        }
        else {
          this.doctor_list = data;
          this.doctor_id = "";
        }
    })
  }
  downloadFile(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // majear valore nulos
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "Listado_consultas.csv");
}

}
