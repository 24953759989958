import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FichaMedicaService } from 'src/app/services/ficha-medica.service';
import Swal from 'sweetalert2';
import { CompleterService, CompleterData } from 'ng2-completer';
import { Usuario } from 'src/app/models/usuario.model';
import { DocumentsService } from 'src/app/services/documents.service';
import { URL_DOCS } from 'src/app/config/config';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { LicenciaMedicaService } from 'src/app/services/licencia-medica.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-ficha-medica',
  templateUrl: './ficha-medica.component.html',
  styleUrls: ['./ficha-medica.component.css']
})
export class FichaMedicaComponent implements OnInit {
  @Input() eventos: any = {};
  @Input() consulta: any = {};
  @Output() messageEvent = new EventEmitter<string>();

  @ViewChild('descargar') content: ElementRef;
  @ViewChild('cerrarSubirDoc') cerrarSubirDoc: ElementRef;

  @ViewChild('eliminardocumento') eliminardocumento: any;

  URL_DOCUMENTS = URL_DOCS;

  fecha_sistema_aux = new Date();
  fecha_sistema = formatDate(this.fecha_sistema_aux, 'dd-MM-yyyy', 'en-US');
  titulo: String = "Seleccione Opción en Barra Lateral";
  subtitulo: String = "Seleccione Opción en Barra Lateral";
  comentario: String;
  comentario2: String;
  accion: any = 0;
  
  examenes: any;
  examen_elegido: any = {};
  id_exam: any;

  recetas: any;
  id_receta: any;


  diagnosticos: CompleterData;
  diagnosticos_data: any = [];

  id_diagnostico: any;
  diagnostico_selected: any = {};
  searchStrDia: string;


  causales: any;
  id_causal: any;

  clinicas: CompleterData;
  clinicas_data: any = [];
  
  id_clinica: any;
  clinica_selected: any = {
    hos_id:null
  };
  searchStr: string;
  
  nuevo: boolean = false;
  modificar: boolean = false;
  sub_historial: boolean = false;
  descargar: boolean = false;
  aux: any = {};
  listado: boolean = false;
  usuario: Usuario;
  doc = null;
  tipo_doc: any;
  doc_permitido: boolean = false;

  ficha: any = {
    anamnesis: [],
    certificados: [],
    derivation:[],
    diagnostics: [],
    orders:[],
    prescriptions: [],
    medical_license: []
  };

  place_holder: string = "";
  place_holder2: string = "";
  id_requerimiento: any;
  titulo_doc: string = "Resumen de atención";

  ruta: any;
  archivos_consulta: any = [];
  archivos_paciente: any = [];

  doc_a_eliminar;
  nombre_doc: string = "";

  data_eliminar: any = {};
  check_firma: boolean = false;

  tab_archivos: any = [true, false];
  
  diagnostico_seleccionado_licencia: any = '';

  pdf_url: any = this.URL_DOCUMENTS+"/uploads/noimage.png";

  tipos_licencia_medica: any = [];
  tipo_seleccionado_licencia: any = "";

  licencia: any = {
    fecha_inicio_reposo: '',
    diagnostico_seleccionado: '',
    dias_reposo: null,
    tipo_seleccionado: '',
    rut_hijo: '',
    fecha_nacimiento_hijo: '',
  }

  constructor(
    public _generalServices: GeneralService,
    private _fichaMedicaServices: FichaMedicaService,
    private _licenciaMedicaServices: LicenciaMedicaService,    
    private _completerService: CompleterService,
    private _documentServices: DocumentsService,
    private router: Router,
    private renderer: Renderer2,
  ) {
    this.usuario = new EncriptacionService().getUserInfo();
    //console.log(this.usuario,'usuario-fichaMedica');
    
    this.postListMedicalLeaveType();
    this.getListarExamType();
    this.getListarPrescriptionType();
    this.getListarConDiagnostic();
    this.getListarClinicas();
    this.getListarConCausal();
    this.Reset();
  }

  ngOnInit(): void {
    //console.log(this.consulta);
    
    this.Reset2();
    this.listar_documentos();
  }

  ngOnChanges() {
    if (this.consulta && this.consulta.id_consulta) {
      //console.log("Existe consulta");
      this.listar_documentos();
    }
  }

  Reset() {
    if (this.usuario.typ_name == 'MEDICO') {
      this.accion = 5;
      this.titulo = "Historia Clínica";
      this.subtitulo = "Subir Historia Clínica";
      this.place_holder = "Ingresar anamnesis";
    } else {      
      this.accion = 1;
      this.place_holder = "Ingresar diagnósticos";
      this.place_holder2 = "Ingresar indicaciones";
    }

    this.sub_historial = true;
    this.nuevo = false;
    this.modificar = false;
    this.sub_historial = false;
    this.listar_documentos();
  }

  async Reset2() {
    this.sub_historial = true;
    this.nuevo = false;
    this.modificar = false;
    this.descargar = false;
    //this.listar_documentos();
  }

  Diagnostico() {
    this.titulo = "Diágnostico e Indicaciones";
    this.subtitulo = "Subir Diágnostico";
    this.comentario = "";
    this.accion = 1;
    this.place_holder = "Ingresar diagnósticos";
    this.place_holder2 = "Ingresar indicaciones";
    this.titulo_doc = "Resumen de atención";
    this.Reset2();
  }

  Orden() {
    this.titulo = "Orden Médica";
    this.subtitulo = "Subir Orden Médica";
    this.comentario = "";
    this.accion = 2;
    this.place_holder = "Ingresar orden de exámen";
    this.titulo_doc = "Orden de examen";
    this.Reset2();
  }

  Receta() {
    this.titulo = "Receta Médica";
    this.subtitulo = "Receta Médica";
    this.comentario = "";
    this.accion = 3;
    this.place_holder = "Ingresar receta";
    this.titulo_doc = "Receta";
    this.Reset2();

  }

  Certificado() {
    this.titulo = "Certificado Médico";
    this.subtitulo = "Subir Certificado Médico";
    this.comentario = "";
    this.accion = 4;
    this.place_holder = "Ingresar certificado médico";
    this.titulo_doc = "Certificado";
    this.Reset2();
  }


  Carta() {
    this.titulo = "Historia Clínica";
    this.subtitulo = "Subir Historia Clínica";
    this.place_holder = "Ingresar anamnesis";
    this.comentario = "";
    this.accion = 5;
    this.Reset2();
    this.descargar = false;
  }


  Adjuntar() {
    this.titulo = "Adjuntar Documento";
    this.subtitulo = "Adjuntar Documento";
    this.comentario = "";
    this.accion = 6;
    this.descargar = false;
    this.Reset2();

  }

  Derivar() {
    this.titulo = "Derivar Paciente";
    this.subtitulo = "Derivar Paciente";
    this.comentario = "";
    this.accion = 7;
    this.place_holder = "Ingresar datos médico";
    this.Reset2();
  }

  verBoletas(){
    this.titulo = "Ver Boletas";
    this.accion = 9;
    this.Reset2();
  }

  Licencia() {
    this.titulo = "Licencia";
    this.subtitulo = "Licencia";
    this.comentario = "";
    this.accion = 8;
    this.place_holder = "Ingresar datos médico";
    this.Reset2();

  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////         CreateOrder          //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  crearOrden(f: NgForm) {
    var comentario = f.value.comentario;
    var diagnostico = f.value.id_diagnostico;
    var examen = f.value.id_exam;
    var receta = f.value.id_receta;

    if (comentario.length > 3) {
      
      if (this.accion == 1) {//Diagnostico

        
        var params;
        // let pdf = this.generarPdfTexto();
        if (this.diagnostico_selected) {
          params = {
            diag_det_his_dia_det_id: "",
            dia_det_med_con_id: this.consulta.med_con_id,
            dia_det_dia_id: this.diagnostico_selected.dia_id,
            dia_det_diagnostic_detail: comentario,
            indicacion: this.comentario2,
            //pdf: pdf
          }
        } else {
          params = {
            diag_det_his_dia_det_id: "",
            dia_det_med_con_id: this.consulta.med_con_id,
            dia_det_dia_id: null,
            dia_det_diagnostic_detail: comentario,
            indicacion: this.comentario2,
            //pdf: pdf
          }
        }
        ////console.log(params);
        this.aux = params;
        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postCreateDiagnostic')
          .then((results:any) => {

            this.modalAgregadoExitoso();
          })
          .catch(error => {
            this.modalErrorDocumento();
                
          });
      
      } else if (this.accion == 2 && examen) {//Examen
        // let pdf = this.generarPdfTexto();

        let params = {
          med_ord_exa_typ_id: examen,
          id_tel_medical_order: "",
          med_ord_med_ord_typ_id: 3,
          med_ord_comment: comentario,
          med_ord_tel_med_con_id: this.consulta.med_con_id,
          //pdf: pdf
        }
        this.aux = params;

        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postCreateOrder')
          .then((results: any) => {
            this.modalAgregadoExitoso();
          })
          .catch(error => {
            this.modalErrorDocumento();
          });
      } else if (this.accion == 3 && receta) {//Receta
        if ( Number(receta) ==5) {
          this.titulo_doc = 'Receta Magistral';
        }
        // let pdf = this.generarPdfTexto();
        let params = {
          pre_pre_typ_id: receta,
          pre_his_pre_id: "",
          pre_prescription: comentario,
          pre_indication: comentario,
          pre_med_con_id: this.consulta.med_con_id,
          //pdf: pdf
        }
        var tipo;
        for (let index = 0; index < this.recetas.recetas.length; index++) {
          // //console.log(this.recetas.recetas[index]);
          
          if (this.recetas.recetas[index].pre_typ_id == receta) {
            tipo = this.recetas.recetas[index].pre_typ_name;
          }
        }
        this.aux = {
          pre_his_indication: comentario,
          pre_typ_name: tipo
        }


        // //console.log(this.titulo_doc);
        

        // //console.log(this.aux);
        this.modalCargando();
        if (this.check_firma) {
          this._fichaMedicaServices.postFirmaDocumento(params)
          .then((results: any) => {
            this.modalAgregadoExitoso();
          })
          .catch(error => {
            this.modalErrorDocumento();                
          });
        } else {          
          this._fichaMedicaServices.postCreateOrder(params, '/postCreatePrescription')
            .then((results: any) => {
              this.modalAgregadoExitoso();
            })
            .catch(error => {
              this.modalErrorDocumento();                
            });
        }
        
      } else if (this.accion == 4) {//Certificado
        // let pdf = this.generarPdfTexto();
        let params = {
          med_ord_exa_typ_id: "",
          id_tel_medical_order: "",
          med_ord_med_ord_typ_id: 2,
          med_ord_comment: comentario,
          med_ord_tel_med_con_id: this.consulta.med_con_id,
          //pdf: pdf
        }
        this.aux = params;
        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postCreateOrder')
          .then((results:any) => {
            this.modalAgregadoExitoso();              
          })
          .catch(error => {
            this.modalErrorDocumento();
                
          });

      } else if (this.accion == 5) {//Carta Paciente


        let params = {
          med_con_anamnesis: comentario,
          med_con_id: this.consulta.med_con_id
        }
        ////console.log(params);
        
        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postUpdateMedicalAnamnesis')
          .then(results => {
            this.modalAgregadoExitoso();
            this.titulo = "Seleccione Opción en Barra Lateral";
            this.subtitulo = "Seleccione Opción en Barra Lateral";
            this.comentario = "";
            this.accion = 0;
            this.reloadComponent();
          })
          .catch(error => {
            Swal.fire('Error.',
              error.message, 'error'
            );
                
          });

      } else if (this.accion == 6) {//Archivos,, todavía no ocupado

        let params = {

          
        }

      } else if (this.accion == 7 ) {//Derivar Paciente

        // //console.log('id_clinica: '+this.clinica_selected.hos_id);
        
        let params = {
          med_ord_tel_med_con_id: this.consulta.med_con_id,
          der_hos_id: this.clinica_selected.hos_id,
          der_doctor: comentario,
          der_id: ""
        }
        ////console.log(params);
        
        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postCreateDerivation')
          .then(results => {
            this.modalAgregadoExitoso();
            this.titulo = "Seleccione Opción en Barra Lateral";
            this.subtitulo = "Seleccione Opción en Barra Lateral";
            this.comentario = "";
            this.accion = 0;
            this.reloadComponent();
          })
          .catch(error => {
            Swal.fire('Error.',
              error.message, 'error');
          });
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Campos Incompletos',
        text: 'Por favor complete los campos del formulario.',
        timer: 5000
      })

    }
    
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////         CreateOrder          //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////           Modificar          //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  crearOrden2(f: NgForm) {
    var comentario = f.value.comentario;
    var diagnostico = f.value.id_diagnostico;
    var examen = f.value.id_exam;
    var receta = f.value.id_receta;
    var causal = f.value.id_causal;

    if (comentario.length > 3 && causal) {
      
      if (this.accion == 1) {//Diagnostico
        var params;
        // let pdf = this.generarPdfTexto();
        if (this.diagnostico_selected) {
          params = {
            causal: causal,
            diag_det_his_dia_det_id: this.id_requerimiento,
            dia_det_med_con_id: this.consulta.med_con_id,
            dia_det_dia_id: this.diagnostico_selected.dia_id,
            dia_det_diagnostic_detail: comentario,
            indicacion: this.comentario2,
            //pdf: pdf
          }
        } else {
          params = {
            causal: causal,
            diag_det_his_dia_det_id: this.id_requerimiento,
            dia_det_med_con_id: this.consulta.med_con_id,
            dia_det_dia_id: null,
            dia_det_diagnostic_detail: comentario,
            indicacion: this.comentario2,
          }
        }

        
        this.modalCargando();
        this.aux = params;

        this._fichaMedicaServices.postCreateOrder(params, '/postCreateDiagnostic')
          .then(results => {
            this.modalAgregadoExitoso();
          })
          .catch(error => {
            this.modalErrorDocumento();
          });
      
      } else if (this.accion == 2 && examen) {//Examen
        // let pdf = this.generarPdfTexto();
        let params = {
          causal: causal,
          med_ord_exa_typ_id: examen,
          id_tel_medical_order: this.id_requerimiento,
          med_ord_med_ord_typ_id: 3,
          med_ord_comment: comentario,
          med_ord_tel_med_con_id: this.consulta.med_con_id,
          //pdf: pdf
        }
        
        this.modalCargando();
        this.aux = params;

        this._fichaMedicaServices.postCreateOrder(params, '/postCreateOrder')
          .then(results => {
            this.modalAgregadoExitoso();     
              
          })
          .catch(error => {
            this.modalErrorDocumento();
          });
    
      } else if (this.accion == 3 && receta) {//Receta
        if ( Number(receta) ==5) {
          this.titulo_doc = 'Receta Magistral';
        }

        
      } else if (this.accion == 4) {//Certificado
        // let pdf = this.generarPdfTexto();
        let params = {
          causal: causal,
          med_ord_exa_typ_id: "",
          id_tel_medical_order: this.id_requerimiento,
          med_ord_med_ord_typ_id: 2,
          med_ord_comment: comentario,
          med_ord_tel_med_con_id: this.consulta.med_con_id,
          //pdf: pdf
        }
        this.modalCargando();
        this.aux = params;
        
        this._fichaMedicaServices.postCreateOrder(params, '/postCreateOrder')
          .then(results => {
           // this.generarOrderPdf(this.id_requerimiento);
           this.modalAgregadoExitoso();    
                
          })
          .catch(error => {
            this.modalErrorDocumento();
          });

      } else if (this.accion == 5) {//Carta Paciente


        let params = {
          causal: causal,
          med_con_anamnesis: comentario,
          med_con_id: this.id_requerimiento
        }

        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postUpdateMedicalAnamnesis')
          .then(results => {

            this.titulo = "Seleccione Opción en Barra Lateral";
            this.subtitulo = "Seleccione Opción en Barra Lateral";
            this.comentario = "";
            this.accion = 0;
            Swal.close();
            this.reloadComponent();
            this.modalModificadoExitoso();
            
          })
          .catch(error => {
            Swal.fire('Error.',
              error.message, 'error'
            );
          });

      } else if (this.accion == 6) {//Archivos,, todavía no ocupado

        let params = {}

      } else if (this.accion == 7 ) {//Derivar Paciente
        // //console.log('id_clinica: '+this.clinica_selected.hos_id);

        let params = {
          med_ord_tel_med_con_id: this.consulta.med_con_id,
          der_hos_id: this.clinica_selected.hos_id,
          der_doctor: this.comentario,
          der_id: this.id_requerimiento
        }
        // //console.log(params);
        
        this.modalCargando();
        
        this._fichaMedicaServices.postCreateOrder(params, '/postCreateDerivation').then(results => {

          this.titulo = "Seleccione Opción en Barra Lateral";
          this.subtitulo = "Seleccione Opción en Barra Lateral";
          this.comentario = "";
          this.accion = 0;
          this.reloadComponent();
          Swal.close();
      
          this.modalModificadoExitoso();
              
        }).catch(error => {
          Swal.fire('Error.',
            error.message, 'error'
          );
                
        });

      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Campos Incompletos',
        text: 'Por favor complete los campos del formulario.',
        timer: 5000
      })

    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////           Modificar          //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////           Eliminar           //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  eliminarPrescription(item) {
    //console.log(item);
    this.data_eliminar = item;
    //this.eliminardocumento.open();



  }

  eliminarOrder(item) {
    //console.log(item);
    this.data_eliminar = item;
    //this.eliminardocumento.open();


  }
  eliminarCertificado(item) {
    //console.log(item);
    this.data_eliminar = item;
    //this.eliminardocumento.open();

  }


  eliminarDiagnostic(item) {
    //console.log(item);
    this.data_eliminar = item;
    //this.eliminardocumento.open();


  }

  eliminarDerivation(item) {
    //console.log(item);
    this.data_eliminar = item;
    //this.eliminardocumento.open();


  }


  eliminarModal() {
    
    if (this.accion == 1) {
      this.modalCargando();
      
      this._fichaMedicaServices.postDeleteDiagnostic(this.data_eliminar.dia_det_id)
        .then(result => {
  
          this.reloadComponent();
          this.eliminarDocumento();
          this.accion = 1;
  
        })
        .catch(error => {
          Swal.fire('Error.',
            error.message, 'error'
          );
        })
    }

    if (this.accion == 2) {
      this.modalCargando();
      
  
      this._fichaMedicaServices.postDeleteOrder(this.data_eliminar.med_ord_id)
        .then(result => {
  
          this.reloadComponent();
          this.eliminarDocumento();
          this.accion = 2;
  
        })
        .catch(error => {
          Swal.fire('Error.',
            error.message, 'error'
          );
        })
    }

    if (this.accion == 3) {
      this.modalCargando();
      
  
      this._fichaMedicaServices.postDeletePrescription(this.data_eliminar.pre_his_pre_id)
        .then(result => {
  
  
          this.reloadComponent();
          this.eliminarDocumento();
          this.accion = 3;
  
        })
        .catch(error => {
          Swal.fire('Error.',
            error.message, 'error'
          );
        })
    }

    if (this.accion == 4) {
      
      this.modalCargando();
    

    this._fichaMedicaServices.postDeleteOrder(this.data_eliminar.med_ord_id)
      .then(result => {

        this.reloadComponent();
        this.eliminarDocumento();
        this.accion = 4;

      })
      .catch(error => {
        Swal.fire('Error.',
          error.message, 'error'
        );
      })
    }

    if (this.accion == 5) {
      
    }

    if (this.accion == 6) {
      this.eliminarConfirmado();
    }
    if (this.accion == 7) {
      this.modalCargando();
      
  
      this._fichaMedicaServices.postDeleteDerivation(this.data_eliminar.der_his_der_id)
        .then(result => {
  
  
          Swal.close();
          // this.reloadComponent();
          this.reloadComponent();
          this.eliminarDocumento();
          this.accion = 7;
  
        })
        .catch(error => {
          Swal.fire('Error.',
            error.message, 'error'
          );
        })
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////           Eliminar           //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////        Ver Documentos        //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  verPrescription(item) {
    this.titulo = "Receta Médica";
    this.subtitulo = "Receta Médica";
    this.comentario = item.pre_his_indication;
    this.accion = 3;
    this.id_receta = item.pre_his_pre_typ_id;
    this.id_requerimiento = item.pre_his_pre_id;

    this.sub_historial = false;
    this.nuevo = false;
    this.modificar = true;
  }

  verOrder(item) {
    this.id_requerimiento = item.med_ord_his_med_ord_id;
    
    if (item.med_ord_his_med_ord_typ_id == 2) {
      this.titulo = "Certificado Médico";
      this.subtitulo = "Actualizar Certificado Médico";
      this.comentario = item.med_ord_his_comment;
      this.accion = 4;

    } else if (item.med_ord_his_med_ord_typ_id == 3) {

      this.titulo = "Orden Médica";
      this.subtitulo = "Actualizar Orden Médica";
      this.comentario = item.med_ord_his_comment;
      this.accion = 2;
      this.id_exam = item.med_ord_his_exa_typ_id;
      this.traerExamen();

    } else if (item.med_ord_his_med_ord_typ_id == 1) {

      this.titulo = "Indicación Médica";
      this.subtitulo = "Actualizar Indicación Médica";
      this.comentario = item.med_ord_his_comment;
      this.accion = 2;
      this.id_exam = item.med_ord_his_exa_typ_id;
      this.traerExamen();
    }
    
    this.sub_historial = false;
    this.nuevo = false;
    this.modificar = true;

  }

  downloadOrder(item) {
    this._fichaMedicaServices.postVerOrder(item)
      .then(results => {

        //console.log(results);
             
      })
      .catch(error => {
        Swal.fire({
          icon: 'warning',
          title: 'Error al cargar documento...',
          timer: 2000
        })

      });
  }

  verDiagnostic(item) {
    this.titulo = "Diágnostico e Indicaciones";
    this.subtitulo = "Actualizar Diágnostico";
    this.comentario = item.diag_det_his_diagnostic_detail;
    this.comentario2 =item.diag_det_his_indication_detail;
    this.accion = 1;
    this.id_diagnostico = item.diag_det_his_dia_id;
    this.id_requerimiento = item.diag_det_his_dia_det_id;
    if (item.diag_det_his_dia_id) {
      this.searchStrDia = item.dia_cie10 + ': ' + item.dia_name;
    }
    this.sub_historial = false;
    this.nuevo = false;
    this.modificar = true;
  }

  verAnamnesis(item) {
    this.titulo = "Historia Clínica";
    this.subtitulo = "Actualizar Historia Clínica";
    this.comentario = item.med_con_his_anamnesis;
    this.id_requerimiento = item.med_con_his_med_con_id;
    this.accion = 5;
        
    this.sub_historial = false;
    this.nuevo = false;
    this.modificar = true;
  }

  verDerivation(item) {
    // this.consulta.med_con_id = item.med_con_id;
    this.titulo = "Derivación Clínica";
    this.subtitulo = "Actualizar Derivación Clínica";
    this.comentario = item.der_his_doctor;
    this.id_requerimiento = item.der_his_der_id;
    this.searchStr = item.hos_name;
    this.accion = 7;
        
    this.sub_historial = false;
    this.nuevo = false;
    this.modificar = true;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////        Ver Documentos        //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////         Ng-Completer         //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  changeClinica($event) {
    if ($event) {
      this.clinica_selected = $event.originalObject;
    }
    ////console.log(this.clinica_selected);
    
  }

  changeDiagnostico($event) {
    if ($event) {
      this.diagnostico_selected = $event.originalObject;
    }
    //console.log(this.diagnostico_selected);
    
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////         Ng-Completer         //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

  onFileSelected(event) {
    this.doc = event.target.files[0];
    var tipo=this.doc.name.split('.');
    //console.log(tipo);
    this.tipo_doc = tipo[(tipo.length) - 1]
    //console.log(this.tipo_doc);
    if (this.tipo_doc == 'pdf' || this.tipo_doc == 'png' || this.tipo_doc == 'jpg' || this.tipo_doc == 'jpeg' || this.tipo_doc == 'PDF' || this.tipo_doc == 'PNG' || this.tipo_doc == 'JPG' || this.tipo_doc == 'JPEG') {
      this.doc_permitido = true;
    } else {
      this.doc_permitido = false;
    }
    //console.log(this.doc_permitido);
    
  }

  agregar_nuevo() {
    // if (this.accion == 8) {
    //   //console.log('Apreta boton');
      
    // } else {
      this.nuevo = true;
      this.modificar = false;
      this.sub_historial = false;
    // }
  }

  modificar_existente() {
    this.nuevo = false;
    this.modificar = true;
    this.sub_historial = false;
  }

  listar_existentes() {
    this.nuevo = false;
    this.modificar = false;
    this.sub_historial = true;
  }


  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }

  nombreValido() {
    return true;
  }

  update_doc(){
    this.ngOnInit();
  }
  
  guardar_doc() {
   

    //console.log(this.doc);
    if (this.doc) {
      if (this.doc.size <= 20048576 && this.doc_permitido && this.nombreValido()) {
        this.nombre_doc = this.nombre_doc + '.' + this.tipo_doc;

        this.modalCargando();

        this._documentServices.postSaveOtro(this.doc, this.consulta.id_consulta, this.nombre_doc, this.consulta.user_id)
          .then(results => {
            Swal.close();
            Swal.fire({
              html:
                ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
                ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > El documento se ha subido exitosamente</h1><br>',
              // footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Volver al inicio</button> ',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000
            })
            //setTimeout(() => window.location.reload(), 4000);
            this.doc = null;
            this.nombre_doc = "";
            this.listar_documentos();
            this.renderer.selectRootElement(this.cerrarSubirDoc.nativeElement).click();
            
          })
          .catch(error => {
            Swal.fire('Error.',
              error.message, 'error'
            );
          });
      } else if (!this.doc_permitido) {
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" > Campos Incompletos </h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Tipo de documento no permitido.</p><br>',
          
        }) 
      } else{
        Swal.fire({
          allowOutsideClick: false,
          html:
            ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
            ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" > Campos Incompletos </h1> <br>' +
            ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" > Por favor complete los campos del formulario.</p><br>',
          
        }) 
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Campos Incompletos',
        text: 'Por favor complete los campos del formulario.',
        timer: 5000
      })

    }
    

        
    // this._documentServices.postSaveDocument(params,'/postCreateDiagnostic')

  }


  eliminarArchivo(item) {
    //console.log(item);
    this.doc_a_eliminar = item;
  }

  eliminarConfirmado() {
    //console.log(this.doc_a_eliminar);
    
    this.modalCargando();


    this._documentServices.postEliminarArchivo(this.doc_a_eliminar.att_name)
      .then(result => {

        this.eliminarDocumento();
        this.listar_documentos();
        //setTimeout(() => window.location.reload(), 4000);
        

      })
      .catch(error => {
        Swal.fire({
          icon: 'warning',
          title: 'Error al eliminar documento.',
          timer: 2000
        })

      })
  }
  
  delFileSelected() {
    this.doc = null;
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.listar_documentos();
    this.clinica_selected = {
      hos_id:null
    };
    this.Reset2();
  }


  modalErrorDocumento() {
    Swal.close();
    Swal.fire({
      icon: 'warning',
      title: 'Error al manejar documento.',
      timer: 2000
    })
  }


  eliminarDocumento() {
    this.data_eliminar = {};
    Swal.close();
    Swal.fire({
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > El documento se ha eliminado exitosamente</h1><br>',
      // footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Volver al inicio</button> ',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 1500
    })
  }
  
  modalCargando() {
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();
  }

  modalModificadoExitoso() {
    Swal.close();
    Swal.fire({
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > El documento se ha modificado exitosamente</h1><br>',
      // footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Volver al inicio</button> ',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2000
    })

  }

  modalAgregadoExitoso() {
    Swal.close();
    Swal.fire({
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 38px; line-height: 48px; display: flex; align-items: center; text-align: center; color: #171994;" > El documento se ha subido exitosamente</h1><br>',
      // footer: '<button id="buttonInicio" style="border: 2px solid #316AD8;box-sizing: border-box;border-radius: 30px; width: 100%;height: 100%; background: #171994;font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px; line-height: 24px; align-items: center; text-align: center; color: #FFFFFF;" class="btn btn-outline-primary btn-lg" (click)="navegar()" >Volver al inicio</button> ',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2000
    })
    this.reloadComponent();
    this.comentario = "";
    this.check_firma = false;
  }

  errorCargar() {
    Swal.close();
    Swal.fire({
      icon: 'warning',
      title: 'Error al eliminar documento.',
      timer: 2000
    })
  }

  modalError(titulo,descripcion) {
    Swal.close();
    Swal.fire({
      allowOutsideClick: true,
      html:
        ' <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood_bad</i> <br><br>' +
        ' <h1 style="font-family: Mulish; font-style: normal; font-weight: 800;font-size: 28px; line-height: 48px; align-items: center; text-align: center; color: #171994;" >'+ titulo +'</h1> <br>' +
        ' <p style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000;" >' + descripcion + '</p><br>',
      timer: 3000,
      showCancelButton: false,
      showConfirmButton: false,
    }) 
  }

  modalConfirmarLicencia() {
    Swal.close();

    
    Swal.fire({
      // title: 'Saliendo de la videoconsulta',
      // text: "seguro?",
      // icon: 'warning',
      html:
      `<div style="text-align: center; font-family: Mulish; font-style: normal;">
        <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">¿Estás seguro de que deseas iniciar el proceso de generación de licencia para este paciente?</h1>
        <br>
      </div>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Generar licencia',
      buttonsStyling: false,
      reverseButtons:true,
      customClass: {
        confirmButton: 'btn-swal-licencia-confirm',
        cancelButton: 'btn-swal-licencia-cancel',
        popup: 'container-swal-licencia-customer'
      }
    })
      .then((result) => {
        //console.log(result,'result-modalConfirmarLicencia');
        
        if (result.isConfirmed) {
          this.solicitarLicenciaPost();
        }
      })
  }
  muestraArchivosPaciente() {
    this.tab_archivos = [true,false];
  }
  muestraArchivosMedico() {
    this.tab_archivos = [false,true];
  }

  verPdfModal(item) {
    //console.log(item);
    this.pdf_url = this.URL_DOCUMENTS+item.link;
  }
  
  verPdfModalVersionActual(item) {
    //console.log(item);
    this.pdf_url = this.URL_DOCUMENTS + item.att_path;
    //console.log(this.pdf_url);
    
  }
  
  traerExamen() {
    //console.log(this.examenes);
    for (let index = 0; index < this.examenes.examenes.length; index++) {
      const element = this.examenes.examenes[index];
      if (element.exa_typ_id==this.id_exam) {
        this.examen_elegido = element;
      }
    }
    //console.log(this.examen_elegido);
  }


  solicitarLicencia() {
    //console.log('solicitarLicencia');
    this.modalConfirmarLicencia();



  }

  solicitarLicenciaPost() {
    if (!this.verificarDatosLicencia()) {
      return;
    }
    let fecha:any = this._generalServices.verificarFecha(this.licencia.fecha_inicio_reposo);
    let fecha_hijo:any = this._generalServices.verificarFecha(this.licencia.fecha_nacimiento_hijo);
    let rut_hijo = (this.licencia.rut_hijo) ? this.licencia.rut_hijo : null;
    this._licenciaMedicaServices.postRequestMedicalLeave(
                                                          fecha.fecha_date_string,
                                                          this.licencia.diagnostico_seleccionado,
                                                          this.licencia.dias_reposo,
                                                          this.licencia.tipo_seleccionado,
                                                          rut_hijo,
                                                          fecha_hijo.fecha_date_string,
                                                          this.consulta.id_consulta
                                                        )
      .then(result => {
        this.modalLicenciaExitosa('Proceso de generación de licencia iniciado exitosamente');
      })
      .catch(error => {
        //console.log(error,'error-solicitarLicencia');
        this.modalError('Error en la generación de licencia', 'Error en la generación de licencia.');
      })
  }

  generarLicencia() {
    //console.log('generarLicencia');
    Swal.close();

    
    Swal.fire({
      // title: 'Saliendo de la videoconsulta',
      // text: "seguro?",
      // icon: 'warning',
      html:
      `<div style="text-align: center; font-family: Mulish; font-style: normal;">
        <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">¿Estás seguro de que deseas iniciar el proceso de generación de licencia para este paciente?</h1>
        <br>
      </div>`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Generar licencia',
      buttonsStyling: false,
      reverseButtons:true,
      customClass: {
        confirmButton: 'btn-swal-licencia-confirm',
        cancelButton: 'btn-swal-licencia-cancel',
        popup: 'container-swal-licencia-customer'
      }
    })
      .then((result) => {
        //console.log(result,'result-modalConfirmarLicencia');
        
        if (result.isConfirmed) {
          this.confirmaGenerarLicencia();
        }
      })
  }

  confirmaGenerarLicencia() {
    if (!this.verificarDatosLicencia()) {
      return;
    }
    let fecha:any = this._generalServices.verificarFecha(this.licencia.fecha_inicio_reposo);
    let fecha_hijo:any = this._generalServices.verificarFecha(this.licencia.fecha_nacimiento_hijo);
    let rut_hijo = (this.licencia.rut_hijo) ? this.licencia.rut_hijo : null;
    this._licenciaMedicaServices.postCreateMedicalLeave(
                                                          fecha.fecha_date_string,
                                                          this.licencia.diagnostico_seleccionado,
                                                          this.licencia.dias_reposo,
                                                          this.licencia.tipo_seleccionado,
                                                          rut_hijo,
                                                          fecha_hijo.fecha_date_string,
                                                          this.consulta.id_consulta
                                                        )
      .then(result => {
        this.modalLicenciaExitosa('Solicitud de generación de licencia generada con éxito.');
      })
      .catch(error => {
        //console.log(error,'error-solicitarLicencia');
        this.modalError('Error en la generación de licencia', 'Error en la generación de licencia.');
      })
  }

  modalLicenciaExitosa(titulo) {
    Swal.close();    
    Swal.fire({
      html:
      `<div style="text-align: center; font-family: Mulish; font-style: normal;">
      <i class="material-icons" style="color:#171994;font-size: 58px;margin: 20px 40px 10px;">mood</i> <br>
        <h1 style="padding: 0 50px; color: #171994; font-weight: bold; font-size: 24px; line-height: 32px;">`+titulo+`</h1>
        <br>
      </div>`,
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      showConfirmButton:false,
      buttonsStyling: false,
      reverseButtons:false,
      customClass: {
        cancelButton: 'btn-swal-licencia-cancel',
        popup: 'container-swal-licencia-customer'
      },
      timer: 3000
    })
    this.reloadComponent();
    this.comentario = "";
    this.check_firma = false;
  }

  generaLicenciaPost() {
    //console.log('generaLicenciaPost');
    if (!this.verificarDatosLicencia()) {
      return;
    }
  }

  verificarDatosLicencia() {
    //console.log('verificarDatosLicencia');
    let fecha = this._generalServices.verificarFecha(this.licencia.fecha_inicio_reposo);
    //console.log(fecha, 'fecha-verificarDatosLicencia');
    let fecha_hijo = this._generalServices.verificarFecha(this.licencia.fecha_nacimiento_hijo);
    //console.log(fecha_hijo,'fecha_hijo-verificarDatosLicencia');
    if (!fecha) {
      this.modalError('Fecha Inválida', 'Por favor ingrese una fecha válida.');
      return false;
    } else if (!this.licencia.dias_reposo || this.licencia.dias_reposo<1) {
      this.modalError('Días Inválidos', 'Por favor ingrese una cantidad de días válida.');
      return false;
    } else if (!this.licencia.diagnostico_seleccionado || this.licencia.diagnostico_seleccionado == '') {
      this.modalError('Diagnóstico Inválido', 'Por favor ingrese un diagnóstico válido.');
      return false;
    } else if (!this.licencia.tipo_seleccionado || this.licencia.tipo_seleccionado == '') {
      this.modalError('Tipo licencia Inválido', 'Por favor ingrese un tipo de licencia válido.');
      return false;
    } else if (this.licencia.rut_hijo && this.licencia.rut_hijo.length>0 && !this._generalServices.validaRut(this.licencia.rut_hijo)) {
      this.modalError('Rut del hijo Inválido', 'Por favor ingrese un rut válido.');
      return false;
    } else if (this.licencia.fecha_nacimiento_hijo && this.licencia.fecha_nacimiento_hijo.length>0 && !fecha_hijo) {
      this.modalError('Fecha hijo Inválida', 'Por favor ingrese una fecha válida.');
      return false;
    } else if (this.licencia.tipo_seleccionado == 4 && (!this.licencia.rut_hijo || !this.licencia.fecha_nacimiento_hijo)) {
      this.modalError('Datos hijo inválidos', 'Por favor los datos del hijo correctamente.');
      return false;
    } else {
      //console.log('Datos correctos-verificarDatosLicencia');
      return true;
    }
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////      servicios_listar       //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

  postListMedicalLeaveType() {
    if (this.usuario.typ_id==1) {      
      this._licenciaMedicaServices.postListMedicalLeaveType()
      .then(result => {
        this.tipos_licencia_medica = result;
        //console.log(this.tipos_licencia_medica);
      })
    }
  }

  getListarExamType() {
    this._fichaMedicaServices.getListarExamType().then(results => {
      this.examenes = results;
    });
  }

  getListarPrescriptionType() {    
    this._fichaMedicaServices.getListarPrescriptionType().then(results => {
      this.recetas = results;
      //console.log(this.recetas);
      
    });
  }

  getListarConDiagnostic() {    
    this._fichaMedicaServices.getListarConDiagnostic().then(results => {
      this.diagnosticos_data = results;
      for (let dia of this.diagnosticos_data.diagnosticos) {
        dia.descripcion = dia.dia_cie10 + ': ' + dia.dia_name;
      }
      this.diagnosticos = this._completerService.local(this.diagnosticos_data.diagnosticos, 'descripcion', 'descripcion')
    });
  }

  getListarClinicas() {    
    this._fichaMedicaServices.getListarClinicas().then(results => {
      this.clinicas_data = results;
      this.clinicas = this._completerService.local(this.clinicas_data, 'hos_name', 'hos_name')
    });
  }

  getListarConCausal() {    
    this._fichaMedicaServices.getListarConCausal()
    .then(results => {
      this.causales = results;
    });
  }
  
  async listar_documentos() {
    
    // while (true) {
      if (!this.consulta.id_consulta) {
        //console.log("No existe consulta");
        // await this.sleep(1000);
      } else {
        this._fichaMedicaServices.getListarFichaConsulta(this.consulta)
          .then(results => {
            // console.log(results);
            this.ficha = results;

            for (let docum in this.ficha){ //numero correlativo para los documentos de una misma consultaId
              let ind = 1;
              for (let file of this.ficha[docum]){
                if( file.file_name ){
                  let ind_st = (100+ind).toString().substring(1)
                  file.file_name_desc = file.file_name.replace('XX', ind_st)
                  ind++;
                }
              }
            }
            // console.log(this.ficha);
          })
          .catch(error => {
            console.log(error);
          });
    
        var use_id = false;
        //console.log(this.usuario.typ_name);
        
        use_id = await this.consulta.user_id;
        
        this._documentServices.postListaArchivosConsulta(this.consulta.id_consulta, use_id)
        .then(results => {
          // console.log(results);
          this.archivos_consulta = results;

          let ind = 1;
          for (let file of this.archivos_consulta){
            if( file.file_name ){
              let ind_st = (100+ind).toString().substring(1)
              file.file_name_desc = file.file_name.replace('XX', ind_st)
              ind++;
            }
          }
          
        })
        .catch(error => {
          console.log(error);
        });
        
        if (this.usuario.typ_name == 'MEDICO') {
          this._documentServices.postListarArchivosPaciente(use_id)
            .then(result => {
              // console.log(result);
            
              this.archivos_paciente = result;
            })
            .catch(error => {
              Swal.fire({
                icon: 'warning',
                title: 'Error al cargar documentos pasados.',
                timer: 2000
              })
            })
    
    
        }
        // break;
      }
    // }
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////      servicios_listar       //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

}


// licencia: any = {
//   fecha_inicio_reposo: '',
//   diagnostico_seleccionado: '',
//   dias_reposo: null,
//   tipo_seleccionado: '',
//   rut_hijo: '',
//   fecha_nacimiento_hijo: '',
// }


