<app-title-header 
  [title]='_title' 
  [subtitle]='_subtitle'>
</app-title-header>

<!-- card -->
<div class="card h-100 p-0 card-doctor" *ngIf="data_cargada">
  <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
    <div class="m-0 p-0 first-line">
      <app-profile-img 
        [img]="datos_consulta.att_path" 
        [doctorName]="datos_consulta.nombre_doctor" 
        [doctorLastName]="datos_consulta.apellido_materno_doctor"
        [color]="'default'">
      </app-profile-img>
      <div class="ml-3 align-self-center detalle">
        <p class="card-text m-0 p-0 doc-data"> Dr. {{ datos_consulta.nombre_doctor }} {{ datos_consulta.apellido_paterno_doctor }} {{ datos_consulta.apellido_materno_doctor }}</p>
        <p class="card-text m-0 p-0 doc-data">{{ datos_consulta.spe_name }} </p> <!--- {{ datos_consulta.sub_spe_name }} -->
        <p class="card-text m-0 p-0 appt-date">Hoy {{datos_consulta.fecha_consulta.day}} de {{datos_consulta.fecha_consulta.month}} - a las {{datos_consulta.fecha_consulta.time}} horas</p>
      </div>
      <div class="ml-3 align-self-center confirmada">
        <p class="text-right"><li>Confirmada</li></p>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <app-video-web-rtc 
      [video_iniciado]='videoIniciado'
    >
    </app-video-web-rtc>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <p class="letra-titulo">Debes Tener Presente</p>
  </div>
</div>

<div class="row mb-2 mb-sm-4 mt-1 mt-sm-1">
    <div class="card-deck"> 

      <div class="card border-confirm card-fono col-md-6 col-sm-12">
        <div class="card-block px-2 px-sm-3 pt-2 pt-sm-3 pb-4">
          <p class="card-text"></p>
          <p class="card-text">Recuerda que debes encender tu cámara y tu micrófono, antes de iniciar tu consulta de telemedicina.</p>
        </div>
      </div>
      <div class="card border-confirm card-fono col-md-6 col-sm-12">
        <div class="card-block ">
          <p class="card-text m-0 p-0">Si tienes algún problema técnico, o el paciente no se presenta, debes comunicarte con nuestra Mesa de Ayuda.</p>
          <p *ngIf="datos_consulta.telefono_plan" class="fono p-0">{{datos_consulta.telefono_plan}}</p>
        </div>
      </div>
  </div> 
</div>