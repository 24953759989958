<div *ngIf="usuario.typ_name == 'PACIENTE'" class="col-md-12"> <!--  *ngIf="tipo_usuario_name=='PACIENTE'" -->
    <br>
    <div class="minititulo">
         Mi perfil
    </div>

    <div class="titulo">
        <div class="row">
            <div class="col-md-6">

                Mis antecedentes
            </div>
            <div class="col-md-6 contenedor-actualizar">
                <button type="button" class="updatebtn"(click)="update_doc()">
                <p style="margin: 0 25px;">
                    Actualizar
                </p>
            </button>
            </div>
        </div>
    </div>
   <br>
</div>

<div *ngIf="usuario.typ_name == 'MEDICO'" class="col-md-12"> <!--  *ngIf="tipo_usuario_name=='PACIENTE'" -->
    <br>
    <div class="minititulo">
         Perfil Paciente
    </div>
    <div class="titulo">
        <div class="row">
            <div class="col-md-6">

                Antecedentes Paciente
            </div>
            <div class="col-md-6 contenedor-actualizar">
                <button type="button" class="updatebtn"
                (click)="update_doc()">
                <p style="margin: 0 25px;">
                    Actualizar
                </p>
            </button>
            </div>
        </div>
    </div>
   <br>
</div>

<!-- ALERGIAS -->
<div class="row card-body-beneficiario">
     <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
        <div class="card-body bordeCardBeneficiario">
            <div class="row">
                <div class="col-md-8 col-12">
                    <h5 class="letraTituloCard">Alergias</h5>
                </div>
                <div class="col-md-4 col-12 colorletrasboton">
                    <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="show = !show">{{ show ? 'Ocultar': 'Ver más' }}</button>
                    <button type="button" class="btn btn-outline-primary btn-lg botonAgregar boton" data-toggle="modal" data-target="#agregarAlergia">Agregar</button>
                </div>
            </div>
            <br>
            <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
            </div>
          <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.allergic ; let i = index;">
              <div class="comment-text w-100 verMasBoton" [class.show]="show">
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Alergia A:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_all_allergic}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Fecha de inicio:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_all_start_date | date:'dd-MM-yyyy' }} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Severidad:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_all_sev_id}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Reacción producida:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_all_reaction}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex no-block">
                      <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                          <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                          <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item cursor" (click)="infoAuditaAlergia(i)" data-toggle="modal" data-target="#eliminarAlergia" >Eliminar de Alergias</a> <!--(click)="eliminarAlergia(i)"-->
                          </div>
                      </div>
                  </div>
                  <br>
                  <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- ANTECEDENTES FAMILIARES(PATOLOGIAS) NUEVO-->

<div class="row card-body-beneficiario">
     <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
        <div class="card-body bordeCardBeneficiario">
            <div class="row">
                <div class="col-md-8 col-12">
                    <h5 class="letraTituloCard">Patologías y/o antecedentes familiares</h5>
                </div>
                <div class="col-md-4 col-12 colorletrasboton">
                <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="showPatologias = !showPatologias">{{ showPatologias ? 'Ocultar': 'Ver más' }}</button>
                    <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#agregarAntecedenteFamiliar">Agregar</button>
                </div>
            </div>
            <br>
            <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
            </div>
          <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.familiar ; let i = index">
              <div class="comment-text w-100 verMasBoton" [class.show]="showPatologias">
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Patología:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_fam_pathology}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Relación familiar:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_fam_ben_rel_id}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Comentario:</label>
                          <div class="col-md-9">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_fam_comments}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex no-block">
                      <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                          <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                          <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item cursor" (click)="infoAuditaFamilia(i)" data-toggle="modal" data-target="#eliminarAntecedenteFamiliar" >Eliminar de patologías</a> <!--(click)="eliminarAlergia(i)"-->
                          </div>
                      </div>
                  </div>
                  <br>
                  <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- HOSPITALIZACION NUEVO -->
<div class="row card-body-beneficiario">
     <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
        <div class="card-body bordeCardBeneficiario">
            <div class="row">
                <div class="col-md-8 col-12">
                    <h5 class="letraTituloCard">Hospitalizaciones y/o Cirugías</h5>
                </div>
                <div class="col-md-4 col-12 colorletrasboton">
                    <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="showHospitalizaciones = !showHospitalizaciones">{{ showHospitalizaciones ? 'Ocultar': 'Ver más' }}</button>
                    <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#agregarHospitalizacion">Agregar</button>
                </div>
            </div>
            <br>
            <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
            </div>
          <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.hospitalizations ; let i = index;">
              <div class="comment-text w-100 verMasBoton" [class.show]="showHospitalizaciones">
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Motivo:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_file_hos_reason}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Fecha inicio:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_file_hos_start_date | date:'dd-MM-yyyy' }} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Fecha termino:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_file_hos_end_date | date:'dd-MM-yyyy'}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Comentario:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_file_hos_comments}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex no-block">
                      <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                          <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                          <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item cursor" (click)="infoAuditaHospitalizacion(i)" data-toggle="modal" data-target="#eliminaHospitalizacion" >Eliminar de hospitalizaciones</a> <!--(click)="eliminarAlergia(i)"-->
                          </div>
                      </div>
                  </div>
                  <br>
                  <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- PATOLOGIAS NUEVO (DIAGNOSTICOS) -->
<div class="row card-body-beneficiario">
     <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
        <div class="card-body bordeCardBeneficiario">
            <div class="row">
                <div class="col-md-8 col-12">
                    <h5 class="letraTituloCard">Diagnósticos anteriores</h5>
                </div>
                <div class="col-md-4 col-12 colorletrasboton">
                    <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="showDiagnosticos = !showDiagnosticos">{{ showDiagnosticos ? 'Ocultar': 'Ver más' }}</button>
                    <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#agregarPatologia">Agregar</button>
                </div>
            </div>
            <br>
            <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
            </div>
          <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.pathology ; let i = index;">
              <div class="comment-text w-100 verMasBoton" [class.show]="showDiagnosticos">
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Diagnóstico:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_pat_name}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Fecha de diagnóstico:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_pat_diagnosis_date | date:'dd-MM-yyyy' }} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Comentario:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_pat_ben_comments}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex no-block">
                      <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                          <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                          <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item cursor" (click)="infoAuditaPatologia(i)" data-toggle="modal" data-target="#eliminarPatologia" >Eliminar de diagnósticos</a> <!--(click)="eliminarAlergia(i)"-->
                          </div>
                      </div>
                  </div>
                  <br>
                  <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- MEDICAMENTOS NUEVO -->
<div class="row card-body-beneficiario">
     <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
        <div class="card-body bordeCardBeneficiario">
            <div class="row">
                <div class="col-md-8 col-12">
                    <h5 class="letraTituloCard">Medicamentos de uso permanente</h5>
                </div>
                <div class="col-md-4 col-12 colorletrasboton">
                    <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="showMedicamentos = !showMedicamentos">{{ showMedicamentos ? 'Ocultar': 'Ver más' }}</button>
                    <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#agregarMedicamento">Agregar</button>
                </div>
            </div>
            <br>
            <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
            </div>
          <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.medicine ; let i = index;">
              <div class="comment-text w-100 verMasBoton" [class.show]="showMedicamentos">
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Medicamento:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_med_name}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Fecha inicio:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_med_start_date | date:'dd-MM-yyyy' }} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Duración:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_med_duration}} </p>
                          </div>
                      </div>
                  </div>
                   <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Frecuencia:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_med_frecuency}}</p>
                          </div>
                      </div>
                  </div>
                   <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Dosis:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_med_dosis}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group row">
                          <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Comentario:</label>
                          <div class="col-md-9 col-7">
                              <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_med_comments}} </p>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex no-block">
                      <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                          <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                          <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item cursor" (click)="infoAuditaMedicamento(i)" data-toggle="modal" data-target="#eliminarMedicamento" >Eliminar de medicamentos</a> <!--(click)="eliminarAlergia(i)"-->
                          </div>
                      </div>
                  </div>
                  <br>
                  <div class="form-group col-md-12 m-t-20">
                    <hr class="m-t-0 m-b-40 linea-color">
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- CIRUGIAS -->
<div class="row card-body-beneficiario">
    <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
       <div class="card-body bordeCardBeneficiario">
           <div class="row">
               <div class="col-md-8 col-12 ">
                   <h5 class="letraTituloCard">Cirugías</h5>
               </div>
               <div class="col-md-4 col-12 colorletrasboton">
                   <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="showCirugias = !showCirugias">{{ showCirugias ? 'Ocultar': 'Ver más' }}</button>
                   <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#agregarCirugia">Agregar</button>
               </div>
           </div>
           <br>
           <div class="form-group col-md-12 m-t-20">
                   <hr class="m-t-0 m-b-40 linea-color">
           </div>
         <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.surgery ; let i = index;">
             <div class="comment-text w-100 verMasBoton" [class.show]="showCirugias">
                 <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Nombre de la cirugía:</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_sur_procedure}} </p>
                         </div>
                     </div>
                 </div>
                 <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Fecha:</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_sur_date_surgery | date:'dd-MM-yyyy' }} </p>
                         </div>
                     </div>
                 </div>
                 <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Médico:</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_sur_doctor}} </p>
                         </div>
                     </div>
                 </div>
                  <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Comentario:</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fil_sur_comments}}</p>
                         </div>
                     </div>
                 </div>
                 <div class="d-flex no-block">
                     <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                         <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                         <div class="dropdown-menu dropdown-menu-right">
                             <a class="dropdown-item cursor" (click)="infoAuditaCirugia(item)" data-toggle="modal" data-target="#eliminarCirugia" >Eliminar de cirugías</a> <!--(click)="eliminarAlergia(i)"-->
                         </div>
                     </div>
                 </div>
                 <br>
                 <div class="form-group col-md-12 m-t-20">
                   <hr class="m-t-0 m-b-40 linea-color">
                 </div>
             </div>
         </div>
       </div>
   </div>
</div>

<!-- HABITOS -->
<div class="row card-body-beneficiario">
    <div class="card col-md-12"> <!-- *ngFor="let item of this.family_group_data.info ; let i = index;" -->
       <div class="card-body bordeCardBeneficiario">
           <div class="row">
               <div class="col-md-8 col-12">
                   <h5 class="letraTituloCard">Hábitos</h5>
               </div>
               <div class="col-md-4 col-12 colorletrasboton">
                   <button type="button" class="btn btn-outline-primary btn-lg botonVerMas colorLetraVolver" (click)="showHabitos = !showHabitos">{{ showHabitos ? 'Ocultar': 'Ver más' }}</button>
                   <button type="button" class="btn btn-outline-primary btn-lg boton" data-toggle="modal" data-target="#agregarHabito">Agregar</button>
               </div>
           </div>
           <br>
           <div class="form-group col-md-12 m-t-20">
                   <hr class="m-t-0 m-b-40 linea-color">
           </div>
         <div class="d-flex flex-row comment-row" *ngFor="let item of mis_antecedentes.habit ; let i = index;">
             <div class="comment-text w-100 verMasBoton" [class.show]="showHabitos">
                 <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Hábito:</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.hab_name}} </p>
                         </div>
                     </div>
                 </div>
                 <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Frecuencia</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.fre_name}} </p>
                         </div>
                     </div>
                 </div>
                 <div class="col-md-12">
                     <div class="form-group row">
                         <label class="letrasCardBeneficiario control-label text-left col-md-3 col-5">Comentario</label>
                         <div class="col-md-9 col-7">
                             <p class="form-control-static letrasCardBeneficiarioBase"> {{item.ben_fin_hab_comments}} </p>
                         </div>
                     </div>
                 </div>
                 <div class="d-flex no-block">
                     <div class="btn-group ml-auto m-t-10" style="color: #171994;">
                         <a class="link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><mat-icon>delete_outline</mat-icon></a>
                         <div class="dropdown-menu dropdown-menu-right">
                             <a class="dropdown-item cursor" (click)="infoAuditaHabito(item)" data-toggle="modal" data-target="#eliminarHabito" >Eliminar de hábitos</a> <!--(click)="eliminarAlergia(i)"-->
                         </div>
                     </div>
                 </div>
                 <br>
                 <div class="form-group col-md-12 m-t-20">
                   <hr class="m-t-0 m-b-40 linea-color">
                 </div>
             </div>
         </div>
       </div>
   </div>
</div>

<!-- Modal agregar Alergia-->
<div class="modal fade" id="agregarAlergia" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal">Agregar Alergia</p>
        <br>
      <div class="">
      <br><br><br>
        <form class="form col-md-12"
                id="AlergiaForm"
                autocomplete ="off"
                [formGroup] = "alergiaForm"
                (ngSubmit)="crearAlergia()">
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            formControlName="alergia"
                            placeholder="Nombre de la alergia"
                            id="alergia">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            onfocus="(this.type='date')"
                            placeholder="Fecha de inicio"
                            formControlName="fecha_inicio"
                            id="fecha_inicio">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <select name="severidad" formControlName="severidad" placeholder="Severidad" class="form-control camposModalAgregar" id="severidad">
                    <option value = "" selected>Severidad</option>
                    <option [value]="zona.sev_id" *ngFor="let zona of severidades">{{zona.sev_name}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            formControlName="reaccion_producida"
                            placeholder="Reacción producida"
                            id="reaccion_producida">
                </div>
            </div>
            <div class="row">
                <div class="col text-danger">
                    <p *ngIf="campoNoValido('alergia')"> La alergia es obligatoria</p>
                    <p *ngIf="campoNoValido('fecha_inicio')"> La fecha de inicio es obligatoria</p>
                    <p *ngIf="campoNoValido('severidad')"> La severidad es obligatoria</p>
                    <p *ngIf="campoNoValido('reaccion_producida')"> La reaccion alergica es obligatoria</p>
                </div>
            </div>
            <br>
            <br>
            <div class="container-fluid">
              <div class="row">
                  <div class="col-md-6">
                      <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                  </div>
                  <div class="col-md-6 letrabotonagregar">
                      <button type="submit" class="btn btn-outline-primary botonModalAgregar">Agregar</button>
                  </div>
              </div>
            </div>
      </form>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Eliminar -->
<div class="modal fade" id="eliminarAlergia" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal" style="text-align: center;">Atención</p>
        <br><br>
      <div class="form-group row">
        <div class="col-md-12">
          <p class="textoModal">Esta eliminando una alergia </p>
        </div>
      </div>
      <br><br>
      <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
                <button type="buton" (click)="eliminaAlergia()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<!--Modal Agregar ANTECEDENTES FAMILIAR(PATOLOGÍAS) NUEVO-->
<div class="modal fade" id="agregarAntecedenteFamiliar" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog"> <!--modal-lg-->
  <div class="modal-content">
    <div class="modal-body">
    <br><br>
        <p class="tituloModal">Agregar Patología</p>
        <br>
      <div class="">
      <br><br><br>
        <form class="form col-md-12"
                id="FamiliaForm"
                autocomplete ="off"
                [formGroup] = "familiaForm"
                (ngSubmit)="crearAntecedenteFamiliar()">
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            placeholder="Patología"
                            formControlName="patologia"
                            id="patologia">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <select name="relacion_familiar" formControlName="relacion_familiar" class="form-control camposModalAgregar" id="relacion_familiar">
                    <option value = "" selected>Relación familiar</option>
                    <option [value]="zona.ben_rel_id" *ngFor="let zona of relacion">{{zona.ben_rel_name}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <textarea class="form-control campoTextAreaModal"
                            type="text"
                            formControlName="comentario"
                            placeholder="Comentario"
                            rows="6"
                            id="comentario"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col text-danger">
                    <p *ngIf="campoNoValidoFamilia('patologia')"> La Patología es obligatoria</p>
                    <p *ngIf="campoNoValidoFamilia('relacion_familiar')"> La Relación familiar es obligatoria</p>
                    <p *ngIf="campoNoValidoFamilia('comentario')"> El Comentario es obligatoria</p>
                </div>
            </div>
            <br>
            <br>
            <div class="container-fluid">
              <div class="row">
                  <div class="col-md-6">
                      <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                  </div>
                  <div class="col-md-6 letrabotonagregar">
                      <button type="submit" class="btn btn-outline-primary botonModalAgregar">Agregar</button>
                  </div>
              </div>
            </div>
      </form>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Eliminar  ANTECEDENTES FAMILIARES(PATOLOGIAS) NUEVO-->
<div class="modal fade" id="eliminarAntecedenteFamiliar" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal" style="text-align: center;">Atención</p>
       <br><br>
      <div class="form-group row">
        <div class="col-md-12">
          <p class="textoModal">Esta eliminando una patología </p>
        </div>
      </div>
      <br><br>
      <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
                <button type="buton" (click)="eliminaFamilia()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Agregar HOSPITALIZACION NUEVO -->
<div class="modal fade" id="agregarHospitalizacion" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog"> <!--modal-lg-->
  <div class="modal-content">
    <div class="modal-body">
    <br><br>
        <p class="tituloModal">Agregar hospitalizaciones</p>
        <br>
      <div class="">
      <br><br><br>
        <form class="form col-md-12"
                id="HospitalizacionForm"
                autocomplete ="off"
                [formGroup] = "hospitalizacionForm"
                (ngSubmit)="crearHospitalizacion()">
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            placeholder="Motivo"
                            formControlName="motivo"
                            id="motivo">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            onfocus="(this.type='date')"
                            placeholder="Fecha de inicio"
                            formControlName="fecha_inicio"
                            id="fecha_inicio">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            onfocus="(this.type='date')"
                            placeholder="Fecha de término"
                            formControlName="fecha_fin"
                            id="fecha_fin">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <textarea class="form-control campoTextAreaModal"
                            type="text"
                            formControlName="comentario"
                            placeholder="Comentario"
                            rows="6"
                            id="comentario"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col text-danger">
                    <p *ngIf="campoNoValidoHospitalizacion('motivo')"> El motivo es obligatorio</p>
                    <p *ngIf="campoNoValidoHospitalizacion('fecha_inicio')"> La fecha inicio es obligatorio</p>
                    <p *ngIf="campoNoValidoHospitalizacion('fecha_fin')"> La fecha fin es obligatorio</p>
                </div>
            </div>
            <br>
            <br>
            <div class="container-fluid">
              <div class="row">
                  <div class="col-md-6">
                      <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                  </div>
                  <div class="col-md-6 letrabotonagregar">
                      <button type="submit" class="btn btn-outline-primary botonModalAgregar">Agregar</button>
                  </div>
              </div>
            </div>
      </form>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Eliminar HOSPITALIZACION NUEVO-->
<div class="modal fade" id="eliminaHospitalizacion" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal" style="text-align: center;">Atención</p>
       <br><br>
      <div class="form-group row">
        <div class="col-md-12">
          <p class="textoModal">Esta eliminando una hospitalización </p>
        </div>
      </div>
      <br><br>
      <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
                <button type="buton" (click)="eliminaHospitalizacion()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Agregar PATOLOGIA(DIAGNOSTICO) NUEVO-->
<div class="modal fade" id="agregarPatologia" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog"> <!--modal-lg-->
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal">Agregar diagnóstico</p>
        <br>
      <div class="">
      <br><br><br>
        <form class="form col-md-12"
                id="PatologiasForm"
                autocomplete ="off"
                [formGroup] = "patologiasForm"
                (ngSubmit)="crearPatologia()">
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            formControlName="nombre"
                            id="nombre"
                            placeholder="Nombre">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            onfocus="(this.type='date')"
                            placeholder="Fecha de inicio"
                            formControlName="fecha_inicio"
                            id="fecha_inicio">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <textarea class="form-control campoTextAreaModal"
                            type="text"
                            formControlName="comentario"
                            placeholder="Comentario"
                            rows="6"
                            id="comentario"></textarea>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col text-danger">
                    <p *ngIf="campoNoValidoPatologias('nombre')"> El nombre de la patología es obligatorio</p>
                    <p *ngIf="campoNoValidoPatologias('fecha_inicio')"> La fecha inicio es obligatorio</p>
                </div>
            </div>
            <br>
            <br>
            <div class="container-fluid">
              <div class="row">
                  <div class="col-md-6">
                      <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                  </div>
                  <div class="col-md-6 letrabotonagregar">
                      <button type="submit" class="btn btn-outline-primary botonModalAgregar">Agregar</button>
                  </div>
              </div>
            </div>
      </form>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Eliminar PATOLOGIA (DIAGNOSTICO) NUEVO-->
<div class="modal fade" id="eliminarPatologia" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal" style="text-align: center;">Atención</p>
        <br><br>
      <div class="form-group row">
        <div class="col-md-12">
          <p class="textoModal">Esta eliminando un diagnóstico </p>
        </div>
      </div>
      <br><br>
      <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
                <button type="buton" (click)="eliminaPatologia()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal Agregar PATOLOGIA(DIAGNOSTICO) NUEVO-->
<div class="modal fade" id="agregarMedicamento" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog"><!--modal-lg-->
  <div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal">Agregar Medicamentos</p>
        <br>
      <div class="">
      <br><br><br>
        <form class="form col-md-12"
                id="MedicamentosForm"
                autocomplete ="off"
                [formGroup] = "medicamentosForm"
                (ngSubmit)="crearMedicamentos()">
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                             formControlName="medicamento"
                            id="medicamento"
                            placeholder="Nombre del medicamento">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            onfocus="(this.type='date')"
                            placeholder="Fecha de inicio"
                            formControlName="fecha_inicio"
                            id="fecha_inicio">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            formControlName="duracion"
                            id="duracion"
                            placeholder="Duración">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            formControlName="frecuencia"
                            id="frecuencia"
                            placeholder="Frecuencia">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <input class="form-control camposModalAgregar"
                            type="text"
                            formControlName="dosis"
                            id="dosis"
                            placeholder="Dosis">
                </div>
            </div>
            <br>
            <div class="form-group row">
                <div class="col-md-12">
                    <textarea class="form-control campoTextAreaModal letrasCardBeneficiarioBase"
                            type="text"
                            formControlName="comentario"
                            placeholder="Comentario"
                            rows="6"
                            id="comentario"></textarea>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col text-danger">
                    <p *ngIf="campoNoValidoMedicamentos('medicamento')"> El nombre del medicamento es obligatorio</p>
                    <p *ngIf="campoNoValidoMedicamentos('fecha_inicio')"> La fecha inicio es obligatorio</p>
                    <p *ngIf="campoNoValidoMedicamentos('duracion')"> La duracion del tratamiento es obligatoria</p>
                    <p *ngIf="campoNoValidoMedicamentos('frecuencia')"> La frecuencia de uso es obligatoria</p>
                    <p *ngIf="campoNoValidoMedicamentos('dosis')"> La dosis es obligatoria</p>
                </div>
            </div>
            <br>
            <br>
            <div class="container-fluid">
              <div class="row">
                  <div class="col-md-6">
                      <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                  </div>
                  <div class="col-md-6 letrabotonagregar">
                      <button type="submit" class="btn btn-outline-primary botonModalAgregar">Agregar</button>
                  </div>
              </div>
            </div>
      </form>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Modal ELIMINA Medicamento NUEVO -->
<div class="modal fade" id="eliminarMedicamento" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
    <div class="modal-body">
    <br>
        <p class="tituloModal" style="text-align: center;">Atención</p>
        <br><br>
    <div class="form-group row">
        <div class="col-md-12">
        <p class="textoModal">Esta eliminando un medicamento </p>
        </div>
    </div>
    <br><br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
            </div>
            <div class="col-md-6 letrabotonagregar">
                <button type="buton" (click)="eliminaMedicamentos()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
            </div>
        </div>
    </div>
    </div>
</div>
</div>
</div>

<!-- Modal agregar cirugías-->
<div class="modal fade" id="agregarCirugia" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
            <br>
            <p class="tituloModal">Agregar cirugías</p>
            <br>
            <div class="">
            <br><br><br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <input class="form-control camposModalAgregar"
                                    type="text"
                                    placeholder="Nombre"
                                    [(ngModel)]="cirugia_nueva.nombre_cirugia"
                                    id="nombre_cirugia">
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <input class="form-control camposModalAgregar"
                                    type="text"
                                    placeholder="Fecha de la cirugía"
                                    [(ngModel)]="cirugia_nueva.fecha_cirugia"
                                    id="fecha_cirugia">
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <input class="form-control camposModalAgregar"
                                    type="text"
                                    [(ngModel)]="cirugia_nueva.nombre_medico"
                                    placeholder="Nombre del médico"
                                    id="medico_cirugia">
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <textarea class="form-control "
                                    type="text"
                                    rows="8"
                                    [(ngModel)]="cirugia_nueva.comentario"
                                    placeholder="Comentario"
                                    style="width: 100%;"
                                    id="comentario_cirugia"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col text-danger">
                            <p *ngIf="campoNoValidoCirugia('nombre_cirugia')"> El nombre de la cirugía es obligatorio.</p>
                            <p *ngIf="campoNoValidoCirugia('fecha_cirugia')"> La fecha de la cirugía es obligatoria o está en formato incorrecto.</p>
                            <p *ngIf="campoNoValidoCirugia('nombre_medico')"> El nombre del médico es obligatorio.</p>
                            <p *ngIf="campoNoValidoCirugia('comentario')"> El comentario es obligatorio.</p>
                        </div>
                    </div>
                    <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                            <button type="submit" class="btn btn-outline-primary botonModalAgregar" (click)="agregarCirugia()">Agregar</button>
                        </div>
                    </div>
                    </div>
          </div>
        </div>
      </div>
    </div>
</div>

<!-- Modal agregar habito-->
<div class="modal fade" id="agregarHabito" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
              <br>
              <p class="tituloModal">Agregar hábito</p>
              <br>
              <span class="tituloModal" style="font-size: 15px;float:left;margin: 0%;cursor: pointer; display: flex;
    align-items: center; " (click)='agregarHabitoArray()'><mat-icon>add</mat-icon> Agregar hábito</span>
              <div class="">
              <br><br><br>
              <div *ngFor="let item of habito_nueva; let i = index;">
                  <div class="form-group row">
                      <div class="col-md-6">
                        <select name="severidad" placeholder="Nombre" class="form-control" [(ngModel)]="habito_nueva[i].id_habito" [disabled]='!(habito_nueva.length - 1 == i)'>
                            <option value = "" selected>Nombre</option>
                            <option [value]="zona.hab_id" *ngFor="let zona of habitos_list" [disabled]='!zona.disponible'>{{zona.hab_name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                      <select name="severidad" placeholder="Frecuencia" class="form-control" [(ngModel)]="habito_nueva[i].id_frecuencia" [disabled]='!(habito_nueva.length - 1 == i)'>
                          <option value = "" selected>Frecuencia</option>
                          <option [value]="zona.fre_id" *ngFor="let zona of frecuency_list">{{zona.fre_name}}</option>
                      </select>
                  </div>
                </div>
                  <br *ngIf="habito_nueva.length - 1 == i">
                  <div *ngIf="habito_nueva.length - 1 == i" class="form-group row">
                      <div class="col-md-12">
                          <textarea class="form-control "
                                  type="text"
                                  rows="8"
                                  [(ngModel)]="habito_nueva[i].comentario"
                                  placeholder="Comentario"
                                  style="width: 100%;"></textarea>
                      </div>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col text-danger">
                          <p *ngIf="campoNoValidoHabito('id_habito',i)"> El hábito es obligatorio.</p>
                          <p *ngIf="campoNoValidoHabito('id_frecuencia',i)"> La frecuencia es obligatoria.</p>
                          <p *ngIf="campoNoValidoHabito('comentario',i)"> El comentario es obligatorio.</p>
                      </div>
                  </div>
              </div>
                      <div class="container-fluid">
                      <div class="row">
                          <div class="col-md-6">
                              <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Cerrar</button>
                          </div>
                          <div class="col-md-6 letrabotonagregar">
                              <button type="submit" class="btn btn-outline-primary botonModalAgregar" (click)="agregarHabito()">Agregar</button>
                          </div>
                      </div>
                      </div>
            </div>
          </div>
        </div>
      </div>
  </div>

<!-- Modal ELIMINA cirugía -->
<div class="modal fade" id="eliminarCirugia" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <br>
                    <p class="tituloModal" style="text-align: center;">Atención</p>
                    <br><br>
                <div class="form-group row">
                    <div class="col-md-12">
                    <p class="textoModal">Esta eliminando una cirugía </p>
                    </div>
                </div>
                <br><br>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                            <button type="buton" (click)="eliminaCirugia()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal ELIMINA hábito -->
<div class="modal fade" id="eliminarHabito" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <br>
                    <p class="tituloModal" style="text-align: center;">Atención</p>
                    <br><br>
                <div class="form-group row">
                    <div class="col-md-12">
                    <p class="textoModal">Esta eliminando un hábito </p>
                    </div>
                </div>
                <br><br>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-secondary botonVolver colorLetraVolver" data-dismiss="modal">Volver</button>
                        </div>
                        <div class="col-md-6 letrabotonagregar">
                            <button type="buton" (click)="eliminaHabito()" class="btn btn-outline-primary botonModalAgregar">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
