import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-medico',
  templateUrl: './medico.component.html',
  styleUrls: ['./medico.component.css']
})
export class MedicoComponent implements OnInit {

  titulo: any = [
                  "Revisar tus próximas consultas",
                  "Configurar tu agenda de consultas",
                  "Realizar videoconsultas fácilmente",
                  "Ver el historial de consulta y el detalle de cada una",
                  "Ver el historial de consulta y el detalle de cada una"
  ];
  
  subtitulo: any = [
                  "Anularlas o ver el detalle de ellas.",
                  "Habilitando y/o bloqueando tus horarios que más te acomeden.",
                  "Encontrarás el detalle de la consulta, los antecedentes del paciente y más.",
                  "Para que tengas un registro de todas tus atenciones.",
                  "Para que tengas un registro de todas tus atenciones."
  ];
  siguiente: any = [
                  "Siguiente",
                  "Siguiente",    
                  "Siguiente",
                  "Siguiente",
                  "¡Empezemos!"
  ];
  estilo: any = [
                  '#2AC9CE',
                  '#E8E8E8',
                  '#E8E8E8',
                  '#E8E8E8',
                  '#E8E8E8'
  ]
  pagina: any = 0;
  constructor(
    private router: Router,
    private GeneralService : GeneralService
  ) { }


  ngOnInit(): void {
  }

  omitirBoton() {
    this.irDashboard();
  }

  irDashboard(){
    this.router.navigate(['dashboard']) //navigateByUrl('/dashboard');
    setTimeout(() => window.location.reload(), 1000);
  }
  siguienteBoton() {
    this.pagina = this.pagina + 1;
    this.limpiarColores();

  }
  empezemosBoton() {
    // this.pagina = this.pagina + 1;

    this.GeneralService.postRealizaOnBoarding()
      .then(result => {
        //console.log(result);
        this.irDashboard();
      })
      .catch(error => {
        console.log(error);
        this.irDashboard();
      })
    
  }

  limpiarColores() {
    this.estilo = [
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8',
      '#E8E8E8'
    ];

    if (this.pagina <= 4) {
      this.estilo[this.pagina] = '#2AC9CE';
    } else {
      this.estilo[4] = '#2AC9CE';
    }
  }
}

