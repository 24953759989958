<div style="width: 100%;">
     <div style="width: 100%;text-align: -webkit-right;margin:10px;">
          <button class="primary buttonStylePrimary" (click)="addCustomer()">Agregar Cliente</button>
     </div>
     <div class="row" style="width: 100%;">
          <div class="col-3">
               <p class="pTableTitle">Imagen</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Nombre</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Teléfono</p>
          </div>
          <div class="col-3">
               <p class="pTableTitle">URL</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Acción</p>
          </div>
     </div>
     <hr>
     <ng-container *ngFor="let customer of customers">
          <div class="row" style="width: 100%;">
               <div class="col-3">
                    <ng-container *ngIf="customer.cus_url_image_web">
                         <img [src]="customer.cus_url_image_web" width="100%">
                    </ng-container>
                    <ng-container *ngIf="!customer.cus_url_image_web">
                         <p class="pTableNotInfo">No tiene imagen</p>
                    </ng-container>
               </div>
               <div class="col-2">
                    <ng-container *ngIf="customer.cus_name">
                         <p class="pTableInfo">{{customer.cus_name}}</p>
                    </ng-container>
                    <ng-container *ngIf="!customer.cus_name">
                         <p class="pTableNotInfo">No tiene</p>
                    </ng-container>
               </div>
               <div class="col-2">
                    <ng-container *ngIf="customer.cus_url_phone">
                         <p class="pTableInfo">{{customer.cus_url_phone}}</p>
                    </ng-container>
                    <ng-container *ngIf="!customer.cus_url_phone">
                         <p class="pTableNotInfo">No tiene</p>
                    </ng-container>
               </div>
               <div class="col-3">
                    <ng-container *ngIf="customer.cus_url_site">
                         <p class="pTableInfo">{{customer.cus_url_site}}</p>
                    </ng-container>
                    <ng-container *ngIf="!customer.cus_url_site">
                         <p class="pTableNotInfo">No tiene</p>
                    </ng-container>
               </div>
               <div class="col-2" style="text-align: center;">
                    <button type="button" class="btn btn-secondary actionButton" (click)="deleteCustomer(customer)">
                    <mat-icon>delete_outline</mat-icon>
                    </button>
                    <button type="button" class="btn btn-secondary actionButton" (click)="editCustomer(customer)">
                    <mat-icon>edit</mat-icon>     
                    </button>
                    <button type="button" class="btn btn-secondary actionButton" (click)="showProducts(customer)">
                     <mat-icon>list</mat-icon>
                         </button>
               </div>
          </div>
          <hr>
     </ng-container>
</div>
<div class="modal fade hide" id="editCustomer" tabindex="-1" role="dialog" aria-labelledby="editCustomer"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Editar cliente</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-4">
                              <p>Nombre</p>
                              <input type="text" [(ngModel)]="editCustomerSelected.cus_name">
                         </div>
                         <div class="col-4">
                              <p>URL página</p>
                              <input type="text" [(ngModel)]="editCustomerSelected.cus_url_site">
                         </div>
                         <div class="col-4">
                              <p>Teléfono</p>
                              <input type="text" [(ngModel)]="editCustomerSelected.cus_url_phone">
                         </div>
                         <div class="col-12">
                              <p>Medio de pago</p>
                              <input id="checkbox-can" style="border: 2px solid #0E0087;padding-top: 8px;
                              box-sizing: border-box;
                              border-radius: 2px;" type="checkbox" [(ngModel)]="editCustomerSelected.cus_can_pay"
                                   name="check_can">
                              <label for="checkbox-can" style="font-family: Mulish;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 24px;
                                color: #171994;">Se puede hacer pagos en la plataforma</label>
                         </div>
                         <div style="margin: 10px;">
                              <label class="buttonStyleSecondary" style="height: 40px;">Examinar
                                   <input type="file" style="cursor: pointer !important;"
                                        (change)="onFileSelectedEdit($event)" max-file-size="1024" required />
                              </label>
                         </div>
                         <div class="col-12">
                              <ng-container *ngIf="editCustomerSelected.cus_image">
                                   <p>{{editCustomerSelected.cus_image.name}}</p>
                              </ng-container>
                         </div>
                         <div class="col-12">
                              <p>
                                   <strong>El documento no puede ser superior a 20 MB (PDF, JPG o PNG)</strong>
                              </p>
                         </div>
                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="primary buttonStylePrimary"
                                   (click)="confirmEdit()">Editar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>
<div class="modal fade hide" id="deleteCustomer" tabindex="-1" role="dialog" aria-labelledby="deleteCustomer"
     aria-hidden="true">
     <div class="modal-dialog " role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Borrar cliente</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <p class="pTableTitle">¿Está seguro de eliminar al cliente {{deleteSelected.cus_name}}?</p>
                         <p style="display: flex; align-items: center;"><mat-icon>error_outline</mat-icon>Todos los planes asociados a este cliente se
                              deshabilitarán.</p>
                    </div>
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-md-6 modalBody">
                              <button class="primary buttonStyleSecondary" data-toggle="modal"
                                   data-target="#deleteCustomer">Cancelar</button>
                         </div>
                         <div class="col-md-6 modalBody">
                              <button class="primary buttonStylePrimary" (click)="confirmDelete()">Eliminar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="addCustomer" tabindex="-1" role="dialog" aria-labelledby="addCustomer"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Agregar cliente</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-4">
                              <p>Nombre</p>
                              <input type="text" [(ngModel)]="newCustomer.cus_name">
                         </div>
                         <div class="col-4">
                              <p>URL página</p>
                              <input type="text" [(ngModel)]="newCustomer.cus_url">
                         </div>
                         <div class="col-4">
                              <p>Teléfono</p>
                              <input type="text" [(ngModel)]="newCustomer.cus_phone">
                         </div>
                         <div class="col-12">
                              <p>Medio de pago</p>
                              <input id="checkbox-can" style="border: 2px solid #0E0087;padding-top: 8px;
                              box-sizing: border-box;
                              border-radius: 2px;" type="checkbox" [(ngModel)]="newCustomer.cus_can_pay"
                                   name="check_can">
                              <label for="checkbox-can" style="font-family: Mulish;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 24px;
                                color: #171994;">Se puede hacer pagos en la plataforma</label>
                         </div>
                         <div style="margin: 10px;">
                              <label class="buttonStyleSecondary" style="height: 40px;">Examinar
                                   <input type="file" style="cursor: pointer !important;"
                                        (change)="onFileSelected($event)" max-file-size="1024" required />
                              </label>
                         </div>
                         <div class="col-12">
                              <ng-container *ngIf="newCustomer.cus_image">
                                   <p>{{newCustomer.cus_image.name}}</p>
                              </ng-container>
                         </div>
                         <div class="col-12">
                              <p>
                                   <strong>El documento no puede ser superior a 20 MB (PDF, JPG o PNG)</strong>
                              </p>
                         </div>
                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="primary buttonStylePrimary"
                                   (click)="confirmAdd()">Guardar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="showProducts" tabindex="-1" role="dialog" aria-labelledby="showProducts"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Productos <mat-icon (click)="showProduct()">add</mat-icon></h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-4">
                              <p>Producto</p>
                         </div>
                         <div class="col-4">
                              <p>Plan</p>
                         </div>
                         <div class="col-4">
                              <p>Acción</p>
                         </div>
                         <div class="col-12">
                              <hr>
                         </div>
                         <ng-container
                              *ngIf="!customerSelected || !customerSelected.products_list || customerSelected.products_list.length == 0; else products">
                              <div class="not-plans">
                                   <div style="display: flex; flex-direction: row; align-items: center;">
                                        <mat-icon>info</mat-icon>
                                        <span>Este cliente no tiene productos disponibles.</span>
                                   </div>
                              </div>
                         </ng-container>
                         <ng-template #products>
                              <ng-container *ngFor="let product of customerSelected.products_list">
                                   <div class="col-4">
                                        <p>{{product.cus_ben_pro_value}}</p>
                                   </div>
                                   <div class="col-4">
                                        <p>{{product.cus_ben_name}}</p>
                                   </div>
                                   <div class="col-4">
                                        <button type="button" class="btn btn-secondary actionButton"
                                             (click)="deleteProduct(product)"><mat-icon>delete</mat-icon></button>
                                   </div>
                              </ng-container>
                         </ng-template>

                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="primary buttonStylePrimary"
                                   (click)="confirmAdd()">Guardar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="insProducts" tabindex="-1" role="dialog" aria-labelledby="insProducts"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Agregar producto</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">

                         <ng-container
                              *ngIf="customerSelected && customerSelected.planes && customerSelected.planes.length > 0">
                              <div class="col-6">
                                   <p>Producto</p>
                                   <input type="text" [(ngModel)]="newProduct.pro_value">
                              </div>
                              <div class="col-6">
                                   <p>Plan</p>
                                   <select [(ngModel)]="newProduct.cus_ben_id">
                                        <option value="">Plan asociado</option>
                                        <ng-container *ngFor="let plan of customerSelected.planes">
                                             <option [value]="plan.cus_ben_id">{{plan.cus_ben_name}}</option>
                                        </ng-container>
                                   </select>
                              </div>
                         </ng-container>
                         <ng-container
                              *ngIf="!customerSelected || !customerSelected.planes || customerSelected.planes.length == 0">
                              <div class="not-plans">
                                   <div style="display: flex; flex-direction: row; ">
                                        <mat-icon>info</mat-icon>
                                        <span>No se puede agregar productos.</span>
                                   </div>
                              </div>
                         </ng-container>
                         <div class="col-12">
                              <hr>
                         </div>
                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="primary buttonStylePrimary"
                                   (click)="addProduct()">Guardar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<button #editCustomerTri type="button" class="btn d-none" data-toggle="modal" data-target="#editCustomer"></button>
<button #deleteCustomerTri type="button" class="btn d-none" data-toggle="modal" data-target="#deleteCustomer"></button>
<button #addCustomerTri type="button" class="btn d-none" data-toggle="modal" data-target="#addCustomer"></button>
<button #showProductsTri type="button" class="btn d-none" data-toggle="modal" data-target="#showProducts"></button>
<button #insProductsTri type="button" class="btn d-none" data-toggle="modal" data-target="#insProducts"></button>