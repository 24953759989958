import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomService } from 'src/app/services/zoom.service';
import { FunctionsService } from 'src/app/services/functions.service';
import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { VideoWebRtcComponent } from 'src/app/components/video-web-rtc/video-web-rtc.component';

@Component({
  selector: 'app-videoconsulta-acompanante',
  templateUrl: './videoconsulta-acompanante.component.html',
  styleUrls: ['./videoconsulta-acompanante.component.css']
})
export class VideoconsultaAcompananteComponent {

  _title = 'Mi videoconsulta';
  _subtitle = '';
  zoomVideo: any

  @ViewChild(VideoWebRtcComponent) WEB_RTC: VideoWebRtcComponent;
  
  _usuario: any = {};  // usuario actual


  chat: boolean = false;

  consulta: any = {};
  btnIniciar: boolean = false;                //habilita el boton de iniciar videoconsulta
  
  parametros: any;
  datos_consulta: any = {};
  data_cargada = false;
  videoIniciado: any = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _zoomService: ZoomService,
    private _functions: FunctionsService,
    private router: Router, 
  ) {   }



  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo();

    // this.zoomVideo = document.getElementById('video-zoom');  
    sessionStorage.setItem('zoom_ud_video', 'true');

    // let zoomContentDivW = this.zoomVideo.clientWidth;                                
    // this.zoomVideo.style.height = 'calc('+ (zoomContentDivW/1.778) + 'px + 222px)'; 

    if ( this._usuario.typ_id != 2 ){
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
    else {
      this.activatedRoute.params.subscribe(parametros => {
        this.parametros = parametros;
        this.consulta = {
          med_con_id: parametros.idMedCon
        } 
        this.obtenerConsulta(parametros.idConsulta);  
      });
      this.observador();
    }
  }


  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   let zoomContentDivW = this.zoomVideo.clientWidth;                                
  //   this.zoomVideo.style.height = 'calc('+ (zoomContentDivW/1.778) + 'px + 222px)'; 

  // }




  obtenerConsulta(consulta_id) { 
    this._zoomService.getConsulta(consulta_id)
      .then(resultado => {
        this.datos_consulta = resultado;      
        this.datos_consulta.fecha_consulta = this._functions.formatApptDate(new Date(this.datos_consulta.fecha_consulta.substr(0, 10).replaceAll('-', '/')), this.datos_consulta.hora)
        this.datos_consulta.dateText = this.datos_consulta.fecha_consulta.text;
        this.datos_consulta.img = '';
        this.data_cargada = true;
        // this.iniciarVideoconsulta() 
        // await this.iniciarConsulta(resultado);
        this.videoIniciado = true;
    
        //console.log(resultado)    
        this.WEB_RTC.joinRoom(resultado);

      }).catch(error => {
        console.log(error);
      });
  }

  async iniciarConsulta(resultado) {
    await this.sleep(2000);
    this.videoIniciado = true;
    
    //console.log(resultado)    
    this.WEB_RTC.joinRoom(resultado);
  }
  
  async observador() {
    let zoom_ud_video = 'true';
        
    while (zoom_ud_video == 'true') {
      zoom_ud_video = sessionStorage.zoom_ud_video;

      if(zoom_ud_video == 'false'){ //se cerro la consulta en el iframe
        let leaveUrl = 'dashboard';
        this.router.navigate([leaveUrl])
        // //console.log("inactiva");
        // window.location.reload();
        // setTimeout(() => window.location.reload(), 1000);
        
      }
      await this.sleep(2000);
    }
    
  }

  sleep(ms = 0) {
    return new Promise(r => setTimeout(r, ms));
  }
}
  



  


