import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-wrapper-workers',
  templateUrl: './login-wrapper-workers.component.html',
  styleUrls: ['./login-wrapper-workers.component.css']
})
export class LoginWrapperWorkersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
