
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { PaymentService } from 'src/app/services/payment.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {
  @Input() vista_externa: boolean = false; //Se ocupa cuando se llama el componente de un perfil que no es el propio del beneficiario
  @Input() beneficiario_externo: any = false; //Se ocupa cuando se llama el componente de un perfil que no es el propio del beneficiario

  usuario: any = {};  // usuario actual


  _title = 'Mi agenda';
  _subtitle = 'Reserva tu hora'

  subtitle = 'Reserva'

  infoTransaction: any = {};
  constructor(
    public generalService: GeneralService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private paymentService: PaymentService,
    private router: Router,
  ) {

    this.activatedRoute.params.subscribe(parametros => {
      // console.log(parametros);
      if (parametros.idTransaction) {
        this.postInfoTransaction(parametros.idTransaction);
      }
    });

  }

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo()
    if (!this.vista_externa) {
      this.usuario = new EncriptacionService().getUserInfo();
    } else {
      this.usuario = this.beneficiario_externo;
    }
  }

  async postInfoTransaction(buyOrder) {

    this.generalService.modalCargando();
    await this.paymentService.infoTransaction(buyOrder)
      .then((result: any) => {
        // console.log(result);
        Swal.close();
        if (result.boo_sta_id == 90 && result.id_consulta) {
          var url = "reserva-pendiente/" + result.id_consulta;
          this.router.navigate([url]);
        } else if (result.boo_sta_id == 11 && result.id_consulta) {
          var url = "dashboard";
          this.generalService.modalError('Error en pago', 'Hubo un error en el pago de la consulta, intentelo nuevamente.');
          this.router.navigate([url]);
        } else {
          this.infoTransaction = result;
        }
      })
      .catch(error => {
        console.log(error, 'error-infoTransaction');
        var url = "dashboard";
        this.generalService.modalError('Error en pago', 'Hubo un error en el pago de la consulta, intentelo nuevamente.');
        this.router.navigate([url]);
      });

  }

}
