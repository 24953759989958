import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptacionService } from 'src/app/services/encriptacion.service';

@Component({
  selector: 'app-unfinished-appointment',
  templateUrl: './unfinished-appointment.component.html',
  styleUrls: ['./unfinished-appointment.component.css']
})
export class UnfinishedAppointmentComponent implements OnInit {
  @Input() data: any ;

  _usuario: any = {};  // usuario actual

  constructor(private router: Router,) { }

  ngOnInit(): void {
    this._usuario = new EncriptacionService().getUserInfo()
    // //console.log(this.data);
  }

  goConsulta() { 
    var url = "finvideoconsulta/"+this.data.id_consulta;
    this.router.navigate([url]);
  }

}
