import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate, ActivatedRoute, RouterLink, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogVideoComponent } from '../pages/mi-agenda/reserva-hora/confirm-dialog-video/confirm-dialog-video.component';
import { VideoconsultaAcompananteComponent } from '../pages/videoconsulta-acompanante/videoconsulta-acompanante.component';
import { VideoconsultaPacienteComponent } from '../pages/videoconsulta-paciente/videoconsulta-paciente.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateVideoGuard implements CanDeactivate<VideoconsultaPacienteComponent | VideoconsultaAcompananteComponent> {
  constructor(private dialogVideo: MatDialog) {

  }
  canDeactivate(
    component: VideoconsultaPacienteComponent | VideoconsultaAcompananteComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
    
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


// console.log(nextState.url.substring(0,10));

    if(nextState.url.substring(0,10) == '/encuesta/'){
        return true;

    }


    if (ActivatedRoute) {
        // console.log(currentRoute);
        // console.log(currentState);
        // console.log(nextState);
        
        
      const dialogRef = this.dialogVideo.open(ConfirmDialogVideoComponent);
      return dialogRef.afterClosed()
    }
    return (true);

  }

 
}