<app-title-header [title]='_title' [subtitle]='_subtitle'>
</app-title-header>


<div class="not-plans" *ngIf="pendingAppointmentList.length  == 0">
  <div class="d-flex" style="padding: 0 15px;">
    <mat-icon style="color: #171994 ;">info</mat-icon>
    <span>No existen reservas pendientes.</span>
  </div>
</div>




<ng-container *ngIf="pendingAppointmentList.length > 0 &&(usuario.typ_id == 2)">
  <div class="row">
    <div *ngFor="let pendiente of pendingAppointmentList" class="xl-col-6 pt-4 col-lg-6 pt-4 col-sm-12 pt-4">
      <app-next-pending-appointment-dashboard [data]="pendiente"></app-next-pending-appointment-dashboard>
    </div>
  </div>


  </ng-container>