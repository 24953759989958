import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';
import { MantenedorService } from 'src/app/services/mantenedor.service';
import { MedicosService } from 'src/app/services/medicos.service';
import Swal from 'sweetalert2';
import { CompleterService, CompleterData } from 'ng2-completer';
import { FEAService } from 'src/app/services/fea.service';


@Component({
  selector: 'app-admin-medicos',
  templateUrl: './admin-medicos.component.html',
  styleUrls: ['./admin-medicos.component.css']
})
export class AdminMedicosComponent implements OnInit {
  
  constructor() { }
  public page: any = {
    main_page: true,
    edit: false,
    add: false,
  };
  ngOnInit(): void {
  }

  setPage({ main_page = false, edit = false, add = false}) {
    this.page = {
      main_page: main_page,
      add: add,
      edit: edit,
    };
  }

  resetPage() {
    this.page = {
      main_page: true,
      edit: false,
      add: false,
    };
  }
  

}
