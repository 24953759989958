
// Formato de labels del calendario

import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }

//   public monthViewTitle({ date, locale }: DateFormatterParams): string {
//     return formatDate(date, 'MMM y', locale);
//   }

//   public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
//     return formatDate(date, 'EEE', locale);
//   }

//   public dayViewHour({ date, locale }: DateFormatterParams): string {
//     return formatDate(date, 'HH:mm', locale);
//   }
}
