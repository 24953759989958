import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { URL_DOCS } from 'src/app/config/config';
import { AgreementService } from 'src/app/services/agreement.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-otras-prestaciones',
  templateUrl: './otras-prestaciones.component.html',
  styleUrls: ['./otras-prestaciones.component.css']
})
export class OtrasPrestacionesComponent implements OnInit {
  @Input() on_dashboard: boolean = false;
  URL_DOCS = URL_DOCS;
  searchText: string = '';
  private searchTextChanged = new Subject<string>();
  isDesktop: boolean;


  agreements: any[] = [];
  filteredAgreements: any[] = [];
  agreements_formated: any = {
    1: [],
    2: [],
    3: [],
  };

  benefits: any[] = [];
  constructor(
    private agreementService: AgreementService,
    private router: Router,
    private generalServices: GeneralService,
    private breakpointObserver: BreakpointObserver
  ) { 

    this.breakpointObserver.observe([
      Breakpoints.Handset, // Dispositivos móviles
      Breakpoints.Tablet, // Dispositivos de tamaño de tablet
  ]).subscribe(result => {
      // Actualizar la variable isDesktop dependiendo del tamaño de la pantalla
      this.isDesktop = !result.matches;
  });
  }

  chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}




  ngOnInit(): void {
    this.generalServices.modalCargando();
    this.agreementService.agreementTypes({})
      .then((result: any) => {
        // console.log(result);
        if (result.agreementsTypes) {
          this.agreements = result.agreementsTypes;
          this.agreementsFormat({ agreements: result.agreementsTypes });
        }
        if (result.benefits) {
          this.benefits = result.benefits;
        }
        Swal.close();
      })
      .catch(error => {

      })


      this.searchTextChanged.pipe(
        debounceTime(300) // Cambia este valor según tus necesidades
      ).subscribe(() => {
        this.ListarAgreements();
      });
  }

  ListarAgreements() {
    if (this.searchText && this.searchText.trim() !== '') {
      const searchTextLower = this.searchText.toLowerCase();
      this.agreements = this.agreements.filter(agreement =>
        agreement.agre_typ_title.toLowerCase().includes(searchTextLower)
      );
    } else {
          this.filteredAgreements = [...this.agreements];

    }
  }

  // Método para manejar cambios en el texto de búsqueda
  onSearchTextChanged() {
    this.searchTextChanged.next(this.searchText);
  }

  goToAgreement(agreement) {
    // console.log(agreement);
    if (!agreement || !agreement.agre_typ_id_) {
      this.generalServices.modalError('Error', 'No se puede acceder a esa información.');
      return;
    }
    let url = '/otra-prestracion/' + agreement.agre_typ_id_;
    this.router.navigateByUrl(url);
  }

  agreementsFormat({ agreements = [] }) {

    for (let i = 0; i < agreements.length; i++) {
      const element = agreements[i];
      
    }


  }

  goToAgreements(){
    

    let url = '/otras-prestraciones';
    this.router.navigateByUrl(url);
  
  }

}
