import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { URL_SERVICIOS } from '../config/config';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class LicenciaMedicaService {

  constructor(
    public http: HttpClient,
    public router: Router,
    public _errors: ErrorsService,
  ) { }
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postListMedicalLeaveType        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postListMedicalLeaveType() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListMedicalLeaveType', JSON.stringify({}), httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postRequestMedicalLeave        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postRequestMedicalLeave(fecha_inicio_reposo,diagnostico_seleccionado,dias_reposo,tipo_seleccionado,rut_hijo,fecha_nacimiento_hijo, id_consulta) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();

    let params = {
      start_date: fecha_inicio_reposo,
      dia_det_id: diagnostico_seleccionado,
      days: dias_reposo,
      typ_id: tipo_seleccionado,
      son_rut: rut_hijo,
      son_birth_date: fecha_nacimiento_hijo,
      app_boo_id: id_consulta
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postRequestMedicalLeave', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postRequestMedicalLeave');
      //this._errors.requestError(error)
      throw error;
    }
  }
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postCreateMedicalLeave        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postCreateMedicalLeave(fecha_inicio_reposo,diagnostico_seleccionado,dias_reposo,tipo_seleccionado,rut_hijo,fecha_nacimiento_hijo, id_consulta) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();
    let params = {
      start_date: fecha_inicio_reposo,
      dia_det_id: diagnostico_seleccionado,
      days: dias_reposo,
      typ_id: tipo_seleccionado,
      son_rut: rut_hijo,
      son_birth_date: fecha_nacimiento_hijo,
      app_boo_id: id_consulta
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postCreateMedicalLeave', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postCreateMedicalLeave');
      //this._errors.requestError(error)
      throw error;
      
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postGenerateMedicalLeave        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postGenerateMedicalLeave(fecha_inicio_reposo,dias_reposo,tipo_seleccionado,rut_hijo,fecha_nacimiento_hijo, id_consulta,med_lea_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();

    let params = {
      start_date: fecha_inicio_reposo,
      days: dias_reposo,
      typ_id: tipo_seleccionado,
      son_rut: rut_hijo,
      son_birth_date: fecha_nacimiento_hijo,
      app_boo_id: id_consulta,
      med_lea_id: med_lea_id
    }

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postGenerateMedicalLeave', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postCreateMedicalLeave');
      //this._errors.requestError(error)
      throw error;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postListMedicalLeaves        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postListMedicalLeaves(f_desde_no_finalizadas,f_hasta_no_finalizadas,f_desde_finalizadas,f_hasta_finalizadas) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {
      f_desde_no_finalizadas: f_desde_no_finalizadas,
      f_hasta_no_finalizadas: f_hasta_no_finalizadas,
      f_desde_finalizadas: f_desde_finalizadas,
      f_hasta_finalizadas: f_hasta_finalizadas,
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListMedicalLeaves', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postListMedicalLeaves');
      
      //this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postListAvailableMedicalLeaves        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postListAvailableMedicalLeaves() {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    let params = {};

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postListAvailableMedicalLeaves', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postListAvailableMedicalLeaves');
      
      //this._errors.requestError(error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postAssignMedicalLeave        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postAssignMedicalLeave(doc_lme_ava_id,med_lea_id,doc_use_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();

    let params = {
      doc_lme_ava_id: Number(doc_lme_ava_id),
      med_lea_id: Number(med_lea_id),
      doc_use_id: Number(doc_use_id),
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postAssignMedicalLeave', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postListAvailableMedicalLeaves');
      
      //this._errors.requestError(error)
      throw error;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
  ////////////////////////////////////////////////////////////////////       postDeleteMedicalLeave        /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async postDeleteMedicalLeave(med_lea_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'authorization': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      })
    };

    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 100000
    })
    Swal.showLoading();

    let params = {
      med_lea_id: Number(med_lea_id)
    };

    try {
      let data = await this.http.post(URL_SERVICIOS + '/postDeleteMedicalLeave', params, httpOptions ).toPromise()
      return data
    }
    catch (error) {
      //console.log(error,'error-postListAvailableMedicalLeaves');
      
      //this._errors.requestError(error)
      throw error;
    }
  }
}
