<div class="row">

    <div class="col-md-6">
        
        <app-dona title="Ventas"></app-dona>

    </div>
    <div class="col-md-6">
        
        <app-dona [labels]="labels1"
                    [data]="data1"
                    title="Preferencias">
        </app-dona> <!-- le paso el nombre de los labels definidos en el fraficas1.ts al componente don-->

    </div>
    <div class="col-md-6">
        
        <app-dona></app-dona>

    </div>
    <div class="col-md-6">
        
        <app-dona></app-dona>

    </div>

</div>