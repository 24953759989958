import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AntecedentesMedicosService } from '../../services/antecedentes_medicos.service';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FichaMedicaService } from '../../services/ficha-medica.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { formatDate } from '@angular/common';
import { CustomerService } from 'src/app/services/customer.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { log } from 'console';

@Component({
  selector: 'app-estado-consulta',
  templateUrl: './estado-consulta.component.html',
  styleUrls: ['./estado-consulta.component.css']
})
export class EstadoConsultaComponent implements OnInit {
  @ViewChild('triModificarEstadoConsulta') triModificarEstadoConsulta: ElementRef;
  @ViewChild('triAceptaModificarStatus') triAceptaModificarStatus: ElementRef;

  usuario: any = {};
  estados_dia: any = {};
  resultados_busqueda: any = {};

  estado_consulta: any = '';
  estados_consulta: any = [];
  consultas_descarga: any = [];

  lista_especialidades: any = [];

  info_input: any = '';
  f_desde: any = '';
  f_hasta: any = '';
  area_medica: any = '';
  consulta_selected: any = {
    conexiones_paciente: [{
      zoo_mee_tim_device: false,
      zoo_mee_tim_start_lobby: false,
      zoo_mee_tim_start_meeting: false
    }]
  };

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  dropdownListArea = [];
  selectedItemsArea = [];
  dropdownSettingsArea: IDropdownSettings = {};


  dropdownListCustomer = [];
  selectedItemsCustomer = [];
  dropdownSettingsCustomer: IDropdownSettings = {};

  typifications: any[] = [];
  typificationSelected: any = {};

  public statusAppo = this.fb.group({
    status: ['', [Validators.required]],
    reason: ['', [Validators.required]],
  });



  constructor(private fb: FormBuilder,
    private _fichaMedicaServices: FichaMedicaService,
    public antecedentesMedicos: AntecedentesMedicosService,
    public generalServices: GeneralService,
    private customerService: CustomerService,
    private scheduleService: ScheduleService,
    private renderer: Renderer2,

  ) { }


  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    // console.log(this.usuario);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'sta_id',
      textField: 'sta_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 2
    };
    this.dropdownSettingsArea = {
      singleSelection: false,
      idField: 'spe_id',
      textField: 'spe_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.dropdownSettingsCustomer = {
      singleSelection: false,
      idField: 'cus_ben_id',
      textField: 'cus_ben_name',
      selectAllText: 'Marcar todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.obtenerEstadosConsultas();
    this.obtenerListaEspecialidades();
    this.postListarCustomer();
    this.actualizarData();
    if (this.usuario.typ_id == 8) {
      this.typificationsAppointment();
    }
  }

  onItemSelect(item: any) {
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  onItemSelectArea(item: any) {
    // console.log(item);
  }
  onSelectAllArea(items: any) {
    // console.log(items);
  }

  onItemSelectCustomer(item: any) {
    //console.log(item);
  }
  onSelectAllCustomer(items: any) {
    //console.log(items);
  }

  typificationsAppointment() {

    this.scheduleService.typificationsAppointment()
      .then((result: any) => {
        // console.log(result);
        this.typifications = result;
      }).catch(error => {
        console.log(error);
      });
  }

  postListarCustomer() {
    this.customerService.postListarCustomer({})
      .then((data: any) => {
        for (let index_cus = 0; index_cus < data.length; index_cus++) {
          const customer = data[index_cus];
          for (let index_ben = 0; index_ben < customer.planes.length; index_ben++) {
            let benefits = customer.planes[index_ben];
            benefits.cus_ben_name = customer.cus_name + ' - ' + benefits.cus_ben_name;
            this.dropdownListCustomer.push(benefits);
          }
        }
        // console.log(this.dropdownListCustomer);
      })
      .catch(e => console.error(e));
  }

  Exportar() {
    //console.log('Exportar');
    Swal.fire({
      icon: 'info',
      title: 'Espere por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000
    })
    Swal.showLoading();
    if (this.consultas_descarga.length == 0) {
      Swal.fire({
        icon: 'info',
        text: 'Sin datos disponibles',
        timer: 2000
      })
    } else {
      Swal.close();
      this.downloadFile(this.consultas_descarga);
    }
  }
  Listar() {
    //console.log('Listar');
    let fecha_desde_post = null;
    let fecha_hasta_post = null;
    try {
      fecha_desde_post = this.validarFecha(this.f_desde);
      fecha_hasta_post = this.validarFecha(this.f_hasta);
    } catch (error) {
      console.log(error);
      this.modalError('Formato fecha malos', 'Por favor escribir una fecha valida.');
      return;
    }
    // console.log(fecha_desde_post);
    // console.log(fecha_hasta_post);

    this._fichaMedicaServices.getListarEstadoConsultasDia(this.arrayStringStatus(this.selectedItems), fecha_desde_post, fecha_hasta_post, this.arrayStringSpeciality(this.selectedItemsArea), this.arrayStringCustomer(this.selectedItemsCustomer))
      .then((data: any) => {
        this.estados_dia = data.consultas
        // console.log(this.estados_dia, 'Estado');
        this.filtrarData();
        Swal.close();
      })
      .catch(error => {
        console.log(error);
      });
  }

  cambiaFiltroEstado() {
    //console.log(this.estado_consulta);  
  }

  obtenerEstadosConsultas() {
    this.generalServices.getAppBooStatus()
      .then((results: any) => {
        this.estados_consulta = results;
        this.dropdownList = results;
      })
      .catch(error => {
        console.log(error);
      });
  }

  obtenerListaEspecialidades() {
    this.generalServices.getSpecialityList()
      .then((data: any) => {
        if (data !== null && data.length > 0) {
          this.lista_especialidades = data;
          this.dropdownListArea = data;
        }
      })
      .catch(e => console.error(e));
  }

  validarFecha(fecha) {
    if (fecha.length > 0) {
      let array_fecha = fecha.split('/');
      array_fecha[0] = array_fecha[0].padStart(2, '0');
      array_fecha[1] = array_fecha[1].padStart(2, '0');
      array_fecha[2] = array_fecha[2].padStart(4, '20');
      //console.log(array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0]);
      let nueva_fecha = new Date(array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0]);
      if (nueva_fecha.toString() != 'Invalid Date') {
        return array_fecha[2] + array_fecha[1] + array_fecha[0];
      } else {
        throw new Error("Fecha Invalida");
      }
    }
    return '';
  }

  downloadFile(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // majear valore nulos
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, "estado_consultas.csv");
  }

  clickConsulta(item) {
    // console.log(item);
    this.consulta_selected = item;
    let typifications = this.typifications.slice();
    for (let index = 0; index < typifications.length; index++) {
      const element = typifications[index];
      if (element.sta_name == item.estado_consulta) {
        // console.log(element);
        this.typificationSelected = element;
      }
    }
  }

  modalError(titulo, detalle) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: detalle,
      timer: 5000
    })
  }

  filtrarData() {
    let lista_filtrada = [];
    this.consultas_descarga = [];
    for (let index = 0; index < this.estados_dia.length; index++) {
      const element = this.estados_dia[index];
      // console.log(element);
      if (
        element.nombre_paciente.toUpperCase().includes(this.info_input.toUpperCase())
        || element.nombre_doctor.toUpperCase().includes(this.info_input.toUpperCase())
        || element.id_consulta.toString().toUpperCase().includes(this.info_input.toUpperCase())
      ) {
        if (element.conexiones_paciente && element.conexiones_paciente[0] && element.conexiones_paciente[0].zoo_mee_tim_device && element.conexion_medico) {
          element.estado_consulta_limpio = 'SI';
        } else {
          element.estado_consulta_limpio = 'NO';
        }
        this.consultas_descarga.push(this.newElement(element));
        lista_filtrada.push(element);
      }
    }
    this.estados_dia = lista_filtrada;
    // console.log(this.estados_dia, 'estados_dia-filtrarData');
    // console.log(this.consultas_descarga,'consultas_descarga-filtrarData');
  }

  async actualizarData() {
    while (true) {
      this.Listar();
      await this.generalServices.sleep(1000 * 60 * 5);
    }
  }

  ListarLoading() {
    this.generalServices.modalCargando();
    this.Listar();
  }

  newElement(oldElement: any) {
    let newElement = {
      id_consulta: oldElement.id_consulta,
      area_medica: oldElement.area_medica,
      fecha_consulta: oldElement.fecha_consulta,
      hora_consulta: oldElement.hora,
      motivo_consulta: oldElement.motivo_consulta,
      estado_consulta: oldElement.estado_consulta,
      dispositivo_paciente: (oldElement.conexiones_paciente && oldElement.conexiones_paciente[0] && oldElement.conexiones_paciente[0].zoo_mee_tim_device) ? oldElement.conexiones_paciente[0].zoo_mee_tim_device : '-',
      conexion_paciente: (oldElement.conexiones_paciente && oldElement.conexiones_paciente[0]) ? 'SI' : 'NO',
      conexion_medico: (oldElement.conexion_medico) ? 'SI' : 'NO',
      hora_conexion_paciente: oldElement.conexiones_paciente ? this.filterConectionDate(oldElement.conexiones_paciente) : '-',
      hora_conexion_medico: (oldElement.conexiones_medico && oldElement.conexiones_medico[0] && oldElement.conexiones_medico[0].zoo_mee_tim_start_meeting) ? formatDate(oldElement.conexiones_medico[0].zoo_mee_tim_start_meeting, 'HH:mm:ss', 'en-US') : '-',
      nombre_paciente: oldElement.nombre_paciente,
      rut_paciente: oldElement.rut_paciente,
      nacimiento_paciente: oldElement.fecha_nacimiento_paciente,
      telefono_paciente: oldElement.telefono_paciente,
      correo_paciente: oldElement.email_paciente,
      tipo_beneficiario: oldElement.tipo_paciente,
      nombre_medico: oldElement.nombre_doctor,
      rut_medico: oldElement.rut_doctor,
      telefono_medico: oldElement.telefono_doctor,
      correo_medico: oldElement.email_doctor,
      atencion_medico_encuesta: (oldElement.encuestas && oldElement.encuestas[0] && oldElement.encuestas[0].sur_score_doctor_attention) ? oldElement.encuestas[0].sur_score_doctor_attention : '-',
      calidad_llamada_encuesta: (oldElement.encuestas && oldElement.encuestas[0] && oldElement.encuestas[0].sur_score_doctor_attention) ? oldElement.encuestas[0].sur_score_video_quality : '-',
      usabilidad_plataforma_encuesta: (oldElement.encuestas && oldElement.encuestas[0] && oldElement.encuestas[0].sur_score_doctor_attention) ? oldElement.encuestas[0].sur_score_platform : '-',
      nps: (oldElement.encuestas && oldElement.encuestas[0] && oldElement.encuestas[0].sur_nps) ? oldElement.encuestas[0].sur_nps : '-',
      comentario_encuesta: (oldElement.encuestas && oldElement.encuestas[0] && oldElement.encuestas[0].sur_score_doctor_attention) ? oldElement.encuestas[0].sur_comment : '-',
      perfil_anulacion: (oldElement.perfil_cancelacion) ? oldElement.perfil_cancelacion : '-',
      medio_anulacion: (oldElement.medio_anulacion) ? ((oldElement.medio_anulacion == 7) ? 'Contact center' : 'Plataforma') : '-',
      fecha_anulacion: (oldElement.fecha_cancelacion) ? formatDate(oldElement.fecha_cancelacion, 'dd-MM-yyyy', 'en-US') : '-',
      hora_anulacion: (oldElement.fecha_cancelacion) ? formatDate(oldElement.fecha_cancelacion, 'HH:mm:ss', 'en-US') : '-',
      motivo_anulacion: (oldElement.motivo_cancelacion) ? oldElement.motivo_cancelacion : '-',
      fecha_agendamiento: (oldElement.fecha_agendamiento) ? formatDate(oldElement.fecha_agendamiento, 'dd-MM-yyyy', 'en-US') : '-',
      hora_agendamiento: (oldElement.fecha_agendamiento) ? formatDate(oldElement.fecha_agendamiento, 'HH:mm:ss', 'en-US') : '-',
      perfil_agendamiento: oldElement.perfil_agendamiento,
      ejecutivo_agendamiento: (oldElement.nombre_agendamiento && (oldElement.id_perfil_agendamiento == 7 || oldElement.id_perfil_agendamiento == 8)) ? oldElement.nombre_agendamiento : '-',
      estado_confirmacion: (oldElement.fecha_confirmacion) ? 'SI' : 'NO',
      canal_confirmacion: (oldElement.medio_confirmacion) ? oldElement.medio_confirmacion : '-',
      fecha_confirmacion: (oldElement.fecha_confirmacion) ? formatDate(oldElement.fecha_confirmacion, 'dd-MM-yyyy', 'en-US') : '-',
      hora_confirmacion: (oldElement.fecha_confirmacion) ? formatDate(oldElement.fecha_confirmacion, 'HH:mm:ss', 'en-US') : '-'
    }
    // console.log(newElement);
    return newElement;
    
    
  }

  filterConectionDate(conexiones) {
    let hora = '';
    hora = (conexiones[0]) ? (conexiones[0].zoo_mee_tim_start_lobby ? conexiones[0].zoo_mee_tim_start_lobby.substring(0, 8) : conexiones[0].zoo_mee_tim_start_meeting ? formatDate(conexiones[0].zoo_mee_tim_start_meeting, 'HH:mm:ss', 'en-US') : '-') : '-'
    return hora;
  }

  arrayStringStatus(arrayStatus) {
    let textArray = [];
    for (let index = 0; index < arrayStatus.length; index++) {
      textArray.push(arrayStatus[index].sta_id);
    }
    return (arrayStatus.length > 0 ? textArray.toString() : null);
  }

  arrayStringCustomer(arrayStatus) {
    let textArray = [];
    for (let index = 0; index < arrayStatus.length; index++) {
      textArray.push(arrayStatus[index].cus_ben_id);
    }
    return (arrayStatus.length > 0 ? textArray.toString() : null);
  }

  arrayStringSpeciality(arraySpeciality) {
    let textArray = [];
    for (let index = 0; index < arraySpeciality.length; index++) {
      textArray.push(arraySpeciality[index].spe_id);
    }
    return (arraySpeciality.length > 0 ? textArray.toString() : null);
  }


  changeStatus() {
    // console.log(this.statusAppo.value);

    if (!this.statusAppo.value.status || !this.statusAppo.value.reason) {
      this.generalServices.modalError('Error al modificar consulta', 'Falta completar información');
      return;
    }

    this.renderer.selectRootElement(this.triModificarEstadoConsulta.nativeElement).click();
    this.renderer.selectRootElement(this.triAceptaModificarStatus.nativeElement).click();
    // @ViewChild('triModificarEstadoConsulta') triModificarEstadoConsulta: ElementRef;
    // @ViewChild('triAceptaModificarStatus') triAceptaModificarStatus: ElementRef;

  }

  confirmChangeStatus() {

    // console.log(this.statusAppo.value);
    if (!this.statusAppo.value.status || !this.statusAppo.value.reason) {
      this.generalServices.modalError('Error al modificar consulta', 'Falta completar información');
      return;
    }

    this.generalServices.modalCargando();

    this.scheduleService.updateStateAppointment(this.consulta_selected.id_consulta, this.statusAppo.value.status, this.statusAppo.value.reason)
      .then(result => {
        this.renderer.selectRootElement(this.triAceptaModificarStatus.nativeElement).click();
        this.generalServices.modalExitoso('Estado cambiado', 'El estado de la consulta ha sido cambiado.');
        this.statusAppo.controls['status'].setValue('');
        this.statusAppo.controls['reason'].setValue('');
        this.Listar();
      }).catch(error => {
        console.log(error);
        this.renderer.selectRootElement(this.triAceptaModificarStatus.nativeElement).click();
        // this.generalServices.modalError('Error al modificar consulta', 'Error al modificar el estado de la consulta');
        this.Listar();
      });

  }

}
