import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CompleterService, LocalData } from 'ng2-completer';
import { URL_DOCS } from 'src/app/config/config';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { FEAService } from 'src/app/services/fea.service';
import { GeneralService } from 'src/app/services/general.service';
import { MedicosService } from 'src/app/services/medicos.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  @ViewChild('insModalFile') insModalFile: ElementRef;
  URL_DOCUMENTS = URL_DOCS;

  public usuario: any;


  userTypes: any[] = [];
  specialities: any[] = [];

  multiSelectSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'use_typ_id',
    textField: 'use_typ_name',
    selectAllText: 'Marcar todos',
    unSelectAllText: 'Desmarcar todos',
    itemsShowLimit: 2
  };

  multiSelectSpeSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'sub_spe_spe_id',
    textField: 'spe_name',
    selectAllText: 'Marcar todos',
    unSelectAllText: 'Desmarcar todos',
    itemsShowLimit: 2
  };

  searchTitle: string = '';
  searchInstitution: string = '';

  @Input() newUser: any = {
    use_id: "",
    name: '',
    p_surname: '',
    m_surname: '',
    rut: '',
    mail: '',
    phone: '',
    gender: '',
    user_types: [],
    specialities: [],
    image: null,
    sign: null,
    sis_doctor: '',
    id_institution: '',
    id_title: '',
    egress_date: '',
    birth_date: '',
    has_fea: false,
    has_lme: false,
  };

  subspecialitys_data: any[] = [];
  subspecialitys: LocalData;
  titles_data: any[] = [];
  titles: LocalData;
  searchStrT: string = '';
  institutions_data: any[] = [];
  institutions: LocalData;

  file_type: any = '';

  constructor(
    private generalService: GeneralService,
    private renderer: Renderer2,
    private usuarioService: UsuarioService,
    private completerService: CompleterService,
    private feaServices: FEAService
  ) { }

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();
    
    this.userTypeList();
    this.postListTitle();
    this.getCompleteSubSpecialityList();
    this.postListInstitution();
  }

  async postListTitle() {
    this.generalService.postListTitle()
      .then((results: any) => {
        this.titles_data = results.consulta;
        for (let tit of this.titles_data) {
          tit.descripcion = 'Título: ' + tit.tit_name;
          if (this.newUser && this.newUser.id_title && this.newUser.id_title == tit.tit_id) {
            this.searchTitle = tit.descripcion;
          }
        }
        this.titles = this.completerService.local(this.titles_data, 'descripcion', 'descripcion')
        //console.log(this.titles);
      })
      .catch(error => {
        this.generalService.modalError('Error al cargar las comunas.', 'Error al cargar las comunas.');
      })
  }
  async getCompleteSubSpecialityList() {
    this.generalService.getCompleteSubSpecialityList()
      .then((results: any) => {
        this.subspecialitys_data = results;
        //console.log(this.subspecialitys_data);
        for (let sub of this.subspecialitys_data) {
          sub.descripcion = 'Especialidad: ' + sub.spe_name + '  |  Sub-Especialidad: ' + sub.sub_spe_name;
        }
        this.subspecialitys = this.completerService.local(this.subspecialitys_data, 'descripcion', 'descripcion')
      })
      .catch(error => {
        this.generalService.modalError('Error al cargar las especialidades.', 'Error al cargar las especialidades.');
      })

  } async postListInstitution() {
    this.generalService.postListInstitution()
      .then((results: any) => {
        this.institutions_data = results.consulta;
        for (let ins of this.institutions_data) {
          ins.descripcion = 'Institución: ' + ins.ins_name + '  |    País: ' + ins.cou_name;
          if (this.newUser && this.newUser.id_institution && this.newUser.id_institution == ins.ins_id) {
            this.searchInstitution = ins.descripcion;
          }
        }
        this.institutions = this.completerService.local(this.institutions_data, 'descripcion', 'descripcion')
      })
      .catch(error => {
        this.generalService.modalError('Error al cargar las instituciones.', 'Error al cargar las instituciones.');
      })
  }

  async userTypeList() {
    this.generalService.modalCargando();
    this.userTypes = [];
    // console.log(this.usuario);
    
    try {

      const result: any = await this.usuarioService.userTypeList();

      if (result && result.user_types) {
        this.userTypes = result.user_types;
      }

      if (this.usuario.typ_id == 8) {
        if (result && result.user_types) {
          this.userTypes = result.user_types.filter(userTypes => {
            return userTypes.use_typ_id == 1;
          });
        }
      }
  
      Swal.close();
    } catch (error) {
      console.error(error);
      Swal.close();
    }
  }
  

  onItemSelectArea(event) {
    // console.log(event);    

  }
  onSelectAllArea(items: any) {
    // console.log(items);
  }
  onDropDownClose(event) {
    // console.log(this.newUser);
  }

  isDoctor() {
    if (this.newUser && this.newUser.user_types && this.newUser.user_types.length > 0) {
      for (let i = 0; i < this.newUser.user_types.length; i++) {
        const element = this.newUser.user_types[i];
        if (element.use_typ_id == 1) {
          return true;
        }
      }
    }
    return false;
  }

  changeTitle(event) {
    // console.log(event);
    if (event && event.originalObject && event.originalObject.tit_id) {
      this.newUser.id_title = event.originalObject.tit_id;
    }
  }

  changeInstitution(event) {
    // console.log(event);
    if (event && event.originalObject && event.originalObject.ins_id) {
      this.newUser.id_institution = event.originalObject.ins_id;
    }
  }

  changeSpe(event) {
    // console.log(event);
  }

  showModalFile(file_type: string) {
    this.renderer.selectRootElement(this.insModalFile.nativeElement).click();
    this.file_type = file_type;
  }

  onFileSelected(event) {
    // console.log(event);
    // console.log(this.file_type);
    this.newUser[this.file_type] = null;

    if (!event || !event.target || !event.target.files || !event.target.files[0] || !this.file_type) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido');
    }
    let image = event.target.files[0];

    if (!this.generalService.verifyExtension(image.name, ['png', 'jpg', 'jpeg'])) {
      this.generalService.modalError('Selecione otro documento', 'Documento no válido, elija un documento jpg, jpge o png.');
      return;
    }
    this.renderer.selectRootElement(this.insModalFile.nativeElement).click();
    this.newUser[this.file_type] = event.target.files[0];
  }

  deleteFile(file_type) {
    if (!file_type) {
      this.generalService.modalError('Error', 'Error en formato de archivo.');
      return;
    }
    this.newUser[file_type] = null;
  }
  //////////////////////////////////////////////////////////////////////////////

  resetUser() {
    this.newUser = {
      use_id: "",
      name: '',
      p_surname: '',
      m_surname: '',
      rut: '',
      mail: '',
      phone: '',
      gender: '',
      user_types: [],
      specialities: [],
      image: null,
      sign: null,
      sis_doctor: '',
      id_institution: '',
      id_title: '',
      egress_date: '',
      birth_date: '',
      has_fea: false,
      has_lme: false,
    };
    this.searchTitle = '';
    this.searchInstitution = '';
  }

  cancel() {

  }

  createUser() {
    // console.log(this.newUser);
    this.generalService.modalCargando();
    let evalConditions = this.validData(this.newUser);
    if (!evalConditions.status) {
      this.generalService.modalError('Error al crear usuario', evalConditions.msg);
      return;
    }
    this.usuarioService.createUser(this.newUser)
      .then((result: any) => {
        // console.log(result);
        if (this.newUser.use_id) {
          this.generalService.modalExitoso('Modificado exitosamente', result && result.msg ? result.msg : 'Usuario modificado exitosamente');
        } else {
          this.generalService.modalExitoso('Creado exitosamente', result && result.msg ? result.msg : 'Usuario creado exitosamente');
        }
      })
      .catch(error => {
        // console.log(error);
        if (this.newUser.use_id) {
          this.generalService.modalError('Error', error && error.error && error.error.msg ? error.error.msg : 'Error al modificar usuario');
        } else {
          this.generalService.modalError('Error', error && error.error && error.error.msg ? error.error.msg : 'Error al crear usuario');
        }
      })
  }

  conditions: any[] = [
    { evalCondition: 'data.name && data.name.length > 2', msg: 'Nombre no válido.' },
    { evalCondition: 'data.p_surname && data.p_surname.length > 2', msg: 'Apellido no válido.' },
    { evalCondition: 'data.rut && this.generalService.validaRut(data.rut)', msg: 'Rut no válido.' },
    { evalCondition: 'data.mail && this.generalService.validaEmail(data.mail)', msg: 'Email no válido.' },
    { evalCondition: 'data.birth_date && data.birth_date < new Date()', msg: 'Fecha nacimiento no válida.' },
    { evalCondition: 'data.phone && data.phone.length == 9', msg: 'Telefono no válido.' },
    { evalCondition: 'data.gender && data.gender.length > 2', msg: 'Género no válido.' },
    { evalCondition: 'data.user_types && data.user_types.length > 0', msg: 'Perfiles no válidos.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.image)', msg: 'Imagen no válida.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.sign)', msg: 'Imagen no válida.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.egress_date && data.egress_date < new Date())', msg: 'Fecha egreso no válida.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.id_title)', msg: 'Título no válido.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.id_institution)', msg: 'Institución no válida.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.specialities && data.specialities.length > 0)', msg: 'Especialidades no válidas.' },
    { evalCondition: '!this.isDoctor() || ( this.isDoctor() && data.sis_doctor && data.sis_doctor.length > 2)', msg: 'SIS no válido.' },
  ];

  validData(data) {
    let response = { status: false, msg: 'Error no identificado' };
    try {
      for (let i = 0; i < this.conditions.length; i++) {
        const element = this.conditions[i];
        if (!eval(element.evalCondition)) {
          response.msg = element.msg;
          return response;
        }
      }
      response.msg = 'Exitoso';
      response.status = true;
      return response;
    } catch (error) {
      return response;
    }
  }
}
