
import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
// import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { 
  CalendarEvent, 
  CalendarMonthViewDay,
  CalendarView, 
  CalendarDateFormatter,
  DAYS_OF_WEEK } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';


@Component({
  selector: 'app-calendar-month',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})


export class CalendarMonthComponent {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  // @Input() eventos: any; 
  @Output() messageEvent = new EventEmitter<CalendarMonthViewDay>();
  @Output() messageMonth = new EventEmitter<any>(); 

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SUNDAY] //[DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  clickedDate: Date;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  activeDayIsOpen: boolean = false; //def: true
  selectedDay: CalendarMonthViewDay;   //dia-calendio seleccionado

  dia_inicial = ''; //dia con el que se inicia el calendario
  month_calendar: any = {
                          year: 0,
                          month: 0
                        };

  constructor() { }

  ngOnInit(): void {
    let hoy = new Date();
    this.month_calendar = {
                            year: hoy.getFullYear(),
                            month: hoy.getMonth() +1
    }
    //console.log(this.month_calendar);
  }

  // Click sobre el dia. Muestra los eventos del dia
  dayEnabledClicked(day: CalendarMonthViewDay): void {
    this.dia_inicial = '';
    if ( this.selectedDay ){
      delete this.selectedDay.cssClass;     //si hay alguno clickeado, le saca la clase
    }
    day.cssClass = 'amc-cal-day-selected'; //agrego clase para que marque

    this.selectedDay = day;
    this.messageEvent.emit(day) //devuelvo el dia formato CalendarMonthViewDay {date: date, events: [{start,title},]}
  }

  // hours: {
  //     start: new Date(eve.sch_date), //fecha de la hora
  //     title: eve.sch_id,             //id de la fecha
  //   },
  sendToCalendar(hours, dia_inicial: Date = null) {
    this.events = hours;
    // //console.log(hours);
    
    if(dia_inicial){
      this.dia_inicial = `${dia_inicial.getFullYear()}${((dia_inicial.getMonth() +101).toString()).substring(1, 3)}${((dia_inicial.getDate() + 100).toString()).substring(1, 3)}`; //'yyyymmdd'
    }
    this.refresh.next();
  }


  //////////////////// Funciones ligadas a los botenes prev y next
  nextPrev(){
    if (this.month_calendar.month==1) {
      this.month_calendar = {
        year: this.month_calendar.year-1,
        month: 12
      }
    } else{     
      this.month_calendar = {
        year: this.month_calendar.year,
        month: this.month_calendar.month-1
      }
    }
    // //console.log(this.month_calendar);
    this.messageMonth.emit(this.month_calendar)
  }

  
  nextMes(){
    if (this.month_calendar.month==12) {
      this.month_calendar = {
        year: this.month_calendar.year+1,
        month: 1
      }
    } else{     
      this.month_calendar = {
        year: this.month_calendar.year,
        month: this.month_calendar.month+1
      }
    }
    // //console.log(this.month_calendar);
    this.messageMonth.emit(this.month_calendar)
  }

  // nextMes(){
  //   let fecha = new Date(this.month_calendar.substr(0,4)+'-'+this.month_calendar.substr(4)+'-10'); 
  //   fecha.setMonth(fecha.getMonth() + 1);
  //   this.month_calendar = (fecha.getFullYear()*100 + (fecha.getMonth() +1)).toString();
  //   this.getHorasMes(this.id_doctor, this.month_calendar);
  // }

  // prevMes(){
  //   let fecha = new Date(this.month_calendar.substr(0,4)+'-'+this.month_calendar.substr(4)+'-10'); 
  //   fecha.setMonth(fecha.getMonth() - 1);
  //   this.month_calendar = (fecha.getFullYear()*100 + (fecha.getMonth() +1)).toString();
  //   this.getHorasMes(this.id_doctor, this.month_calendar);
  // }

  // marca un dia del calendario
  click_day(dia: Date){
    this.dia_inicial = `${dia.getFullYear()}${((dia.getMonth() +101).toString()).substring(1, 3)}${((dia.getDate() + 100).toString()).substring(1, 3)}`;
    this.refresh.next();
  }

  //funcion que se ejecuta despues de renderizar el calendar (con refresh.next()), utilizada para marcar el dia inicial
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    if (this.dia_inicial != ''){ //si existe un dia iniciado, lo marca (no ejecuta nada)
      body.forEach((day) => {
        let diaC = day.date;
        let diaC_int = `${diaC.getFullYear()}${((diaC.getMonth() +101).toString()).substring(1, 3)}${((diaC.getDate() + 100).toString()).substring(1, 3)}`;
        if(this.dia_inicial == diaC_int){
          // this.dayEnabledClicked(day)      // hacer esto al inicio da error
          day.cssClass = 'amc-cal-day-selected';
          this.selectedDay = day;
        }
      });
    }
  }


}



