import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';


import { UsuarioService } from 'src/app/services/usuario.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { EncriptacionService } from 'src/app/services/encriptacion.service';



@Component({
  selector: 'app-agenda-medicos',
  templateUrl: './agenda-medicos.component.html',
  styleUrls: ['./agenda-medicos.component.css']
})
export class AgendaMedicosComponent implements OnInit {

  constructor(
    public router: Router,
    public _usuarioService: UsuarioService,
    public _schedule: ScheduleService,
    public _general: GeneralService,
  ) { }

  doctor_list: any = [];                                       // listado de doctores
  searchText: string = ''; 
  usuario: any = {};

  ngOnInit(): void {
    this.usuario = new EncriptacionService().getUserInfo();

    if ( this.usuario.typ_id == 7 || this.usuario.typ_id == 8 ){
      this.getDoctores();  
    }
    else {
      Swal.fire({
        icon: 'info',
        title: 'Acceso no autorizado',
        timer: 2000
      })
      setTimeout(() => this.router.navigate(['dashboard']), 2000);
    }
  }



  // Trae los doctores
  getDoctores(){
    this._general.getDoctorList() 
      .then( (data: any) => {
        if ( data.length == 0 ) {
          Swal.fire({
            icon: 'info',
            text: 'Sin medicos disponibles',
            timer: 2000
          })
        }
        else {
          this.doctor_list = data;          
        }
    })
  }





  verAgenda(doctor_id){
    this.router.navigate(['agenda/' +doctor_id+ '/ver'])
  } 
  editAgenda(doctor_id){
    this.router.navigate(['agenda/' +doctor_id+ '/add'])
  }
}
