<div *ngIf="steps.table" style="width: 100%;">
     <div style="width: 100%;text-align: -webkit-right;margin:10px;">
          <button class="primary buttonStylePrimary" (click)="addCustomerBenefits()">Agregar Plan</button>
     </div>
     <div class="row" style="width: 100%;">
          <div class="col-2">
               <p class="pTableTitle">Imagen</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Nombre</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Teléfono</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Periocidad</p>
          </div>
          <div class="col-1">
               <p class="pTableTitle">N° Cargas</p>
          </div>
          <div class="col-1">
               <p class="pTableTitle">Tipo</p>
          </div>
          <div class="col-2">
               <p class="pTableTitle">Acción</p>
          </div>
     </div>
     <hr>
     <ng-container *ngFor="let cBenefit of cBenefits">
          <div class="row" style="width: 100%;">
               <div class="col-2">
                    <ng-container *ngIf="cBenefit.cus_ben_image">
                         <img [src]="cBenefit.cus_ben_image" width="100%">
                    </ng-container>
                    <ng-container *ngIf="!cBenefit.cus_ben_image">
                         <p class="pTableNotInfo"> No tiene imagen</p>
                    </ng-container>
               </div>
               <div class="col-2">
                    <ng-container *ngIf="cBenefit.cus_ben_name">
                         <p class="pTableInfo">{{cBenefit.cus_ben_name}}</p>
                    </ng-container>
                    <ng-container *ngIf="!cBenefit.cus_ben_name">
                         <p class="pTableNotInfo"> No tiene</p>
                    </ng-container>
               </div>
               <div class="col-2">
                    <ng-container *ngIf="cBenefit.cus_ben_phone">
                         <p class="pTableInfo">{{cBenefit.cus_ben_phone}}</p>
                    </ng-container>
                    <ng-container *ngIf="!cBenefit.cus_ben_phone">
                         <p class="pTableNotInfo"> No tiene</p>
                    </ng-container>
               </div>
               <div class="col-2">
                    <ng-container *ngIf="cBenefit.cus_ben_typ_name">
                         <p class="pTableInfo">{{cBenefit.cus_ben_typ_name}}</p>
                    </ng-container>
                    <ng-container *ngIf="!cBenefit.cus_ben_typ_name">
                         <p class="pTableNotInfo"> No tiene</p>
                    </ng-container>
               </div>
               <div class="col-1">
                    <ng-container *ngIf="cBenefit.cus_ben_qty_beneficiaries">
                         <p class="pTableInfo">{{cBenefit.cus_ben_qty_beneficiaries}}</p>
                    </ng-container>
                    <ng-container *ngIf="!cBenefit.cus_ben_qty_beneficiaries">
                         <p class="pTableNotInfo">Por base</p>
                    </ng-container>
               </div>
               <div class="col-1">
                    <ng-container *ngIf="cBenefit.cus_ben_particular">
                         <p class="pTableInfo">Particular</p>
                    </ng-container>
                    <ng-container *ngIf="!cBenefit.cus_ben_particular">
                         <p class="pTableNotInfo">Epresarial</p>
                    </ng-container>
               </div>
               <div class="col-2" style="text-align: center;">
                    <button type="button" class="btn btn-secondary actionButton"
                         (click)="deleteCustomerBenefits(cBenefit)"><mat-icon>delete</mat-icon></button>
                    <button type="button" class="btn btn-secondary actionButton"
                         (click)="editCustomerBenefits(cBenefit)"><mat-icon>edit</mat-icon></button>
                    <button type="button" class="btn btn-secondary actionButton" (click)="seeDetails(cBenefit)"><mat-icon>info</mat-icon></button>
               </div>
          </div>
          <hr>
     </ng-container>
</div>


<!--------------------------------------  Agregar -------------------------------------->
<div *ngIf="steps.add" style="width: 100%;">
     <div class="row modalBody" style="width: 100%;">
          <div class="col-4">
               <p class="pAddTitle">Nombre *</p>
               <input type="text" [(ngModel)]="newCustomerBen.cus_ben_name">
          </div>
          <div class="col-4">
               <p class="pAddTitle">Cliente *</p>
               <select [(ngModel)]="newCustomerBen.cus_ben_cus_id">
                    <option value="">Seleccione cliente</option>
                    <option *ngFor="let customer of customers" [value]="customer.cus_id">{{customer.cus_name}}</option>
               </select>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Periocidad *</p>
               <select [(ngModel)]="newCustomerBen.cus_ben_cus_ben_typ_id">
                    <option value="">Seleccione periocidad</option>
                    <option *ngFor="let benType of cBenefitsTypes" [value]="benType.cus_ben_typ_id">
                         {{benType.cus_ben_typ_name}}</option>
               </select>
          </div>
     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-3">
               <p class="pAddTitle">Tipo *</p>
               <select [(ngModel)]="newCustomerBen.cus_ben_particular">
                    <option value="">Seleccione tipo</option>
                    <option value="true">Particular</option>
                    <option value="false">Empresarial</option>
               </select>
          </div>
          <div class="col-3">
               <p class="pAddTitle">N° cargas<mat-icon
                         title="Si está vacío es por proceso de carga de base.">error_outline</mat-icon></p>
               <input type="number" [(ngModel)]="newCustomerBen.cus_ben_qty_beneficiaries">
          </div>
          <div class="col-3">
               <p class="pAddTitle">Código</p>
               <input type="text" [(ngModel)]="newCustomerBen.cus_ben_code">
          </div>
          <div class="col-3">
               <p class="pAddTitle">Teléfono</p>
               <input type="text" [(ngModel)]="newCustomerBen.cus_ben_phone">
          </div>
     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-6">
               <p class="pAddTitle">Descripción *</p>
               <textarea rows="4" [(ngModel)]="newCustomerBen.cus_ben_description"></textarea>
          </div>
          <div class="col-6">
               <p class="pAddTitle">Texto bienvenida <mat-icon
                         title="Si quiere que parte del texto vaya en negrita tiene que estar entre las etiquetas <span></span>">error_outline</mat-icon>
               </p>
               <textarea rows="4" [(ngModel)]="newCustomerBen.cus_ben_text_welcome"></textarea>
          </div>

     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-12">
               <p class="pAddTitle">Imagen Plan *
                    <label class="buttonStyleSecondary" style="height: 40px;margin-left: 10px;">Examinar
                         <input type="file" style="cursor: pointer !important;" (change)="onFileSelectedLogo($event)"
                              max-file-size="1024" required />
                    </label>
               </p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Archivo</p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Tipo</p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Peso</p>
          </div>
          <div class="col-12">
               <hr>
          </div>
          <div class="col-4">
               <p *ngIf="newCustomerBen.cus_ben_image && newCustomerBen.cus_ben_image.name">
                    {{newCustomerBen.cus_ben_image.name}}</p>
               <p *ngIf="!newCustomerBen.cus_ben_image || !newCustomerBen.cus_ben_image.name">No tiene</p>
          </div>
          <div class="col-4">
               <p *ngIf="newCustomerBen.cus_ben_image && newCustomerBen.cus_ben_image.type">
                    {{newCustomerBen.cus_ben_image.type}}</p>
               <p *ngIf="!newCustomerBen.cus_ben_image || !newCustomerBen.cus_ben_image.type">No tiene</p>
          </div>
          <div class="col-4">
               <p *ngIf="newCustomerBen.cus_ben_image && newCustomerBen.cus_ben_image.size">
                    {{newCustomerBen.cus_ben_image.size/1024 | number:'1.0-0'}} KB</p>
               <p *ngIf="!newCustomerBen.cus_ben_image || !newCustomerBen.cus_ben_image.size">No tiene</p>
          </div>


     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-12">
               <p class="pAddTitle">Especialidades * <mat-icon (click)="openModalSpe()">add</mat-icon></p>
               <ng-container *ngIf="newCustomerBen.cus_ben_specialities.length < 1">
                    <p> No tiene especialidades asociadas</p>
               </ng-container>
               <ng-container *ngIf="newCustomerBen.cus_ben_specialities.length >= 1">
                    <div class="row" style="width: 100%;">
                         <div class="col-4">
                              <p class="pAddTitle">Especialidad</p>
                         </div>
                         <div class="col-4">
                              <p class="pAddTitle">N° Consultas Titular</p>
                         </div>
                         <div class="col-3">
                              <p class="pAddTitle">N° Consultas Beneficiario</p>
                         </div>
                         <div class="col-1">
                              <p class="pAddTitle">Acción</p>
                         </div>
                         <div class="col-12">
                              <hr>
                         </div>
                         <ng-container *ngFor="let spe of newCustomerBen.cus_ben_specialities">
                              <div class="col-4">
                                   <p>{{spe.spe_name}}</p>
                              </div>
                              <div class="col-4">
                                   <p>{{spe.cty_titular?spe.cty_titular:'Ilimitadas'}} - {{spe.disc_titular}}%</p>
                              </div>
                              <div class="col-3">
                                   <p>{{spe.cty_beneficiary?spe.cty_beneficiary:'Ilimitadas'}} -
                                        {{spe.disc_beneficiary}}%</p>
                              </div>
                              <div class="col-1">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="deleteSpe(spe)"><mat-icon>delete</mat-icon></button>
                              </div>
                         </ng-container>
                    </div>
               </ng-container>

          </div>



     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-12">
               <p class="pAddTitle">Sala de espera * <mat-icon (click)="openModalMedia()">add</mat-icon></p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Archivo</p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Tipo</p>
          </div>
          <div class="col-3">
               <p class="pAddTitle">Peso</p>
          </div>
          <div class="col-1">
               <p class="pAddTitle">Acción</p>
          </div>
          <div class="col-12">
               <hr>
          </div>

          <ng-container *ngFor="let media of newCustomerBen.cus_ben_media; let i = index">
               <div class="col-4">
                    <p *ngIf="media.name">{{media.name}}</p>
                    <p *ngIf="!media.name"> No tiene</p>
               </div>
               <div class="col-4">
                    <p *ngIf="media.type">{{media.type}}</p>
                    <p *ngIf="!media.type"> No tiene</p>
               </div>
               <div class="col-3">
                    <p *ngIf="media.size">{{media.size/1024 | number:'1.0-0'}} KB</p>
                    <p *ngIf="!media.size"> No tiene</p>
               </div>
               <div class="col-1">
                    <button type="button" class="btn btn-secondary actionButton" (click)="deleteMedia(i)">
                         <mat-icon>delete_outline</mat-icon>
                         </button>
               </div>
          </ng-container>


     </div>
     <hr>

     <div class="row" style="width: 100%;justify-content: right;margin:10px;">
          <div style="margin: 5px;">
               <button class="primary buttonStyleSecondary" (click)="cancelAdd()">Cancelar</button>
          </div>
          <div style="margin: 5px;">
               <button class="primary buttonStylePrimary" (click)="confirmAdd()">Agregar</button>
          </div>
     </div>
</div>
<!--------------------------------------  Fin Agregar -------------------------------------->

<!--------------------------------------  Editar -------------------------------------->
<div *ngIf="steps.edit" style="width: 100%;">
     <div class="row modalBody" style="width: 100%;">
          <div class="col-4">
               <p class="pAddTitle">Nombre *</p>
               <input type="text" [(ngModel)]="editCustomerBenSelected.cus_ben_name">
          </div>
          <div class="col-4">
               <p class="pAddTitle">Cliente *</p>
               <select [(ngModel)]="editCustomerBenSelected.cus_ben_cus_id">
                    <option value="">Seleccione cliente</option>
                    <option *ngFor="let customer of customers" [value]="customer.cus_id">{{customer.cus_name}}</option>
               </select>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Periocidad *</p>
               <select [(ngModel)]="editCustomerBenSelected.cus_ben_cus_ben_typ_id">
                    <option value="">Seleccione periocidad</option>
                    <option *ngFor="let benType of cBenefitsTypes" [value]="benType.cus_ben_typ_id">
                         {{benType.cus_ben_typ_name}}</option>
               </select>
          </div>
     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-3">
               <p class="pAddTitle">Tipo *</p>
               <select [(ngModel)]="editCustomerBenSelected.cus_ben_particular">
                    <option value="">Seleccione tipo</option>
                    <option value="true">Particular</option>
                    <option value="false">Empresarial</option>
               </select>
          </div>
          <div class="col-3">
               <p class="pAddTitle">N° cargas<mat-icon
                         title="Si está vacío es por proceso de carga de base.">error_outline</mat-icon></p>
               <input type="number" [(ngModel)]="editCustomerBenSelected.cus_ben_qty_beneficiaries">
          </div>
          <div class="col-3">
               <p class="pAddTitle">Código</p>
               <input type="text" [(ngModel)]="editCustomerBenSelected.cus_ben_code">
          </div>
          <div class="col-3">
               <p class="pAddTitle">Teléfono</p>
               <input type="text" [(ngModel)]="editCustomerBenSelected.cus_ben_phone">
          </div>
     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-6">
               <p class="pAddTitle">Descripción *</p>
               <textarea rows="4" [(ngModel)]="editCustomerBenSelected.cus_ben_description"></textarea>
          </div>
          <div class="col-6">
               <p class="pAddTitle">Texto bienvenida <mat-icon
                         title="Si quiere que parte del texto vaya en negrita tiene que estar entre las etiquetas <span></span>">error_outline</mat-icon>
               </p>
               <textarea rows="4" [(ngModel)]="editCustomerBenSelected.cus_ben_text_welcome"></textarea>
          </div>

     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-12">
               <p class="pAddTitle">Imagen Plan *
                    <label class="buttonStyleSecondary" style="height: 40px;margin-left: 10px;">Examinar
                         <input type="file" style="cursor: pointer !important;" (change)="onFileSelectedLogo($event)"
                              max-file-size="1024" required />
                    </label>
               </p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Archivo</p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Tipo</p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Peso</p>
          </div>
          <div class="col-12">
               <hr>
          </div>
          <div class="col-4">
               <p *ngIf="editCustomerBenSelected.cus_ben_image && editCustomerBenSelected.cus_ben_image.name">
                    {{editCustomerBenSelected.cus_ben_image.name}}</p>
               <p *ngIf="!editCustomerBenSelected.cus_ben_image || !editCustomerBenSelected.cus_ben_image.name">No tiene</p>
          </div>
          <div class="col-4">
               <p *ngIf="editCustomerBenSelected.cus_ben_image && editCustomerBenSelected.cus_ben_image.type">
                    {{editCustomerBenSelected.cus_ben_image.type}}</p>
               <p *ngIf="!editCustomerBenSelected.cus_ben_image || !editCustomerBenSelected.cus_ben_image.type">No tiene</p>
          </div>
          <div class="col-4">
               <p *ngIf="editCustomerBenSelected.cus_ben_image && editCustomerBenSelected.cus_ben_image.size">
                    {{editCustomerBenSelected.cus_ben_image.size/1024 | number:'1.0-0'}} KB</p>
               <p *ngIf="!editCustomerBenSelected.cus_ben_image || !editCustomerBenSelected.cus_ben_image.size">No tiene</p>
          </div>


     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-12">
               <p class="pAddTitle">Especialidades * <mat-icon (click)="openModalSpe()">add</mat-icon></p>
               <ng-container *ngIf="editCustomerBenSelected.cus_ben_specialities.length < 1">
                    <p> No tiene especialidades asociadas</p>
               </ng-container>
               <ng-container
                    *ngIf="editCustomerBenSelected.cus_ben_specialities.length >= 1 || editCustomerBenSelected.new_cus_ben_specialities.length >= 1">
                    <div class="row" style="width: 100%;">
                         <div class="col-4">
                              <p class="pAddTitle">Especialidad</p>
                         </div>
                         <div class="col-4">
                              <p class="pAddTitle">N° Consultas Titular</p>
                         </div>
                         <div class="col-3">
                              <p class="pAddTitle">N° Consultas Carga</p>
                         </div>
                         <div class="col-1">
                              <p class="pAddTitle">Acción</p>
                         </div>
                         <div class="col-12">
                              <hr>
                         </div>
                         <ng-container *ngFor="let spe of editCustomerBenSelected.cus_ben_specialities">
                              <div class="col-4">
                                   <p>{{spe.spe_name}}</p>
                              </div>
                              <div class="col-4">
                                   <p>{{spe.cus_ben_qty_con_titular?spe.cus_ben_qty_con_titular:'Ilimitadas'}} -
                                        {{spe.cus_ben_disc_titular}}%</p>
                              </div>
                              <div class="col-3">
                                   <p>{{spe.cus_ben_qty_con_beneficiaries?spe.cus_ben_qty_con_beneficiaries:'Ilimitadas'}}
                                        - {{spe.cus_ben_disc_beneficiaries}}%</p>
                              </div>
                              <div class="col-1">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="deleteSpeDet(spe.cus_ben_det_id)"><mat-icon>delete</mat-icon></button>
                              </div>
                         </ng-container>
                         <ng-container *ngFor="let spe of editCustomerBenSelected.new_cus_ben_specialities">
                              <div class="col-4">
                                   <p>{{spe.spe_name}}</p>
                              </div>
                              <div class="col-4">
                                   <p>{{spe.cty_titular?spe.cty_titular:'Ilimitadas'}} - {{spe.disc_titular}}%
                                   </p>
                              </div>
                              <div class="col-3">
                                   <p>{{spe.cty_beneficiary?spe.cty_beneficiary:'Ilimitadas'}} -
                                        {{spe.disc_beneficiary}}%</p>
                              </div>
                              <div class="col-1">
                                   <button type="button" class="btn btn-secondary actionButton"
                                        (click)="deleteSpe(spe)"><mat-icon>delete</mat-icon></button>
                              </div>
                         </ng-container>
                    </div>
               </ng-container>

          </div>



     </div>
     <hr>
     <div class="row modalBody" style="width: 100%;">
          <div class="col-12">
               <p class="pAddTitle">Sala de espera * <mat-icon (click)="openModalMedia()">add</mat-icon></p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Archivo</p>
          </div>
          <div class="col-4">
               <p class="pAddTitle">Tipo</p>
          </div>
          <div class="col-3">
               <p class="pAddTitle">Peso</p>
          </div>
          <div class="col-1">
               <p class="pAddTitle">Acción</p>
          </div>
          <div class="col-12">
               <hr>
          </div>

          <ng-container *ngFor="let media of editCustomerBenSelected.cus_ben_media; let i = index">
               <div class="col-4">
                    <p *ngIf="media.med_roo_lob_url"><a [href]="media.med_roo_lob_url" target="_blank">
                              Ver
                         </a></p>
                    <p *ngIf="!media.med_roo_lob_url"> No tiene</p>
               </div>
               <div class="col-4">
                    <p *ngIf="media.med_roo_lob_media_type">{{media.med_roo_lob_media_type}}</p>
                    <p *ngIf="!media.med_roo_lob_media_type"> No tiene</p>
               </div>
               <div class="col-3">
                    <p *ngIf="media.size">{{media.size/1024 | number:'1.0-0'}} KB</p>
                    <p *ngIf="!media.size"> No tiene</p>
               </div>
               <div class="col-1">
                    <button type="button" class="btn btn-secondary actionButton"
                         (click)="deleteMediaRoomLobby(media.med_roo_lob_id)"><mat-icon>delete</mat-icon></button>
               </div>
          </ng-container>
          <ng-container *ngFor="let media of editCustomerBenSelected.new_cus_ben_media; let i = index">
               <div class="col-4">
                    <p *ngIf="media.name">{{media.name}}</p>
                    <p *ngIf="!media.name"> No tiene</p>
               </div>
               <div class="col-4">
                    <p *ngIf="media.type">{{media.type}}</p>
                    <p *ngIf="!media.type"> No tiene</p>
               </div>
               <div class="col-3">
                    <p *ngIf="media.size">{{media.size/1024 | number:'1.0-0'}} KB</p>
                    <p *ngIf="!media.size"> No tiene</p>
               </div>
               <div class="col-1">
                    <button type="button" class="btn btn-secondary actionButton" (click)="deleteMedia(i)">
                    <mat-icon>delete</mat-icon>
                    </button>
               </div>
          </ng-container>


     </div>
     <hr>

     <div class="row" style="width: 100%;justify-content: right;margin:10px;">
          <div style="margin: 5px;">
               <button class="primary buttonStyleSecondary" (click)="cancelEdit()">Cancelar</button>
          </div>
          <div style="margin: 5px;">
               <button class="primary buttonStylePrimary" (click)="confirmEdit()">Editar</button>
          </div>
     </div>
</div>
<!--------------------------------------  Fin Editar -------------------------------------->


<div class="modal fade hide" id="deleteCustomerBen" tabindex="-1" role="dialog" aria-labelledby="deleteCustomerBen"
     aria-hidden="true">
     <div class="modal-dialog " role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Borrar cliente</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <p class="pTableTitle">¿Está seguro de eliminar el plan
                              {{deleteCustomerBenSelected.cus_ben_name}}?</p>
                         <p>Todos los beneficiarios asociados a este plan se
                              deshabilitarán.</p>
                    </div>
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-md-6 modalBody">
                              <button class="primary buttonStyleSecondary" data-toggle="modal"
                                   data-target="#deleteCustomerBen">Cancelar</button>
                         </div>
                         <div class="col-md-6 modalBody">
                              <button class="primary buttonStylePrimary" (click)="confirmDelete()">Eliminar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>
<div class="modal fade hide" id="seeCustomerBen" tabindex="-1" role="dialog" aria-labelledby="seeCustomerBen"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Detalle Plan</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;">
                         <div class="row" style="width: 100%;">
                              <div class="col-6">
                                   <p class="pTableTitle">Imagen</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_ben_image">
                                        <img [src]="detailCustomerBenSelected.cus_ben_image" width="90%">
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_ben_image">
                                        <p class="pTableNotInfo"> No tiene
                                             imagen</p>
                                   </ng-container>
                              </div>

                              <div class="col-3">
                                   <p class="pTableTitle">Plan</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_ben_name">
                                        <p class="pTableInfo">{{detailCustomerBenSelected.cus_ben_name}}</p>
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_ben_name">
                                        <p class="pTableNotInfo"> No tiene</p>
                                   </ng-container>
                              </div>
                              <div class="col-3">
                                   <p class="pTableTitle">Cliente</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_name">
                                        <p class="pTableInfo">{{detailCustomerBenSelected.cus_name}}</p>
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_name">
                                        <p class="pTableNotInfo"> No tiene</p>
                                   </ng-container>
                              </div>
                              <div class="col-12">
                                   <hr>
                              </div>
                              <div class="col-6">
                                   <p class="pTableTitle">Teléfono</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_ben_phone">
                                        <p class="pTableInfo">{{detailCustomerBenSelected.cus_ben_phone}}</p>
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_ben_phone">
                                        <p class="pTableNotInfo"> No tiene</p>
                                   </ng-container>
                              </div>

                              <div class="col-6">
                                   <p class="pTableTitle">Periocidad</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_ben_typ_name">
                                        <p class="pTableInfo">{{detailCustomerBenSelected.cus_ben_typ_name}}</p>
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_ben_typ_name">
                                        <p class="pTableNotInfo"> No tiene</p>
                                   </ng-container>
                              </div>
                              <div class="col-12">
                                   <hr>
                              </div>
                              <div class="col-6">
                                   <p class="pTableTitle">N° Cargas</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_ben_qty_beneficiaries">
                                        <p class="pTableInfo">{{detailCustomerBenSelected.cus_ben_qty_beneficiaries}}
                                        </p>
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_ben_qty_beneficiaries">
                                        <p class="pTableNotInfo">Por base</p>
                                   </ng-container>
                              </div>

                              <div class="col-6">
                                   <p class="pTableTitle">Tipo</p>
                                   <ng-container *ngIf="detailCustomerBenSelected.cus_ben_particular">
                                        <p class="pTableInfo">Particular</p>
                                   </ng-container>
                                   <ng-container *ngIf="!detailCustomerBenSelected.cus_ben_particular">
                                        <p class="pTableNotInfo">Epresarial</p>
                                   </ng-container>
                              </div>
                              <div class="col-12">
                                   <hr>
                              </div>

                              <div class="col-6">
                                   <p class="pTableTitle">C. Consultas</p>
                                   <ng-container
                                        *ngFor="let especialidad of detailCustomerBenSelected.cus_ben_specialities">
                                        <p class="pSpecialities">
                                             {{especialidad.spe_name}}:
                                        </p>
                                        <ul>
                                             <li>Titular: <ng-container
                                                       *ngIf="!especialidad.cus_ben_qty_con_titular">Ilimitadas</ng-container><ng-container
                                                       *ngIf="especialidad.cus_ben_qty_con_titular">{{especialidad.cus_ben_qty_con_titular}}</ng-container>
                                             </li>
                                             <li>Carga: <ng-container
                                                       *ngIf="!especialidad.cus_ben_qty_con_beneficiaries">Ilimitadas</ng-container><ng-container
                                                       *ngIf="especialidad.cus_ben_qty_con_beneficiaries">{{especialidad.cus_ben_qty_con_beneficiaries}}</ng-container>
                                             </li>
                                        </ul>
                                   </ng-container>
                              </div>

                              <div class="col-6">
                                   <p class="pTableTitle">Sala de espera</p>
                                   <ng-container *ngFor="let mediaElement of detailCustomerBenSelected.cus_ben_media">
                                        <p class="pSpecialities"><a [href]="mediaElement.med_roo_lob_url"
                                                  target="_blank">
                                                  {{mediaElement.med_roo_lob_media_type == 'image'? 'Imagen':'Video'}}
                                             </a></p>
                                   </ng-container>
                              </div>
                         </div>




                    </div>
                    <div class="row" style="justify-content: space-around;">
                         <div class="col-xs-12" style="text-align: -webkit-center;">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="addSpeBen" tabindex="-1" role="dialog" aria-labelledby="addSpeBen" aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Agregar especialidad</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;margin-bottom: 20px;">
                         <div class="col-4" style="align-self: self-end;">
                              <p class="pAddTitle">Especialidad</p>
                              <select [(ngModel)]="newSpe.spe_id">
                                   <option value="">Seleccione especialidad</option>
                                   <option *ngFor="let spe of specialityList" [value]="spe.spe_id">{{spe.spe_name}}
                                   </option>
                              </select>
                         </div>
                         <div class="col-2" style="align-self: self-end;">
                              <p class="pAddTitle">N° consultas titular <mat-icon
                                        title="Si queda vacío se considera ilimitadas.">error_outline</mat-icon></p>
                              <input type="number" [(ngModel)]="newSpe.cty_titular">
                         </div>
                         <div class="col-2" style="align-self: self-end;">
                              <p class="pAddTitle">Porcentaje</p>
                              <input type="number" [(ngModel)]="newSpe.disc_titular">
                         </div>
                         <div class="col-2" style="align-self: self-end;">
                              <p class="pAddTitle">N° consultas carga <mat-icon
                                        title="Si queda vacío se considera ilimitadas.">error_outline</mat-icon></p>
                              <input type="number" [(ngModel)]="newSpe.cty_beneficiary">
                         </div>
                         <div class="col-2" style="align-self: self-end;">
                              <p class="pAddTitle">Porcentaje</p>
                              <input type="number" [(ngModel)]="newSpe.disc_beneficiary">
                         </div>
                    </div>
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-md-6 modalBody">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-md-6 modalBody">
                              <button class="primary buttonStylePrimary" (click)="addSpe()">Agregar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade hide" id="addMediaBen" tabindex="-1" role="dialog" aria-labelledby="addMediaBen"
     aria-hidden="true">
     <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
               <div class="modal-header justify-content-center">
                    <h2 class="pModalTitle">Agregar media</h2>
               </div>
               <div class="modal-body">
                    <div class="row modalBody" style="width: 100%;margin-bottom: 20px;">
                         <div class="col-4">
                              <label class="buttonStyleSecondary" style="height: 40px;">Examinar
                                   <input type="file" style="cursor: pointer !important;" (change)="addMedia($event)"
                                        max-file-size="1024" required />
                              </label>
                              <p *ngIf="newMedia.name">{{newMedia.name}}</p>
                         </div>
                    </div>
                    <div class="row modalBody" style="width: 100%;">
                         <div class="col-md-6 modalBody">
                              <button type="button" class="buttonStyleSecondary" data-dismiss="modal">Cerrar</button>
                         </div>
                         <div class="col-md-6 modalBody">
                              <button class="primary buttonStylePrimary" (click)="confirmAddMedia()">Agregar</button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

<button #editCustomerBenTri type="button" class="btn d-none" data-toggle="modal"
     data-target="#editCustomerBen"></button>
<button #deleteCustomerBenTri type="button" class="btn d-none" data-toggle="modal"
     data-target="#deleteCustomerBen"></button>

<button #seeCustomerBenTri type="button" class="btn d-none" data-toggle="modal" data-target="#seeCustomerBen"></button>
<button #addSpeModal type="button" class="btn d-none" data-toggle="modal" data-target="#addSpeBen"></button>
<button #addMediaModal type="button" class="btn d-none" data-toggle="modal" data-target="#addMediaBen"></button>