import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../pages/mi-agenda/reserva-hora/confirm-dialog/confirm-dialog.component';
import { ReservaHoraCcComponent } from '../pages/mi-agenda-cc/reserva-hora-cc/reserva-hora-cc.component';
import { ReservaHoraComponent } from '../pages/mi-agenda/reserva-hora/reserva-hora.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ReservaHoraComponent | ReservaHoraCcComponent> {
  constructor(private dialogReserva: MatDialog) {

  }
  canDeactivate(
    component: ReservaHoraComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot

  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    if(nextState.url == '/mis-proximas-consultas'){
        return true;

    }


    if (ActivatedRoute) {
      // console.log(nextState);

      const dialogRef = this.dialogReserva.open(ConfirmDialogComponent);
      return dialogRef.afterClosed()
    }
    return (true);

  }




}