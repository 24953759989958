import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CompleterData, CompleterService } from 'ng2-completer';
import { EncriptacionService } from 'src/app/services/encriptacion.service';
import { GeneralService } from 'src/app/services/general.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { BeneficiaryService } from '../../../services/beneficiary.service';

@Component({
  selector: 'app-busca-user',
  templateUrl: './busca-user.component.html',
  styleUrls: ['./busca-user.component.css']
})
export class BuscaUserComponent implements OnInit {
  generalService: any;

  constructor(private fb: FormBuilder,
    public _general: GeneralService,
    private completerService: CompleterService,
    private usuarioService: UsuarioService,
    private beneficiaryService: BeneficiaryService) { }

  rutCuenta: string;
  tipo_usuario_name: string;
  usuario: any;

  rutBuscar: string;

  public muestraPerfil = true;
  public noHayInfo = true;
  public esmedico = false;

  usuario_buscado: any = {};
  public rut: string;



  public plansList: any = [];


  public rutABuscar = this.fb.group({
    rutBbdd: ['']
  });

  ngOnInit() {
    if (!sessionStorage.getItem('token')) { //sin session
      this.usuarioService.logout()
    }

    this.usuario = new EncriptacionService().getUserInfo();
    this.rutCuenta = this.usuario.rut;

  }

    changeRut() {
    var rut_Cliente: any = this.rutABuscar.value.rutBbdd;
    var valor = rut_Cliente;
    //console.log(this.rut)
    let resultado = valor.split('.').join('');
    let resultado2 = resultado.split('-').join('');
    var cuerpo = resultado2.toString().slice(0, -1);
    var dv = resultado2.toString().slice(-1).toUpperCase();
    if (valor != "") {
      this.rutABuscar.value.rutBbdd = cuerpo + "-" + dv;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * resultado2.toString().charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
    }
    var dvEsperado
    dvEsperado = 11 - (suma % 11);
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;
    if (dvEsperado != dv) {
      //console.log("changeRut invalido");
    }
    else {
      //console.log("changeRut valido");
    }
  }

  buscarRut() {
    this.plansList = [];

    if(!this._general.validaRut(this.rutABuscar.value.rutBbdd)){
      this._general.modalError('El usuario buscado no existe en el sistema','Ingresar RUT valido')
      this.rutABuscar.reset();
      return;
    }
    
    this.beneficiaryService.searchBeneficiary({ rut: this.rutABuscar.value.rutBbdd })
      .then((data: any) => {
        this.rutABuscar.reset();
        console.log(data);
        this.plansList = data;
        
        if(!this._general.validaRut(this.rutABuscar.value.rutBbdd) && this.plansList.length == 0){
          this._general.modalError('El RUT ingresado no se encuentra registrado en ninguna base del sistema','')
          this.rutABuscar.reset();
          return;
        }

      })
    
  }

}
