import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  constructor() { }
  public page: any = {
    main_page: true,
    edit: false,
    add: false,
  };
  ngOnInit(): void {
  }

  setPage({ main_page = false, edit = false, add = false}) {
    this.page = {
      main_page: main_page,
      add: add,
      edit: edit,
    };
  }

  resetPage() {
    this.page = {
      main_page: true,
      edit: false,
      add: false,
    };
  }

}
