<div class="row">
     <div class="col-12 divCard">
          <div style="width: 100%;margin-top: 20px;" class="row">
               <div class="col-9" style="align-self: center;">
                    <h2 class="pModalTitle">Carga de base</h2>
               </div>
               <div class="col-3">
                    <a class="buttonStyleSecondary" style="place-content:center;" download="ejemplo_plantilla" target="_blank"
                         href="./assets/files/ejemplo.xlsx">
                         Descargar plantilla
                    </a>
               </div>
               <div class="col-12">
                    <hr>
               </div>
               <ng-container *ngIf="customers.length > 0">
                    <div class="col-4">
                         <p class="pTableTitle">Cliente</p>
                         <select [(ngModel)]="cus_id" name="cus_id" (ngModelChange)="changeCustomer($event)">
                              <option value=''>Seleccione un cliente</option>
                              <option *ngFor="let cliente of customers" [value]='cliente.cus_id'>{{cliente.cus_name}}
                              </option>
                         </select>
                    </div>
               </ng-container>
               <ng-container *ngIf="customerSelected && customerSelected.planes && customerSelected.planes.length>0">
                    <div class="col-4">
                         <p class="pTableTitle">Plan <mat-icon
                                   title="Si queda vacío se considera que es por productos.">error_outline</mat-icon></p>
                         <select [(ngModel)]="cus_ben_id" name="cus_ben_id"
                              (ngModelChange)="changeCustomerBenefits($event)">
                              <option value=''>Seleccione un plan</option>
                              <option *ngFor="let plan of customerSelected.planes" [value]='plan.cus_ben_id'>
                                   {{plan.cus_ben_name}}</option>
                         </select>
                    </div>
               </ng-container>
               <ng-container *ngIf="cus_id">
                    <div class="col-4">
                         <p class="pTableTitle">Tipo carga</p>
                         <select [(ngModel)]="loadType" name="loadType">
                              <option value=''>Seleccione tipo carga</option>
                              <option value='stock'>Base stock</option>
                              <option value='acumulativa'>Base acumulativa</option>
                         </select>
                    </div>
                    <div class="col-12">
                         <hr>
                    </div>
               </ng-container>
               <ng-container *ngIf="loadType ">
                    <div class="col-3" style="max-width: 150px;">
                         <p class="pTableTitle">Seleccione archivo</p>
                         <label class="buttonStyleSecondary" style="height: 40px;margin-left: 10px;">Examinar
                              <input type="file" style="cursor: pointer !important;" (change)="onFileSelected($event)"
                                   max-file-size="1024" required />
                         </label>
                    </div>
               </ng-container>
               <ng-container *ngIf="csvFile && csvFile.name">
                    <div class="col-4">
                         <p class="pTableTitle">Archivo</p>
                         <p>{{csvFile.name}}</p>
                    </div>
                    <ng-container
                         *ngIf="tableDataPreview && tableDataPreview.length > 0 && headers && headers.length > 0 ">
                         <div class="col-4" style="align-self: center !important;">
                              <button class="primary buttonStylePrimary" (click)="loadData()">Cargar base</button>
                         </div>
                    </ng-container>
                    <div class="col-12">
                         <hr>
                    </div>
               </ng-container>

               <ng-container *ngIf="tableDataPreview && tableDataPreview.length > 0 && headers && headers.length > 0 ">
                    <div class="col-12">
                         <p class="pTableTitle" style="margin-bottom: 10px;">Vista previa</p>
                         <table style="width: 100%;">
                              <tr>
                                   <td *ngFor="let header of headers">
                                        <p class="pTableTitle">{{header.name}}</p>
                                   </td>
                              </tr>
                              <ng-container *ngFor="let data of tableDataPreview">
                                   <tr>
                                        <ng-container *ngFor="let header of headers">
                                             <td>{{data[header.name]}}</td>
                                        </ng-container>
                                   </tr>
                              </ng-container>
                         </table>
                    </div>
               </ng-container>
          </div>
     </div>

</div>