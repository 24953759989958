<div class="input-group">
    <span class="input-group-btn">
        <button (click)="cambiarValor(-5)"
                [ngClass]="btnClass"
                type="button">
            <mat-icon>remove</mat-icon>
            <!-- class="btn btn-primary" lo reemplazo por el [ngClass]="btnClass" que es un input para el color del boton-->
        </button>
    </span>
    <input  type="number" 
            class="form-control" 
            [ngClass]="{'is-invalid': progreso > 100 || progreso < 0}"
            placeholder="progreso"
            min ="0"
            max="100"
            (ngModelChange)="onChange($event)"
            [(ngModel)] = "progreso">
    <span class="input-group-btn">
        <button (click)="cambiarValor(+5)"
                [ngClass]="btnClass"
                type="button">
                <mat-icon>add</mat-icon>
            </button>
    </span>
</div>
 