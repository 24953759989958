<ng-container *ngIf="mediaList.length > 0">
    <div class="row">
        <div class="col-md-12">
            <div id="carouselControls" style="width:100%; min-height:300px ;"class="carousel slide" data-interval="false">
                <ol class="carousel-indicators">
                    <ng-container *ngFor="let mediaItem of mediaList; let i = index">
                        <li data-target="#carouselControls" [attr.data-slide-to]="i" class="active" ></li>
                    </ng-container>
                  </ol>
                <div class="carousel-inner" style="text-align: -webkit-center;width:100%; height:400px ; padding: 20px;"  >
                    <ng-container *ngFor="let mediaItem of mediaList; let i = index">
                        <div [class]="i == 0 ? 'carousel-item active itemCarousel':'carousel-item itemCarousel'"[style]="mediaItem.med_roo_lob_media_type == 'video'?'background-color: rgb(0 0 0 / 34%);':'background-color: rgb(0 0 0 / 0%);'">
                            <ng-container *ngIf="mediaItem.med_roo_lob_media_type == 'video'">
                                <video [id]="nameIframe+i" style="width:auto;height: auto;" [src]="mediaItem.med_roo_lob_url|safeUrl" controls></video>
                            </ng-container>
                            <ng-container *ngIf="mediaItem.med_roo_lob_media_type == 'image'">
                                <img style="width:100%;" [src]="mediaItem.med_roo_lob_url|safeUrl" class="centeredElement">
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Anterior</span>
                </a>
                <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Siguiente</span>
                </a>
            </div>

        </div>
    </div>

</ng-container>
